<template>
    <div class="order-items">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Quality Assessments</h3>
        <div class="flex flex-col">
            <div class="-my-2 py-2 overflow-x-auto md:overflow-visible sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
                    <table class="min-w-full">
                        <thead>
                        <tr>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Quality Assessment
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase">
                                Date of works
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="orderItemEntity, key in orderItemEntities">
                            <payment-voucher-qas-row @change="change" :ref="'QualityAssessmentRow'" :orderItemEntity="orderItemEntity"
                                            :scope="'order-item-row-'+key" :response="response"></payment-voucher-qas-row>
                        </template>
                        </tbody>
                        <new-payment-voucher-qas @change="change" :ref="'newQualityAssessments'" :response="response"></new-payment-voucher-qas>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PaymentVoucherQasRow from "@/components/PaymentVoucherQasRow";
    import NewPaymentVoucherQas from "@/components/NewPaymentVoucherQas";

    export default {
        inject: ["$validator"],
        components: {
            NewPaymentVoucherQas,
            PaymentVoucherQasRow,
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            orderItemEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.properties.get('qas');
            }
        },
        methods: {
            updateItems() {
                let updateItemPromises = [];
                this.$refs.newQualityAssessments.addNewItems().forEach(function (promise) {
                    updateItemPromises.push(promise);
                })

                return Promise.all(updateItemPromises);
            },
            change() {
                this.$emit('change');
            }
        },
    }
</script>

<style lang="scss">
    .order-items label {
        display: none;
    }

    .table-container-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        background-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -moz-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -ms-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -o-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
    }
</style>
