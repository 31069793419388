<template>
    <div v-if="message || details" class="bg-purple-200 p-4">
        <div class="flex md:items-center">
            <div class="flex-shrink-0">
                <!-- Heroicon name: information-circle -->
                <svg class="h-5 w-5 text-purple-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between md:items-center">
                <p class="text-sm leading-5 text-purple-700">
                    {{message}}
                </p>
                <p class="mt-3 text-sm leading-5 md:mt-0 md:ml-6">
                    <a href="#" data-dusk="more-details-link" @click="displayModal = true" class="whitespace-nowrap font-medium text-purple-700 hover:text-purple-600 transition ease-in-out duration-150">
                        Details <svg class="inline mr-3 h-6 w-6  group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16.01 11H5c-.55 0-1 .45-1 1s.45 1 1 1h11.01v1.79c0 .45.54.67.85.35l2.78-2.79c.19-.2.19-.51 0-.71l-2.78-2.79c-.31-.32-.85-.09-.85.35V11z"/></svg>
                    </a>
                </p>
            </div>
            <div v-if="displayModal" class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="fixed inset-0 transition-opacity">
                        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>

                    <!-- This element is to trick the browser into centering the modal contents. -->
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                    <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-6 pb-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div>
                            <div class="mt-3 text-center sm:mt-5">
                                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                    Details
                                </h3>
                                <div class="mt-2">
                                    <p class="text-sm leading-5 text-gray-500 whitespace-pre-wrap" v-html="details"></p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-6">
                            <span class="flex w-full rounded-md shadow-sm">
                              <button @click="displayModal = false" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Close
                              </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Siren from "super-siren";
import { mapGetters } from "vuex";
import SlideOver from "../SlideOver";
import PrimaryButton from "../PrimaryButton";
export default {
    components: {
        SlideOver,
        PrimaryButton
    },
  data() {
    return {
        displayModal : false,
        message : null,
        details: null
    };
  },
    created() {
        let follow = Siren.Helper.follow;

        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(
                    follow("dashboards")
                )
                .then(res => {
                    var properties = res.body.properties;
                    this.message = properties.get('message');
                    this.details = properties.get('details');
                 }, this);
        })
    },
    computed: {
    ...mapGetters({
            accessToken: "getAccessToken"
        })
    }
}
</script>

<style lang="scss" scoped>
</style>
