<template>
    <div class="certificate-items" v-if="newCertificateAction || orderItemEntities.count() > 0">
        <h3 class="certificate-header text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Certificates</h3>
        <div class="flex flex-col">
            <div class="-my-2 py-2 overflow-x-auto md:overflow-visible sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
                    <table class="min-w-full">
                        <thead>
                        <tr>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase">
                                Name
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase">
                                Image
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase">
                                Expiry Date
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase">
                                Reminder Limit (months)
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase">
                                Front Or Back
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="orderItemEntity, key in orderItemEntities">
                            <certificate-row @change="change" :ref="'certificateRow'" :orderItemEntity="orderItemEntity"
                                            :scope="'certificate-row-'+key" :response="response"></certificate-row>
                        </template>
                        </tbody>
                        <new-certificate @change="change" :ref="'newCertificate'" :response="response"></new-certificate>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CertificateRow from "@/components/CertificateRow";
    import NewCertificate from "@/components/NewCertificates";

    export default {
        inject: ["$validator"],
        components: {
            CertificateRow,
            NewCertificate
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            orderItemEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('certificate'));
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update').first();
            },
            newCertificateAction() {
                return this.response.body.actions.filter(action => action.name === 'add-certificate').first();
            },
        },
        methods: {
            updateCertificates() {
                let updateItemPromises = [];

                if (this.$refs.certificateRow) {
                    this.$refs.certificateRow.forEach(function (row, index) {
                        updateItemPromises.push(row.update().catch(error => {
                            throw {
                                error: error,
                                scope: 'certificate-row-' + index
                            };
                        }));
                    });
                }

                this.$refs.newCertificate.addCertificate().forEach(function (promise) {
                    updateItemPromises.push(promise);
                })

                return Promise.all(updateItemPromises);
            },
            change() {
                this.$emit('change');
            }
        },
    }
</script>

<style lang="scss">
    .certificate-items label {
        display: none;
    }

    .table-container-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        background-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -moz-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -ms-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -o-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
    }
</style>
