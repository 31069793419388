<template>
    <div class="container mx-auto px-4 mb-6 mt-6">
        <div class="bg-white py-12 px-12  border-b border-gray-200 sm:px-6 overflow-hidden shadow rounded-lg">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                Getting started.
            </h3>
            <p class="mt-1 text-sm leading-5 text-gray-500 mb-6">
                Welcome to your new account, to make the most of our procurement system there are a few stepup steps you'll need to complete first.
            </p>

            <div>
                <nav class="flex justify-left">
                    <ul class="space-y-6">
                        <li v-for="step in completeSteps" class="py-1 py-2 rounded px-2 mb-1">
                            <div class="group">
                                <div class="flex items-start space-x-3">
                                    <div class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                                        <svg class="h-full w-full text-indigo-600 group-hover:text-indigo-800 group-focus:text-indigo-800 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-900 group-focus:text-gray-900 transition ease-in-out duration-150">{{ step.title }}</p>
                                </div>
                            </div>
                        </li>

                        <li v-for="step in incompleteSteps" class="py-1 py-2 rounded px-2 mb-1" :class="{'bg-gray-200': isNextStep(step)}">
                            <div class="group">
                                <div class="flex items-start space-x-3">
                                    <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
                                        <span class="block h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400 group-focus:bg-gray-400 transition ease-in-out duration-150"></span>
                                    </div>
                                    <p class="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-900 group-focus:text-gray-900 transition ease-in-out duration-150">{{ step.title }}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="ml-4 border border-gray-200 rounded p-4 flex-grow">
                        <div class="pb-5 space-y-2">
                            <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">
                                {{nextStep.title}}
                            </h3>
                            <p class="max-w-4xl text-sm leading-5 text-gray-500 mb-4">{{nextStepContent}}</p>
                            <primary-button @click.native="goToLink(nextStep.key, nextStep.client_id)" >{{nextStep.title}}</primary-button>
                        </div>
                    </div>
                </nav>
            </div>
        </div>


    </div>
</template>

<script>
    import PrimaryButton from "./PrimaryButton";
    export default {
        components: {PrimaryButton},
        computed: {
            nextStep() {
                return this.incompleteSteps[0];
            },
            nextStepContent(){
                if(this.nextStep.key === "create-site-manager"){
                    return `To start things off we need to add a site manager / supervisor. Click below to add your first site manager / supervisor.`;
                }

                if(this.nextStep.key === "create-approval-team-member"){
                    return `Each organisation will need an approved person to approve order over a certain amount. Please now add an approved person who will have this permission.`;
                }

                if(this.nextStep.key === "create-project"){
                    return 'Let add our first project. Here we will save the project details.';
                }

                if(this.nextStep.key === "create-contract"){
                    return 'We can now add our contracts to the projects. This can help maintain separate budgets for separate contracts on the same project. ie. Electrical or Mechanical, or we can split any way you decide like Block A or Block B.';
                }
            }
        },
        methods: {
            isNextStep(step) {
                return step === this.nextStep;
            },
            goToLink(key, clientId){
                var link = 'clients/'+clientId
                switch(key){
                    case 'create-site-manager':
                    case 'create-approval-team-member':
                        link = link + '#users';
                        break;
                    case 'create-project':
                        link = link + '#projects';
                        break;
                    case 'create-contract':
                        link = link + '#contracts';
                        break;
                }
                window.location = link;
            }
        },
        props: {
            completeSteps: {
                type: Object
            },
            incompleteSteps: {
                type: Object
            },
        },
    }
</script>

<style scoped>

</style>
