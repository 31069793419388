<template>
    <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <div class="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
            <slot v-if="filterAction" name="filters">
                <order-filters class="-ml-3 -mr-4" v-if="filterAction" :response="response" :action="filterAction" actionBaseUrl="/product-library"></order-filters>
            </slot>
            <div class="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                <a v-if="withoutTypeLink" :href="getLinkHref(withoutTypeLink)" class="group">
                    <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                        <img src="../../images/no-image-available.png" alt="products without type" class="h-full w-full object-contain object-center group-hover:opacity-75" />
                    </div>
                    <h3 class="mt-4 text-lg text-center text-gray-700">Uncategorised Products</h3>
                </a>
                <a v-for="(product,index) in categories" :key="index" :href="getLink(product)" class="group">
                    <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                        <img v-if="product.entity.properties.get('image')" :src="product.entity.properties.get('image')" :alt="product.entity.properties.get('image') ? product.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />
                        <img v-else src="../../images/no-image-available.png" :alt="product.entity.properties.get('image') ? product.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />

                    </div>
                    <h3 class="mt-4 text-lg text-center text-gray-700">{{ product.entity.properties.get('name') }}</h3>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import OrderFilters from "@/v2/filters/OrderFilters.vue";

export default {
    name: "ProductCategoryList",
    components: {OrderFilters},
    props:{
        categories:{
            type: Object,
            default: null
        },
        withoutTypeLink:{
            type: Object,
            default: null
        },
        response:{
            type: Object
        }
    },
    computed: {
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        }
    },
    methods:{
        getLink(category)
        {
            return this.getFrontendRouteFromApiRoute(category.entity.links.filter(entity => entity.rels.contains('self')).first().href);
        },
        getLinkHref(link){
            return this.getFrontendRouteFromApiRoute(link.href);
        }
    }
}
</script>
