<template>
  <div >
  <notification :message="message"></notification>
    <top-header-template>
        <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Reset Password
                </h2>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md mb-1 mx-6">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <p class="text-red-600" v-if="error" v-text="error"></p>
                <form action="#" method="POST" @submit.prevent="onSubmit">
                    <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Email address</label>
                    <div class="mt-1 rounded-md shadow-sm">
                        <input
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          id="email"
                          name="email"
                          v-model="email"
                          v-validate="'required'"
                          placeholder="Please enter your email"
                          :class="{'is-invalid': errors.first('email')}"
                        >
                        <span class="mt-2 text-sm text-red-600">{{ errors.first('email') }}</span>
                    </div>
                    <div class="mt-6">
                      <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="w-full flex-inline justify-center align-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:ring-primary active:bg-primary-700 transition duration-150 ease-in-out">
                            Reset Password
                            <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4 align-middle" colorClass="text-gray-200"></loading>
                        </button>
                      </span>
                    </div>
                </form>
              </div>
                <p class="mt-10 text-center text-sm text-gray-500">
                    Back to
                    <a href="#" @click.prevent="goToLogin()" class="cursor-pointer font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Login</a>
                </p>
            </div>
        </div>
    </top-header-template>
  </div>
</template>

<script>
import Notification from "../components/Notification";
import { mapActions, mapGetters } from "vuex";
import Siren from "super-siren";
import TopHeaderTemplate from "../templates/TopHeaderTemplate";
import axios from "axios";
import InductionCertificates from "@/components/Action/Fields/InductionCertificates.vue";
import NewUserCertificates from "@/components/Action/Fields/NewUserCertificates.vue";
import LargeModel from "@/v2/components/LargeModel.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: {Loading, NewUserCertificates, InductionCertificates, TopHeaderTemplate, Notification, LargeModel },

  data() {
    return {
        error: null,
        email: null,
        password: null,
        showPassword: false,
        contactNumber: null,
        firstName: null,
        surname: null,
        dateOfBirth: null,
        certificates: [],
        showCertificates: false,
        loading: false
    };
  },
  computed: {
    ...mapGetters({
         accessToken: "getAccessToken",
        message: "getMessage",
    })
  },
  mounted() {
    if (this.accessToken !== null) {
      Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
      return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
        this.$router.push({ path: "/" });
      });
    }
  },

  methods: {
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
            this.loading = true;
            this.errors.clear();
            this.error = null;
            axios.post(process.env.VUE_APP_API_URL + '/api/user/forgot-password', {
                email: this.email
            })
                .then(response => {
                    if(response.data.error !== undefined){
                        this.error = response.data.error;
                        this.loading = false

                    }else if(response.data.success !== undefined){
                        this.$store.dispatch(
                            "setMessage",
                            response.data.success
                        );
                        this.goToLogin();
                        this.loading = false

                    }
                })
                .catch(error => {console.log(error);
                    this.loading = false;
                    if (error.response.status !== 422) {
                        this.error = error;
                    }
                    this.$setLaravelValidationErrorsFromResponse(error.response.data);
                });
        }
      });
    },
      togglePassword(){
          this.showPassword = !this.showPassword
      },
      goToLogin(){
          if(this.$route.query.redirect_to){
              this.$router.push("/login?redirect_to=" + this.$route.query.redirect_to);
          }else {
              this.$router.push("/login");
          }
      },
      messages(response) {
          return response.data.entities
              .filter(function (entity) {
                  return entity.rel.includes("status-messages");
              })[0];
      }
  }
};
</script>
