<template>
    <div v-if="this.links && hasLink('product-categories')" class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-3 sm:grid-cols-4 pt-6">
        <div class="border md:rounded-lg mb-8 bg-white grid grid-cols-3 col-span-3 section-links">
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('product-categories')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('product-categories')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M384 48c8.8 0 16 7.2 16 16V192h48V64c0-35.3-28.7-64-64-64H192c-35.3 0-64 28.7-64 64V192h48V64c0-8.8 7.2-16 16-16h56v64c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V48h56zM327.6 512H512c35.3 0 64-28.7 64-64V288c0-35.3-28.7-64-64-64H327.6c11.8 13.2 20.1 29.7 23.1 48H376v64c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V272h56c8.8 0 16 7.2 16 16V448c0 8.8-7.2 16-16 16H350.7c-3.1 18.3-11.3 34.8-23.1 48zM256 272c8.8 0 16 7.2 16 16V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V288c0-8.8 7.2-16 16-16h56v64c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V272h56zM64 224c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V288c0-35.3-28.7-64-64-64H64z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Categories</p>
                        <p>View Categories</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasSagesLink() {
            return this.hasLink('sages') || this.hasLink('sage-descriptions') || this.hasLink('sage-nominal-codes');
        },
    },
}
</script>

<style scoped>

</style>
