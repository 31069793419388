<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <template slot-scope="{logo}">
                <fetch-root-siren :linkName='linkName' :link="link" :logo="logo">
                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                    <span @click="navigateToClientUsers(response)" class="cursor-pointer">
                                        Users
                                    </span>
                                    <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span class="text-secondary-darkblue">
                                        {{response.body.properties.get('name')}}
                                    </span>
                                </div>
                                <div class="flex items-center text-secondary-darkgrey">
                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'update-user' && action.name !== 'save-as-default-project-oss-filter' && action.name !== 'add-certificate' && !action.fields.isEmpty())">
                                        <form-action class="action-with-fields" :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                        ></form-action>
                                    </div>
                                    <div class="mr-1" v-for="actionWithoutFields in response.body.actions.filter(action => action.fields.isEmpty())">
                                        <confirmation-action v-if="actionWithoutFields[0] !== 'delete' && actionWithoutFields[0] !== 'log-in-as-user'" :action="actionWithoutFields[1]"
                                                        :actionKey="actionWithoutFields[0]"
                                                         :redirectOnSuccess="true"
                                                         :onSuccessPath="$router.currentRoute.fullPath"></confirmation-action>
                                        <different-user-sign-in-action v-else-if="actionWithoutFields[0] === 'log-in-as-user'"
                                                                       :action="actionWithoutFields[1]"
                                                                       :actionKey="actionWithoutFields[0]"
                                                                       :redirectOnSuccess="true"
                                                                       :onSuccessPath="$router.currentRoute.fullPath"></different-user-sign-in-action>
                                        <confirmation-action v-else :action="actionWithoutFields[1]"
                                                             :actionKey="actionWithoutFields[0]"
                                                             :redirectOnSuccess="true"
                                                             :onSuccessPath="getLinkForClientUsersPage(response)"></confirmation-action>
                                    </div>
                                </div>
                            </div>
                        </page-header>
                        <div class="grid grid-cols-4 min-h-screen pb-12">
                            <div
                                class="col-span-4 p-6 border border-gray-200 border-b-0 border-r-0 border-l-0">

                                <user-summary ref="summary"  :response="response" @change="change" @valueNotChanged="valueNotChanged" @currentViewChanged="currentViewChanged" :holidaysUsed="holidaysUsed"></user-summary>


                                <insurance-details-table :response="response" ref="insurance"></insurance-details-table>
                                <bank-details-table :response="response" ref="bank-details"></bank-details-table>

                                <div class="generic-summary p-4 bg-white shadow sm:rounded-lg mt-8" :class="{'hidden': currentView!=='leave'}">
                                    <div class="grid grid-cols-1 gap-4">
                                        <holiday-requests-table v-show="currentView === 'leave'" :response="response" ref="holiday-requests" @totalHolidaysUsed="setHolidaysUsed"></holiday-requests-table>
                                    </div>
                                </div>

                                <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense" :class="{'hidden': currentView!=='documents'}">
                                    <div class="space-y-6">
                                        <!-- Description list-->
                                        <section>
                                            <div class="bg-white shadow sm:rounded-lg">
                                                <div class="px-4 py-5 sm:px-6 grid grid-cols-1">
                                                    <certificates v-show="currentView" class="mt-4" :response="response" ref="certificates" @change="change"></certificates>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>

                                <div class="generic-summary p-4 bg-white shadow sm:rounded-lg mt-8" :class="{'hidden': currentView!=='payslips'}">
                                    <div class="grid grid-cols-1 gap-4">
                                        <user-payslips-table v-show="currentView === 'payslips'" :response="response" ref="payslips"></user-payslips-table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </template>
                    <div v-if="hasChangesWaitingOnSave"
                         style="right:0px;left:6rem;"
                         class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                        <a-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                            User
                        </a-button>
                    </div>
                </div>

            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import UserSummary from '../../components/Summaries/UserSummary';
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import PrimaryButton from "../../components/PrimaryButton";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Certificates from "@/components/Certificates";
    import InsuranceDetailsTable from "@/components/InsuranceDetailsTable";
    import BankDetailsTable from "@/components/BankDetailsTable";
    import HolidayRequestsTable from "@/components/Tables/HolidayRequestsTable";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import Button from "@/v2/buttons/Button";
    import ConfirmationAction from "@/v2/components/ConfirmationAction";
    import DifferentUserSignInAction from "@/v2/components/DifferentUserSignInAction.vue";
    import UserPayslipsTable from "@/components/Tables/UserPayslipsTable.vue";

    export default {
        inject: ["$validator"],
        components: {
            UserPayslipsTable,
            MainTemplate,
            FetchRootSiren,
            Loading,
            PrimaryButton,
            UserSummary,
            Certificates,
            InsuranceDetailsTable,
            BankDetailsTable,
            HolidayRequestsTable,
            PageHeader,
            FormAction,
            'a-button': Button,
            ConfirmationAction,
            DifferentUserSignInAction
        },
        data() {
            return {
                hasChangesWaitingOnSave: false,
                loading:false,
                currentView: 'accounts',
                holidaysUsed: 0,
                unpaidLeave: 0
            }
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },
        },
        methods: {
            change() {
                console.log('changed');
                this.hasChangesWaitingOnSave = true;
            },
            valueNotChanged(){
                console.log('not-changed');
                this.hasChangesWaitingOnSave = false;
            },
            currentViewChanged(value){
                this.currentView = value;
                console.log(this.currentView);
            },
            async saveData() {
                this.loading = true;
                if(this.$refs.certificates && this.$refs.certificates.newCertificateAction) {
                    this.$refs.summary.update().then(res => {
                        this.$refs.certificates.updateCertificates().then(res => {
                            this.actionSuccess();
                        }).catch(error => {
                            console.log('cert error');
                            if(error.error.status !== 422) {
                                alert('There was an error processing your request.' + error.error);
                            }
                            this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                            this.loading = false;
                        });
                    }).catch(error => {
                        if(error.status !== 422) {
                            alert('There was an error processing your request.' + error.error);
                        }
                        console.log(error.response.text)
                        this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                        this.loading = false;
                    })
                }else{
                    this.$refs.summary.update().then(res => {
                        this.actionSuccess();
                    }).catch(error => {
                        if(error.status !== 422) {
                            alert('There was an error processing your request.' + error.error);
                        }
                        this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                        this.loading = false;
                    })
                }
            },
            actionSuccess(){
                this.$store.dispatch(
                    "setMessage",
                    'The user was saved successfully'
                );
                this.hasChangesWaitingOnSave = false;
                this.loading = false;
                this.$router.go(0);
            },
            setHolidaysUsed(value){
                this.holidaysUsed = value;
            },
            navigateToClientUsers(response) {
                if(response.body.links.filter(link => link.rels.contains('users-list')).first()) {
                    let selfLink = response.body.links.filter(link => link.rels.contains('users-list')).first().href;
                    let parts = selfLink.split('/api');
                    this.$router.push(parts[1]);
                }
            },
            getLinkForClientUsersPage(response){
                if(response.body.links.filter(link => link.rels.contains('users-list')).first()) {
                    let selfLink = response.body.links.filter(link => link.rels.contains('users-list')).first().href;
                    let parts = selfLink.split('/api');
                    return parts[1];
                }
            }
        },
    }
</script>

<style scoped>

</style>
