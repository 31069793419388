<template>
    <div>
        <red-button  v-if=isButton class="mr-3" :class="additionalButtonClasses" href="#" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">{{action.title}}</red-button>

        <a v-else class="cursor-pointer font-normal underline flex justify-center mr-3"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">{{action.title}}</a>
        <portal to="destination">
            <modal v-if="displayModal" :submitting="submittingModal" :title="getTitle" :confirmationString='finalConfirmationString' @removeFromDisplay="removeFromDisplay()" @perform="perform()" isNegativeAction="true"></modal>
        </portal>
    </div>
</template>

<script>
    import Button from './Button';
    import Modal from './Modal';
    import RedButton from "./RedButton";
    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                title:null,
                confirmationString: null,
                continueOnFail: '0'
            }
        },
        components: {
            RedButton,
            Modal
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            properties:{
                type: Object
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            isButton:{
                type: Boolean,
                default:true
            },
            additionalButtonClasses:{
                type:String
            }
        },
        computed: {
            finalConfirmationString() {
                if(this.confirmationString === null) {
                    var preText = '';
                    if (this.action.name === 'request-update') {
                        if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                            preText = this.properties.get('last_updated_by_name') + ' requested an update ' + this.properties.get('last_updated_by_date') + ', ';
                    }
                    return preText + `Are you sure you want to "${this.action.title}"`
                }
                return this.confirmationString;
            },
            getTitle(){
                if(this.title === null){
                    return this.action.title;
                }
                return this.title;
            },
            actionData(){
                var that = this;
                if(!this.action){
                    return {};
                }
                if(!this.action.fields){
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
        },
        methods: {
            perform() {
                this.submittingModal = true;
                this.actionData['continue_on_failure'] = this.continueOnFail;
                this.action.perform(this.actionData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                }).catch(error => {
                    if(error.status === 422){
                        this.displayModal = false;
                        this.submittingModal = false;
                        if(error.response.body.errors.hasOwnProperty('uploaded_to_sage')){
                            this.title = 'This invoice has been uploaded to sage';
                            this.confirmationString = 'Do you want to continue?';
                            this.displayModal = true;
                            this.continueOnFail = '1';
                        }else{
                            alert('This invoice has a linked sales invoice, please remove it from the sales invoice first.');
                        };
                    }else{
                        alert('There was an error processing your request.' + error);
                    }

                });
            },
            messages(response) {
            return response.body.entities
                .filter(function(entity) {
                return entity.rels.contains("status-messages");
                })
                .first();
            },
            removeFromDisplay(){
                this.displayModal = false;
                this.title = null;
                this.confirmationString = null;
                this.continueOnFail = '0';
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
