<template>
    <main-template>

        <template slot-scope="{logo}">
            <div>
                <template>
                    <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">
                        <div slot-scope="{ response, logo }">
                            <loading v-if="!response"></loading>
                            <template v-else>
                                <page-header :logo="logo">
                                    <div class="md:flex justify-between w-full">
                                        <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                            Clients
                                            <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span @click="navigateToClient(response)" class="cursor-pointer">
                                                {{response.body.properties.get('name')}}
                                            </span>
                                            <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span @click="navigateToBookkeeping(response)" class="cursor-pointer">
                                                Bookkeeping
                                            </span>
                                            <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span class="text-secondary-darkblue">
                                            Download Report
                                            </span>

                                        </div>
                                    </div>
                                </page-header>
                                <client-order-reports-form v-if="response" :response="response"></client-order-reports-form>
                            </template>
                        </div>
                    </fetch-siren-root>
                </template>
            </div>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import ClientOrderReportsForm from "@/v2/components/forms/ClientOrderReportsForm";
    export default {
    name: "ExportOrders",
        components: {
            FormAction,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader,
            ClientOrderReportsForm
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {
            navigateToClient(response) {
                let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            },
            navigateToBookkeeping(response) {
                let selfLink = response.body.links.filter(link => link.rels.contains('bookkeeping')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            },
        }
    }

</script>

<style scoped>

</style>
