<template>
    <paginated-list :response="response" actionBaseUrl="/manufacturers">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Name</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Actions</th>
        </template>
        <template v-slot:noItems>
            No manufacturers found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in manufacturers" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('name') }}
                    </div>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>
                        <div class="mr-1" v-for="actionWithFields in entity.entity.actions.filter(action => action.name !== 'filter')">
                            <form-action class="action-with-fields" :action="actionWithFields[1]"
                                         :actionKey="actionWithFields[0]"
                                         :redirectOnSuccess="false"
                            ></form-action>
                        </div>
                    </div>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
export default {
    name: "ManufacturerPaginatedList",
    components:{
        ConfirmationAction,
        PaginatedList,
        StatusBadge,
        OrderTabs,
        FormAction
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        manufacturers() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('manufacturer'));
        },
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
