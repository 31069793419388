<template>
    <main-template>
        <template v-slot:default="slotProps">
            <page-header :logo="slotProps.logo">QA Manager</page-header>
            <q-a-submenu-nav :links="slotProps.links">

            </q-a-submenu-nav>
            <div class="flex w-full px-4 sm:px-6 md:px-4" v-if="res && (hasLink('qa-trackers', slotProps.links) || hasLink('create-qa', res.body.links))">

                <div class="md:flex justify-start w-full items-center mx-auto md:px-4">
                    <div class="flex items-center text-secondary-darkgrey" v-if="hasLink('create-qa', res.body.links)">
                        <a-button class="mr-3" v-if="res && hasLink('create-qa', res.body.links) && !res.body.actions.filter(action => action.name === 'can-not-create-qa-on-hub').first()" @click.native="goToCreateQaPage()">Create New QA</a-button>
                        <template class="mr-3" v-if="res && res.body.actions.filter(action => action.name === 'can-not-create-qa-on-hub').first()">
                            {{res.body.actions.filter(action => action.name === 'can-not-create-qa-on-hub').first().title}}
                        </template>
                        <a-button class="mr-3" v-if="res && hasLink('create-pre-check', res.body.links)" @click.native="goToCreatePreCheckPage()">Create New Pre-Check</a-button>

                    </div>
                </div>

                <div class="md:flex justify-end w-full items-center">

                <div class="flex items-center text-secondary-darkgrey">

                    <div class="mr-1" v-for="actionWithFields in res.body.actions.filter(action => action.name !== 'filter')">
                        <create-qa-tracker-form v-if="actionWithFields[0] === 'create-qa-tracker'"
                                                :action="actionWithFields[1]"
                                                :actionKey="actionWithFields[0]"
                                                :redirectOnSuccess="true"
                                                onSuccessPath="/qa-tools"
                                                @success="success(actionWithFields[0])"
                        ></create-qa-tracker-form>
                        <form-action v-else class="action-with-fields" :action="actionWithFields[1]"
                                     :actionKey="actionWithFields[0]"
                                     :redirectOnSuccess="true"
                                     :onSuccessPath="$router.currentRoute.fullPath"
                        >test</form-action>
                    </div>
                </div>
            </div>
            </div>
            <qa-tracker-paginated-list v-if="res && hasLink('qa-trackers', slotProps.links)" :response="res"></qa-tracker-paginated-list>
        </template>
    </main-template>
</template>

<script>
import MainTemplate from "@/v2/templates/MainTemplate";
import PageTitle from "@/common/PageTitle";
import PageHeader from "@/v2/components/Header";
import OfficeSubmenuNav from "@/v2/components/OfficeSubmenuNav";
import ManagementSubmenuNav from "@/v2/components/ManagementSubmenuNav";
import QASubmenuNav from "@/v2/components/QASubmenuNav";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import QaTrackerPaginatedList from "@/v2/paginatedLists/QaTrackerPaginatedList";
import CreateQaTrackerForm from "@/v2/components/forms/CreateQaTrackerForm";
import Button from "@/v2/buttons/Button";
import FormAction from "@/v2/components/FormAction.vue";

export default {
    name: "RentalItems",
    components: {
        QASubmenuNav,
        ManagementSubmenuNav,
        OfficeSubmenuNav,
        PageTitle,
        MainTemplate,
        PageHeader,
        QaTrackerPaginatedList,
        CreateQaTrackerForm,
        'a-button': Button,
        FormAction
    },
    data(){
        return {
           res: null
        }
    },

    mounted() {
        let follow = Siren.Helper.follow;
        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
        Siren.get(process.env.VUE_APP_API_URL + "/api/qa-trackers" + window.location.search)
            //.then(follow("qa-trackers"))
            .then(res => {
                let siren = res.body;
                this.res = res;
                this.loading = false;
            }, this);
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
    },
    methods: {
        hasLink(key, links){
            if(links) {
                return links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        goToCreateQaPage() {
            return this.$router.push('quality-assessments/create');
        },
        goToCreatePreCheckPage(){
            return this.$router.push('pre-checks/create');
        },
    }
}

</script>

<style scoped>

</style>
