<template>
    <div class="rounded-md flex-grow">
        <div class="focus-within:z-10">
            <label for="email" class="block text-sm font-medium leading-5 text-gray-700 mt-3">{{field.title}}<span v-if="isOptional" class="text-gray-400"> (Optional)</span><span class="text-red-500 ml-1" v-if="required">*</span></label>
            <div>
                <div class="rate">
                    <input type="radio" id="star5" name="rate" value="5" v-model="propsVal"/>
                    <label for="star5" title="text">5 stars</label>
                    <input type="radio" id="star4" name="rate" value="4" v-model="propsVal"/>
                    <label for="star4" title="text">4 stars</label>
                    <input type="radio" id="star3" name="rate" value="3" v-model="propsVal"/>
                    <label for="star3" title="text">3 stars</label>
                    <input type="radio" id="star2" name="rate" value="2" v-model="propsVal"/>
                    <label for="star2" title="text">2 stars</label>
                    <input type="radio" id="star1" name="rate" value="1" v-model="propsVal"/>
                    <label for="star1" title="text">1 star</label>
                </div>
                <div class="clear-both"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {},
            field: {
                type: Object
            },
            validationFieldName: {
                type: String,
                default: 'random'
            },
            dependsOn: {
                type: String,
                default: null
            },
            dependsOnValue: {
                default: null
            },
            isOptional: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                propsVal: this.value
            }
        },
        computed: {
            filteredOptions() {
                if (this.dependsOn !== null && this.dependsOnValue === null) {
                    return;
                }

                if (this.dependsOn === null || this.dependsOnValue === null) {
                    return this.field.options;
                }

                let options = this.field.options;
                return options.filter(function (option) {
                    return option[this.dependsOn] !== null && (option[this.dependsOn]).toString() === (this.dependsOnValue).toString()
                }.bind(this));
            },
            isDisabled() {
                return this.dependsOn !== null && this.dependsOnValue === null;
            },
            required: function(){
                if(this.field.validation !== null && this.field.validation !== undefined) {
                    return this.field.validation.includes('required');
                }
                return false;
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    console.log(value);
                    this.$emit("input", value);
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>
    .rate {
        float: left;
        height: 46px;
        padding: 0 10px;
    }
    .rate:not(:checked) > input {
        position:absolute;
        top:-9999px;
    }
    .rate:not(:checked) > label {
        float:right;
        width:1em;
        overflow:hidden;
        white-space:nowrap;
        cursor:pointer;
        font-size:30px;
        color:#ccc;
    }
    .rate:not(:checked) > label:before {
        content: '★ ';
    }
    .rate > input:checked ~ label {
        color: #ffc700;
    }
    .rate:not(:checked) > label:hover,
    .rate:not(:checked) > label:hover ~ label {
        color: #deb217;
    }
    .rate > input:checked + label:hover,
    .rate > input:checked + label:hover ~ label,
    .rate > input:checked ~ label:hover,
    .rate > input:checked ~ label:hover ~ label,
    .rate > label:hover ~ input:checked ~ label {
        color: #c59b08;
    }
</style>
