<template>
    <div class="order-items">
        <div class="md:flex justify-between w-full items-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Pre-Priced Items</h3>
        </div>
        <div class="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">
            <div class="grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 xl:gap-x-8">
                <span class="col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-8 inline-flex items-center text-secondary-lightblue">
                    <span @click="resetCategories(false)" class="cursor-pointer">
                        Categories
                    </span>
                    <template v-if="currentParentCategoryId && searchingAllProducts === false">
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span @click="setCategory(currentParentCategoryId, currentParentCategoryName); setParentCategory(null,null); setType(null, null)" class="cursor-pointer">
                            {{ currentParentCategoryName }}
                        </span>
                    </template>

                    <template v-if="categoryId && searchingAllProducts === false">
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <span class="cursor-pointer" :class="{'text-secondary-darkblue': currentTypeId === null}" @click="setType(null,null)">
                            {{categoryName}}
                        </span>
                    </template>
                    <template v-if="currentTypeId && searchingAllProducts === false">
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <span class="text-secondary-darkblue">
                            {{currentTypeName}}
                        </span>
                    </template>
                    <template v-if="searchingAllProducts === true">
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <span class="text-secondary-darkblue">
                            Product Search
                        </span>
                    </template>
                </span>
                <span class="col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-8 items-center" :class="{'hidden': searchingAllProducts || currentTypeId !== null}">
                    <pre-defined-item-filters :reloadPage="false" ref="topFilters" @performAction="performAction" v-if="filterAction" :response="response" :action="filterAction" @searchProducts="searchingAllProducts=true" :usePaddings="false"></pre-defined-item-filters>
                </span>
                <template v-for="(product,index) in categories" v-if="!searchingAllProducts">
                    <a v-if="categoryId === null" class="group cursor-pointer" :key="index" @click="setCategory(product.entity.properties.get('id'), product.entity.properties.get('name')); setParentCategory(null, null)">
                        <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                            <img v-if="product.entity.properties.get('image')" :src="product.entity.properties.get('image')" :alt="product.entity.properties.get('image') ? product.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />
                            <img v-else src="../../images/no-image-available.png" :alt="product.entity.properties.get('image') ? product.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />

                        </div>
                        <h3 class="mt-4 text-normal text-center text-gray-700">{{ product.entity.properties.get('name') }}</h3>
                    </a>
                    <template v-for="subCategory in subCategories.filter(sub => sub.entity.properties.get('category_id') === product.entity.properties.get('id'))">
                        <a v-if="categoryId === product.entity.properties.get('id')" class="group cursor-pointer" @click="setCategory(subCategory.entity.properties.get('id'),subCategory.entity.properties.get('name')); setParentCategory(product.entity.properties.get('id'), product.entity.properties.get('name'))">
                            <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                <img v-if="subCategory.entity.properties.get('image')" :src="subCategory.entity.properties.get('image')" :alt="subCategory.entity.properties.get('image') ? subCategory.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />
                                <img v-else src="../../images/no-image-available.png" :alt="subCategory.entity.properties.get('image') ? subCategory.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />

                            </div>
                            <h3 class="mt-4 text-normal text-center text-gray-700">{{ subCategory.entity.properties.get('name') }}</h3>
                        </a>
                        <template v-for="type in types.filter((type) => type.entity.properties.get('category_id') === subCategory.entity.properties.get('id'))">
                            <a v-if="categoryId === type.entity.properties.get('category_id') && currentTypeId === null" class="group cursor-pointer" @click="setType(type.entity.properties.get('id'), type.entity.properties.get('name'))">
                                <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img v-if="type.entity.properties.get('image')" :src="type.entity.properties.get('image')" :alt="type.entity.properties.get('image') ? type.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />
                                    <img v-else src="../../images/no-image-available.png" :alt="type.entity.properties.get('image') ? type.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />

                                </div>
                                <h3 class="mt-4 text-normal text-center text-gray-700">{{ type.entity.properties.get('name') }}</h3>
                            </a>
                        </template>
                    </template>
                </template>
                <template v-if="!searchingAllProducts && categories.count() === 0">
                    <span class="col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-8">
                        No Categories Found.
                    </span>
                </template>
                <template v-if="!searchingAllProducts && categoryId && !currentParentCategoryId && subCategories.filter(sub => sub.entity.properties.get('category_id') === categoryId).count() === 0">
                    <span class="col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-8">
                        No Sub Categories Found.
                    </span>
                </template>
                <template v-if="!searchingAllProducts && categoryId && currentParentCategoryId && types.filter((type) => type.entity.properties.get('category_id') === categoryId).count() === 0">
                    <span class="col-span-2 sm:col-span-3 lg:col-span-4 xl:col-span-8">
                        No Types Found.
                    </span>
                </template>
            </div>
            <template v-for="type in types" v-if="!searchingAllProducts">
                <template v-if="currentTypeId === type.entity.properties.get('id')">
                    <div class="py-4 text-left">
                        <div class="w-1/4 lg:w-1/6 float-left">
                            <div class=" aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                <img v-if="type.entity.properties.get('image')" :src="type.entity.properties.get('image')" :alt="type.entity.properties.get('image') ? type.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />
                                <img v-else src="../../images/no-image-available.png" :alt="type.entity.properties.get('image') ? type.entity.properties.get('name'): null" class="h-full w-full object-contain object-center group-hover:opacity-75" />
                            </div>
                        </div>
                        <dl class="w-3/4 grid grid-cols-1 gap-x-4 gap-y-4 pl-4 float-left">
                            <div>
                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                    Name
                                </dt>
                                <dd class="text-sm text-black font-bold">{{ type.entity.properties.get('name') }}</dd>
                            </div>

                            <div>
                                <dt class="text-sm font-normal text-gray-500">Description</dt>
                                <dd class="text-sm font-bold text-black">
                                    {{ type.entity.properties.get('description') }}
                                </dd>

                            </div>
                        </dl>
                        <div class="clear-both"></div>
                    </div>
                </template>
            </template>
        </div>
        <div class="inline-flex  w-full overflow-auto">
<!--            <div class="w-1/4 ml-4 mr-4">-->
<!--                <nav v-if="categories.count() > 0" class="isolate flex flex-col divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">-->
<!--                    <template v-for="category, key in categories">-->
<!--                        <a href="#" @click="categoryId = category.entity.properties.get('id'); currentParentCategoryId = null" class=" cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-left text-sm font-medium hover:bg-gray-50 focus:z-10" :class="{'text-gray-900': categoryId === category.entity.properties.get('id'), 'text-gray-500 hover:text-gray-700': categoryId !== category.entity.properties.get('id')}">-->
<!--                            <span class="flex justify-between items-center">{{category.entity.properties.get('name')}}-->
<!--                            <svg v-if="currentParentCategoryId === category.entity.properties.get('id') || categoryId === category.entity.properties.get('id')" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">&lt;!&ndash;! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. &ndash;&gt;<path d="M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z"/></svg>-->
<!--                            <svg v-else xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">&lt;!&ndash;! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. &ndash;&gt;<path d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"/></svg>-->

<!--                            </span>-->

<!--                            <span v-if="categoryId === category.entity.properties.get('id')" aria-hidden="true" class="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"></span>-->

<!--                            <span v-else aria-hidden="true" class="bg-gray-200 absolute inset-x-0 bottom-0 h-0.5"></span>-->
<!--                        </a>-->
<!--                        <template v-for="subCategory in subCategories.filter(sub => sub.entity.properties.get('category_id') === category.entity.properties.get('id'))">-->
<!--                            <order-sub-categories :category="subCategory" :category-id="categoryId"-->
<!--                                                  :parentCategoryId="category.entity.properties.get('id')"-->
<!--                                                  :isVisible="currentParentCategoryId === category.entity.properties.get('id') || categoryId === category.entity.properties.get('id')"-->
<!--                                                  :sub-categories="subCategories" @categoryChanged="categoryChanged"-->
<!--                                                  :types="types" @typeIdChanged="typeChanged" :currentTypeId="currentTypeId"-->
<!--                            />-->
<!--                        </template>-->

<!--                    </template>-->
<!--                </nav>-->
<!--                <nav v-else class="isolate flex flex-col divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">-->
<!--                    <span href="#" class=" cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-left text-sm font-medium hover:bg-gray-50 focus:z-10">-->
<!--                        <span class="flex justify-between items-center">No categories found.</span>-->
<!--                    </span>-->
<!--                </nav>-->
<!--            </div>-->

            <div class="flex flex-col w-full" :class="{'hidden': currentTypeId === null && !searchingAllProducts}">
                <div class=" py-2 overflow-x-auto md:overflow-visible  sm:px-6  lg:px-8">
                    <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
                        <div class="bg-white">
                            <slot name="filters">
                                <pre-defined-item-filters ref="filters" :reloadPage="false" @performAction="performAction" v-if="filterAction" :response="response" :action="filterAction"></pre-defined-item-filters>
                            </slot>

                        </div>
                        <table class="min-w-full">
                            <thead>
                            <tr>
                                <th style="width:100%;"
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Item
                                </th>
                                <th
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Manufacturer
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Due Date
                                </th>
                                <th class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Qty
                                </th>
                                <th v-if="isDelivered" class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Delivered Qty
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Unit
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="category, key in categories">
                                    <template v-for="orderItemEntity, key in orderItemEntities.filter(entity => entity.entity.properties.get('category_id') === category.entity.properties.get('id') && !entity.entity.properties.get('is_deleted') )">

                                        <predefined-item-row @change="change" :ref="'preDefinedItemRow'" :orderItemEntity="orderItemEntity" :searchAllProducts="searchingAllProducts"
                                                        :scope="'pre-defined-item-row-'+key" :response="response" :currentCategoryId="categoryId" :manufacturerFilter="filteredManufacturers" :filteredQuery="filteredQuery"></predefined-item-row>
                                    </template>
                            </template>
                            <template v-for="category, key in subCategories">
                                    <template v-for="orderItemEntity, key in orderItemEntities.filter(entity => entity.entity.properties.get('category_id') === category.entity.properties.get('id') && !entity.entity.properties.get('is_deleted'))">
                                        <predefined-item-row @change="change" :ref="'preDefinedItemRow'" :orderItemEntity="orderItemEntity" :searchAllProducts="searchingAllProducts"
                                                             :scope="'pre-defined-item-row-'+key" :response="response" :currentCategoryId="categoryId" :currentTypeId="currentTypeId" :manufacturerFilter="filteredManufacturers" :filteredQuery="filteredQuery"></predefined-item-row>
                                    </template>
                            </template>
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center" :class="{'hidden' : orderItemEntities.filter(entity => (searchingAllProducts || (entity.entity.properties.get('category_id') === categoryId && !entity.entity.properties.get('is_deleted') && entity.entity.properties.get('type_id') === currentTypeId)) && (filteredManufacturers === null || (entity.entity.properties.get('manufacturer_id') !== null && filteredManufacturers.split(',').includes(entity.entity.properties.get('manufacturer_id').toString()))) && (filteredQuery === null || entity.entity.properties.get('product').toLowerCase().includes(filteredQuery.toLowerCase()))).count() !== 0}">
                                <slot name="noItems">No products found for this category.</slot>
                            </td>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PredefinedItemRow from "@/components/PredefinedItemRow.vue";
import OrderSubCategories from "@/components/OrderSubCategories.vue";
import OrderFilters from "@/v2/filters/OrderFilters.vue";
import PreDefinedItemFilters from "@/v2/filters/PreDefinedItemFilters.vue";

export default {
        inject: ["$validator"],
        components: {
            PreDefinedItemFilters,
            OrderFilters,
            OrderSubCategories,
            PredefinedItemRow
        },
        props: {
            response: {
                type: Object,
            },
        },
        data(){
            return {
                'categoryId': null,
                'currentParentCategoryId': null,
                'currentTypeId': null,
                'categoryName': null,
                'currentParentCategoryName': null,
                'currentTypeName': null,
                'defaultTypeId': null,
                'defaultTypeName': null,
                'defaultCategoryId': null,
                'defaultCategoryName': null,
                'defaultParentCategoryId': null,
                'defaultParentCategoryName': null,
                'defaultManufacturerId': null,
                'filteredManufacturers': null,
                'filteredQuery': null,
                'searchingAllProducts': false
            }
        },
        created(){
          // if(this.categories && this.categories.first()){
          //     this.categoryId = this.categories.first().entity.properties.get('id');
          // }
            if(this.response.body.properties.get('default_type_id')) {
                let currentType = this.types.filter((type) => {
                    console.log(type.entity.properties.get('id'));
                    return type.entity.properties.get('id') === this.response.body.properties.get('default_type_id');
                }).first();
                if(currentType){
                    this.currentTypeId = currentType.entity.properties.get('id');
                    this.currentTypeName = currentType.entity.properties.get('name');
                    if(currentType.entity.properties.get('category_id')) {
                        let currentCategory = this.subCategories.filter(subCategory => {
                            return subCategory.entity.properties.get('id') === currentType.entity.properties.get('category_id');
                        }).first();
                        if(currentCategory) {
                            this.categoryId = currentCategory.entity.properties.get('id');
                            this.categoryName = currentCategory.entity.properties.get('name');
                            if(currentCategory.entity.properties.get('category_id')) {
                                let currentParentCategory = this.categories.filter(category => {
                                    return category.entity.properties.get('id') === currentCategory.entity.properties.get('category_id');
                                }).first();
                                if (currentParentCategory) {
                                    this.currentParentCategoryId = currentParentCategory.entity.properties.get('id');
                                    this.currentParentCategoryName = currentParentCategory.entity.properties.get('name');
                                }
                            }
                        }
                    }
                    this.defaultTypeId = this.currentTypeId;
                    this.defaultTypeName = this.currentTypeName;
                    this.defaultCategoryId = this.categoryId;
                    this.defaultCategoryName = this.categoryName;
                    this.defaultParentCategoryId = this.currentParentCategoryId;
                    this.defaultParentCategoryName = this.currentParentCategoryName;
                }
            }
            if(this.response.body.properties.get('default_manufacturer_id') !== null) {
                this.defaultManufacturerId = this.response.body.properties.get('default_manufacturer_id').toString();
                this.filteredManufacturers = this.defaultManufacturerId;
            }
        },
        computed: {
            orderItemEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('product'));
            },
            categories() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('category'));
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update').first();
            },
            filterAction() {
                return this.response.body.actions.filter(action => action.name === 'filter-products').first();
            },
            getSplitOrderAction(){
                return this.response.body.actions
                    .filter(action =>
                        action.name === 'split-order'
                    ).first()
            },
            subCategories() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('sub-category'));
            },
            types() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('product-type'));
            },
        },
        methods: {
            async updateItems() {
                let updateItemPromises = [];

                if (this.$refs.preDefinedItemRow) {
                    for (const row of this.$refs.preDefinedItemRow){
                        updateItemPromises.push(await row.update().catch(error => {
                            throw {
                                error: error,
                                scope: 'pre-defined-item-row-' + 1
                            };
                        }));
                    };
                }
                return await Promise.all(updateItemPromises);
            },
            change() {
                this.$emit('change');
            },
            updateRow(row){
                return row.update().catch(error => {
                    throw {
                        error: error,
                        scope: 'pre-defined-item-row-' + index
                    };
                });
            },
            categoryChanged(value){
                this.categoryId = value[0];
                this.currentParentCategoryId = value[1];
            },
            typeChanged(value){
                this.currentTypeId = value;
            },
            resetCategories(useDefault){
                if(useDefault === true){
                    this.categoryId = this.defaultCategoryId;
                    this.categoryName = this.defaultCategoryName;
                    this.currentParentCategoryId = this.defaultParentCategoryId;
                    this.currentParentCategoryName = this.defaultParentCategoryName;
                    this.currentTypeId = this.defaultTypeId;
                    this.currentTypeName = this.defaultTypeName;
                }else {
                    this.categoryId = null;
                    this.categoryName = null;
                    this.currentParentCategoryId = null;
                    this.currentParentCategoryName = null;
                    this.currentTypeId = null;
                    this.currentTypeName = null
                }
                this.resetSearch();
            },
            resetSearch(){
                this.searchingAllProducts = false;
                const data = [];
                data['filter[query]'] = '';
                data['filter[manufacturer_id]'] = '';
                this.$refs.filters.updateActionData(data);
                this.$refs.topFilters.updateActionData(data);
            },
            setCategory(id, name){
                this.categoryId = id;
                this.categoryName = name;
                this.resetSearch();
            },
            setParentCategory(id, name){
                this.currentParentCategoryId = id;
                this.currentParentCategoryName = name;
                this.resetSearch();
            },
            setType(id, name){
                this.currentTypeId = id;
                this.currentTypeName = name;
                this.resetSearch();
            },
            performAction(data){
                if(data['filter[manufacturer_id]'] === ''){
                    this.filteredManufacturers = null;
                }else{
                    this.filteredManufacturers = data['filter[manufacturer_id]']
                }

                if(data['filter[query]'] === ''){
                    this.filteredQuery = null;
                }else{
                    this.filteredQuery = data['filter[query]'];
                }
                console.log(data);
                this.$refs.filters.updateActionData(data);
                this.$refs.topFilters.updateActionData(data);
            }
        },
    }
</script>

<style lang="scss">
    .order-items label {
        display: none;
    }

    .table-container-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        background-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -moz-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -ms-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -o-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
    }
</style>
