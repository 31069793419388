<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">RFIs</h2>
                <span class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty())"
                         class="mr-1">
                        <action-with-form onSuccessPath="rfis" :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"></action-with-form>
                    </div>
                    <div v-for="actionWithoutFields in response.body.actions.filter(action => action.fields.isEmpty())" class="mr-1">
                        <action-with-confirmation :action="actionWithoutFields[1]" :actionKey="actionWithoutFields[0]" :properties="response.body.properties"></action-with-confirmation>
                    </div>
                </span>
            </div>
        </div>
    </header>
</template>

<script>
    import ActionWithForm from "./ActionWithForm";
    import ActionWithConfirmation from "./ActionWithConfirmation";
    export default {
        components: {ActionWithForm, ActionWithConfirmation},
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'filter');
            },
        }
    }
</script>

<style scoped>

</style>
