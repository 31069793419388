<template>
  <div >
  <notification :message="message"></notification>
    <on-site-id-top-header-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <div v-else>
                        <div class="container mx-auto px-4 sm:px-6 lg:px-8">
                            <div class="grid grid-cols-1 sm:grid-cols-3">
                                <div v-if="hasLink(response,'profile')" class=" mt-8 max-h-100 p-4 w-full">
                                    <div class="mx-auto container">
                                        <a-button class="w-full" @click.native="navigateToProfile(response)">Profile</a-button>
                                    </div>
                                </div>
                                <div v-if="hasLink(response,'my-on-site-id')" class=" mt-8 max-h-100 p-4 w-full">
                                    <div class="mx-auto container">
                                        <a-button class="w-full" @click.native="navigateToMyOnSiteId(response)">My On Site ID</a-button>
                                    </div>
                                </div>
                                <div v-if="hasLink(response,'employer-settings')"  class=" mt-8 max-h-100 p-4 w-full">
                                    <div class="mx-auto container">
                                        <a-button class="w-full" @click.native="navigateToEmployerSettings(response)">Employer Settings</a-button>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-1">
                                <div class=" mt-8 max-h-100 p-4 w-full">
                                    <div class="mx-auto container">
                                        <a-button class="w-full" @click.native="logout" colourType="negative">Log Out</a-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fetch-siren-root>
        </template>
    </on-site-id-top-header-template>
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import { mapActions, mapGetters } from "vuex";
import Siren from "super-siren";
import TopHeaderTemplate from "@/templates/TopHeaderTemplate.vue";
import FetchSirenRoot from "@/components/FetchSirenRoot.vue";
import Loading from "@/components/Loading.vue";
import OnSiteIdTopHeaderTemplate from "@/templates/OnSiteIdTopHeaderTemplate.vue";
import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";
import Button from "@/v2/buttons/Button.vue";
import AssignOnSiteIdAction from "@/v2/components/AssignOnSiteIdAction.vue";

export default {
  components: {
      AssignOnSiteIdAction,
      'a-button': Button,
      ConfirmationAction, OnSiteIdTopHeaderTemplate, Loading, FetchSirenRoot, TopHeaderTemplate, Notification },

  data() {
    return {
    };
  },
  computed: {
      linkName() {
          return null;
      },
      link(){
          // if(Object.keys(this.$route.query).length === 0){
          //     return '/orders';
          // }
          return this.$route.fullPath.replace('/v3','')
      },
    ...mapGetters({
         accessToken: "getAccessToken",
        message: "getMessage",
        messageWithoutTimeout: "getMessageWithoutTimeout"
    })
  },
  mounted() {
    // if (this.accessToken !== null) {
    //   Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
    //   return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
    //     this.$router.push({ path: "/" });
    //   });
    // }
  },

  methods: {
      hasLink(response, linkName){
          return response.body.links.filter(link => link.rels.contains(linkName)).first();
      },
      navigateToProfile(response) {
          let selfLink = response.body.links.filter(link => link.rels.contains('profile')).first().href;
          let parts = selfLink.split('/api');
          this.$router.push(parts[1]);
      },
      navigateToMyOnSiteId(response) {
          let selfLink = response.body.links.filter(link => link.rels.contains('my-on-site-id')).first().href;
          let parts = selfLink.split('/api');
          this.$router.push(parts[1]);
      },
      navigateToEmployerSettings(response) {
          let selfLink = response.body.links.filter(link => link.rels.contains('employer-settings')).first().href;
          let parts = selfLink.split('/api');
          this.$router.push(parts[1]);
      },
      logout() {
          this.$store.dispatch("logout").then(() => this.$router.push("/osid-login"));
      },
  },
};
</script>

<style>
body{
    background-color:red
}
*{
    margin:0;
}
</style>
