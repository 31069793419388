<template>
  <div>
    <main-template>
        <page-title title="Search for Take Offs"></page-title>
        <div class="container mx-auto px-4 mx-auto">
            <take-off-search-form v-if="action" :action="action"></take-off-search-form>
        </div>
    </main-template>
  </div>
</template>

<script>
import PageTitle from '../common/PageTitle';
import { mapGetters } from "vuex";
import Siren from 'super-siren';
import ActionHelper from '../mixins/Siren/ActionHelper';
import MainTemplate from "../templates/MainTemplate";
import TakeOffSearchForm from "../components/CustomForms/TakeOffSearchForm";
let follow = Siren.Helper.follow;
export default {
  mixins: [ActionHelper],
  name: "TakeOffSearch",
  components: {
    PageTitle,
      MainTemplate,
      TakeOffSearchForm
  },
  data() {
    return {
      action: null
    }
  },
  computed: {
    ...mapGetters({
      accessToken: "getAccessToken"
    })
  },
  mounted () {
    Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
      Siren.get(process.env.VUE_APP_API_URL + "/api/")
        .then(follow("take-offs"))
        .then(res => {
          let siren = res.body;
          this.action = siren.actions.filter(action => action.name === 'filter').get('filter');
          console.log(this.action);
        }, this);

  },
};
</script>
