import { render, staticRenderFns } from "./NewAddPriceOrderItem.vue?vue&type=template&id=82cd14b4&scoped=true&"
import script from "./NewAddPriceOrderItem.vue?vue&type=script&lang=js&"
export * from "./NewAddPriceOrderItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82cd14b4",
  null
  
)

export default component.exports