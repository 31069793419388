<template>
    <div>
        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="$emit('removeFromDisplay')" @perform="perform()">

            <component v-if="getField('make')" :is="getFieldComponent(getField('make'))" :field="getField('make')" v-model="formData[getField('make').name]">
            </component>
            <div v-show="errors.has(getField('make').name)" class="text-red-600">{{ errors.first(getField('make').name) }}</div>

            <component v-if="getField('model')" :is="getFieldComponent(getField('model'))" :field="getField('model')" v-model="formData[getField('model').name]">
            </component>
            <div v-show="errors.has(getField('model').name)" class="text-red-600">{{ errors.first(getField('model').name) }}</div>

            <component v-if="getField('license_number')" :is="getFieldComponent(getField('license_number'))" :field="getField('license_number')" v-model="formData[getField('license_number').name]">
            </component>
            <div v-show="errors.has(getField('license_number').name)" class="text-red-600">{{ errors.first(getField('license_number').name) }}</div>

            <component v-if="getField('mileage')" :is="getFieldComponent(getField('mileage'))" :field="getField('mileage')" v-model="formData[getField('mileage').name]">
            </component>
            <div v-show="errors.has(getField('mileage').name)" class="text-red-600">{{ errors.first(getField('mileage').name) }}</div>

            <component v-if="getField('current_keeper_id')" :is="getFieldComponent(getField('current_keeper_id'))" :field="getField('current_keeper_id')" v-model="formData[getField('current_keeper_id').name]">
            </component>
            <div v-show="errors.has(getField('current_keeper_id').name)" class="text-red-600">{{ errors.first(getField('current_keeper_id').name) }}</div>

            <component v-if="getField('tax_expiry_date')" :is="getFieldComponent(getField('tax_expiry_date'))" :field="getField('tax_expiry_date')" v-model="formData[getField('tax_expiry_date').name]">
            </component>
            <div v-show="errors.has(getField('tax_expiry_date').name)" class="text-red-600">{{ errors.first(getField('tax_expiry_date').name) }}</div>

            <component v-if="getField('mot_expiry_date')" :is="getFieldComponent(getField('mot_expiry_date'))" :field="getField('mot_expiry_date')" v-model="formData[getField('mot_expiry_date').name]">
            </component>
            <div v-show="errors.has(getField('mot_expiry_date').name)" class="text-red-600">{{ errors.first(getField('mot_expiry_date').name) }}</div>

            <component v-if="getField('private_use')" :is="getFieldComponent(getField('private_use'))" :field="getField('private_use')" v-model="formData[getField('private_use').name]">
            </component>
            <div v-show="errors.has(getField('private_use').name)" class="text-red-600">{{ errors.first(getField('private_use').name) }}</div>

            <component v-if="getField('notes')" :is="getFieldComponent(getField('notes'))" :field="getField('notes')" v-model="formData[getField('notes').name]">
            </component>
            <div v-show="errors.has(getField('notes').name)" class="text-red-600">{{ errors.first(getField('notes').name) }}</div>

            <images-input v-if="getField('images')" :action="action" v-model="formData[getField('images').name]"></images-input>
            <div v-show="errors.has(getField('images').name)" class="text-red-600">{{ errors.first(getField('images').name) }}</div>
        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import ImagesInput from "@/components/Action/Fields/ImagesInput";

export default {
    name: "UpdateVehicleForm",
    data() {
        return {
            submittingModal: false
        }
    },
    components: {
        SlideOver,
        'a-button': Button,
        ImagesInput
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        formData() {
            console.log(this.action);
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        }
    },
}
</script>

<style scoped>

</style>
