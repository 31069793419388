<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" v-if="paginationProperties && paginationLinks && paginationProperties.lastPage !== 1 && paginationProperties.links">
        <div class="flex-1 flex justify-between sm:hidden">
            <a v-if="paginationLinks.prev" @click="navigate(paginationLinks.prev)" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                Previous
            </a>
            <a v-if="paginationLinks.next" @click="navigate(paginationLinks.next)" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                Next
            </a>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:flex-row-reverse sm:items-center sm:justify-between">

            <div>
                <nav class="relative z-0 inline-flex shadow-sm -space-x-px cursor-pointer" aria-label="Pagination">
                    <template v-for="(link, index) in paginationProperties.links">
                        <a v-if="index === 0 && paginationProperties.currentPage !== 1" @click="navigate(link.url)"  class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span class="sr-only">Previous</span>
                            <!-- Heroicon name: chevron-left -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                        </a>
                        <a v-else-if="index === paginationProperties.links.length - 1 && paginationProperties.currentPage !== paginationProperties.lastPage" @click="navigate(link.url)" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span class="sr-only">Next</span>
                            <!-- Heroicon name: chevron-right -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </a>
                        <a v-else-if="index !== 0 && index !== paginationProperties.links.length - 1"
                            class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                            tabindex="0"
                            @click="navigate(link.url)"
                           :class="{
                                'rounded-l-md':index === 1 &&  paginationProperties.currentPage === 1,
                                'rounded-r-md': index === paginationProperties.links.length - 2 && paginationProperties.currentPage === paginationProperties.lastPage
                            }"
                        >{{link.label}}</a>
                    </template>
                </nav>
            </div>
        </div>



    </div>
</template>

<script>
import Siren from "super-siren";
import { mapActions, mapGetters } from "vuex";
let follow = Siren.Helper.follow;
export default {
  name: "pagination",
  props: ["response"],
  computed: {
    paginationProperties() {
      if(!this.response){
        return null
      }

      this.from = this.response.body.properties.get("from");
      this.to = this.response.body.properties.get("to");
      this.total = this.response.body.properties.get("total");
      this.currentPage = this.response.body.properties.get("current_page");
      this.lastPage = this.response.body.properties.get("last_page");
      this.links = this.response.body.properties.get("links");

      return {
        from: this.from,
        to: this.to,
        total: this.total,
        currentPage: this.currentPage,
        lastPage: this.lastPage,
        links: this.links
      }
    },
    paginationLinks(){
      if(!this.response){
        return null
      }

      return {
        next: this.response.body.links.filter(link => link.rels.contains("next_page")).first(),
        prev: this.response.body.links.filter(link => link.rels.contains("prev_page")).first()};
    },
    ...mapGetters({
        accessToken: "getAccessToken"
    })
  },
  methods: {
    navigate(link) {
      let parts = link.split("/api");
      this.$router.push(parts[1]);
      window.location.href = parts[1];
    },
    navigateAdditionalNext(link) {
      Siren.Client.addHeader(
          "Authorization",
          `Bearer ${this.accessToken}`
      );


      Siren.get(link.href).then(res => {
        let siren = res.body;
        let next = siren.links
          .filter(link => link.rels.contains("next_page"))
          .first();
        let parts = next.href.split("/api");
        this.$router.push(parts[1]);
      }, this);
    },
    navigateAdditionalPrev(link) {
      Siren.Client.addHeader(
          "Authorization",
          `Bearer ${this.accessToken}`
      );

      Siren.get(link.href).then(res => {
        let siren = res.body;
        let next = siren.links
          .filter(link => link.rels.contains("prev_page"))
          .first();
        let parts = next.href.split("/api");
        this.$router.push(parts[1]);
      }, this);
    },
  }
};
</script>
