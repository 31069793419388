<template>
    <div class="bg-white overflow-hidden shadow rounded-lg md:w-2/5 mx-auto md:mt-24 reports-form">
        <div class="px-4 py-5 sm:p-6">
            <div>
                <property-or-field @input="change" title="Query" :action="action" fieldId="filter[query]" v-model="actionData['filter[query]']"></property-or-field>
                <property-or-field @input="change" title="Project" :action="action" fieldId="filter[project_id]" v-model="actionData['filter[project_id]']"></property-or-field>
                <property-or-field @input="change" title="Contract" :action="action" fieldId="filter[site_id]" v-model="actionData['filter[site_id]']"></property-or-field>
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-4 sm:px-6">
            <primary-button @click.native="update" :loading="loading">Search Take-Offs</primary-button>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from "../PropertyOrField";
    import PrimaryButton from "../PrimaryButton";
    import fileDownload from "js-file-download";
    export default {
        data() {
            return {
                loading:false
            }
        },
        props: {
            action: {
                type: Object
            },
        },
        components: {
            PropertyOrField,
            PrimaryButton,
        },
        computed: {
            actionData(){
                var that = this;
                if(!this.action){
                    return {};
                }
                if(!this.action.fields){
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    }
                });
                return payload;
            },
        },
        methods: {
            change(){
                this.$emit('change');
            },
            update(){
                window.location = this.getFrontendRouteFromApiRoute(this.action.href) + '?' + this.getUrlParams(this.actionData);
            },
            getUrlParams(object){
                var str = "";
                for (var key in object) {
                    if(object[key] === null){
                        continue;
                    }
                    if (str != "") {
                        str += "&";
                    }
                    str += key + "=" + encodeURIComponent(object[key]);
                }
                return str;
            }
        },
    }
</script>

<style>
    .reports-form label.block{display:none;}
    .reports-form .form-group label{display:block;}
</style>
