<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full" data-cy="orders-table">
                    <thead>
                        <tr v-if="filterAction">
                            <th colspan=1000 class="p-8  bg-gray-50">
                                <generic-table-filter :action="filterAction"
                                :response="response" :actionBaseUrl="$route.path"
                                ></generic-table-filter>
                            </th>
                        </tr>
                        <tr>
                        <th
                            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            v-for="headingValue in headingValues"
                            :key="headingValue"
                        >{{headingValue}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.response.body.properties.get('total') === 0">
                        <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                No daily reports found.
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="(companyEntity, key) in companyEntities">
                            <tr class="bg-white hover:bg-gray-100" :class="{'bg-gray-50': !(key % 2 == 0), 'bg-red-100': getPropertiesToDisplay(companyEntity).project}">
                                <template v-for="(field, key) in getPropertiesToDisplay(companyEntity)">
                                    <td class="whitespace-nowrap text-sm leading-5 text-gray-500">
                                        <template v-if="key === 'details'">
                                            <div class="h-1/2 border-gray-100 border-l flex align-items-center p-3 ">
                                                <template v-if="field['times']['in'] !== 'N/A'">
                                                    <div class="inline-block w-16 font-bold">IN:</div>{{formatToTime(field['times']['in'])}} <span class="px-4">/</span>
                                                    <location-viewer-with-icon v-if="field['locations']['in'] !== 'N/A'" :x="field['locations']['in']['x']" :y="field['locations']['in']['y']">{{field['locations']['in']['project']}}</location-viewer-with-icon> <span class="px-4">/</span>
                                                    <photo-viewer-with-icon class="inline-flex" v-if="field['photos']['in'] !== 'N/A'" :file-name="field['photos']['in']"></photo-viewer-with-icon>
                                                </template>
                                                <template v-else>
                                                    N/A
                                                </template>
                                            </div>
                                            <div v-if="field['relocations']" class="h-1/2 border-t border-gray-100 border-l flex align-items-center p-3 ">
                                                <div class="outloute-block font-bold">Relocations:</div>
                                            </div>
                                            <template v-for="relocation in field['relocations']">
                                                <div class="h-1/2 border-gray-100 border-l flex align-items-center p-3 ">
                                                    <div class="outloute-block font-bold w-16"></div>
                                                    {{formatToTime(relocation['time'])}}<span v-if="relocation['location']" class="px-4">/</span>
                                                    <location-viewer-with-icon v-if="relocation['location']" :x="relocation['location']['x']" :y="relocation['location']['y']">{{relocation['location']['project']}}</location-viewer-with-icon> <span v-if="relocation['photo']" class="px-4">/</span>
                                                    <photo-viewer-with-icon relocationclass="outloute-flex" v-if="relocation['photo']" :file-name="relocation['photo']"></photo-viewer-with-icon>
                                                </div>
                                            </template>
                                            <div class="h-1/2 border-t border-gray-100 border-l flex align-items-center p-3 ">
                                                <div class="outloute-block w-16 font-bold">OUT:</div>
                                                <template v-if="field['times']['out'] !== 'N/A'">
                                                    {{formatToTime(field['times']['out'])}} <span class="px-4">/</span>
                                                    <location-viewer-with-icon v-if="field['locations']['out'] !== 'N/A'" :x="field['locations']['out']['x']" :y="field['locations']['out']['y']">{{field['locations']['out']['project']}}</location-viewer-with-icon> <span class="px-4">/</span>
                                                    <photo-viewer-with-icon class="outloute-flex" v-if="field['photos']['out'] !== 'N/A'" :file-name="field['photos']['out']"></photo-viewer-with-icon>
                                                </template>
                                                <template v-else>
                                                    N/A
                                                </template>
                                            </div>
                                        </template>
                                        <div class="px-3" style="max-width: 60px;" v-else>{{field}}</div>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                    </table>
                    <pagination
                        :response="response"
                        @navigate="paginationNavigation($event)"
                        @navigateAdditionalNext="paginationNavigation($event)"
                        @navigateAdditionalPrev="paginationNavigation($event)"
                    />
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatusBadge from "./StatusBadge";
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    import LocationViewerWithIcon from "@/components/LocationViewerWithIcon";
    import PhotoViewerWithIcon from "@/components/PhotoViewerWithIcon";
    import dayjs from "dayjs";
    export default {
        components: {
            PhotoViewerWithIcon,
            StatusBadge,
            Pagination,
            GenericTableFilter,
            LocationViewerWithIcon
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            companyEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('daily-report-item'));
            },
            headingValues() {
                return ['Employee', 'Details'];
            }
        },
        methods: {
            formatToTime(datetime){
                return dayjs(datetime).format('HH:mm');
            },
            getPropertiesToDisplay(reportEntity) {

               return {
                   'user': reportEntity.entity.properties.get('user'),
                   'details':{
                       'times': {
                           'in' : reportEntity.entity.properties.get('time_in'),
                           'out' : reportEntity.entity.properties.get('time_out')
                       },
                       'locations': {
                           'in': reportEntity.entity.properties.get('location_in'),
                           'out': reportEntity.entity.properties.get('location_out')
                       },
                       'photos' : {
                           'in': reportEntity.entity.properties.get('photo_in'),
                           'out': reportEntity.entity.properties.get('photo_out'),
                       },
                       'relocations': reportEntity.entity.properties.get('relocations')
                   }
               }
            },
            navigateToItem(companyEntity) {
                let selfLink = companyEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            },
            getTimesheetLink(reportEntity){
                let timesheetLink = reportEntity.entity.links.filter(link => link.rels.contains('timesheet-builder')).first().href;
                let parts = timesheetLink.split('/api');
                return parts[1];
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
