<template>
    <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
        <div class="w-0 flex-1 flex items-center">
            <!-- Heroicon name: paper-clip -->
            <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
            </svg>
            <span class="ml-2 flex-1 w-0 truncate">
                                    {{attachment.entity.properties.get('file')}}
                                </span>
        </div>
        <div class="ml-4 flex-shrink-0 flex items-center">
            <action-with-confirmation
                :is-button="false"
                :action="attachment.entity.actions.get('delete')"
                actionKey="delete"
                :onSuccessPath="$router.currentRoute.path"
                :isNegativeAction="true">
            </action-with-confirmation>
            <secondary-button class="ml-3" @click.native="showPDF = true">
                View Attachment
            </secondary-button>
            <template v-if="showPDF">
                <download-pdf :url="attachment.entity.properties.get('file')" @removeFromDisplay="showPDF = false"></download-pdf>
            </template>
        </div>
    </li>
</template>

<script>
    import DownloadPdf from "../../pages/DownloadPdf";
    import SecondaryButton from "../SecondaryButton";
    import ActionWithConfirmation from "../ActionWithConfirmation";
    export default {
        components:{DownloadPdf, SecondaryButton, ActionWithConfirmation},
        data() {
            return {
                showPDF: false
            }
        },
        props: {
            attachment: {
                type: Object
            },
        },
    }
</script>

<style scoped>

</style>
