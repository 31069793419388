var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-template',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var logo = ref.logo;
return [_c('fetch-siren-root',{attrs:{"linkName":_vm.linkName,"link":_vm.link,"logo":logo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var response = ref.response;
var logo = ref.logo;
return _c('div',{},[(!response)?_c('loading'):[_c('page-header',{attrs:{"logo":logo}},[_vm._v(" Return Requests "),_c('svg',{staticClass:"mx-4",attrs:{"width":"8","height":"15","viewBox":"0 0 8 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.125 1.95829L6.66667 7.49996L1.125 13.0416","stroke":"#003E7A","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',{staticClass:"text-secondary-darkblue"},[_vm._v(" Return for order #"+_vm._s(response.body.properties.get('order_reference'))+" ")]),_c('status-badge',{staticClass:"ml-2",attrs:{"status":response.body.properties.get('status')}})],1),_c('div',{staticClass:"mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3"},[_c('div',{staticClass:"space-y-6 lg:col-start-1 lg:col-span-2"},[_c('section',[_c('div',{staticClass:"bg-white shadow sm:rounded-lg"},[_c('div',{staticClass:"px-4 py-5 sm:px-6 grid grid-cols-1"},[_c('dl',{staticClass:"grid grid-cols-1 gap-x-4 gap-y-4"},[_c('div',[_c('dt',{staticClass:"inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center"},[_vm._v(" Rental Request Items ")])]),_c('return-request-items-list',{attrs:{"response":response}})],1)])])])]),(response.body.actions
                        .filter(function (action) { return action.name !== 'update' &&
                            action.name !== 'update-invoice' &&
                            action.name !== 'add-new-item' &&
                            action.name !== 'search-products' &&
                            action.name !== 'update-quote'; }
                        ).count() > 0)?_c('section',{staticClass:"lg:col-start-3 lg:col-span-1"},[_c('div',{staticClass:"bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6"},[_c('return-request-actions',{attrs:{"response":response}})],1)]):_vm._e()])]],2)}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }