<template>
    <div class="bg-white overflow-hidden shadow rounded-lg md:w-2/5 mx-auto md:mt-24 reports-form">
            <div class="px-4 py-5 sm:p-6">
                <p></p>

                <div class="rounded-md bg-blue-50 p-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: solid/information-circle -->
                            <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between">
                            <p class="text-sm text-blue-700">
                                The invoices and credit notes have been exported, please proceed to import these into Sage & populate the "Sage Number" column. Once populated please import the updated version back here to mark them as "Uploaded to Sage".
                            </p>
                        </div>
                    </div>
                </div>

                <div>
                    <property-or-field @input="change" title="File" :action="action" fieldId="file" v-model="actionData['file']"></property-or-field>
                </div>
            </div>
            <div class="bg-gray-50 px-4 py-4 sm:px-6">
                <primary-button @click.native="saveData('0')" :loading="loading">Re-Import Invoices</primary-button>
            </div>
        <portal to="destination">
            <modal v-if="displayModal" :submitting="submittingModal" :title="title" :confirmationString='finalConfirmationString' @removeFromDisplay="displayModal = false" @perform="perform()"></modal>
        </portal>
    </div>
</template>

<script>
    import PropertyOrField from "../PropertyOrField";
    import PrimaryButton from "../PrimaryButton";
    import fileDownload from "js-file-download";
    import Modal from '../Modal';
    export default {
        data() {
            return {
                loading:false,
                displayModal:false,
                submittingModal:false,
                title:null,
                finalConfirmationString: null,
                exported:false
            }
        },
        props: {
            action: {
                type: Object,
                default: {}
            },
        },
        components: {
            PropertyOrField,
            PrimaryButton,
            Modal
        },
        computed: {
            actionData(){
                var that = this;
                if(!this.action){
                    return {};
                }
                if(!this.action.fields){
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
        },
        methods: {
            change(){
                this.$emit('change');
            },
            update(continueOnFail){
                this.actionData['continue_on_fail'] = continueOnFail;
                return this.action.perform(this.actionData);
            },
            async saveData() {
                this.loading = true;
                this.update().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        res.body.success
                    );
                    this.loading = false;
                }).catch(error => {
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            perform() {
                this.submittingModal = true;
                this.$store.dispatch(
                    "clearMessagesWithoutTimeoutNow"
                );
                this.saveData('1');
                this.displayModal = false;
                this.submittingModal = false;
            },
        },
    }
</script>

<style>
    .reports-form label.block{display:none;}
    .reports-form .form-group label{display:block;}
</style>
