<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full" data-cy="orders-table">
                    <thead>
                        <tr v-if="filterAction">
                            <th colspan=1000 class="p-8  bg-gray-50">
                                <generic-table-filter :action="filterAction"
                                                      :response="response"
                                                      :actionBaseUrl="$route.path"
                                ></generic-table-filter>
                            </th>
                        </tr>
                        <tr>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                v-for="headingValue in headingValues"
                                :key="headingValue"
                            >{{headingValue}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.response.body.properties.get('total') === 0">
                        <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                            <td colspan="100" class="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 text-center">
                                No credit notes found.
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="creditNote,key in creditNoteEntities">

                            <tr
                                class="bg-white hover:bg-gray-100"
                                :class="{'bg-gray-50': !(key % 2 == 0)}">
                                <template v-for="field, key in getPropertiesToDisplay(creditNote)">
                                    <template v-if="key === 'is_uploaded'">
                                        <td>
                                        <template v-if="field">
                                            <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                                        </template>
                                        <template v-else>
                                            <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                        </template>
                                        </td>
                                    </template>
                                    <template v-else-if="key === 'order'">
                                        <td  class="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <a v-if="field" :href="'/orders/'+ field">View order</a>
                                        </td>
                                    </template>
                                    <template v-else-if="key != 'file' && key !== 'linked_sales_invoice'">
                                        <td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {{field}}
                                        </td>
                                    </template>
                                    <template v-else-if="key === 'linked_sales_invoice'">
                                        <td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <a class="underline hover:no-underline" v-if="field" :href="`/invoices/${field.uuid}`">{{field.reference}} - {{field.status}}</a>
                                            <span v-else>No linked sales invoice</span>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <download-pdf-icon-with-link :url="creditNote.entity.properties.get('file')">
                                        </download-pdf-icon-with-link>

                                    </template>
                                </template>
                                <td class="flex p-2">

                                    <new-credit-note-form :action="updateCreditNoteAction(creditNote)"
                                                      onSuccessPath="/credit-notes"></new-credit-note-form>
                                    <action-with-confirmation :action="creditNote.entity.actions.get('mark-as-uploaded')"
                                                              actionKey="mark-as-uploaded"
                                                              onSuccessPath="/credit-notes"></action-with-confirmation>
                                    <delete-credit-note-confirmation v-if="creditNote.entity.actions.get('delete-credit-note')"
                                                                    :action="creditNote.entity.actions.get('delete-credit-note')"
                                                                    actionKey="delete-credit-note"
                                                                    onSuccessPath="/credit-notes">
                                    </delete-credit-note-confirmation>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>

            <div class="container-wide">
                <pagination
                    :response="response"
                    @navigate="paginationNavigation($event)"
                    @navigateAdditionalNext="paginationNavigation($event)"
                    @navigateAdditionalPrev="paginationNavigation($event)"
                />
            </div>

        </div>
    </div>
</template>

<script>
    import Pagination from "./Pagination";
    import DownloadPdf from "../pages/DownloadPdf";
    import NewCreditNoteForm from "./NewCreditNoteForm";
    import DownloadPdfIconWithLink from "./DownloadPdfIconWithLink";
    import DeleteCreditNoteConfirmation from "@/components/DeleteCreditNoteConfirmation";
    import ActionWithConfirmation from "@/components/ActionWithConfirmation";
    import GenericTableFilter from "@/components/GenericTableFilter";

    export default {
        components: {
            DownloadPdf,
            Pagination,
            NewCreditNoteForm,
            DownloadPdfIconWithLink,
            DeleteCreditNoteConfirmation,
            ActionWithConfirmation,
            GenericTableFilter
        },
        props: {
            response: {
                type: Object,
            },
        },
        data() {
            return {
                showPDF: false
            }
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            creditNoteEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('credit-note'));
            },
            headingValues() {
                return ['Reference', 'Order', 'Invoice PDF', 'Net Value', 'VAT Value', 'Gross Value', 'Uploaded To Sage', 'Linked Sales Invoice Status', 'Actions'];
            }
        },
        methods: {
            getPropertiesToDisplay(creditNote) {
                return {
                    'reference' : creditNote.entity.properties.get('reference'),
                    'order' : creditNote.entity.properties.get('order_uuid'),
                    'file' : creditNote.entity.properties.get('file'),
                    'net_value' : creditNote.entity.properties.get('net_value'),
                    'vat_value' : creditNote.entity.properties.get('vat_value'),
                    'gross_value' : creditNote.entity.properties.get('gross_value'),
                    'is_uploaded' : creditNote.entity.properties.get('is_uploaded'),
                    'linked_sales_invoice' : creditNote.entity.properties.get('linked_sales_invoice'),
                };
            },
            updateCreditNoteAction(creditNote) {
                return creditNote.entity.actions.find(action => action.name == 'update-credit-note')
            },
            togglePDF()
            {
                this.showPDF = !this.showPDF
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
