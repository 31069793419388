<template>
    <div>
        <a-button class="mr-3" v-if="!isNegativeAction && !isPositiveAction" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>
        <red-button class="mr-3" v-else-if="isNegativeAction" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </red-button>
        <green-button class="mr-3" v-else-if="isPositiveAction" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </green-button>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
        :confirmationString='`Are you sure you want to \"${action.title}\"`'
        @removeFromDisplay="displayModal = false" @perform="perform()"
        :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction">
            <component :is="getFieldComponent(action.fields.get('name'))" :field="action.fields.get('name')" v-model="formData['name']">
            </component>
            <div v-show="errors.has('name')" class="text-red-600">{{ errors.first('name') }}</div>

            <component :is="getFieldComponent(action.fields.get('rebate'))" :field="action.fields.get('rebate')" v-model="formData['rebate']" @input="setRebate">
            </component>
            <div v-show="errors.has('rebate')" class="text-red-600">{{ errors.first('rebate') }}</div>

            <template v-if="rebate">
                <component :is="getFieldComponent(action.fields.get('rebate_notes'))" :field="action.fields.get('rebate_notes')" v-model="formData['rebate_notes']">
                </component>
                <div v-show="errors.has('rebate_notes')" class="text-red-600">{{ errors.first('rebate_notes') }}</div>
            </template>

            <component :is="getFieldComponent(action.fields.get('discount'))" :field="action.fields.get('discount')" v-model="formData['discount']" @input="setDiscount">
            </component>
            <div v-show="errors.has('discount')" class="text-red-600">{{ errors.first('discount') }}</div>

            <template v-if="discount">
                <component :is="getFieldComponent(action.fields.get('discount_notes'))" :field="action.fields.get('discount_notes')" v-model="formData['discount_notes']">
                </component>
                <div v-show="errors.has('discount_notes')" class="text-red-600">{{ errors.first('discount_notes') }}</div>
            </template>

        <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </slide-over>
    </div>
</template>

<script>
    import Button from '../Button';
    import Modal from '../Modal';
    import SlideOver from "../SlideOver";
    import fileDownload from "js-file-download";
    import RedButton from "../RedButton";
    import GreenButton from "../GreenButton";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                rebate: false,
                discount: false
            }
        },
        components: {
            SlideOver,
            'a-button': Button,
            Modal,
            RedButton,
            GreenButton
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            isNegativeAction: {
                type: Boolean,
                default: false
            },
            isPositiveAction: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                var that = this;
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) {
                        payload[field.name] = field.value;
                        if(field.name === 'rebate') that.rebate = field.value;
                        if(field.name === 'discount') that.discount = field.value;
                    };
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            setRebate(value) {
                this.rebate = value;
            },
            setDiscount(value) {
                this.discount = value;
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>
