<template>
    <div v-if="field">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Permissions</h3>
        <div class="flex flex-col mt-4">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Name
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        View
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Add
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Edit
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Approve / Others
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(group, index) in groupedOptions">
                                    <tr class="font-bold bg-indigo-100 p-5 w-full">
                                        <td colspan="6" class="text-lg leading-6 font-medium text-gray-900 p-6">{{index}}</td></tr>
                                    <template v-for="(permissions, groupName) in group">
                                        <tr class="even:bg-gray-50 bg-white">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {{ groupName }}
                                                <div class="tooltip" v-if="groupName === 'order-accounting-info'">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 cursor-pointer flex justify-center items-center"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>
                                                    <span class="tooltiptext p-2">
                                                        Add/Edit<br>
                                                        Reconcile Orders<br>
                                                        Rental Item Invoices
                                                    </span>
                                                </div>
                                                <div class="tooltip" v-if="groupName === 'order-procurement-info'">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 cursor-pointer flex justify-center items-center"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>
                                                    <span class="tooltiptext p-2">
                                                        Add<br>
                                                        Send Quote Request<br>
                                                        Select Quote<br>
                                                        Mark As Take Off<br>
                                                        Create Scheduled Delivery<br><br>
                                                        Edit<br>
                                                        Mark As Ready For Quote<br>
                                                        Update Scheduled Delivery<br>
                                                        Delete Order
                                                    </span>
                                                </div>
                                            </td>

                                            <template v-if="groupName === 'general'" >
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                </td>
                                                <td colspan="">
                                                    <template>
                                                        <div class="p-4" v-for="permission, key in permissions">
                                                            <div class="inline-block pr-2">{{key}}</div><input type="checkbox" :value="permission" v-model="selectedPermissions">
                                                        </div>
                                                    </template>
                                                </td>
                                            </template>
                                            <template v-else-if="index === 'app'" >
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <input type="checkbox" v-if="permissions['view-on-app']"  :value="permissions['view-on-app']" v-model="selectedPermissions">
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                </td>
                                                <td colspan="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                </td>
                                            </template>
                                            <template v-else>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <input type="checkbox" v-if="permissions['view']"  :value="permissions['view']" v-model="selectedPermissions">
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <input type="checkbox" v-if="permissions['add']" :value="permissions['add']" v-model="selectedPermissions">
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <input type="checkbox" v-if="permissions['edit']" :value="permissions['edit']" v-model="selectedPermissions">
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <input type="checkbox" v-if="permissions['approve']" :value="permissions['approve']" v-model="selectedPermissions">
                                            </td>
                                            </template>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "UserPermissions",
    props:{
        field: {
            type: Object
        },
    },
    data() {
        return {
            selectedPermissions: []
        }
    },
    mounted(){
            if (!this.field || this.field.options === null) {
                return [];
            }
            var payload = [];
            Object.keys(this.field.options).forEach(function(option){
            option = this.field.options[option];
                if(option.selected) {
                    payload.push(option.value);
                }
            },this);
            this.selectedPermissions = payload;
            setTimeout(() => this.$emit("valueNotChanged"), 100);
            return payload;
    },
  methods:{
        getOption(permission){
            let options = this.field.options;
            return options.filter(function (option) {
                if (option.title === permission){
                    return option.value;
                }
            })
        },
        getCheckedValue(permission)
        {
            let option = this.getOption(permission)[0];
            if (option){
                return option.value;
            }
            return false;
        }
    },
    computed: {
        groupedOptions() {
            if (!this.field || this.field.options === null) {
                return {};
            }
            let groupedOptions = {};
            Object.keys(this.field.options).forEach(function(option){
                    option = this.field.options[option];
                    let module = option.module;
                    let parts = option.title.split('.');
                    console.log(option.title);
                    // if(option.title === 'general.unlimited-approvals'){
                    //     if(groupedOptions['approvals'] === undefined){
                    //         groupedOptions['approvals'] = {};
                    //     }
                    //     if(groupedOptions['approvals']['financial'] === undefined){
                    //         groupedOptions['approvals']['financial'] = {};
                    //     }
                    //     groupedOptions['approvals']['financial']['view'] = option.value;
                    // }else if(option.title === 'qas.approve'){
                    //     if(groupedOptions['approvals'] === undefined){
                    //         groupedOptions['approvals'] = {};
                    //     }
                    //     if(groupedOptions['approvals']['quality'] === undefined){
                    //         groupedOptions['approvals']['quality'] = {};
                    //     }
                    //     groupedOptions['approvals']['quality']['view'] = option.value;
                    // }else if(option.title === 'qa-payment-vouchers.approve'){
                    //     if(groupedOptions['approvals'] === undefined){
                    //         groupedOptions['approvals'] = {};
                    //     }
                    //     if(groupedOptions['approvals']['payment-vouchers'] === undefined){
                    //         groupedOptions['approvals']['payment-vouchers'] = {};
                    //     }
                    //     groupedOptions['approvals']['payment-vouchers']['view'] = option.value;
                    // }else if(option.title === 'holiday-requests.approve'){
                    //     if(groupedOptions['approvals'] === undefined){
                    //         groupedOptions['approvals'] = {};
                    //     }
                    //     if(groupedOptions['approvals']['leave-manager'] === undefined){
                    //         groupedOptions['approvals']['leave-manager'] = {};
                    //     }
                    //     groupedOptions['approvals']['leave-manager']['view'] = option.value;
                    // }else {
                        if (groupedOptions[module] === undefined) {
                            groupedOptions[module] = {};
                        }
                        if (groupedOptions[module][parts[0]] === undefined) {
                            groupedOptions[module][parts[0]] = {};
                        }
                        groupedOptions[module][parts[0]][parts[1]] = option.value;
                    // }
                },this);
            // if(groupedOptions['approvals'] === undefined){
            //     groupedOptions['approvals'] = {};
            // }
            //
            // if(groupedOptions['approvals'] === undefined){
            //     groupedOptions['approvals'] = {};
            // }
            // if(groupedOptions['approvals']['leave-op'] === undefined){
            //     groupedOptions['approvals']['leave-op'] = {};
            // }
            // groupedOptions['approvals']['leave-op']['view'] = 0;
            // if(groupedOptions['approvals'] === undefined){
            //     groupedOptions['approvals'] = {};
            // }
            // if(groupedOptions['approvals']['sensitive'] === undefined){
            //     groupedOptions['approvals']['sensitive'] = {};
            // }
            // groupedOptions['approvals']['sensitive']['view'] = 0;

            return groupedOptions;
        }
    },
    watch: {
        selectedPermissions: {
            handler(value) {
                this.$emit("input", value.toString());
            },
            deep: true
        },
    },
}
</script>

<style scoped>
    /* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    min-width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}
</style>
