<script>
    import { mapActions, mapGetters } from "vuex";
    import Siren from "super-siren";
    export default {
        computed: {
            ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
            })
        },
        render(createElement) {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

            return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
                let siren = res.body;
                let orderLink = siren.links.filter(link => link.rels.contains("orders")).first().href;
                let parts = orderLink.split("/api/");
                this.$router.push(parts[1])
                // this.$router.go(0);
            }, this).catch(error => {
                this.$router.push({ path: "/login" });
            });
        }
    }
</script>
