import defaultState from './state';

export const setAccessToken = (state, accessToken) => {
  state.accessToken = accessToken;
};

export const setUser = (state, user) => {
  state.user = user;
};

export const logout = (state) => {
  Object.keys(defaultState).forEach(key => state[key] = defaultState[key]);
};

export const setMessage = (state, message) => {
  state.message = message;
};

export const clearMessages = (state) => {
  state.message = null;
}

export const setMessageWithoutTimeout = (state, message) => {
    state.messageWithoutTimeout = message;
}

export const clearMessagesWithoutTimeout = (state) => {
    state.messageWithoutTimeout = null;
}
