<template>
    <div :key="$route.fullPath" class="create-new-order">
        <main-template>

        <page-title title="Create New Order">
            <div >
                <span data-v-1b26be5a=""
                      class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 badge-pending">pending</span>
            </div>
        </page-title>
        <loading v-if="loading"></loading>
        <div class="container mx-auto px-4" v-if="!loading" :key="key">
            <div class="bg-white shadow p-4 md:grid md:grid-cols-3 md:gap-4 sm:rounded-lg mb-4">
                <property-or-field title="Client" :action="createNewOrderAction"
                                   fieldId="client_id" propertyKey="client"
                                   v-model="createNewOrderActionData['client_id']"
                                   @input="setClientId"></property-or-field>

                <dependant-field title="Project" :action="createNewOrderAction"
                                   fieldId="project_id"
                                 dependsOn="client_id"
                                 :dependsOnValue="clientId"
                                   v-model="createNewOrderActionData['project_id']"
                                   @input="setProjectId"></dependant-field>

                <dependant-field @input="change" title="Site" :action="createNewOrderAction"
                                 fieldId="site_id"
                                 dependsOn="project_id"
                                 :dependsOnValue="projectId"
                                 v-model="createNewOrderActionData['site_id']"
                ></dependant-field>

                <dependant-field title="Site Manager" :action="createNewOrderAction"
                                   fieldId="site_manager_id" propertyKey="user_id"
                                   dependsOn="client_id"
                                   :dependsOnValue="clientId"
                                   v-model="createNewOrderActionData['site_manager_id']"></dependant-field>

                <property-or-field @input="change" title="Date" :action="createNewOrderAction"
                                   fieldId="date" propertyKey="date"
                                   v-model="createNewOrderActionData['date']"></property-or-field>
                <property-or-field @input="changeScheduledFile" title="Scheduled File"
                                   :action="createNewOrderAction" fieldId="scheduled_file"
                                   propertyKey="scheduled_file"
                                   v-model="createNewOrderActionData['scheduled_file']"></property-or-field>
                <property-or-field @input="change" title="Expected By Date" v-if="createNewOrderActionData['scheduled_file'] === null"
                                   :action="createNewOrderAction" fieldId="expected_by_date"
                                   propertyKey="expected_by_date"
                                   v-model="createNewOrderActionData['expected_by_date']"></property-or-field>
                <property-or-field @input="change" title="Quick Reference"
                                   :action="createNewOrderAction" fieldId="quick_reference"
                                   propertyKey="quick_reference"
                                   v-model="createNewOrderActionData['quick_reference']"></property-or-field>
                <property-or-field @input="change" title="Specifications"
                                   :action="createNewOrderAction" fieldId="specifications" propertyKey="specifications"
                                   v-model="createNewOrderActionData['specifications']"></property-or-field>
                <property-or-field @input="changeDeliveryMethod" title="Delivery Method"
                                   :action="createNewOrderAction" fieldId="delivery_method"
                                   propertyKey="delivery_method"
                                   v-model="createNewOrderActionData['delivery_method']"></property-or-field>
                <property-or-field v-if="createNewOrderActionData['delivery_method'] === 'pick_up'" @input="change" title="Supplier"
                                   :action="createNewOrderAction" fieldId="supplier"
                                   propertyKey="supplier"
                                   v-model="createNewOrderActionData['supplier']"></property-or-field>
                <property-or-field v-if="createNewOrderActionData['delivery_method'] === 'pick_up'" @input="change" title="Pick Up Location"
                                   :action="createNewOrderAction" fieldId="pick_up_location"
                                   propertyKey="pick_up_location"
                                   v-model="createNewOrderActionData['pick_up_location']"></property-or-field>
                <property-or-field @input="change" title="Delivery must be booked"
                                   :action="createNewOrderAction" fieldId="booking_delivery"
                                   propertyKey="booking_delivery"
                                   v-model="createNewOrderActionData['booking_delivery']"></property-or-field>
                <property-or-field @input="change" title="Supplier Notes"
                                   :action="createNewOrderAction" fieldId="additional_notes"
                                   propertyKey="additional_notes"
                                   v-model="createNewOrderActionData['additional_notes']"></property-or-field>
                <property-or-field @input="change" title="Procurement Notes"
                                   :action="createNewOrderAction" fieldId="procurement_notes"
                                   propertyKey="procurement_notes"
                                   v-model="createNewOrderActionData['procurement_notes']"></property-or-field>
                <property-or-field @input="change" title="Attachment"
                                   :action="createNewOrderAction" fieldId="attachment"
                                   propertyKey="attachment"
                                   v-model="createNewOrderActionData['attachment']"></property-or-field>

                <property-or-field @input="change" title="Question"
                                   :action="createNewOrderAction" fieldId="question"
                                   propertyKey="question"
                                   v-model="createNewOrderActionData['question']"></property-or-field>

            </div>
            <div
                v-if="!loading"
                class="p-3 bottom-0 w-full mt-6 -mt-4 flex justify-end z-40">
                <primary-button @click.native="createOrder()" class="cursor-pointer" data-cy="submit" :loading="submitting">
                    Create
                    Order
                </primary-button>
            </div>

        </div>



        </main-template>
    </div>
</template>

<script>
    import Loading from '../components/Loading';
    import PrimaryButton from '../components/PrimaryButton';
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import PropertyOrField from "../components/PropertyOrField";
    import PageTitle from "../common/PageTitle";
    import DependantField from "../components/DependantField";
    import MainTemplate from "../templates/MainTemplate";

    let follow = Siren.Helper.follow;
    export default {
        components: {
            MainTemplate,
            Loading,
            PrimaryButton,
            PropertyOrField,
            PageTitle,
            DependantField
        },
        data() {
            return {
                createNewOrderAction: null,
                projectId: null,
                loading: true,
                submitting: false,
                key:0,
                clientId: null,
                scheduledFile: null
            }
        },

        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            createNewOrderActionData() {
                var vm = this;
                if (!this.createNewOrderAction) {
                    return {};
                }
                if (!this.createNewOrderAction.fields) {
                    return {};
                }
                var payload = {};
                this.createNewOrderAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                if(payload['client_id'] === undefined)
                {
                    Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                    Siren.get(process.env.VUE_APP_API_URL + "/api/")
                        .then(
                            follow("current-user")
                        )
                        .then(res => {
                            this.clientId = res.body.properties.get("client_id");
                        }, this);
                }
                return payload;
            },
        },
        methods: {
            change() {
            },
            changeDeliveryMethod() {
                this.change();
                this.key++;
            },
            changeIsScheduled() {
                this.change();
                this.key++;
            },
            createOrder() {
                this.submitting = true;
                this.createNewOrderAction.perform(this.createNewOrderActionData).then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.submitting = false;
debugger;
                    let next = res.body.links
                        .filter(link => link.rels.contains("self"))
                        .first();
                    let parts = next.href.split("/api");
                    this.$router.push(parts[1]);
                }).catch(error => {
                    debugger;
                    this.submitting = false;
                    if (error.response.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                });
            },
            setProjectId(value) {
                this.projectId = value;
            },
            setClientId(value) {
                this.clientId = value;
            },
            changeScheduledFile(value) {
                this.scheduledFile = value;
                console.log(value);
                this.change();
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },
        mounted() {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow("orders"))
                .then(res => {
                    let siren = res.body;
                    this.createNewOrderAction = siren.actions.filter(action => action.name === 'create-new-order').get('create-new-order');
                    this.loading = false;
                }, this);

        },
    }
</script>

<style lang="scss">
    .create-new-order .label-a {
        display: none;
    }
</style>
