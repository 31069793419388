<template>
    <div class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10">
        <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-out duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
        </transition>

        <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
                <div class="">
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left max-h-screen-3/4 overflow-auto">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {{title}}
                    </h3>
                    <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500">
                        {{confirmationString}}
                    </p>
                    </div>
                    <slot></slot>
                </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <span v-if="hasConfirmationAction" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <primary-button v-if="!isNegativeAction && !isPositiveAction" @click.native="perform()" data-cy="submit-modal" :loading="submitting">
                            {{firstButtonTitle}}
                        </primary-button>
                        <red-button v-if="isNegativeAction" @click.native="perform()" data-cy="submit-modal" :loading="submitting">
                            {{firstButtonTitle}}
                        </red-button>
                        <green-button v-if="isPositiveAction" @click.native="perform()" data-cy="submit-modal" :loading="submitting">
                            {{firstButtonTitle}}
                        </green-button>
                    </span>
                    <span v-if="hasConfirmationAction" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <primary-button v-if="!isNegativeAction && !isPositiveAction" @click.native="performSecond()" data-cy="submit-modal" :loading="submittingSecond">
                            {{secondButtonTitle}}
                        </primary-button>
                        <red-button v-if="isNegativeAction" @click.native="performSecond()" data-cy="submit-modal" :loading="submittingSecond">
                            {{secondButtonTitle}}
                        </red-button>
                        <green-button v-if="isPositiveAction" @click.native="performSecond()" data-cy="submit-modal" :loading="submittingSecond">
                            {{secondButtonTitle}}
                        </green-button>
                    </span>
                    <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button @click="removeFromDisplay()" data-cy="cancel-modal" type="button" class="inline-flex justify-center w-full rounded-md border text-blue-700 bg-blue-100 hover:bg-blue-50 px-4 py-2  text-base leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-300 focus:shadow-outline active:bg-blue-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                            Cancel
                        </button>
                    </span>
                </div>
            </div>
        </transition>
        </div>
</template>

<script>
    import PrimaryButton from './PrimaryButton';
    import RedButton from "./RedButton";
    import GreenButton from "./GreenButton";
    export default {
        components: {
            PrimaryButton,
            RedButton,
            GreenButton
        },
        props: {
            submitting:{
                type: Boolean,
                default: false
            },
            submittingSecond:{
                type: Boolean,
                default: false
            },
            hasConfirmationAction:{
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: 'Perform action.'
            },
            confirmationString: {
                type: String,
                default: 'Are you sure?'
            },
            isNegativeAction:{
                type: Boolean,
                default: false
            },
            isPositiveAction: {
                type: Boolean,
                default: false
            },
            firstButtonTitle:{
                type: String,
                default: 'Confirm'
            },
            secondButtonTitle:{
                type: String,
                default: 'Confirm'
            }
        },
        methods: {
            removeFromDisplay() {
                this.$emit('removeFromDisplay');
            },
            perform(){
                this.$emit('perform');
            },
            performSecond(){
                this.$emit('performSecond');
            }
        },
    }
</script>
