<template>
    <paginated-list :response="response" actionBaseUrl="/quality-assessments">
        <template v-slot:savedFilter>
            <order-tabs :response="response"></order-tabs>
        </template>
        <template v-slot:headers>

            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Line</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Reference</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Date</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Contract</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Issued By</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Location L/U/B</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Voucher</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Actions</th>
        </template>
        <template v-slot:noItems>
            No quality assessments found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in qualityAssessments" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('line_id') }}
                    </div>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{ entity.entity.properties.get('reference') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{ entity.entity.properties.get('created_at') }}</div>
                </td>
                <td class="whitespace-normal px-3 py-4 text-ism text-secondary-darkgrey font-bold">
                    <div>{{entity.entity.properties.get('contract')}}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('added_by') }}
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('location') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <svg v-if="entity.entity.properties.get('voucher') === 'Yes'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                    <svg v-else-if="entity.entity.properties.get('voucher') === 'No'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <template v-else>
                        {{ entity.entity.properties.get('voucher') }}
                    </template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold flex">
                    <a-button @click.native="navigateToItem(entity)">View</a-button>
                    <confirmation-action v-if="entity.entity.actions.get('approve-qa')" buttonType="positive" :action="entity.entity.actions.get('approve-qa')" :onSuccessPath="$route.path">
                    </confirmation-action>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import Button from "@/v2/buttons/Button";
export default {
    name: "QualityAssementPaginatedList",
    components:{
        ConfirmationAction,
        PaginatedList,
        StatusBadge,
        OrderTabs,
        'a-button': Button
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        qualityAssessments() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('quality-assessment'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
