<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

        <div slot-scope="{ response, logo }">
            <loading v-if="!response"></loading>
            <template v-else>
                <page-header :logo="logo">

                    <div class="md:flex justify-between w-full items-center">
                        <span class="flex items-center text-secondary-lightblue text-xl font-bold w-full">
                            Orders
                            <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span class="text-secondary-darkblue">Schedule Manager</span>

                        </span>
                        <div class="flex items-center text-secondary-darkgrey">
                            <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter' && action.name !== 'get-report' && action.name !== 'reimport-with-sage-numbers' && action.name !== 'create-new-order' && action.name !== 'export-invoices' && action.name !== 'get-report')">
                                <form-action class="action-with-fields" :action="actionWithFields[1]"
                                             :actionKey="actionWithFields[0]"
                                             :redirectOnSuccess="true"
                                             :onSuccessPath="$router.currentRoute.fullPath"
                                ></form-action>
                            </div>
                        </div>
                    </div>
                </page-header>

                <schedule-manager-paginated-list :response="response"></schedule-manager-paginated-list>
            </template>
        </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import PaginatedList from "@/v2/paginatedLists/PaginatedList";
    import PageHeader from "@/v2/components/Header";
    import ScheduleManagerPaginatedList from "@/v2/paginatedLists/ScheduleManagerPaginatedList";
    import Button from "@/v2/buttons/Button";
    import FormAction from "@/v2/components/FormAction";
    export default {
    name: "ScheduleManager",
        components: {
            ScheduleManagerPaginatedList,
            PaginatedList,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader,
            'a-button': Button,
            FormAction
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {

        }
    }

</script>

<style scoped>

</style>
