<template>
    <div>
    <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Timeline</h2>

    <!-- Activity Feed -->
    <div class="mt-6 flow-root">
        <ul class="-mb-8">
            <li v-for="(activity, index) in response.body.properties.get('human_readable_activity_stream')">
                <div class="relative pb-8">
                    <span v-if="index+1 !== response.body.properties.get('human_readable_activity_stream').length" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                    <div class="relative flex space-x-3">
                        <div>
                                                  <span class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                                    <!-- Heroicon name: solid/user -->
                                                    <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                      <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                                                    </svg>
                                                  </span>
                        </div>
                        <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div>
                                <p class="text-sm text-gray-500">{{activity.description}} <a href="#" class="font-medium text-gray-900">{{activity.actioned_by}}</a></p>
                            </div>
                            <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                <time datetime="2020-09-20">{{activity.date}}</time>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>


    <div class="mt-6 flex flex-col justify-stretch" v-if="approveAction">
        <confirmation-action :action="approveAction" buttonType="positive" additionalButtonClasses="w-full justify-center py-4" class="w-full" :onSuccessPath="$route.path">
            <!--                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" class="fill-current mr-1" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/></svg>-->
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" class="fill-current mr-1" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
        </confirmation-action>
    </div>
    </div>
</template>

<script>
import ConfirmationAction from "@/v2/components/ConfirmationAction";
export default {
name: "QualityAssessmentTimeline",
    components:{
        ConfirmationAction
    },
    props:{
        response:{
            type:Object
        },
        showActions:{
            type:Boolean,
            default:true
        }
    },
    computed:{
        approveAction(){
            if(!this.response || !this.showActions){
                return null;
            }

            return this.response.body.actions.filter(action => action.name === 'approve-qa').first();
        },
    }
}
</script>

<style scoped>

</style>
