<template>
    <div>
        <div v-for="(action, index) in remainingActions">
            <template v-if="!action[1].fields.isEmpty()">
                <add-categories-and-plots-to-qa-tracker-form v-if="action[0] === 'add-categories-and-plots'" :action="action[1]"
                                                             additionalButtonClasses="my-2 w-full"
                                                             :actionKey="action[0]"
                                                             :redirectOnSuccess="false"
                                                             @success="success(action[0])"
                ></add-categories-and-plots-to-qa-tracker-form>
                <form-action v-else :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></form-action>
            </template>
            <template v-else-if="action[1].fields.isEmpty()">
                <confirmation-action v-if="action[0] === 'delete-qa-tracker'" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties" onSuccessPath="/qa-tools"></confirmation-action>
                <confirmation-action v-else additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath"></confirmation-action>
            </template>
        </div>
    </div>
</template>

<script>
import Button from "@/v2/buttons/Button";
import ApproveButtons from "@/v2/buttons/ApproveButtons";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
import AddCategoriesAndPlotsToQaTrackerForm from "@/v2/components/forms/AddCategoriesAndPlotsToQaTrackerForm";
export default {
    name: "QaTrackerActions",
    data() {
        return {
            displayApproveModal : false,
            displayRejectModal : false
        }
    },
    components:{
        FormAction,
        ConfirmationAction,
        'a-button' : Button,
        ApproveButtons,
        AddCategoriesAndPlotsToQaTrackerForm
    },
    props:{
        response:{
            type: Object
        },
        remainingActions:{
            type: Object
        }
    },
    methods:{
        getColourType(action, index){
            if(action === 'delete-qa-tracker'){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        getAction(actionName){
            return this.remainingActions.filter(action => action.name === actionName).first();
        },
        success(key) {
            this.$router.go(0);
        }
    }
}
</script>

<style scoped>

</style>
