var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cursor-default"},[(_vm.isButton)?_c('a-button',{class:_vm.additionalButtonClasses,attrs:{"colourType":_vm.buttonType,"title":_vm.title ? _vm.title : _vm.action.title},nativeOn:{"click":function($event){_vm.displayModal = true}}},[_vm._t("icon",[_vm._v(_vm._s(_vm.title ? _vm.title : _vm.action.title))])],2):_c('a',{staticClass:"cursor-pointer font-normal",attrs:{"data-cy":("action-button-" + (_vm.action.name))},on:{"click":function($event){_vm.displayModal = true}}},[_vm._v(" "+_vm._s(_vm.title ? _vm.title : _vm.action.title)+" ")]),_c('slide-over',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayModal),expression:"displayModal"}],staticClass:"text-left",attrs:{"submitting":_vm.submittingModal,"title":_vm.action.title,"confirmationString":("Are you sure you want to \"" + (_vm.action.title) + "\"")},on:{"removeFromDisplay":function($event){_vm.displayModal = false},"perform":function($event){return _vm.perform()}}},[_vm._l((_vm.action.fields.sort(function (a, b) {
            if (a === null) {
                return 1;
            }
            if (b === null) {
                return -1;
            }
            return a.sort - b.sort;
        })),function(field,index){return [_c(_vm.getFieldComponent(field[1]),{tag:"component",attrs:{"field":field[1]},model:{value:(_vm.formData[field[1].name]),callback:function ($$v) {_vm.$set(_vm.formData, field[1].name, $$v)},expression:"formData[field[1].name]"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has(field[1].name)),expression:"errors.has(field[1].name)"}],staticClass:"text-red-600 text-sm"},[_vm._v(_vm._s(_vm.errors.first(field[1].name)))])]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('items')),expression:"errors.has('items')"}],staticClass:"text-red-600 text-sm"},[_vm._v(_vm._s(_vm.errors.first('items')))])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }