<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <manufacturers-header :response="response"></manufacturers-header>
                        <div class="container mx-auto p-4 mb-8">
                            <!--<saved-filters :response="response">
                            </saved-filters>-->
                            <filterable-paginated-manufacturers-list :response="response">

                            </filterable-paginated-manufacturers-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import ManufacturersHeader from "../components/Headers/ManufacturersHeader";
    import SavedFilters from "../components/SavedFilters";
    import MainTemplate from "../templates/MainTemplate";
    import FilterablePaginatedManufacturersList from "../components/FilterablePaginatedManufacturersList";

    export default {
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            ManufacturersHeader,
            SavedFilters,
            FilterablePaginatedManufacturersList
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
