<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        Return Requests
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            Return for order #{{response.body.properties.get('order_reference')}}
                        </span>

                        <status-badge class="ml-2" :status="response.body.properties.get('status')"></status-badge>
                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                    Rental Request Items
<!--                                                    <svg @click="displayRfiDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">-->
<!--                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />-->
<!--                                                    </svg>-->
                                                </dt>

                                            </div>
                                            <return-request-items-list :response="response"></return-request-items-list>
                                        </dl>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <section v-if="response.body.actions
                        .filter(action =>
                            action.name !== 'update' &&
                            action.name !== 'update-invoice' &&
                            action.name !== 'add-new-item' &&
                            action.name !== 'search-products' &&
                            action.name !== 'update-quote'
                        ).count() > 0" class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <return-request-actions :response="response"></return-request-actions>
                            </div>
                        </section>
                    </div>

                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import StatusBadge from "@/components/StatusBadge";
import PageHeader from "@/v2/components/Header";
import RfiActions from "@/v2/components/Actions";
import ReturnRequestItemsList from "@/v2/paginatedLists/ReturnRequestItemsList";
import ReturnRequestActions from "@/v2/components/ReturnRequestActions";
export default {
    name: "ReturnRequest",
    data() {
        return {
        }
    },
    components: {
        ReturnRequestItemsList,
        PageHeader,
        StatusBadge,
        Button,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        ReturnRequestActions
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
    }
}
</script>

<style scoped>

</style>
