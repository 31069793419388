<template>
    <!-- Comments-->
    <section aria-labelledby="notes-title">

        <div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div class="divide-y divide-gray-200">
                <div class="px-4 py-6 sm:px-6">
                    <div class="flow-root">
                        <div class="mb-5">Order Activity</div>

                      <div v-if="addCommentAction" class="bg-white pt-5">
                        <div class="flex space-x-3">
                          <div class="flex-shrink-0">
                            <div class="h-10 w-10 rounded-full bg-secondary-lightpeach flex items-center justify-center ring-8 ring-secondary-lightbacka">
                              {{initials(currentUserName)}}
                            </div>
                          </div>
                          <div class="min-w-0 flex-1">
                            <form action="#">
                              <div>
                                <label for="comment" class="sr-only">Comment</label>
                                <textarea id="comment" name="comment" rows="3" class="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md" placeholder="Add a comment" v-model="comment"></textarea>
                                <div v-show="errors.has('comment')" class="text-red-600 text-sm">{{ errors.first('comment') }}</div>

                              </div>
                              <div class="mt-3 flex items-center justify-end">
                                <a-button @click.native="performAddComment" colourType="tertiary" :loading="submitting">Comment</a-button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                        <ul role="list" class="-mb-8">
                            <div v-for="(activity, index) in activities">
                                <li>
                                    <div class="relative pb-8">
                                        <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" v-if="index + 1 !== activities.count()"></span>
                                        <div class="relative flex items-start space-x-3">
                                            <div>
                                                <div class="relative" :class="{'px-0': activity.entity.properties.get('activity').startsWith('Comment'), 'px-1': !activity.entity.properties.get('activity').startsWith('Comment')}">
                                                    <div v-if="activity.entity.properties.get('activity').includes('Created order')" class="h-8 w-8 bg-secondary-lightpeach rounded-full ring-8 ring-secondary-lightbacka flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                                                        </svg>
                                                    </div>
                                                    <div v-else-if="activity.entity.properties.get('activity') === 'Marked as ready for quote'" class="h-8 w-8 bg-secondary-lightpeach rounded-full ring-8 ring-secondary-lightbacka flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                    <div v-else-if="activity.entity.properties.get('activity') === 'Quote Approved'" class="h-8 w-8 bg-green-500 rounded-full ring-8 ring-secondary-lightbacka flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                    <div v-else-if="activity.entity.properties.get('activity').includes('Quote Rejected')" class="h-8 w-8 bg-error rounded-full ring-8 ring-secondary-lightbacka flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </div>
                                                    <template v-else-if="activity.entity.properties.get('activity').startsWith('Comment')">
                                                        <div class="h-10 w-10 rounded-full bg-secondary-lightpeach flex items-center justify-center ring-8 ring-secondary-lightbacka flex items-center justify-center">
                                                            {{ initials(activity.entity.properties.get('causer')) }}
                                                        </div>
                                                        <span class="absolute -bottom-0.5 -right-1 rounded-tl px-0.5 py-px">
                                                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                              <path stroke-linecap="round" stroke-linejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                                          </svg>
                                                        </span>
                                                    </template>
                                                    <div v-else class="h-8 w-8 bg-secondary-lightpeach rounded-full ring-8 ring-secondary-lightbacka flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="min-w-0 flex-1 py-1.5">
                                                <div v-if="!activity.entity.properties.get('activity').startsWith('Comment')" class="text-sm text-gray-500">
                                                    <a href="#" class="font-medium text-gray-900">{{ activity.entity.properties.get('causer') }}</a>
                                                    {{activity.entity.properties.get('activity')}}
                                                    <span class="whitespace-nowrap">{{getNumberOfDaysElapsed(activity.entity.properties.get('date'))}} days ago</span>
                                                </div>
                                                <template v-else>
                                                    <div>
                                                        <div class="text-sm">
                                                            <a href="#" class="font-medium text-gray-900">{{ activity.entity.properties.get('causer') }}</a>
                                                        </div>
                                                        <p class="mt-0.5 text-sm text-gray-500">Commented {{getNumberOfDaysElapsed(activity.entity.properties.get('date'))}} days ago</p>
                                                    </div>
                                                    <div class="mt-2 text-sm text-gray-700">
                                                        <p>{{activity.entity.properties.get('activity').replace('Comment - ', '')}}</p>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import Siren from "super-siren";
import {mapGetters} from "vuex";
export default {
    name: "ActivityFeed",
    data(){
        return {
            comment: null,
            submitting: false,
            currentUserName: null,
            currentUserProfile: null
        }
    },
    components: {
        'a-button': Button
    },
    props:{
        response: {
            type: Object
        }
    },
    computed: {
        activities(){
            return this.response.body.entities.filter(function(entity){
                let activity = null;
                if(entity.rels.contains('activities')) {
                    activity = entity.entity.properties.get('activity');
                }else{
                    return entity.rels.contains('activities');
                }
                return entity.rels.contains('activities') &&
                    activity !== 'Added an order item' &&
                    !activity.includes('Quote request sent to') &&
                    activity !== 'Updated an order item' &&
                    activity !== 'Marked as quotes received' &&
                    activity !== 'Update Requested' &&
                    activity !== 'Quote Updated' &&
                    activity !== 'Removed an order item' &&
                    activity !== 'Invoice Added' &&
                    !activity.includes('uploaded to sage') &&
                    activity !=='Partial Delivery Event Added';
            });
        },
        addCommentAction(){
            return this.response.body.actions.filter(action => action.name === 'add-comment').first();
        },
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
            message: "getMessage",
            messageWithoutTimeout: "getMessageWithoutTimeout"
        }),
    },
    methods:{
        getNumberOfDaysElapsed(fromDate){
            let splitFromDate1 = fromDate.split(' ');
            let splitFromDate2 = splitFromDate1[1].split('/')
            let rejoinedDate = splitFromDate2[1] + '/' + splitFromDate2[0] + '/' + splitFromDate2[2] + ' ' + splitFromDate1[0];
            let date_1 = new Date(rejoinedDate);
            let date_2 = new Date();

            let difference = date_1.getTime() - date_2.getTime();
            let TotalDays = Math.ceil(difference / (1000 * 3600 * 24)) * -1;
            return TotalDays;
        },
        performAddComment() {
            this.submitting = true;
            this.addCommentAction.perform({'comment': this.comment}).then(res => {
                this.submitting = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                this.$router.go(0);
                this.$emit('success', res);
            }).catch(error => {
                this.submitting = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        initials(userName) {
            if (userName) {
                return userName
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        }
    },
    mounted(){
        let follow = Siren.Helper.follow;
        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
            let siren = res.body;
            return res;
        }, this).then(
            follow("current-user")
        )
            .then(res => {
                let siren = res.body;
                this.currentUserName = siren.properties.get('name');
                this.currentUserProfile = siren.properties.get('profile_image');
            }, this).catch(error => {
        })

        this.$forceUpdate();
    }
}
</script>

<style scoped>

</style>
