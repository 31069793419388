<template>
    <div class="flex flex-col" v-if="invoices.size > 0">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Invoices In</h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Reference
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Invoice PDF
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Short Description
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Tax Code
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Gross Value
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Net Value
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Vat Value
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Uploaded To Sage
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Linked Sales Invoice Status
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Action
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <template v-for="invoice in invoices">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ invoice.entity.properties.get('reference') }}
                        </td>
                        <download-pdf-icon-with-link :url="invoice.entity.properties.get('file')"></download-pdf-icon-with-link>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ invoice.entity.properties.get('short_description') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ invoice.entity.properties.get('tax_code') }}
                        </td>
                        <td :class="{'text-red-500' : invoice.entity.properties.get('based_on_gross_value')}"
                            class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{invoice.entity.properties.get('gross_value')}}
                        </td>
                        <td :class="{'text-red-500' : invoice.entity.properties.get('based_on_net_value')}"
                            class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{invoice.entity.properties.get('net_value')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{invoice.entity.properties.get('vat_value')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <template v-if="invoice.entity.properties.get('isUploaded')">
                                <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            </template>
                            <template v-else>
                                <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </template>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <a class="underline hover:no-underline" v-if="invoice.entity.properties.get('linked_sales_invoice')" :href="`/invoices/${invoice.entity.properties.get('linked_sales_invoice').uuid}`">{{invoice.entity.properties.get('linked_sales_invoice').reference}} - {{invoice.entity.properties.get('linked_sales_invoice').status}}</a>
                            <span v-else>No linked sales invoice</span>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <reconcile-order-form class="mb-2" v-if="invoice.entity.actions.get('update-invoice')"
                                                  :action="invoice.entity.actions.get('update-invoice')"
                                                  actionKey="update-invoice"
                                                  :onSuccessPath="$router.currentRoute.fullPath">
                            </reconcile-order-form>
                            <action-with-confirmation class="mb-2" v-if="invoice.entity.actions.get('mark-invoice-as-uploaded-to-sage')"
                                                  :action="invoice.entity.actions.get('mark-invoice-as-uploaded-to-sage')"
                                                  actionKey="mark-invoice-as-uploaded-to-sage"
                                                  :onSuccessPath="`/orders/${response.body.properties.get('id')}`">
                            </action-with-confirmation>
                            <reassign-invoice-form class="mb-2" v-if="invoice.entity.actions.get('reassign-invoice')"
                                                      :action="invoice.entity.actions.get('reassign-invoice')"
                                                      actionKey="reassign-invoice"
                                                      :onSuccessPath="`/orders/${response.body.properties.get('id')}`"
                                                        :isUploaded="invoice.entity.properties.get('isUploaded')">
                            </reassign-invoice-form>
                            <delete-invoice-in-confirmation v-if="invoice.entity.actions.get('delete-invoice')"
                                                    :action="invoice.entity.actions.get('delete-invoice')"
                                                    actionKey="delete-invoice"
                                                    :onSuccessPath="`/orders/${response.body.properties.get('id')}`"
                                                    :isButton="false">
                            </delete-invoice-in-confirmation>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ReconcileOrderForm from './ReconcileOrderForm';
    import DownloadPdf from "../pages/DownloadPdf";
    import DeleteInvoiceInConfirmation from "./DeleteInvoiceInConfirmation";
    import ActionWithForm from "./ActionWithForm";
    import ActionWithConfirmation from "./ActionWithConfirmation";
    import DownloadPdfIconWithLink from "./DownloadPdfIconWithLink";
    import ReassignInvoiceForm from "@/components/CustomForms/ReassignInvoiceForm";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                submittingByCard: false,
                invoiceFile: null,
                showPDF:false,
                quoteValue: null,
                includesVat:null
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        components: {
            DownloadPdfIconWithLink,
            ReconcileOrderForm,
            DownloadPdf,
            DeleteInvoiceInConfirmation,
            ActionWithConfirmation,
            ActionWithForm,
            ReassignInvoiceForm
        },
        computed: {
            invoices() {
                let invoices = this.response.body.entities.filter(entity => entity.rels.contains('invoice'));
                return invoices;
            },
        },
        mounted(){
            let quote =  this.response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("quote");
                })
                .first();
            if(quote){
                this.includesVat = quote.entity.properties.get('includes_vat')
                this.quoteValue = parseFloat(quote.entity.properties.get('total').replace('£', ''))
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
