<template>
    <div>

        <div class="container-wide notifications-list">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div
                        class="align-middle inline-block min-w-full"
                    >
                        <div class="min-w-full">
                            <template v-for="notification in notifications">
                                <ul class="relative divide-y divide-gray-200 border-b border-gray-200 rounded">
                                    <li
                                        class="relative pl-4 pr-6 py-5 bg-white hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6 mt-5">
                                        <div class="flex items-center justify-between space-x-4">
                                            <!-- Repo name and link -->
                                            <div class="min-w-0 space-y-3">
                                                <div class="flex items-center mb-1">
                                                    <span class="block">
                                                        <h2 class="text-sm font-medium">
                                                          <span class="cursor-pointer">
                                                            <span aria-hidden="true"></span>
                                                              <span v-if="!notification.entity.properties.get('is_read')" class="flex items-center">

                                                                <span
                                                                      aria-hidden="true"
                                                                      class="h-3 w-3 bg-red-400 rounded-full mr-2 flex-shrink-0" style="z-index: 2;">
                                                                    <span  class="h-2 w-2 rounded-full bg-red-400"></span>
                                                                </span>
                                                                    <span class="font-bold">
                                                                        {{notification.entity.properties.get('title')}}
                                                                    </span>
                                                                </span>
                                                                <template v-else>
                                                                    {{notification.entity.properties.get('title')}}
                                                                </template>
                                                          </span>
                                                        </h2>
                                                        <span v-html="notification.entity.properties.get('content')"></span>
                                                      </span>
                                                </div>
                                                <span class="relative group flex items-center cursor-pointer">
                                                    <span
                                                        class="text-xs text-gray-500 group-hover:text-gray-900 font-medium truncate">
                                                        {{ notification.entity.properties.get('created_at') }}
                                                  </span>
                                                </span>
                                            </div>
                                            <!-- Repo meta info -->

                                            <confirmation-action
                                                v-if="!notification.entity.properties.get('is_read')"
                                                :action="notification.entity.actions.first()"
                                                :action-key="notification.entity.properties.get('id')"
                                                onSuccessPath="/notifications"></confirmation-action>

                                            <span class="pr-5" v-else>
                                                <span class="text-sm">read </span>
                                                <span class="text-xs text-gray-500 group-hover:text-gray-900 font-medium truncate">{{ notification.entity.properties.get('read_at_human')
                                                    }}</span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-wide">
            <pagination
                :response="response"
                @navigate="paginationNavigation($event)"
                @navigateAdditionalNext="paginationNavigation($event)"
                @navigateAdditionalPrev="paginationNavigation($event)"
            />
        </div>

    </div>
</template>

<script>
import Pagination from "@/v2/paginatedLists/Pagination";
import ConfirmationAction from "@/v2/components/ConfirmationAction";

export default {
    components: {
        ConfirmationAction,
        Pagination,
    },
    props: {
        response: {
            type: Object,
        },
    },
    computed: {
        notifications() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('notification'));
        },
    },
    methods: {
        navigateToItem(productEntity) {
            let selfLink = productEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        }
    },
}
</script>

<style lang="scss">
    .notifications-list a{
        text-decoration: underline;
    }
</style>
