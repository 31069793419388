<template>
    <span class="flex flex-col " :class="{'hidden' : !isVisible}">
        <a href="#" @click="changeCategoryId([category.entity.properties.get('id'), parentCategoryId])" class=" cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10" :class="{'text-gray-900': categoryId === category.entity.properties.get('id'), 'text-gray-500 hover:text-gray-700': categoryId !== category.entity.properties.get('id')}">
            <span class="">{{category.entity.properties.get('name')}}
                <template v-if="types && types.filter((type) => type.entity.properties.get('category_id') === category.entity.properties.get('id')).count() > 0">
                    <span class="inline-flex sm:pl-4">
                        <svg v-if="categoryId === category.entity.properties.get('id')" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z"/></svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"/></svg>
                    </span>
                </template>
            </span>
            <span v-if="categoryId === category.entity.properties.get('id') && currentTypeId === null" aria-hidden="true" class="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"></span>

            <span v-else aria-hidden="true" class="bg-gray-200 absolute inset-x-0 bottom-0 h-0.5"></span>
        </a>
        <template v-for="type in types.filter((type) => type.entity.properties.get('category_id') === category.entity.properties.get('id'))">
            <a href="#" @click="changeTypeId(type.entity.properties.get('id'))" class=" cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10" :class="{'text-gray-900': categoryId === category.entity.properties.get('id') && currentTypeId === type.entity.properties.get('id'), 'text-gray-500 hover:text-gray-700': categoryId !== category.entity.properties.get('id') || currentTypeId !== type.entity.properties.get('id'), 'hidden' : category.entity.properties.get('id') !== categoryId}">
                <span class="">{{type.entity.properties.get('name')}}

                </span>
                <span v-if="categoryId === category.entity.properties.get('id') && currentTypeId === type.entity.properties.get('id')" aria-hidden="true" class="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"></span>

                <span v-else aria-hidden="true" class="bg-gray-200 absolute inset-x-0 bottom-0 h-0.5"></span>
            </a>
        </template>
    </span>


</template>
<script>
export default {
  name: 'OrderSubCategories',
  props: {
    category: {},
    categoryId: {},
    subCategories: {},
      parentCategoryId:{},
      isVisible:{},
      types:{
        type: Object,
          default: null
      },
      currentTypeId:{
        type: String,
          default: null
      }
  },
    methods: {
      changeCategoryId(value){
          this.$emit("categoryChanged", value);
          this.$emit('typeIdChanged', null);
      },
        categoryChanged(value)
        {
            this.$emit("categoryChanged", value);
        },
        changeTypeId(value){
          this.$emit('typeIdChanged', value);
        }
    }
}
</script>
<style lang="scss">
.order-items label {
  display: none;
}

</style>
