<template>
    <tr class="bg-white order-item-row" >
        <td @click="showQa(orderItemEntity.href)" class="px-3 py-4 whitespace-normal border-b border-gray-200 text-sm leading-5 text-gray-500 align-top cursor-pointer">
            {{orderItemEntity.name}}
        </td>
        <td @click="showQa(orderItemEntity.href)" class="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top cursor-pointer">
            {{orderItemEntity.date_of_works}}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <span v-if="removeAction" class="cursor-pointer font-normal underline text-gray-400" @click="removeItem()">remove</span>
        </td>
        <large-model v-if="showQaPanel"  @removeFromDisplay="showQaPanel = false" additionalClasses="bg-primary-100" :displayConfirmButton="false">
            <div class="bg-primary-100 p-5">
            <QASummary v-if="qaResponse" :response="qaResponse" :showActions="false"
                       :show-construction-drawing-p-d-f="showConstructionDrawingPDF" :show-p-d-f="showPDF"
                       @togglePdf="showPDF=!showPDF"/>
            </div>
        </large-model>
    </tr>

</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from "../mixins/orderItemsUpdate";
    import LargeModel from "@/v2/components/LargeModel.vue";
    import QASummary from "@/v2/pages/QASummary.vue";
    import Siren from "super-siren";
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                displayQaDetails: false,
                showPDF: false,
                showConstructionDrawingPDF: false,
                showQaPanel: false,
                qaResponse: null
            }
        },
        inject: ["$validator"],
        components: {
            QASummary,
            LargeModel,
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            orderItemEntity: {
                type: Object,
            },
            scope: {
                type: String
            },
            response: {
                type: Object
            }
        },
        computed: {
            updateAction() {
                return this.orderItemEntity.entity.actions.filter(action => action.name === 'update').first();
            },
            removeAction() {
                //return this.orderItemEntity.entity.actions.filter(action => action.name === 'delete').first();
            },
            updateActionData() {
                if (!this.updateAction) {
                    return {};
                }
                if (!this.updateAction.fields) {
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
        },
        methods: {
            update() {
                return this.updateAction.perform(this.updateActionData);
            },
            removeItem() {
                return this.removeAction.perform().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'Item removed successfully.'
                    );
                    this.$router.go(0);
                }).catch(error => {
                    alert('There was an error processing your request.' + error);
                });
            },
            change() {
                this.$emit('change');
            },
            showQa(url){
                let parts = url.split("/api");
                Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                Siren.get(url).then(res => {

                    this.qaResponse = res;
                     this.showQaPanel = true;
                }, this);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
