<template>
    <div class="bg-white overflow-hidden shadow rounded-lg md:w-2/5 mx-auto md:mt-24 reports-form">
        <template v-if="!exported">
            <div class="px-4 py-5 sm:p-6">
                <div>
                    <property-or-field @input="change" title="To" :action="action" fieldId="to_date" v-model="actionData['to_date']"></property-or-field>
                </div>
            </div>
            <div class="bg-gray-50 px-4 py-4 sm:px-6">
                <a-button @click.native="saveData('0')" :loading="loading">Export</a-button>
            </div>
        </template>
        <portal to="destination">
            <modal v-if="displayModal" :submitting="submittingModal" :title="title" :confirmationString='finalConfirmationString' @removeFromDisplay="displayModal = false" @perform="perform()"></modal>
        </portal>
    </div>
</template>

<script>
    import PropertyOrField from "@/components/PropertyOrField";
    import fileDownload from "js-file-download";
    import Modal from '@/v2/components/Modal';
    import Button from "@/v2/buttons/Button";
    export default {
        inject: ["$validator"],
        data() {
            return {
                loading:false,
                displayModal:false,
                submittingModal:false,
                title:null,
                finalConfirmationString: null,
                exported:false,
                exportType:"PI"
            }
        },
        props: {
            action: {
                type: Object,
                default: {}
            },
        },
        components: {
            PropertyOrField,
            Modal,
            'a-button': Button
        },
        computed: {
            actionData(){
                var that = this;
                if(!this.action){
                    return {};
                }
                if(!this.action.fields){
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
        },
        methods: {
            change(){
                this.$emit('change');
            },
            update(continueOnFail){
                this.actionData['continue_on_fail'] = continueOnFail;
                return this.action.perform(this.actionData).responseType('blob');
            },
            async saveData() {
                this.loading = true;
                this.update().then(res => {
                    if(res.status === 204){
                        alert('No matching invoices or credit notes were found.');
                        this.loading = false;
                        return;
                    }
                    if(res.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                    {
                        debugger;
                        var fileURL = window.URL.createObjectURL(res.xhr.response);
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', `${this.exportType}-export-${new Date().toJSON().slice(0,10)}.xlsx`);
                        document.body.appendChild(fileLink);
                        fileLink.click();

                        this.$store.dispatch(
                            "setMessage",
                            'The export was processed successfully'
                        );

                        if(this.exportType === "PI"){
                            this.$emit('success');
                        }
                    }
                    else if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');

                        this.$store.dispatch(
                            "setMessage",
                            'The export was processed successfully'
                        );

                        this.$emit('success');
                    }
                    this.loading = false;
                }).catch(error => {
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            perform() {
                this.submittingModal = true;
                this.$store.dispatch(
                    "clearMessagesWithoutTimeoutNow"
                );
                this.saveData('1');
                this.displayModal = false;
                this.submittingModal = false;
            },
        },
    }
</script>

<style>
    .reports-form label.block{display:none;}
    .reports-form .form-group label{display:block;}
</style>
