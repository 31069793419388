<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link">

        <div slot-scope="{ response }">
            <loading v-if="!response"></loading>
            <template v-else>
                <page-header :logo="logo">
                    <div class="md:flex justify-between w-full items-center">
                        Supplier Ratings
                    </div>
                </page-header>
                <supplier-rating-paginated-list :response="response" ></supplier-rating-paginated-list>
            </template>
        </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import Button from "@/v2/buttons/Button";
    import SupplierRatingPaginatedList from "@/v2/paginatedLists/SupplierRatingPaginatedList";
    export default {
    name: "SupplierRatings",
        components: {
            SupplierRatingPaginatedList,
            FormAction,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader,
            'a-button': Button
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {
        }
    }

</script>

<style scoped>

</style>
