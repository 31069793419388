<template>
    <widget-stat type="schedules"></widget-stat>
</template>

<script>

    import WidgetStat from "./WidgetStat";

    export default {
        components: {
            WidgetStat,
        },
    };
</script>
