<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Order
                    #{{response.body.properties.get('order_reference')}}<span v-if="response.body.properties.get('purchase_order_number')"> / PO #{{response.body.properties.get('purchase_order_number')}}</span> </h2>
                <span class="flex items-center">
                <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty() && action.name !=='rate-order' &&
                action.name !== 'select-quote-and-ask-cms-to-beat')"
                     class="mr-1">
                    <email-preview-action v-if="actionWithFields[0] === 'send-quotation-request'"
                                          :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :onSuccessPath="$router.currentRoute.fullPath"></email-preview-action>
                    <select-quote-form v-else-if="actionWithFields[0] === 'select-quote-for-approval'"
                                      :action="actionWithFields[1]"
                                      :actionKey="actionWithFields[0]"
                                      :onSuccessPath="$router.currentRoute.fullPath"
                                      :secondAction="remaningActions.filter(action => !action.fields.isEmpty() && action.name ==='select-quote-and-ask-cms-to-beat').first()"
                    ></select-quote-form>
                    <goods-in-note-action-with-form v-else-if="actionWithFields[0] === 'add-goods-in-note' || actionWithFields[0] === 'mark-as-fully-delivered' "
                                                    :response="response" :action="actionWithFields[1]"
                                                    :actionKey="actionWithFields[0]"
                                                    :onSuccessPath="$router.currentRoute.fullPath"></goods-in-note-action-with-form>

                    <split-order-action-with-form v-else-if="actionWithFields[0] === 'split-order'"
                                                    :response="response" :action="actionWithFields[1]"
                                                    :actionKey="actionWithFields[0]"
                                                    :onSuccessPath="$router.currentRoute.fullPath"></split-order-action-with-form>

                    <returns-form v-else-if="actionWithFields[0] === 'create-return-request'"
                                  :response="response" :action="actionWithFields[1]"
                                  :actionKey="actionWithFields[0]"
                                  :onSuccessPath="$router.currentRoute.fullPath">
                    </returns-form>
                    <reconcile-order-form v-else-if="actionWithFields[0] === 'reconcile-order' || actionWithFields[0] === 'create-invoice-in'"
                                          :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :onSuccessPath="$router.currentRoute.fullPath"
                    >
                    </reconcile-order-form>
                    <new-credit-note-form v-else-if="actionWithFields[0] === 'add-credit-note'"
                                          :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :onSuccessPath="$router.currentRoute.fullPath"></new-credit-note-form>
                    <action-with-confirmation v-else-if="actionWithFields[0] === 'mark-as-paid'" :action="actionWithFields[1]" :actionKey="actionWithFields[0]" :onSuccessPath="$router.currentRoute.fullPath"></action-with-confirmation>
                    <ready-for-quote-action v-else-if="actionWithFields[0] === 'mark-as-ready-for-quote'" :action="actionWithFields[1]" :actionKey="actionWithFields[0]" :properties="response.body.properties" :isNegativeAction="isActionNegative(actionWithFields[0])" :isPositiveAction="isActionPositive(actionWithFields[0])" :onSuccessPath="$router.currentRoute.fullPath"></ready-for-quote-action>
                    <review-quotes v-else-if="actionWithFields[0] === 'reject-competing-quote'" :response="response"
                                   :firstAction="actionWithFields[1]" :secondAction="remaningActions.filter(action => action.fields.isEmpty() && action.name ==='approve-competing-quote').first()"
                                   :onSuccessPath="$router.currentRoute.fullPath"></review-quotes>

                    <action-with-form v-else :action="actionWithFields[1]"
                                      :actionKey="actionWithFields[0]"
                                      :isPositiveAction="isActionPositive(actionWithFields[0])"
                                      :isNegativeAction="isActionNegative(actionWithFields[0])"
                                      :onSuccessPath="$router.currentRoute.fullPath"
                    ></action-with-form>


                </div>
                <div v-for="actionWithoutFields in response.body.actions.filter(action => action.fields.isEmpty() && action.name !== 'approve-competing-quote')" class="mr-1">
                    <action-with-confirmation :action="actionWithoutFields[1]" :actionKey="actionWithoutFields[0]" :properties="response.body.properties" :isNegativeAction="isActionNegative(actionWithoutFields[0])" :isPositiveAction="isActionPositive(actionWithoutFields[0])" :onSuccessPath="$router.currentRoute.fullPath"></action-with-confirmation>
                </div>
                <status-badge :status="response.body.properties.get('status')" :deliveryDate="scheduledDeliveryDate"></status-badge>
                <status-badge class="ml-2" v-if="response.body.properties.get('is_awaiting_credit')" status="awaiting-credit"></status-badge>
                    <template v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty() && action.name ==='rate-order')">
                     <rate-order-form
                                        :action="actionWithFields[1]"
                                       :actionKey="actionWithFields[0]"
                                       :isPositiveAction="isActionPositive(actionWithFields[0])"
                                       :isNegativeAction="isActionNegative(actionWithFields[0])"
                                       :onSuccessPath="$router.currentRoute.fullPath"
                     ></rate-order-form>
                    </template>
            </span>
            </div>
        </div>
    </header>
</template>

<script>
    import Button from './Button';
    import ActionWithConfirmation from './ActionWithConfirmation';
    import ActionWithForm from './ActionWithForm';
    import GoodsInNoteActionWithForm from './GoodsInNoteActionWithForm';
    import ReturnsForm from './ReturnsForm';
    import EmailPreviewAction from './EmailPreviewAction';
    import SplitOrderActionWithForm from './SplitOrderActionWithForm';
    import ReconcileOrderForm from './ReconcileOrderForm';
    import StatusBadge from "./StatusBadge";
    import SelectQuoteForm from "./SelectQuoteForm";
    import NewCreditNoteForm from "@/components/NewCreditNoteForm";
    import ReadyForQuoteAction from "@/components/ReadyForQuoteAction";
    import RateOrderForm from "@/components/CustomForms/RateOrderForm";
    import ReviewQuotes from "@/components/CustomForms/ReviewQuotes";

    export default {
        inject: ["$validator"],
        components: {
            StatusBadge,
            'a-button': Button,
            ActionWithConfirmation,
            ActionWithForm,
            EmailPreviewAction,
            GoodsInNoteActionWithForm,
            ReturnsForm,
            SplitOrderActionWithForm,
            ReconcileOrderForm,
            SelectQuoteForm,
            NewCreditNoteForm,
            ReadyForQuoteAction,
            RateOrderForm,
            ReviewQuotes
        },
        props: {
            response: {
                type: Object,
            },
        },
        created() {
            document.title = 'Order #' + this.response.body.properties.get('order_reference') + ' - Elmech';
        },
        computed: {
            remaningActions() {
                return this.response.body.actions
                    .filter(action =>
                        action.name !== 'update' &&
                        action.name !== 'update-invoice' &&
                        action.name !== 'add-new-item' &&
                        action.name !== 'search-products'
                    );
            },
            scheduledDeliveryDate() {
                let scheduledDelivery = this.response.body.properties.get('scheduled_delivery');

                if (!scheduledDelivery) {
                    return null;
                }

                return scheduledDelivery;
            },
        },
        methods:{
            isActionPositive(action){
                if(action === 'mark-as-ready-for-quote' || action === 'mark-as-take-off' || action === 'approve-quotes' || action === 'mark-as-ready-for-order'){
                    return true;
                }
                return false;
            },
            isActionNegative(action){
                if(action ==='reject-order' || action === 'delete-order' || action === 'reject-quotes'){
                    return true;
                }
                return false;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
