import { render, staticRenderFns } from "./ExportOnSiteIdForm.vue?vue&type=template&id=6b748a45&scoped=true&"
import script from "./ExportOnSiteIdForm.vue?vue&type=script&lang=js&"
export * from "./ExportOnSiteIdForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b748a45",
  null
  
)

export default component.exports