<template>
    <div>
        <a-button class="mr-3" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>

        <slide-over v-if="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
        :confirmationString='`Are you sure you want to \"${action.title}\"`'
        @removeFromDisplay="displayModal = false" @perform="perform()">
        <template v-for="(field, index) in action.fields">
            <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[1].name]">
            </component>
            <div v-show="errors.has(field[1].name)" class="text-red-600">{{ errors.first(field[1].name) }}</div>
        </template>

        <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </slide-over>
    </div>
</template>

<script>
    import Button from './Button';
    import Modal from './Modal';
    import SlideOver from "./SlideOver";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false
            }
        },
        components: {
            SlideOver,
            'a-button': Button,
            Modal
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            items:{
                type: Array,
                default: null
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                if(this.items){
                    this.formData.items = JSON.stringify(this.items);
                }
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>
