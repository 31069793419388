<template>
    <div v-if="advertFile && showAdvert" class=" bg-white overflow-hidden shadow rounded-lg h-full m-6 block relative">
        <div class="h-full flex flex-col justify-center items-center ">
            <div class="">
                <div class="max-w-full"><a :href="advertLink" target="_blank"><img class="mx-auto" :src="advertFile"></a></div>
            </div>
        </div>
        <button class="absolute top-0 right-0" @click="showAdvert = false">
            <svg class="h-5 w-5 text-gray-300 hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
        </button>
    </div>
</template>

<script>
import Siren from "super-siren";
import {mapGetters} from "vuex";

export default {
    name: "MenuAdvert",
    data() {
        return {
            showAdvert: false,
            advertFile : null,
            advertLink : null
        };
    },
    created() {
        let follow = Siren.Helper.follow;

        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(
                    follow("dashboards")
                )
                .then(res => {
                    var properties = res.body.properties;
                    this.advertFile = process.env.VUE_APP_API_URL + '/storage/adverts/' + properties.get('advert_file');
                    this.advertLink = properties.get('advert_link');
                    this.showAdvert = true;
                }, this);
        })
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        })
    }
}
</script>

<style scoped>

</style>
