<template>
    <div>
        <a-button class="mr-3" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>

        <slide-over-with-2-buttons v-show="displayModal" :submitting="submittingModal" :submittingSecond="submittingSecond" :title="action.title" :data-cy="`action-button-${action.name}`"
        :confirmationString='`Are you sure you want to \"${action.title}\"`'
        @removeFromDisplay="displayModal = false" @firstPerform="perform()" @secondPerform="secondPerform" firstButtonTitle="Select Quote" :secondButtonTitle="getSecondButtonTitle()" :showSecondButton="showSelectCompetingQuoteButton">
        <template v-for="(field, index) in action.fields">
            <component v-if="field[1].type !== 'filterable-select' && field[1].name !== 'immediate_payment'" :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[1].name]">
            </component>
            <component v-else-if="field[1].type !== 'filterable-select' && field[1].name === 'immediate_payment'" @input="immediatePaymentChanged"  :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[1].name]">
            </component>
            <component v-else-if="field[1].type === 'filterable-select'" :is="getFieldComponent(field[1])" @input="supplierChanged" :options="field[1].options" :title="field[1].title" v-model="formData[field[1].name]" :data-cy="'filterable'">
            </component>
            <div v-show="errors.has(field[1].name)" class="text-red-600">{{ errors.first(field[1].name) }}</div>
        </template>

        <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </slide-over-with-2-buttons>
    </div>
</template>

<script>
    import Button from './Button';
    import Modal from './Modal';
    import fileDownload from "js-file-download";
    import SlideOverWith2Buttons from "@/components/SlideOverWith2Buttons";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                submittingSecond: false,
                showSelectCompetingQuoteButton: true
            }
        },
        components: {
            SlideOverWith2Buttons,
            'a-button': Button,
            Modal
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            secondAction: {
                type: Object,
                default: null
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            getSecondButtonTitle(){
                if(this.secondAction === null){
                    return "";
                }
                return "Select quote with CMS compete.";
            },
            secondPerform() {
                this.submittingSecond = true;
                this.secondAction.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingSecond = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingSecond = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingSecond = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            supplierChanged(value){
                if(parseInt(this.formData['cms_supplier_id']) === parseInt(value) ||
                    (this.formData['immediate_payment'] !== 'no' && this.formData['immediate_payment'] !== null)){
                    this.showSelectCompetingQuoteButton = false;
                }else{
                    this.showSelectCompetingQuoteButton = true;
                }
            },
            immediatePaymentChanged(value){
                console.log(value);
                if(parseInt(this.formData['cms_supplier_id']) === this.formData['supplier_id'] ||
                    (value !== 'no' && value !== null)){
                    this.showSelectCompetingQuoteButton = false;
                }else{
                    this.showSelectCompetingQuoteButton = true;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
