<template>
    <widget-stat type="all"></widget-stat>
</template>

<script>


export default {
    components: {
        WidgetStat: () => import('./WidgetStat.vue')
    },
};
</script>
