<template>
    <div class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-3 sm:grid-cols-4 pt-6">
        <div class="border md:rounded-lg mb-8 bg-white grid grid-cols-3 col-span-3 section-links">
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('quality-assessments')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('quality-assessments')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M88 48C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72C16 58.75 26.75 48 40 48H88zM488 72C501.3 72 512 82.75 512 96C512 109.3 501.3 120 488 120H184C170.7 120 160 109.3 160 96C160 82.75 170.7 72 184 72H488zM488 232C501.3 232 512 242.7 512 256C512 269.3 501.3 280 488 280H184C170.7 280 160 269.3 160 256C160 242.7 170.7 232 184 232H488zM488 392C501.3 392 512 402.7 512 416C512 429.3 501.3 440 488 440H184C170.7 440 160 429.3 160 416C160 402.7 170.7 392 184 392H488zM16 232C16 218.7 26.75 208 40 208H88C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232zM88 368C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392C16 378.7 26.75 368 40 368H88z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">QA List</p>
                        <p>List and view individual QAs</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('templates')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('templates')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M144 416c-17.67 0-32 14.33-32 32s14.33 32.01 32 32.01s32-14.34 32-32.01S161.7 416 144 416zM211.2 32H104C46.66 32 0 78.66 0 136v16C0 165.3 10.75 176 24 176S48 165.3 48 152v-16c0-30.88 25.12-56 56-56h107.2C244.7 80 272 107.3 272 140.8c0 22.66-12.44 43.27-32.5 53.81L167 232.8C137.1 248 120 277.9 120 310.6V328c0 13.25 10.75 24.01 24 24.01S168 341.3 168 328V310.6c0-14.89 8.188-28.47 21.38-35.41l72.47-38.14C297.7 218.2 320 181.3 320 140.8C320 80.81 271.2 32 211.2 32z"/></svg>

                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">QA Categories</p>
                        <p>Manage QA Categories / Templates</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('pre-checks')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('pre-checks')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M320 64H280h-9.6C263 27.5 230.7 0 192 0s-71 27.5-78.4 64H104 64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64zM80 112v24c0 13.3 10.7 24 24 24h88 88c13.3 0 24-10.7 24-24V112h16c8.8 0 16 7.2 16 16V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V128c0-8.8 7.2-16 16-16H80zm88-32a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM105.8 229.3l-.4 1.2c-4.4 12.5 2.1 26.2 14.6 30.6s26.2-2.1 30.6-14.6l.4-1.2c1.1-3.2 4.2-5.3 7.5-5.3h58.3c8.4 0 15.1 6.8 15.1 15.1c0 5.4-2.9 10.4-7.6 13.1l-44.3 25.4c-7.5 4.3-12.1 12.2-12.1 20.8V328c0 13.3 10.7 24 24 24c13.1 0 23.8-10.5 24-23.6l32.3-18.5c19.6-11.3 31.7-32.2 31.7-54.8c0-34.9-28.3-63.1-63.1-63.1H158.6c-23.7 0-44.8 14.9-52.8 37.3zM224 416a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">QA Pre-Checks</p>
                        <p>List and view QA Pre-Checks</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasSagesLink() {
            return this.hasLink('sages') || this.hasLink('sage-descriptions') || this.hasLink('sage-nominal-codes');
        },
    },
}
</script>

<style scoped>

</style>
