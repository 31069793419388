<template>
    <div>
        <a-button v-if="showButton" :class="additionalButtonClasses" @click.native="$emit('displayForm')">
          {{title ? title : action.title}}
        </a-button>
        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="$emit('removeFromDisplay')" @perform="perform()">

            <component v-if="getField('name')" :is="getFieldComponent(getField('name'))" :field="getField('name')" v-model="formData[getField('name').name]">
            </component>
            <div v-show="errors.has(getField('name').name)" class="text-red-600">{{ errors.first(getField('name').name) }}</div>

            <component v-if="getField('sage_reference')" :is="getFieldComponent(getField('sage_reference'))" :field="getField('sage_reference')" v-model="formData[getField('sage_reference').name]">
            </component>
            <div v-show="errors.has(getField('sage_reference').name)" class="text-red-600">{{ errors.first(getField('sage_reference').name) }}</div>

            <component v-if="getField('currency')" :is="getFieldComponent(getField('currency'))" :field="getField('currency')" v-model="formData[getField('currency').name]">
            </component>
            <div v-show="errors.has(getField('currency').name)" class="text-red-600">{{ errors.first(getField('currency').name) }}</div>

            <component v-if="getField('credit_limit')" :is="getFieldComponent(getField('credit_limit'))" :field="getField('credit_limit')" v-model="formData[getField('credit_limit').name]">
            </component>
            <div v-show="errors.has(getField('credit_limit').name)" class="text-red-600">{{ errors.first(getField('credit_limit').name) }}</div>

            <component v-if="getField('credit_limit_start_date')" :is="getFieldComponent(getField('credit_limit_start_date'))" :field="getField('credit_limit_start_date')" v-model="formData[getField('credit_limit_start_date').name]">
            </component>
            <div v-show="errors.has(getField('credit_limit_start_date').name)" class="text-red-600">{{ errors.first(getField('credit_limit_start_date').name) }}</div>

            <component v-if="getField('payment_terms_in_days')" :is="getFieldComponent(getField('payment_terms_in_days'))" :field="getField('payment_terms_in_days')" v-model="formData[getField('payment_terms_in_days').name]">
            </component>
            <div v-show="errors.has(getField('payment_terms_in_days').name)" class="text-red-600">{{ errors.first(getField('payment_terms_in_days').name) }}</div>

            <component v-if="getField('payment_terms_from')" :is="getFieldComponent(getField('payment_terms_from'))" :field="getField('payment_terms_from')" v-model="formData[getField('payment_terms_from').name]">
            </component>
            <div v-show="errors.has(getField('payment_terms_from').name)" class="text-red-600">{{ errors.first(getField('payment_terms_from').name) }}</div>

            <component v-if="getField('notes')" :is="getFieldComponent(getField('notes'))" :field="getField('notes')" v-model="formData[getField('notes').name]">
            </component>
            <div v-show="errors.has(getField('notes').name)" class="text-red-600">{{ errors.first(getField('notes').name) }}</div>

            <component v-if="getField('type')" :is="getFieldComponent(getField('type'))" :field="getField('type')" v-model="formData[getField('type').name]">
            </component>
            <div v-if="getField('type')" v-show="errors.has(getField('type').name)" class="text-red-600">{{ errors.first(getField('type').name) }}</div>


            <component v-if="getField('tax_code')" :is="getFieldComponent(getField('tax_code'))" :field="getField('tax_code')" v-model="formData[getField('tax_code').name]">
            </component>
            <div v-if="getField('tax_code')" v-show="errors.has(getField('tax_code').name)" class="text-red-600">{{ errors.first(getField('tax_code').name) }}</div>

            <component v-if="getField('short_description')" :is="getFieldComponent(getField('short_description'))" :field="getField('short_description')" v-model="formData[getField('short_description').name]">
            </component>
            <div v-if="getField('short_description')" v-show="errors.has(getField('short_description').name)" class="text-red-600">{{ errors.first(getField('short_description').name) }}</div>

            <component v-if="getField('nominal_code')" :is="getFieldComponent(getField('nominal_code'))" :field="getField('nominal_code')" v-model="formData[getField('nominal_code').name]">
            </component>
            <div v-if="getField('nominal_code')" v-show="errors.has(getField('nominal_code').name)" class="text-red-600">{{ errors.first(getField('nominal_code').name) }}</div>

        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import TextAreaInput from "@/components/Action/Fields/TextAreaInput";

export default {
    name: "UpdateSupplierForm",
    data() {
        return {
            submittingModal: false
        }
    },
    components: {
        SlideOver,
        'a-button': Button,
        TextAreaInput
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        },
        showButton:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            if(!this.action.fields.filter(field => field.name === fieldName)){
                return null;
            }
            return this.action.fields.filter(field => field.name === fieldName).first();
        }
    },
}
</script>

<style scoped>

</style>
