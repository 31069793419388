<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Certificates</h2>

                <div class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty())"
                         class="mr-1">
                        <action-with-form :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="hasOutstandingOnboardingSteps"
                                          onSuccessPath="/"
                                          @success="success(actionWithFields[0])"
                        ></action-with-form>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import ActionWithForm from "../ActionWithForm";
    import Siren from "super-siren";
    import {mapGetters} from "vuex";
    import onboardingRedirect from "../../mixins/onboardingRedirect";
    export default {
        mixins: [onboardingRedirect],
        inject: ["$validator"],
        components: {
            ActionWithForm
        },
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'filter' && action.name !== 'add-certificate')
            },
        },
        props: {
            response: {
                type: Object,
            },
        },
        methods: {
            success(key) {
                this.$router.go(0);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
