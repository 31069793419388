<template>
    <div id="users" class="flex flex-col" >
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8" >
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr v-if="filterAction">
                    <th colspan=1000 class="p-8  bg-gray-50">
                        <generic-table-filter :action="filterAction"
                                              :response="response"
                                              :actionBaseUrl="$route.path"
                        ></generic-table-filter>
                    </th>
                </tr>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Name
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Email
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Contact Number
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Roles
                    </th>
                </tr>
                </thead>
                <tbody v-if="users.size > 0" class="bg-white">
                <template v-for="user in users">
                    <tr @click="navigateToItem(user)" class="border-b border-gray-200 last:border-b-0 hover:bg-gray-100 cursor-pointer">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ user.entity.properties.get('name') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ user.entity.properties.get('email') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ user.entity.properties.get('contact_number') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{user.entity.properties.get('roles')}}
                        </td>
                    </tr>
                </template>
                </tbody>
                <tbody v-else>
                <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                        No users found.
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionWithForm from "../ActionWithForm";
    import ActionWithConfirmation from "../ActionWithConfirmation";
    import Button from "@/components/Button";
    import GenericTableFilter from "@/components/GenericTableFilter";
    export default {
        components: {
            ActionWithForm,
            ActionWithConfirmation,
            GenericTableFilter,
            'a-button': Button
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            users() {
                let users = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("user");
                    });
                if(!users){
                    return null;
                }

                return users;
            },
            createUserAction() {
                return this.response.body.actions.filter(action => action.name === 'add-new-user').first()
            },
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            createOperativeAction() {
                return this.response.body.actions.filter(action => action.name === 'add-new-operative').first()
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            },
            success(key) {
                this.$router.go(0);
            },
            navigateToItem(user) {
                let link = user.entity.links.filter(link => link.rels.contains('self'));
                if (link.first() === undefined){return;}
                let selfLink = link.first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            },
            getToCertificatesPage(user){
                console.log(user);
                user.entity.links.filter(link => link.rels.contains('certificates'))
                    .map(link => link.href)
                    .forEach(href => {
                        let parts = href.split('/api');
                        this.$router.push(parts[1]);
                    });
            }
        },
        mounted(){
            if(this.$route.hash == '#users') {
                document.querySelector(this.$route.hash).scrollIntoView();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
