<template>
    <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
    <div class="fixed z-10 inset-0 overflow-y-auto z-50 cursor-default">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" style="height:100%">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle min-w-90 sm:p-6" style="height:90%" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" @click="removeFromDisplay" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                        <!-- Heroicon name: x -->
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="sm:flex sm:items-start flex-col h-full w-full">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2" id="modal-headline">
                        Operative Location
                    </h3>
                    <div class="mt-3 text-center sm:mt-0 sm:text-left relative h-full w-full">
                        <div class="bg-white overflow-hidden h-full">
                            <div class="sm:py-6 h-full"><div class="flex items-center h-full">
                                <div class="w-0 flex-1 h-full pb-5 sm:pb-6">
                                    <GmapMap
                                        :center="{lat:x, lng:y}"
                                        :zoom="17"
                                        map-type-id="terrain"
                                        class="h-full"
                                        style="min-height: 200px"
                                    >
                                        <GmapMarker
                                            :position="{lat: x, lng: y}"
                                            :clickable="true"
                                            :draggable="false"
                                        />
<!--                                        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">-->
<!--                                            {{infoContent}}-->
<!--                                        </gmap-info-window>-->
                                    </GmapMap>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline" v-if="x && y">
                        Coordinates: {{x}}, {{y}}
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        props: {
            x:{
              type: Number
            },
            y:{
                type: Number
            }
        },
        data() {
            return {
                file: null,
                mimeType: null
            }
        },
        created() {
            // this.downloadWithVueResource();
        },
        methods: {
            removeFromDisplay() {
                this.$emit('removeFromDisplay');
            },
            perform() {
                this.$emit('perform');
            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        }
    }
</script>

<style scoped>
</style>
