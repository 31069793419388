import { render, staticRenderFns } from "./PaymentTypesInput.vue?vue&type=template&id=250a2284&"
import script from "./PaymentTypesInput.vue?vue&type=script&lang=js&"
export * from "./PaymentTypesInput.vue?vue&type=script&lang=js&"
import style0 from "./PaymentTypesInput.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports