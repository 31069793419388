<template>
    <div class="fixed inset-0 overflow-hidden z-10">
        <div class="absolute inset-0 overflow-hidden">
            <section class="absolute inset-y-0 pl-16 max-w-full right-0 flex">
                <div class="w-screen max-w-md">
                    <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                        <div class="flex-1 h-0 overflow-y-auto">
                            <header class="space-y-1 py-6 px-4 bg-gray-700 sm:px-6">
                                <div class="flex items-center justify-between space-x-3">
                                    <h2 class="text-lg leading-7 font-medium text-white">
                                        {{title}}
                                    </h2>
                                    <div class="h-7 flex items-center" @click="removeFromDisplay()">
                                        <button aria-label="Close panel" class="text-gray-200 hover:text-white transition ease-in-out duration-150">
                                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </header>
                            <div class="flex-1 flex flex-col justify-between">
                                <div class="px-4 divide-y divide-gray-200 sm:px-6">
                                    <div class="space-y-6 pt-6 pb-5">
                                        <slot></slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end">
                            <span class="inline-flex rounded-md shadow-sm">
                                <button @click="removeFromDisplay()" data-cy="cancel-modal" type="button" class="inline-flex mr-3 justify-center w-full rounded-md border border-gray-300 px-4 py-2 text-blue-700 bg-blue-100 hover:bg-blue-50 text-base leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-300 focus:ring active:bg-blue-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                    Cancel
                                </button>
                            </span>
                            <span class="inline-flex rounded-md shadow-sm">
                                <primary-button @click.native="perform()" data-cy="submit-modal" :loading="submitting">
                                    Confirm
                                </primary-button>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

</template>

<script>
    import PrimaryButton from './PrimaryButton';
    export default {
        inject: ["$validator"],
        components: {
            PrimaryButton,
        },
        props: {
            submitting:{
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Perform action.'
            },
            confirmationString: {
                type: String,
                default: 'Are you sure?'
            },
        },
        methods: {
            removeFromDisplay() {
                this.$emit('removeFromDisplay');
            },
            perform(){
                this.$emit('perform');
            }
        },
    }
</script>

<style scoped>

</style>
