<template>
    <div>
        <div class="">
            <div class="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                <div>
                    <div>
                        <div class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <div class="bg-white">
<!--                                            <order-filters :response="response" :action="filterAction"></order-filters>-->
                                        </div>

                                        <table class="min-w-full">
                                            <thead class="bg-secondary-lightbacka">
                                                <tr>
                                                    <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Employee</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">{{ getDateForDay('day1') }}</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">{{ getDateForDay('day2') }}</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">{{ getDateForDay('day3') }}</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">{{ getDateForDay('day4') }}</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">{{ getDateForDay('day5') }}</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">{{ getDateForDay('day6') }}</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">{{ getDateForDay('day7') }}</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Weeklytotal (h)</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Billable Total (£)</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="this.response.body.properties.get('total') === 0 || entities.count() === 0">
                                                <tr class="bg-white hover:bg-secondary-lightbacka cursor-pointer">
                                                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                                        No weekly reports found.
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else class="divide-y divide-trueGray-200 bg-white">
                                            <template v-for="(entity, key) in entities">
                                                <tr @click="navigateToTimesheet(entity)" class="bg-white hover:bg-secondary-lightbacka cursor-pointer">
                                                    <template v-for="(field, key) in getPropertiesToDisplay(entity)">
                                                        <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold">
                                                            <template v-if="key === 'location_in' || key === 'location_out'">
                                                                <location-viewer-with-icon v-if="field !== 'N/A'" :x="field['x']" :y="field['y']"></location-viewer-with-icon>
                                                            </template>
                                                            <template v-else>{{field}}</template>
                                                        </td>
                                                    </template>
                                                </tr>
                                            </template>
                                            <tr class="bg-white hover:bg-secondary-lightbacka font-bold">
                                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-secondary-darkgrey text-right" :colspan="8">Grand Total (h):</td>
                                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-secondary-darkgrey">{{getWeeklyHoursTotal()}}</td>
                                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-secondary-darkgrey"></td>
                                            </tr>
                                            <tr class="bg-white hover:bg-secondary-lightbacka font-bold">
                                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-secondary-darkgrey text-right" :colspan="8">Grand Billable Total:</td>
                                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-secondary-darkgrey"></td>
                                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-secondary-darkgrey">{{getBillableTotal()}}</td>
                                            </tr>
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import Pagination from "@/v2/paginatedLists/Pagination";
import PhotoViewerWithIcon from "@/components/PhotoViewerWithIcon";
import LocationViewerWithIcon from "@/components/LocationViewerWithIcon";

export default {
    name: "DailyReportsPaginatedList",
    components:{
        Pagination,
        PhotoViewerWithIcon,
        LocationViewerWithIcon
    },
    props: {
        response:{
            type: Object
        }
    },
    computed:{
        filterAction() {
            if(!this.response){
                return null;
            }
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
        entities(){
            if(!this.response){
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('weekly-report-item'));
        },
        headingValues() {
            return ['Employee', 'Sat','Sun','Mon','Tue','Wed','Thurs','Fri','WeeklyTotal (h)','Billable Total (£)'];
        }
    },
    methods:{
        getPropertiesToDisplay(reportEntity) {
            return {
                'user': reportEntity.entity.properties.get('user'),
                // 'sat': reportEntity.entity.properties.get('sat'),
                // 'sun': reportEntity.entity.properties.get('sun'),
                // 'mon': reportEntity.entity.properties.get('mon'),
                // 'tue': reportEntity.entity.properties.get('tue'),
                // 'wed': reportEntity.entity.properties.get('wed'),
                // 'thurs': reportEntity.entity.properties.get('thurs'),
                // 'fri': reportEntity.entity.properties.get('fri'),
                'day1': reportEntity.entity.properties.get('day1'),
                'day2': reportEntity.entity.properties.get('day2'),
                'day3': reportEntity.entity.properties.get('day3'),
                'day4': reportEntity.entity.properties.get('day4'),
                'day5': reportEntity.entity.properties.get('day5'),
                'day6': reportEntity.entity.properties.get('day6'),
                'day7': reportEntity.entity.properties.get('day7'),
                'weeklyTotal': reportEntity.entity.properties.get('weekly_total'),
                'billable_total': reportEntity.entity.properties.get('billable_total')
            }
        },
        navigateToItem(companyEntity) {
            let selfLink = companyEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        navigateToTimesheet(ReportEntity) {
            let timesheetBuilderLink = ReportEntity.entity.links.filter(link => link.rels.contains('timesheet-builder')).first().href;
            let parts = timesheetBuilderLink.split('/api');
            this.$router.push(parts[1]);
        },
        getWeeklyHoursTotal(){
            return this.response.body.properties.get('hours_total')
        },
        getBillableTotal(){
            return this.response.body.properties.get('billable_total')
        },
        getDateForDay(day){
            console.log(this.response.body.properties.get(day.toLowerCase()));
            return this.response.body.properties.get(day.toLowerCase())
        }
    }
}
</script>

<style scoped>

</style>
