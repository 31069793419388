<template>
  <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
    <div class="space-y-6 lg:col-start-1 lg:col-span-2">
      <!-- Description list-->
      <section>
        <div class="bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-1">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
              <div>
                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                  Pre-Check Details
                </dt>
              </div>
                <div>
                    <dt class="text-sm font-nromal text-gray-500">Reference</dt>
                    <dd class="text-sm font-bold text-black">
                        {{ response.body.properties.get('reference') }}
                    </dd>

                </div>
              <div>
                <dt class="text-sm font-nromal text-gray-500">Work By</dt>
                <dd class="text-sm font-bold text-black">
                  {{ response.body.properties.get('subcontractor_name') }}
                </dd>

              </div>
              <div>
                <dt class="text-sm font-nomal text-gray-500">Project & Location</dt>
                <dd class="text-sm font-bold text-black">{{response.body.properties.get('location')}}</dd>
              </div>
              <div>
                <dt class="text-sm font-nomal text-gray-500">Date of works</dt>
                <dd class="text-sm font-bold text-black">
                  {{response.body.properties.get('date_of_works')}}
                </dd>
              </div>
              <div>
                <dt class="text-sm font-nomal text-gray-500">Questions & Answers</dt>
                <dd class="text-sm font-bold text-black">
                  <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li v-for="(question, index) in response.body.properties.get('questions')" :key="question.question"
                        class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div class="w-0 flex-1 flex items-center">
                        <span class="ml-2 flex-1 w-0 truncate">
                          {{ question.question }}<span
                            v-if="question.is_required"
                            class="text-red-500 ml-1">*</span>
                        </span>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <svg v-if="response.body.properties.get('answers')[index]['checked']"
                             xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                             fill="#000000">
                          <path d="M0 0h24v24H0V0z" fill="none"/>
                          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                             fill="#000000">
                          <path d="M0 0h24v24H0V0z" fill="none"/>
                          <path
                              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
                        </svg>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
              <div>
                <dt v-if="response.body.properties.get('on_site_confirmation_image')" class="text-sm font-nomal text-gray-500">On site confirmation image</dt>
                <dd class="mt-1 text-sm font-bold text-black">
                  <div
                      class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                    <a :href="response.body.properties.get('on_site_confirmation_image')" class="pointer-events-none">
                      <img :src="response.body.properties.get('on_site_confirmation_image')" alt=""
                           class="group-hover:opacity-75 object-cover pointer-events-none  rounded-lg">
                    </a>
                  </div>
                </dd>
              </div>
              <div v-if="response.body.properties.get('construction_drawing')">
                <dt class="text-sm font-nomal text-gray-500">Construction Drawing</dt>
                <dd class="mt-1 text-sm font-bold text-black">
                  <div
                      class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                    <a v-if="!response.body.properties.get('construction_drawing') || !response.body.properties.get('construction_drawing').endsWith('.pdf')"
                       :href="response.body.properties.get('construction_drawing')" class="pointer-events-none">
                      <img :src="response.body.properties.get('construction_drawing')" alt=""
                           class="group-hover:opacity-75 object-cover pointer-events-none  rounded-lg">
                    </a>
                    <span v-else>
                        <a class="text-sm text-gray-700 underline" href="#"
                           @click.prevent="showConstructionDrawingPDF = true">Download Construction Drawing</a><br>
                        <template v-if="showConstructionDrawingPDF">
                            <download-pdf
                                :url="response.body.properties.get('construction_drawing')"
                                @removeFromDisplay="showConstructionDrawingPDF = false"></download-pdf>
                        </template>
                    </span>
                  </div>
                </dd>
              </div>
              <div v-for="(additionalImage, index) in response.body.properties.get('additional_images')">
                <dt class="text-sm font-medium text-gray-500">
                  Additional Image {{index+1}}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <div
                      class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                    <a :href="additionalImage" class="pointer-events-none">
                      <img :src="additionalImage" alt=""
                           class="group-hover:opacity-75 object-cover pointer-events-none  rounded-lg">
                    </a>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>

    </div>
      <section class="lg:col-start-3 lg:col-span-1 space-y-4">
          <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
              <quality-assessment-actions v-if="showActions" :response="response"
                                          :remainingActions="response.body.actions.filter(action => action.name !== 'update-additional-image')"></quality-assessment-actions>
          </div>
      </section>
  </div>
</template>
<script>
import DownloadPdf from "@/pages/DownloadPdf"
import QualityAssessmentActions from "@/v2/components/QualityAssessmentActions"
import QualityAssessmentComments from "@/v2/components/QualityAssessmentComments"
import QualityAssessmentTimeline from "@/v2/components/QualityAssessmentTimeline"

export default {
  name: 'QASummary',
  components: {DownloadPdf, QualityAssessmentActions, QualityAssessmentComments, QualityAssessmentTimeline},
  props: {
    response: {type: Object},
    showConstructionDrawingPDF: {
        type:Boolean,
        default:false
    },
    showPDF: {
        type:Boolean,
        default:false
    },
      showActions: {
        type: Boolean,
          default: true
      }
  },
    methods:{
      togglePDF(){
          this.$emit('togglePdf');
      }
    }
}
</script>
