<template>
    <div id="holiday-requests" class="flex flex-col" >
        <div class="sm:flex sm:items-center sm:justify-between py-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                Leave Requests
            </h3>
        </div>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8" >
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Start Date
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        End Date
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Days Requested
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Project
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Unpaid
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Approved
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Reason
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody v-if="holidayRequests.size > 0" class="bg-white">
                <template v-for="holidayRequest in holidayRequests">
                    <tr class="border-b border-gray-200 last:border-b-0 hover:bg-gray-100 cursor-pointer">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ holidayRequest.entity.properties.get('start_date') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ holidayRequest.entity.properties.get('end_date') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ holidayRequest.entity.properties.get('days_requested') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ holidayRequest.entity.properties.get('project') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ holidayRequest.entity.properties.get('is_unpaid') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ holidayRequest.entity.properties.get('status') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ holidayRequest.entity.properties.get('reason') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <div class="flex">
                            <template v-for="actionWithFields in holidayRequest.entity.actions.filter(action => action.fields.isEmpty() && action.name !== 'delete')">
                                <confirmation-action v-if="actionWithFields[0] === 'approve'" :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :onSuccessPath="$route.path"
                                                     additionalButtonClasses="mr-2"
                                                     buttonType="positive"
                                ></confirmation-action>
                                <confirmation-action v-else-if="actionWithFields[0] === 'delete'" :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :onSuccessPath="$route.path"
                                                     additionalButtonClasses="mr-2"
                                                     buttonType="negative"
                                ></confirmation-action>
                                <confirmation-action v-else :action="actionWithFields[1]"
                                                                            :actionKey="actionWithFields[0]"
                                                                            :onSuccessPath="$route.path"
                                                                            additionalButtonClasses="mr-2"
                            ></confirmation-action>
                            </template>
                            <template v-for="actionWithFields in holidayRequest.entity.actions.filter(action => !action.fields.isEmpty())">
                                <form-action v-if="actionWithFields[0] === 'reject'" :action="actionWithFields[1]"
                                              :actionKey="actionWithFields[0]"
                                              :onSuccessPath="$route.path"
                                              additionalButtonClasses="mr-2"
                                             buttonType="negative"
                                ></form-action>
                                <form-action v-else :action="actionWithFields[1]"
                                                             :actionKey="actionWithFields[0]"
                                                             :onSuccessPath="$route.path"
                                                             additionalButtonClasses="mr-2"
                            ></form-action>
                            </template>

                                <template v-for="actionWithFields in holidayRequest.entity.actions.filter(action => action.fields.isEmpty() && action.name === 'delete')">
                                    <confirmation-action v-if="actionWithFields[0] === 'approve'" :action="actionWithFields[1]"
                                                         :actionKey="actionWithFields[0]"
                                                         :onSuccessPath="$route.path"
                                                         additionalButtonClasses="mr-2"
                                                         buttonType="positive"
                                    ></confirmation-action>
                                    <confirmation-action v-else-if="actionWithFields[0] === 'delete'" :action="actionWithFields[1]"
                                                         :actionKey="actionWithFields[0]"
                                                         :onSuccessPath="$route.path"
                                                         additionalButtonClasses="mr-2"
                                                         buttonType="negative"
                                    ></confirmation-action>
                                    <confirmation-action v-else :action="actionWithFields[1]"
                                                         :actionKey="actionWithFields[0]"
                                                         :onSuccessPath="$route.path"
                                                         additionalButtonClasses="mr-2"
                                    ></confirmation-action>
                                </template>
                        </div>
                    </td>
                    </tr>
                    <tr v-if="holidayRequest.entity.properties.get('rejection_note')" class="border-b border-gray-200 last:border-b-0 hover:bg-gray-100 cursor-pointer">
                        <td class="px-3 py-2 whitespace-nowrap text-sm leading-5 bg-error text-white" colspan="8">
                            Rejection Note: {{ holidayRequest.entity.properties.get('rejection_note') }}
                        </td>
                    </tr>
                </template>
                <tr>
                    <td colspan="2" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        Number of days requested in current year ({{response.body.properties.get('holiday_days_from')}} - {{response.body.properties.get('holiday_days_to')}})
                    </td>
                    <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{response.body.properties.get('total_holiday_days')}}
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                        No leave requests found.
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "@/components/Button";
    import GenericTableFilter from "@/components/GenericTableFilter";
    import ConfirmationAction from "@/v2/components/ConfirmationAction";
    import FormAction from "@/v2/components/FormAction";
    export default {
        components: {
            FormAction,
            ConfirmationAction,
            GenericTableFilter,
            'a-button': Button
        },
        data(){
            return {
                totalDays: 0
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            holidayRequests() {
                let holidayRequests = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("holiday-request");
                    });
                if(!holidayRequests){
                    return null;
                }

                return holidayRequests;
            },
        },
        mounted(){
            this.$emit('totalHolidaysUsed', this.response.body.properties.get('total_holiday_days'))
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            },
            success(key) {
                this.$router.go(0);
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
