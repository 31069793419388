<template>
    <div class="absolute container mx-auto bottom-0 sm:top-0 inset-x-0 px-4 pt-4 pb-4 z-10">
        <!--
            Background overlay, show/hide based on modal state.

            Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
            Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!--
            Modal panel, show/hide based on modal state.

            Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all w-full sm:p-6">
            <div class="sm:flex sm:items-start justify-center ">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <slot></slot>
            </div>
            </div>
            <div class="mt-5 sm:mt-4 flex flex-col flex-col-reverse sm:flex-row sm:flex-row-reverse">
                <span v-if="displayConfirmButton" class="flex mt-3 sm:mt-0 w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <primary-button class="w-full inline-flex justify-center " @click.native="perform()" data-cy="submit-modal" :loading="submitting">
                        Confirm
                    </primary-button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <a-button @click.native="removeFromDisplay()">
                        Cancel
                    </a-button>
                </span>
            </div>
        </div>
        </div>
</template>

<script>
    import PrimaryButton from './PrimaryButton';
    import Button from "./Button";
    export default {
        components: {
            PrimaryButton,
            'a-button': Button,
        },
        props: {
            title: {
                type: String,
                default: 'Perform action.'
            },
            confirmationString: {
                type: String,
                default: 'Are you sure?'
            },
            submitting: {
                type: Boolean,
                default: false
            },
            displayConfirmButton:{
                type: Boolean,
                default: true
            }
        },
        methods: {
            removeFromDisplay() {
                this.$emit('removeFromDisplay');
            },
            perform(){
                this.$emit('perform');
            }
        },
    }
</script>
