<template>
    <paginated-list :response="response" :actionBaseUrl="$route.path" :useEntities="true" :hasEntities="categories && categories.count() > 0">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Name</th>
        </template>
        <template v-slot:noItems>
            No categories found.
        </template>
        <template v-slot:tableRows>
            <tr @click="navigateToItem(entity)" v-for="entity in categories" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap flex-grow py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('name') }}
                    </div>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";
import FormAction from "@/v2/components/FormAction";
import moment from "moment";
export default {
    name: "SubCategoryPaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        OrderTabs,
        DownloadPdfIconWithLink,
        FormAction
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        categories() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('sub-category'));
        },
    },
    methods: {
        navigateToItem(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
