<template>
    <div :key="$route.fullPath" class="create-new-order">
        <main-template>
            <template slot-scope="{logo}">
                <page-header :logo="logo">
                    Create A QA
                </page-header>
                <loading v-if="loading"></loading>
                <div class="px-16 py-16" v-if="!loading" :key="key">
                    <template>
                        <div>
                            <div class="md:grid md:grid-cols-3 md:gap-6">
                                <div class="md:col-span-1">
                                    <div class="px-4 sm:px-0">
                                        <h3 class="text-lg font-medium leading-6 text-gray-900">Tracker / Category Information</h3>
                                        <p class="mt-1 text-sm text-gray-600">
                                            Select tracker then category
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-5 md:mt-0 md:col-span-2">
                                    <form action="#" method="POST">
                                        <div class="shadow sm:rounded-md sm:overflow-hidden">
                                            <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                                <div>
                                                    <div>
                                                        <property-or-field title="Client" :action="getQATemplateAction"
                                                                           fieldId="client_id" propertyKey="client"
                                                                           v-model="getQATemplateActionData['client_id']"
                                                                           @input="setClientId"></property-or-field>
                                                    </div>
                                                </div>

                                                <div>
                                                    <dependant-field title="QA Tracker" :action="getQATemplateAction"
                                                                     fieldId="qa_tracker" propertyKey="qa_tracker"
                                                                     v-model="getQATemplateActionData['qa_tracker']"
                                                                     @input="getTrackerDetails"
                                                                     dependsOn="client_id"
                                                                     :dependsOnValue="clientId"
                                                    ></dependant-field>
                                                </div>
                                                <div>
                                                    <template v-if="getQaTrackerDetailsAction">
                                                            Type: {{qaTrackerDetailsActionData['type']}}
                                                    </template>
                                                </div>

                                                <div>
                                                    <dependant-field title="QA Category" :action="getQATemplateAction"
                                                                     fieldId="qa_category_id" propertyKey="qa_category_id"
                                                                     v-model="getQATemplateActionData['qa_category_id']"
                                                                     @input="getRemaningFields()"
                                                                     dependsOn="tracker_id"
                                                                     :dependsOnValue="qaTrackerId"
                                                    ></dependant-field>
                                                </div>
                                                <div>
                                                    <dependant-field title="Site Manager" :action="createNewQAActionData"
                                                                     fieldId="site_manager_id" propertyKey="user_id"
                                                                     dependsOn="client_id"
                                                                     :dependsOnValue="clientId"
                                                                     v-model="createNewQAActionData['site_manager_id']"></dependant-field>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div v-if="createNewQAAction" class="hidden sm:block" aria-hidden="true">
                            <div class="py-12">
                                <div class="border-t border-gray-200" />
                            </div>
                        </div>
                        <template v-if="createNewQAAction && !useCameraForOnSiteConfirmation">
                            <div v-if="createNewQAAction" class="mt-10 sm:mt-0">
                                <div class="md:grid md:grid-cols-3 md:gap-6 space-y-6">
                                    <div class="mt-5 md:col-span-1">
                                        <div class="px-4 sm:px-0">
                                            <h3 class="text-lg font-medium leading-6 text-gray-900">Date of works</h3>
                                            <p class="mt-1 text-sm text-gray-600">
                                                Date details go here
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-5 md:mt-0 md:col-span-2">
                                        <form action="#" method="POST">
                                            <div class="shadow overflow-hidden sm:rounded-md">
                                                <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                                    <div>
                                                        <property-or-field @input="change" title="Date of works" :action="createNewQAAction"
                                                                           fieldId="date_of_works" propertyKey="date_of_works"
                                                                           v-model="createNewQAActionData['date_of_works']"></property-or-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div v-if="createNewQAAction" class="hidden sm:block" aria-hidden="true">
                            <div class="py-12">
                                <div class="border-t border-gray-200" />
                            </div>
                        </div>
                        <template v-if="createNewQAAction && !useCameraForOnSiteConfirmation">
                            <div v-if="createNewQAAction" class="mt-10 sm:mt-0">
                                <div class="md:grid md:grid-cols-3 md:gap-6 space-y-6">
                                    <div class="mt-5 md:col-span-1">
                                        <div class="px-4 sm:px-0">
                                            <h3 class="text-lg font-medium leading-6 text-gray-900">Operative / Location Information</h3>
                                            <p class="mt-1 text-sm text-gray-600">
                                                Operative and location details go here
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-5 md:mt-0 md:col-span-2">
                                        <form action="#" method="POST">
                                            <div class="shadow overflow-hidden sm:rounded-md">
                                                <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                                    <div>
                                                        <property-or-field @input="change" title="Operative" :action="createNewQAAction"
                                                                           fieldId="operative_id" propertyKey="subcontractor"
                                                                           v-model="createNewQAActionData['operative_id']"
                                                                            @returnPaymentType="returnPaymentType"></property-or-field>
                                                    </div>
                                                    <div>
                                                        <property-or-field @input="change" :title="identifiersTitle()" :action="createNewQAAction"
                                                                           fieldId="identifiers" propertyKey="identifiers"
                                                                           v-model="identifier"></property-or-field>
                                                    </div>
                                                    <div>
                                                        <property-or-field @input="change" title="Priced Or Hourly Day work" :action="createNewQAAction"
                                                                           fieldId="priced_or_hourly_day_work" propertyKey="priced_or_hourly_work"
                                                                           v-model="createNewQAActionData['priced_or_hourly_day_work']"></property-or-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div v-if="createNewQAAction" class="hidden sm:block" aria-hidden="true">
                                <div class="py-12">
                                    <div class="border-t border-gray-200" />
                                </div>
                            </div>

                            <div v-if="createNewQAAction" class="mt-10 sm:mt-0">
                                <div class="md:grid md:grid-cols-3 md:gap-6">
                                    <div class="md:col-span-1">
                                        <div class="px-4 sm:px-0">
                                            <h3 class="text-lg font-medium leading-6 text-gray-900">Notes / Comments</h3>
                                            <p class="mt-1 text-sm text-gray-600 font-normal">
                                                Notes and comments go here
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-5 md:mt-0 md:col-span-2">
                                        <form action="#" method="POST">
                                            <div class="shadow overflow-hidden sm:rounded-md">
                                                <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                                    <div>
                                                        <property-or-field @input="changeWorkDone" title="Works Done" :action="createNewQAAction"
                                                                           fieldId="work_done" propertyKey="work_done"
                                                                           v-model="createNewQAActionData['work_done']"></property-or-field>
                                                        <property-or-field v-if="!workDone || workDone === '0'" @input="change" title="Comment" :action="createNewQAAction"
                                                                           fieldId="comment" propertyKey="comment"
                                                                           v-model="createNewQAActionData['comment']"></property-or-field>
                                                    </div>
                                                    <div>
                                                        <property-or-field @input="change" title="Additional Notes" :action="createNewQAAction"
                                                                           fieldId="additional_notes" propertyKey="additional_notes"
                                                                           v-model="createNewQAActionData['additional_notes']"></property-or-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div v-if="createNewQAAction" class="hidden sm:block" aria-hidden="true">
                                <div class="py-12">
                                    <div class="border-t border-gray-200" />
                                </div>
                            </div>

                            <div v-if="createNewQAAction" class="mt-10 sm:mt-0">
                                <div class="md:grid md:grid-cols-3 md:gap-6">
                                    <div class="md:col-span-1">
                                        <div class="px-4 sm:px-0">
                                            <h3 class="text-lg font-medium leading-6 text-gray-900">Images</h3>
                                            <p class="mt-1 text-sm text-gray-600 font-normal">Insert images here
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-5 md:mt-0 md:col-span-2">
                                        <form action="#" method="POST">
                                            <div class="shadow overflow-hidden sm:rounded-md">
                                                <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                                    <div>
                                                        <property-or-field @input="change" title="On site confirmation image" :action="createNewQAAction"
                                                                           fieldId="on_site_confirmation_image" propertyKey="on_site_confirmation_image"
                                                                           v-model="createNewQAActionData['on_site_confirmation_image']"
                                                                           :useCamera="useCameraForOnSiteConfirmation"
                                                        ></property-or-field>
                                                    </div>
                                                    <div>
                                                        <property-or-field @input="change" title="Construction Drawing" :action="createNewQAAction"
                                                                           fieldId="construction_drawing" propertyKey="construction_drawing"
                                                                           v-model="createNewQAActionData['construction_drawing']" :parentId="identifier" @returnSelectedOption="returnSelectedOption"></property-or-field>
                                                    </div>
                                                    <div>
                                                        <p class="block text-sm font-medium leading-5">Additional Images</p>
                                                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg border border-gray-200">
                                                                    <table class="min-w-full divide-y additional-images-table ">
                                                                        <thead class="bg-gray-50">
                                                                        <tr>
                                                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                                Image
                                                                            </th>
                                                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr  v-for="qty in additionalImagesQty"  class="bg-white">
                                                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">

                                                                                <property-or-field @input="change" title="On site confirmation image" :action="createNewQAAction"
                                                                                                   :fieldId="`additional_images[${qty -1}]`" :propertyKey="`additional_images[${qty -1}]`"
                                                                                                   v-model="additionalImages[`${qty -1}`]" :parentId="identifier" @returnSelectedOption="returnSelectedImage(value, qty-1)"></property-or-field>
                                                                            </td>
                                                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                                <span v-if="qty === additionalImagesQty" @click="removeAdditionalImage(qty -1)" class="cursor-pointer font-normal underline text-gray-400">remove</span>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                        <tbody >
                                                                        <tr class="bg-white text-center">
                                                                            <td colspan="100" >
                                                                                <div class="grid grid-cols-3 p-3" v-if="additionalImagesQty < 15">
                                                                                    <a-button @click.native="additionalImagesQty++" data-cy="add-item"
                                                                                            class="col-start-2 inline-flex justify-center py-2 px-4 ">
                                                                                        + Additional Image
                                                                                    </a-button>
                                                                                </div>
                                                                                <div v-else>
                                                                                    <p class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400">
                                                                                        Max 15 additional images.
                                                                                    </p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div v-if="createNewQAAction" class="hidden sm:block" aria-hidden="true">
                                <div class="py-12">
                                    <div class="border-t border-gray-200" />
                                </div>
                            </div>

                            <div v-if="createNewQAAction" class="mt-10 sm:mt-0">
                                <div class="md:grid md:grid-cols-3 md:gap-6">
                                    <div class="md:col-span-1">
                                        <div class="px-4 sm:px-0">
                                            <h3 class="text-lg font-medium leading-6 text-gray-900">Questions</h3>
                                            <p class="mt-1 text-sm text-gray-600 font-normal">Answer question here
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-5 md:mt-0 md:col-span-2">
                                        <form action="#" method="POST">
                                            <div class="shadow overflow-hidden sm:rounded-md">
                                                <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                                    <div>
                                                        <questions-box fieldId="answers" :action="createNewQAAction" v-model="createNewQAActionData['answers']">

                                                        </questions-box>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div v-if="createNewQAAction && paymentType !== 'hourly_rate'" class="hidden sm:block" aria-hidden="true">
                                <div class="py-12">
                                    <div class="border-t border-gray-200" />
                                </div>
                            </div>

                            <div v-if="createNewQAAction&& paymentType !== 'hourly_rate'" class="mt-10 sm:mt-0">
                                <div class="md:grid md:grid-cols-3 md:gap-6">
                                    <div class="md:col-span-1">
                                        <div class="px-4 sm:px-0">
                                            <h3 class="text-lg font-medium leading-6 text-gray-900">Payment Types</h3>
                                            <p class="mt-1 text-sm text-gray-600 font-normal">Select Payment Types here
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-5 md:mt-0 md:col-span-2">
                                        <form action="#" method="POST">
                                            <div class="shadow overflow-hidden sm:rounded-md">
                                                <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                                    <div>
                                                        <payment-types-box fieldId="payment_types" :action="createNewQAAction" v-model="createNewQAActionData['payment_types']">

                                                        </payment-types-box>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </template>
                        <template v-else>
                            <div v-if="createNewQAAction" class="mt-10 sm:mt-0">
                                <div class="md:grid md:grid-cols-3 md:gap-6">
                                    <div class="md:col-span-1">
                                        <div class="px-4 sm:px-0">
                                            <h3 class="text-lg font-medium leading-6 text-gray-900">Create QA on App</h3>
                                            <p class="mt-1 text-sm text-gray-600 font-normal">
                                                QA's must be created on the app
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-5 md:mt-0 md:col-span-2">
                                        <form action="#" method="POST">
                                            <div class="shadow overflow-hidden sm:rounded-md">
                                                <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                                    <div>
                                                        These QA's must be created on the app
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div
                        v-if="!loading && showCreateButton && !useCameraForOnSiteConfirmation"
                        class="p-3 py-10 bottom-0 w-full flex justify-end z-40">
                        <a-button @click.native="createQa()" class="cursor-pointer" data-cy="submit" :loading="submitting">
                            Create
                            QA
                        </a-button>
                    </div>
                    <modal v-show="uploadingImages" title="" confirmationString="" :hasConfirmationAction="false" :showCancelButton="false">
                        <div class="text-center w-full">
                            <p class="text-gray-900 font-bold mb-2">Quality Assessment created successfully.</p>
                            <p class="text-gray-900">Uploading Images...</p>
                            <p class="text-gray-500">Image {{imageNumberUploading}} of {{additionalImages.length}} Uploading</p>
                            <loading class="inline-flex relative h-auto -ml-4"></loading>
                        </div>
                    </modal>
                </div>
            </template>
        </main-template>
    </div>
</template>

<script>
    import Loading from '@/components/Loading';
    import PrimaryButton from '@/components/PrimaryButton';
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import PropertyOrField from "@/components/PropertyOrField";
    import PageTitle from "@/common/PageTitle";
    import DependantField from "@/components/DependantField";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import QuestionsBox from "@/components/Action/Fields/QuestionsBox";
    import PageHeader from "@/v2/components/Header";
    import Button from "@/v2/buttons/Button";
    import PaymentTypesBox from "@/components/Action/Fields/PaymentTypesBox";
    import Modal from "@/v2/components/Modal.vue";

    let follow = Siren.Helper.follow;
    export default {
        components: {
            PaymentTypesBox,
            MainTemplate,
            Loading,
            PrimaryButton,
            PropertyOrField,
            PageTitle,
            DependantField,
            QuestionsBox,
            PageHeader,
            'a-button': Button,
            Modal
        },
        data() {
            return {
                getQATemplateAction: null,
                createNewQAAction:null,
                projectId: null,
                loading: true,
                submitting: false,
                key:0,
                clientId: null,
                showCreateButton: false,
                additionalImagesQty:0,
                qaTrackerId: null,
                getQaTrackerDetailsAction: null,
                useCameraForOnSiteConfirmation: false,
                workDone: false,
                identifier: null,
                preCheckImage: null,
                paymentType: null,
                additionalImages: [],
                uploadingImages: false,
                imageNumberUploading: 0
            }
        },

        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            getQATemplateActionData() {
                var vm = this;
                if (!this.getQATemplateAction) {
                    return {};
                }
                if (!this.getQATemplateAction.fields) {
                    return {};
                }
                var payload = {};
                this.getQATemplateAction.fields.forEach(function (field) {
                    if (field.value !== undefined && field.name === 'client_id') vm.clientId = field.value;
                    if (field.value !== undefined) payload[field.name] = field.value;
                })
                return payload;
            },
            createNewQAActionData(reset) {
                var vm = this;
                if (!this.createNewQAAction) {
                    return {};
                }
                if (!this.createNewQAAction.fields) {
                    return {};
                }
                var payload = {};
                this.createNewQAAction.fields.forEach(function (field) {
                    //if(vm.createNewQAActionData[field.name]) payload[field.name] = field.value;
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                payload['tracker_id'] = vm.qaTrackerId;
                return payload;
            },
            qaTrackerDetailsActionData() {
                var vm = this;
                if (!this.getQaTrackerDetailsAction) {
                    return {};
                }
                if (!this.getQaTrackerDetailsAction.fields) {
                    return {};
                }
                var payload = {};
                this.getQaTrackerDetailsAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            }
        },
        methods: {
            removeAdditionalImage(qty){
                this.createNewQAActionData[`additional_images[${qty}`] = '';
                this.additionalImagesQty--;
            },
            change() {
            },
            changeWorkDone(value) {
                this.workDone = value;
            },
            changeDeliveryMethod() {
                this.change();
                this.key++;
            },
            changeIsScheduled() {
                this.change();
                this.key++;
            },
            getRemaningFields(){
                this.createNewQAAction = null;
                this.fetchingFormFields = true;
                if(this.getQATemplateActionData.qa_category_id !== null){
                    this.getQATemplateAction.perform(this.getQATemplateActionData).then(res => {
                        this.useCameraForOnSiteConfirmation = res.body.properties.get('use_camera_for_on_site_confirmation');
                        this.createNewQAAction = res.body.actions.filter(action => action.name === 'add-new-quality-assessment').get('add-new-quality-assessment');
                        this.showCreateButton = true;
                    });
                }else{
                    this.createNewQAAction = null;
                    this.showCreateButton = false;
                }

            },
            async createQa() {
                if(this.submitting === false) {
                    this.submitting = true;
                    this.createNewQAActionData['identifiers'] = this.identifier;
                    this.createNewQAActionData['construction_drawing_from_pre_check'] = this.preCheckImage;
                    this.createNewQAAction.perform(this.createNewQAActionData).then(async res => {
                        let additionalImageAction = res.body.actions.filter(action => action.name === 'update-additional-image').first();
                        let imagesError = 0;
                        if (this.additionalImages !== null && this.additionalImages.length > 0 && additionalImageAction) {
                            this.uploadingImages = true;
                            for (const [index, image] of this.additionalImages.entries()) {
                                this.imageNumberUploading = index + 1;
                                await additionalImageAction.perform({
                                    image: image,
                                    imageId: index
                                }).then(res => {
                                    console.log('success')
                                }).catch(error => {
                                    console.log(error);
                                    imagesError++;
                                })
                            }
                        }
                        this.uploadingImages = false;
                        this.imageNumberUploading = 0

                        this.submitting = false;
                        await this.$store.dispatch(
                            "setMessage",
                            this.messages(res).entity.properties.get("success")
                        );
                        let next = res.body.links
                            .filter(link => link.rels.contains("self"))
                            .first();
                        let parts = next.href.split("/api");
                        await this.$router.push(parts[1]);
                        this.$emit('success', res);
                    }).catch(error => {
                        this.submitting = false;
                        if (error.status === 422) {
                            this.$setLaravelValidationErrorsFromResponse(
                                JSON.parse(error.response.text)
                            );
                        } else {
                            alert('There was an error processing your request.' + error);
                        }
                        this.$emit('failure');
                    });
                }
            },
            setProjectId(value) {
                this.projectId = value;
            },
            setClientId(value) {
                this.clientId = value;
            },
            setQaTrackerId(value) {
                this.qaTrackerId = value;
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            getTrackerDetails(value) {
                if (value !== null) {
                    Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                    Siren.get(process.env.VUE_APP_API_URL + "/api/qa-trackers/" + value).then(res => {
                        let siren = res.body;
                        this.getQaTrackerDetailsAction = siren.actions.filter(action => action.name === 'get-tracker-details').get('get-tracker-details');
                        this.loading = false;
                    }, this);
                }
                this.setQaTrackerId(value);
                this.getRemaningFields();
            },
            identifiersTitle(){
                if (this.qaTrackerDetailsActionData['type'] === 'communal'){
                    return 'Level/Area';
                }
                return 'Level/Unit'
            },
            returnSelectedOption(value){
                this.preCheckImage = value;
            },
            returnSelectedImage(value, qty){
                this.preCheckImage = value;
            },
            returnPaymentType(value){
                this.paymentType = value;
            },
        },
        mounted() {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow("quality-assessments"))
                .then(res => {
                    let siren = res.body;
                    this.getQATemplateAction = siren.actions.filter(action => action.name === 'get-qa-template').get('get-qa-template');
                    this.loading = false;
                }, this);
        },
    }
</script>

<style lang="scss">
    .create-new-order:not(show-label) .label-a {
        display: none;
    }
    .create-new-order .show-label .label-a{
        display:block;
    }
    .create-new-order .show-label .form-group label{
        display:none;
    }
    .additional-images-table label{
        display: none;
    }
</style>
