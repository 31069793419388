<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link" :logo="logo">
                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                    <a href="/invoices">Invoices</a>
                                    <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span class="text-secondary-darkblue">
                                        {{response.body.properties.get('reference')}}
                                    </span>
                                </div>
                                <div class="flex items-center -mt-3 text-secondary-darkgrey">
                                    <div v-if="response.body.properties.get('pdf_file')" class="mr-1">
                                        <a-button  @click.native="togglePDF">
                                            <svg class="w-6 h-6 text-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                                            Download PDF
                                        </a-button>
                                        <template v-if="showPDF">
                                            <download-pdf :url="response.body.properties.get('pdf_file')" @removeFromDisplay="showPDF = false"></download-pdf>
                                        </template>
                                    </div>
                                    <div v-for="actionWithFields in response.body.actions.filter(action => !action.fields.isEmpty() && action.name !== 'export-as-csv' && action.name !== 'export-as-excel' && action.name !== 'add-new-item')"
                                         class="mr-1">
                                        <form-action :action="actionWithFields[1]"
                                                          :actionKey="actionWithFields[0]"
                                                          :redirectOnSuccess="false"
                                                          @success="success(actionWithFields[0])"
                                                        :buttonType="actionWithFields[0].includes('delete')? 'negative' : 'primary'"
                                        ></form-action>
                                    </div>
                                    <div v-for="actionWithFields in response.body.actions.filter(action => action.fields.isEmpty() && action.name !== 'export-as-csv' && action.name !== 'export-as-excel' && action.name !== 'add-new-item')"
                                         class="mr-1">
                                        <confirmation-action :action="actionWithFields[1]"
                                                                  :actionKey="actionWithFields[0]"
                                                                  :redirectOnSuccess="true"
                                                                  @success="success(actionWithFields[0])"
                                                                  onSuccessPath="/invoices"
                                                                :buttonType="actionWithFields[0].includes('delete')? 'negative' : 'primary'"
                                        ></confirmation-action>
                                    </div>
                                    <div v-for="actionWithFields in response.body.actions.filter(action => action.name === 'export-as-csv' || action.name === 'export-as-excel' && action.name !== 'add-new-item')"
                                         class="mr-1">
                                        <confirmation-export-sales-invoice :action="actionWithFields[1]"
                                                                         :actionKey="actionWithFields[0]"
                                                                         :redirectOnSuccess="true"
                                                                         @success="success(actionWithFields[0])"
                                                                         :onSuccessPath="$route.path"
                                                                         :buttonType="actionWithFields[0].includes('delete')? 'negative' : 'primary'"
                                        ></confirmation-export-sales-invoice>
                                    </div>

                                    <div  data-cy="status">
                            <span
                                v-if="response.body.properties.get('status')"
                                :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 badge-${response.body.properties.get('status')}`"
                            >{{response.body.properties.get('status')}}</span>
                                    </div>
                                </div>
                            </div>
                        </page-header>
                        <div class="grid grid-cols-4 min-h-screen">
                            <div
                                class="col-span-4 lg:col-span-3 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">
                            <invoice-line-items :response="response" ref="invoiceItems" @change="change"></invoice-line-items>
                            <div class="flex justify-end">
                                <div class="p-4 text-right">
                                    <span class="text-l block text-gray-400">Net Total</span>
                                    <span class="text-l block text-gray-400">VAT</span>
                                    <span class="text-l font-medium block text-gray-900">Grand Total</span>
                                    <span class="text-l font-medium block text-gray-900" v-if="response.body.properties.get('paid_total')">Paid Total</span>
                                    <span class="text-l font-medium block text-gray-900" v-if="response.body.properties.get('outstanding_total')">Outstanding Total</span>

                                </div>
                                <div class="p-4 text-right">
                                    <span class="text-l block text-gray-400">{{response.body.properties.get('net_total')}}</span>
                                    <span class="text-l block text-gray-400">{{response.body.properties.get('vat')}}</span>
                                    <span class="text-l font-medium block text-gray-900">{{response.body.properties.get('grand_total')}}</span>
                                    <span class="text-l font-medium block text-gray-900" v-if="response.body.properties.get('paid_total')">{{response.body.properties.get('paid_total')}}</span>
                                    <span class="text-l font-medium block text-gray-900" v-if="response.body.properties.get('outstanding_total')">{{response.body.properties.get('outstanding_total')}}</span>
                                </div>
                            </div>

                                <div class="mt-8 border-t border-gray-200 pt-8 flex justify-between">
                                    <div class="flex-1">
                                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                                            Payment Details
                                        </h3>
                                        <span class="block text-sm"><span class="font-medium">Bank/Sort Code:</span> {{response.body.properties.get('bank_sort_code')}}</span>
                                        <span class="block text-sm"><span class="font-medium">Account Number:</span> {{response.body.properties.get('bank_account_number')}}</span>
                                        <span class="block text-sm"><span class="font-medium">Payment Reference:</span> {{response.body.properties.get('reference')}}</span>
                                    </div>
                                    <div class="flex-1">
                                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                                            Other Information
                                        </h3>
                                        <span class="block text-sm"><span class="font-medium">Company Registration Number::</span> {{response.body.properties.get('company_registration_number')}}</span>
                                    </div>
                                </div>

                            </div>
                            <div class="bg-gray-100 border border-gray-200 border-b-0 border-r-0 p-6 hidden lg:block">
                                <order-activity-feed :response="response"></order-activity-feed>
                            </div>
                        </div>
                    </template>
                    <div v-if="hasChangesWaitingOnSave"
                         style="right:0px;left:16rem;"
                         class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                        <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                            Invoice
                        </primary-button>
                    </div>
                </div>

            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import InvoiceLineItems from "../components/InvoiceLineItems";
    import PrimaryButton from "../components/PrimaryButton";
    import OrderActivityFeed from "../components/OrderActivityFeed";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import ConfirmationAction from "@/v2/components/ConfirmationAction";
    import ConfirmationExportSalesInvoice from "@/v2/components/forms/ConfirmationExportSalesInvoice";
    import Button from "@/v2/buttons/Button";
    import DownloadPdf from "@/pages/DownloadPdf";
    export default {
        components: {
            ConfirmationAction,
            MainTemplate,
            FetchRootSiren,
            Loading,
            InvoiceLineItems,
            PrimaryButton,
            OrderActivityFeed,
            PageHeader,
            FormAction,
            ConfirmationExportSalesInvoice,
            'a-button' : Button,
            DownloadPdf
        },
        data() {
            return {
                hasChangesWaitingOnSave: false,
                showPDF: false
            }
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            }
        },
        methods: {
            async saveData() {
                this.loading = true;
                    this.$refs.invoiceItems.updateItems().then(res => {
                        this.$store.dispatch(
                            "setMessage",
                            'The invoice was saved successfully'
                        );
                        this.hasChangesWaitingOnSave = false;
                        this.loading = false;
                        this.$router.go(0);
                    }).catch(error => {
                        alert('There was an error processing your request.' + error.error);
                        this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                        this.loading = false;
                    });
            },
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            success(key) {
                this.$router.go(0);
            },
            togglePDF()
            {
                this.showPDF = !this.showPDF
            }
        },
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1024px) {
        .order-container {
            background: linear-gradient(to right, white, white 50%, #f4f5f7 50%);
        }
    }
</style>
