<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Credit Notes</h2>
                <span class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty())"
                         class="mr-1">
                        <new-credit-note-form v-if="actionWithFields[0] === 'add-new-credit-note'"
                                                :action="actionWithFields[1]"
                                              :actionKey="actionWithFields[0]"
                                              :onSuccessPath="$router.currentRoute.fullPath"></new-credit-note-form>
                        <action-with-form v-else :action="actionWithFields[1]"
                                      :actionKey="actionWithFields[0]"
                                          :onSuccessPath="$router.currentRoute.fullPath"></action-with-form>
                    </div>
                </span>
            </div>

        </div>
    </header>
</template>

<script>
    import ExportInvoices from "./ExportInvoices";
    import NewCreditNoteForm from "./NewCreditNoteForm";
    import ActionWithForm from "./ActionWithForm";
    export default {
        components: {
            ExportInvoices,
            NewCreditNoteForm,
            ActionWithForm
        },
        inject: ["$validator"],
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'filter');
            },
        },
        methods: {
            redirectToItem(response) {
                this.$router.go(0);
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
