<template>
    <div class="order-summary">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Project Summary</h3>
        <div class="generic-summary p-4 bg-white" :key="key">
            <div class="grid grid-cols-3 gap-4">
                <property-or-field @input="change" :properties="properties" title="Identifier" :action="updateAction" fieldId="identifier" propertyKey="identifier" v-model="updateActionData['identifier']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Name" :action="updateAction" fieldId="name" propertyKey="name" v-model="updateActionData['name']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Address" :action="updateAction" fieldId="address" propertyKey="address" v-model="updateActionData['address']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Note" :action="updateAction" fieldId="note" propertyKey="note" v-model="updateActionData['note']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Sage Reference" :action="updateAction" fieldId="sage_reference" propertyKey="sage_reference" v-model="updateActionData['sage_reference']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Latitude" :action="updateAction" fieldId="latitude" propertyKey="latitude" v-model="updateActionData['latitude']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Longitude" :action="updateAction" fieldId="longitude" propertyKey="longitude" v-model="updateActionData['longitude']"></property-or-field>
            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from '../PropertyOrField';
    export default {
        inject: ["$validator"],
        components: {
            PropertyOrField,
        },
        data() {
            return {
                key : 0
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update-project').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            properties(){
                return this.response.body.properties;
            }
        },
        methods: {
            update(){
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
