<template>
    <paginated-list :response="response" actionBaseUrl="/costing">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Name</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Ordered</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Unpaid Orders</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Invoices</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Rentals</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Vouchers</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Day Work</th>
            <th scope="col" class="px-3 py-2 uppercase font-bold text-left text-sm text-secondary-darkgrey">Total</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Budget</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Remaining</th>
        </template>
        <template v-slot:noItems>
            No costing found.
        </template>
        <template v-slot:tableRows>
            <template v-for="entity in costings">
                <tr @click="navigateToItem(entity)"  class="hover:bg-secondary-lightbacka cursor-pointer bg-red-100">
                    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                        <div>{{ entity.entity.properties.get('project_name') }}
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{entity.entity.properties.get('quotes_total')}}
                            </div>
                            <div v-if="entity.entity.properties.get('quotes_euro_total')">
                                {{entity.entity.properties.get('quotes_euro_total')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{entity.entity.properties.get('unpaid_total')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_unpaid_total')">
                                {{entity.entity.properties.get('euro_unpaid_total')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{entity.entity.properties.get('invoices_total')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_invoices_total')">
                                {{entity.entity.properties.get('euro_invoices_total')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        {{ entity.entity.properties.get('rentals_total') }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        {{ entity.entity.properties.get('payment_vouchers_total') }}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        £0.00
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{entity.entity.properties.get('overall_total')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_overall_total')">
                                {{entity.entity.properties.get('euro_overall_total')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{entity.entity.properties.get('budget')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_budget')">
                                {{entity.entity.properties.get('euro_budget')}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{entity.entity.properties.get('remaining')}}
                            </div>
                            <div v-if="entity.entity.properties.get('euro_remaining')">
                                {{entity.entity.properties.get('euro_remaining')}}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr @click="navigateToSite(site)" v-for="(site, index) in entity.entity.properties.get('sites')" class="hover:bg-secondary-lightbacka cursor-pointer">
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        {{site['site_name']}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{site['quotes_total']}}
                            </div>
                            <div v-if="site['quotes_euro_total']">
                                {{site['quotes_euro_total']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{site['unpaid_total']}}
                            </div>
                            <div v-if="site['euro_unpaid_total']">
                                {{site['euro_unpaid_total']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{site['invoices_total']}}
                            </div>
                            <div v-if="site['euro_invoices_total']">
                                {{site['euro_invoices_total']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        {{site['rentals_total']}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        {{site['payment_vouchers_total']}}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        £0.00
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{site['overall_total']}}
                            </div>
                            <div v-if="site['euro_overall_total']">
                                {{site['euro_overall_total']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{site['budget']}}
                            </div>
                            <div v-if="site['euro_budget']">
                                {{site['euro_budget']}}
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                        <div>
                            <div>
                                {{site['remaining']}}
                            </div>
                            <div v-if="site['euro_remaining']">
                                {{site['euro_remaining']}}
                            </div>
                        </div>
                    </td>

                </tr>

            </template>





        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";
export default {
    name: "CreditNotePaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        OrderTabs,
        DownloadPdfIconWithLink
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        costings() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('costing'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        getParentLink(properties) {
            return '/orders/' + properties.get("order_uuid");
        },
        navigateToSite(site) {
            let selfLink = site['site_link']
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
