<template>
    <div class="flex flex-col" v-if="insurance">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Insurance</h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Type
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Name
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Address
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Policy Number
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Policy Expiry Date
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <template v-if="insurance">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{insurance.entity.properties.get('type')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{insurance.entity.properties.get('name')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{insurance.entity.properties.get('address')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{insurance.entity.properties.get('policy_number')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{insurance.entity.properties.get('policy_expiry_date')}}
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionWithForm from "./ActionWithForm";
    import ActionWithConfirmation from "./ActionWithConfirmation";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        components: {
            ActionWithConfirmation,
            ActionWithForm,
        },
        computed: {
            insurance() {
                let insurance = this.response.body.entities.filter(entity => entity.rels.contains('insurance')).first();
                return insurance;
            },
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
