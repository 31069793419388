<template>
    <div :key="$route.fullPath">
        <main-template>
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                Products
                                <div class="flex items-center -mt-3 text-secondary-darkgrey">
                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter' && !action.fields.isEmpty())">
                                        <form-action class="action-with-fields" :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     onSuccessPath="/products"
                                        ></form-action>
                                    </div>
                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter' && action.fields.isEmpty())">
                                        <confirmation-action :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     onSuccessPath="/products"
                                        ></confirmation-action>
                                    </div>
                                </div>
                            </div>
                        </page-header>

                        <div class="container mx-auto p-4 mb-8">
                            <filterable-paginated-products-list :response="response">

                            </filterable-paginated-products-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import FilterablePaginatedProductsList from '../components/FilterablePaginatedProductsList';
    import Loading from '../components/Loading';
    import DashboardStats from '../components/Dashboard/DashboardStats';
    import SavedFilters from '../components/SavedFilters';
    import PrimaryButton from '../components/PrimaryButton';
    import MainTemplate from "@/v2/templates/MainTemplate";
    import PageHeader from "@/v2/components/Header";
    import ConfirmationAction from "@/v2/components/ConfirmationAction";
    import FormAction from "@/v2/components/FormAction";
    export default {
        components: {
            ConfirmationAction,
            MainTemplate,
            FetchRootSiren,
            FilterablePaginatedProductsList,
            Loading,
            DashboardStats,
            SavedFilters,
            PrimaryButton,
            PageHeader,
            FormAction
        },
        computed: {
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
