<template>
    <div>
        <portal to="destination" >
            <modal class="z-50" v-if="displayModal" :submitting="submittingModal" :title="title" :confirmationString='confirmationTitle' @removeFromDisplay="removeFromDisplay" @perform="perform()" :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction"></modal>
        </portal>
    </div>
</template>

<script>
    import Button from '../Button';
    import Modal from '../Modal';
    import RedButton from "../RedButton";
    import GreenButton from "../GreenButton";
    export default {
        data() {
            return {
                submittingModal: false
            }
        },
        components: {
            'a-button' : Button,
            Modal,
            RedButton,
            GreenButton
        },
        props: {
            title:{
                type: String
            },
            confirmationTitle: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            isButton:{
                type: Boolean,
                default:true
            },
            additionalButtonClasses:{
                type:String
            },
            isNegativeAction: {
                type:Boolean,
                default:false
            },
            isPositiveAction:{
                type:Boolean,
                default:false
            },
            displayModal:{
                type: Boolean,
                default:false
            }
        },
        computed: {
            finalConfirmationString() {
                var preText = '';
                if (this.action.name === 'request-update'){
                    if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                    preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
                }

                return preText + `Are you sure you want to "${this.action.title}"`
            }
        },
        methods: {
            perform() {
                this.$emit('perform');
            },
            messages(response) {
            return response.body.entities
                .filter(function(entity) {
                return entity.rels.contains("status-messages");
                })
                .first();
            },
            removeFromDisplay(){
                this.$emit('removeFromDisplay')
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
