<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <invoices-in-header :response="response"></invoices-in-header>
                        <div class="container mx-auto p-4 mb-8">
                            <!--<saved-filters :response="response">
                            </saved-filters>-->
                            <filterable-paginated-invoices-in-list :response="response">

                            </filterable-paginated-invoices-in-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import SavedFilters from "../components/SavedFilters";
    import MainTemplate from "../templates/MainTemplate";
    import InvoicesInHeader from "../components/Headers/InvoicesInHeader";
    import FilterablePaginatedInvoicesInList from "../components/FilterablePaginatedInvoicesInList";

    export default {
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            InvoicesInHeader,
            SavedFilters,
            FilterablePaginatedInvoicesInList
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
