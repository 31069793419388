<template>
    <div :key="$route.fullPath">
        <main-template>
            <iframe :src="baseUrl" width="100%" style="height:100vh" class="-mb-12"></iframe>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import SuppliersHeader from "../components/SuppliersHeader";
    import FilterablePaginatedSuppliersList from "../components/FilterablePaginatedSuppliersList";
    import SavedFilters from "../components/SavedFilters";
    import MainTemplate from "@/v2/templates/MainTemplate";

    export default {
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading
        },
        props: {
            baseUrl: {
                type: String
            },
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
