<template>
    <div class="h-screen flex overflow-hidden bg-primary-100 relative">
        <notification :message="message"></notification>
        <notification-without-timeout :message="messageWithoutTimeout"></notification-without-timeout>
        <!-- Off-canvas menu for mobile -->

        <div class="md:hidden">
            <div class="fixed inset-0 flex z-40"  v-show="isMobileMenuVisible">
                <transition
                    enter-active-class="transition-opacity ease-linear duration-300"
                    leave-active-class="transition-opacity ease-linear duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div v-show="isMobileMenuVisible" class="fixed inset-0">
                        <div class="absolute inset-0 bg-primary-600 opacity-75"></div>
                    </div>
                </transition>
                <transition
                    enter-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-class="translate-x-0"
                    leave-to-class="-translate-x-full"
                >
                    <div v-show="isMobileMenuVisible" class="relative flex-1 flex flex-col max-w-xs w-full bg-primary-800">
                    <div class="absolute top-0 right-0 -mr-14 p-1" >
                        <button @click="toggleMobileMenu()" class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-primary-600" aria-label="Close sidebar">
                            <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="flex-1 h-0 pb-4 overflow-y-auto">
                        <menu-nav v-if="links" :links="links" :actions="actions" :rav-access="ravAccess" :oss-access="ossAccess" :cms-help-access="cmsHelpHubAccess" :oss-help-access="ossHelpAccess" :rav-help-access="ravHelpAccess"></menu-nav>
                    </div>
                    <div class="flex-shrink-0 flex bg-primary-700 p-4 flex-col">
                        <menu-advert></menu-advert>
                        <div class="flex-shrink-0 group block">
                            <div class="flex items-center">
                                <a href="/notifications" class="relative">
                                    <img class="inline-flex w-14 items-center rounded-full" v-if="user.profile_image" :src="'/storage/profile_images/' + user.profile_image">
                                    <div v-else>
                                        <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
                                            <span class="text-md font-medium leading-none text-white">{{initials}}</span>
                                          </span>
                                    </div>
                                    <span v-if="userHasNotifications"
                                          class="absolute top-0 right-0 block h-3 w-3 rounded-full ring-2 ring-white bg-red-400"></span>
                                </a>
                                <div class="ml-3">
                                    <div class="flex items-center">
                                        <p class="text-sm leading-5 font-medium text-white">
                                            {{user.name}} {{clientName ? '/ ' + clientName : ''}}
                                        </p>
                                        <a class="mr-3 ml-3 cursor-pointer" @click="displaySettings = true" :data-cy="`action-button`">
                                            <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>
                                        </a>
                                    </div>
                                    <a @click.prevent="logout" :data-cy="`sign-out`" class="cursor-pointer text-xs leading-4 font-medium text-gray-300 group-hover:text-gray-200 transition ease-in-out duration-150">
                                        Sign Out
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </transition>
                <div class="flex-shrink-0 w-14">
                    <!-- Force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </div>

        <!-- Static sidebar for desktop -->
        <div class="hidden md:flex md:flex-shrink-0">
            <div class="flex flex-col w-64">
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex flex-col h-0 flex-1 bg-primary-800">
                    <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        <div class="flex items-center justify-center flex-shrink-0 px-4">
                            <a href="/">
                                <app-logo class-for-img="h-auto w-auto px-1" class="h-auto w-auto px-1"></app-logo>
                            </a>
                        </div>
                        <menu-nav v-if="links" :links="links" :actions="actions" :rav-access="ravAccess" :oss-access="ossAccess"  :cms-help-access="cmsHelpHubAccess" :oss-help-access="ossHelpAccess" :rav-help-access="ravHelpAccess"></menu-nav>
                    </div>
                    <div class="flex-shrink-0 flex bg-primary-700 p-4 flex-col" v-if="user">
                                            <menu-advert></menu-advert>
                        <div class="flex-shrink-0 w-full group block">
                            <div class="flex items-center">
                                <div class="relative">
                                    <a href="/notifications">
                                        <img
                                            class="inline-flex w-14 items-center border-2 border-read-500 rounded-full mb-3" v-if="user.profile_image" :src="'/storage/profile_images/' + user.profile_image">
                                        <div v-else>
                                            <span
                                                class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
                                                    <span class="text-md font-medium leading-none text-white">{{ initials }}</span>
                                                </span>
                                        </div>
                                        <span v-if="userHasNotifications"
                                            class="absolute top-0 right-0 block h-3 w-3 rounded-full ring-2 ring-white bg-red-400"></span>
                                    </a>
                                </div>
                                <div class="ml-3">
                                    <div class="flex items-center">
                                        <p class="text-sm leading-5 font-medium text-white">
                                            {{user.name}} {{clientName ? '/ ' + clientName : ''}}
                                        </p>
                                        <a class="mr-3 ml-3 cursor-pointer" @click="displaySettings = true" :data-cy="`action-button`">
                                            <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>
                                        </a>
                                    </div>
                                    <a @click.prevent="logout" :data-cy="`sign-out-desktop`" class="cursor-pointer text-xs leading-4 font-medium text-gray-300 group-hover:text-gray-200 transition ease-in-out duration-150">
                                        Sign Out
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col w-0 flex-1 overflow-hidden">
            <div class="md:hidden pl-1 pt-1 pb-1 sm:pb-3 sm:pl-3 sm:pt-3 bg-primary-800  flex justify-between">
                <button @click="toggleMobileMenu()" class="h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-100 hover:text-gray-900 focus:outline-none focus:bg-primary-200 transition ease-in-out duration-150" aria-label="Open sidebar">
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
                <a href="/" class="flex items-center">
                    <app-logo class-for-img="h-8 w-auto" class="h-8 w-auto"></app-logo>
                </a>
                <span class="w-12 spacer"></span>
            </div>

            <transition
                enter-active-class="ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-out duration-200"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div v-if="!$root.onLine" class="rounded-md bg-yellow-50 p-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: exclamation -->
                            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-sm font-medium text-yellow-800">
                                You're offline
                            </h3>
                            <div class="mt-2 text-sm text-yellow-700">
                                <p>
                                    You are not currently connected to the internet. You can still browse this app however some data and functionality may be missing until you reconnect.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <transition
                enter-active-class="ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-out duration-200"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div v-if="$root.showBackOnline" class="rounded-md bg-green-50 p-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: check-circle -->
                            <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-sm font-medium text-green-800">
                                You're back online.
                            </h3>
                            <div class="mt-2 text-sm text-green-700">
                                <p>
                                    Looks like you are back online you should now have full access to data and functionality.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <main class="flex-1 relative z-1 overflow-y-auto focus:outline-none" tabindex="0" :class="{'bg-cover': backgroundImageUrl !== null}" :style="[backgroundImageUrl !== null ?{backgroundImage:`url(${backgroundImageUrl})`}: null]">
                <div>
                    <slot></slot>
                </div>
            </main>
            <div class="md:hidden h-14">
            </div>

            <div class="md:hidden fixed bottom-0 bg-white w-full p-2 border-t border-gray-200 ">
                <ul class="list-none flex text-center justify-center">
                    <li class="flex flex-col items-center px-2">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                        <span class="text-gray-400 uppercase text-xs"><router-link :to="{ name: 'Dashboard'}">Home</router-link></span>
                    </li>
                    <li class="flex flex-col items-center px-2">
                        <a href="/orders/create" class="flex flex-col items-center">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            <span class="text-gray-400 uppercase text-xs">Order</span>
                        </a>
                    </li>
                    <li class="flex flex-col items-center">
                        <delivery-quick-actions v-if="addDeliveryAction" :displayModal="$route.query.source === 'quick-add-delivery' || $root.quickActionActive === 'add-delivery'" :action="addDeliveryAction" data-cy="delivery-quick-action">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            <span class="text-gray-400 uppercase text-xs">Delivery</span>
                        </delivery-quick-actions>
                    </li>
                </ul>
            </div>
        </div>

        <settings-slide-over v-if="displaySettings" :title="`Settings`" :data-cy="`button-settings`"
                             @removeFromDisplay="resetSettings()" @perform="perform()" class="settings-slide-over">
            <property-or-field class="mt-4" title="Name" :action="userEditAction" fieldId="name" v-model="formEditData['name']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Email" :action="userEditAction" fieldId="email" v-model="formEditData['email']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Contact Number" :action="userEditAction" fieldId="contact_number" v-model="formEditData['contact_number']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Profile Image" :action="userEditAction" fieldId="profile_image" v-model="formEditData['profile_image']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Background Image" :action="userEditAction" fieldId="background_image" v-model="formEditData['background_image']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Contact Preference" :action="userEditAction" fieldId="contact_preference" v-model="formEditData['contact_preference']" scope="user-details"></property-or-field>
            <button v-if="showPasswordField === false" @click="showPasswordField = true" data-cy="edit-password" type="button" class="inline-flex mr-3 mt-4 justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Edit Password
            </button>

            <property-or-field class="mt-4" v-if="showPasswordField" title="Current Password" :action="userResetPasswordAction" fieldId="current_password" v-model="formPasswordData['current_password']" scope="password"></property-or-field>
            <property-or-field class="mt-4" v-if="showPasswordField" title="Password" :action="userResetPasswordAction" fieldId="password" v-model="formPasswordData['password']" scope="password"></property-or-field>

        </settings-slide-over>
    </div>

</template>

<script>
    import MenuNav from "../common/MenuNav";
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import formatting from "../mixins/formatting";
    import Notification from "../components/Notification";
    import SettingsSlideOver from "../components/SettingsSlideOver";
    import PropertyOrField from "../components/PropertyOrField";
    import AddDeliveryQuickAction from "../components/AddDeliveryQuickAction";
    import DeliveryQuickActions from "../components/DeliveryQuickActions";
    import ActionWithForm from "../components/ActionWithForm";
    import MenuAdvert from "../common/MenuAdvert";
    import AppLogo from "../components/AppLogo";
    import NotificationWithoutTimeout from "../components/NotificationWithoutTimeout";
    export default {
        inject: ["$validator"],
        components:{MenuNav, Notification, NotificationWithoutTimeout, SettingsSlideOver, PropertyOrField, AddDeliveryQuickAction, ActionWithForm, DeliveryQuickActions, MenuAdvert, AppLogo},
        mixins: [formatting],
        data() {
            return {
                currentAction: null,
                canCreateOrders: [1, 2, 6, 7], //Site Manage, Procurement Manager, Admin, Approval Team,
                canInviteClients: [6],
                onboardingSteps: [],
                links : null,
                actions: null,
                clientName: null,
                isMobileMenuVisible:false,
                displaySettings: false,
                userEditAction: null,
                userResetPasswordAction: null,
                submittingModal:false,
                showPasswordField: false,
                showMessages:true,
                show:false,
                ravAccess:false,
                ossAccess:false,
                cmsHelpHubAccess:false,
                ossHelpAccess:false,
                ravHelpAccess:false,
                userHasNotifications: false
            };
        },
        props: {
            backgroundImageUrl: {
                type: String,
                default: null
            },
        },
        computed: {
            addDeliveryAction()
            {
                if(!this.actions){
                    return null;
                }
                return this.actions.filter(action => action.name === 'find-order-to-add-delivery-for').first();
            },
            initials() {
                if (this.user) {
                    return this.user.name
                        .replace(/[^a-zA-Z- ]/g, "")
                        .match(/\b\w/g)
                        .join("");
                }
            },
            formEditData() {
                if (!this.userEditAction.fields) {
                    return {};
                }
                var payload = {};
                this.userEditAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            formPasswordData() {
                if (!this.userResetPasswordAction.fields) {
                    return {};
                }
                var payload = {};
                this.userResetPasswordAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },

            ...mapGetters({
                accessToken: "getAccessToken",
                user: "getUser",
                message: "getMessage",
                messageWithoutTimeout: "getMessageWithoutTimeout"
            }),
        },
        methods: {
            logout() {
                this.$store.dispatch("logout").then(() => this.$router.push("/login"));
            },
            toggleMobileMenu(){
                this.isMobileMenuVisible = !this.isMobileMenuVisible;
            },
            getFieldComponent(field) {
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.displaySettings = true;
                this.errors.clear("user-details");
                this.errors.clear("password");
                this.userEditAction.perform(this.formEditData).then(res => {
                    if (this.showPasswordField) {
                        this.userResetPasswordAction.perform(this.formPasswordData).then(res => {
                            this.successfullySubmitted(res);
                        }).catch(error => {
                            this.submittingModal = false;
                            alert('There was an error processing your request.' + error);
                            this.$setLaravelValidationErrorsFromResponse(
                                JSON.parse(error.response.text),
                                'password'
                            );
                            this.$emit('failure');
                        })
                    }else{
                        this.successfullySubmitted(res);
                    }
                }).catch(error => {
                    this.submittingModal = false;
                    alert('There was an error processing your request.' + error);
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text),
                        "user-details"
                    );
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            resetSettings(){
                this.showPasswordField = false;
                this.displaySettings = false;
                this.formEditData.name = this.userEditAction.fields.get('name').value;
                this.formEditData.email = this.userEditAction.fields.get('email').value;
                this.formEditData.contact_number = this.userEditAction.fields.get('contact_number').value;
                this.formPasswordData.password = '';
                this.formEditData.profile_image = '';
                this.errors.clear("user-details");
                this.errors.clear("password");
            },
            successfullySubmitted(res){
                this.displaySettings = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                this.$router.go(0);
                this.$emit('success', res);
            },
            goToNotificationsLink()
            {
                this.$router.push('/notifications')
            }
        },
        mounted() {
            let follow = Siren.Helper.follow;
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

            Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
                let siren = res.body;
                this.links = siren.links;
                this.actions = siren.actions;
                return res;
            }, this).then(
                follow("current-user")
            )
                .then(res => {
                    let siren = res.body;
                    let client = siren.embeddedEntities.filter(entity => entity.entity.classes.contains('client')).first();
                    if(client !== undefined){
                        let name = client.entity.properties.get('name');
                        this.clientName = name;
                        this.ravAccess = client.entity.properties.get('can_access_rav');
                        this.ossAccess = client.entity.properties.get('can_access_oss');
                        this.cmsHelpHubAccess = client.entity.properties.get('can_access_cms_help_hub');
                        this.ossHelpAccess = client.entity.properties.get('can_access_oss_help');
                        this.ravHelpAccess = client.entity.properties.get('can_access_rav_help');
                    }else{
                        this.ravAccess = !siren.properties.get('is_direct_supplier');
                        this.ossAccess = !siren.properties.get('is_direct_supplier');
                        this.cmsHelpHubAccess = !siren.properties.get('is_direct_supplier');
                        this.ossHelpAccess = !siren.properties.get('is_direct_supplier');
                        this.ravHelpAccess = !siren.properties.get('is_direct_supplier');
                    }
                    this.user.name = siren.properties.get('name');
                    this.user.has_notifications = siren.properties.get('has_notifications');
                    this.userHasNotifications = this.user.has_notifications;
                    this.user.profile_image = siren.properties.get('profile_image');
                    this.userEditAction = siren.findActionByName('update-user');
                    this.userResetPasswordAction= siren.findActionByName('reset-user-password');
                }, this).catch(error => {
                    console.log('error fetching user data');
                })

            this.$forceUpdate();
        },
    }
</script>

<style>
.settings-slide-over .form-group label{display:none;}
</style>
