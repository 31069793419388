import { render, staticRenderFns } from "./SupplierGroups.vue?vue&type=template&id=2dbbf828&scoped=true&"
import script from "./SupplierGroups.vue?vue&type=script&lang=js&"
export * from "./SupplierGroups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dbbf828",
  null
  
)

export default component.exports