<template>
    <div>
        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full" data-cy="orders-table">
                    <thead>
                        <tr v-if="filterAction">
                            <th colspan=1000 class="p-8  bg-gray-50">
                                <generic-table-filter :action="filterAction"
                                :response="response"
                                actionBaseUrl="/holiday-requests"
                                ></generic-table-filter>
                            </th>
                        </tr>
                        <tr>
                        <th
                            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            v-for="headingValue in headingValues"
                            :key="headingValue"
                        >{{headingValue}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.response.body.properties.get('total') === 0">
                        <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                No holiday requests found.
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="holidayRequestEntity,key in holidayRequestEntities">
                            <tr class="bg-white hover:bg-gray-100 cursor-pointer" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                <template v-for="field, key in getPropertiesToDisplay(holidayRequestEntity)">
                                    <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                        {{field}}
                                    </td>
                                </template>
                                <td class="flex p-2">
                                    <action-with-confirmation v-if="holidayRequestEntity.entity.actions.get('approve')" :action="holidayRequestEntity.entity.actions.get('approve')"
                                                              actionKey="approve"
                                                              :onSuccessPath="$router.currentRoute.fullPath">
                                    </action-with-confirmation>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    </table>
                    <pagination
                        :response="response"
                        @navigate="paginationNavigation($event)"
                        @navigateAdditionalNext="paginationNavigation($event)"
                        @navigateAdditionalPrev="paginationNavigation($event)"
                    />
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatusBadge from "./StatusBadge";
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    import ActionWithConfirmation from "@/components/ActionWithConfirmation";
    export default {
        components: {
            StatusBadge,
            Pagination,
            GenericTableFilter,
            ActionWithConfirmation
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            holidayRequestEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('holiday-request'));
            },
            headingValues() {
                return ['User', 'Start Date', 'End Date', 'Requested Days', 'Approved', 'Reason', 'Actions'];
            }
        },
        methods: {
            getPropertiesToDisplay(holidayRequestEntity) {
                return {
                    'user' : holidayRequestEntity.entity.properties.get('user'),
                    'start_date' : holidayRequestEntity.entity.properties.get('start_date'),
                    'endDate' : holidayRequestEntity.entity.properties.get('end_date'),
                    'requested_days' : holidayRequestEntity.entity.properties.get('requested_days'),
                    'approved' : holidayRequestEntity.entity.properties.get('is_approved'),
                    'reason' : holidayRequestEntity.entity.properties.get('reason')
                };
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
