<template>
    <div class="z-40 rental-form">
        <a-button class="mr-3" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>
        <portal to="destination">
            <slide-over v-if="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
                        :confirmationString='`Are you sure you want to \"${action.title}\"`'
                        @removeFromDisplay="displayModal = false" @perform="checkUploadedBeforeSubmit()">

                <filterable-select v-model="formData['order_id']" :options="action.fields.get('order_id').options" :title="action.fields.get('order_id').title"></filterable-select>
                <div v-show="errors.has('order_id')" class="text-red-600">{{ errors.first('order_id') }}</div>




            </slide-over>
        </portal>
        <uploaded-to-sage-confirmation @perform="perform" @removeFromDisplay="displayUploadedConfirmation=false" :displayModal="displayUploadedConfirmation" title="Uploaded To Sage" confirmationTitle="The Ex.Ref in sage will not be updated, Please update it manually"></uploaded-to-sage-confirmation>

    </div>
</template>

<script>
    import Button from '@/v2/buttons/Button';
    import Modal from '@/v2/components/Modal';
    import SlideOver from "@/v2/components/SlideOver";
    import PropertyOrField from "@/components/PropertyOrField";
    import DependantField from "@/components/DependantField";
    import FilterableSelect from "@/templates/FilterableSelect";
    import UploadedToSageConfirmation from "@/v2/components/forms/UploadedToSageConfirmation";
    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                orderId:null,
                displayUploadedConfirmation: false
            }
        },
        components: {
            SlideOver,
            'a-button': Button,
            Modal,
            DependantField,
            PropertyOrField,
            FilterableSelect,
            UploadedToSageConfirmation
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            isUploaded: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            checkUploadedBeforeSubmit(){
                if (this.isUploaded){
                    //confirm("This invoice has been uploaded to sage. The ex ref in sage will not update, please update it manually.")
                    //this.perform();
                    this.displayUploadedConfirmation = true;
                }else{
                    this.perform();
                }
            },
            perform() {
                this.displayUploadedConfirmation = false;
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
    .rental-form label.label-a{
        display: none;
    }
</style>
