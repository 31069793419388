<template>
    <paginated-list :response="response" actionBaseUrl="/vehicles">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Make</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Model</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">License Number</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Mileage</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Current Keeper</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Actions</th>
        </template>
        <template v-slot:noItems>
            No vehicles found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in vehicles" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td @click="navigateToItem(entity)" class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('make') }}
                    </div>
                </td>
                <td @click="navigateToItem(entity)" class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{ entity.entity.properties.get('model') }}</div>
                </td>
                <td @click="navigateToItem(entity)" class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{ entity.entity.properties.get('license_number') }}</div>
                </td>
                <td @click="navigateToItem(entity)" class="whitespace-nowrap px-3 py-4 text-ism text-secondary-darkgrey font-bold">
                    <div>{{entity.entity.properties.get('mileage')}}</div>
                </td>
                <td @click="navigateToItem(entity)" class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('current_keeper') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold flex">
                    <form-action v-if="entity.entity.actions.get('request-handover')" :action="entity.entity.actions.get('request-handover')" :onSuccessPath="$route.path">
                    </form-action>
                    <confirmation-action buttonType="positive" v-if="entity.entity.actions.get('accept-handover')" :action="entity.entity.actions.get('accept-handover')" :onSuccessPath="$route.path">
                    </confirmation-action>
                    <start-handover-form v-if="entity.entity.actions.get('start-handover')" :action="entity.entity.actions.get('start-handover')"
                                         :onSuccessPath="$route.path"
                    ></start-handover-form>
                    <start-handover-form v-if="entity.entity.actions.get('complete-handover')" :action="entity.entity.actions.get('complete-handover')"
                                         :onSuccessPath="$route.path"
                    ></start-handover-form>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
import StartHandoverForm from "@/v2/components/forms/StartHandoverForm";
export default {
    name: "VehiclePaginatedList",
    components:{
        ConfirmationAction,
        PaginatedList,
        StatusBadge,
        OrderTabs,
        FormAction,
        StartHandoverForm
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        vehicles() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('vehicle'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
