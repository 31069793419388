import { render, staticRenderFns } from "./PayNSend.vue?vue&type=template&id=e865d10a&scoped=true&"
import script from "./PayNSend.vue?vue&type=script&lang=js&"
export * from "./PayNSend.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e865d10a",
  null
  
)

export default component.exports