<template>
    <main-template>
        <template slot-scope="{logo}">
        <div>
            <template>
                <page-header :logo="logo">
                    <div class="md:flex justify-between w-full items-center">
                        Search for Take Offs
                    </div>
                </page-header>
                <take-off-search-form></take-off-search-form>
            </template>
        </div>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import TakeOffSearchForm from "@/v2/components/forms/TakeOffSearchForm";
    export default {
    name: "TakeOffSearch",
        components: {
            FormAction,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader,
            TakeOffSearchForm
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {

        }
    }

</script>

<style scoped>

</style>
