<template>
    <div class="text-right">
        <secondary-button @click.native="displayModal = true" data-cy="upload-items-via-csv">
            <svg class="stroke-current inline-block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
            </svg>
        </secondary-button>
        <modal v-if="displayModal" title="Upload items from csv" confirmationString="" @removeFromDisplay="displayModal = false" @perform="load()">
            <p class="text-sm leading-5 text-gray-500 mb-2">Please upload the items in the correct format. <a @click="downloadTemplate()" class="underline cursor-pointer hover:text-gray-900">Click here to download the template.</a></p>

            <input data-cy="items-csv-upload" type="file" accept=".csv" @change="loadTextFromFile"/>
        </modal>
    </div>
</template>

<script>
    import moment from "moment";
    import Modal from './Modal';
    import SecondaryButton from './SecondaryButton';
    import fileDownload from 'js-file-download';
    export default {
        components: {
            Modal,
            SecondaryButton,
        },
        data() {
            return {
                displayModal: false,
                result: null
            }
        },
        props: {
            fileTemplate: {
                type: String,
                default: '"description","code","due_date","quantity","unit"'
            },
            fileTemplateName:{
                type: String,
                default: 'order-items-upload-template.csv'
            }
        },
        methods: {
            downloadTemplate() {
                fileDownload(this.fileTemplate, this.fileTemplateName);
            },
            loadTextFromFile(ev) {
                const file = ev.target.files[0];
                const reader = new FileReader();

                reader.onload = e => this.result = e.target.result;
                reader.readAsText(file);
            },
            load(){
                console.log(this.result);
                let lines = this.result.split("\r\n").filter(function (el) {
                    return el != '';
                });
                console.log(lines);
                const header = lines.shift().split(",").map(function(item){
                    return item.replace(/^'(.*)'$/, '$1').replace(/^"(.*)"$/, '$1');
                });

                let obj = lines.map(line => {
                    const bits = line.split(",")
                    let obj = {};

                    // header.forEach((h, i) => obj[h] = console.log(bits[i], i));

                    header.forEach((h, i) => {
                        obj[h] = bits[i].replace(/^'(.*)'$/, '$1').replace(/^"(.*)"$/, '$1').replace('\n', ' ').replace(/^(\")/, '').replace(/(\")$/, '')
                    });
                    if(obj.due_date) {
                        obj.due_date = moment(obj.due_date, "DD/MM/YYYY").format('YYYY-MM-DD');
                    }
                    if(obj.unit_price) {
                        obj.unit_price = obj.unit_price * 100
                    }

                    return obj;
                });
                this.$emit('itemsImported', obj);
                this.displayModal = false;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
