<template>
    <div id="contracts" class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Prefix
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Name
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Address Override
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Belongs To User
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Belongs To Project
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white" v-if="contracts.size > 0">
                <template v-for="contract in contracts">
                    <tr class="border-b border-gray-200 last:border-b-0" :class="{'bg-gray-200 border-gray-300': contract.entity.properties.get('deactivated_at') !== null}">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ contract.entity.properties.get('prefix') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ contract.entity.properties.get('name') }}
                        </td>
                        <td class="px-3 py-4 text-sm leading-5 text-gray-500">
                            {{ contract.entity.properties.get('address') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ contract.entity.properties.get('belongs_to_user') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{contract.entity.properties.get('belongs_to_project')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <div class="flex">
                                <template v-for="actionWithFields in contract.entity.actions.filter(action => !action.fields.isEmpty())">
                                    <add-contract-form v-if="actionWithFields[0] === 'update-site'" :action="actionWithFields[1]"
                                                       :actionKey="actionWithFields[0]"
                                                       :redirectOnSuccess="true"
                                                       :onSuccessPath="$router.currentRoute.fullPath"
                                                       additionalButtonClasses="mr-3"></add-contract-form>
                                    <form-action v-else :action="actionWithFields[1]"
                                                      :actionKey="actionWithFields[0]"
                                                      :onSuccessPath="$route.path"
                                                      additionalButtonClasses="mr-3"
                                    ></form-action>
                                </template>
                                <template v-for="actionWithFields in contract.entity.actions.filter(action => action.fields.isEmpty())">
                                    <confirmation-action :action="actionWithFields[1]"
                                                      :actionKey="actionWithFields[0]"
                                                      :onSuccessPath="$route.path"
                                                      :buttonType="actionWithFields[0].includes('delete') || actionWithFields[0].includes('deactivate')? 'negative' : 'primary'"
                                                      additionalButtonClasses="mr-3"
                                    ></confirmation-action>
                                </template>
                            </div>
                        </td>
                    </tr>
                </template>
                </tbody>
                <tbody v-else>
                <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                        No contracts found.
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ConfirmationAction from "@/v2/components/ConfirmationAction";
    import FormAction from "@/v2/components/FormAction";
    import AddContractForm from "@/v2/components/forms/AddContractForm.vue";
    export default {
        components: {
            AddContractForm,
            ConfirmationAction,
            FormAction
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            contracts() {
                let contracts = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("site");
                    });
                if(!contracts){
                    return null;
                }

                return contracts;
            },
            createContractAction() {
                return this.response.body.actions.filter(action => action.name === 'add-new-site').first()
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            },
            success(key) {
                this.$router.go(0);
            }
        },
        mounted(){
            if(this.$route.hash == '#contracts') {
                document.querySelector(this.$route.hash).scrollIntoView();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
