<template>
        <div>
            <div class="divide-y divide-gray-200">
                <div class="px-4 py-6 sm:px-6">
                    <span v-if="comments.length < 1" class="text-gray-500 text-sm">No comments yet.</span>

                    <ul class="space-y-8">
                        <li v-for="comment in comments">
                            <div class="flex space-x-3">
                                <div class="flex-shrink-0">
                                    <img class="h-10 w-10 rounded-full" v-if="comment.added_by_profile_image" :src="getprofileImageUrl(comment.added_by_profile_image)" alt="">
                                    <div v-else>
                                        <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
                                            <span class="text-md font-medium leading-none text-white">{{initials(comment.added_by)}}</span>
                                          </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-sm">
                                        <a href="#" class="font-medium text-gray-900">{{comment.added_by}}</a>
                                    </div>
                                    <div class="mt-1 text-sm text-gray-700">
                                        <p>{{comment.text}}</p>
                                    </div>
                                    <div class="mt-2 text-sm space-x-2">
                                        <span class="text-gray-500 font-medium">{{ comment.created_at }}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="bg-gray-50 px-4 py-6 sm:px-6">
                <div class="flex space-x-3">
                    <div class="flex-shrink-0">
                        <img
                            class="h-10 w-10 rounded-full" v-if="user.profile_image" :src="getprofileImageUrl(user.profile_image)">
                        <div v-else>
                            <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
                                <span class="text-md font-medium leading-none text-white">{{initials(user.name)}}</span>
                              </span>
                        </div>
                    </div>
                    <div class="min-w-0 flex-1">
                        <div>
                            <label for="comment" class="sr-only">About</label>
                            <textarea id="comment" name="comment" rows="3" v-model="comment" class="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md" placeholder="Add a comment"></textarea>
                        </div>
                        <div class="mt-3 flex items-center justify-between">
                            <span></span>
                            <a-button @click.native="addComment(response)">
                                Comment
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import {mapGetters} from "vuex";
import Button from "@/v2/buttons/Button";

export default {
    name: "QualityAssessmentComments",
    components:{
        'a-button': Button
    },
    props:{
        response: {
            type: Object
        },
        commentsOverride: {
            type: Array
        },
        comment: ''
    },
    computed:{
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        comments() {
            if(this.commentsOverride){
                return this.commentsOverride;
            }
            return this.response.body.properties.get('comments_stream');
        },
    },
    methods: {
        addComment(response) {
            console.log(this.comment);
            return response.body.actions.filter(action => action.name === 'add-comment').first().perform({
                'comment' : this.comment
            }).then(res => {
                this.commentsOverride = res.body.properties.get('comments_stream');
                this.comment = '';
            });
        },
        initials(name) {
            if (name) {
                return name
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        getprofileImageUrl(url){
            return process.env.VUE_APP_API_URL + '/storage/profile_images/' + url;
        }
    }
}
</script>

<style scoped>

</style>
