<template>
    <paginated-list :response="response" actionBaseUrl="/client-address-book">
        <template v-slot:savedFilter>
            <order-tabs :response="response"></order-tabs>
        </template>
        <template v-slot:headers>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey sm:pl-6">Name</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Email</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Contact Number</th>
        </template>
        <template v-slot:noItems>
            No client contacts found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in contacts" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('name') }}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{entity.entity.properties.get('email')}}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('contact_number') }}
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
export default {
    name: "ClientAddressBookPaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        OrderTabs
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        contacts() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('contact'));
        },
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
