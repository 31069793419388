<template>
    <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
        <div>
            <dt class="text-xs font-normal text-secondary-darkgrey">Project & Contract Information</dt>
            <dd class="mt-1 text-sm font-bold text-black">
                {{orderProject.entity.properties.get('name')}}
                -
                {{orderSite.entity.properties.get('name')}}
            </dd>
        </div>
        <div>
            <dd class="text-sm font-bold text-black">
                <span v-if="orderSite.entity.properties.get('formattedAddress')" v-html="orderSite.entity.properties.get('formattedAddress')"></span>
                <span v-else v-html="orderProject.entity.properties.get('formattedAddress')"></span>
            </dd>

        </div>
        <div>
            <dt class="text-xs font-normal text-secondary-darkgrey">Contact Information</dt>
            <dd class="mt-1 text-sm font-bold text-black">{{orderUser.entity.properties.get('name')}}</dd>
            <dd class="mt-1 text-sm font-bold text-black">{{orderUser.entity.properties.get('contact_number') ? orderUser.entity.properties.get('contact_number') : ''}}
              {{orderUser.entity.properties.get('contact_number') && orderUser.entity.properties.get('email') ? ' / ': ''}}
              {{orderUser.entity.properties.get('email')}}</dd>
        </div>
    </dl>
</template>

<script>
export default {
    name: "OrderUserInfo",
    props:{
        response:{
            type: Object
        }
    },
    computed: {
        orderUser(orderEntity) {
            let orderUser = this.response.body.entities
                .filter(function(entity) {
                    return entity.rels.contains("user");
                })
                .first();

            if(!orderUser){
                return null;
            }

            return orderUser;
        },
        orderSite(orderEntity) {
            let orderSite = this.response.body.entities
                .filter(function(entity) {
                    return entity.rels.contains("site");
                })
                .first();

            if(!orderSite){
                return null;
            }

            return orderSite;
        },
        orderProject(orderEntity) {
            let orderProject = this.response.body.entities
                .filter(function(entity) {
                    return entity.rels.contains("project");
                })
                .first();

            if(!orderProject){
                return null;
            }

            return orderProject;
        }
    }
}
</script>

<style scoped>

</style>
