<template>
        <div class="bg-white overflow-hidden shadow rounded-lg min-h-full flex flex-col justify-between">
          <div class="px-4 flex-grow flex justify-start items-center">
            <div class="flex items-center">
              <div class="flex-shrink-0 rounded-md p-3 my-3" :class="backgroundColor" v-html="svg"></div>
              <div class="ml-5 flex-grow">
                <dl>
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 truncate"
                  >{{title}}</dt>
                  <dd class="flex items-baseline">
                    <div
                      class="text-2xl leading-8 font-semibold text-gray-900"
                    >{{value}}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-4 sm:px-6" v-if="viewMoreLink">
            <div class="text-sm leading-5">
              <a
                @click.prevent="navigateTo(viewMoreLink)"
                :href="viewMoreLink"
                class="font-medium text-blue-600 hover:text-blue-500 transition ease-in-out duration-150"
              >{{viewMoreLinkTitle}}</a>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-4 sm:px-6" v-else>
            <div class="text-sm leading-5">
              Coming soon.
            </div>
          </div>
        </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
            },
            title: {
                type: String,
            },
            value: {
                type: String,
            },
            viewMoreLink: {
                type: String,
            },
            viewMoreLinkTitle: {
                type: String,
            },
        },
        methods: {
          navigateTo(link){
            window.location = this.getFrontendRouteFromApiRoute(link);
          },
        },
        computed: {
            svg() {
                if(this.name === 'on-hire'){
                    return `<svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"/>
                    </svg>`;
                }

                if(this.name === 'orders-in-progress'){
                    return `<svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>`;
                }

                if(this.name === 'awaiting-approval' || this.name === 'awaiting-credit'){
                    return `<svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>`;
                }

                return `
                    <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"/>
                    </svg>
                `;
            },
            backgroundColor(){
                if(this.name === 'on-hire'){
                    return 'bg-orange-300';
                }

                if(this.name === 'orders-in-progress'){
                    return 'bg-red-400';
                }

                if(this.name === 'awaiting-approval' || this.name === 'awaiting-credit'){
                    return 'bg-green-300';
                }

                return 'bg-pink-400';
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
