<template>
    <div class="flex flex-col" v-if="provisionalQuote">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Competing Quote</h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="grid grid-cols-2">
                <div>
                    Supplier:
                </div>
                <div>
                    {{ provisionalQuote.entity.properties.get('company_name') }}
                </div>
                <div>
                    Manufacturer:
                </div>
                <div>
                    {{ provisionalQuote.entity.properties.get('manufacturer') }}
                </div>
                <div>
                    Includes VAT:
                </div>
                <div>
                    {{ provisionalQuote.entity.properties.get('includes_vat') }}
                </div>
                <div>
                    Total
                </div>
                <div>
                    {{ provisionalQuote.entity.properties.get('total') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DownloadPdf from "../pages/DownloadPdf";

    export default {
        components: {DownloadPdf},
        props: {
            response: {
                type: Object,
            },
        },
        data() {
            return {
                showPDF: false
            }
        },
        computed: {
            provisionalQuote(orderEntity) {
                let provisionalQuote = this.response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("competing_quote");
                    })
                    .first();

                if (!provisionalQuote) {
                    return null;
                }

                return provisionalQuote;
            },
            rawInvoiceValue() {
                return parseInt(this.provisionalQuote.entity.properties.get('invoice_net_value').replace(/£/g, "").replace(/\,/g, ''));
            },
            rawTotal() {
                return parseInt(this.provisionalQuote.entity.properties.get('total').replace(/£/g, "").replace(/\,/g, ''));
            },
            isInvoiceValueGreaterThanQuotedValue() {
                return this.rawInvoiceValue > this.rawTotal;
            },
            getBannerText()
            {
                if(this.provisionalQuote.entity.properties.get('total_with_service_fee') !== null){
                    return 'Total inc service fee: ' + this.provisionalQuote.entity.properties.get('total_with_service_fee')
                }
                return null;
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
                if (!/^https?:\/\//i.test(url)) {
                    url = "http://" + url;
                }
                return url;
            },
            togglePDF()
            {
                this.showPDF = !this.showPDF
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
