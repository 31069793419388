<template>
        <main-template :backgroundImageUrl="backgroundImageUrl">
            <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link">

                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                <span class="text-secondary-darkblue">CMS Dashboard</span>
                                <div class="flex items-center">
                                    <div class="mr-1 hidden md:block text-secondary-darkgrey">
                                        <template v-if="response.body.actions.filter(action => action.name === 'save-widgets').first()">
                                            <template v-if="editable">
                                                <a-button @click.native="displayModal = true">Add Widget</a-button>
                                                <slide-over v-if="displayModal" title="Add widget"
                                                            confirmationString=''
                                                            @removeFromDisplay="displayModal = false" @perform="addWidget">
                                                    <radio-input :field="widgetTypeValues" v-model="inputValue"></radio-input>
                                                </slide-over>
                                            </template>
                                            <template v-else>
                                                <a-button @click.native="enableEditWidgets">Edit Widgets</a-button>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>



                        </page-header>

                        <onboarding-steps v-if="unfinishedOnboardingSteps.length > 0" :completeSteps="finishedOnboardingSteps" :incompleteSteps="unfinishedOnboardingSteps"></onboarding-steps>
                        <dashboard-modal></dashboard-modal>

                        <advert></advert>

                        <drag-drop-widgets :editable="editable"></drag-drop-widgets>
                    </template>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>






</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import FilterablePaginatedOrdersList from '@/components/FilterablePaginatedOrdersList';
import Loading from '@/components/Loading';
import DashboardStats from '@/components/Dashboard/DashboardStats';
import SavedFilters from '@/components/SavedFilters';
import DashboardHeader from '@/components/Headers/DashboardHeader';
import MainTemplate from '@/v2/templates/MainTemplate';
import OnboardingSteps from "@/components/OnboardingSteps";
import {mapGetters} from "vuex";
import Siren from "super-siren";
import DashboardModal from "@/components/Dashboard/DashboardModal";
import DragDropWidgets from "@/components/DragDropWidgets";
import PageHeader from "@/v2/components/Header";
import Button from "@/v2/buttons/Button";
import SlideOver from "@/v2/components/SlideOver";
import Advert from "@/v2/components/Advert";
export default {
    components: {
        Advert,
        OnboardingSteps,
        FetchSirenRoot,
        Loading,
        DashboardStats,
        DashboardHeader,
        SavedFilters,
        MainTemplate,
        DashboardModal,
        DragDropWidgets,
        PageHeader,
        'a-button' : Button,
        SlideOver
    },
    data() {
        return {
            onboardingSteps: [],
            editable: false,
            backgroundImage: null,
            backgroundImageUrl: null,
            hasOssModule: false,
            displayModal: false,
            widgetTypeValues: null,
            inputValue:null
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        unfinishedOnboardingSteps() {
            return this.onboardingSteps.filter(step => step.done !== true)
        },
        finishedOnboardingSteps() {
            return this.onboardingSteps.filter(step => step.done === true)
        },

    },
    mounted() {
        let follow = Siren.Helper.follow;

        EventBus.$on('disableEditWidgets', function() {
            this.editable = false;
        }.bind(this))

        this.widgetTypeValues = { name: 'widget_type', title: 'Widget Type', type: 'radio', options: [
                {value: 'stats', title: 'Stats'},
                {value: 'project-locations', title: 'Project Locations'},
                {value: 'orders-in-progress', title: 'Orders In Progress'},
                {value: 'awaiting-approval', title: 'Awaiting Approval'},
                {value: 'awaiting-credit', title: 'Awaiting Credit'},
                {value: 'schedules', title: 'Schedules'},
                {value: 'current-date-time', title: 'Current Date And Time'},
                {value: 'dashboard-clocked-in',title: 'Users Checked In'},
                {value: 'notifications', title: 'Notifications'},
                {value: 'quotes-mismatch', title: 'Quotes Mismatch'}
            ]};


        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
            Siren.get(process.env.VUE_APP_API_URL + "/api/").then(
                follow("current-user")
            )
                .then(res => {
                    this.backgroundImage = res.body.properties.get('background_image');
                    if(this.backgroundImage !== null){
                        this.backgroundImageUrl = process.env.VUE_APP_API_URL + '/storage/background_images/' + this.backgroundImage
                    }
                    this.hasOssModule = res.body.properties.get('has_oss_module');

                    if(!this.hasOssModule){
                        this.widgetTypeValues.options.forEach((widget, index) => {
                            if(widget.value === 'dashboard-clocked-in' && !this.hasOssModule){
                                this.widgetTypeValues.options.splice(index, 1);
                            }
                        });
                    }
                })
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(
                    follow("onboarding-steps")
                )
                .then(res => {
                    this.onboardingSteps = JSON.parse(res.text).links;
                }, this)
                .catch(error => {
                    console.log('error fetching dashboard');
                });
        });



    },
    methods: {
        addWidget(){
            EventBus.$emit('addWidget', this.inputValue);
            this.inputValue = null;
            this.displayModal = false;
        },
        enableEditWidgets(){
            this.editable = true;
            EventBus.$emit('enableEditWidgets');
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
