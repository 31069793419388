<template>
    <main-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        Return Requests
                    </page-header>
                    <return-requests-paginated-list :response="response"></return-requests-paginated-list>
                </template>
            </div>
            </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import PaginatedList from "@/v2/paginatedLists/PaginatedList";
    import PageHeader from "@/v2/components/Header";
    import ReturnRequestsPaginatedList from "@/v2/paginatedLists/ReturnRequestsPaginatedList";
    export default {
    name: "ScheduleManager",
        components: {
            ReturnRequestsPaginatedList,
            PaginatedList,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {

        }
    }

</script>

<style scoped>

</style>
