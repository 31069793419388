<template>
    <div class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-3 pt-6">
        <div class="border md:rounded-lg mb-8 bg-white grid grid-cols-5 col-span-3 section-links-2">
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('departments')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full"  :href="getLinkHref('departments')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320 32C346.5 32 368 53.49 368 80V144C368 170.5 346.5 192 320 192H312V232H464C494.9 232 520 257.1 520 288V320H528C554.5 320 576 341.5 576 368V432C576 458.5 554.5 480 528 480H464C437.5 480 416 458.5 416 432V368C416 341.5 437.5 320 464 320H472V288C472 283.6 468.4 280 464 280H312V320H320C346.5 320 368 341.5 368 368V432C368 458.5 346.5 480 320 480H256C229.5 480 208 458.5 208 432V368C208 341.5 229.5 320 256 320H264V280H112C107.6 280 104 283.6 104 288V320H112C138.5 320 160 341.5 160 368V432C160 458.5 138.5 480 112 480H48C21.49 480 0 458.5 0 432V368C0 341.5 21.49 320 48 320H56V288C56 257.1 81.07 232 112 232H264V192H256C229.5 192 208 170.5 208 144V80C208 53.49 229.5 32 256 32H320zM320 80H256V144H320V80zM48 432H112V368H48V432zM256 432H320V368H256V432zM464 432H528V368H464V432z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Departments</p>
                        <!--                        <p>View & Add Holiday Requests</p>-->
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
        actions: {
            type: Object,
            default: {}
        }
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasAction(key){
            return this.actions.filter(action => action.name === key).size;
        }
    },
}
</script>

<style scoped>

</style>
