<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full" data-cy="invoices-in-table">
                    <thead>
                        <tr v-if="filterAction">
                            <th colspan=1000 class="p-8  bg-gray-50">
                                <generic-table-filter :action="filterAction"
                                :response="response"
                                :actionBaseUrl="$route.path"
                                ></generic-table-filter>
                            </th>
                        </tr>
                        <tr>
                        <th
                            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            v-for="headingValue in headingValues"
                            :key="headingValue"
                        >{{headingValue}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.response.body.properties.get('total') === 0">
                        <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                No Purchase Invoices found.
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="manufacturerEntity,key in manufacturerEntities">
                            <tr class="bg-white hover:bg-gray-100 cursor-pointer" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                <template v-for="field, key in getPropertiesToDisplay(manufacturerEntity)">
                                    <invoices-in-cell :cellKey="key" :field="field"></invoices-in-cell>
                                </template>


                                <td class="flex p-2">
                                    <reconcile-order-form v-if="manufacturerEntity.entity.actions.get('update-invoice')" :action="manufacturerEntity.entity.actions.get('update-invoice')"
                                                      actionKey="update-invoice"
                                                          :onSuccessPath="$router.currentRoute.fullPath"
                                    ></reconcile-order-form>
                                    <reconcile-order-form v-if="manufacturerEntity.entity.actions.get('send-for-approval')" :action="manufacturerEntity.entity.actions.get('send-for-approval')"
                                                          actionKey="send-for-approval"
                                                          :onSuccessPath="$router.currentRoute.fullPath"
                                    ></reconcile-order-form>
                                    <action-with-confirmation :action="manufacturerEntity.entity.actions.get('mark-invoice-as-uploaded-to-sage')"
                                                              actionKey="mark-invoice-as-uploaded-to-sage"
                                                              :onSuccessPath="$router.currentRoute.fullPath">
                                    </action-with-confirmation>
                                    <action-with-confirmation v-if="manufacturerEntity.entity.actions.get('approve-invoice')"
                                                              :action="manufacturerEntity.entity.actions.get('approve-invoice')"
                                                              actionKey="approve-invoice"
                                                              :onSuccessPath="$router.currentRoute.fullPath"
                                                              isPositiveAction="true">
                                    </action-with-confirmation>
                                    <action-with-form v-if="manufacturerEntity.entity.actions.get('reject-invoice')"
                                                      :action="manufacturerEntity.entity.actions.get('reject-invoice')"
                                                      actionKey="reject-invoice"
                                                      :onSuccessPath="$router.currentRoute.fullPath"
                                                      isNegativeAction="true">
                                    </action-with-form>
                                    <delete-invoice-in-confirmation :action="manufacturerEntity.entity.actions.get('delete-invoice')"
                                    actionKey="delete-invoice"
                                    :onSuccessPath="$router.currentRoute.fullPath"
                                    ></delete-invoice-in-confirmation>

                                </td>
                            </tr>
                            <tr v-if="manufacturerEntity.entity.properties.get('rejection_note')"
                                class="bg-white text-red-600" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                <td class="px-6 pb-2" colspan="100">
                                    Rejection Note: {{manufacturerEntity.entity.properties.get('rejection_note')}}
                                </td>
                            </tr>

                        </template>
                    </tbody>
                    </table>
                    <pagination
                        :response="response"
                        @navigate="paginationNavigation($event)"
                        @navigateAdditionalNext="paginationNavigation($event)"
                        @navigateAdditionalPrev="paginationNavigation($event)"
                    />
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    import PrimaryButton from "./PrimaryButton";
    import SecondaryButton from "./SecondaryButton";
    import ActionWithForm from "./ActionWithForm";
    import ApproveRentalForm from "./CustomForms/ApproveRentalForm";
    import Modal from "./Modal";
    import RentalFeesModal from "./RentalFeesModal";
    import InvoicesInCell from "./Tables/InvoicesInCell"
    import ReconcileSlideOver from "./ReconcileSlideOver";
    import ReconcileOrderForm from "./ReconcileOrderForm";
    import DeleteInvoiceInConfirmation from "./DeleteInvoiceInConfirmation";
    import ActionWithConfirmation from "./ActionWithConfirmation";
    export default {
        components: {
            ReconcileSlideOver,
            Modal,
            SecondaryButton,
            PrimaryButton,
            Pagination,
            GenericTableFilter,
            ActionWithForm,
            ApproveRentalForm,
            RentalFeesModal,
            InvoicesInCell,
            ReconcileOrderForm,
            DeleteInvoiceInConfirmation,
            ActionWithConfirmation
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            manufacturerEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('invoice-in'));
            },
            headingValues() {
                return ['Reference', 'Item', 'Parent Item', 'Invoice PDF', 'Net Value', 'VAT Value', 'Gross Value', 'Uploaded To Sage', 'Linked Sales Invoice Status', 'Action'];
            }
        },
        methods: {
            getPropertiesToDisplay(manufacturerEntity) {
                return {
                    'reference' : manufacturerEntity.entity.properties.get('reference'),
                    'item' : manufacturerEntity.entity.properties.get('entity_type'),
                    'parent_link' : this.getParentLink(manufacturerEntity.entity.properties),
                    'invoice_pdf' : manufacturerEntity.entity.properties.get('file'),
                    'net_value' : manufacturerEntity.entity.properties.get('net_value'),
                    'vat_value' : manufacturerEntity.entity.properties.get('vat_value'),
                    'gross_value' : manufacturerEntity.entity.properties.get('gross_value'),
                    'uploaded' : manufacturerEntity.entity.properties.get('isUploaded'),
                    'linked_invoices' : {
                        'status' : manufacturerEntity.entity.properties.get('linked_sales_invoice_status'),
                        'reference' : manufacturerEntity.entity.properties.get('linked_sales_invoice_reference'),
                        'uuid' : manufacturerEntity.entity.properties.get('linked_sales_invoice_uuid'),
                    }
                };
            },
            getParentLink(properties) {
                if(properties.get("entity_type") === "rental_item_invoice_in"){
                    return `<a href="/rental-items/${properties.get("parent_uuid")}">View parent rental item</a>`;
                }
                return `<a href="/orders/${properties.get("parent_uuid")}">View parent order</a>`;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
