<template>
    <main-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <div class="md:flex justify-between w-full">
                            <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                <span class="text-secondary-darkblue">
                                    On Site Id
                                </span>
                            </div>
                            <div class="flex items-center text-secondary-darkgrey">
                                <div v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter'  && !action.fields.isEmpty())"
                                     class="mr-1">
                                    <form-action :action="actionWithFields[1]"
                                                 :actionKey="actionWithFields[0]"
                                                 :redirectOnSuccess="true"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                                    ></form-action>
                                </div>
                            </div>
                        </div>

                    </page-header>
                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-2">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                    My On Site Id Details
                                                    <svg v-if="setOnSiteId(response) && setOnSiteId(response).actions.filter(action => action.name==='update-on-site-id').count() >0" @click="displayOnSiteIdDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </dt>
                                            </div>
                                            <div v-if="onSiteIdResource.properties.get('name')">
                                                <dt class="text-sm font-nromal text-gray-500">Name</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ onSiteIdResource.properties.get('name') }}
                                                </dd>

                                            </div>
                                            <div v-if="onSiteIdResource.properties.get('date_of_birth')">
                                                <dt class="text-sm font-nomal text-gray-500">Date Of Birth</dt>
                                                <dd class="text-sm font-bold text-black">{{onSiteIdResource.properties.get('date_of_birth')}}</dd>
                                            </div>
                                            <div v-if="onSiteIdResource.properties.get('medical_conditions')">
                                                <dt class="text-sm font-nomal text-gray-500">Medical Conditions</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{onSiteIdResource.properties.get('medical_conditions')}}
                                                </dd>
                                            </div>
                                            <div v-if="onSiteIdResource.properties.get('blood_type')">
                                                <dt class="text-sm font-nomal text-gray-500">Blood Type</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{onSiteIdResource.properties.get('blood_type')}}
                                                </dd>
                                            </div>
                                            <div v-if="onSiteIdResource.properties.get('allergies')">
                                                <dt class="text-sm font-nomal text-gray-500">Allergies</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{onSiteIdResource.properties.get('allergies')}}
                                                </dd>
                                            </div>
                                            <div v-if="onSiteIdResource.properties.get('need_to_know_information')">
                                                <dt class="text-sm font-nomal text-gray-500">Need to know information</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{onSiteIdResource.properties.get('need_to_know_information')}}
                                                </dd>
                                            </div>
                                            <div v-if="getCscsCard(response)">
                                                <dt class="text-sm font-nomal text-gray-500">CSCS Card</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <a class="text-sm text-gray-700 underline" href="#" @click.prevent="showCsCs = true">Download ({{getCscsCard(response).entity.properties.get('image')}})</a><br>
                                                    <template v-if="showCsCs">
                                                        <download-pdf :url="getUrl(getCscsCard(response).entity.properties.get('image'))" @removeFromDisplay="showCsCs = false"></download-pdf>
                                                    </template   >


                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <section class="lg:col-start-3 lg:col-span-1 space-y-4">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <on-site-id-actions  :response="response"
                                                            :remainingActions="onSiteIdResource.actions.filter(action => action.name==='update-on-site-id')"></on-site-id-actions>
                            </div>
                        </section>

                    </div>
                    <update-on-site-id-form v-if="onSiteIdResource.actions.filter(action => action.name==='update-on-site-id')" :displayModal="displayOnSiteIdDetails" :action="onSiteIdResource.actions.filter(action => action.name==='update-on-site-id').first()" @removeFromDisplay="displayOnSiteIdDetails=false" :onSuccessPath="$route.path"></update-on-site-id-form>


                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import FormAction from "@/v2/components/FormAction";
import DownloadBatchOnSiteIdForm from "@/v2/components/forms/DownloadBatchOnSiteIdForm.vue";
import UpdateOnSiteIdForm from "@/v2/components/forms/UpdateOnSiteIdForm.vue";
import QualityAssessmentActions from "@/v2/components/QualityAssessmentActions.vue";
import OnSiteIdActions from "@/v2/components/OnSiteIdActions.vue";
import DownloadPdf from "@/pages/DownloadPdf.vue";
export default {
    name: "MyOnSiteId",
    data() {
        return {
            loading: false,
            pdfLoading: false,
            showDownloadForm: false,
            displayOnSiteIdDetails: false,
            onSiteIdResource: null,
            showCsCs: false
        }
    },
    components: {
        OnSiteIdActions,
        QualityAssessmentActions,
        UpdateOnSiteIdForm,
        DownloadBatchOnSiteIdForm,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        FormAction,
        DownloadPdf
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToClient(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        setOnSiteId(response){
            this.onSiteIdResource = response.body.entities.filter(entity => entity.rels.contains('on-site-id')).first().entity;
            return this.onSiteIdResource;
        },
        getCscsCard(response)
        {
            return response.body.entities.filter(entity => entity.rels.includes('certificate') && entity.entity.properties.get('name') === 'CSCS' && entity.entity.properties.get('front_or_back') === 'front' ).first();
        },
        getUrl(url){
            if(!url.includes('/')){
                return process.env.VUE_APP_API_URL +'/api/files/attachments/' + url;
            }
            if(url.includes('public')){
                return process.env.VUE_APP_API_URL + '/' + url.replace('public', 'storage');
            }
            return process.env.VUE_APP_API_URL +'/api/files/' + url;
        },
    }
}
</script>

<style scoped>

</style>
