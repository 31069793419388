<template>
    <div>
        <invoice-export-form @success="showReimportForm = true" v-if="!showReimportForm && action" :action="action"></invoice-export-form>
        <reimport-form v-if="showReimportForm && reimportaction" :action="reimportaction"></reimport-form>
        <div v-if="!showReimportForm" class="text-center pt-4 text-xs hover:underline text-gray-400 cursor-pointer" @click="showReimportForm = true">Already done this? - Skip to reimport</div>
    </div>
</template>

<script>
    import InvoiceExportForm from "@/v2/components/forms/InvoiceExportForm";
    import ReimportForm from "@/v2/components/forms/ReimportForm";
    export default {
        data() {
            return {
                showReimportForm: false,
                action: null,
                reimportaction: null
            }
        },
        props:{
            response:{
                type: Object
            }
        },
        components: {
            InvoiceExportForm,
            ReimportForm
        },
        computed: {
        },
        mounted () {
            this.action = this.response.body.actions.filter(action => action.name === 'export-invoices').get('export-invoices');
            this.reimportaction = this.response.body.actions.filter(action => action.name === 'reimport-with-sage-numbers').get('reimport-with-sage-numbers');
        },
        methods: {
            change(){
                this.$emit('change');
            },
            update(){

            },
        },
    }
</script>

<style>
    .reports-form label.block{display:none;}
    .reports-form .form-group label{display:block;}
</style>
