<template>
    <div>
        <div class="">
            <div class="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                <div>
                    <div>
                        <div class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <div class="bg-white">
<!--                                            <order-filters :response="response" :action="filterAction"></order-filters>-->
                                        </div>

                                        <table class="min-w-full">
                                            <thead class="bg-secondary-lightbacka">
                                                <tr>
                                                    <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Date</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Time Actual In</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Time Actual Out</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Time Billable In</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Time Billable Out</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Time Billable Total (h)</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Location In</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Location Out</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Photo In</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Photo Out</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Comment</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Score</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="this.response.body.properties.get('total') === 0">
                                                <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                                                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                                        No daily reports found.
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else class="divide-y divide-trueGray-200 bg-white">
                                            <template v-for="(companyEntity, key) in companyEntities">
                                                <tr class="hover:bg-secondary-lightbacka cursor-pointer">
                                                    <template v-for="(field, key, index) in getPropertiesToDisplay(companyEntity)">
                                                        <td class="whitespace-nowrap py-4 px-4 text-sm text-secondary-darkgrey font-bold" :class="{'bg-error': key === 'score' && (field === 'no_project_out' || field === 'no_project_in' ||  field === 'none_of_the_above_project_manually_entered_at_clock_in'), 'bg-yellow-500': key === 'score' && (field === 'no_clock_out' || field === 'late_clock_out' ||  field === 'project_manually_entered_at_clock_in'), 'bg-green-400': key === 'score' && field === 'ok', 'sm:pl-6': index ===0}">
                                                            <template v-if="key === 'location_in' || key === 'location_out'">
                                                                <location-viewer-with-icon v-if="field !== 'N/A' && field['x'] !== null && field['y'] !==null" :x="field['x']" :y="field['y']"></location-viewer-with-icon>
                                                                <template>
                                                                    <template v-if="field['project']">
                                                                        <form-action v-if="key === 'location_in' && companyEntity.entity.actions.get('update-project-0')"
                                                                                          :action="companyEntity.entity.actions.get('update-project-0')"
                                                                                          actionKey="update-project-0"
                                                                                          :onSuccessPath="$route.fullPath"
                                                                                          :title="field['project']"
                                                                                          :isButton="false">
                                                                        </form-action>
                                                                        <form-action v-else-if="key === 'location_out' && companyEntity.entity.actions.get('update-project-1')"
                                                                                          :action="companyEntity.entity.actions.get('update-project-1')"
                                                                                          actionKey="update-project-1"
                                                                                          :onSuccessPath="$route.fullPath"
                                                                                          :title="field['project']"
                                                                                          :isButton="false">
                                                                        </form-action>
                                                                      <template v-else>
                                                                        <div>
                                                                          {{field['project']}}
                                                                        </div>
                                                                      </template>
                                                                    </template>
                                                                    <template v-else>
                                                                        <form-action v-if="key === 'location_in' && companyEntity.entity.actions.get('update-project-0')"
                                                                                          :action="companyEntity.entity.actions.get('update-project-0')"
                                                                                          actionKey="update-project-0"
                                                                                          :onSuccessPath="$route.fullPath"
                                                                                          title="Edit"
                                                                                          :isButton="false">
                                                                        </form-action>
                                                                        <form-action v-if="key === 'location_out' && companyEntity.entity.actions.get('update-project-1')"
                                                                                     :action="companyEntity.entity.actions.get('update-project-1')"
                                                                                     actionKey="update-project-1"
                                                                                     :onSuccessPath="$route.fullPath"
                                                                                     title="Edit"
                                                                                     :isButton="false">
                                                                        </form-action>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                            <template v-else-if="(key === 'photo_in' || key === 'photo_out') && field">
                                                                <photo-viewer-with-icon v-if="field !== 'N/A'" :file-name="field"></photo-viewer-with-icon>
                                                            </template>
                                                            <template v-else-if="key === 'comment'">
                                                                <template v-if="companyEntity.entity.properties.get('comment_in')">
                                                                    clock in: {{companyEntity.entity.properties.get('comment_in')}}<br>
                                                                </template>
                                                                <template v-if="companyEntity.entity.properties.get('comment_out')">
                                                                    clock out: {{companyEntity.entity.properties.get('comment_out')}}<br>
                                                                </template>
                                                                {{field}}
                                                                <form-action v-if="companyEntity.entity.actions.get('comment')"
                                                                                  :action="companyEntity.entity.actions.get('comment')"
                                                                                  actionKey="comment"
                                                                                  :onSuccessPath="$route.fullPath">
                                                                </form-action>
                                                            </template>
                                                            <template v-else-if="key === 'time_actual_in' || key === 'time_actual_out' || key === 'time_billable_in' || key === 'time_billable_out'">
                                                                <form-action v-if="companyEntity.entity.actions.get('update-actual-in') && key === 'time_actual_in' &&
                                                                                formatToTime(field) !== ''"
                                                                             :action="companyEntity.entity.actions.get('update-actual-in')"
                                                                             actionKey="update-actual-in"
                                                                             :onSuccessPath="$route.fullPath"
                                                                            :isButton="false"
                                                                            :title="formatToTime(field)">
                                                                </form-action>
                                                                <form-action v-else-if="companyEntity.entity.actions.get('update-actual-out') && key === 'time_actual_out'"
                                                                             :action="companyEntity.entity.actions.get('update-actual-out')"
                                                                             actionKey="update-actual-out"
                                                                             :onSuccessPath="$route.fullPath"
                                                                             :isButton="false"
                                                                             :title="formatToTime(field)? formatToTime(field) : 'N/A'">
                                                                </form-action>
                                                                <template v-else>
                                                                    {{formatToTime(field)}}
                                                                </template>
                                                            </template>
                                                            <template v-else-if="key === 'score'">
                                                                <template v-if="field === 'ok'">
                                                                    <span class="text-white uppercase">OK</span>
                                                                </template>
                                                                <template v-else-if="field === 'no_project_out' || field === 'no_project_in'">
                                                                    <span class="text-white uppercase">MISSING PROJECT</span>
                                                                </template>
                                                                <template v-else-if="field === 'no_clock_out'">
                                                                    <span class="text-white uppercase">NO CLOCK OUT</span>
                                                                </template>
                                                                <template v-else-if="field === 'late_clock_out'">
                                                                    <span class="text-white uppercase">LATE CLOCK OUT</span>
                                                                </template>
                                                                <template v-else-if="field === 'project_manually_entered_at_clock_in'">
                                                                    <span class="text-white uppercase">Project Selected manually at clock in</span>
                                                                </template>
                                                              <template v-else-if="field === 'none_of_the_above_project_manually_entered_at_clock_in'">
                                                                <span class="text-white uppercase">None of the above Project Selected manually at clock in</span>
                                                              </template>
                                                                <div v-else class="text-white uppercase">{{field}}</div>
                                                            </template>
                                                            <template v-else>{{field}}</template>
                                                        </td>
                                                    </template>
                                                </tr>
                                            </template>
                                            <tr class="hover:bg-secondary-lightbacka cursor-pointer">
                                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-secondary-darkgrey text-right font-bold" :colspan="5">Weekly Total (h):</td>
                                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-secondary-darkgrey font-bold">{{getWeeklyHoursTotal()}}</td>
                                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-secondary-darkgrey font-bold" :colspan="6"></td>
                                            </tr>
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import Pagination from "@/v2/paginatedLists/Pagination";
import PhotoViewerWithIcon from "@/components/PhotoViewerWithIcon";
import LocationViewerWithIcon from "@/components/LocationViewerWithIcon";
import FormAction from "@/v2/components/FormAction";

export default {
    name: "DailyReportsPaginatedList",
    components:{
        Pagination,
        PhotoViewerWithIcon,
        LocationViewerWithIcon,
        FormAction
    },
    props: {
        response:{
            type: Object
        }
    },
    computed:{
        filterAction() {
            if(!this.response){
                return null;
            }
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
        companyEntities(){
            if(!this.response){
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('timesheet-item'));
        },
        headingValues() {
            return ['Date', 'Time Actual In', 'Time Actual Out', 'Time Billable In', ' Time Billable Out', 'Time Billable total (h)', 'Location In', 'Location Out', 'Photo In', 'Photo Out', 'Comment', 'Score'];
        }
    },
    methods:{
        formatToTime(datetime){
            if (datetime == null){
                return '';
            }
            if(!dayjs(datetime).isValid()){
                return '';
            }
            return dayjs(datetime).format('HH:mm');
        },
        getPropertiesToDisplay(reportEntity) {
            console.log(reportEntity.entity.properties);
            return {
                'date': reportEntity.entity.properties.get('date'),
                'time_actual_in': reportEntity.entity.properties.get('time_actual_in'),
                'time_actual_out': reportEntity.entity.properties.get('time_actual_out'),
                'time_billable_in': reportEntity.entity.properties.get('time_billable_in'),
                'time_billable_out': reportEntity.entity.properties.get('time_billable_out'),
                'time_billable_total_hours': reportEntity.entity.properties.get('time_billable_hours_total'),
                'location_in': reportEntity.entity.properties.get('location_in'),
                'location_out': reportEntity.entity.properties.get('location_out'),
                'photo_in': reportEntity.entity.properties.get('photo_in'),
                'photo_out': reportEntity.entity.properties.get('photo_out'),
                'comment': reportEntity.entity.properties.get('comment'),
                'score': reportEntity.entity.properties.get('score'),
            }
        },
        navigateToItem(companyEntity) {
            let selfLink = companyEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        getWeeklyHoursTotal(){
            return this.response.body.properties.get('hours_total')
        },
        getBillableTotal(){
            return this.response.body.properties.get('billable_total')
        }
    }
}
</script>

<style scoped>

</style>
