<template>
  <div>
    <div class="sm:hidden mb-4" v-if="filterAction">
      <select
        @change="navigateTo($event.target.value)"
        aria-label="Selected tab"
        class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:ring-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
      >
        <option v-for="(link, index) in savedFilterLinks" :key="index" :value="link.href" :selected="link.title.includes('active')">{{link.title.replace(' (active)', '')}}</option>

      </select>
    </div>
    <div class="hidden sm:block">
      <div>
        <nav class="flex">
          <a v-for="(link, index) in savedFilterLinks" :key="index"
            @click="navigateTo(link.href)"
            href="#"
            class="whitespace-nowrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            :class="{'text-blue-600 border-blue-500 focus:text-blue-800 focus:border-blue-700': link.title.includes('active')}"
            :data-cy="link.title.includes('active')? 'active-search' : 'search'"
          >{{link.title.replace(' (active)', '')}}</a>
          <a v-if="!hasMatchingSavedFilter() && savedFilterLinks"
            class="whitespace-nowrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 text-blue-600 border-blue-500 focus:text-blue-800 focus:border-blue-700"
          >Untitled search</a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    response: {
      type: Object
    }
  },
  methods: {
      navigateTo(link){
          window.location = this.getFrontendRouteFromApiRoute(link);
        //     this.$router.go(link.replace('/api', ''));
        },
        hasMatchingSavedFilter(){
          if(!this.savedFilterLinks){
            return false;
          }
          let links = this.savedFilterLinks.filter(link => link.title.includes('active'));
          return links.size;
      },
      changeEvent(event){
        console.log(event.target.value);
      }
  },
  computed: {
    savedFilterLinks(){
      return  this.response.body.links.filter(link => link.rels.toString().includes('saved_filter'));
    },
    filterAction() {
        if(!this.response){
            return null;
        }
        return this.response.body.actions.filter(action => action.name === 'filter').first();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
