<template>
    <div id="projects" class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Name
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Address
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Note
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white" v-if="projects.size > 0">
                <template v-for="project in projects">
                    <tr class="border-b border-gray-200 last:border-b-0" :class="{'bg-gray-200 border-gray-300': project.entity.properties.get('deactivated_at') !== null}">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ project.entity.properties.get('name') }}
                        </td>
                        <td class="px-3 py-4 text-sm leading-5 text-gray-500">
                            {{ project.entity.properties.get('address') }}
                        </td>
                        <td class="px-3 py-4 text-sm leading-5 text-gray-500">
                            {{project.entity.properties.get('note')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <div class="flex">
                                <template v-for="actionWithFields in project.entity.actions.filter(action => !action.fields.isEmpty() && action.name !== 'update-project')">
                                    <form-action :action="actionWithFields[1]"
                                                      :actionKey="actionWithFields[0]"
                                                      :onSuccessPath="$route.path"
                                                      additionalButtonClasses="mr-3"
                                    ></form-action>
                                </template>
                                <template v-for="actionWithFields in project.entity.actions.filter(action => !action.fields.isEmpty() && action.name === 'update-project')">
                                    <project-form  :action="actionWithFields[1]"
                                                   :actionKey="actionWithFields[0]"
                                                   :onSuccessPath="$route.path"
                                                      additionalButtonClasses="mr-3"
                                                   ></project-form>
                                </template>
                                <template v-for="actionWithFields in project.entity.actions.filter(action => action.fields.isEmpty() && action.name !== 'update-project')">
                                    <confirmation-action :action="actionWithFields[1]"
                                                              :actionKey="actionWithFields[0]"
                                                              :onSuccessPath="$route.path"
                                                            :buttonType="actionWithFields[0].includes('delete')|| actionWithFields[0].includes('deactivate') ?'negative' :'primary'"
                                                            additionalButtonClasses="mr-3"
                                    ></confirmation-action>
                                </template>
                            </div>
                        </td>
                    </tr>
                </template>
                </tbody>
                <tbody v-else>
                <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                        No projects found.
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionWithForm from "../ActionWithForm";
    import ActionWithConfirmation from "../ActionWithConfirmation";
    import ProjectForm from "../CustomForms/ProjectForm";
    import ConfirmationAction from "@/v2/components/ConfirmationAction";
    export default {
        components: {
            ConfirmationAction,
            ActionWithForm,
            ActionWithConfirmation,
            ProjectForm
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            projects() {
                let projects = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("project");
                    });
                if(!projects){
                    return null;
                }

                return projects;
            },
            createProjectAction() {
                return this.response.body.actions.filter(action => action.name === 'add-new-project').first()
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            },
            success(key) {
                this.$router.go(0);
            }
        },
        mounted(){
            if(this.$route.hash == '#projects') {
                document.querySelector(this.$route.hash).scrollIntoView();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
