import Redirector from "./Redirector";
import Orders from './pages/Orders';
import Order from './pages/Order';
import OrderCreate from './pages/OrderCreate';
import Products from './pages/Products';
import ReturnRequests from './pages/ReturnRequests';
import ReturnRequest from './pages/ReturnRequest';
import DownloadPdf from './pages/DownloadPdf';
import Clients from './pages/Clients';
import Invoices from './pages/Invoices';
import Invoice from './pages/Invoice';
import SignUp from "./pages/AppSignUp";
import InvoiceReports from "./pages/InvoiceReports";
import InviteClient from "./pages/InviteClient";
import Supplier from './pages/Supplier.vue';
import Suppliers from './pages/Suppliers.vue';
import Companies from './pages/Companies.vue';
import RFIs from './pages/RFIs.vue';
import QualityAssessments from "./pages/QualityAssessments";
import Login from "./pages/Login";
import Client from "./pages/IndividualItem/Client";
import Company from "./pages/IndividualItem/Company";
import Projects from "./pages/Collections/Projects";
import Project from "./pages/IndividualItem/Project";
import Contracts from "./pages/Collections/Contracts";
import Contract from "./pages/IndividualItem/Contract";
import ProductTypes from "./pages/Collections/ProductTypes";
import ProductType from "./pages/IndividualItem/ProductType";
import Users from "./pages/Collections/Users";
import User from "./pages/IndividualItem/User";
import Reports from "./pages/Reports";
import TakeOffSearch from "./pages/TakeOffSearch";
import RFISearch from "./pages/RFISearch";
import Dashboard from "./pages/Dashboard";
import SignUpSuccess from "./pages/SignUpSuccess";
import Dashboards from "./pages/Collections/Dashboards";
import EditDashboard from "./pages/IndividualItem/EditDashboard";
import RFI from "./pages/RFI";
import RentalItems from "./pages/Collections/RentalItems";
import Manufacturers from "./pages/Manufacturers";
import Sages from "./pages/Sages";
import Sage from "./pages/IndividualItem/Sage";
import InvoicesIn from "./pages/InvoicesIn";
import SageDescriptions from "./pages/SageDescriptions";
import SageDescription from "./pages/IndividualItem/SageDescription";
import SageNominalCodes from "./pages/SageNominalCodes";
import SageNominalCode from "./pages/IndividualItem/SageNominalCode";
import Notifications from "./pages/Notifications";
import CreditNotes from "./pages/CreditNotes";
import HubViewer from "./pages/HubViewer";
import DashboardWidgets from "./pages/Collections/DashboardWidgets";
import DashboardWidgetEdit from "./pages/DashboardWidgetEdit";
import RentalItem from "./pages/RentalItem";
import SuppliersBreakdown from "./pages/SuppliersBreakdown";
import TermsAndConditions from "./pages/TermsAndConditions";
import TermsAndConditionsNotAccepted from "./pages/TermsAndConditionsNotAccepted";
import QualityAssessmentCreate from "./pages/QualityAssessmentCreate";
import TemplateCreate from "./pages/TemplateCreate";
import QualityAssessment from "./pages/QualityAssessment";
import Templates from "./pages/Templates";
import Template from "./pages/Template";
import DirectSupplier from './pages/IndividualItem/DirectSupplier';
import DirectSuppliers from './pages/DirectSuppliers';
import PaymentVouchers from "./pages/PaymentVouchers";
import PaymentVoucher from "./pages/PaymentVoucher";
import Certificates from "@/pages/Collections/Certificates";
import Costing from "@/pages/Costing";
import UnapprovedInvoicesIn from "@/pages/UnapprovedInvoicesIn";
import ManufacturerReports from "@/pages/ManufacturerReports";
import QaTrackers from "@/pages/QaTrackers";
import QaTracker from "@/pages/QaTracker";
import Induction from "./pages/Induction";
import InviteUser from "@/pages/InviteUser";
import ScheduleManager from "@/pages/ScheduleManager";
import HolidayRequests from "@/pages/HolidayRequests";
import Vehicles from "@/pages/Vehicles";
import OfficeItems from "@/pages/OfficeItems";
import Materials from "@/pages/Materials";
import Vehicle from "@/pages/IndividualItem/Vehicle";
import Material from "@/pages/IndividualItem/Material";
import OfficeItem from "@/pages/IndividualItem/OfficeItem";
import SupplierRatings from "@/pages/SupplierRatings";
import SupplierRating from "@/pages/IndividualItem/SupplierRating";
import DailyReports from "@/pages/DailyReports";
import WeeklyReports from "@/pages/WeeklyReports";
import TimesheetBuilder from "@/pages/TimesheetBuilder";
import DefaultClientUsers from "@/pages/Collections/DefaultClientUsers";
import OrdersV2 from "@/v2/pages/Orders";
import OrderV2 from  "@/v2/pages/Order";
import DashboardV2 from "@/v2/pages/Dashboard";
import DailyReportV2 from "@/v2/pages/DailyReports";
import WeeklyReportsV2 from "@/v2/pages/WeeklyReports";
import TimesheetBuilderV2 from "@/v2/pages/TimesheetBuilder";
import ScheduleManagerV2 from "@/v2/pages/ScheduleManager";
import ReturnRequestsV2 from "@/v2/pages/ReturnRequests";
import RentalItemsV2 from "@/v2/pages/RentalItems";
import CreateOrder from "@/v2/pages/CreateOrder";
import RFIsV2 from "@/v2/pages/RFIs";
import AddressBook from "@/v2/pages/AddressBook";
import HolidayRequestsV2 from "@/v2/pages/HolidayRequests";
import SalesInvoices from "@/v2/pages/SalesInvoices";
import PurchaseInvoices from "@/v2/pages/PurchaseInvoices";
import CreditNotesV2 from "@/v2/pages/CreditNotes";
import CostingV2 from "@/v2/pages/Costing";
import SageDescriptionsV2 from "@/v2/pages/SageDescriptions";
import SageTaxCodes from "@/v2/pages/SageTaxCodes";
import SageNominalCodesV2 from "@/v2/pages/SageNominalCodes";
import ContactV2 from "@/v2/pages/Contact";
import RfiV2 from "@/v2/pages/Rfi";
import SageTaxCode from "@/v2/pages/SageTaxCode";
import SageDescriptionV2 from "@/v2/pages/SageDescription";
import RentalItemV2 from "@/v2/pages/RentalItem";
import ReturnRequestV2 from "@/v2/pages/ReturnRequest";
import QualityAssessmentsV2 from "@/v2/pages/QualityAssessments";
import SuppliersV2 from "@/v2/pages/Suppliers";
import SupplierV2 from "@/v2/pages/Supplier";
import SupplierRatingsV2 from "@/v2/pages/SupplierRatings";
import ManufacturersV2 from "@/v2/pages/Manufacturers";
import QaCategoriesV2 from "@/v2/pages/QaCategories";
import PaymentVouchersV2 from "@/v2/pages/PaymentVouchers";
import MaterialsV2 from "@/v2/pages/Materials";
import OfficeItemsV2 from "@/v2/pages/OfficeItems";
import VehiclesV2 from "@/v2/pages/Vehicles";
import QaTrackersV2 from "@/v2/pages/QaTrackers";
import OfficeItemV2 from "@/v2/pages/OfficeItem";
import VehicleV2 from "@/v2/pages/Vehicle";
import MaterialV2 from "@/v2/pages/Material";
import TakeOffsSearchV2 from "@/v2/pages/TakeOffsSearch";
import ExportOrdersV2 from "@/v2/pages/ExportOrders";
import ExportInvoicesV2 from "@/v2/pages/ExportInvoices";
import OfficeTools from "@/v2/pages/OfficeTools";
import ManagementTools from "@/v2/pages/ManagementTools";
import QATools from "@/v2/pages/QATools";
import InventoryTools from "@/v2/pages/InventoryTools";
import ClientV2 from "@/v2/pages/Client"
import AdminTools from "@/v2/pages/AdminTools";
import CreateQaV2 from "@/v2/pages/CreateQa";
import QaTrackerV2 from "@/v2/pages/QaTracker";
import QualityAssessmentV2 from "@/v2/pages/QualityAssessment";
import CreateQaCategoryV2 from "@/v2/pages/CreateQaCategory";
import VehicleLogs from "@/v2/pages/VehicleLogs";
import VehicleLog from "@/v2/pages/VehicleLog";
import Evac from "@/v2/pages/Evac";
import SiteVehicleLogRequirements from "@/v2/pages/SiteVehicleLogRequirements";
import ClientSettingsTools from "@/v2/pages/ClientSettingsTools";
import ClientOnSiteSelfie from "@/v2/pages/ClientOnSiteSelfie";
import ClientReoccurringInvoiceItems from "@/v2/pages/ClientReoccurringInvoiceItems";
import ClientContracts from "@/v2/pages/ClientContracts";
import ClientProjects from "@/v2/pages/ClientProjects";
import ClientUsers from "@/v2/pages/ClientUsers";
import ClientAccount from "@/v2/pages/ClientAccount";
import ClientAddressBook from "@/v2/pages/ClientAddressBook";
import ClientProcurementTools from "@/v2/pages/ClientProcurementTools";
import ClientBookkeepingTools from "@/v2/pages/ClientBookkeepingTools";
import ExportClientOrders from "@/v2/pages/ExportClientOrders";
import ClientQualityAssessments from "@/v2/pages/ClientQualityAssessments";
import CMSNewsViewer from "@/pages/CMSNewsViewer";
import CmsNews from "@/v2/pages/CmsNews";
import ExportClientInvoices from "@/v2/pages/ExportClientInvoices";
import ClientUserGroups from "@/v2/pages/ClientUserGroups";
import SupplierGroups from "@/v2/pages/SupplierGroups";
import ClientDayPayRequests from "@/v2/pages/ClientDayPayRequests";
import ClientDepartments from "@/v2/pages/ClientDepartments.vue";
import DayPayRequests from "@/v2/pages/DayPayRequests.vue";
import DayPayRequest from "@/v2/pages/DayPayRequest.vue";
import Subscriptions from "@/v2/pages/Subscriptions.vue";
import Subscription from "@/v2/pages/Subscription.vue";
import ClientPreChecks from "@/v2/pages/ClientPreChecks.vue";
import CreatePreCheck from "@/v2/pages/CreatePreCheck.vue";
import PreChecks from "@/v2/pages/PreChecks.vue";
import PreCheck from "@/v2/pages/PreCheck.vue";
import ProductLibrary from "@/v2/pages/ProductLibrary.vue";
import ProductSingle from "@/v2/pages/ProductSingle.vue";
import ProductCategories from "@/v2/pages/ProductCategories.vue";
import Category from "@/v2/pages/Category.vue";
import OnSiteIdBatch from "@/v2/pages/OnSiteIdBatch.vue";
import OnSiteIdSingle from "@/v2/pages/OnSiteIdSingle.vue";
import MyOnSiteId from "@/v2/pages/MyOnSiteId.vue";
import Register from "@/pages/Register.vue";
import ClientPendingUsers from "@/v2/pages/ClientPendingUsers.vue";
import QuoteGenerator from "@/v2/pages/QuoteGenerator.vue";
import OnSiteIdLogin from "@/pages/OnSiteIdLogin.vue";
import OnSiteIdRegister from "@/pages/OnSiteIdRegister.vue";
import ForgotPassword from "@/pages/ForgotPassword.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import OnSiteIdForgotPassword from "@/pages/OnSiteIdForgotPassword.vue";
import CallOuts from "@/v2/pages/CallOuts.vue";
import CallOutSingle from "@/v2/pages/CallOutSingle.vue";
import ProductTypeSingle from "@/v2/pages/ProductTypeSingle.vue";
import ProductsWithoutType from "@/v2/pages/ProductsWithoutType.vue";
import OnSiteIdDashboard from "@/v2/pages/OnSiteIdDashboard.vue";
import MobileUser from "@/v2/pages/MobileUser.vue";
import MobileEmployerSettings from "@/v2/pages/MobileEmployerSettings.vue";
import Traccar from "@/v2/pages/Traccar.vue";
import PayNSend from "@/v2/pages/PayNSend.vue";
import PendingPayNSend from "@/v2/pages/PendingPayNSend.vue";

const routes = [
    {
        path: '/notifications',
        components: {
            default: Notifications
        },
        meta: {
            title: 'Notifications'
        }
    },
    {
        path: '/orders/create',
        components: {
            default: CreateOrder
        },
        meta: {
            title: 'Create An Order'
        }
    },
    {
        path: '/orders/:id',
        name: 'singleOrder',
        component: OrderV2,
        meta: {
            title: 'Order'
        }
    },
    {
        path: '/orders',
        component: OrdersV2,
        //component: Orders,
        meta: {
            title: 'Orders'
        }
    },
    {
        path: '/schedule-manager',
        name:'ScheduleManager',
        component: ScheduleManagerV2,
        meta: {
            title: 'Schedule Manager'
        }
    },
    {
        path: '/office',
        component: OfficeTools,
        meta: {
            title: 'Office Tools'
        }
    },
    {
        path: '/manage',
        component: ManagementTools,
        meta: {
            title: 'Management Tools'
        }
    },
    {
        path: '/qa-tools',
        component: QATools,
        meta: {
            title: 'QA Tools'
        }
    },
    {
        path: '/inventory',
        component: InventoryTools,
        meta: {
            title: 'Inventory Tools'
        }
    },
    {
        path: '/admin',
        component: AdminTools,
        meta: {
            title: 'Admin'
        }
    },
    {
        path: '/sages',
        component: SageTaxCodes,
        meta: {
            title: 'Sages'
        }
    },
    {
        path: '/sages/:id',
        component: SageTaxCode,
        meta: {
            title: 'Sages'
        }
    },
    {
        path: '/sages_description',
        component: SageDescriptionsV2,
        meta: {
            title: 'Sage Descriptions'
        }
    },
    {
        path: '/sages_description/:id',
        component: SageDescriptionV2,
        meta: {
            title: 'Sage Description'
        }
    },
    {
        path: '/sages_nominal_code',
        component: SageNominalCodesV2,
        meta: {
            title: 'Sage Nominal Codes'
        }
    },
    {
        path: '/sages_nominal_code/:id',
        component: SageNominalCode,
        meta: {
            title: 'Sage Nominal Code'
        }
    },
    {
        path: '/invoices',
        component: SalesInvoices,
        meta: {
            title: 'Invoices'
        }
    },
    {
        path: '/credit-notes',
        component: CreditNotesV2,
        meta: {
            title: 'Credit Notes'
        }
    },
    {
        path: '/invoices/:id',
        component: Invoice,
        meta: {
            title: 'Invoice'
        }
    },
    {
        path: '/suppliers/:id',
        component: ContactV2,
        meta: {
            title: 'Address Book'
        }
    },
    {
        path: '/suppliers',
        component: AddressBook,
        meta: {
            title: 'Contact'
        }
    },
    {
        path: '/client-address-book',
        component: ClientAddressBook,
        meta: {
            title: 'Client Address Book'
        }
    },
    {
        path: '/clients/:id/manufacturers',
        component: ManufacturersV2,
        meta: {
            title: 'Manufacturers'
        }
    },
    {
        path: '/invoices-in',
        component: PurchaseInvoices,
        meta: {
            title: 'Purchase Invoices'
        }
    },
    {
        path: '/unapproved-invoices-in',
        component: UnapprovedInvoicesIn,
        meta: {
            title: 'Unapproved Purchase Invoices'
        }
    },
    {
        path: '/clients/:id/companies',
        component: SuppliersV2,
        meta: {
            title: 'Suppliers'
        }
    },
    {
        path: '/companies/:id',
        component: SupplierV2,
        meta: {
            title: 'Supplier'
        }
    },
    {
        path: '/supplier-ratings',
        component: SupplierRatingsV2,
        meta: {
            title: 'Supplier Ratings'
        }
    },
    {
        path: '/companies/:id/ratings',
        component: SupplierRating,
        meta: {
            title: 'Supplier Ratings'
        }
    },
    {
        path:'/supplier-breakdown',
        component: SuppliersBreakdown,
        meta: {
            title: 'Supplier Breakdown'
        }
    },
    {
        path: '/projects',
        component: Projects,
        meta: {
            title: 'Projects'
        }
    },
    {
        path: '/projects/:id',
        component: Project,
        meta: {
            title: 'Project'
        }
    },
    {
        path: '/sites',
        component: Contracts,
        meta: {
            title: 'Contracts'
        }
    },
    {
        path: '/sites/:id',
        component: Contract,
        meta: {
            title: 'Contracts'
        }
    },
    {
        path: '/clients',
        component: Clients,
        meta: {
            title: 'Clients'
        }
    },
    {
        path: '/clients/:id',
        component: ClientV2,
        meta: {
            title: 'Clients'
        }
    },
    {
         path: '/direct-suppliers/:id',
        component: DirectSupplier,
        meta:{
            title: 'Direct Supplier'
         }
    },
    {
        path: '/direct-suppliers',
        component: DirectSuppliers,
        meta:{
            title: 'Direct Suppliers'
        }
    },
    {
        path: '/products',
        component: Products,
        meta: {
            title: 'Products'
        }
    },
    {
        path: '/product-types',
        component: ProductTypes,
        meta: {
            title: 'Products Types'
        }
    },
    {
        path: '/product-types/:id',
        component: ProductType,
        meta: {
            title: 'Products Type'
        }
    },
    {
        path: '/cms-users',
        name:'CmsUsers',
        component: Users,
        meta: {
            title: 'CMS Users'
        }
    },
    {
        path: '/users',
        name:'Users',
        component: Users,
        meta: {
            title: 'Users'
        }
    },
    {
        path: '/users/:id',
        component: User,
        meta: {
            title: 'User'
        }
    },
    {
        path: '/default-client-users',
        name:'DefaultClientUsers',
        component: DefaultClientUsers,
        meta: {
            title: 'Users'
        }
    },
    {
        path: '/return-requests',
        component: ReturnRequestsV2,
        meta: {
            title: 'return-requests'
        }
    },
    {
        path: '/return-requests/:id',
        component: ReturnRequestV2,
        meta: {
            title: 'return-request'
        }
    },
    {
        path: '/dashboards/:id',
        component: EditDashboard,
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/take-off-orders',
        name:'TakeOffs',
        component: OrdersV2,
        meta: {
            title: 'Take Off Orders',
        },
    },
    {
        path: '/take-off-orders/search',
        components: {
            default: TakeOffsSearchV2
        },
        meta: {
            title: 'Search Take Offs'
        }
    },
    {
        path: '/quotes-mismatch',
        name:'MismatchQuotes',
        component: OrdersV2,
        meta: {
            title: 'Mismatch Quotes'
        }
    },
    {
        path: '/tender-orders',
        name:'TenderOrders',
        component: OrdersV2,
        meta: {
            title: 'Tender Orders'
        }
    },
    {
        path: '/rfis',
        components: {
            default: RFIsV2,
        },
        meta: {
            title: 'RFIs'
        }
    },

    {
        path: '/quality-assessments/create',
        components: {
            default: CreateQaV2,
        },
        meta: {
            title: 'Create a QA'
        }
    },
    {
        path: '/quality-assessments',
        components: {
            default: QualityAssessmentsV2,
        },
        meta: {
            title: 'QAs'
        }
    },
    {
        path: '/quality-assessments/:id',
        name: 'quality-assessment',
        components: {
            default: QualityAssessmentV2,
        },
        meta: {
            title: 'QAs'
        }
    },
    {
        path: '/qa-categories/create',
        components: {
            default: CreateQaCategoryV2
        },
        meta: {
            title: 'Create Qa Category'
        }
    },
    {
        path: '/qa-categories',
        components: {
            default: QaCategoriesV2
        },
        meta: {
            title: 'Qa Categories'
        }
    },
    {
        path: '/qa-categories/:id',
        components:{
            default: Template
        },
        meta:{
            title: 'Qa Category'
        }
    },
    {
        path: '/pre-checks/create',
        components: {
            default: CreatePreCheck,
        },
        meta: {
            title: 'Create a pre-check'
        }
    },
    {
        path: '/pre-checks',
        components: {
            default: PreChecks,
        },
        meta: {
            title: 'Pre-Checks'
        }
    },
    {
        path: '/pre-checks/:id',
        name: 'pre-check',
        components: {
            default: PreCheck,
        },
        meta: {
            title: 'Pre-Checks'
        }
    },
    {
        path: '/users/:id/certificates',
        components: {
            default: Certificates
        },
        meta: {
            title: 'Certificates'
        }
    },
    {
        path: '/qa-trackers',
        components: {
            default: QaTrackersV2,
        },
        meta: {
            title: 'QA Trackers'
        }
    },{
        path: '/qa-trackers/:id',
        components: {
            default: QaTrackerV2,
        },
        meta: {
            title: 'QA Tracker'
        }
    },
    {
        path: '/vehicles',
        components: {
            default: VehiclesV2,
        },
        meta: {
            title: 'Vehicles'
        }
    },
    {
        path: '/vehicles/:id',
        components: {
            default: VehicleV2,
        },
        meta: {
            title: 'Vehicle'
        }
    },
    {
        path: '/office-items',
        components: {
            default: OfficeItemsV2,
        },
        meta: {
            title: 'Office Items'
        }
    },
    {
        path: '/office-items/:id',
        components: {
            default: OfficeItemV2,
        },
        meta: {
            title: 'Office Item'
        }
    },
    {
        path: '/materials',
        components: {
            default: MaterialsV2,
        },
        meta: {
            title: 'Materials'
        }
    },
    {
        path: '/materials/:id',
        components: {
            default: MaterialV2,
        },
        meta: {
            title: 'Material'
        }
    },
    {
        path: '/rental-items',
        components: {
            default: RentalItemsV2,
        },
        meta: {
            title: 'Rental Items'
        }
    },
    {
        path: '/payment-vouchers',
        components: {
            default: PaymentVouchersV2,
        },
        meta: {
            title: 'Payment Vouchers'
        }
    },
    {
        path: '/payment-vouchers/:id',
        components: {
            default: PaymentVoucher,
        },
        meta: {
            title: 'Payment Voucher'
        }
    },
    {
        path: '/slip-send',
        components: {
            default: PayNSend,
        },
        meta: {
            title: 'Slip Send'
        }
    },
    {
        path: '/slip-send/pending/:id',
        components: {
            default: PendingPayNSend,
        },
        meta: {
            title: 'Slip Send - Pending'
        }
    },
    {
        path: '/rental-items/:id',
        components: {
            default: RentalItemV2,
        },
        meta: {
            title: 'Rental Item'
        }
    },
    {
        path: '/request-for-information/:id',
        components: {
            default: RfiV2
        },
        meta: {
            title: 'RFI'
        }
    },
    {
        path: '/admin/cms-urls',
        components: {
            default: CmsNews
        },
        meta: {
            title: 'CMS URLs'
        }
    },
    {
        path: '/rfis/search',
        components: {
            default: RFISearch
        },
        meta: {
            title: 'Search RFIs'
        }
    },
    {
        path: '/reports',
        components: {
            default: ExportOrdersV2
        },
        meta: {
            title: 'Reports'
        }
    },
    {
        path: '/invoice-reports',
        components: {
            default: ExportInvoicesV2
        },
        meta: {
            title: 'Invoice Export'
        }
    },
    {
        path: '/manufacturer-reports',
        components: {
            default: ManufacturerReports
        },
        meta: {
            title: 'Manufacturer Reports'
        }
    },
    {
        path: '/holiday-requests',
        component: HolidayRequestsV2,
        meta: {
            title: 'Holiday Requests'
        }
    },
    {
        path: '/dashboard-widgets-create',
        components: {
            default: DashboardWidgetEdit
        },
        meta: {
            title: 'Create Default Widget'
        }
    },
    {
        path: '/dashboard-widgets/:id',
        component: DashboardWidgetEdit,
        meta: {
            title: 'DefaultWidget'
        }
    },

    {
        path: '/dashboard-widgets',
        components: {
            default: DashboardWidgets
        },
        meta: {
            title: 'Widgets'
        }
    },
    {
        path: '/on-site-id',
        components: {
            default: MyOnSiteId
        },
        meta: {
            title: 'On Site Id'
        }
    },
    {
        path: '/on-site-id-batch',
        components: {
            default: OnSiteIdBatch
        },
        meta: {
            title: 'Download On Site Id'
        }
    },
    {
        path: '/on-site-id/:id',
        name: 'ViewOnSiteId',
        components: {
            default: OnSiteIdSingle
        },
        meta: {
            title: 'On Site Id'
        }
    },
    {
        path: '/mobile/dashboard',
        name: 'OnSiteIdDashboard',
        components: {
            default: OnSiteIdDashboard
        },
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/mobile/users/:id',
        name: 'MobileUser',
        components: {
            default: MobileUser
        },
        meta: {
            title: 'User'
        }
    },
    {
        path: '/request-client-access',
        name: 'MobileEmployerSettings',
        components: {
            default: MobileEmployerSettings
        },
        meta: {
            title: 'Employer Settings'
        }
    },
    {
        path: '/quote-generator',
        components: {
            default: QuoteGenerator
        },
        meta: {
            title: 'Quote Generator'
        }
    },

    {
        path: '/traccar',
        components: {
            default: Traccar
        },
        meta: {
            title: 'Traccar'
        }
    },
    {
        path: '/call-outs',
        components: {
            default: CallOuts
        },
        meta: {
            title: 'Call Outs'
        }
    },
    {
        path: '/call-outs/:id',
        components: {
            default: CallOutSingle
        },
        meta: {
            title: 'Call Outs'
        }
    },
    {
        path: '/invite-client',
        components: {
            default: InviteClient
        },
        meta: {
            title: 'Invite Client'
        }
    },
    {
        path: '/invite-user',
        components: {
            default: InviteUser
        },
        meta: {
            title: 'Invite User'
        }
    },
    {
        path: '/signup',
        name:'SignUp',
        components: {
            default: SignUp
        },
        meta: {
            title: 'Sign up'
        }
    },
    {
        path: '/signup/success',
        name: 'SignUpSuccess',
        components: {
            default: SignUpSuccess
        },
        meta: {
            title: 'Sign up - success'
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        components: {
            default: ForgotPassword
        },
        meta: {
            title: 'Reset Password'
        }
    },{
        path: '/osid-forgot-password',
        name: 'OnSiteIdForgotPassword',
        components: {
            default: OnSiteIdForgotPassword
        },
        meta: {
            title: 'Reset Password'
        }
    },
    {
        path: '/reset-password/:token/:id',
        name: 'ResetPassword',
        components: {
            default: ResetPassword
        },
        meta: {
            title: 'Reset Password'
        }
    },
    {
        path: '/register',
        name: 'Register',
        components: {
            default: Register
        },
        meta: {
            title: 'Sign Up'
        }
    },
    {
        path: '/osid-register',
        name: 'OnSiteIdRegister',
        components: {
            default: OnSiteIdRegister
        },
        meta: {
            title: 'Sign Up'
        }
    },
    {
        path: '/induction',
        name:'Induction',
        components: {
            default: Induction
        },
        meta: {
            title: 'Registration'
        }
    },
    {
        path: '/login',
        name: 'Login',
        components: {
            default: Login
        },
        meta: {
            title: 'Login to CMS'
        }
    },
    {
        path: '/osid-login',
        name: 'OnSiteIdLogin',
        components: {
            default: OnSiteIdLogin
        },
        meta: {
            title: 'Login to CMS'
        }
    },
    {
        path: '/rav/dashboard/invoices/add',
        name: 'RAV',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://rav.companiesms.co.uk/dashboard/invoices/add" })
        },
        meta: {
            title: 'RAV'
        }
    },
    {
        path: '/rav/dashboard/cc/view',
        name: 'RAV',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://rav.companiesms.co.uk/dashboard/cc/view" })
        },
        meta: {
            title: 'RAV'
        }
    },
    {
        path: '/rav/dashboard/vouchers/manage/office',
        name: 'RAV',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://rav.companiesms.co.uk/dashboard/vouchers/manage/office" })
        },
        meta: {
            title: 'RAV'
        }
    },
    {
        path: '/rav/dashboard/employees/view',
        name: 'RAV',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://rav.companiesms.co.uk/dashboard/employees/view" })
        },
        meta: {
            title: 'RAV'
        }
    },
    {
        path: '/oss/admin/project',
        name: 'OSS',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/project" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/oss/admin/user',
        name: 'OSS',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/user" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/oss/admin/weekly-summary',
        name: 'OSS',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/weekly-summary" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/oss/admin/daily-summary',
        name: 'OSS',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/daily-summary" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/oss/admin/timesheet-builder',
        name: 'OSS',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://oss.companiesms.co.uk/admin/timesheet-builder" })
        },
        meta: {
            title: 'OSS'
        }
    },
    {
        path: '/help-hub/cms',
        name: 'CMS Help Hub',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://cmshelp.companiesms.co.uk" })
        },
        meta: {
            title: 'CMS Help Hub'
        }
    },
    {
        path: '/help-hub/oss',
        name: 'OSS Help',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://help.companiesms.co.uk/oss" })
        },
        meta: {
            title: 'OSS Help'
        }
    },
    {
        path: '/help-hub/rav',
        name: 'RAV Help',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://help.companiesms.co.uk/rav" })
        },
        meta: {
            title: 'RAV Help Hub'
        }
    },
    {
        path: '/urls/cms_news',
        name: 'cms-news',
        components:{
            default: CMSNewsViewer
        },
        meta: {
            title: 'CMS News'
        }
    },
    {
        path: '/urls/elmech_drive',
        name: 'elmech-drive',
        components:{
            default: CMSNewsViewer
        },
        meta: {
            title: 'Elmech Drive'
        }
    },
    {
        path: '/urls/cms_drive',
        name: 'cms-drive',
        components:{
            default: CMSNewsViewer
        },
        meta: {
            title: 'CMS Drive'
        }
    },
    {
        path: '/urls/cctv',
        name: 'cctv',
        components:{
            default: CMSNewsViewer
        },
        meta: {
            title: 'CCTV'
        }
    },
    {
        path: '/urls/ezpz_pdf',
        name: 'ezpz-pdf',
        components:{
            default: CMSNewsViewer
        },
        meta: {
            title: 'EzPz PDF'
        }
    },
    {
        path: '/batch-invoice',
        name: 'Batch Invoice',
        components:{
            default: HubViewer
        },
        props:{
            default: route => ({ baseUrl: "https://invoice-compiler.companiesms.co.uk/hw.html?v=1.0.5" })
        },
        meta: {
            title: 'Batch Invoice'
        }
    },
    {
        path: '/',
        name:'Dashboard',
        components: {
            default: DashboardV2
        },
        meta: {
            title: 'CMS Dashboard'
        }
    },
    {
        path: '/costing',
        name:'Costing',
        components: {
            default: CostingV2
        },
        meta: {
            title: 'CMS Costing'
        }
    },
    {
        path: '/onsiteselfie/daily-reports',
        name: 'Daily Report',
        group: 'OSS',
        components: {
            default: DailyReportV2
        },
        meta:{
            title: 'Daily Report'
        }
    },{
        path: '/on-site-selfie/weekly-reports',
        name: 'Weekly Report',
        components: {
            default: WeeklyReportsV2
        },
        meta:{
            title: 'Weekly Report'
        }
    },
    {
        path: '/on-site-selfie/timesheet-builder',
        name: 'Timesheet Builder',
        components: {
            default: TimesheetBuilderV2
        },
        meta:{
            title: 'Timesheet Builder'
        }
    },
    {
        path: '/site-vehicle-entry-logs',
        name: 'Vehicle Logs',
        components: {
            default: VehicleLogs
        },
        meta:{
            title: 'Vehicle Logs'
        }
    },
    {
        path: '/site-vehicle-entry-logs/:id',
        name: 'Vehicle Logs',
        components: {
            default: VehicleLog
        },
        meta:{
            title: 'Vehicle Log'
        }
    },
    {
        path: '/clients/:id/site-vehicle-entry-log-requirements',
        name: 'Site Vehicle Entry Log Requirements',
        components: {
            default: SiteVehicleLogRequirements
        },
        meta:{
            title: 'Site Vehicle Entry Log Requirements'
        }
    },
    {
        path: '/clients/:id/on-site-selfie',
        name: 'On-Site Selfie',
        components: {
            default: ClientOnSiteSelfie
        },
        meta:{
            title: 'On-Site Selfie'
        }
    },
    {
        path: '/clients/:id/quality-assessments',
        name: 'Quality Assessments',
        components: {
            default: ClientQualityAssessments
        },
        meta:{
            title: 'QualityAssessment'
        }
    },
    {
        path: '/clients/:id/reoccurring-invoice-items',
        name: 'Reoccurring Invoice Items',
        components: {
            default: ClientReoccurringInvoiceItems
        },
        meta:{
            title: 'Reoccurring Invoice Items'
        }
    },
    {
        path: '/clients/:id/contracts',
        name: 'Contracts',
        components: {
            default: ClientContracts
        },
        meta:{
            title: 'Contracts'
        }
    },
    {
        path: '/clients/:id/departments',
        name: 'Departments',
        components: {
            default: ClientDepartments
        },
        meta:{
            title: 'Departments'
        }
    },
    {
        path: '/clients/:id/projects',
        name: 'Projects',
        components: {
            default: ClientProjects
        },
        meta:{
            title: 'Projects'
        }
    },
    {
        path: '/clients/:id/users',
        name: 'Users',
        components: {
            default: ClientUsers
        },
        meta:{
            title: 'Users'
        }
    },
    {
        path: '/clients/:id/user-groups',
        name: 'User Groups',
        components: {
            default: ClientUserGroups
        },
        meta:{
            title: 'User Groups'
        }
    },
    {
        path: '/clients/:id/settings',
        name: 'Settings',
        component: ClientSettingsTools,
        meta: {
            title: 'Settings'
        }
    },
    {
        path: '/clients/:id/procurement',
        name: 'Procurement Settings',
        component: ClientProcurementTools,
        meta: {
            title: 'Procurement Settings'
        }
    },
    {
        path: '/clients/:id/supplier-groups',
        component: SupplierGroups,
        meta: {
            title: 'Supplier Groups'
        }
    },
    {
        path: '/clients/:id/bookkeeping',
        name: 'Bookkeeping',
        component: ClientBookkeepingTools,
        meta: {
            title: 'Bookkeeping'
        }
    },
    {
        path: '/clients/:id/bookkeeping/order-report',
        name: 'Export Orders',
        component: ExportClientOrders,
        meta: {
            title: 'Export Orders'
        }
    },
    {
        path: '/clients/:id/bookkeeping/invoice-report',
        name: 'Invoices Export',
        component: ExportClientInvoices,
        meta: {
            title: 'Invoices Export'
        }
    },
    {
        path: '/clients/:id/account',
        name: 'Account',
        component: ClientAccount,
        meta: {
            title: 'Account'
        }
    },
    {
        path: '/clients/:id/day-pay-requests',
        name: 'Day Pay Requests',
        components: {
            default: ClientDayPayRequests
        },
        meta:{
            title: 'Day Pay Requests'
        }
    },
    {
        path: '/clients/:id/worxs',
        name: 'WorXs',
        components: {
            default: ClientPreChecks
        },
        meta:{
            title: 'WorXs'
        }
    },
    {
        path: '/day-pay-requests',
        name: 'DayPayRequests',
        components: {
            default: DayPayRequests
        },
        meta:{
            title: 'Day Pay Requests'
        }
    },
    {
        path: '/day-pay-requests/:id',
        name: 'DayPayRequest',
        components: {
            default: DayPayRequest
        },
        meta:{
            title: 'Day Pay Requests'
        }
    },
    {
        path: '/subscriptions',
        name: 'Subscriptions',
        components: {
            default: Subscriptions
        },
        meta:{
            title: 'Subscriptions'
        }
    },
    {
        path: '/subscriptions/:id',
        name: 'Subscription',
        components: {
            default: Subscription
        },
        meta:{
            title: 'Subscriptions'
        }
    },
    {
        path: '/product-library',
        name: 'ProductLibrary',
        components: {
            default: ProductLibrary
        },
        meta:{
            title: 'Product Library'
        }
    },
    {
        path: '/product-library/:id',
        name: 'ProductSingle',
        components: {
            default: ProductSingle
        },
        meta:{
            title: 'Product Library'
        }
    },
    {
        path: '/product-categories',
        name: 'ProductCategories',
        components: {
            default: ProductCategories
        },
        meta:{
            title: 'Categories'
        }
    },
    {
        path: '/product-categories/:id',
        name: 'CategorySingle',
        components: {
            default: Category
        },
        meta:{
            title: 'Categories'
        }
    },
    {
        path: '/product-categories/:id/no-type',
        name: 'ProductsWithoutType',
        components: {
            default: ProductsWithoutType
        },
        meta:{
            title: 'Products - no type'
        }
    },
    {
        path: '/product-library/types/:id',
        name: 'ProductTypeSingle',
        components: {
            default: ProductTypeSingle
        },
        meta:{
            title: 'Product Library - types'
        }
    },
    {
        path: '/clients/:id/pending-users',
        name: 'Pending Users',
        components: {
            default: ClientPendingUsers
        },
        meta:{
            title: 'Pending Users'
        }
    },
    {
        path: '/accept-terms-and-conditions',
        name:'Terms And Conditions',
        component: TermsAndConditions,
        meta: {
            title: 'Terms And Conditions'
        }
    },
    {
        path: '/terms-not-accepted',
        name:'Terms Not Accepted',
        component: TermsAndConditionsNotAccepted,
        meta: {
            title: 'Terms And Conditions Not Accepted'
        }
    },
    {
        path: '/files/quotes/:fileName',
        component: DownloadPdf,
        props:{
            url: process.env.VUE_APP_API_URL + '/api' + window.location.pathname
        },
        meta: {
            title: 'Download Quote'
        }
    },
    {
        path: '/files/invoices/:fileName',
        component: DownloadPdf,
        props:{
            url: process.env.VUE_APP_API_URL + '/api' + window.location.pathname
        },
        meta: {
            title: 'Download Invoice'
        }
    },
    {
        path: '/files/rfis/:fileName',
        component: DownloadPdf,
        props:{
            url: process.env.VUE_APP_API_URL + '/api' + window.location.pathname
        },
        meta: {
            title: 'Download RFI'
        }
    },
    {
        path: '/files/attachments/:fileName',
        component: DownloadPdf,
        props:{
            url: process.env.VUE_APP_API_URL + '/api' + window.location.pathname
        },
        meta: {
            title: 'Download Attachment'
        }
    },
    {
        path:'/evac',
        components: {
            default: Evac,
        },
        meta: {
            title: 'Evac'
        }
    },
    {
        path: '*', // or '/index.html'
        beforeEnter: (to, from, next) => {
            next('/')
        }
    }
];


export default routes;
