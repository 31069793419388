<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                    <a href="/direct-suppliers">Direct Suppliers</a>
                                    <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span class="text-secondary-darkblue">
                                        {{response.body.properties.get('name')}}
                                    </span>
                                </div>

                                <div class="flex items-center -mt-3 text-secondary-darkgrey">
                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'update-direct-supplier' && action.name !== 'add-new-user' &&  !action.fields.isEmpty())">
                                        <form-action class="action-with-fields" :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                        ></form-action>
                                    </div>
                                </div>
                            </div>
                        </page-header>
                        <div class="grid grid-cols-4 min-h-screen">
                            <div
                                class="col-span-4 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">

                                <direct-supplier-summary ref="summary"  :response="response" @change="change"></direct-supplier-summary>
                                <direct-supplier-clients-table :response="response"></direct-supplier-clients-table>
                                <client-users-table :response="response"></client-users-table>
                            </div>
                        </div>
                    </template>
                    <div v-if="hasChangesWaitingOnSave"
                         style="right:0px;left:16rem;"
                         class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                        <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                            Direct Supplier
                        </primary-button>
                    </div>
                </div>

            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import DirectSupplierSummary from '../../components/Summaries/DirectSupplierSummary';
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import PrimaryButton from "../../components/PrimaryButton";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import DirectSupplierClientsTable from "../../components/Tables/DirectSupplierClientsTable";
    import ClientUsersTable from "../../components/Tables/ClientUsersTable";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";

    export default {
        inject: ["$validator"],
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            PrimaryButton,
            DirectSupplierSummary,
            DirectSupplierClientsTable,
            ClientUsersTable,
            PageHeader,
            FormAction
        },
        data() {
            return {
                hasChangesWaitingOnSave: false,
                loading:false,
            }
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },
        },
        methods: {
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            async saveData() {
                this.loading = true;
                this.$refs.summary.update().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'The direct supplier was saved successfully'
                    );
                    this.hasChangesWaitingOnSave = false;
                    this.loading = false;
                }).catch(error => {
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
        },
    }
</script>

<style scoped>

</style>
