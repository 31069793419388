<template>
    <div class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-8 grid-rows-8 pt-6">
        <a class="border rounded-lg bg-white flex items-center justify-center py-6 flex-col text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium text-sm" v-if="hasLink('create-new-order')" :href="getLinkHref('create-new-order')">
            <svg class="w-6 h-6 text-secondary-lightblue fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M432 256C432 269.3 421.3 280 408 280h-160v160c0 13.25-10.75 24.01-24 24.01S200 453.3 200 440v-160h-160c-13.25 0-24-10.74-24-23.99C16 242.8 26.75 232 40 232h160v-160c0-13.25 10.75-23.99 24-23.99S248 58.75 248 72v160h160C421.3 232 432 242.8 432 256z"/></svg>

            New Order
        </a>
<!--        <a class="border rounded-lg bg-white flex items-center justify-center py-6 flex-col text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium text-sm" v-if="hasLink('orders')" :href="getLinkHref('orders')" @openSubMenu="openSection('return-requests')">-->
<!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M14 10L12 11M12 11L10 10M12 11V13.5M20 7L18 8M20 7L18 6M20 7V9.5M14 4L12 3L10 4M4 7L6 6M4 7L6 8M4 7V9.5M12 21L10 20M12 21L14 20M12 21V18.5M6 18L4 17V14.5M18 18L20 17V14.5" stroke="#B8B8FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->

<!--            Order List-->
<!--        </a>-->
        <a class="border rounded-lg bg-white flex items-center justify-center py-6 flex-col text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium text-sm" v-if="hasLink('tender-orders')" :href="getLinkHref('tender-orders')">
            <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M264 224h-144C106.8 224 96 234.8 96 248S106.8 272 120 272h144C277.3 272 288 261.3 288 248S277.3 224 264 224zM168 320h-48C106.8 320 96 330.8 96 344s10.75 24 24 24h48c13.25 0 24-10.75 24-24S181.3 320 168 320zM264 128h-144C106.8 128 96 138.8 96 152S106.8 176 120 176h144C277.3 176 288 165.3 288 152S277.3 128 264 128zM320 0H64C28.65 0 0 28.65 0 64v384c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V64C384 28.65 355.3 0 320 0zM336 448c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V64c0-8.822 7.178-16 16-16h256c8.822 0 16 7.178 16 16V448z"/></svg>

            Tender Orders
        </a>
        <a class="border rounded-lg bg-white flex items-center justify-center py-6 flex-col text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium text-sm" v-if="hasLink('return-requests')" :href="getLinkHref('return-requests')" @openSubMenu="openSection('return-requests')">
            <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M30.81 49.81c8.969-3.656 19.28-1.656 26.16 5.219l41.1 41.1c41.07-40.38 97.11-64.92 157.1-64.92C379.6 32.11 480 132.5 480 256s-100.4 223.9-223.9 223.9c-52.31 0-103.3-18.33-143.5-51.77c-10.19-8.5-11.56-23.62-3.062-33.81c8.531-10.22 23.62-11.56 33.81-3.062C174.9 417.5 214.9 432 256 432c97.03 0 176-78.97 176-176S353 80 256 80c-47.08 0-90.93 19.29-123.2 50.89l52.14 52.14c6.875 6.875 8.906 17.19 5.219 26.16C186.5 218.2 177.7 224 168 224h-128C26.75 224 16 213.3 16 200v-128C16 62.28 21.84 53.53 30.81 49.81z"/></svg>

            Return Requests
        </a>
        <a class="border rounded-lg bg-white flex items-center justify-center py-6 flex-col text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium text-sm" v-if="hasLink('rental-items')" :href="getLinkHref('rental-items')">
            <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M80 160H96V40C96 17.91 113.9 0 136 0H292.1C309.6 0 324.5 10.28 330.4 25.81L413.4 244.7C415.1 249.3 416 254.1 416 258.9V344.4C435.6 362 448 387.6 448 416C448 469 405 512 352 512C298.1 512 256 469 256 416H192C192 469 149 512 96 512C42.98 512 0 469 0 416V240C0 195.8 35.82 160 80 160zM360.3 240L287.4 48H144V171.4L240 240H360.3zM212.1 279.1L112.6 208H80C62.33 208 48 222.3 48 240V332.8C62.12 324.7 78.51 320 96 320C131.5 320 162.6 339.3 179.2 368H268.8C285.4 339.3 316.5 320 352 320C357.5 320 362.8 320.5 368 321.3V288H239.1C229.1 288 220.2 284.9 212.1 279.1H212.1zM96 464C122.5 464 144 442.5 144 416C144 389.5 122.5 368 96 368C69.49 368 48 389.5 48 416C48 442.5 69.49 464 96 464zM352 464C378.5 464 400 442.5 400 416C400 389.5 378.5 368 352 368C325.5 368 304 389.5 304 416C304 442.5 325.5 464 352 464zM528 400H616C629.3 400 640 410.7 640 424C640 437.3 629.3 448 616 448H504C490.7 448 480 437.3 480 424V24C480 10.75 490.7 0 504 0C517.3 0 528 10.75 528 24V400z"/></svg>

            Rental Items
        </a>
        <a class="border rounded-lg bg-white flex items-center justify-center py-6 flex-col text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium text-sm" v-if="hasLink('take-offs')" href="/take-off-orders/search" @openSubMenu="openSection('take-offs')">
            <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 464H288C296.8 464 304 456.8 304 448V384H352V448C352 483.3 323.3 512 288 512H64C28.65 512 0 483.3 0 448V224C0 188.7 28.65 160 64 160H128V208H64C55.16 208 48 215.2 48 224V448C48 456.8 55.16 464 64 464zM160 64C160 28.65 188.7 0 224 0H448C483.3 0 512 28.65 512 64V288C512 323.3 483.3 352 448 352H224C188.7 352 160 323.3 160 288V64zM224 304H448C456.8 304 464 296.8 464 288V64C464 55.16 456.8 48 448 48H224C215.2 48 208 55.16 208 64V288C208 296.8 215.2 304 224 304z"/></svg>

            Take Offs
        </a>
        <a class="border rounded-lg bg-white flex items-center justify-center py-6 flex-col text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium text-sm" v-if="hasLink('schedule-manager')" href="/schedule-manager">
            <svg class="w-6 h-6 text-secondary-lavenderblue fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M127.1 0C141.3 0 151.1 10.75 151.1 24V64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V192H47.1V448C47.1 456.8 55.16 464 63.1 464H284.5C296.7 482.8 312.5 499.1 330.8 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0H127.1zM576 368C576 447.5 511.5 512 432 512C352.5 512 287.1 447.5 287.1 368C287.1 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368zM415.1 304V368C415.1 376.8 423.2 384 431.1 384H480C488.8 384 496 376.8 496 368C496 359.2 488.8 352 480 352H447.1V304C447.1 295.2 440.8 288 431.1 288C423.2 288 415.1 295.2 415.1 304V304z"/></svg>
            Schedule Manager
        </a>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
    },
}
</script>

<style scoped>

</style>
