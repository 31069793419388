<template>
    <div id="background-app" class="h-min static min-h-screen" style="background-color:#1E1E1E">
        <div class="container mx-auto pt-2 px-6 sm:px-6 lg:px-8 max-w-2xl">
            <a href="/mobile/dashboard">
                <img src="../../images/on-site-id-logo.png" class="w-full">
            </a>
        </div>
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
            <div>
                <slot></slot>
            </div>
        </main>
    </div>

</template>

<script>
    import MenuNav from "../common/MenuNav";
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import formatting from "../mixins/formatting";
    export default {
        inject: ["$validator"],
        components:{MenuNav},
        mixins: [formatting],
        data() {
            return {
            };
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken",
                user: "getUser",
                message: "getMessage",
            }),
        },
        methods: {
        },
    }
</script>

<style scoped>
#background-app {
    background-image: url('../../images/on-site-id-background.png');
    background-size:cover;
    background-repeat: repeat-y;
}
</style>
