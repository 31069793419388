<template>
    <div>
        <a-button class="mr-3" v-if="!isNegativeAction && !isPositiveAction" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>
        <red-button class="mr-3" v-else-if="isNegativeAction" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </red-button>
        <green-button class="mr-3" v-else-if="isPositiveAction" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </green-button>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
        :confirmationString='`Are you sure you want to \"${action.title}\"`'
        @removeFromDisplay="displayModal = false" @perform="perform()"
        :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction">

            <template v-if="toUserIdField()">
                <filterable-select-input v-model="formData['to_user_id']" :title="toUserIdField().title" :options="toUserIdField().options" :required="required"></filterable-select-input>
                <div v-show="errors.has('to_user_id')" class="text-red-600">{{ errors.first('to_user_id') }}</div>
            </template>
            <property-or-field @input="change" :action="action"
                               fieldId="current_mileage"
                               v-model="formData['current_mileage']"
            ></property-or-field>
            <div v-show="errors.has('current_mileage')" class="text-red-600">{{ errors.first('current_mileage') }}</div>

            <images-input :action="action" v-model="formData['images']"></images-input>
            <div v-show="errors.has('images')" class="text-red-600">{{ errors.first('images') }}</div>
        </slide-over>
    </div>
</template>

<script>
    import Button from '../Button';
    import Modal from '../Modal';
    import SlideOver from "../SlideOver";
    import fileDownload from "js-file-download";
    import RedButton from "../RedButton";
    import GreenButton from "../GreenButton";
    import PropertyOrField from "@/components/PropertyOrField";
    import DependantField from "@/components/DependantField";
    import ImagesInput from "@/components/Action/Fields/ImagesInput";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
            }
        },
        components: {
            ImagesInput,
            SlideOver,
            'a-button': Button,
            Modal,
            RedButton,
            GreenButton,
            PropertyOrField,
            DependantField
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            isNegativeAction: {
                type: Boolean,
                default: false
            },
            isPositiveAction: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                var that = this;
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined){
                        payload[field.name] = field.value
                        if(field.name === 'type'){
                            that.setType(field.value)
                        }
                    };
                });
                return payload;
            }
        },
        methods: {
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if(newPath  !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        }else{
                            this.$router.go(0);
                        }
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            toUserIdField() {
                return this.action.fields.filter(field => field.name === 'to_user_id').first();
            },
            required(){
                if(this.toUserIdField()){
                    if(this.toUserIdField().validation != null) {
                        return this.toUserIdField().validation.includes('required')
                    }
                }
                return false;
            }

        },

    }
</script>

<style lang="scss">

</style>
