<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <refund-requests-header :response="response"></refund-requests-header>
                        <div class="container mx-auto p-4 mb-8">
                            <filterable-paginated-return-requests-list :response="response">

                            </filterable-paginated-return-requests-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import FilterablePaginatedReturnRequestsList from '../components/FilterablePaginatedReturnRequestsList';
    import Loading from '../components/Loading';
    import DashboardStats from '../components/Dashboard/DashboardStats';
    import SavedFilters from '../components/SavedFilters';
    import PrimaryButton from '../components/PrimaryButton';
    import ProductHeader from '../components/ProductHeader';
    import RefundRequestsHeader from "../components/RefundRequestsHeader";
    import MainTemplate from "../templates/MainTemplate";

    export default {
        components: {
            MainTemplate,
            FetchRootSiren,
            FilterablePaginatedReturnRequestsList,
            Loading,
            DashboardStats,
            SavedFilters,
            PrimaryButton,
            ProductHeader,
            RefundRequestsHeader
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
