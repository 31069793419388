<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div
                        class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                    >
                        <table class="min-w-full" data-cy="orders-table">
                            <thead>
                            <tr v-if="filterAction">
                                <th colspan=1000 class="p-8  bg-gray-50">
                                    <order-table-filter :action="filterAction"
                                                          :response="response"
                                                          :action-base-url="actionBaseUrl"
                                    ></order-table-filter>
                                </th>
                            </tr>
                            <tr>
                                <th
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                    v-for="headingValue in headingValues"
                                    :key="headingValue"
                                >{{headingValue}}
                                </th>
                            </tr>
                            </thead>
                            <tbody v-if="this.response.body.properties.get('total') === 0">
                            <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                                <td colspan="100"
                                    class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                    No orders found.
                                </td>
                            </tr>
                            </tbody>
                            <tbody>
                            <template v-for="orderEntity,key in orderEntities">
                                <tr
                                    class="bg-white hover:bg-gray-100 cursor-pointer"
                                    :class="{'bg-gray-50': !(key % 2 == 0)}">
                                    <template v-for="field, key in getPropertiesToDisplay(orderEntity)">
                                        <td class="whitespace-nowrap text-sm leading-5 text-gray-500 overflow-hidden">
                                            <a v-bind:href="getItemLink(orderEntity)" class="block px-3 py-8 -my-4">
                                            <template v-if="key === 'status'">
                                                <status-badge :status="field" :deliveryDate="scheduledDeliveryDate(orderEntity)"  :expected-by-date="expectedByDate(orderEntity)" class="bootstrap-styles"></status-badge>
                                            </template>
                                            <template v-else>{{field}}</template>
                                            </a>
                                        </td>
                                    </template>
                                </tr>
                                <tr v-if="orderEntity.entity.properties.get('rejection_note')"
                                    class="bg-white text-red-600" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                    <td class="px-6 pb-2" colspan="100">
                                        {{orderEntity.entity.properties.get('rejection_note')}}
                                    </td>
                                </tr>

                            </template>
                            </tbody>
                        </table>
                        <pagination
                            :response="response"
                            @navigate="paginationNavigation($event)"
                            @navigateAdditionalNext="paginationNavigation($event)"
                            @navigateAdditionalPrev="paginationNavigation($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatusBadge from "./StatusBadge";
    import Pagination from "./Pagination";
    import OrderTableFilter from "@/components/OrderTableFilter";

    export default {
        components: {
            StatusBadge,
            Pagination,
            OrderTableFilter
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            orderEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('order'));
            },
            headingValues() {
                return ['Order Reference', 'PO Number', 'Quick Reference', 'Project', 'Status', 'Date'];
            },
            actionBaseUrl(){
                if(this.$route.name === 'TakeOffs'){
                    return '/take-off-orders';
                }
                if(this.$route.name === 'MismatchQuotes'){
                    return '/quotes-mismatch'
                }
                if(this.$route.name === 'ScheduleManager'){
                    return '/schedule-manager'
                }
                if(this.$route.name === 'TenderOrders'){
                    return '/tender-orders'
                }


                return '/orders';
            }
        },
        methods: {
            getPropertiesToDisplay(orderEntity) {
                return {
                    'order_reference': orderEntity.entity.properties.get('order_reference'),
                    'purchase_order_number' : orderEntity.entity.properties.get('purchase_order_number'),
                    'quick_reference': orderEntity.entity.properties.get('quick_reference'),
                    'project': orderEntity.entity.properties.get('project'),
                    'status': orderEntity.entity.properties.get('status'),
                    'date': orderEntity.entity.properties.get('date'),
                };
            },
            expectedByDate(orderEntity) {
                return orderEntity.entity.properties.get('expected_by_date');
            },
            scheduledDeliveryDate(orderEntity) {
                let scheduledDelivery = orderEntity.entity.entities
                    .filter(function (entity) {
                        return entity.rels.contains("scheduled_delivery");
                    })
                    .first();

                if (!scheduledDelivery) {
                    return null;
                }

                return scheduledDelivery.entity.properties.get('date');
            },
            paginationNavigation({link}) {
                debugger;
            },
            getItemLink(orderEntity){
                let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                return(parts[1])
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
