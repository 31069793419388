<template>
    <div class="order-summary">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Material Summary</h3>
        <div class="generic-summary p-4 bg-white" :key="key">
            <div class="grid grid-cols-3 pb-4 gap-4">
                <property-or-field @input="change" :properties="properties" title="Item" :action="updateAction" fieldId="item" propertyKey="item" v-model="updateActionData['item']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Code" :action="updateAction" fieldId="code" propertyKey="code" v-model="updateActionData['code']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Quantity" :action="updateAction" fieldId="quantity" propertyKey="quantity" v-model="updateActionData['quantity']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Contract" :action="updateAction" fieldId="contract_id" propertyKey="contract_id" v-model="updateActionData['contract_id']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Note" :action="updateAction" fieldId="note" propertyKey="note" v-model="updateActionData['note']"></property-or-field>
            </div>
            <div class="sm:col-span-2" v-if="response.body.properties.get('image')">
                <dt class="text-sm font-medium text-gray-500">
                    Image
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                    <div class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                        <a :href="response.body.properties.get('image')" class="pointer-events-none">
                            <img :src="response.body.properties.get('image')" alt="" class="group-hover:opacity-75 object-cover pointer-events-none  rounded-lg">
                        </a>
                        <button type="button" class="absolute">
                            <span class="sr-only">View details for IMG_5214.HEIC</span>
                        </button>
                    </div>
                </dd>
            </div>
            <div class="grid grid-cols-1 pb-8 gap-4">
                <material-quantities-table :response="response"></material-quantities-table>
            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from '../PropertyOrField';
    import MaterialQuantitiesTable from "@/components/MaterialQuantitiesTable";
    export default {
        inject: ["$validator"],
        components: {
            MaterialQuantitiesTable,
            PropertyOrField,
        },
        data() {
            return {
                key : 0
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update-material').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            properties(){
                return this.response.body.properties;
            }
        },
        methods: {
            update(){
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
