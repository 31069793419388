<template>
    <div v-if="actionField || (properties && properties.get(propertyKey))">
        <label class="block text-sm font-medium leading-5 text-gray-500 mb-0 label-a"
        >{{title}} <span v-if="comment" class="font-normal text-xs text-gray-400">({{comment}})</span>
            <span class="text-red-500" v-if="required && title">*</span>
        </label>
        <div class="form-group" v-if="actionField">
            <component ref="fieldComponent" :is="getInputComponent(actionField)" :field="actionField"
                       v-model="propsVal" :dependsOn="dependsOn" :dependsOnValue="dependsOnValue" :useCamera="useCamera"
                       :dependsOn2="dependsOn2" :dependsOnValue2="dependsOnValue2" :includeNullOption="includeNullOption"
                       @returnPaymentType="returnPaymentType" :parentId="parentId" @returnSelectedOption="returnSelectedOption"></component>
            <div v-show="errors.has(validationFieldName)" class="text-red-600">{{ errors.first(validationFieldName) }}
            </div>
        </div>
        <div v-else>
            <template v-if="title === 'Attachment' || title === 'Scheduled File'">
                <a class="text-sm text-gray-700 underline" v-if="properties.get(propertyKey)" href="#" @click.prevent="togglePDF">Download ({{properties.get(propertyKey)}})</a>
                <template v-if="showPDF">
                    <download-pdf :url="attachmentUrl" @removeFromDisplay="showPDF = false"></download-pdf>
                </template>
            </template>
            <template v-else>
                {{properties.get(propertyKey)}}
            </template>
        </div>
    </div>
</template>

<script>
    import CheckboxInput from './Action/Fields/CheckboxInput';
    import RadioInput from './Action/Fields/RadioInput';
    import TextInput from './Action/Fields/TextInput';
    import DateInput from './Action/Fields/DateInput';
    import NumberInput from './Action/Fields/NumberInput';
    import TextAreaInput from './Action/Fields/TextAreaInput';
    import DownloadPdf from "../pages/DownloadPdf";

    export default {
        inject: ["$validator"],
        components: {
            CheckboxInput,
            RadioInput,
            TextInput,
            DateInput,
            NumberInput,
            TextAreaInput,
            DownloadPdf
        },
        computed: {
            actionField() {
                if (!this.action) {
                    return null;
                }
                return this.action.fields.filter(field => field.name === this.fieldId).first();
            },
            validationFieldName() {
                if (!this.scope) {
                    return this.fieldId;
                }

                return this.scope + '.' + this.fieldId;
            },
            required: function(){
                if(this.actionField){
                    if(this.actionField.validation != null) {
                        return this.actionField.validation.includes('required')
                    }
                }
                return false;
            },
          attachmentUrl(){
            if(!this.properties.get(this.propertyKey).includes('/')){
              return process.env.VUE_APP_API_URL +'/api/files/attachments/' + this.properties.get(this.propertyKey);
            }
            if(this.properties.get(this.propertyKey).includes('public')){
              return process.env.VUE_APP_API_URL + '/' + this.properties.get(this.propertyKey).replace('public', 'storage');
            }
            return process.env.VUE_APP_API_URL +'/api/files/' + this.properties.get(this.propertyKey);
          }
        },
        methods: {

            getInputComponent(actionField) {
                if (actionField.name === 'additional_notes' || actionField.name === 'procurement_notes') {
                    return 'text-area-input';
                }

                if(actionField.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return actionField.type + '-input';
            },
            togglePDF()
            {
                this.showPDF = !this.showPDF
            },
            returnSelectedOption(value)
            {
                this.$emit('returnSelectedOption', value);
            },
            returnPaymentType(value){
                this.$emit('returnPaymentType', value);
            }
        },
        props: {
            value: {},
            title: {
                type: String
            },
            action: {
                type: Object
            },
            fieldId: {
                type: String
            },
            propertyKey: {
                type: String
            },
            properties: {
                type: Object
            },
            scope: {
                type: String,
                default: null
            },
            comment:{
                type: String
            },
            dependsOn:{
                type: String,
                default: null
            },
            dependsOnValue:{
                type : Number,
                default: null
            },
            useCamera:{
                type: Boolean,
                default: false
            },
            dependsOn2:{
                type: String,
                default: null
            },
            dependsOnValue2:{
                default: null
            },
            includeNullOption:{
                type: Boolean,
                default: true
            },
            parentId:{
                default: null
            }
        },
        data() {
            return {
                propsVal: this.value,
                showPDF: false
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            }
        }
    };
</script>

<style lang="scss" scoped></style>
