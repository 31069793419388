<template>
    <div :key="$route.fullPath" class="invite-client">
        <main-template>
            <template slot-scope="{logo}">
            <page-header :logo="logo">
                Invite Client
            </page-header>
            <loading v-if="loading"></loading>
            <div class="container mx-auto px-4 mx-auto mt-5" v-if="!loading">
                <div class="bg-white sm:w-3/4 lg:w-1/2 shadow rounded mx-auto p-4">
                    <div class="grid grid-cols-1 p-3 sm:grid-cols-1 gap-4">
                        <div class="form-group">
                            <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                                Email address
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                        type="email"
                                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                        id="email"
                                        name="email"
                                        v-model="email"
                                        v-validate="'required|email'"
                                        placeholder="Please enter your email address"
                                        :class="{'is-invalid': errors.first('email') || errors.first('client_admin.email')}"
                                >
                                <p class="mt-2 text-sm text-red-600" id="email-error"
                                   v-if="errors.first('email') || errors.first('client_admin.email')"
                                >{{ errors.first('email') || errors.first('client_admin.email') }}</p>
                            </div>

                        </div>
                          <span class="block w-full rounded-md shadow-sm">
                              <primary-button data-cy="submit" class="w-full flex justify-center" @click.native="inviteClient" :loading="submitting">Invite</primary-button>
                          </span>
                    </div>
                </div>
            </div>
            </template>
        </main-template>
    </div>
</template>

<script>
    import Loading from '../components/Loading';
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import PageTitle from "../common/PageTitle";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import PrimaryButton from "../components/PrimaryButton";
    import PageHeader from "@/v2/components/Header";

    let follow = Siren.Helper.follow;

    export default {
        components: {
            PrimaryButton,
            MainTemplate,
            Loading,
            PageTitle,
            PageHeader
        },
        data() {
            return {
                inviteClientAction: null,
                loading: true,
                submitting: false,
                email: null
            }
        },

        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        },
        methods: {
            change() {

            },
            inviteClient(){
                this.submitting = true;
                this.inviteClientAction.perform({email: this.email}).then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.$router.go(0);
                }).catch(error => {
                    this.submitting = false;
                    if (error.response.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                })
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },
        mounted() {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(res => {
                    let siren = res.body;
                    this.inviteClientAction = siren.actions.filter(action => action.name === 'invite-client').get('invite-client');


                    if(!this.inviteClientAction){
                        //reditect
                        this.$router.push({ path: "/" });
                    }

                    this.loading = false;
                }, this);

        },
    }
</script>

<style lang="scss">
    .create-new-order .label-a {
        display: none;
    }
</style>
