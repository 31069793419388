<template>
    <main-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <a href="/orders">Orders</a>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{response.body.properties.get('project')}}
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{response.body.properties.get('site')}}
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('order_reference')}}
                            <template v-if="response.body.properties.get('purchase_order_number')">
                                / {{response.body.properties.get('purchase_order_number')}}
                            </template>
                        </span>

                        <status-badge class="ml-2" :status="response.body.properties.get('status')" ></status-badge>
                        <flag-order-action class="ml-2" v-if="response.body.actions.get('flag-order')" :hasFlag="response.body.properties.get('has_flag')" :action="response.body.actions.get('flag-order')" :onSuccessPath="$route.path" :redirect-on-success="false">
                        </flag-order-action>
                    </page-header>
<!--                    stats commented out for now-->
<!--                    <div class="bg-white border-t border-b flex justify-center">-->
<!--                        <dl class="max-w-7xl grid grid-cols-1 sm:p-6 bg-white items-center overflow-hidden divide-y divide-gray-200 md:grid-cols-6 md:divide-y-0 md:divide-x">-->
<!--                            <div class="px-4 py-2">-->
<!--                                <dt class=" text-xs font-normal text-secondary-darkgrey">Expected In</dt>-->
<!--                                <dd class="mt-1 items-baseline md:block lg:flex">-->
<!--                                    <div class="flex items-baseline text-sm  font-bold text-secondary-darkgrey">-->
<!--                                        4 days-->
<!--                                    </div>-->
<!--                                </dd>-->
<!--                            </div>-->
<!--                            <div class="px-4 py-2">-->
<!--                                <dt class=" text-xs font-normal text-secondary-darkgrey">Delivered</dt>-->
<!--                                <dd class="mt-1 items-baseline md:block lg:flex">-->
<!--                                    <div class="flex items-baseline text-sm  font-bold text-secondary-darkgrey">-->
<!--                                        66%-->
<!--                                    </div>-->

<!--                                </dd>-->
<!--                            </div>-->
<!--                            <div class="px-4">-->
<!--                                <dt class=" text-xs font-normal text-secondary-darkgrey">Budget Remaining</dt>-->
<!--                                <dd class="mt-1 items-baseline md:block lg:flex">-->
<!--                                    <div class="flex items-baseline text-sm  font-bold text-secondary-darkgrey">-->
<!--                                        £200,000-->
<!--                                    </div>-->

<!--                                </dd>-->
<!--                            </div>-->
<!--                            <div class="px-4 py-2">-->
<!--                                <dt class=" text-xs font-normal text-secondary-darkgrey">Steps</dt>-->
<!--                                <dd class="mt-1 items-baseline md:block lg:flex">-->
<!--                                    <div class="flex items-baseline text-sm  font-bold text-secondary-darkgrey">-->
<!--                                        4 of 6-->
<!--                                    </div>-->

<!--                                </dd>-->
<!--                            </div>-->
<!--                            <div class="px-4 py-2">-->
<!--                                <dt class=" text-xs font-normal text-secondary-darkgrey">Delivered</dt>-->
<!--                                <dd class="mt-1 items-baseline md:block lg:flex">-->
<!--                                    <div class="flex items-baseline text-sm  font-bold text-secondary-darkgrey">-->
<!--                                        66%-->
<!--                                    </div>-->

<!--                                </dd>-->
<!--                            </div>-->
<!--                            <div class="px-4 py-2">-->
<!--                                <dt class=" text-xs font-normal text-secondary-darkgrey">Invoiced</dt>-->
<!--                                <dd class="mt-1 items-baseline md:block lg:flex">-->
<!--                                    <div class="flex items-baseline text-sm  font-bold text-secondary-darkgrey">-->
<!--                                        100%-->
<!--                                    </div>-->

<!--                                </dd>-->
<!--                            </div>-->
<!--                        </dl>-->
<!--                    </div>-->

                    <refund-requests-review :response="response"></refund-requests-review>
                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-2">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                    Order Details
                                                    <svg v-if="response.body.actions.filter(action => action.name==='update').first()" @click="displayOrderDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </dt>
                                                <dd class="text-sm text-black font-bold">{{ response.body.properties.get('quick_reference') }}</dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Schedule</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <template v-if="response.body.properties.get('is_scheduled') === 'No'">
                                                        Not Scheduled - Expected by {{response.body.properties.get('expected_by_date')}}
                                                    </template>
                                                    <template v-else>
                                                        Scheduled
                                                    </template>
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Project & Contract</dt>
                                                <dd class="text-sm font-bold text-black">{{response.body.properties.get('project')}} - {{response.body.properties.get('site')}}</dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Delivery</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <template v-if="response.body.properties.get('booking_delivery') === 'Yes'">
                                                        Delivery required - booking required.
                                                    </template>
                                                    <template v-else>
                                                        Collection.
                                                    </template>
                                                    <template v-if="response.body.entities.filter(entity => entity.rels.contains('goods_in_note')).size > 0">
                                                        <br>
                                                        <a class="text-md cursor-pointer text-blue-600 hover:text-blue-500" @click="displayDeliveryNotes = true">View delivery notes</a>
                                                    </template>
                                                </dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Supplier Notes</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <template v-if="response.body.properties.get('additional_notes')">
                                                        {{ response.body.properties.get('additional_notes') }}
                                                    </template>
                                                    <template v-else>No Notes</template>
                                                </dd>
                                            </div>
                                            <div v-if="response.body.properties.get('procurement_notes')">
                                                <dt class="text-sm font-nomal text-gray-500">Procurement Notes</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <template>{{ response.body.properties.get('procurement_notes') }}</template>
                                                </dd>
                                            </div>
                                        </dl>
                                        <div class="py-6 md:py-0">

                                            <div class="bg-secondary-lightbacka shadow sm:rounded-lg">
                                                <div class="px-4 py-5 sm:px-6">
                                                    <selected-quote :response="response" @updateQuote="displayQuoteDetails = true" @displayInvoices="displayInvoices=true" @displayRefunded="displayCreditNotes=true" @showItems="showOrderItems" :lowestPrice="lowestPrice" @showPrePricedItems="displayPrePricedItems = true"></selected-quote>

                                                    <order-details :response="response" @showItems="showOrderItems"></order-details>
                                                </div>
                                            </div>
                                            <div class="bg-secondary-lightbacka shadow sm:rounded-lg mt-4" v-if="response.body.properties.get('credit_limit_total')">
                                                <div class="px-4 py-5 sm:px-6">
                                                    <dl class="grid grid-cols-2 sm:grid-cols-2 gap-x-4 gap-y-4 mb-4 xl:grid-cols-3">
                                                        <div class="border-2 rounded-lg p-2">
                                                            <dt class="text-xs font-normal text-secondary-darkgrey-100">Spent</dt>
                                                            <dd class="text-black font-bold my-2">{{ response.body.properties.get('credit_limit_spent') }}</dd>
                                                        </div>
                                                        <div class="border-2 rounded-lg p-2">
                                                            <dt class="text-xs font-normal text-secondary-darkgrey-100">On Order</dt>
                                                            <dd class="text-black font-bold my-2">{{ response.body.properties.get('credit_limit_on_order') }}</dd>
                                                        </div>
                                                        <div class="border-2 rounded-lg p-2">
                                                            <dt class="text-xs font-normal text-secondary-darkgrey-100">Credit Limit</dt>
                                                            <dd class="text-black font-bold my-2">{{ response.body.properties.get('credit_limit_total') }}</dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <activity-feed :response="response"></activity-feed>
                        </div>
                        <section class="lg:col-start-3 lg:col-span-1 space-y-4">
                            <div v-if="response.body.actions
                            .filter(action =>
                                action.name !== 'update' &&
                                action.name !== 'update-invoice' &&
                                action.name !== 'add-new-item' &&
                                action.name !== 'search-products' &&
                                action.name !== 'update-quote' &&
                                action.name !== 'add-comment' &&
                                action.name !== 'add-product-to-order' &&
                                action.name !== 'get-pre-priced-values' &&
                                action.name !== 'flag-order' &&
                                action.name !== 'select-cms-quote' &&
                                action.name !== 'filter-products'
                            ).count() !== 0" class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <order-actions :response="response" @displayOrderItems="displayOrderItems=true"></order-actions>
                            </div>
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <order-user-info :response="response"></order-user-info>
                            </div>
                        </section>
                    </div>

                    <large-model :submitting="orderItemsSubmitting" v-show="displayOrderItems" confirmButtonText="Save Items" @removeFromDisplay="displayOrderItems = false" @perform="saveItems" backgroundColor="bg-white overflow-y-scroll">
                        <order-items :response="response" ref="orderItems" @addPreDefinedItems="showPreDefinedItems"></order-items>
                    </large-model>

                    <large-model :submitting="preDefinedItemsSubmitting" v-show="displayPreDefinedItems" confirmButtonText="Add items to order" @removeFromDisplay="displayPreDefinedItems = false; resetPreDefinedCategories()" @perform="savePreDefinedItems">
                        <pre-defined-order-items :response="response" ref="preDefinedItems"></pre-defined-order-items>
                    </large-model>

                    <large-model v-show="displayPrePricedItems" @removeFromDisplay="displayPrePricedItems = false" :displayConfirmButton="false">
                        <pre-priced-list :response="response" ref="prePricedItems" @lowestPrice="lowestPriceUpdated"></pre-priced-list>
                    </large-model>

                    <large-model v-show="displayInvoices" @removeFromDisplay="displayInvoices=false" :displayConfirmButton="false">
                        <p class="text-xl mx-auto px-4 sm:px-6 md:px-8">Invoices</p>
                        <order-invoice-paginated-list :response="response"></order-invoice-paginated-list>
                    </large-model>

                    <large-model v-show="displayCreditNotes" @removeFromDisplay="displayCreditNotes=false" :displayConfirmButton="false">
                        <p class="text-xl mx-auto px-4 sm:px-6 md:px-8">Credit Notes</p>
                        <order-credit-note-paginated-list :response="response"></order-credit-note-paginated-list>

                    </large-model>


                    <update-order-form v-if="response.body.actions.filter(action => action.name==='update').count() > 0" :displayModal="displayOrderDetails" :action="response.body.actions.filter(action => action.name==='update').first()" @removeFromDisplay="displayOrderDetails=false" :onSuccessPath="$router.currentRoute.fullPath"></update-order-form>

                    <large-model v-show="displayDeliveryNotes" @removeFromDisplay="displayDeliveryNotes = false" :displayConfirmButton="false">
                        <goods-in-note-table :response="response"></goods-in-note-table>
                    </large-model>



                </template>
            </div>

        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import ApproveButtons from "@/v2/buttons/ApproveButtons";
import StatusBadge from "@/components/StatusBadge";
import OrderUserInfo from "@/v2/components/OrderUserInfo";
import SelectedQuote from "@/v2/components/SelectedQuote";
import PageHeader from "@/v2/components/Header";
import OrderActions from "@/v2/components/OrderActions";
import ActivityFeed from "@/v2/components/ActivityFeed";
import LargeModel from "@/v2/components/LargeModel";
import OrderItems from "@/components/OrderItems";
import Invoices from "@/components/InvoicesTableList";
import CreditNotes from "@/components/CreditNotesTableList";
import SlideOver from "@/components/SlideOver";
import UpdateOrderForm from "@/v2/components/forms/UpdateOrderForm";
import UpdateQuoteForm from "@/v2/components/forms/UpdateQuoteForm";
import OrderInvoicePaginatedList from "@/v2/paginatedLists/OrderInvoicePaginatedList";
import OrderCreditNotePaginatedList from "@/v2/paginatedLists/OrderCreditNotePaginatedList";
import RefundRequestsReview from "@/components/RefundRequestsReview";
import GoodsInNoteTable from "@/components/GoodsInNoteTable";
import OrderDetails from "@/v2/components/OrderDetails";
import PreDefinedOrderItems from "@/components/PreDefinedOrderItems.vue";
import PrePricedList from "@/components/PrePricedList.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";
export default {
    inject: ["$validator"],
    name: "Order",
    data() {
        return {
            displayOrderItems: false,
            orderItemsSubmitting: false,
            displayInvoices: false,
            displayCreditNotes: false,
            displayQuoteDetails: false,
            displayOrderDetails: false,
            showAttachment: false,
            displayDeliveryNotes: false,
            displayPreDefinedItems: false,
            preDefinedItemsSubmitting: false,
            displayPrePricedItems: false,
            lowestPrice: 0
        }
    },
    components: {
        ConfirmationAction,
        FlagOrderAction,
        PrePricedList,
        PreDefinedOrderItems,
        OrderCreditNotePaginatedList,
        SlideOver,
        Invoices,
        LargeModel,
        ActivityFeed,
        OrderActions,
        PageHeader,
        OrderUserInfo,
        StatusBadge,
        Button,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        ApproveButtons,
        SelectedQuote,
        OrderItems,
        CreditNotes,
        UpdateOrderForm,
        UpdateQuoteForm,
        OrderInvoicePaginatedList,
        RefundRequestsReview,
        GoodsInNoteTable,
        OrderDetails
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update' &&
                    action.name !== 'update-invoice' &&
                    action.name !== 'add-new-item' &&
                    action.name !== 'search-products' &&
                    action.name !== 'add-product-to-order' &&
                    action.name !== 'filter-products'
                );
        },

    },
    methods: {
        showOrderItems(){
            this.displayOrderItems = true;
        },
        showPreDefinedItems(){
            this.displayPreDefinedItems = true;
        },
        async saveItems(){
            if (!this.orderItemsSubmitting) {
                this.orderItemsSubmitting = true;
                this.$refs.orderItems.updateItems().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'The order was saved successfully'
                    );
                    this.hasChangesWaitingOnSave = false;
                    this.orderItemsSubmitting = false;
                    this.$router.go(0);
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error.error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.orderItemsSubmitting = false;
                })
            }
        },

        async savePreDefinedItems(){
            if (!this.preDefinedItemsSubmitting) {
                this.preDefinedItemsSubmitting = true;
                this.$refs.preDefinedItems.updateItems().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'The order was saved successfully'
                    );
                    this.hasChangesWaitingOnSave = false;
                    this.preDefinedItemsSubmitting = false;
                    this.$router.go(0);
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error.error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.preDefinedItemsSubmitting = false;
                })
            }
        },
        attachmentUrl(attachment){
            if(!attachment.includes('/')){
                return process.env.VUE_APP_API_URL +'/api/files/attachments/' + attachment;
            }
            if(attachment.includes('public')){
                return process.env.VUE_APP_API_URL + '/' + attachment.replace('public', 'storage');
            }
            return process.env.VUE_APP_API_URL +'/api/files/' + attachment;
        },
        lowestPriceUpdated(total){
            this.lowestPrice = total;
        },
        resetPreDefinedCategories(){
            this.$refs.preDefinedItems.resetCategories(true);
        }
    }
}
</script>

<style scoped>

</style>
