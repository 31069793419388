<template>
    <div>
        <a-button v-if="isButton" :colourType="buttonType" class="mr-3" :class="additionalButtonClasses" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">{{action.title}}</a-button>
        <a v-else class="cursor-pointer font-normal underline text-gray-400"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">{{action.title}}</a>
        <portal to="destination">
            <modal-with2-confirm-buttons v-if="displayModal" :firstButtonTitle="firstButtonTitle" :secondButtonTitle="secondButtonTitle" :submitting="submittingModal" :submittingSecond="submittingModalUsingButton2" :title="action.title" :confirmationString='finalConfirmationString' @removeFromDisplay="displayModal = false" @perform="showSpecified()" @performSecond="showDirectSupplier()" :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction"></modal-with2-confirm-buttons>
        </portal>
        <portal to="destination">
            <modal v-if="displaySpecified" :submitting="submittingModal" :title="action.title" :confirmationString='finalConfirmationString' @removeFromDisplay="displaySpecified = false" @perform="perform()" :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction">
            <property-or-field class="mt-2" :action="action"
                               fieldId="specified_notes" propertyKey="specified_notes"
                               v-model="formData['specified_notes']"></property-or-field>
                <div v-show="errors.has('specified_notes')" class="text-red-600">{{ errors.first('specified_notes') }}</div>
            </modal>
        </portal>
        <portal to="destination">
            <modal v-if="displayDirectSupplier" :submitting="submittingModal" :title="action.title" :confirmationString='discountConfirmationString' @removeFromDisplay="displayDirectSupplier = false" @perform="performDirectSupplier()" :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction">
                <property-or-field class="mt-2" :action="action"
                                   fieldId="discount" propertyKey="discount"
                                   v-model="formData['discount']"></property-or-field>
                <div v-show="errors.has('discount')" class="text-red-600">{{ errors.first('discount') }}</div>
            </modal>
        </portal>
    </div>
</template>

<script>
    import Button from '@/v2/buttons/Button';
    import ModalWith2ConfirmButtons from "@/v2/components/ModalWith2ConfirmButtons";
    import Modal from "@/v2/components/Modal";
    import PropertyOrField from "@/components/PropertyOrField";
    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                firstButtonTitle: null,
                secondButtonTitle: null,
                submittingModalUsingButton2: false,
                displaySpecified: false,
                displayDirectSupplier: false,
                discountConfirmationString: 'Are any discounts applicable to this order?'
            }
        },
        components: {
            'a-button' : Button,
            Modal,
            ModalWith2ConfirmButtons,
            PropertyOrField
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            properties:{
                type: Object
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            isButton:{
                type: Boolean,
                default:true
            },
            additionalButtonClasses:{
                type:String
            },
            isNegativeAction: {
                type:Boolean,
                default:false
            },
            isPositiveAction:{
                type:Boolean,
                default:false
            }
        },
        computed: {
            finalConfirmationString() {
                var preText = '';
                if (this.action.name === 'request-update'){
                    if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                    preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
                }

                return preText + `Are you sure you want to "${this.action.title}"`
            },
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        created() {
            var that = this;
            this.action.fields.filter(field => field.contains('destination')).first().options.forEach(function (option){
                if(option.title === 'Specified'){
                    that.firstButtonTitle = 'Use ' + option.title;
                }else{
                    that.secondButtonTitle = 'Use ' + option.title
                }
            })
        },
        methods: {
            showSpecified(){
                this.displayModal = false;
                this.submittingModal = false;
                setTimeout(function () {
                    this.displaySpecified = true;
                }.bind(this), 500)
            },
            showDirectSupplier(){
                this.displayModal = false;
                this.submittingModal = false;
                setTimeout(function () {
                    this.displayDirectSupplier = true;
                }.bind(this), 500)
            },
            perform() {
                this.submittingModal = true;
                this.formData['destination'] = 'specified';
                this.action.perform(this.formData).then(res => {
                    this.displaySpecified = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                }).catch(error => {
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                        this.displaySpecified = false;
                    }

                    this.submittingModal = false;
                });
            },

            performDirectSupplier() {
                this.submittingModal = true;
                this.formData['destination'] = 'direct_supplier';
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                }).catch(error => {
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                        this.displayModal = false;
                    }
                    this.submittingModal = false;
                });
            },
            messages(response) {
            return response.body.entities
                .filter(function(entity) {
                return entity.rels.contains("status-messages");
                })
                .first();
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
