<template>
  <div >
  <notification :message="message"></notification>
    <top-header-template>
        <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-7xl">
                <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Please accept the terms and conditions
                </h2>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-7xl mb-1 mx-6">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <p class="text-red-600" v-if="error" v-text="error"></p>
                <form action="#" method="POST" @submit.prevent="onSubmit">
                    <terms-and-conditions-text></terms-and-conditions-text>
                    <div class="mt-8">
                        <input
                          type="checkbox"
                          id="email"
                          name="email"
                          v-model="acceptedTerms"
                          :class="{'is-invalid': errors.first('terms_and_conditions')}"
                        ><label for="email" class="pl-2">I accept the terms and conditions</label>
                    <br><span class="mt-2 text-sm text-red-600">{{ errors.first('terms_and_conditions') }}</span>
                    </div>
                    <div class="mt-6">
                      <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:ring-primary active:bg-primary-700 transition duration-150 ease-in-out">Accept</button>
                      </span>
                        <span class="flex justify-center mt-4">
                            <a href="#" @click.prevent="logout" class="text-gray-500 underline">Cancel</a>
                        </span>
                    </div>
                </form>
              </div>
            </div>
        </div>
    </top-header-template>
  </div>
</template>

<script>
import Notification from "../components/Notification";
import { mapActions, mapGetters } from "vuex";
import Siren from "super-siren";
import TopHeaderTemplate from "../templates/TopHeaderTemplate";
import DownloadPdf from "./DownloadPdf";
import TermsAndConditionsText from "@/components/TermsAndConditionsText";

export default {
  components: {TermsAndConditionsText, TopHeaderTemplate, Notification, DownloadPdf },

  data() {
    return {
      error: null,
      acceptedTerms: false,
      termsAndConditionsAction : false,
      showPDF: false,
      termsLink: null
    };
  },
  computed: {
    ...mapGetters({
         accessToken: "getAccessToken",
        message: "getMessage",
    })
  },
  mounted() {
    if (this.accessToken === null) {
      return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
        this.$router.push({ path: "/login" });
      });
    }else{
        let follow = Siren.Helper.follow;
        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(
            follow('client')
        ).then(res => {
            let siren = res.body;
            this.termsAndConditionsAction = siren.findActionByName('terms-and-conditions');
        })
    }
    this.termsLink = process.env.VUE_APP_API_URL + '/storage/app/terms/terms_and_conditions.pdf'
  },

  methods: {
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
            this.termsAndConditionsAction.perform({
                terms_and_conditions: this.acceptedTerms,
            }).then(res => {
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                this.$router.push('/');
                this.$router.go(0);
            }).catch(error => {
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });


        }
      });
    },
      messages(response) {
          return response.body.entities
              .filter(function (entity) {
                  return entity.rels.contains("status-messages");
              })
              .first();
      },
      logout() {
          this.$store.dispatch("logout").then(() => this.$router.push("/login"));
      },
      togglePDF()
      {
          this.showPDF = !this.showPDF
      }

  }
};
</script>
