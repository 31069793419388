<template>
    <div :key="$route.fullPath" class="h-full order-container">
<!--        <top-header></top-header>-->
        <main-template>
            <fetch-root-siren :link="link">
            <div slot-scope="{ response }" v-if="response">
                <rental-item-header :response="response"></rental-item-header>

                <div class="grid grid-cols-4 min-h-screen">
                    <div
                        class="col-span-4 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">
                        <rental-item-summary :response="response" ref="rentalSummary" @change="change"></rental-item-summary>
                        <rental-item-invoices-table :response="response"></rental-item-invoices-table>

                    </div>
                </div>
            </div>
        </fetch-root-siren>
        </main-template>
        <div v-if="hasChangesWaitingOnSave"
             class="p-3 fixed bottom-0 w-full bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
            <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                Rental Item
            </primary-button>
        </div>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import FilterablePaginatedList from '../components/FilterablePaginatedOrdersList';
    import OrderHeader from '../components/OrderHeader';
    import OrderSummary from '../components/OrderSummary';
    import OrderItems from '../components/OrderItems';
    import SelectedQuote from '../components/SelectedQuote';
    import PrimaryButton from '../components/PrimaryButton';
    import OrderUserInformation from '../components/OrderUserInformation';
    import OrderActivityFeed from '../components/OrderActivityFeed';
    import RefundRequestsReview from '../components/RefundRequestsReview';
    import MainTemplate from "../templates/MainTemplate";
    import Invoices from '../components/InvoicesTableList';
    import RentalItemSummary from "../components/RentalItemSummary";
    import RentalItemHeader from "../components/RentalItemHeader";
    import RentalItemInvoicesTable from "../components/RentalItemInvoicesTable";

    export default {
        inject: ["$validator"],
        data() {
            return {
                hasChangesWaitingOnSave: false,
                loading: false
            }
        },
        components: {
            PrimaryButton,
            FetchRootSiren,
            FilterablePaginatedList,
            RentalItemHeader,
            RentalItemSummary,
            MainTemplate,
            RentalItemInvoicesTable
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            }
        },
        methods: {
            async saveData() {
                this.loading = true;
            },
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1024px) {
        .order-container {
            background: linear-gradient(to right, white, white 50%, #f4f5f7 50%);
        }
    }
</style>
