<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link">

        <div slot-scope="{ response }">
            <loading v-if="!response"></loading>
            <template v-else>
                <page-header :logo="logo">
                    <div class="md:flex justify-between w-full items-center">
                        <div class="md:flex items-center">
                            <span @click="navigateToProcurement(response)" class="cursor-pointer">
                                Procurement
                            </span>
                            <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span class="text-secondary-darkblue">
                                Supplier Groups
                            </span>
                        </div>
                        <div class="flex items-center text-secondary-darkgrey">
                            <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter')">
                                <form-action class="action-with-fields" :action="actionWithFields[1]"
                                                  :actionKey="actionWithFields[0]"
                                             :redirectOnSuccess="true"
                                             :onSuccessPath="$router.currentRoute.fullPath"
                                ></form-action>
                            </div>
                        </div>
                    </div>
                </page-header>
                <supplier-groups-paginated-list :response="response"></supplier-groups-paginated-list>
            </template>
        </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import SupplierPaginatedList from "@/v2/paginatedLists/SuppliersPaginatedList";
    import Button from "@/v2/buttons/Button";
    import UpdateSupplierForm from "@/v2/components/forms/UpdateSupplierForm";
    import SupplierGroupsPaginatedList from "@/v2/paginatedLists/SupplierGroupsPaginatedList";
    export default {
    name: "Suppliers",
        components: {
            SupplierGroupsPaginatedList,
            SupplierPaginatedList,
            FormAction,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader,
            'a-button': Button,
            UpdateSupplierForm
        },
        data()
        {
            return {
                showCreateSupplier: false
            }
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {
            navigateTo(fullLink){
                let link = fullLink.href;
                let parts = link.split('/api');
                this.$router.push(parts[1]);
            },
            navigateToProcurement(response) {
                let selfLink = response.body.links.filter(link => link.rels.contains('procurement')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            },
        }
    }

</script>

<style scoped>

</style>
