<template>
    <paginated-list :response="response" actionBaseUrl="/holiday-requests">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:filters>
            <holiday-request-filters v-if="filterAction" :response="response" :action="filterAction" actionBaseUrl="/holiday-requests"></holiday-request-filters>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">User</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Start Date</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">End Date</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Requested Date</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Requested Days</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Project</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Unpaid</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Status</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Reason</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Actions</th>
        </template>
        <template v-slot:noItems>
            No holiday requests found.
        </template>
        <template v-slot:tableRows>
          <template v-for="entity in holidayRequests">
            <tr class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('user') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{ entity.entity.properties.get('start_date') }}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-ism text-secondary-darkgrey font-bold">
                    <div>{{entity.entity.properties.get('end_date')}}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-ism text-secondary-darkgrey font-bold">
                    <div>{{entity.entity.properties.get('requested_date')}}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('days_requested') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('project') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('is_unpaid') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('status') }}
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('reason') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold flex flex-col text-center">
                    <confirmation-action additionalButtonClasses="" v-if="entity.entity.actions.get('approve')" :action="entity.entity.actions.get('approve')"
                                              actionKey="approve"
                                              :onSuccessPath="$router.currentRoute.fullPath"
                                              button-type="positive">
                    </confirmation-action>
                  <form-action additionalButtonClasses="mt-2" v-if="entity.entity.actions.get('reject')" :action="entity.entity.actions.get('reject')"
                                    actionKey="reject"
                                    :onSuccessPath="$router.currentRoute.fullPath"
                                    buttonType="negative">

                  </form-action>
                    <confirmation-action additionalButtonClasses="mt-2" v-if="entity.entity.actions.get('delete')" :action="entity.entity.actions.get('delete')"
                                         actionKey="delete"
                                         :onSuccessPath="$router.currentRoute.fullPath"
                                         button-type="negative">
                    </confirmation-action>
                </td>
            </tr>
            <tr v-if="entity.entity.properties.get('rejection_note')" class="hover:bg-secondary-lightbacka cursor-pointer">
              <td v-if="entity.entity.properties.get('rejection_note')" colspan="10" class="bg-error text-white whitespace-nowrap py-2 pl-4 text-sm sm:pl-6 font-bold" >
                Rejection Note: {{entity.entity.properties.get('rejection_note')}}
              </td>
            </tr>
          </template>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
import HolidayRequestFilters from "@/v2/filters/HolidayRequestFilters";
export default {
    name: "HolidayRequestPaginatedList",
    components:{
      FormAction,
        ConfirmationAction,
        PaginatedList,
        StatusBadge,
        OrderTabs,
        HolidayRequestFilters
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        holidayRequests() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('holiday-request'));
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        }
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
