<template>
    <large-model v-if="mailResponse" @removeFromDisplay="removeFromDisplay()" @perform="performSendAction()" :submitting="submitting">
      <div class="container mx-auto px-4 overflow-scroll">
        <div>
          <h1 class="text-gray-300 text-center mb-10 text-bold font-bold">Email preview</h1>
          <div class="send-for-quote-header">
            <div class="email-details">
              <div class="row">
                <div class="col-md-9">
                  <div class="mb-3">
                    <div class="d-flex align-items-center">
                      <label for>To</label>
                      <div class="to-addresses d-flex align-items-center">
                        {{toValue}}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-for="(field, index) in sendAction.fields" class="mb-2">
                    <component :is="getFieldComponent(field[1].type)" :field="field[1]" v-model="formData[field[1].name]">
                    </component>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white">
            <div class="email-body">
              <div class="row align-items-center" style="height:100%; text-align:center">
                <div class="col tailwind-only" v-if="mailResponse">
                  <div v-html="mailResponse.body.properties.get('html')"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </large-model>
</template>

<script>
import LargeModel from "@/v2/components/LargeModel";
import Loading from '@/components/Loading';

export default {
  name: "QuotationRequestEmailPreview",

  data() {
    return {
      submitting: false,
        toValue:""
    }
  },

  components: {
    LargeModel,
    Loading
  },

  props: ["mailResponse", "displayLargeModel"],

  computed: {
    sendAction() {
      let action = this.mailResponse.body.actions.filter(
        action => action.name === "send-quote-request"
      ).first();
      return action;
    },
    bcc(){
      let bcc = [];
      if(this.mailResponse.body.properties.get('bcc_procurement')){
        bcc.push('Procurement');
      }
      if(this.mailResponse.body.properties.get('bcc_site_manager')){
        bcc.push('Site Manager');
      }
      return bcc;
    },
    formData(){
          if(!this.sendAction.fields){
              return {};
          }
          var payload = {};
          this.sendAction.fields.forEach(function(field){
              if (field.value !== undefined) payload[field.name] = field.value;
          });
          return payload;
      },
  },
  methods: {
    getFieldComponent(type) {
        return type + '-input';
    },
    removeFromDisplay(){
      return this.$emit('removeFromDisplay');
    },
    performSendAction(){
      this.submitting = true;
      this.sendAction.perform(this.formData).then(res => {
        this.$store.dispatch(
            "setMessage",
            this.messages(res).entity.properties.get("success")
        );
        this.submitting = false;
        this.$router.go(0);
      }).catch(error => {
        alert('There was an error processing your request.');
        this.submitting = false;
      })
    },
    messages(response) {
        return response.body.entities
            .filter(function(entity) {
            return entity.rels.contains("status-messages");
            })
            .first();
    }
  },
    created(){
      var contacts = this.mailResponse.body.properties.get('contacts')
      var email = this.mailResponse.body.properties.get('email')
      if (contacts){
          this.toValue = contacts
          if (email){
              this.toValue += ", "
          }
      }
        if (email){
            this.toValue += email
        }

    }
};
</script>
