<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <a href="/site-vehicle-entry-logs">Site Vehicle Entry Logs</a>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('check_in')['registration']}}
                        </span>
                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-1">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                    Vehicle Log Details
                                                </dt>
                                                <dd class="text-sm text-black font-bold">{{response.body.properties.get('check_in')['registration']}}</dd>
                                            </div>
                                            <div class="grid grid-cols-3 gap-4">
                                                <div>
                                                    <dt class="text-sm font-nromal text-gray-500">Time In</dt>
                                                    <dd class="text-sm font-bold text-black">
                                                        {{formatTime(response.body.properties.get('check_in')['time'])}}
                                                    </dd>

                                                </div>
                                                <div>
                                                    <dt class="text-sm font-nomal text-gray-500">Photo</dt>
                                                    <dd class="text-sm font-bold text-black">
                                                        <img :src="response.body.properties.get('check_in_photo')"></dd>
                                                </div>
                                                <div>
                                                    <dt class="text-sm font-nomal text-gray-500">Licence</dt>
                                                    <dd class="text-sm font-bold text-black">
                                                        <img :src="response.body.properties.get('licence_photo')">
                                                    </dd>
                                                </div>
                                            </div>
                                            <template v-if="response.body.properties.get('check_out')">
                                                <div class="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <dt class="text-sm font-nromal text-gray-500">Time Out</dt>
                                                        <dd class="text-sm font-bold text-black">
                                                            {{formatTime(response.body.properties.get('check_out')['time'])}}
                                                        </dd>

                                                    </div>
                                                    <div>
                                                        <dt class="text-sm font-nomal text-gray-500">Photo</dt>
                                                        <dd class="text-sm font-bold text-black">
                                                            <img :src="response.body.properties.get('check_out_photo')"></dd>
                                                    </div>
                                                </div>
                                            </template>
                                            <div v-if="response.body.properties.get('fors_grade')">
                                                <dt class="text-sm font-nromal text-gray-500">FORS Grade</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{response.body.properties.get('fors_grade')}}
                                                </dd>
                                            </div>
                                            <div v-if="response.body.properties.get('fors_clocks')">
                                                <dt class="text-sm font-nromal text-gray-500">FORS / CLOCKS</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{response.body.properties.get('fors_clocks')}}
                                                </dd>
                                            </div>
                                            <div v-if="response.body.properties.get('signature')">
                                                <dt class="text-sm font-nromal text-gray-500">Signature</dt>
                                                <dd class="text-sm font-bold text-black">
                                                <img class="w-1/2" :src="response.body.properties.get('signature')"></dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>

                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import StatusBadge from "@/components/StatusBadge";
import PageHeader from "@/v2/components/Header";
import Actions from "@/v2/components/Actions";
import dayjs from "dayjs";
export default {
    name: "VehicleLog",
    data() {
        return {
        }
    },
    components: {
        PageHeader,
        StatusBadge,
        Button,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        Actions
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        showOrderItems(){
            this.displayOrderItems = true;
        },
        formatTime(time){
            return dayjs(time).format('h:mm a');
        },
    }
}
</script>

<style scoped>

</style>
