<template>
        <span class="relative z-0 inline-flex shadow-sm rounded-full w-full">
          <button type="button" class="justify-center relative inline-flex items-center px-4 py-2 rounded-l-full text-white bg-error text-sm font-medium focus:z-10 focus:outline-none focus:ring-1  w-1/2 hover:bg-white hover:text-error border-error border-2" @click="$emit('rejectClicked')">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
              {{ rejectText }}
              <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4"></loading>
          </button>
          <button type="button" @click="approveClicked" class="-ml-px justify-center relative inline-flex items-center px-4 py-2 rounded-r-full text-white bg-green-500 text-sm font-medium focus:z-10 focus:outline-none  w-1/2 hover:bg-white hover:text-green-500 border-green-500 border-2" >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                </svg>
              {{ approveText }}
              <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4"></loading>
          </button>
        </span>

</template>

<script>
import Loading from "@/components/Loading";
export default {
    name: "ApproveButtons",
    components: {
        Loading
    },
    props: {
        approveText: {
            type: String,
        },
        rejectText: {
            type: String
        },
        loading:{
            type: Boolean,
            default: false
        }
    },
    methods: {
        approveClicked(){
            this.$emit('approveClicked');
        }
    }
}
</script>

<style scoped>

</style>
