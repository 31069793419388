<template>
    <div>
        <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
                <section class="absolute inset-y-0 pl-16 z-50 max-w-full right-0 flex">
                    <!--
                      Slide-over panel, show/hide based on slide-over state.

                      Entering: "transform transition ease-in-out duration-500 sm:duration-700"
                        From: "translate-x-full"
                        To: "translate-x-0"
                      Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
                        From: "translate-x-0"
                        To: "translate-x-full"
                    -->
                    <div class="w-screen max-w-md" v-show="display">
                        <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                            <div class="flex-1 h-0 overflow-y-auto">
                                <header class="space-y-1 py-6 px-4 bg-gray-700 sm:px-6">
                                    <div class="flex items-center justify-between space-x-3">
                                        <h2 class="text-lg leading-7 font-medium text-white">
                                            Export PI/PC
                                        </h2>
                                        <div class="h-7 flex items-center">
                                            <button aria-label="Close panel" class="text-indigo-200 hover:text-white transition ease-in-out duration-150">
                                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="text-sm leading-5 text-indigo-300">
                                            Get started by filling in the information below to create your new project.
                                        </p>
                                    </div>
                                </header>
                                <div class="flex-1 flex flex-col justify-between">
                                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                                        <div class="space-y-6 pt-6 pb-5">
                                            <div class="space-y-1">
                                                <label for="project_name" class="block text-sm font-medium leading-5 text-gray-900">
                                                    Project name
                                                </label>
                                                <div class="relative rounded-md shadow-sm">
                                                    <input type="text" id="project_name" class="form-input block w-full sm:text-sm sm:leading-5 transition ease-in-out duration-150">
                                                </div>
                                            </div>
                                            <div class="space-y-1">
                                                <label for="description" class="block text-sm font-medium leading-5 text-gray-900">
                                                    Description
                                                </label>
                                                <div class="relative rounded-md shadow-sm">
                                                    <textarea id="description" rows="4" class="form-input block w-full sm:text-sm sm:leading-5 transition ease-in-out duration-150"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="space-y-4 pt-4 pb-6">
                                            <div class="flex text-sm leading-5">
                                                <a href="#" class="group space-x-2 inline-flex items-center font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                                                    <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                                                    </svg>
                                                    <span>
                        Copy link
                      </span>
                                                </a>
                                            </div>
                                            <div class="flex text-sm leading-5">
                                                <a href="#" class="group space-x-2 inline-flex items-center text-gray-500 hover:text-gray-900 transition ease-in-out duration-150">
                                                    <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                    </svg>
                                                    <span>
                        Learn more about sharing
                      </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end">
                                <span class="inline-flex rounded-md shadow-sm">
                                  <button type="button" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                    Cancel
                                  </button>
                                </span>
                                                    <span class="inline-flex rounded-md shadow-sm">
                                  <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                    Save
                                  </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                display: false
            }
        },
    }
</script>

<style scoped>

</style>
