<template>
    <div v-if="action" >
        <slide-over style="z-index:100" v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="$emit('removeFromDisplay')" @perform="perform()">

            <component v-if="getField('supplier_id')" :is="getFieldComponent(getField('supplier_id'))" :field="getField('supplier_id')" v-model="formData[getField('supplier_id').name]">
            </component>
            <div v-show="errors.has(getField('supplier_id').name)" class="text-red-600">{{ errors.first(getField('supplier_id').name) }}</div>

            <component v-if="getField('total')" :is="getFieldComponent(getField('total'))" :field="getField('total')" v-model="formData[getField('total').name]">
            </component>
            <div v-show="errors.has(getField('total').name)" class="text-red-600">{{ errors.first(getField('total').name) }}</div>

            <component v-if="getField('currency')" :is="getFieldComponent(getField('currency'))" :field="getField('currency')" v-model="formData[getField('currency').name]">
            </component>
            <div v-show="errors.has(getField('currency').name)" class="text-red-600">{{ errors.first(getField('currency').name) }}</div>

            <component v-if="getField('pdf_file')" :is="getFieldComponent(getField('pdf_file'))" :field="getField('pdf_file')" v-model="formData[getField('pdf_file').name]">
            </component>
            <div v-show="errors.has(getField('pdf_file').name)" class="text-red-600">{{ errors.first(getField('pdf_file').name) }}</div>

            <template v-if="getField('cms_pdf_file')">
                <div class="border-2 border-red-500 p-4 pt-2 rounded-md">
                    Use CMS Quote
                    <component v-if="getField('cms_pdf_file')" :is="getFieldComponent(getField('cms_pdf_file'))" :field="getField('cms_pdf_file')" v-model="formData[getField('cms_pdf_file').name]">
                    </component>
                    <div v-if="getField('cms_pdf_file')" v-show="errors.has(getField('cms_pdf_file').name)" class="text-red-600">{{ errors.first(getField('cms_pdf_file').name) }}</div>
                    <template v-if="getField('cms_total')">
                        <component class="mt-3" v-if="getField('cms_total')" :is="getFieldComponent(getField('cms_total'))" :field="getField('cms_total')" v-model="formData[getField('cms_total').name]">
                        </component>
                        <div v-if="getField('cms_total')" v-show="errors.has(getField('cms_total').name)" class="text-red-600">{{ errors.first(getField('cms_total').name) }}</div>
                    </template>
                    <template v-if="getField('cms_total_inc_tax')">
                        <component class="mt-3" v-if="getField('cms_total_inc_tax')" :is="getFieldComponent(getField('cms_total_inc_tax'))" :field="getField('cms_total_inc_tax')" v-model="formData[getField('cms_total_inc_tax').name]">
                        </component>
                        <div v-if="getField('cms_total_inc_tax')" v-show="errors.has(getField('cms_total_inc_tax').name)" class="text-red-600">{{ errors.first(getField('cms_total_inc_tax').name) }}</div>
                    </template>
                    <template v-if="getField('is_proforma')">
                        <component v-if="getField('is_proforma')" :is="getFieldComponent(getField('is_proforma'))" :field="getField('is_proforma')" v-model="formData[getField('is_proforma').name]">
                        </component>
                        <div v-if="getField('is_proforma')" v-show="errors.has(getField('is_proforma').name)" class="text-red-600">{{ errors.first(getField('is_proforma').name) }}</div>
                    </template>
                </div>
            </template>

            <component v-if="getField('manufacturer_id')" :is="getFieldComponent(getField('manufacturer_id'))" :field="getField('manufacturer_id')" v-model="formData[getField('manufacturer_id').name]">
            </component>
            <div v-show="errors.has(getField('manufacturer_id').name)" class="text-red-600">{{ errors.first(getField('manufacturer_id').name) }}</div>

            <component v-if="getField('account_used')" :is="getFieldComponent(getField('account_used'))" :field="getField('account_used')" v-model="formData[getField('account_used').name]">
            </component>
            <div v-show="errors.has(getField('account_used').name)" class="text-red-600">{{ errors.first(getField('account_used').name) }}</div>

        </slide-over>
    </div>

</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";

export default {
    name: "UpdateQuoteForm",
    data() {
        return {
            submittingModal: false
        }
    },
    components: {
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        }
    },
}
</script>

<style scoped>

</style>
