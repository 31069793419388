<template>
    <div class="order-summary">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Contact Summary</h3>
        <div class="generic-summary p-4 bg-white" :key="key">
            <div class="grid grid-cols-3 gap-4">
                <property-or-field @input="change" :properties="properties" title="Name" :action="updateAction" fieldId="name" propertyKey="name" v-model="updateActionData['name']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Contact Number" :action="updateAction" fieldId="contact_number" propertyKey="contact_number" v-model="updateActionData['contact_number']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Contact Type" :action="updateAction" fieldId="contact_type" propertyKey="contact_type" v-model="updateActionData['contact_type']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Supplier Position" :action="updateAction" fieldId="supplier_position" propertyKey="supplier_position" v-model="updateActionData['supplier_position']"></property-or-field>
            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    export default {
        inject: ["$validator"],
        components: {
            PropertyOrField,
        },
        data() {
            return {
                key : 0
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update-supplier').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            properties(){
                return this.response.body.properties;
            }
        },
        methods: {
            update(){
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
