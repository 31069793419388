<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Request For Information</h2>

                <div class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty())"
                         class="mr-1">
                        <action-with-form class="action-with-fields" :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="false"
                                          @success="success(actionWithFields[0])"
                        ></action-with-form>
                    </div>
                    <div v-for="actionWithoutFields in response.body.actions.filter(action => action.fields.isEmpty())" class="mr-1">
                        <email-preview-action-new v-if="actionWithoutFields[0] === 'create-preview'"
                                              :action="actionWithoutFields[1]"
                                              :actionKey="actionWithoutFields[0]"></email-preview-action-new>
                        <action-with-confirmation v-else :action="actionWithoutFields[1]" :actionKey="actionWithoutFields[0]" :properties="response.body.properties"></action-with-confirmation>
                    </div>
                    <status-badge :status="response.body.properties.get('status')" :deliveryDate="scheduledDeliveryDate"></status-badge>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import Button from '../Button';
    import ActionWithConfirmation from '../ActionWithConfirmation';
    import ActionWithForm from '../ActionWithForm';
    import EmailPreviewActionNew from '../EmailPreviewActionNew';
    import StatusBadge from "../StatusBadge";

    export default {
        inject: ["$validator"],
        components: {
            'a-button': Button,
            ActionWithConfirmation,
            ActionWithForm,
            EmailPreviewActionNew,
            StatusBadge
        },
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'update-rfi')
            },
            scheduledDeliveryDate() {
                let scheduledDelivery = this.response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("scheduled_delivery");
                    })
                    .first();

                if (!scheduledDelivery) {
                    return null;
                }

                return scheduledDelivery.entity.properties.get('date');
            },
        },
        props: {
            response: {
                type: Object,
            },
        },
        methods: {
            success(key) {
                this.$router.go(0);
            }
        },
        created() {
            document.title = 'RFI - CMS';
        },
    }
</script>

<style lang="scss" scoped>

</style>
