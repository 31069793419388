<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Quality Assessments</h2>

                <div class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty())"
                         class="mr-1">
                        <a-button v-if="actionWithFields[0] === 'get-qa-template'" @click.native="goToCreateQAsPage()">Create New QA</a-button>
                        <a-button v-else-if="actionWithFields[0] === 'create-template'" @click.native="goToCreateTemplatePage()">Create New Template</a-button>
                        <action-with-form v-else :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="false"
                                          @success="success(actionWithFields[0])"
                        ></action-with-form>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import ActionWithForm from '../ActionWithForm';
    import Button from "@/components/Button";

    export default {
        inject: ["$validator"],
        components:{ActionWithForm, 'a-button': Button},
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'filter')
            }
        },
        created() {
            document.title = 'Quality Assessments - CMS';
        },
        methods: {
            goToCreateQAsPage() {
                return this.$router.push('quality-assessments/create');
            },
            goToCreateTemplatePage() {
                return this.$router.push('templates/create');
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
