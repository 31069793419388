<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <orders-header :response="response"></orders-header>
                        <div class="container mx-auto p-4 mb-8">
                            <saved-filters :response="response">
                            </saved-filters>
                            <filterable-paginated-schedule-manager-list :response="response">

                            </filterable-paginated-schedule-manager-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import SavedFilters from '../components/SavedFilters';
    import OrdersHeader from '../components/OrdersHeader';
    import MainTemplate from '../templates/MainTemplate';
    import FilterablePaginatedScheduleManagerList from "@/components/FilterablePaginatedScheduleManagerList";
    export default {
        components: {
            FilterablePaginatedScheduleManagerList,
            FetchRootSiren,
            Loading,
            SavedFilters,
            OrdersHeader,
            MainTemplate
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
