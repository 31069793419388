import { render, staticRenderFns } from "./OfficeSubmenuNav.vue?vue&type=template&id=12a599af&scoped=true&"
import script from "./OfficeSubmenuNav.vue?vue&type=script&lang=js&"
export * from "./OfficeSubmenuNav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a599af",
  null
  
)

export default component.exports