<template>
    <div>
        <div class="flex flex-col">
            <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
                    <table class="min-w-full">
                        <thead>
                        <tr>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Description
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Unit Price
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white" v-if="reoccurringInvoiceItems.size != 0">
                            <tr v-for="item in reoccurringInvoiceItems" class="border-b border-gray-200 last:border-b-0">
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    {{item.entity.properties.get('description')}}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    {{item.entity.properties.get('unit_price')}}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    <div class="flex">
                                        <form-action :action="item.entity.actions.get('update')"
                                                          actionKey="update"
                                                          :onSuccessPath="$route.path"
                                                          additionalButtonClasses="mr-3"
                                        ></form-action>
                                        <confirmation-action :action="item.entity.actions.get('delete')"
                                                                  actionKey="delete"
                                                                  :onSuccessPath="$route.path"
                                                                  buttonType="negative"
                                                                  additionalButtonClasses="mr-3"
                                        ></confirmation-action>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                        <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                No reoccurring invoice items found.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormAction from "@/v2/components/FormAction";
    import ConfirmationAction from "@/v2/components/ConfirmationAction";
    export default {
        components: {ConfirmationAction, FormAction},
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            reoccurringInvoiceItems(orderEntity) {
                let items = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("reoccurring_invoice_items");
                    });

                if(!items){
                    return null;
                }

                return items;
            }
        },
    }
</script>

<style scoped>

</style>
