<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <a href="/qa-tools">QA Trackers</a>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('name')}}
                        </span>

                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-full lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-2">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                    QA Tracker Details
                                                </dt>
                                                <dd class="text-sm text-black font-bold">{{ response.body.properties.get('name') }}</dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-normal text-gray-500">Is Pre-Check Required</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <span v-if="response.body.properties.get('pre_check_required')">Yes</span>
                                                    <span v-else>No</span>
                                                </dd>

                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <section v-if="response.body.actions.filter(action =>action.name !== 'update-tracker' && action.name !== 'get-tracker-details').count() !== 0" class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <qa-tracker-actions :response="response" :remainingActions="response.body.actions.filter(action => action.name !== 'get-tracker-details' && action.name !== 'update-priority')"></qa-tracker-actions>
                            </div>
                        </section>

                        <section class="lg:col-start-1 lg:col-span-3">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <qa-tracker-level-paginated-list ref="trackerList" :response="response" @change="change"></qa-tracker-level-paginated-list>
                            </div>
                        </section>

                    </div>

                </template>
                <div v-if="hasChangesWaitingOnSave"
                     style="right:0px;left:6rem;"
                     class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                    <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                        QA Tracker
                    </primary-button>
                </div>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import Actions from "@/v2/components/Actions";
import QaTrackerLevelPaginatedList from "@/v2/paginatedLists/QaTrackerLevelPaginatedList";
import QaTrackerActions from "@/v2/components/QaTrackerActions";
import PrimaryButton from "@/components/PrimaryButton";
export default {
    name: "QaTracker",
    data() {
        return {
            hasChangesWaitingOnSave: false,
            loading: false
        }
    },
    components: {
        PrimaryButton,
        QaTrackerLevelPaginatedList,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        QaTrackerActions
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        change() {
            this.hasChangesWaitingOnSave = true;
        },
        async saveData() {
            this.loading = true;
            this.$setLaravelValidationErrorsFromResponse('');
            this.$refs.trackerList.savePriorities().then(res => {
                this.actionSuccess()
            }).catch(error => {
                this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                this.loading = false;
            })
        },
        actionSuccess(){
            this.$store.dispatch(
                "setMessage",
                'The tracker priorities were saved successfully'
            );
            this.hasChangesWaitingOnSave = false;
            this.loading = false;
            //this.$router.go(0);
        },
    }
}
</script>

<style scoped>

</style>
