<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <span class="text-secondary-darkblue">Slip Send</span>
                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 sm:px-6 md:px-8 pt-5">
                                        Templates
                                    </div>
                                    <pay-n-send-template-paginated-list :response="response"></pay-n-send-template-paginated-list>

                                </div>
                            </section>

                        </div>
                        <section v-if="response.body.actions.filter(action =>action.name !== 'update-material').count() !== 0" class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">

                                <pending-pay-n-send-actions :response="response" :remainingActions="response.body.actions.filter(action =>action.name !== 'update-material')"></pending-pay-n-send-actions>
                            </div>
                        </section>
                    </div>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import UpdateMaterialForm from "@/v2/components/forms/UpdateMaterialForm";
import StockAdjustmentPaginatedList from "@/v2/paginatedLists/StockAdjustmentPaginatedList";
import Actions from "@/v2/components/Actions";
import SupplierPaginatedList from "@/v2/paginatedLists/SuppliersPaginatedList.vue";
import PayNSendTemplatePaginatedList from "@/v2/paginatedLists/PayNSendTemplatePaginatedList.vue";
import PendingPayNSendActions from "@/v2/components/PendingPayNSendActions.vue";
export default {
    name: "Material",
    data() {
        return {
            displayMaterialDetails: false
        }
    },
    components: {
        PendingPayNSendActions,
        PayNSendTemplatePaginatedList,
        SupplierPaginatedList,
        StockAdjustmentPaginatedList,
        UpdateMaterialForm,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        Actions
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
    }
}
</script>

<style scoped>

</style>
