<template>
    <div :key="$route.fullPath">
        <main-template>
            <template slot-scope="{logo}">
                <fetch-root-siren :linkName='linkName' :link="link" :logo="logo">
                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full">
                                <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                    Clients
                                </div>
                                <div class="flex items-center text-secondary-darkgrey">
                                    <div v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'update-client' && action.name !== 'add-new-site' && action.name !== 'add-new-user' && action.name !== 'add-new-operative' && action.name !== 'add-new-project' && action.name !== 'filter' && action.name !== 'update-log-requirements' && !action.fields.isEmpty())"
                                         class="mr-1">
                                        <create-client-form v-if="actionWithFields[0] === 'create-new-client'"
                                                            :action="actionWithFields[1]"
                                                            :actionKey="actionWithFields[0]"
                                                            :redirectOnSuccess="true"
                                                            :onSuccessPath="$router.currentRoute.fullPath"
                                        ></create-client-form>
                                        <form-action v-else :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                        ></form-action>
                                    </div>
                                </div>
                            </div>
                        </page-header>
                        <div class="container mx-auto p-4 mb-8">
                            <filterable-paginated-clients-list :response="response">
                            </filterable-paginated-clients-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import FilterablePaginatedOrdersList from '../components/FilterablePaginatedOrdersList';
    import Loading from '../components/Loading';
    import DashboardStats from '../components/Dashboard/DashboardStats';
    import SavedFilters from '../components/SavedFilters';
    import ClientsHeader from '../components/ClientsHeader';
    import FilterablePaginatedClientsList from "../components/FilterablePaginatedClientsList";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import CreateClientForm from "@/v2/components/forms/CreateClientForm";
    export default {
        components: {
            CreateClientForm,
            MainTemplate,
            FetchRootSiren,
            FilterablePaginatedOrdersList,
            Loading,
            ClientsHeader,
            FilterablePaginatedClientsList,
            PageHeader,
            FormAction
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
