<template>
    <div class="order-summary">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Rental Item Summary</h3>
        <div class="generic-summary p-4 bg-white" :key="key">
            <div class="grid grid-cols-3 gap-4">
                <property-or-field @input="change" :properties="properties" title="PO Number" :action="updateAction" fieldId="purchase_order_number" propertyKey="purchase_order_number" v-model="updateActionData['purchase_order_number']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Requested By" :action="updateAction" fieldId="requested_by_user" propertyKey="requested_by_user" v-model="updateActionData['requested_by_user']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Contract" :action="updateAction" fieldId="contract" propertyKey="contract" v-model="updateActionData['contract']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Description" :action="updateAction" fieldId="description" propertyKey="description" v-model="updateActionData['description']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Quantity" :action="updateAction" fieldId="quantity" propertyKey="quantity" v-model="updateActionData['quantity']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Unit" :action="updateAction" fieldId="unit" propertyKey="unit" v-model="updateActionData['unit']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Due Date" :action="updateAction" fieldId="due_date" propertyKey="due_date" v-model="updateActionData['due_date']"></property-or-field>
            </div>
        </div>
        <div v-if="properties.get('rental_fee')">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Rental Item Fees</h3>
        <p><span class="text-gray-500 text-xs tracking-normal">Rental fee </span>{{properties.get('rental_fee')}} <span class="text-gray-500 text-xs tracking-normal">per</span> {{properties.get('rental_fee_per')}}<br/>

            <span class="text-gray-500 text-xs tracking-normal">Pickup fee </span> {{properties.get('pickup_fee')}}<br/>
            <span class="text-gray-500 text-xs tracking-normal">Extra fee </span> {{properties.get('extra_fee')}}</p>

        <p v-if="properties.get('supplier')"><span class="text-gray-500 text-xs tracking-normal">Supplier </span> {{properties.get('supplier')}}</p>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import DownloadPdf from "../pages/DownloadPdf";
    export default {
            inject: ["$validator"],
        components: {
            DownloadPdf,
            PropertyOrField,
        },
        data() {
            return {
                key : 0,
                showInvoice: false
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            properties(){
                console.log(this.response.body.properties);
                return this.response.body.properties;
            }
        },
        methods: {
            toggleInvoice()
            {
                this.showInvoice = true
            },
            updateOrder(){
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            },
            changeDeliveryMethod(){
                this.key++;
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
