<template>
    <tr classs="bg-white">
        <template ref="newItem">
            <td class="px-3 py-4 bg-white whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
                <property-or-field ref="name" @input="changePresetName" class="mb-2"
                                   :action="newItemAction"
                                   fieldId="preset-name" propertyKey="preset-name"
                                   v-model="newItemActionData['preset-name']"></property-or-field>
                <property-or-field v-if="presetName === 'custom'" ref="name" @change="changeName" :scope="scope"
                                                :action="newItemAction"
                                                fieldId="name" propertyKey="name"
                                                v-model="newItemActionData['name']"></property-or-field>
            </td>
            <td class="px-3 py-4 bg-white whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
                <property-or-field ref="image" :scope="scope"
                                                :action="newItemAction" fieldId="image"
                                                propertyKey="name"
                                                v-model="newItemActionData['image']" :value="newItemActionData['image']"></property-or-field>
            </td>
          <td class="px-3 py-4 bg-white whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            <property-or-field ref="expiry_date" :scope="scope"
                               :action="newItemAction"
                               fieldId="expiry_date" propertyKey="expiry_date"
                               v-model="newItemActionData['expiry_date']"></property-or-field>
          </td>
            <td class="px-3 py-4 bg-white whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
                <property-or-field ref="reminder_limit" :scope="scope"
                                   :action="newItemAction"
                                   fieldId="reminder_limit" propertyKey="reminder_limit"
                                   v-model="newItemActionData['reminder_limit']"></property-or-field>
            </td>
            <td class="px-3 py-4 bg-white whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
                <property-or-field ref="front_or_back" :scope="scope"
                                   :action="newItemAction"
                                   fieldId="front_or_back" propertyKey="front_or_back"
                                   v-model="newItemActionData['front_or_back']"></property-or-field>
            </td>
            <td class="px-3 py-4 bg-white whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
                <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem();">remove</span>
            </td>
        </template>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from '../mixins/orderItemsUpdate.js';

    export default {
        inject: ["$validator"],
        mixins: [orderItemsUpdate],
        data() {
            return {
                isSuccessfullyProcessed: false,
                name: null,
                presetName: null
            }
        },
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            response: {
                type: Object,
            },
            scope: {
                type: String
            },
            keyProp: {
                type: Number
            },
            defaultValues: {
                type: Object,
                default: {}
            }
        },
        computed: {
            newItemAction() {
                return this.response.body.actions.filter(action => action.name === 'add-certificate').first();
            },
            newItemActionData() {
                if (!this.newItemAction.fields) {
                    return {};
                }
                var payload = {};
                this.newItemAction.fields.forEach(function (field) {
                    if (this.defaultValues[field.name]) {
                        payload[field.name] = this.defaultValues[field.name]
                    } else if (field.value !== undefined) {
                        payload[field.name] = field.value;
                    }
                }, this);
                return payload;
            },
        },
        methods: {
            create() {
                if (this.isSuccessfullyProcessed) {
                    return Promise.resolve();
                }
                if(this.newItemActionData['preset-name'] !== 'custom'){
                    this.newItemActionData['name'] = this.newItemActionData['preset-name'];
                }

                return this.newItemAction.perform(this.newItemActionData).then(res => {
                    this.isSuccessfullyProcessed = true;
                });
            },
            removeItem() {
                this.$emit('removeItem', this.keyProp);
            },
            changeName(value) {
                this.name = value;
                this.$emit('change');
            },
            changePresetName(value) {
                this.presetName = value;
                this.$emit('change');
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
