<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full" data-cy="orders-table">
                    <thead>
                        <tr v-if="filterAction">
                            <th colspan=1000 class="p-8  bg-gray-50">
                                <generic-table-filter :action="filterAction"
                                :response="response" :actionBaseUrl="$route.path"
                                ></generic-table-filter>
                            </th>
                        </tr>
<!--                        <tr>-->
<!--                            <th rowspan="3"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Date</th>-->
<!--                            <th rowspan="1" colspan="5"-->
<!--                                class="text-center px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Time</th>-->
<!--                            <th rowspan="2" colspan="2"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Location</th>-->
<!--                            <th rowspan="2" colspan="2"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Photo</th>-->
<!--                            <th rowspan="3" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Comment</th>-->
<!--                            <th rowspan="3" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Score</th>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <th rowspan="1" colspan="2"-->
<!--                                class="text-center px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >actual</th>-->
<!--                            <th rowspan="1" colspan="3"-->
<!--                                class="text-center px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >billable</th>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                            <th rowspan="1" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >In</th>-->
<!--                            <th rowspan="1" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Out</th>-->
<!--                            <th rowspan="1" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >In</th>-->
<!--                            <th rowspan="1" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Out</th>-->
<!--                            <th rowspan="1" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Total (h)</th>-->
<!--                            <th rowspan="1" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >In</th>-->
<!--                            <th rowspan="1" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Out</th>-->
<!--                            <th rowspan="1" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >In</th>-->
<!--                            <th rowspan="1" colspan="1"-->
<!--                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"-->

<!--                            >Out</th>-->
<!--                        </tr>-->


                        <tr>
                        <th
                            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            v-for="headingValue in headingValues"
                            :key="headingValue"
                        >{{headingValue}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.response.body.properties.get('total') === 0">
                        <tr class="bg-white hover:bg-gray-100">
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                No daily reports found.
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="(companyEntity, key) in companyEntities">
                            <tr class="bg-white hover:bg-gray-100" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                <template v-for="(field, key) in getPropertiesToDisplay(companyEntity)">
                                    <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                        <template v-if="key === 'location_in' || key === 'location_out'">
                                            <location-viewer-with-icon v-if="field !== 'N/A' && field['x'] !== null && field['y'] !==null" :x="field['x']" :y="field['y']"></location-viewer-with-icon>
                                            <template v-if="field['x'] !== null && field['y'] !== null">
                                                <template v-if="field['project']">
                                                    <action-with-form v-if="key === 'location_in' && companyEntity.entity.actions.get('update-project-0')"
                                                                      :action="companyEntity.entity.actions.get('update-project-0')"
                                                                      actionKey="update-project-0"
                                                                      :onSuccessPath="$route.fullPath"
                                                                      :title="field['project']"
                                                                      :isButton="false">
                                                    </action-with-form>
                                                    <action-with-form v-if="key === 'location_out' && companyEntity.entity.actions.get('update-project-1')"
                                                                      :action="companyEntity.entity.actions.get('update-project-1')"
                                                                      actionKey="update-project-1"
                                                                      :onSuccessPath="$route.fullPath"
                                                                      :title="field['project']"
                                                                      :isButton="false">
                                                    </action-with-form>
                                                </template>
                                                <template v-else>
                                                    <action-with-form v-if="key === 'location_in' && companyEntity.entity.actions.get('update-project-0')"
                                                                      :action="companyEntity.entity.actions.get('update-project-0')"
                                                                      actionKey="update-project-0"
                                                                      :onSuccessPath="$route.fullPath"
                                                                      title="Edit"
                                                                      :isButton="false">
                                                    </action-with-form>
                                                    <action-with-form v-if="key === 'location_out' && companyEntity.entity.actions.get('update-project-1')"
                                                                      :action="companyEntity.entity.actions.get('update-project-1')"
                                                                      actionKey="update-project-1"
                                                                      :onSuccessPath="$route.fullPath"
                                                                      title="Edit"
                                                                      :isButton="false">
                                                    </action-with-form>
                                                </template>
                                            </template>
                                        </template>
                                        <template v-else-if="key === 'photo_in' || key === 'photo_out'">
                                            <photo-viewer-with-icon v-if="field !== 'N/A'" :file-name="field"></photo-viewer-with-icon>
                                        </template>
                                        <template v-else-if="key === 'comment'">
                                            {{field}}
                                            <action-with-form v-if="companyEntity.entity.actions.get('comment')"
                                                                            :action="companyEntity.entity.actions.get('comment')"
                                                                            actionKey="comment"
                                                                            :onSuccessPath="$route.fullPath">
                                            </action-with-form>
                                        </template>
<!--                                        <template v-else-if="key === 'time_billable_in'">-->
<!--                                            <template v-if="field !== 'N/A'">-->
<!--                                                <action-with-form v-if="companyEntity.entity.actions.get('update-working-hours')"-->
<!--                                                                  :action="companyEntity.entity.actions.get('update-working-hours')"-->
<!--                                                                  actionKey="update-working-hours"-->
<!--                                                                  :onSuccessPath="$route.fullPath"-->
<!--                                                                  :title="field"-->
<!--                                                                  :isButton="false">-->
<!--                                                </action-with-form>-->
<!--                                            </template>-->
<!--                                            <template v-else>-->
<!--                                                <action-with-form v-if="companyEntity.entity.actions.get('update-working-hours')"-->
<!--                                                                  :action="companyEntity.entity.actions.get('update-working-hours')"-->
<!--                                                                  actionKey="update-working-hours"-->
<!--                                                                  :onSuccessPath="$route.fullPath"-->
<!--                                                                  title="Edit"-->
<!--                                                                  :isButton="false">-->
<!--                                                </action-with-form>-->
<!--                                            </template>-->
<!--                                        </template>-->
<!--                                        <template v-else-if="key === 'time_billable_out'">-->
<!--                                            <template v-if="field !== 'N/A'">-->
<!--                                                <action-with-form v-if="companyEntity.entity.actions.get('update-working-hours')"-->
<!--                                                                  :action="companyEntity.entity.actions.get('update-working-hours')"-->
<!--                                                                  actionKey="update-working-hours"-->
<!--                                                                  :onSuccessPath="$route.fullPath"-->
<!--                                                                  :title="field"-->
<!--                                                                  :isButton="false">-->
<!--                                                </action-with-form>-->
<!--                                            </template>-->
<!--                                            <template v-else>-->
<!--                                                <action-with-form v-if="companyEntity.entity.actions.get('update-working-hours')"-->
<!--                                                                  :action="companyEntity.entity.actions.get('update-working-hours')"-->
<!--                                                                  actionKey="update-working-hours"-->
<!--                                                                  :onSuccessPath="$route.fullPath"-->
<!--                                                                  title="Edit"-->
<!--                                                                  :isButton="false">-->
<!--                                                </action-with-form>-->
<!--                                            </template>-->
<!--                                        </template>-->
                                        <template v-else-if="key === 'time_actual_in' || key === 'time_actual_out' || key === 'time_billable_in' || key === 'time_billable_out'">{{formatToTime(field)}}</template>
                                        <template v-else>{{field}}</template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <tr class="bg-white hover:bg-gray-100" :class="{'bg-gray-50': !(companyEntities.count() % 2 == 0)}">
                            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right" :colspan="headingValues.length-7">Weekly Total (h):</td>
                            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">{{getWeeklyHoursTotal()}}</td>
                            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500" :colspan="6"></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatusBadge from "./StatusBadge";
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    import LocationViewerWithIcon from "@/components/LocationViewerWithIcon";
    import PhotoViewerWithIcon from "@/components/PhotoViewerWithIcon";
    import ActionWithForm from "@/components/ActionWithForm";
    import dayjs from "dayjs";
    export default {
        components: {
            PhotoViewerWithIcon,
            StatusBadge,
            Pagination,
            GenericTableFilter,
            LocationViewerWithIcon,
            ActionWithForm
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            companyEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('timesheet-item'));
            },
            headingValues() {
                return ['Date', 'Time Actual In', 'Time Actual Out', 'Time Billable In', ' Time Billable Out', 'Time Billable total (h)', 'Location In', 'Location Out', 'Photo In', 'Photo Out', 'Comment', 'Score'];
            }
        },
        methods: {
            formatToTime(datetime){
                if (datetime == null){
                    return '';
                }
                if(!dayjs(datetime).isValid()){
                    return '';
                }
                return dayjs(datetime).format('HH:mm');
            },
            getPropertiesToDisplay(reportEntity) {
                console.log(reportEntity.entity.properties);
               return {
                    'date': reportEntity.entity.properties.get('date'),
                    'time_actual_in': reportEntity.entity.properties.get('time_actual_in'),
                    'time_actual_out': reportEntity.entity.properties.get('time_actual_out'),
                    'time_billable_in': reportEntity.entity.properties.get('time_billable_in'),
                    'time_billable_out': reportEntity.entity.properties.get('time_billable_out'),
                    'time_billable_total_hours': reportEntity.entity.properties.get('time_billable_hours_total'),
                    'location_in': reportEntity.entity.properties.get('location_in'),
                    'location_out': reportEntity.entity.properties.get('location_out'),
                    'photo_in': reportEntity.entity.properties.get('photo_in'),
                    'photo_out': reportEntity.entity.properties.get('photo_out'),
                   'comment': reportEntity.entity.properties.get('comment'),
                   'score': reportEntity.entity.properties.get('score'),
               }
            },
            navigateToItem(companyEntity) {
                let selfLink = companyEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            },
            getWeeklyHoursTotal(){
                return this.response.body.properties.get('hours_total')
            },
            getBillableTotal(){
                return this.response.body.properties.get('billable_total')
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
