<template>
    <div class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10">
        <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-out duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
        </transition>

        <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
                <div class="">
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left max-h-screen-3/4 overflow-auto">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {{title}}
                    </h3>
                    <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500">
                        {{confirmationString}}
                    </p>
                    </div>
                    <slot></slot>
                </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <span v-if="hasConfirmationAction" class="flex w-full sm:ml-3 sm:w-auto">
                        <a-button @click.native="perform()" :loading="submitting" :colourType="getButtonType()">
                            Confirm
                        </a-button>
                    </span>
                    <span class="mt-3 flex w-full sm:mt-0 sm:w-auto">
                        <a-button v-if="showCancelButton" colourType="tertiary" @click.native="removeFromDisplay()">
                            Cancel
                        </a-button>
                    </span>
                </div>
            </div>
        </transition>
        </div>
</template>

<script>
    import Button from '@/v2/buttons/Button';
    export default {
        components: {
            'a-button': Button,
        },
        props: {
            submitting:{
                type: Boolean,
                default: false
            },
            hasConfirmationAction:{
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: 'Perform action.'
            },
            confirmationString: {
                type: String,
                default: 'Are you sure?'
            },
            buttonType: {
                type: String,
                default: 'primary'
            },
            showCancelButton:{
                type: Boolean,
                default:true
            }
        },
        methods: {
            removeFromDisplay() {
                console.log('remove');
                this.$emit('removeFromDisplay');
            },
            perform(){
                this.$emit('perform');
            },
            getButtonType(){
                if(this.buttonType === 'custom'){
                    return 'primary';
                }
                return this.buttonType;
            }
        },
    }
</script>
