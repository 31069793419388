<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Rental Item</h2>
                <span class="flex items-center">
                    <approve-rental-form v-if="remaningActions.get('approve-rental')" :action="remaningActions.get('approve-rental')"
                                         actionKey="approve-rental"
                                         :potentialPO="response.body.properties.get('potential_purchase_order_number')"
                                         :redirectOnSuccess="true"
                                         :onSuccessPath="$route.path"
                    ></approve-rental-form>
                    <action-with-form v-if="remaningActions.get('reject-rental')" :action="remaningActions.get('reject-rental')"
                                      actionKey="reject-rental"
                                      :redirectOnSuccess="true"
                                      :onSuccessPath="$route.path"
                    ></action-with-form>
                    <action-with-form v-if="remaningActions.get('request-offhire')" :action="remaningActions.get('request-offhire')"
                                      actionKey="request-offhire"
                                      :redirectOnSuccess="true"
                                      :onSuccessPath="$route.path"
                    ></action-with-form>
                    <action-with-form v-if="remaningActions.get('approve-offhire')" :action="remaningActions.get('approve-offhire')"
                                      actionKey="approve-offhire"
                                      :redirectOnSuccess="true"
                                      :onSuccessPath="$route.path"
                    ></action-with-form>
                    <action-with-form v-if="remaningActions.get('complete-offhire')" :action="remaningActions.get('complete-offhire')"
                                      actionKey="complete-offhire"
                                      :redirectOnSuccess="true"
                                      :onSuccessPath="$route.path"
                    ></action-with-form>
                    <action-with-form v-if="remaningActions.get('reject-offhire')" :action="remaningActions.get('reject-offhire')"
                                      actionKey="reject-offhire"
                                      :redirectOnSuccess="true"
                                      :onSuccessPath="$route.path"
                    ></action-with-form>
                    <reconcile-order-form v-if="remaningActions.get('create-invoice')" :action="remaningActions.get('create-invoice')"
                                          actionKey="create-invoice"
                                          :redirectOnSuccess="true"
                                          :onSuccessPath="$route.path">
                    </reconcile-order-form>
                    <status-badge :status="response.body.properties.get('status')"></status-badge>
                </span>
            </div>
        </div>
    </header>
</template>

<script>
    import Button from './Button';
    import ActionWithConfirmation from './ActionWithConfirmation';
    import ActionWithForm from './ActionWithForm';
    import GoodsInNoteActionWithForm from './GoodsInNoteActionWithForm';
    import ReturnsForm from './ReturnsForm';
    import EmailPreviewAction from './EmailPreviewAction';
    import SplitOrderActionWithForm from './SplitOrderActionWithForm';
    import ReconcileOrderForm from './ReconcileOrderForm';
    import StatusBadge from "./StatusBadge";
    import SelectQuoteForm from "./SelectQuoteForm";
    import ApproveRentalForm from "./CustomForms/ApproveRentalForm";

    export default {
        inject: ["$validator"],
        components: {
            StatusBadge,
            'a-button': Button,
            ActionWithConfirmation,
            ActionWithForm,
            EmailPreviewAction,
            GoodsInNoteActionWithForm,
            ReturnsForm,
            SplitOrderActionWithForm,
            ReconcileOrderForm,
            SelectQuoteForm,
            ApproveRentalForm,
        },
        props: {
            response: {
                type: Object,
            },
        },
        created() {
            document.title = 'Rental Item - Elmech';
        },
        computed: {
            remaningActions() {
                return this.response.body.actions
                    .filter(action =>
                        action.name !== 'update'
                    );
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
