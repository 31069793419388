<template>
    <div>
        <a-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">{{action.title}}</a-button>
        <slide-over v-show="displayModal" :title="action.title" :confirmationString='`Are you sure you want to \"${action.title}\"`' @removeFromDisplay="displayModal = false" @perform="perform()">
            <template v-for="(field, index) in action.fields">
                <component :is="getFieldComponent(field[1].type)" :field="field[1]" v-model="formData[field[1].name]">
                </component>
                <div v-show="errors.has(field[1].name)" class="text-red-600">{{ errors.first(field[1].name) }}</div>
            </template>
        </slide-over>

        <quotation-request-email-preview v-if="displayLargeModel" :mailResponse="previewResponse" @removeFromDisplay="displayLargeModel = false" data-cy="email-preview"> </quotation-request-email-preview>
    </div>
</template>

<script>
    import Button from '@/v2/buttons/Button';
    import QuotationRequestEmailPreview from '@/v2/components/QuotationRequestEmailPreview';
    import SlideOver from "@/v2/components/SlideOver";
    export default {
        data() {
            return {
                displayModal: false,
                displayLargeModel: false,
                previewResponse: null
            }
        },
        components: {
            'a-button' : Button,
            QuotationRequestEmailPreview,
            SlideOver
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            additionalButtonClasses:{
                type: String
            },
            buttonType:{
                type: String
            }
        },
        computed: {
            formData(){
                if(!this.action.fields){
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(type) {
                return type + '-input';
            },
            perform() {
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.displayLargeModel = true;
                    this.previewResponse = res;
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                    // this.displayModal = false;
                });
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
