<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <rfis-header :response="response"></rfis-header>
                        <div class="container mx-auto p-4 mb-8">
                            <filterable-paginated-rfis-list :response="response">
                            </filterable-paginated-rfis-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import RFISHeader from "../components/RFISHeader";
    import RefundRequestItems from "../components/RefundRequestItems";
    import RefundRequestedByDetails from "../components/RefundRequestedByDetails";
    import MainTemplate from "../templates/MainTemplate";
    import FilterablePaginatedRFIsList from "../components/FilterablePaginatedRFIsList";

    export default {
        components: {
            MainTemplate,
            'rfis-header' : RFISHeader,
            FetchRootSiren,
            Loading,
            RefundRequestItems,
            RefundRequestedByDetails,
            'filterable-paginated-rfis-list' : FilterablePaginatedRFIsList
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },

        },
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1024px) {
        .order-container {
            background: linear-gradient(to right, white, white 50%, #f4f5f7 50%);
        }
    }
</style>
