<template>
    <div class="gap-4 m-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">{{response.body.properties.get('name')}}</h3>
            <div class="generic-summary p-4">
                <div class="flex flex-col">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow overflow-hidden border-b border-gray-200">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <template v-for="(qa_tracker_column_name,key) in Object.keys(response.body.properties.get('qa_tracker')[0]).splice(1)">
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{{qa_tracker_column_name}} </th>
                                            </template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(qa_trackers,key) in response.body.properties.get('qa_tracker')">
                                            <tr class="bg-white" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{qa_trackers.name}}</td>
                                                <template v-for="(qa_tracker_column_name,index) in Object.keys(response.body.properties.get('qa_tracker')[0]).splice(2)">
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        <template v-if="qa_trackers[qa_tracker_column_name] === 'created'">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                        </template>
                                                        <template v-else-if="qa_trackers[qa_tracker_column_name] === 'signed'">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                            </svg>
                                                        </template>
                                                        <template v-else-if="qa_trackers[qa_tracker_column_name] === 'approved'">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        </template>
                                                        <template v-else>
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </template>
                                                    </td>
                                                </template>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import ActionWithConfirmation from "../ActionWithConfirmation";
import Siren from "super-siren";
import {mapGetters} from "vuex";
export default {
    components:{ActionWithConfirmation},
    props: {
        response: {
            type: Object
        },
    },
    data() {
        return {
        }
    },
    computed: {

    },
    methods: {
    },

}
</script>

<style scoped>

</style>
