<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <certificates-header :response="response"></certificates-header>
                        <div class="container mx-auto p-4 mb-8">
                            <certificates class="mt-4 certificate-page" :response="response" ref="certificates" @change="change"></certificates>
                        </div>
                    </template>
                    <div v-if="hasChangesWaitingOnSave"
                         style="right:0px;left:16rem;"
                         class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                        <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                            Certificates
                        </primary-button>
                    </div>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import DashboardStats from '../../components/Dashboard/DashboardStats';
    import SavedFilters from '../../components/SavedFilters';
    import MainTemplate from "../../templates/MainTemplate";
    import CertificatesHeader from "@/components/Headers/CertificatesHeader";
    import Certificates from "@/components/Certificates";
    import PrimaryButton from "@/components/PrimaryButton";
    export default {
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            CertificatesHeader,
            Certificates,
            PrimaryButton
        },
        data() {
            return {
                hasChangesWaitingOnSave: false,
                loading:false
            }
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            async saveData() {
                this.loading = true;
                this.$refs.certificates.updateCertificates().then(res => {
                    this.actionSuccess();
                }).catch(error => {
                    if(error.error.status !== 422) {
                        alert('There was an error processing your request.' + error.error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.loading = false;
                });
            },
            actionSuccess(){
                this.$store.dispatch(
                    "setMessage",
                    'The certificates were saved successfully'
                );
                this.hasChangesWaitingOnSave = false;
                this.loading = false;
                this.$router.go(0);
            }
        }
    }
</script>

<style lang="scss">
.certificate-page.certificate-items .certificate-header {
    display:none
}
</style>
