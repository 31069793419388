
<template>
    <div :key="$route.fullPath">
        <main-template>
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link" :logo="logo">
                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <daily-report-header :response="response" :logo="logo"></daily-report-header>
<!--                        <daily-report-header class="bg-red-500" :response="response"></daily-report-header>-->
                        <div class="p-4">
    <!--                        <saved-filters :response="response">-->
    <!--                        </saved-filters>-->

                            <div >
                                <oss-tabs></oss-tabs>
                                <daily-report-action :response="response"></daily-report-action>
                            <header class="mt-8 relative flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
                                <div>
                                    <h1 class="text-lg font-semibold leading-6 text-gray-900">
                                        <time class="sm:inline">{{currentDate()}}</time>
                                    </h1>
                                    <p class="mt-1 text-sm text-gray-500">{{currentDay()}}</p>
                                </div>
                                <div class="flex items-center">
                                    <div class="flex items-center rounded-md shadow-sm md:items-stretch">
                                        <button @click="getPrevDate()" type="button" class="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                                            <span class="sr-only">Previous month</span>
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                        <DatePicker v-model="date" :max-date="new Date()" @input="changeDay" :class="{'rounded-r-md border-r' : isCurrentPageToday()}" class="hidden border-t border-b border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block cursor-pointer flex items-center">
                                            <template #default="{ togglePopover }" >
                                                <button type="button" @click="togglePopover" class="h-full w-full px-3.5" >
                                                    {{getCurrentPageName()}}
                                                </button>
                                            </template>
                                        </DatePicker>

                                        <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden"></span>
                                        <button v-if="!isCurrentPageToday()" @click="getNextDate()" type="button" class="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                                            <span class="sr-only">Next month</span>
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </header>
                                <div class="mb-8">
                                    <daily-reports-paginated-list :response="response"></daily-reports-paginated-list>
                                </div>

                            </div>

                        </div>



                    </template>
                </div>
            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>
<script>
    import PageHeader from "@/v2/components/Header";
    import FetchRootSiren from '@/components/FetchSirenRoot';
    import Loading from '@/components/Loading';
    import MainTemplate from "@/v2/templates/MainTemplate";
    import FilterablePaginatedDailyReportsList from "@/components/FilterablePaginatedDailyReportsList";
    import moment from "moment";
    import DailyReportHeader from "@/v2/components/headers/DailyReportHeader";
    import DailyReportsPaginatedList from "@/v2/paginatedLists/DailyReportsPaginatedList";
    import OssTabs from "@/v2/tabs/OssTabs";
    import DailyReportAction from "@/components/DailyReportAction";

    import { Calendar, DatePicker } from 'v-calendar';
    export default {
        data(){
          return {
              organisationId: null,
              date: new Date()
          }
        },
        components: {
            DailyReportsPaginatedList,
            DailyReportHeader,
            FilterablePaginatedDailyReportsList,
            MainTemplate,
            FetchRootSiren,
            Loading,
            PageHeader,
            OssTabs,
            DailyReportAction,
            Calendar,
            DatePicker
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
        mounted() {
            this.date = this.getCurrentDate();
        },
        methods:{
            getPrevDate(){
                let prevDate = this.getCurrentDate();
                prevDate.setDate(prevDate.getDate() - 1);
                let projectId = null;
                let userId = null;
                if(this.$route.query['project_id'] !== undefined){
                    projectId = this.$route.query['project_id'];
                }
                if(this.$route.query['user_id'] !== undefined){
                    userId = this.$route.query['user_id'];
                }
                let showDeactivatedUsersQuery = '';
                if(this.$route.query['show_deactivated_users'] !== null && this.$route.query['show_deactivated_users'] !== undefined){
                    showDeactivatedUsersQuery = '&show_deactivated_users='+ this.$route.query['show_deactivated_users'];
                }
                this.$router.push(this.$route.path + '?date=' + moment(prevDate).format('YYYY-MM-DD') + '&project_id=' + projectId + '&user_id=' + userId + '&organisation_id=' + this.$route.query['organisation_id'] + showDeactivatedUsersQuery);
                return this.$route.path + '?date=' + moment(prevDate).format('YYYY-MM-DD') + '&project_id=' + projectId + '&user_id=' + userId + '&organisation_id=' + this.$route.query['organisation_id'] + showDeactivatedUsersQuery
            },
            getNextDate(){
                let nextDate = this.getCurrentDate();
                nextDate.setDate(nextDate.getDate() + 1);
                if(nextDate > new Date()){
                    return;
                }
                let projectId = null;
                let userId = null;
                if(this.$route.query['project_id'] !== undefined){
                    projectId = this.$route.query['project_id'];
                }
                if(this.$route.query['user_id'] !== undefined){
                    userId = this.$route.query['user_id'];
                }
                let showDeactivatedUsersQuery = '';
                if(this.$route.query['show_deactivated_users'] !== null && this.$route.query['show_deactivated_users'] !== undefined){
                    showDeactivatedUsersQuery = '&show_deactivated_users='+ this.$route.query['show_deactivated_users'];
                }
                this.$router.push(this.$route.path + '?date=' + moment(nextDate).format('YYYY-MM-DD') + '&project_id=' + projectId + '&user_id=' + userId + '&organisation_id=' + this.$route.query['organisation_id'] + showDeactivatedUsersQuery);
            },
            goToToday(){
                let today = new Date();
                this.date = today;
                let projectId = null;
                let userId = null;
                if(this.$route.query['project_id'] !== undefined){
                    projectId = this.$route.query['project_id'];
                }
                if(this.$route.query['user_id'] !== undefined){
                    userId = this.$route.query['user_id'];
                }
                let showDeactivatedUsersQuery = '';
                if(this.$route.query['show_deactivated_users'] !== null && this.$route.query['show_deactivated_users'] !== undefined){
                    showDeactivatedUsersQuery = '&show_deactivated_users='+ this.$route.query['show_deactivated_users'];
                }
                this.$router.push(this.$route.path + '?date=' + moment(today).format('YYYY-MM-DD') + '&project_id=' + projectId + '&user_id=' + userId + '&organisation_id=' + this.$route.query['organisation_id'] + showDeactivatedUsersQuery);
            },
            isCurrentPageToday(){
                let nextDate = this.getCurrentDate();
                nextDate.setDate(nextDate.getDate() + 1);
                if(nextDate > new Date()){
                    return true;
                }
                return false;
            },
            currentDate(){
                let currentDate = this.getCurrentDate();
                return moment(currentDate).format('D MMMM YYYY');
            },
            currentDay(){
                let currentDate = this.getCurrentDate();
                return moment(currentDate).format('dddd');
            },
            getCurrentPageName(){
                let currentDate = this.getCurrentDate();
                if(this.isCurrentPageToday()){
                    return 'Today';
                }
                return moment(currentDate).format('Do MMM YYYY');
            },
            getCurrentDate(){
                let date = this.$route.query.date;
                let currentDate = new Date(date);
                if(this.$route.query.date === undefined || this.$route.query.date === 'null' || this.$route.query.date === null) {
                    date = null;
                    currentDate = new Date();
                }
                return currentDate;
            },
            changeDay(date){
                let projectId = null;
                let userId = null;
                if(this.$route.query['project_id'] !== undefined){
                    projectId = this.$route.query['project_id'];
                }
                if(this.$route.query['user_id'] !== undefined){
                    userId = this.$route.query['user_id'];
                }
                let showDeactivatedUsersQuery = '';
                console.log(this.$route.query['show_deactivated_users']);
                if(this.$route.query['show_deactivated_users'] !== null && this.$route.query['show_deactivated_users'] !== undefined){
                    showDeactivatedUsersQuery = '&show_deactivated_users='+ this.$route.query['show_deactivated_users'];
                }
                this.$router.push(this.$route.path + '?date=' + moment(date).format('YYYY-MM-DD') + '&project_id=' + projectId + '&user_id=' + userId + '&organisation_id=' + this.$route.query['organisation_id'] + showDeactivatedUsersQuery);
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
