<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

        <div slot-scope="{ response, logo }">
            <loading v-if="!response"></loading>
            <template v-else>
                <page-header :logo="logo">
                    <div class="md:flex justify-between w-full items-center">
                        Leave Requests
                        <div class="flex items-center text-secondary-darkgrey">
                            <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter')">
                                <create-holiday-request-form v-if="actionWithFields[0] === 'request-holiday'"
                                                             class="action-with-fields" :action="actionWithFields[1]"
                                             :actionKey="actionWithFields[0]"
                                             :redirectOnSuccess="true"
                                             :onSuccessPath="$router.currentRoute.fullPath"
                                ></create-holiday-request-form>
                                <form-action v-else
                                             class="action-with-fields" :action="actionWithFields[1]"
                                             :actionKey="actionWithFields[0]"
                                             :redirectOnSuccess="true"
                                             :onSuccessPath="$router.currentRoute.fullPath"
                                ></form-action>
                            </div>
                        </div>
                    </div>
                </page-header>

                <holiday-request-paginated-list :response="response"></holiday-request-paginated-list>
            </template>
        </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import HolidayRequestPaginatedList from "@/v2/paginatedLists/HolidayRequestPaginatedList";
    import CreateHolidayRequestForm from "@/v2/components/forms/CreateHolidayRequestForm";
    export default {
    name: "HolidayRequests",
        components: {
            CreateHolidayRequestForm,
            HolidayRequestPaginatedList,
            FormAction,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {

        }
    }

</script>

<style scoped>

</style>
