<template>
    <div v-if="action">
        <p class="block text-sm font-medium leading-5 text-gray-700 mt-3">Questions</p>

        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="flex flex-col questions-box">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Question
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Confirmed
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Confirmation Required
                                    </th>
                                </tr>
                                </thead>
                                <tbody v-if="questions.length > 0">
                                <tr  v-for="(question, key) in questions" class="bg-white"><!-- bg-gray-50 -->
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {{question.title}}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="true" v-model="confirmedQuestions[question.value].checked">
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <svg v-if="question.required" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                        No questions found
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div v-show="errors.has(validationFieldName)" class="text-red-600">{{ errors.first(validationFieldName) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["$validator"],
    data() {
        return {
            confirmedQuestions: [],
            qtys: []
        }
    },
    props: {
        action: {
            type: Object,
        },
        fieldId: {
            type: String
        },
        scope: {
            type: String,
            default: null
        },
    },
    computed: {
        questions() {
            let answers =  this.action.fields.get('answers').options;
            console.log(answers);
                let that = this;
            answers.forEach(function(answer){
                let checked = false;
                if(answer.checked){
                    checked = true;
                }
                let quantity = answer.default_quantity;
                if(answer.quantity){
                    quantity = answer.quantity;
                }
                that.confirmedQuestions.push({'checked': checked, 'quantity':quantity});
            })
            return answers;

        },
        validationFieldName() {
            if (!this.scope) {
                return this.fieldId;
            }

            return this.scope + '.' + this.fieldId;
        },
        quantityField() {
            return {'title':'Quantity', 'validation':null}
        },
    },
    methods: {

    },
    watch: {
        confirmedQuestions: {
            handler(newValue, oldValue) {
                this.$emit("input", JSON.stringify(newValue));
            },
            deep: true
        }
    },
}
</script>

<style>
.questions-box label{
    display:none
}
</style>
