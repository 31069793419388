<template>
    <div class="bg-gray-200 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
                <h4 class="uppercase text-xs text-gray-400" >Project &amp; Contract Info</h4>

                <p class="text-sm">{{orderProject.entity.properties.get('name')}} - {{orderSite.entity.properties.get('name')}}</p>
                <p v-if="orderSite.entity.properties.get('formattedAddress')" class="text-gray-500 text-xs mb-2" v-html="orderSite.entity.properties.get('formattedAddress')"></p>
                <p v-else class="text-gray-500 text-xs mb-2" v-html="orderProject.entity.properties.get('formattedAddress')"></p>

                <h4 data-v-557595cc="" class="uppercase text-xs text-gray-400">Contact Info</h4>
                <h4 class="text-sm">{{orderUser.entity.properties.get('name')}}</h4>
                <span class="text-gray-500 text-xs flex">{{orderUser.entity.properties.get('contact_number') ? orderUser.entity.properties.get('contact_number') + ' / ' : ''}}  {{orderUser.entity.properties.get('email')}}</span>
            </div>
    </div>
</template>

<script>
    export default {
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            orderUser(orderEntity) {
                let orderUser = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("user");
                    })
                    .first();

                if(!orderUser){
                    return null;
                }

                return orderUser;
            },
            orderSite(orderEntity) {
                let orderSite = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("site");
                    })
                    .first();

                if(!orderSite){
                    return null;
                }

                return orderSite;
            },
            orderProject(orderEntity) {
                let orderProject = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("project");
                    })
                    .first();

                if(!orderProject){
                    return null;
                }

                return orderProject;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
