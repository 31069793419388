<template>
    <div v-if="action" class="bg-white overflow-hidden shadow rounded-lg md:w-2/5 mx-auto md:mt-24 reports-form">
        <div class="bg-gray-50 px-4 py-4 sm:px-6">
            <a-button @click.native="saveData" :loading="loading">Export Orders</a-button>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from "@/components/PropertyOrField";
    import fileDownload from "js-file-download";
    import Button from "@/v2/buttons/Button";
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    let follow = Siren.Helper.follow;
    export default {
        data() {
            return {
                loading:false,
                action: null
            }
        },
        components: {
            PropertyOrField,
            'a-button': Button
        },
        computed: {
            actionData(){
                var that = this;
                if(!this.action){
                    return {};
                }
                if(!this.action.fields){
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        },
        mounted () {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow("orders"))
                .then(res => {
                    let siren = res.body;
                    this.action = siren.actions.filter(action => action.name === 'get-report').get('get-report');
                }, this);

        },
        methods: {
            change(){
                this.$emit('change');
            },
            update(){
                return this.action.perform(this.actionData);
            },
            async saveData() {
                this.loading = true;
                this.update().then(res => {
                    if(res.status === 204){
                        alert('No matching orders were found.');
                        this.loading = false;
                        return;
                    }
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                    }
                    this.$store.dispatch(
                        "setMessage",
                        'The export was processed successfully'
                    );
                    this.loading = false;
                }).catch(error => {
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
        },
    }
</script>

<style>
    .reports-form label.block{display:none;}
    .reports-form .form-group label{display:block;}
</style>
