<template>
  <div >
  <notification :message="message"></notification>
    <on-site-id-top-header-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo" @responseLoaded="dataLoaded">

                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <div v-else>
                        <div v-if="response.body.properties.get('name')" class="container mx-auto px-4 sm:px-6 lg:px-8">
                            <div class="bg-secondary-appdarkblue opacity-75 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full">
                                <template v-if="getCscsCard(response)">
                                    <div class="w-full pb-4" v-if="getCscsCard(response).entity.properties.get('full_url')">
                                        <img class="mx-auto" :src="getCscsCard(response).entity.properties.get('full_url')"/>
                                    </div>
                                </template>
                                <div class="mx-auto container">
                                    <dt class="text-base font-extrabold text-white">Name</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('name')}}
                                    </dd>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('date_of_birth')">
                                    <dt class="text-base font-bold text-white">Date Of Birth</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('date_of_birth')}}
                                    </dd>
                                </div>
                            </div>
                            <div class="bg-secondary-appdarkblue opacity-70 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full"
                            v-if="response.body.properties.get('medical_conditions') || response.body.properties.get('blood_type') || response.body.properties.get('allergies') || response.body.properties.get('need_to_know_information')">
                                <div class="mx-auto container">
                                    <dt class="text-xl text-center font-bold text-white">Emergency Information</dt>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('medical_conditions')">
                                    <dt class="text-base font-bold text-white">Medical Conditions</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('medical_conditions')}}
                                    </dd>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('blood_type')">
                                    <dt class="text-base font-bold text-white">Blood Type</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('blood_type')}}
                                    </dd>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('allergies')">
                                    <dt class="text-base font-bold text-white">Allergies</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('allergies')}}
                                    </dd>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('need_to_know_information')">
                                    <dt class="text-base font-bold text-white">Need To Know Information</dt>
                                    <dd class="text-base font-normal text-gray-300">
                                        {{response.body.properties.get('need_to_know_information')}}
                                    </dd>
                                </div>
                            </div>

                            <div class="bg-secondary-appdarkblue opacity-70 rounded-xl mt-8 max-h-100 p-4 border-2 border-secondary-appdarkblue w-full"
                                 v-if="response.body.properties.get('employer_name')">
                                <div class="mx-auto container">
                                    <dt class="text-xl text-center font-bold text-white">Employer</dt>
                                </div>
                                <div class="pt-4 mx-auto container" v-if="response.body.properties.get('employer_name')">
                                    <dt class="text-base font-bold text-white">On Site For {{response.body.properties.get('employer_name')}}</dt>
                                    <div class="text-center w-full mt-2"><img :src="getLogo(response)"></div>
                                </div>

                            </div>
                        </div>
                        <div v-else-if="response.body.properties.get('status')" class="container mx-auto px-4 sm:px-6 lg:px-8">
                                <div class="bg-red-100 rounded-xl mt-8 max-h-100 p-4 border-2 border-gray-400 w-full opacity-70">
                                    <div class="font-extrabold text-center" v-if="response.body.properties.get('status') === 'not-found'">
                                        This on site id does not exist
                                    </div>
                                    <div v-else-if="response.body.properties.get('status') === 'no-longer-valid'" class="font-extrabold text-center">
                                        This on site id has been replaced with a new one by the user and is no longer valid.
                                    </div>
                                    <div v-else-if="response.body.properties.get('status') === 'not-assigned'">
                                        <div v-if="getAssignToUserAction(response)">
                                            <div>This on site id has not been assigned to a user, to assign it to your account please click the button below.</div>
                                            <assign-on-site-id-action
                                                :action="getAssignToUserAction(response)"
                                                :action-key="getAssignToUserAction(response).name"
                                                :redirect-on-success="true"
                                                additional-button-classes="mt-4"
                                                :on-success-path="$router.currentRoute.fullPath"
                                            ></assign-on-site-id-action>
                                        </div>
                                    </div>
                                </div>
                        </div>

                    </div>
                </div>
            </fetch-siren-root>
        </template>
    </on-site-id-top-header-template>
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import { mapActions, mapGetters } from "vuex";
import Siren from "super-siren";
import TopHeaderTemplate from "@/templates/TopHeaderTemplate.vue";
import FetchSirenRoot from "@/components/FetchSirenRoot.vue";
import Loading from "@/components/Loading.vue";
import OnSiteIdTopHeaderTemplate from "@/templates/OnSiteIdTopHeaderTemplate.vue";
import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";
import Button from "@/v2/buttons/Button.vue";
import AssignOnSiteIdAction from "@/v2/components/AssignOnSiteIdAction.vue";

export default {
  components: {
      AssignOnSiteIdAction,
      'a-button': Button,
      ConfirmationAction, OnSiteIdTopHeaderTemplate, Loading, FetchSirenRoot, TopHeaderTemplate, Notification },

  data() {
    return {
    };
  },
  computed: {
      linkName() {
          return null;
      },
      link(){
          // if(Object.keys(this.$route.query).length === 0){
          //     return '/orders';
          // }
          return this.$route.fullPath.replace('/v3','')
      },
    ...mapGetters({
         accessToken: "getAccessToken",
        message: "getMessage",
        messageWithoutTimeout: "getMessageWithoutTimeout"
    })
  },
  mounted() {
    // if (this.accessToken !== null) {
    //   Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
    //   return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
    //     this.$router.push({ path: "/" });
    //   });
    // }
  },

  methods: {
      getAssignToUserAction(response){
         return response.body.actions.filter(action => action.name === 'assign-to-user').first();
      },
      getCscsCards(response)
      {
          return response.body.entities.filter(entity => entity.entity.properties.get('name') === 'CSCS' && entity.entity.properties.get('front_or_back') === 'front' );
      },
      getCscsCard(response)
      {
          return response.body.entities.filter(entity => entity.entity.properties.get('name') === 'CSCS' && entity.entity.properties.get('front_or_back') === 'front' )

              .first();
      },
      downloadWithVueResource() {
          debugger;
          this.$http({
              method: 'get',
              url: this.url,
              headers: {Authorization: `Bearer ${this.accessToken}`}
          })
              .then(response => {
                  this.file = response.body.file;
                  this.mimeType = response.body.mime_type;
              })
              .catch((error) => {
                  if (error.status === 401) {
                      alert('Please login to download the file.');
                  } else {
                      alert('There was an error whilst downloading the file.');
                  }
              })
      },
      goToLogin(response){
          this.$router.push({name: 'OnSiteIdLogin', query: {redirect_to: this.$route.path},});
      },
      dataLoaded(response){
          if(response.body.properties.get('status') === 'not-assigned' && !this.getAssignToUserAction(response)){
              console.log(response);
              this.$store.dispatch(
                  "setMessage",
                  'This on site id has not been assigned to a user, to assign it to your account please login.'
              );
              this.goToLogin(response)
          }
      },
      getLogo(response){
          let logo = response.body.properties.get('employer_logo');
          if (logo !== null) {
              if (logo && logo.startsWith('public/')) {
                  logo = logo.replace('public/', '');
              }
              logo = process.env.VUE_APP_API_URL + '/storage/logo/' + logo
          }
          return logo;
      }
  },
};
</script>

<style>
body{
    background-color:red
}
*{
    margin:0;
}
</style>
