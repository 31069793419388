<template>
    <div>
        <a-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>

        <slide-over-with-2-buttons v-show="displayModal" :submitting="submittingModal" :submittingSecond="loadingOrderDetails" :title="action.title" :data-cy="`action-button-${action.name}`"
                                   :confirmationString='`Are you sure you want to \"${action.title}\"`'
                                   @removeFromDisplay="displayModal = false" @firstPerform="perform()" @secondPerform="secondPerform" firstButtonTitle="Generate Quote" secondButtonTitle="Generate Blank Quote" :showSecondButton="true">
            <template v-if="getField('client_id')">
                <component v-if="getField('client_id')" :is="getFieldComponent(getField('client_id'))" :field="getField('client_id')" :options="getField('client_id').options" :title="getField('client_id').title" v-model="clientId" :data-cy="'filterable'">
                </component>
                <div v-if="getField('client_id')" v-show="errors.has(getField('client_id').name)||newErrors['client_id']" class="text-red-600">{{ errors.first(getField('client_id').name) || newErrors['client_id'] }}</div>
            </template>
            <template v-if="getField('order_id')">
                <component v-if="getField('order_id')" :is="getFieldComponent(getField('order_id'))" :field="getField('order_id')" :options="getField('order_id').options" :title="getField('order_id').title" v-model="orderId" :data-cy="'filterable'"
                dependsOn="client_id" :dependsOnValue="clientId">
                </component>
                <div v-if="getField('order_id')" v-show="errors.has(getField('order_id').name) ||newErrors['order_id']" class="text-red-600">{{ errors.first(getField('order_id').name)|| newErrors['order_id'] }}</div>
            </template>
            <template v-if="getField('carriage_fee')">
                <component v-if="getField('carriage_fee')" :is="getFieldComponent(getField('carriage_fee'))" :field="getField('carriage_fee')" :title="getField('carriage_fee').title" v-model="carriageFee">
                </component>
                <div v-if="getField('carriage_fee')" v-show="errors.has(getField('carriage_fee').name) ||newErrors['carriage_fee']" class="text-red-600">{{ errors.first(getField('carriage_fee').name)|| newErrors['carriage_fee'] }}</div>
            </template>
            <template v-if="getField('quote_or_proforma')">
                <component v-if="getField('quote_or_proforma')" :is="getFieldComponent(getField('quote_or_proforma'))" :field="getField('quote_or_proforma')" :options="getField('quote_or_proforma').options" :title="getField('quote_or_proforma').title" v-model="quoteOrProforma">
                </component>
                <div v-if="getField('quote_or_proforma')" v-show="errors.has(getField('quote_or_proforma').name)||newErrors['quote_or_proforma']" class="text-red-600">{{ errors.first(getField('quote_or_proforma').name) || newErrors['quote_or_proforma'] }}</div>
            </template>

            <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </slide-over-with-2-buttons>

        <large-model class="z-50" v-show="displayQuoteGeneratorModal" :submitting="submittingQuote" :submittingSecond="submittingSecond" :title="action.title" :data-cy="`action-button-${action.name}`"
                     :confirmationString='`Are you sure you want to \"${action.title}\"`'
                     @removeFromDisplay="displayQuoteGeneratorModal = false" @perform="performGenerateQuote()" confirmButtonText="Generate Quote">
            <order-items-add-prices :response="orderResponse||response" ref="orderItems" v-model="orderPrices" :serviceFee="serviceFee"></order-items-add-prices>

        </large-model>


    </div>
</template>

<script>
import Button from '@/v2/buttons/Button';
import Modal from '@/components/Modal';
import fileDownload from "js-file-download";
import SlideOverWith2Buttons from "@/v2/components/SlideOverWith2Buttons";
import OrderItemsAddPrices from "@/components/OrderItemsAddPrices.vue";
import LargeModel from "@/v2/components/LargeModel.vue";
import Siren from "super-siren";

export default {
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            submittingSecond: false,
            showSelectCompetingQuoteButton: true,
            displayQuoteGeneratorModal: false,
            orderPrices: {},
            loadingOrderDetails: false,
            orderResponse: null,
            clientId: null,
            newErrors: {},
            submittingQuote: false,
            orderId: null,
            serviceFee: 3,
            carriageFee: 0,
            quoteOrProforma: 'quote'
        }
    },
    components: {
        LargeModel,
        OrderItemsAddPrices,
        SlideOverWith2Buttons,
        'a-button': Button,
        Modal
    },
    props: {
        action: {
            type: Object,
        },
        response:{
            type: Object
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        secondAction: {
            type: Object,
            default: null
        },
        additionalButtonClasses:{
            type: String
        },
        buttonType: {
            type: String
        }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            let that = this;
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            if(!this.submittingModal) {
                this.newErrors = {};
                this.submittingModal = true;
                this.orderResponse = null;
                this.orderPrices = [];
                this.$refs.orderItems.itemPrices = [];
                this.$refs.orderItems.$refs.newOrderItems.qty = [];
                if(this.orderId !== null) {
                    console.log(this.quoteOrProforma);
                    if(this.quoteOrProforma === null){
                        this.submittingModal = false;
                        this.newErrors = {'quote_or_proforma' : 'The quote or proforma is required.'};
                        return;
                    }
                    Siren.get(process.env.VUE_APP_API_URL + "/api/orders/" + this.orderId).then(res => {
                        this.orderResponse = res;
                        this.displayQuoteGeneratorModal = true;
                        this.submittingModal = false;
                    });
                }else{
                    this.submittingModal = false;
                    this.newErrors = {'order_id' : 'The order is required.'};
                }
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        secondPerform() {
            if(!this.loadingOrderDetails) {
                this.newErrors = {};
                if(this.clientId) {
                    if(this.quoteOrProforma === null){
                        this.submittingModal = false;
                        this.newErrors = {'quote_or_proforma' : 'The quote or proforma is required.'};
                        return;
                    }
                    let action = this.response.body.actions.filter(action => action.name === 'generate-quote').first();
                    if(action) {
                        let clientField = action.fields.filter(field => field.name === 'client_id').first();
                        if(clientField) {
                            if(clientField.options.filter(option => option.value === this.clientId) && clientField.options.filter(option => option.value === this.clientId)[0]);
                                let clientOption = clientField.options.filter(option => option.value === this.clientId)[0];
                                if(clientOption) {
                                    this.serviceFee = clientOption.service_fee;
                                }
                        }
                    }

                    this.orderResponse = null;
                    this.orderPrices = [];
                    this.$refs.orderItems.itemPrices = [];
                    this.$refs.orderItems.$refs.newOrderItems.qty = [];
                    this.loadingOrderDetails = true;
                    this.displayQuoteGeneratorModal = true;
                    this.loadingOrderDetails = false;
                }else{
                    this.submittingModal = false;
                    this.newErrors = {'client_id' : 'The client is required.'};
                }
            }

        },
        supplierChanged(value){
            if(parseInt(this.formData['cms_supplier_id']) === parseInt(value) ||
                (this.formData['immediate_payment'] !== 'no' && this.formData['immediate_payment'] !== null)){
                this.showSelectCompetingQuoteButton = false;
            }else{
                this.showSelectCompetingQuoteButton = true;
            }
        },
        immediatePaymentChanged(value){
            if(parseInt(this.formData['cms_supplier_id']) === this.formData['supplier_id'] ||
                (value !== 'no' && value !== null)){
                this.showSelectCompetingQuoteButton = false;
            }else{
                this.showSelectCompetingQuoteButton = true;
            }
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        },
        performGenerateQuote() {
            if(!this.submittingQuote) {
                this.submittingQuote = true;
                this.formData['order_prices'] = JSON.stringify(this.orderPrices);
                this.formData['order_id'] = this.orderId;
                this.formData['client_id'] = this.clientId;
                this.formData['carriage_fee'] = this.carriageFee;
                this.formData['quote_or_proforma'] = this.quoteOrProforma;
                this.action.perform(this.formData).responseType('blob').then(res => {
                    var fileURL = window.URL.createObjectURL(res.xhr.response);
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'quote.pdf');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.formData['order_prices'] = null;
                    this.submittingQuote = false;
                    this.displayQuoteGeneratorModal = false;
                    this.displayModal = false;
                }).catch(error => {
                    this.submittingQuote = false;
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
