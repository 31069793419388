<template>
    <div>
        <a-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>
      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">

            <template v-if="action.fields.get('latitude') && !locationFound">
                <div class="flex items-center">
                    <component :is="getFieldComponent(action.fields.get('latitude'))" :field="action.fields.get('latitude')" v-model="latitude">
                    </component>
                    <location-picker-with-icon class="mt-4" :x="latitude" :y="longitude" @sendLocation="saveLocation"></location-picker-with-icon>
                </div>
                <div v-show="errors.has('latitude')" class="text-red-600 text-sm">{{ errors.first('latitude') }}</div>
            </template>

            <template v-if="action.fields.get('longitude') && !locationFound">
                <div class="flex items-center">
                    <component :is="getFieldComponent(action.fields.get('longitude'))" :field="action.fields.get('longitude')" v-model="longitude">
                    </component>
                    <location-picker-with-icon class="mt-4" :x="latitude" :y="longitude" @sendLocation="saveLocation"></location-picker-with-icon>
                </div>
                <div v-show="errors.has('longitude')" class="text-red-600 text-sm">{{ errors.first('longitude') }}</div>
            </template>

            <component :is="getFieldComponent(action.fields.get('photo'))" :field="action.fields.get('photo')" v-model="formData['photo']" capture="camera">
            </component>
            <div v-show="errors.has('photo')" class="text-red-600 text-sm">{{ errors.first('photo') }}</div>

            <component :is="getFieldComponent(action.fields.get('comment'))" :field="action.fields.get('comment')" v-model="formData['comment']">
            </component>
            <div v-show="errors.has('comment')" class="text-red-600 text-sm">{{ errors.first('comment') }}</div>

            <div v-show="errors.has('items')" class="text-red-600 text-sm">{{ errors.first('items') }}</div>

        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import LocationPickerWithIcon from "@/components/LocationPickerWithIcon";

export default {
    name: "CreateClientForm",
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            latitude: null,
            longitude: null,
            locationFound: false
        }
    },
    components: {
        SlideOver,
        'a-button': Button,
        LocationPickerWithIcon
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      }
    },
    mounted() {
        if(!("geolocation" in navigator)) {
            console.log('Geolocation is not available.');
            return;
        }

        // this.gettingLocation = true;
        // get position
        navigator.geolocation.getCurrentPosition(pos => {
            // this.gettingLocation = false;
            console.log(pos)
            this.latitude = pos.coords.latitude;
            this.longitude = pos.coords.longitude;
            this.formData['latitude'] = pos.coords.latitude;
            this.formData['longitude'] = pos.coords.longitude;
            this.locationFound = true;
        }, err => {

            // this.gettingLocation = false;
            // this.errorStr = err.message;
            console.log(err.message);
        })
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.formData['latitude'] = this.latitude;
            this.formData['longitude'] = this.longitude;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else if(error.status === 403){
                    alert(error.response.body.message);
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        saveLocation(location){
            this.latitude = location.x;
            this.longitude = location.y;
            this.formData['latitude'] = location.x;
            this.formData['longitude'] = location.y;
        }
    },
}
</script>

<style scoped>

</style>
