<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <timesheet-builder-header :response="response"></timesheet-builder-header>
                        <div class="container mx-auto p-4 mb-8">
    <!--                        <saved-filters :response="response">-->
    <!--                        </saved-filters>-->
                            <timesheet-builder-action :response="response" ref="timesheetReport" @change="change"></timesheet-builder-action>

                            <filterable-paginated-timesheet-builder-list :response="response">

                            </filterable-paginated-timesheet-builder-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import CompaniesHeader from "../components/CompaniesHeader";
    import MainTemplate from "../templates/MainTemplate";
    import moment from "moment";
    import FilterablePaginatedWeeklyReportsList from "@/components/FilterablePaginatedWeeklyReportsList";
    import WeeklyReportHeader from "@/components/Headers/WeeklyReportHeader";
    import TimesheetBuilderAction from "@/components/TimesheetBuilderAction";
    import FilterablePaginatedTimesheetBuilderList from "@/components/FilterablePaginatedTimesheetBuilderList";
    import TimesheetBuilderHeader from "@/components/Headers/TimesheetBuilderHeader";

    export default {
        components: {
            TimesheetBuilderHeader,
            FilterablePaginatedTimesheetBuilderList,
            TimesheetBuilderAction,
            WeeklyReportHeader,
            MainTemplate,
            FetchRootSiren,
            Loading,
            FilterablePaginatedWeeklyReportsList
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            }
        },
        methods:{
        }
    }
</script>

<style lang="scss" scoped>

</style>
