<template>
    <nav class="mt-5 md:mt-2 flex-1 px-2 bg-primary-800 space-y-1">
        <menu-group href="/">
            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"/></svg>
            Dashboard
        </menu-group>

        <menu-group v-if="hasAnAdminLink()" :is-open="isOpen('admin')" @openSubMenu="openSection('admin')">
            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm7-7H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-1.75 9c0 .23-.02.46-.05.68l1.48 1.16c.13.11.17.3.08.45l-1.4 2.42c-.09.15-.27.21-.43.15l-1.74-.7c-.36.28-.76.51-1.18.69l-.26 1.85c-.03.17-.18.3-.35.3h-2.8c-.17 0-.32-.13-.35-.29l-.26-1.85c-.43-.18-.82-.41-1.18-.69l-1.74.7c-.16.06-.34 0-.43-.15l-1.4-2.42c-.09-.15-.05-.34.08-.45l1.48-1.16c-.03-.23-.05-.46-.05-.69s.02-.46.05-.68l-1.48-1.16c-.13-.11-.17-.3-.08-.45l1.4-2.42c.09-.15.27-.21.43-.15l1.74.7c.36-.28.76-.51 1.18-.69l.26-1.85c.03-.17.18-.3.35-.3h2.8c.17 0 .32.13.35.29l.26 1.85c.43.18.82.41 1.18.69l1.74-.7c.16-.06.34 0 .43.15l1.4 2.42c.09.15.05.34-.08.45l-1.48 1.16c.03.23.05.46.05.69z"/></svg>
            Admin
            <template v-slot:sub-menu>
<!--                <sub-menu-item v-if="hasLink('admin.clients')" :href="getLinkHref('admin.clients')">Manage Clients</sub-menu-item>-->
<!--                <sub-menu-item v-if="hasLink('admin.client')" :href="getLinkHref('admin.client')">Manage Organisation</sub-menu-item>-->
                <sub-menu-item v-if="hasAction('invite-client')" href="/invite-client">Invite Client</sub-menu-item>
                <sub-menu-item v-if="hasAction('invite-user')" href="/invite-user">Invite User</sub-menu-item>
                <!--<sub-menu-item v-if="hasLink('admin.projects')" :href="getLinkHref('admin.projects')">Manage Projects</sub-menu-item>
                <sub-menu-item v-if="hasLink('admin.contracts')" :href="getLinkHref('admin.contracts')">Manage Contracts</sub-menu-item>-->
                <sub-menu-item v-if="hasLink('admin.users')" :href="getLinkHref('admin.users')">Manage Users</sub-menu-item>
                <sub-menu-item v-if="hasLink('admin.default-client-users')" :href="getLinkHref('admin.default-client-users')">Manage New Users</sub-menu-item>
                <!--<sub-menu-item v-if="hasLink('admin.suppliers')" :href="getLinkHref('admin.suppliers')">Manage Suppliers</sub-menu-item>-->
<!--                <sub-menu-item v-if="hasLink('admin.companies')" :href="getLinkHref('admin.companies')">Manage Suppliers</sub-menu-item>-->
<!--                <sub-menu-item v-if="hasLink('admin.direct-suppliers')" :href="getLinkHref('admin.direct-suppliers')">Manage Direct Suppliers</sub-menu-item>-->
<!--                <sub-menu-item v-if="hasLink('admin.direct-supplier')" :href="getLinkHref('admin.direct-supplier')">Manage Direct Supplier</sub-menu-item>-->
<!--                <sub-menu-item v-if="hasLink('admin.manufacturers')" :href="getLinkHref('admin.manufacturers')">Manage Manufacturers</sub-menu-item>-->
<!--                <sub-menu-item v-if="hasLink('admin.products')" :href="getLinkHref('admin.products')">Manage Products</sub-menu-item>-->
<!--                <sub-menu-item v-if="hasLink('admin.product-types')" :href="getLinkHref('admin.product-types')">Manage Product Types</sub-menu-item>-->
<!--                <sub-menu-item v-if="hasLink('admin.dashboards')" :href="getLinkHref('admin.dashboards')">Manage Dashboard</sub-menu-item>-->
                <sub-menu-item v-if="hasLink('admin.dashboard-widgets')" :href="getLinkHref('admin.dashboard-widgets')">Manage Widgets</sub-menu-item>
            </template>
        </menu-group>

        <menu-group :is-open="isOpen('office')" v-if="hasOfficeLink()" @openSubMenu="openSection('office')">
            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="currentColor"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M13.03,7.06L14.09,6l1.41,1.41 L16.91,6l1.06,1.06l-1.41,1.41l1.41,1.41l-1.06,1.06L15.5,9.54l-1.41,1.41l-1.06-1.06l1.41-1.41L13.03,7.06z M6.25,7.72h5v1.5h-5 V7.72z M11.5,16h-2v2H8v-2H6v-1.5h2v-2h1.5v2h2V16z M18,17.25h-5v-1.5h5V17.25z M18,14.75h-5v-1.5h5V14.75z"/></g></svg>
            Office
            <template v-slot:sub-menu>
                <menu-group v-if="hasSagesLink()" :is-open="isOpen('sage')" @openSubMenu="openSection('sage')">
                    <span class="pl-10">Sage</span>
                    <template v-slot:sub-menu>
                        <sub-menu-item class="pl-20" v-if="hasLink('sages')" :href="getLinkHref('sages')">Tax Codes</sub-menu-item>
                        <sub-menu-item class="pl-20" v-if="hasLink('sage-descriptions')" :href="getLinkHref('sage-descriptions')">Short Descriptions</sub-menu-item>
                        <sub-menu-item class="pl-20" v-if="hasLink('sage-nominal-codes')" :href="getLinkHref('sage-nominal-codes')">Nominal Codes</sub-menu-item>
                    </template>
                </menu-group>
                <sub-menu-item class="pl-10" v-if="hasLink('invoices')" :href="getLinkHref('invoices')" :is-open="isOpen('invoices')" @openSubMenu="openSection('invoices')">
                    Sales Invoices
                </sub-menu-item>

                <sub-menu-item class="pl-10" v-if="hasLink('invoices-in')" :href="getLinkHref('invoices-in')" :is-open="isOpen('invoices-in')" @openSubMenu="openSection('invoices-in')">
                    Purchase Invoices
                </sub-menu-item>

                <sub-menu-item class="pl-10" v-if="hasLink('unapproved-invoices-in')" :href="getLinkHref('unapproved-invoices-in')" :is-open="isOpen('unapproved-invoices-in')" @openSubMenu="openSection('unapproved-invoices-in')">
                    Unapproved Purchase Invoices
                </sub-menu-item>

                <sub-menu-item class="pl-10" v-if="hasLink('credit-notes')" :href="getLinkHref('credit-notes')" :is-open="isOpen('credit-notes')" @openSubMenu="openSection('credit-notes')">
                    Credit Notes
                </sub-menu-item>

                <sub-menu-item class="pl-10" v-if="hasLink('quotes-mismatch')" href="/quotes-mismatch" :is-open="isOpen('quotes-mismatch')" @openSubMenu="openSection('quotes-mismatch')">
                    Quotes Mismatch
                </sub-menu-item>

                <sub-menu-item class="pl-10" v-if="hasLink('costing')" href="/costing" :is-open="isOpen('costing')" @openSubMenu="openSection('costing')">
                    Costing
                </sub-menu-item>

                <menu-group v-if="hasLink('reports')" :is-open="isOpen('reports')" @openSubMenu="openSection('reports')">
                    <span class="pl-10">Exports</span>
                    <template v-slot:sub-menu>
                        <sub-menu-item href="/reports">Export Orders</sub-menu-item>
                        <sub-menu-item href="/invoice-reports">Export PI/PC</sub-menu-item>
                    </template>
                </menu-group>
            </template>
        </menu-group>

        <menu-group data-cy="orders-link" v-if="hasLink('orders') || hasLink('tender-orders') || hasLink('return-requests') || hasLink('rental-items') || hasLink('take-offs') || hasLink('schedule-manager')" :is-open="isOpen('orders')" @openSubMenu="openSection('orders')">
            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M17.21 9l-4.38-6.56c-.19-.28-.51-.42-.83-.42-.32 0-.64.14-.83.43L6.79 9H2c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27L23 10c0-.55-.45-1-1-1h-4.79zM9 9l3-4.4L15 9H9zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
            Orders
            <template v-slot:sub-menu>
                <sub-menu-item class="pl-10" v-if="hasLink('create-new-order')" :href="getLinkHref('create-new-order')" :is-open="isOpen('create-new-order')">
                    New Order
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('orders')" :href="getLinkHref('orders')" :is-open="isOpen('orders')" @openSubMenu="openSection('return-requests')">
                    Order List
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('tender-orders')" :href="getLinkHref('tender-orders')" :is-open="isOpen('orders')">
                    Tender Orders
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('return-requests')" :href="getLinkHref('return-requests')" :is-open="isOpen('return-requests')" @openSubMenu="openSection('return-requests')">
                    Return Requests
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('rental-items')" :href="getLinkHref('rental-items')">
                    Rental Items
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('take-offs')" href="/take-off-orders/search" :is-open="isOpen('take-offs')" @openSubMenu="openSection('take-offs')">
                    Take Offs
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('schedule-manager')" href="/schedule-manager">
                    Schedule Manager
                </sub-menu-item>
            </template>
        </menu-group>

        <menu-group id="management-link" v-if="hasLink('RFIs') || hasLink('suppliers') || hasLink('holiday-requests')" :is-open="isOpen('management')" @openSubMenu="openSection('management')">
            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><rect fill="none" height="24" width="24"/><g><path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,17c0,0.55-0.45,1-1,1H7c-0.55,0-1-0.45-1-1l0-0.61 c0-1.18,0.68-2.26,1.76-2.73C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43L0,17 c0,0.55,0.45,1,1,1l3.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1 c0.4,0.68,0.63,1.46,0.63,2.29V18l3.5,0c0.55,0,1-0.45,1-1L24,16.43z M12,6c1.66,0,3,1.34,3,3c0,1.66-1.34,3-3,3s-3-1.34-3-3 C9,7.34,10.34,6,12,6z"/></g></svg>
            Management
            <template v-slot:sub-menu>
                <sub-menu-item class="pl-10" v-if="hasLink('RFIs')" :href="getLinkHref('RFIs')" :is-open="isOpen('rfis')" @openSubMenu="openSection('rfis')">
                    RFIs
                </sub-menu-item>

                <sub-menu-item class="pl-10" v-if="hasLink('suppliers')" :href="getLinkHref('suppliers')">
                    Address Book
                </sub-menu-item>

                <sub-menu-item class="pl-10" v-if="hasLink('holiday-requests')" :href="getLinkHref('holiday-requests')">
                    Holiday Requests
                </sub-menu-item>
            </template>
        </menu-group>

<!--        <menu-group :is-open="isOpen('oss')" @openSubMenu="openSection('oss')">-->
<!--            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><path d="M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z"/></svg>-->
<!--            OSS-->
<!--            <template v-slot:sub-menu>-->
<!--                <sub-menu-item class="pl-10" href="/oss/admin/project">Projects</sub-menu-item>-->
<!--                <sub-menu-item class="pl-10" href="/oss/admin/user">Users</sub-menu-item>-->
<!--                <sub-menu-item class="pl-10" href="/oss/admin/weekly-summary">Weekly Report</sub-menu-item>-->
<!--                <sub-menu-item class="pl-10" href="/oss/admin/daily-summary">Daily Report</sub-menu-item>-->
<!--                <sub-menu-item class="pl-10" href="/oss/admin/timesheet-builder">Timesheet Builder</sub-menu-item>-->
<!--            </template>-->
<!--        </menu-group>-->

        <menu-group id="qa-link" v-if="hasLink('quality-assessments') || hasLink('templates') || hasLink('qa-trackers')" :is-open="isOpen('quality-assessments')" @openSubMenu="openSection('quality-assessments')">
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd" />
            </svg>
            Quality Assessments
            <template v-slot:sub-menu>
                <sub-menu-item class="pl-10" v-if="hasLink('qa-trackers')" :href="getLinkHref('qa-trackers')" >
                    QA Tracker List
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('quality-assessments')" :href="getLinkHref('quality-assessments')" :is-open="isOpen('quality-assessments')" @openSubMenu="openSection('quality-assessments')">
                    QA List
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('templates')" :href="getLinkHref('templates')">
                    QA Categories List
                </sub-menu-item>
            </template>
        </menu-group>

        <menu-group id="payment-voucher-link" v-if="hasLink('payment-vouchers')" :is-open="isOpen('payment-vouchers')" @openSubMenu="openSection('payment-vouchers')">
            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><rect fill="none" height="24" width="24"/><g><path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,17c0,0.55-0.45,1-1,1H7c-0.55,0-1-0.45-1-1l0-0.61 c0-1.18,0.68-2.26,1.76-2.73C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43L0,17 c0,0.55,0.45,1,1,1l3.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1 c0.4,0.68,0.63,1.46,0.63,2.29V18l3.5,0c0.55,0,1-0.45,1-1L24,16.43z M12,6c1.66,0,3,1.34,3,3c0,1.66-1.34,3-3,3s-3-1.34-3-3 C9,7.34,10.34,6,12,6z"/></g></svg>
            Payment Vouchers
            <template v-slot:sub-menu>
                <sub-menu-item class="pl-10" v-if="hasLink('payment-vouchers')" :href="getLinkHref('payment-vouchers')" :is-open="isOpen('payment-vouchers')" @openSubMenu="openSection('quality-assessments')">
                    Payment Voucher List
                </sub-menu-item>
            </template>
        </menu-group>

        <menu-group id="inventory-link" v-if="hasAnInventoryLink()" :is-open="isOpen('inventory')" @openSubMenu="openSection('inventory')">
            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><rect fill="none" height="24" width="24"/><g><path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,17c0,0.55-0.45,1-1,1H7c-0.55,0-1-0.45-1-1l0-0.61 c0-1.18,0.68-2.26,1.76-2.73C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43L0,17 c0,0.55,0.45,1,1,1l3.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1 c0.4,0.68,0.63,1.46,0.63,2.29V18l3.5,0c0.55,0,1-0.45,1-1L24,16.43z M12,6c1.66,0,3,1.34,3,3c0,1.66-1.34,3-3,3s-3-1.34-3-3 C9,7.34,10.34,6,12,6z"/></g></svg>
            Inventory
            <template v-slot:sub-menu>
                <sub-menu-item class="pl-10" v-if="hasLink('materials')" :href="getLinkHref('materials')" >
                    Materials
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('office-items')" :href="getLinkHref('office-items')" >
                    Office Items
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('vehicles')" :href="getLinkHref('vehicles')" >
                    Vehicles
                </sub-menu-item>
            </template>
        </menu-group>


        <menu-group id="oss" v-if="hasLink('oss-daily-report') || hasLink('oss-weekly-report') || hasLink('oss-timesheet-builder')" :is-open="isOpen('oss')" @openSubMenu="openSection('oss')">
            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><rect fill="none" height="24" width="24"/><g><path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,17c0,0.55-0.45,1-1,1H7c-0.55,0-1-0.45-1-1l0-0.61 c0-1.18,0.68-2.26,1.76-2.73C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43L0,17 c0,0.55,0.45,1,1,1l3.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1 c0.4,0.68,0.63,1.46,0.63,2.29V18l3.5,0c0.55,0,1-0.45,1-1L24,16.43z M12,6c1.66,0,3,1.34,3,3c0,1.66-1.34,3-3,3s-3-1.34-3-3 C9,7.34,10.34,6,12,6z"/></g></svg>
            On-site Selfie
            <template v-slot:sub-menu>
                <sub-menu-item class="pl-10" v-if="hasLink('oss-daily-report')" :href="getLinkHref('oss-daily-report')" :is-open="isOpen('oss')" @openSubMenu="openSection('oss')">
                    Daily Report
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('oss-weekly-report')" :href="getLinkHref('oss-weekly-report')" :is-open="isOpen('oss')" @openSubMenu="openSection('oss')">
                    Weekly Report
                </sub-menu-item>
                <sub-menu-item class="pl-10" v-if="hasLink('oss-timesheet-builder')" :href="getLinkHref('oss-timesheet-builder')" :is-open="isOpen('oss')" @openSubMenu="openSection('oss')">
                    Timesheet Builder
                </sub-menu-item>
            </template>

        </menu-group>

        <menu-group v-if="canAccessHelpMenu()" :is-open="isOpen('help-hub')" @openSubMenu="openSection('help-hub')" data-dusk="help-hub">
            <svg class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            Help Hub
            <template v-slot:sub-menu>
                <sub-menu-item v-if="hasLink('cms-help-hub')" class="pl-10" :href="getLinkHref('cms-help-hub')">CMS Hub</sub-menu-item>
                <sub-menu-item v-if="hasLink('oss-help-hub')" class="pl-10" :href="getLinkHref('oss-help-hub')">On-site Selfie</sub-menu-item>
                <sub-menu-item v-if="hasLink('rav-help-hub')" class="pl-10" :href="getLinkHref('rav-help-hub')">RAV System</sub-menu-item>
            </template>
        </menu-group>
    </nav>
</template>

<script>
    import SubMenuItem from "./SubMenuItem";
    import MenuGroup from "./MenuGroup";
    export default {
        components: {
            MenuGroup,
            SubMenuItem
        },
        data() {
            return {
                openSections: []
            }
        },
        props: {
            links: {
                type: Object,
                default: () => {}
            },
            actions: {
                type: Object,
                default: () => {}
            },
            ravAccess:{
                type: Boolean,
                default: false
            },
            ossAccess:{
                type: Boolean,
                default: false
            },
            cmsHelpAccess:{
                type: Boolean,
                default:false
            },
            ossHelpAccess:{
                type: Boolean,
                default:false
            },
            ravHelpAccess:{
                type: Boolean,
                default:false
            }
        },
        methods: {
            openSection(key) {
                if(this.isOpen(key)){
                    const index = this.openSections.indexOf(key);
                    this.openSections.splice(index);
                }else {
                    this.openSections.push(key);
                }
            },
            isOpen(key){
                return this.openSections.includes(key);
            },
            hasLink(key){
                return this.links.filter(link => link.rels.contains(key)).size;
            },
            hasAction(key){
                return this.actions.filter(action => action.name === key).size;
            },
            hasAnAdminLink(){
                return this.hasLink('admin.clients') || this.hasLink('admin.client') || this.hasLink('admin.projects') || this.hasLink('admin.contracts') || this.hasLink('admin.users') || this.hasLink('admin.suppliers') || this.hasLink('admin.companies') || this.hasLink('admin.products')|| this.hasLink('admin.product-types') || this.hasLink('admin.direct-suppliers') || this.hasLink('admin.direct-supplier') || this.hasAction('invite-user') || this.hasAction('invite-client');
            },
            hasSagesLink() {
                return this.hasLink('sages') || this.hasLink('sage-descriptions') || this.hasLink('sage-nominal-codes');
            },
            getLinkHref(key){
                return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
            },
            canAccessHelpMenu(){
                return this.hasLink('cms-help-hub') || this.hasLink('oss-help-hub') || this.hasLink('rav-help-hub')
            },
            hasOfficeLink(){
                return this.hasSagesLink() || this.hasLink('invoices') || this.hasLink('invoices-in') || this.hasLink('unapproved-invoices-in') || this.hasLink('credit-notes') || this.hasLink('reports') || this.hasLink('quotes-mismatch') || this.hasLink('costing');
            },
            hasAnInventoryLink(){
                return this.hasLink('materials') || this.hasLink('office-items') || this.hasLink('vehicles')
            }
        },
    }
</script>

<style scoped>

</style>
