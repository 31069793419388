<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <weekly-report-header :response="response"></weekly-report-header>
                        <div class="container mx-auto p-4 mb-8">
    <!--                        <saved-filters :response="response">-->
    <!--                        </saved-filters>-->
                            <weekly-report-action :response="response" ref="weeklyReport" @change="change"></weekly-report-action>

                            <filterable-paginated-weekly-reports-list :response="response">

                            </filterable-paginated-weekly-reports-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import CompaniesHeader from "../components/CompaniesHeader";
    import MainTemplate from "../templates/MainTemplate";
    import moment from "moment";
    import FilterablePaginatedWeeklyReportsList from "@/components/FilterablePaginatedWeeklyReportsList";
    import WeeklyReportAction from "@/components/WeeklyReportAction";
    import WeeklyReportHeader from "@/components/Headers/WeeklyReportHeader";

    export default {
        components: {
            WeeklyReportHeader,
            WeeklyReportAction,
            MainTemplate,
            FetchRootSiren,
            Loading,
            FilterablePaginatedWeeklyReportsList
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            }
        },
        methods:{
        }
    }
</script>

<style lang="scss" scoped>

</style>
