<template>
    <div class="flex flex-col" v-if="ratings.size > 0">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Ratings</h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Rating Value
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Comment
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <template v-for="rating in ratings">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ rating.entity.properties.get('rating_value') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ rating.entity.properties.get('comment') }}
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionWithForm from "./ActionWithForm";
    export default {
        components: {
            ActionWithForm
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            ratings(orderEntity) {
                let ratings = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("rating");
                    });
                if(!ratings){
                    return null;
                }

                return ratings;
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
