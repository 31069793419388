<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full" data-cy="orders-table">
                    <thead>
                        <tr v-if="filterAction">
                            <th colspan=1000 class="p-8  bg-gray-50">
                                <generic-table-filter :action="filterAction"
                                :response="response" :actionBaseUrl="$route.path"
                                ></generic-table-filter>
                            </th>
                        </tr>
                        <tr>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                v-for="headingValue in headingValues"
                                :key="headingValue"
                            >{{headingValue}}
                                <template v-if="getDateForDay(headingValue)">
                                    <br>
                                    {{getDateForDay(headingValue)}}
                                </template>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="this.response.body.properties.get('total') === 0">
                        <tr class="bg-white hover:bg-gray-100">
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                No daily reports found.
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="(companyEntity, key) in companyEntities">
                            <tr @click="navigateToTimesheet(companyEntity)" class="bg-white hover:bg-gray-100 cursor-pointer" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                <template v-for="(field, key) in getPropertiesToDisplay(companyEntity)">
                                    <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                        <template v-if="key === 'location_in' || key === 'location_out'">
                                            <location-viewer-with-icon v-if="field !== 'N/A'" :x="field['x']" :y="field['y']"></location-viewer-with-icon>
                                        </template>
                                        <template v-else>{{field}}</template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <tr class="bg-white hover:bg-gray-100" :class="{'bg-gray-50': !(companyEntities.count() % 2 == 0)}">
                            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right" :colspan="headingValues.length-2">Grand Total (h):</td>
                            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">{{getWeeklyHoursTotal()}}</td>
                            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500"></td>
                        </tr>
                        <tr class="bg-white hover:bg-gray-100" :class="{'bg-gray-50': !(companyEntities.count() % 2 == 0)}">
                            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right" :colspan="headingValues.length-2">Grand Billable Total:</td>
                            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500"></td>
                            <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">{{getBillableTotal()}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatusBadge from "./StatusBadge";
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    import LocationViewerWithIcon from "@/components/LocationViewerWithIcon";
    export default {
        components: {
            StatusBadge,
            Pagination,
            GenericTableFilter,
            LocationViewerWithIcon
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            companyEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('weekly-report-item'));
            },
            headingValues() {
                return ['Employee', 'Sat','Sun','Mon','Tue','Wed','Thurs','Fri','WeeklyTotal (h)','Billable Total (£)'];
            }
        },
        methods: {
            getPropertiesToDisplay(reportEntity) {
               return {
                   'user': reportEntity.entity.properties.get('user'),
                   'sat': reportEntity.entity.properties.get('sat'),
                   'sun': reportEntity.entity.properties.get('sun'),
                   'mon': reportEntity.entity.properties.get('mon'),
                   'tue': reportEntity.entity.properties.get('tue'),
                   'wed': reportEntity.entity.properties.get('wed'),
                   'thurs': reportEntity.entity.properties.get('thurs'),
                   'fri': reportEntity.entity.properties.get('fri'),
                   'weeklyTotal': reportEntity.entity.properties.get('weekly_total'),
                   'billable_total': reportEntity.entity.properties.get('billable_total')
               }
            },
            navigateToItem(companyEntity) {
                let selfLink = companyEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            },
            navigateToTimesheet(ReportEntity) {
                let timesheetBuilderLink = ReportEntity.entity.links.filter(link => link.rels.contains('timesheet-builder')).first().href;
                let parts = timesheetBuilderLink.split('/api');
                this.$router.push(parts[1]);
            },
            getWeeklyHoursTotal(){
                return this.response.body.properties.get('hours_total')
            },
            getBillableTotal(){
                return this.response.body.properties.get('billable_total')
            },
            getDateForDay(day){
                console.log(this.response.body.properties.get(day.toLowerCase()));
                return this.response.body.properties.get(day.toLowerCase())
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
