<template>
    <div>
        <secondary-button @click.native="showModal = !showModal">View fees</secondary-button>
        <modal class="z-50" v-if=showModal @removeFromDisplay="showModal = false" :hasConfirmationAction="false" confirmationString="Fees"  :title="`Rental fees for ${entity.entity.properties.get('purchase_order_number')}`">
            <p><span class="text-gray-500 text-xs tracking-normal">Rental fee </span>{{entity.entity.properties.get('rental_fee')}} <span class="text-gray-500 text-xs tracking-normal">per</span> {{entity.entity.properties.get('rental_fee_per')}}<br/>

                <span class="text-gray-500 text-xs tracking-normal">Pickup fee </span> {{entity.entity.properties.get('pickup_fee')}}<br/>
                <span class="text-gray-500 text-xs tracking-normal">Extra fee </span> {{entity.entity.properties.get('extra_fee')}}</p>

           <p v-if="entity.entity.properties.get('supplier')"><span class="text-gray-500 text-xs tracking-normal">Supplier </span> {{entity.entity.properties.get('supplier')}}</p>
        </modal>
    </div>
</template>

<script>
    import Modal from "./Modal";
    import SecondaryButton from "./SecondaryButton";
    export default {
        components:{SecondaryButton, Modal},
        props: {
            entity: {
                type: Object,
            },
        },
        data() {
            return {
                showModal: false
            }
        },
    }
</script>

<style scoped>

</style>
