<template>
    <tr class="bg-white order-item-row" v-if="(searchAllProducts || (currentCategoryId === orderItemEntity.entity.properties.get('category_id') &&
    currentTypeId === orderItemEntity.entity.properties.get('type_id'))) &&
    (manufacturerFilter === null || (orderItemEntity.entity.properties.get('manufacturer_id').toString() !== null && manufacturerFilter.split(',').includes(orderItemEntity.entity.properties.get('manufacturer_id').toString() ))) &&
    (filteredQuery === null || orderItemEntity.entity.properties.get('product').toLowerCase().includes(filteredQuery.toLowerCase()))">
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            {{orderItemEntity.entity.properties.get('product')}}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            {{orderItemEntity.entity.properties.get('manufacturer_name')}}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <property-or-field ref="next" @input="change" :properties="orderItemEntity.entity.properties"
                               title="Due Date" :scope="scope"
                               :action="updateAction" fieldId="due_date" propertyKey="due_date"
                               v-model="updateActionData['due_date']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <property-or-field @input="change" :properties="orderItemEntity.entity.properties" title="Qty"
                               :action="updateAction" fieldId="quantity" propertyKey="quantity" :scope="scope"
                               v-model="updateActionData['quantity']"></property-or-field>
        </td>
        <td v-if="isDelivered" class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <property-or-field @input="change" :properties="orderItemEntity.entity.properties" title="Delivered Qty"
                               :action="updateAction" fieldId="delivered_quantity" propertyKey="delivered_quantity" :scope="scope"
                               v-model="updateActionData['delivered_quantity']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <property-or-field @input="change" :properties="orderItemEntity.entity.properties" title="Unit" :scope="scope"
                               :action="updateAction" fieldId="unit" propertyKey="unit"
                               v-model="updateActionData['unit']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <span v-if="removeAction" class="cursor-pointer font-normal underline text-gray-400" @click="removeItem()">remove</span>
        </td>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from "../mixins/orderItemsUpdate";

    export default {
        inject: ["$validator"],
        data(){
            return{
                startedQuantity: 0
            }
        },
        mixins: [orderItemsUpdate],
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            orderItemEntity: {
                type: Object,
            },
            scope: {
                type: String
            },
            response: {
                type: Object
            },
            currentCategoryId:{
                type: String
            },
            currentTypeId:{
                type: String,
                default: null
            },
            manufacturerFilter:{
                default:null
            },
            filteredQuery:{
                type: String,
                default: null
            },
            searchAllProducts:{
                type: Boolean,
                default: false
            }
        },
        mounted(){
          this.startedQuantity = this.updateActionData['quantity'];
        },
        computed: {
            updateAction() {
                return this.orderItemEntity.entity.actions.filter(action => action.name === 'add-product-to-order').first();
            },
            removeAction() {
                return this.orderItemEntity.entity.actions.filter(action => action.name === 'delete').first();
            },
            updateActionData() {
                if (!this.updateAction) {
                    return {};
                }
                if (!this.updateAction.fields) {
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
        },
        methods: {
            async update() {
                if(this.startedQuantity !== this.updateActionData['quantity']) {
                    return await this.updateAction.perform(this.updateActionData);
                }
            },
            removeItem() {
                return this.removeAction.perform().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'Item removed successfully.'
                    );
                    this.$router.go(0);
                }).catch(error => {
                    alert('There was an error processing your request.' + error);
                });

            },
            change() {
                this.$emit('change');
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
