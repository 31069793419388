<template>
    <div :key="$route.fullPath" class="create-new-order">
        <main-template>

        <page-title title="Create A QA">
        </page-title>
        <loading v-if="loading"></loading>
        <div class="container mx-auto px-4" v-if="!loading" :key="key">
            <div class="bg-white shadow p-4 sm:rounded-lg mb-4">
                <property-or-field title="Client" :action="getQATemplateAction"
                                   fieldId="client_id" propertyKey="client"
                                   v-model="getQATemplateActionData['client_id']"
                                   @input="setClientId"></property-or-field>

                <dependant-field title="QA Tracker" :action="getQATemplateAction"
                                 fieldId="qa_tracker" propertyKey="qa_tracker"
                                 v-model="getQATemplateActionData['qa_tracker']"
                                 @input="getTrackerDetails"
                                 dependsOn="client_id"
                                 :dependsOnValue="clientId"
                ></dependant-field>

                <template v-if="getQaTrackerDetailsAction">
                    <div class="mt-2">
                        Type: {{qaTrackerDetailsActionData['type']}}
                    </div>
                </template>

                <dependant-field title="QA Category" :action="getQATemplateAction"
                                   fieldId="qa_category_id" propertyKey="qa_category_id"
                                   v-model="getQATemplateActionData['qa_category_id']"
                                    @input="getRemaningFields()"
                                    dependsOn="tracker_id"
                                    :dependsOnValue="qaTrackerId"
                ></dependant-field>

                <dependant-field title="Project" :action="createNewQAAction"
                                 fieldId="project_id"
                                 dependsOn="client_id"
                                 :dependsOnValue="clientId"
                                 v-model="createNewQAActionData['project_id']"
                                 @input="setProjectId"></dependant-field>

                <dependant-field @input="change" title="Site" :action="createNewQAAction"
                                 fieldId="contract_id"
                                 dependsOn="project_id"
                                 :dependsOnValue="projectId"
                                 v-model="createNewQAActionData['contract_id']"
                ></dependant-field>

                <property-or-field @input="change" title="Operative" :action="createNewQAAction"
                                   fieldId="operative_id" propertyKey="subcontractor"
                                   v-model="createNewQAActionData['operative_id']"></property-or-field>

                <div class="mt-2 show-label">
                    <property-or-field @input="change" :title="identifiersTitle()" :action="createNewQAAction"
                                       fieldId="identifiers" propertyKey="identifiers"
                                       v-model="createNewQAActionData['identifiers']"></property-or-field>
                </div>
                <property-or-field @input="change" title="Priced Or Hourly Day work" :action="createNewQAAction"
                                   fieldId="priced_or_hourly_day_work" propertyKey="priced_or_hourly_work"
                                   v-model="createNewQAActionData['priced_or_hourly_day_work']"></property-or-field>

                <property-or-field @input="change" title="Comment" :action="createNewQAAction"
                                   fieldId="comment" propertyKey="comment"
                                   v-model="createNewQAActionData['comment']"></property-or-field>



                <property-or-field @input="change" title="Additional Notes" :action="createNewQAAction"
                                   fieldId="additional_notes" propertyKey="additional_notes"
                                   v-model="createNewQAActionData['additional_notes']"></property-or-field>

                <property-or-field @input="change" title="On site confirmation image" :action="createNewQAAction"
                                   fieldId="on_site_confirmation_image" propertyKey="on_site_confirmation_image"
                                   v-model="createNewQAActionData['on_site_confirmation_image']"
                                   :useCamera="useCameraForOnSiteConfirmation"
                ></property-or-field>

                <property-or-field @input="change" title="Construction Drawing" :action="createNewQAAction"
                                   fieldId="construction_drawing" propertyKey="construction_drawing"
                                   v-model="createNewQAActionData['construction_drawing']"></property-or-field>

                <div class="flex flex-col mt-3 mb-3" v-if="createNewQAAction">
                    <p class="block text-sm font-medium leading-5 text-gray-700">Additional Images</p>

                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg border border-gray-200">
                                <table class="min-w-full divide-y additional-images-table ">
                                    <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Image
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="qty in additionalImagesQty"  class="bg-white">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <property-or-field @input="change" title="On site confirmation image" :action="createNewQAAction"
                                                                   :fieldId="`additional_images[${qty}]`" :propertyKey="`additional_images[${qty}]`"
                                                                   v-model="createNewQAActionData[`additional_images[${qty}]`]"></property-or-field>
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <span v-if="qty === additionalImagesQty" @click="removeAdditionalImage(qty)" class="cursor-pointer font-normal underline text-gray-400">remove</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody >
                                        <tr class="bg-white text-center">
                                            <td colspan="100" >
                                                <div class="grid grid-cols-3 p-3" v-if="additionalImagesQty < 14">
                                                    <button @click="additionalImagesQty++" data-cy="add-item"
                                                            class="col-start-2 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition duration-150 ease-in-out">
                                                        + Additional Image
                                                    </button>
                                                </div>
                                                <div v-else>
                                                    <p class="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400">
                                                        Max 15 additional images.
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                <property-or-field @input="change" title="Question" :action="createNewQAAction"-->
<!--                                   fieldId="question" propertyKey="question"-->
<!--                                   v-model="createNewQAActionData['question']"></property-or-field>-->

                <questions-box fieldId="answers" :action="createNewQAAction" v-model="createNewQAActionData['answers']">

                </questions-box>

            </div>
            <div
                v-if="!loading && showCreateButton"
                class="p-3 bottom-0 w-full mt-6 -mt-4 flex justify-end z-40">
                <primary-button @click.native="createQa()" class="cursor-pointer" data-cy="submit" :loading="submitting">
                    Create
                    QA
                </primary-button>
            </div>

        </div>



        </main-template>
    </div>
</template>

<script>
    import Loading from '../components/Loading';
    import PrimaryButton from '../components/PrimaryButton';
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import PropertyOrField from "../components/PropertyOrField";
    import PageTitle from "../common/PageTitle";
    import DependantField from "../components/DependantField";
    import MainTemplate from "../templates/MainTemplate";
    import QuestionsBox from "../components/Action/Fields/QuestionsBox";

    let follow = Siren.Helper.follow;
    export default {
        components: {
            MainTemplate,
            Loading,
            PrimaryButton,
            PropertyOrField,
            PageTitle,
            DependantField,
            QuestionsBox
        },
        data() {
            return {
                getQATemplateAction: null,
                createNewQAAction:null,
                projectId: null,
                loading: true,
                submitting: false,
                key:0,
                clientId: null,
                showCreateButton: false,
                additionalImagesQty:0,
                qaTrackerId: null,
                getQaTrackerDetailsAction: null,
                useCameraForOnSiteConfirmation: false
            }
        },

        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            getQATemplateActionData() {
                var vm = this;
                if (!this.getQATemplateAction) {
                    return {};
                }
                if (!this.getQATemplateAction.fields) {
                    return {};
                }
                var payload = {};
                this.getQATemplateAction.fields.forEach(function (field) {
                    if (field.value !== undefined && field.name === 'client_id') vm.clientId = field.value;
                    if (field.value !== undefined) payload[field.name] = field.value;
                })
                return payload;
            },
            createNewQAActionData() {
                var vm = this;
                if (!this.createNewQAAction) {
                    return {};
                }
                if (!this.createNewQAAction.fields) {
                    return {};
                }
                var payload = {};
                this.createNewQAAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                payload['tracker_id'] = vm.qaTrackerId;
                return payload;
            },
            qaTrackerDetailsActionData() {
                var vm = this;
                if (!this.getQaTrackerDetailsAction) {
                    return {};
                }
                if (!this.getQaTrackerDetailsAction.fields) {
                    return {};
                }
                var payload = {};
                this.getQaTrackerDetailsAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            }
        },
        methods: {
            removeAdditionalImage(qty){
                this.createNewQAActionData[`additional_images[${qty}`] = '';
                this.additionalImagesQty--;
            },
            change() {
            },
            changeDeliveryMethod() {
                this.change();
                this.key++;
            },
            changeIsScheduled() {
                this.change();
                this.key++;
            },
            getRemaningFields(){
                this.fetchingFormFields = true;
                if(this.getQATemplateActionData.qa_category_id !== null){
                    this.getQATemplateAction.perform(this.getQATemplateActionData).then(res => {
                        this.useCameraForOnSiteConfirmation = res.body.properties.get('use_camera_for_on_site_confirmation');
                        this.createNewQAAction = res.body.actions.filter(action => action.name === 'add-new-quality-assessment').get('add-new-quality-assessment');
                        this.showCreateButton = true;
                    });
                }else{
                    this.createNewQAAction = null;
                    this.showCreateButton = false;
                }

            },
            createQa() {
                this.submitting = true;
                this.createNewQAAction.perform(this.createNewQAActionData).then(res => {
                    this.submitting = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    let next = res.body.links
                        .filter(link => link.rels.contains("self"))
                        .first();
                    let parts = next.href.split("/api");
                    this.$router.push(parts[1]);
                    this.$emit('success', res);
                }).catch(error => {
                    this.submitting = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            setProjectId(value) {
                this.projectId = value;
            },
            setClientId(value) {
                this.clientId = value;
            },
            setQaTrackerId(value) {
                this.qaTrackerId = value;
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            getTrackerDetails(value) {
                if (value !== null) {
                    Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                    Siren.get(process.env.VUE_APP_API_URL + "/api/qa-trackers/" + value).then(res => {
                        let siren = res.body;
                        this.getQaTrackerDetailsAction = siren.actions.filter(action => action.name === 'get-tracker-details').get('get-tracker-details');
                        this.loading = false;
                    }, this);
                }
                this.setQaTrackerId(value);
                this.getRemaningFields();
            },
            identifiersTitle(){
                if (this.qaTrackerDetailsActionData['type'] === 'communal'){
                    return 'Level/Area';
                }
                return 'Level/Unit'
            }
        },
        mounted() {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow("quality-assessments"))
                .then(res => {
                    let siren = res.body;
                    this.getQATemplateAction = siren.actions.filter(action => action.name === 'get-qa-template').get('get-qa-template');
                    this.loading = false;
                }, this);

        },
    }
</script>

<style lang="scss">
    .create-new-order:not(show-label) .label-a {
        display: none;
    }
    .create-new-order .show-label .label-a{
        display:block;
    }
    .create-new-order .show-label .form-group label{
        display:none;
    }
    .additional-images-table label{
        display: none;
    }
</style>
