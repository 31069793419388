<template>
    <div>
        <a-button v-if="showButton" :class="additionalButtonClasses" @click.native="$emit('displayForm')">
          {{title ? title : action.title}}
        </a-button>
        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="$emit('removeFromDisplay')" @perform="perform()">

            <component v-if="getField('pages')" :is="getFieldComponent(getField('pages'))" :field="getField('pages')" v-model="formData[getField('pages').name]">
            </component>
            <div v-show="errors.has(getField('pages').name)" class="text-red-600">{{ errors.first(getField('pages').name) }}</div>

            <component v-if="getField('logo')" :is="getFieldComponent(getField('logo'))" :field="getField('logo')" v-model="formData[getField('logo').name]">
            </component>
            <div v-show="errors.has(getField('logo').name)" class="text-red-600">{{ errors.first(getField('logo').name) }}</div>

        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import TextAreaInput from "@/components/Action/Fields/TextAreaInput";

export default {
    name: "DownloadBatchOnSiteIdForm",
    data() {
        return {
            submittingModal: false
        }
    },
    components: {
        SlideOver,
        'a-button': Button,
        TextAreaInput
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        },
        showButton:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).responseType('blob').then(res => {
                var fileURL = window.URL.createObjectURL(res.xhr.response);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'on-site-id-batch.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
                this.submittingModal = false;
                this.$emit('removeFromDisplay')
            }).catch(error => {
                this.submittingModal = false;
                alert('There was an error processing your request.' + error);
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            if(!this.action.fields.filter(field => field.name === fieldName)){
                return null;
            }
            return this.action.fields.filter(field => field.name === fieldName).first();
        },
    },
}
</script>

<style scoped>

</style>
