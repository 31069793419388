<template>
    <div>
        <div v-if="remainingActions.filter(action => action.name === 'accept-return-request').first()">
            <quote-approval-action class="my-2" :approveAction="getAction('accept-return-request')" :rejectAction="getAction('reject-return-request')"></quote-approval-action>
        </div>
        <div v-for="(action, index) in remainingActions.filter(action => action.name !== 'accept-return-request' && action.name !== 'reject-return-request')">
            <template v-if="!action[1].fields.isEmpty() && action[0] !== 'reject-quotes'">
                <email-preview-form additionalButtonClasses="my-2 w-full" v-if="action[0] === 'send-quotation-request'"
                                    :action="action[1]"
                                    :actionKey="action[0]"
                                    :onSuccessPath="$router.currentRoute.fullPath"
                                    :buttonType="index === 0 ? 'primary' : 'secondary'"></email-preview-form>
                <select-quote-form v-else-if="action[0] === 'select-quote-for-approval'"
                                   :action="action[1]"
                                   :actionKey="action[0]"
                                   :onSuccessPath="$router.currentRoute.fullPath"
                                   :secondAction="remainingActions.filter(action2 => !action2.fields.isEmpty() && action2.name ==='select-quote-and-ask-cms-to-beat').first()"
                                   additionalButtonClasses="my-2 w-full"
                                   :buttonType="index === 0 ? 'primary' : 'secondary'"
                ></select-quote-form>
                <goods-in-note-form v-else-if="action[0] === 'add-goods-in-note' || action[0] === 'mark-as-fully-delivered' "
                                    :response="response" :action="action[1]"
                                    :actionKey="action[0]"
                                    :onSuccessPath="$router.currentRoute.fullPath"
                                    additionalButtonClasses="my-2 w-full"
                                    :buttonType="index === 0 ? 'primary' : 'secondary'"
                ></goods-in-note-form>

                <split-order-form v-else-if="action[0] === 'split-order'"
                                  :response="response" :action="action[1]"
                                  :actionKey="action[0]"
                                  :onSuccessPath="$router.currentRoute.fullPath"
                                  additionalButtonClasses="my-2 w-full"
                                  :buttonType="index === 0 ? 'primary' : 'secondary'"></split-order-form>

                <returns-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'create-return-request'"
                              :response="response" :action="action[1]"
                              :actionKey="action[0]"
                              :onSuccessPath="$router.currentRoute.fullPath"
                              :buttonType="index === 0 ? 'primary' : 'secondary'">
                </returns-form>
                <reconcile-order-form v-else-if="action[0] === 'reconcile-order' || action[0] === 'create-invoice-in'"
                                      :action="action[1]"
                                      :actionKey="action[0]"
                                      :onSuccessPath="$router.currentRoute.fullPath"
                                      additionalButtonClasses="my-2 w-full"
                                      :buttonType="index === 0 ? 'primary' : 'secondary'"
                >
                </reconcile-order-form>
                <new-credit-note-form v-else-if="action[0] === 'add-credit-note'"
                                      :action="action[1]"
                                      :actionKey="action[0]"
                                      :onSuccessPath="$router.currentRoute.fullPath"
                                      additionalButtonClasses="my-2 w-full"
                                      :buttonType="index === 0 ? 'primary' : 'secondary'"
                ></new-credit-note-form>
                <action-confirmation additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'mark-as-paid'" :action="action[1]" :actionKey="action[0]" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)"></action-confirmation>
                <ready-for-quote-action v-else-if="action[0] === 'mark-as-ready-for-quote'" :action="action[1]" :actionKey="action[0]" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath"></ready-for-quote-action>
                <review-quotes v-else-if="action[0] === 'reject-competing-quote'" :response="response"
                               :firstAction="action[1]" :secondAction="remainingActions.filter(action => action.fields.isEmpty() && action.name ==='approve-competing-quote').first()"
                               :onSuccessPath="$router.currentRoute.fullPath"
                ></review-quotes>



                <form-action v-else :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></form-action>
            </template>
            <template v-else-if="action[1].fields.isEmpty() && action[0] !== 'approve-quotes'">
                <confirmation-action additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath"></confirmation-action>
            </template>
        </div>
    </div>
</template>

<script>
import Button from "@/v2/buttons/Button";
import ApproveButtons from "@/v2/buttons/ApproveButtons";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
import QuoteApprovalAction from "@/v2/components/QuoteApprovalAction";
import ReturnsForm from "@/v2/components/forms/ReturnsForm";
import GoodsInNoteForm from "@/v2/components/forms/GoodsInNoteForm";
import ReconcileOrderForm from "@/v2/components/forms/ReconcileOrderForm";
import NewCreditNoteForm from "@/v2/components/forms/NewCreditNoteForm";
import EmailPreviewForm from "@/v2/components/forms/EmailPreviewForm";
import SelectQuoteForm from "@/v2/components/forms/SelectQuoteForm";
import SplitOrderForm from "@/v2/components/forms/SplitOrderForm";
export default {
    name: "OrderActions",
    data() {
        return {
            displayApproveModal : false,
            displayRejectModal : false
        }
    },
    components:{
        QuoteApprovalAction,
        FormAction,
        ConfirmationAction,
        'a-button' : Button,
        ApproveButtons,
        ReturnsForm,
        GoodsInNoteForm,
        ReconcileOrderForm,
        NewCreditNoteForm,
        EmailPreviewForm,
        SelectQuoteForm,
        SplitOrderForm
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update' &&
                    action.name !== 'update-invoice' &&
                    action.name !== 'add-new-item' &&
                    action.name !== 'search-products' &&
                    action.name !== 'update-quote'
                )
        },
    },
    methods:{
        getColourType(action, index){
            if(action === 'delete-order'){
                return 'negative';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        getAction(actionName){
            return this.remainingActions.filter(action => action.name === actionName).first();
        }
    }
}
</script>

<style scoped>

</style>
