<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link" :logo="logo">
                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                <div class="md:flex items-center">
                                    <a href="/qa-categories">QA Categories</a>
                                    <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span class="text-secondary-darkblue">
                                        {{response.body.properties.get('name')}}
                                    </span>
                                </div>
                            <div class="flex items-center text-secondary-darkgrey">
                                <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter' && action.name !== 'add-new-quality-assessment' && action.name !== 'update-template')">
                                    <duplicate-template-form v-if="actionWithFields[0] === 'duplicate'"
                                                 class="action-with-fields" :action="actionWithFields[1]"
                                                 :actionKey="actionWithFields[0]"
                                                 :redirectOnSuccess="true"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                                    ></duplicate-template-form>
                                </div>
                            </div>
                            </div>
                        </page-header>

                        <div class="grid grid-cols-4 min-h-screen">
                            <div
                                class="col-span-4 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">

                                <template-summary ref="summary"  :response="response" @change="change"></template-summary>
                            </div>
                        </div>
                    </template>
                    <div v-if="hasChangesWaitingOnSave"
                                    style="right:0px;left:6rem;"
                                    class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                    <a-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                        Category
                    </a-button>
                </div>
                </div>
            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import MainTemplate from "@/v2/templates/MainTemplate";
    import TemplateHeader from "../components/Headers/TemplateHeader";
    import TemplateSummary from "@/components/Summaries/TemplateSummary";
    import PrimaryButton from "@/components/PrimaryButton";
    import PageHeader from "@/v2/components/Header";
    import Button from "@/v2/buttons/Button";
    import FormAction from "@/v2/components/FormAction";
    import DuplicateTemplateForm from "@/v2/components/forms/DuplicateTemplateForm";
    export default {
        data() {
            return {
                hasChangesWaitingOnSave : false,
                loading : false
            }
        },
        components: {
            DuplicateTemplateForm,
            FormAction,
            MainTemplate,
            FetchRootSiren,
            Loading,
            TemplateHeader,
            TemplateSummary,
            'a-button' : Button,
            PageHeader
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },
        },
        methods:{
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            async saveData() {
                this.loading = true;
                this.$setLaravelValidationErrorsFromResponse('');
                this.$refs.summary.update().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'The category was saved successfully'
                    );
                    this.hasChangesWaitingOnSave = false;
                    this.loading = false;
                }).catch(error => {
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.loading = false;
                })
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
