<template>
    <div>
        <a-button :class="additionalButtonClasses" :colourType="buttonType" @click.native="displayModal = true">{{title? title : action.title}}</a-button>

        <portal to="destination">
            <modal :class="zIndex" v-if="displayModal" :submitting="submittingModal" :title="action.title" :confirmationString='finalConfirmationString' @removeFromDisplay="displayModal = false" @perform="perform()" :buttonType="buttonType"></modal>
        </portal>
    </div>
</template>

<script>
import Modal from "@/v2/components/Modal";
import Button from "@/v2/buttons/Button"
export default {
    name: "DownloadDummyOnSiteId",
    components: {
        'a-button': Button,
        Modal
    },
    data() {
        return {
            displayModal: false,
            submittingModal: false
        }
    },
    props:{
        buttonType:{
            type: String,
            default: 'primary'
        },
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        properties:{
            type: Object
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        additionalButtonClasses: {
            type: String,
            default: null
        },
        zIndex:{
            type: String,
            default: 'z-10'
        },
        title:{
            type: String
        }
    },
    computed:{
        finalConfirmationString() {
            var preText = '';
            if (this.action.name === 'request-update'){
                if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                    preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
            }

            return preText + `Are you sure you want to "${this.action.title}"`
        }
    },
    methods: {
        perform() {
            if(this.submittingModal === false) {
                this.submittingModal = true;
                this.action.perform().responseType('blob').then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    var fileURL = window.URL.createObjectURL(res.xhr.response);
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'on-site-id-batch.pdf');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.$store.dispatch(
                        "setMessage",
                        'Promo on site ids deownloaded successfully.'
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if (newPath !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        } else {
                            this.$router.go(0);
                        }
                    }
                }).catch(error => {
                    if (error.status === 403 || error.status === 401) {
                        alert("You do not have permission to " + this.action.title);
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function(entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        }
    }
}
</script>

<style scoped>

</style>
