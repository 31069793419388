<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <costing-header :response="response"></costing-header>
                        <div class="container mx-auto p-4 mb-8">
    <!--                        <saved-filters :response="response">-->
    <!--                        </saved-filters>-->
                            <filterable-paginated-costing-list :response="response">

                            </filterable-paginated-costing-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import CompaniesHeader from "../components/CompaniesHeader";
    import MainTemplate from "../templates/MainTemplate";
    import FilterablePaginatedCostingList from "@/components/FilterablePaginatedCostingList";
    import CostingHeader from "@/components/Headers/CostingHeader";

    export default {
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            CostingHeader,
            FilterablePaginatedCostingList
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
