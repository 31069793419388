<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link">

            <div slot-scope="{ response }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <span @click="navigateToSuppliers(response)" class="cursor-pointer">
                            Suppliers
                        </span>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('name')}}
                        </span>

                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-2">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                   Supplier Details
                                                    <svg @click="displaySupplierDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </dt>
                                                <dd class="text-sm text-black font-bold">{{ response.body.properties.get('name') }}</dd>
                                            </div>

                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Sage Reference</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <template v-if="response.body.properties.get('sage_reference')">{{ response.body.properties.get('sage_reference') }}</template>
                                                    <template v-else>No sage reference</template>
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Credit Limit</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('credit_limit') }}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Start Date</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <template v-if="response.body.properties.get('credit_limit_start_date')">{{ response.body.properties.get('credit_limit_start_date') }}</template>
                                                    <template v-else>No start date</template>
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Payment Terms In Days</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('payment_terms_in_days') }}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Payment Terms From</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('payment_terms_from') }}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Notes</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <template v-if="response.body.properties.get('notes')">{{ response.body.properties.get('notes') }}</template>
                                                    <template v-else>No Notes</template>
                                                </dd>
                                            </div>
                                            <div v-if="response.body.properties.get('tax_code')">
                                                <dt class="text-sm font-nromal text-gray-500">Tax Code</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('tax_code') }}
                                                </dd>
                                            </div>
                                            <div v-if="response.body.properties.get('short_description')">
                                                <dt class="text-sm font-nromal text-gray-500">Short Description</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('short_description') }}
                                                </dd>
                                            </div>
                                            <div v-if="response.body.properties.get('nominal_code')">
                                                <dt class="text-sm font-nromal text-gray-500">Nominal Code</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('nominal_code') }}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <section class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <actions :response="response" :remainingActions="
                                response.body.actions.filter(action =>
                                    action.name !== 'update-company'
                                )"></actions>
                            </div>
                        </section>
                        <section class="lg:col-start-1 lg:col-span-3">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <span class="text-sm font-nomal text-gray-500">Contacts</span>
                                <supplier-contact-paginated-list :response="response">
                                </supplier-contact-paginated-list>
                            </div>
                        </section>
                    </div>
                    <update-supplier-form :displayModal="displaySupplierDetails" :action="response.body.actions.filter(action => action.name==='update-company').first()" @removeFromDisplay="displaySupplierDetails=false" :onSuccessPath="$route.fullPath" :showButton="false"></update-supplier-form>



                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import UpdateSupplierForm from "@/v2/components/forms/UpdateSupplierForm";
import Actions from "@/v2/components/Actions";
import SupplierContactPaginatedList from "@/v2/paginatedLists/SupplierContactPaginatedList";
export default {
    name: "Supplier",
    data() {
        return {
            displaySupplierDetails: false
        }
    },
    components: {
        SupplierContactPaginatedList,
        Actions,
        UpdateSupplierForm,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToSuppliers(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('companies')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
