<template>
    <div>
        <div v-if="field" class="flex flex-col questions-input whitespace-normal">
            <div class=" overflow-x-auto">
                <div class="p-2 align-middle inline-block min-w-full">
                    <span class=" block text-sm font-medium text-gray-700">{{ field.title  ? field.title: 'Fields' }}</span>
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th v-for="option in field.options" v-if="option.name !== 'can_add_line_break' && option.name !== 'can_add_bar'" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <template>{{ option.title }}</template>
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="field" class="bg-white" v-for="(item,key) in items"><!-- bg-gray-50 -->
                                <template v-if="!item['line_break'] && !item['bar']">
                                <td v-for="option in field.options" v-if="option.name !== 'can_add_line_break' && option.name !== 'can_add_bar'" class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
<!--                                    <component is="radio-input" :field="field.get('department_id')" v-model="item.department" @change="change">-->
<!--                                    </component>-->
                                    <component :is="getInputComponent(option)" :field="option" v-model="item[option.title]"></component>
                                </td>
                                </template>
                                <td class="text-center" :colspan="field.options.filter(option => option.name !== 'can_add_line_break' && option.name !== 'can_add_bar').length" v-else-if="item['line_break']">
                                    Line Break
                                </td>
                                <td class="text-center" :colspan="field.options.filter(option => option.name !== 'can_add_line_break' && option.name !== 'can_add_bar').length" v-else-if="item['bar']">
                                    <span style="color:#3b82f6">Bar</span>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">
                                    <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem(key);">remove</span>
                                </td>
                            </tr>
                            <tr v-if="field" class="bg-white text-center">
                                <td colspan="100">
                                    <div class="grid grid-cols-3 p-3">
                                        <a-button @click.native="addNewItem()" data-cy="add-item"
                                                  class="col-start-2 inline-flex justify-center">
                                            + New {{ field.title  ? getWithoutEndingS(field.title): 'Field' }}</a-button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="field.options.filter(option => option.name === 'can_add_line_break' || option.name === 'can_add_bar').length > 0" class="bg-white text-center">
                                <td colspan="100">
                                    <div class="grid grid-cols-3 p-3">
                                        <div class="col-start-2 inline-flex justify-center w-full">
                                        <template v-if="field.options.filter(option => option.name === 'can_add_line_break').length > 0">
                                            <a-button @click.native="addLineBreak()" data-cy="add-item"
                                                      class="justify-center w-full" colourType="secondary">
                                                + Add Line Break</a-button>
                                        </template>
                                        <template v-if="field.options.filter(option => option.name === 'can_add_bar').length > 0">
                                            <a-button @click.native="addBar()" data-cy="add-item"
                                                      class="justify-center w-full" colourType="secondary">
                                                + Add Bar</a-button>
                                        </template>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                            <tr v-if="field.options.filter(option => option.name === 'can_add_bar').length > 0" class="bg-white text-center">
                                <td colspan="100">
                                    <div class="grid grid-cols-3 p-3">


                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertyOrField from "@/components/PropertyOrField";
import Button from "@/v2/buttons/Button.vue";
import GbpInput from "@/components/Action/Fields/GbpInput";

export default {
    components: {
        PropertyOrField,
        'a-button': Button
    },
    data() {
        return {
            items: []
        }
    },
    props: {
        value:{
            type:String
        },
        action: {
            type: Object,
        },
        propertyKey: {
            type: String
        },
        properties: {
            type: Object
        },
        field: {
            type: Object
        }
    },
    methods: {
        addNewItem() {
            var optionsWithValues = {};
            this.field.options.forEach(option => {
                if(option.value !== null && option.value !== undefined) {
                    optionsWithValues[option.title] = option.value;
                }
            });
            this.items.push(optionsWithValues);
            this.$emit('change');
        },
        addLineBreak() {
            this.items.push({'line_break': true});
            this.$emit('change');
        },
        addBar(){
            this.items.push({'bar': true});
            this.$emit('change');
        },
        removeItem(key) {
            this.$set(this.items, this.items.splice(key, 1));
            this.$emit('change');
        },
        change() {
            this.$emit("change");
        },
        getInputComponent(actionField) {
            if (actionField.name === 'additional_notes' || actionField.name === 'procurement_notes') {
                return 'text-area-input';
            }

            if(actionField.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return actionField.type + '-input';
        },
        getWithoutEndingS(title){
            if(title.endsWith('s')){
                return title.substring(0, title.length - 1);
            }
            return title;
        }
    },
    created() {
        if(this.value){
            this.items = this.value;
        }
        if(this.properties) {
            this.items = this.properties.get('fields');
        }
    },
    computed:{
    },
    watch: {
        items: {
            handler(newValue, oldValue) {
                this.$emit("input", JSON.stringify(newValue));
            },
            deep: true
        }
    },
}
</script>

<style>
.questions-input label{
    display:none
}
</style>
