<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <qa-tracker-header :response="response"></qa-tracker-header>
                        <qa-tracker-summary :response="response"></qa-tracker-summary>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import MainTemplate from "../templates/MainTemplate";
    import QaTrackerHeader from "@/components/Headers/QaTrackerHeader";
    import QaTrackerSummary from "@/components/Summaries/QaTrackerSummary";

    export default {
        data() {
            return {

            }
        },
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            QaTrackerHeader,
            QaTrackerSummary
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            }
        },
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1024px) {
        .order-container {
            background: linear-gradient(to right, white, white 50%, #f4f5f7 50%);
        }
    }
</style>
