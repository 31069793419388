<template>
    <div>
        <a-button class="mr-3" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
        :confirmationString='`Are you sure you want to \"${action.title}\"`'
        @removeFromDisplay="displayModal = false" @perform="perform()">

            <!-- Order -->
            <component @input="changeOrder" :is="getFieldComponent(action.fields.get('filter[order_id]'))" :field="action.fields.get('filter[order_id]')" v-model="formData[action.fields.get('filter[order_id]').name]">
            </component>
            <div v-show="errors.has(action.fields.get('filter[order_id]').name)" class="text-red-600">{{ errors.first(action.fields.get('filter[order_id]').name) }}</div>

            <template v-if="markAsFullyDeliveredAction || addGoodsInNoteAction">
                <action-with-confirmation v-if="markAsFullyDeliveredAction" :action="markAsFullyDeliveredAction"></action-with-confirmation>
                <goods-in-note-action-with-form v-if="addGoodsInNoteAction"  :action="addGoodsInNoteAction" :response="orderResponse"></goods-in-note-action-with-form>
            </template>

            <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>
        </slide-over>
    </div>
</template>

<script>
    import Button from './Button';
    import Modal from './Modal';
    import SlideOver from "./SlideOver";
    import ActionWithForm from "./ActionWithForm";
    import ActionWithConfirmation from "./ActionWithConfirmation";
    import GoodsInNoteActionWithForm from "./GoodsInNoteActionWithForm";
    import {mapGetters} from "vuex";
    import Siren from "super-siren";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                orderActions: null,
                orderResponse: null
            }
        },
        components: {
            ActionWithConfirmation,
            SlideOver,
            'a-button': Button,
            Modal,
            GoodsInNoteActionWithForm
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            markAsFullyDeliveredAction(){
                if(!this.orderActions){
                    return null;
                }
                return this.orderActions.filter(action => action.name === 'mark-as-fully-delivered').first();
            },
            addGoodsInNoteAction(){
                if(!this.orderActions){
                    return null;
                }
                return this.orderActions.filter(action => action.name === 'add-goods-in-note').first();
            }
        },
        methods: {
            changeOrder(){
                this.action.perform(this.formData)
                    .then(res => {
                        let firstOrderSelfLink = res.body.entities.first().entity.links.filter(link => link.rels.contains('self')).first();
                        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                        Siren.get(firstOrderSelfLink.href).then(res => {
                            this.orderActions = res.body.actions;
                            this.orderResponse = res;
                        });
                    });
            },
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>
