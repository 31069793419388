<template>
    <div>
        <div class="flex flex-col questions-input">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" :class="{'border-gray-600': backgroundColor !== 'bg-white'}">
                        <table class="min-w-full divide-y divide-gray-200"  :class="{'divide-gray-600': backgroundColor !== 'bg-white'}">
                            <thead :class="{'bg-secondary-appdarkblue bg-opacity-70 text-white': backgroundColor !== 'bg-white', 'bg-gray-50 text-gray-500' : backgroundColor === 'bg-white'}">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                                    Name
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                                    Image
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                                    Expiry Date
                                </th>
                                <th class="px-6 py-3 text-left text-xs leading-4 font-medium uppercase">
                                    Reminder Limit (months)
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                                    Front Or Back
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="bg-white" v-for="(certificate,key) in certificates" :class="backgroundColor"><!-- bg-gray-50 -->
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <component is="radio-input" :field="{title: 'Preset Name', options: [
                                        {value: 'driving_licence', title: 'Driving Licence'}, {value: 'CSCS', title: 'CSCS'}, {value: 'visa', title: 'Visa'}, {value: 'custom', title: 'Custom'}
                                    ]}" v-model="certificate.preset_name" @change="change">
                                    </component>
                                    <input v-if="certificate.preset_name==='custom'" placeholder="Name" type="text" v-model="certificate.name" class="mt-2 form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" @input="change">
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <component is="base64-input" :field="{title: 'image', validation: null}" v-model="certificate.image" @change="change">
                                    </component>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <component is="date-input" :field="{title: 'Expiry Date', validation: null}" v-model="certificate.expiry_date" @change="change">
                                    </component>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <component is="number-input" :field="{title: 'Reminder Limit', validation: null}" v-model="certificate.reminder_limit" @change="change">
                                    </component>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <component is="radio-input" :field="{title: 'Front Or Back', options: [
                                        {value: 'front', title: 'Front'}, {value: 'back', title: 'Back'}
                                    ]}" v-model="certificate.front_or_back" @change="change">
                                    </component>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">
                                    <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem(key);">remove</span>
                                </td>
                            </tr>
                            <tr class="bg-white text-center" :class="backgroundColor">
                                <td colspan="100">
                                    <div class="grid grid-cols-3 p-3">
                                        <a-button @click.native="addNewItem()" data-cy="add-item"
                                        class="col-start-2 inline-flex justify-center">
                                        + New Cerificate</a-button>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertyOrField from "@/components/PropertyOrField";
import Button from "@/v2/buttons/Button.vue";
import GbpInput from "@/components/Action/Fields/GbpInput";

export default {
    components: {
        PropertyOrField,
        'a-button': Button
    },
    data() {
        return {
            certificates: []
        }
    },
    props: {
        action: {
            type: Object,
        },
        propertyKey: {
            type: String
        },
        properties: {
            type: Object
        },
        backgroundColor: {
            type: String,
            default: 'bg-white'
        }
    },
    methods: {
        addNewItem() {
            this.certificates.push({name:'', image: '',expiry_date: null, front_or_back: null, reminder_limit: 0, preset_name: null});
            this.$emit('change');
        },
        removeItem(key) {
            this.$set(this.certificates, this.certificates.splice(key, 1));
            this.$emit('change');
        },
        change() {
            this.$emit("change");
        },
    },
    created() {
        if(this.properties) {
            this.certificates = this.properties.get('certificates');
        }
    },
    computed:{
    },
    watch: {
        certificates: {
            handler(newValue, oldValue) {
                this.$emit("input", JSON.stringify(newValue));
            },
            deep: true
        }
    },
}
</script>

<style>
.questions-input label{
    display:none
}
</style>
