<template>
    <main-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <div class="md:flex justify-between w-full items-center">
                            Sales Invoices
                            <div class="flex items-center text-secondary-darkgrey">
                                <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter')">
                                    <create-sales-invoice-form v-if="actionWithFields[0] === 'create-new-invoice'" class="action-with-fields" :action="actionWithFields[1]"
                                                 :actionKey="actionWithFields[0]"
                                                 :redirectOnSuccess="false"
                                                 @success="redirectToItem"
                                    ></create-sales-invoice-form>
                                    <form-action v-else class="action-with-fields" :action="actionWithFields[1]"
                                                      :actionKey="actionWithFields[0]"
                                                      :redirectOnSuccess="false"
                                                      @success="redirectToItem"
                                    ></form-action>
                                </div>
                            </div>
                        </div>
                    </page-header>

                    <sales-invoice-paginated-list :response="response"></sales-invoice-paginated-list>
                </template>
            </div>
            </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import SalesInvoicePaginatedList from "@/v2/paginatedLists/SalesInvoicePaginatedList";
    import CreateSalesInvoiceForm from "@/v2/components/forms/CreateSalesInvoiceForm";
    export default {
    name: "SalesInvoices",
        components: {
            SalesInvoicePaginatedList,
            FormAction,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader,
            CreateSalesInvoiceForm
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {
            redirectToItem(response) {
                let selfLink = response.body.links.filter(link => link.rels.contains("self")).first().href;
                let parts = selfLink.split("/api");
                this.$router.push(parts[1])
            }
        }
    }

</script>

<style scoped>

</style>
