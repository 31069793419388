<template>
    <paginated-list :response="response" :actionBaseUrl="$route.path" :useEntities="true" :hasEntities="userGroups && userGroups.count() > 0">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Date</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Hours</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Works By</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Project</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Status</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Actions</th>
        </template>
        <template v-slot:noItems>
            No day pay requests found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in userGroups" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td @click="navigateToItem(entity)" class="whitespace-nowrap flex-grow py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ displayDate(entity.entity.properties.get('date_of_work')) }}
                    </div>
                </td>
                <td @click="navigateToItem(entity)" class="whitespace-nowrap px-3 py-2 text-sm text-secondary-darkgrey font-bold">
                    <div>
                        {{ entity.entity.properties.get('hours') }}
                    </div>
                </td>
                <td @click="navigateToItem(entity)" class="whitespace-nowrap px-3 py-2 text-sm text-secondary-darkgrey font-bold">
                    <div>
                        {{ entity.entity.properties.get('requested_by') }}
                    </div>
                </td>
                <td @click="navigateToItem(entity)" class="whitespace-nowrap px-3 py-2 text-sm text-secondary-darkgrey font-bold">
                    <div>
                        {{ entity.entity.properties.get('project') }}
                    </div>
                </td>
                <td @click="navigateToItem(entity)" class="whitespace-nowrap px-3 py-2 text-sm text-secondary-darkgrey font-bold">
                    <div>
                        <span v-if="entity.entity.properties.get('status') === 'Approved'" class="text-green-500">Approved</span>
                        <span v-if="entity.entity.properties.get('status') === 'Pending'" class="text-red-500">Pending</span>
                        <span v-if="entity.entity.properties.get('status') === 'Rejected'" class="text-red-500">Rejected</span>
                        <span v-if="entity.entity.properties.get('status') === 'Manager Approved'" class="text-green-500">Manager Approved</span>
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold flex">
                    <confirmation-action class="ml-1" v-if="entity.entity.actions.get('approve')" buttonType="positive" :action="entity.entity.actions.get('approve')" title="Approve" :redirectOnSuccess="true" :onSuccessPath="$route.fullPath">
                    </confirmation-action>
                    <form-action class="ml-1" v-if="entity.entity.actions.get('reject')" buttonType="negative" :action="entity.entity.actions.get('reject')" title="Reject" :redirectOnSuccess="true" :onSuccessPath="$route.fullPath">
                    </form-action>
                    <confirmation-action class="ml-1" v-if="entity.entity.actions.get('delete')" buttonType="negative" :action="entity.entity.actions.get('delete')" title="Delete" :redirectOnSuccess="true" :onSuccessPath="$route.fullPath">
                    </confirmation-action>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";
import FormAction from "@/v2/components/FormAction";
import moment from "moment";
import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";
export default {
    name: "DayPayRequestsPaginatedList",
    components:{
        ConfirmationAction,
        PaginatedList,
        StatusBadge,
        OrderTabs,
        DownloadPdfIconWithLink,
        FormAction
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        userGroups() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('day-pay-request'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        displayDate(date){
            return moment(date).format('DD/MM/YY')
        },
    }
}
</script>

<style scoped>

</style>
