<template>
    <div v-if="action || properties && properties.get(propertyKey)">
        <div class="flex flex-col questions-input">
            <div class="-my-2 overflow-x-auto sm:-mx-6 ">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Image
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="action" class="bg-white" v-for="(certificate,key) in certificates"><!-- bg-gray-50 -->
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <component is="base64-input" :field="action.fields.get('images')" v-model="certificate.image" @change="change">
                                    </component>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">
                                    <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem(key);">remove</span>
                                </td>
                            </tr>
                            <tr v-if="action" class="bg-white text-center">
                                <td colspan="100">
                                    <div class="grid grid-cols-3 p-3">
                                        <a-button @click.native="addNewItem()" data-cy="add-item"
                                        class="col-start-2 inline-flex justify-center">
                                        + New Image</a-button>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertyOrField from "@/components/PropertyOrField";
import Button from "@/components/Button";
import GbpInput from "@/components/Action/Fields/GbpInput";

export default {
    components: {
        PropertyOrField,
        'a-button': Button
    },
    data() {
        return {
            certificates: []
        }
    },
    props: {
        action: {
            type: Object,
        },
        propertyKey: {
            type: String
        },
        properties: {
            type: Object
        },
    },
    methods: {
        addNewItem() {
            this.certificates.push({image: ''});
            this.$emit('change');
        },
        removeItem(key) {
            this.$set(this.certificates, this.certificates.splice(key, 1));
            this.$emit('change');
        },
        change() {
            this.$emit("change");
        },
    },
    created() {
        if(this.properties) {
            this.certificates = this.properties.get('certificates');
        }
    },
    computed:{
    },
    watch: {
        certificates: {
            handler(newValue, oldValue) {
                this.$emit("input", JSON.stringify(newValue));
            },
            deep: true
        }
    },
}
</script>

<style>
.questions-input label{
    display:none
}
</style>
