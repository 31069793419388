<template>
    <main-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <div class="md:flex justify-between w-full">
                            <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                <span class="text-secondary-darkblue">
                                    Day Pay Requests
                                </span>
                            </div>
<!--                            <div class="flex items-center text-secondary-darkgrey">-->
<!--                                <div v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter'  && !action.fields.isEmpty())"-->
<!--                                     class="mr-1">-->
<!--                                    <form-action :action="actionWithFields[1]"-->
<!--                                                 :actionKey="actionWithFields[0]"-->
<!--                                                 :redirectOnSuccess="true"-->
<!--                                                 :onSuccessPath="$router.currentRoute.fullPath"-->
<!--                                    ></form-action>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>

                    </page-header>

                    <day-pay-requests-paginated-list :response="response"></day-pay-requests-paginated-list>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import FormAction from "@/v2/components/FormAction";
import ClientUserGroupsPaginatedList from "@/v2/paginatedLists/ClientUserGroupsPaginatedList";
import DayPayRequestsPaginatedList from "@/v2/paginatedLists/DayPayRequestsPaginatedList.vue";
export default {
    name: "dayPayRequests",
    data() {
        return {
            loading: false
        }
    },
    components: {
        DayPayRequestsPaginatedList,
        ClientUserGroupsPaginatedList,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        FormAction,
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToClient(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
