<template>
    <div class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-3 pt-6">
        <div class="border md:rounded-lg mb-8 bg-white grid grid-cols-5 col-span-3 section-links-2">
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('companies')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('companies')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 416C256 469 213 512 160 512C106.1 512 64 469 64 416C28.65 416 0 387.3 0 352V64C0 28.65 28.65 0 64 0H352C387.3 0 416 28.65 416 64V96H458.7C473.6 96 487.8 101.9 498.3 112.4L591.6 205.7C602.1 216.2 608 230.4 608 245.3V368H616C629.3 368 640 378.7 640 392C640 405.3 629.3 416 616 416H576C576 469 533 512 480 512C426.1 512 384 469 384 416H256zM352 48H64C55.16 48 48 55.16 48 64V352C48 360.8 55.16 368 64 368H76.84C93.44 339.3 124.5 320 160 320C195.5 320 226.6 339.3 243.2 368H352C360.8 368 368 360.8 368 352V64C368 55.16 360.8 48 352 48zM464.4 146.3C462.9 144.8 460.9 144 458.7 144H416V240H558C557.9 239.9 557.8 239.7 557.7 239.6L464.4 146.3zM160 368C133.5 368 112 389.5 112 416C112 442.5 133.5 464 160 464C186.5 464 208 442.5 208 416C208 389.5 186.5 368 160 368zM480 464C506.5 464 528 442.5 528 416C528 389.5 506.5 368 480 368C453.5 368 432 389.5 432 416C432 442.5 453.5 464 480 464z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Suppliers</p>
<!--                        <p>View address book</p>-->
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('supplier-groups')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('supplier-groups')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 48c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16H76.8c16.6-28.7 47.6-48 83.2-48s66.6 19.3 83.2 48H320h32c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM480 512c-53 0-96-43-96-96h-8H352 320 256c0 53-43 96-96 96s-96-43-96-96c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H352c35.3 0 64 28.7 64 64V96h42.7c14.9 0 29.1 5.9 39.6 16.4l93.3 93.3c10.5 10.5 16.4 24.7 16.4 39.6V368h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H576c0 53-43 96-96 96zm78-272c-.1-.1-.2-.3-.4-.4l-93.3-93.3c-1.5-1.5-3.5-2.3-5.7-2.3H416v96H558zM160 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm368-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM176 96c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v48h48c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H240v48c0 8.8-7.2 16-16 16H192c-8.8 0-16-7.2-16-16V208H128c-8.8 0-16-7.2-16-16V160c0-8.8 7.2-16 16-16h48V96z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Supplier Groups</p>
                        <!--                        <p>View address book</p>-->
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('manufacturers')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('manufacturers')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320.1 175C330.3 184.4 330.3 199.6 320.1 208.1L240.1 288.1C231.6 298.3 216.4 298.3 207 288.1C197.7 279.6 197.7 264.4 207 255L246.1 216H112C98.75 216 88 205.3 88 192C88 178.7 98.75 168 112 168H246.1L207 128.1C197.7 119.6 197.7 104.4 207 95.03C216.4 85.66 231.6 85.66 240.1 95.03L320.1 175zM480 512C426.1 512 384 469 384 416H256C256 469 213 512 160 512C106.1 512 64 469 64 416C28.65 416 0 387.3 0 352V64C0 28.65 28.65 0 64 0H352C387.3 0 416 28.65 416 64V96H458.7C473.6 96 487.8 101.9 498.3 112.4L591.6 205.7C602.1 216.2 608 230.4 608 245.3V368H616C629.3 368 640 378.7 640 392C640 405.3 629.3 416 616 416H576C576 469 533 512 480 512H480zM48 64V352C48 360.8 55.16 368 64 368H76.84C93.44 339.3 124.5 320 160 320C195.5 320 226.6 339.3 243.2 368H352C360.8 368 368 360.8 368 352V64C368 55.16 360.8 48 352 48H64C55.16 48 48 55.16 48 64zM558 240C557.9 239.9 557.8 239.7 557.7 239.6L464.4 146.3C462.9 144.8 460.9 144 458.7 144H416V240L558 240zM160 464C186.5 464 208 442.5 208 416C208 389.5 186.5 368 160 368C133.5 368 112 389.5 112 416C112 442.5 133.5 464 160 464zM480 368C453.5 368 432 389.5 432 416C432 442.5 453.5 464 480 464C506.5 464 528 442.5 528 416C528 389.5 506.5 368 480 368z"/></svg>

                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manufacturers</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasAction('invite-user')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" href="/invite-user">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 48c44.11 0 80 35.89 80 80c0 44.11-35.89 80-80 80C179.9 208 144 172.1 144 128C144 83.89 179.9 48 224 48zM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM48.71 464C55.38 401.1 108.7 352 173.3 352H274.7c64.61 0 117.1 49.13 124.6 112H48.71zM616 200h-48v-48C568 138.8 557.3 128 544 128s-24 10.75-24 24v48h-48C458.8 200 448 210.8 448 224s10.75 24 24 24h48v48C520 309.3 530.8 320 544 320s24-10.75 24-24v-48h48C629.3 248 640 237.3 640 224S629.3 200 616 200z"/></svg>

                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Invite User</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
        actions: {
            type: Object,
            default: {}
        }
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasAction(key){
            return this.actions.filter(action => action.name === key).size;
        }
    },
}
</script>

<style scoped>

</style>
