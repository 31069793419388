<template>
    <div class="bg-white overflow-hidden shadow rounded-lg md:w-2/5 mx-auto md:mt-24 reports-form">
        <div class="px-4 py-5 sm:p-6">
            <div class="text-sm text-gray-600">
                <strong>Please note</strong> - Due to performance improvements the filters have been removed from this export.
                You should be able to relatively quickly (10-20s) export all the data into a CSV & use your spreadsheet program to filter the headings as you wish.
                </br>
                </br>
                Please pass any feedback onto James Anelay or Danny McCready
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-4 sm:px-6">
            <primary-button @click.native="saveData" :loading="loading">Export Orders</primary-button>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from "../PropertyOrField";
    import PrimaryButton from "../PrimaryButton";
    import fileDownload from "js-file-download";
    export default {
        data() {
            return {
                loading:false
            }
        },
        props: {
            action: {
                type: Object,
                default: {}
            },
        },
        components: {
            PropertyOrField,
            PrimaryButton,
        },
        computed: {
            actionData(){
                var that = this;
                if(!this.action){
                    return {};
                }
                if(!this.action.fields){
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
        },
        methods: {
            change(){
                this.$emit('change');
            },
            update(){
                return this.action.perform(this.actionData);
            },
            async saveData() {
                this.loading = true;
                this.update().then(res => {
                    if(res.status === 204){
                        alert('No matching orders were found.');
                        this.loading = false;
                        return;
                    }
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                    }
                    this.$store.dispatch(
                        "setMessage",
                        'The export was processed successfully'
                    );
                    this.loading = false;
                }).catch(error => {
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
        },
    }
</script>

<style>
    .reports-form label.block{display:none;}
    .reports-form .form-group label{display:block;}
</style>
