<template>
    <div>
        <a-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>
      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">
            <template v-if="action.fields.filter(field => field.name === 'client_id' && field.type !== 'hidden').first()">
                <property-or-field :action="action"
                                   fieldId="client_id"
                                   v-model="formData['client_id']"
                                   @input="clientChanged"
                ></property-or-field>
                <div v-show="errors.has('client_id')" class="text-red-600 text-sm">{{ errors.first('client_id') }}</div>
            </template>
            <property-or-field :action="action"
                               fieldId="item"
                               v-model="formData['item']"
            ></property-or-field>
            <div v-show="errors.has('item')" class="text-red-600">{{ errors.first('item') }}</div>

            <property-or-field :action="action"
                               fieldId="code"
                               v-model="formData['code']"
            ></property-or-field>
            <div v-show="errors.has('code')" class="text-red-600">{{ errors.first('code') }}</div>

            <property-or-field :action="action"
                               fieldId="quantity"
                               v-model="formData['quantity']"
            ></property-or-field>
            <div v-show="errors.has('quantity')" class="text-red-600">{{ errors.first('quantity') }}</div>

            <property-or-field :action="action"
                               fieldId="project_id"
                               v-model="formData['project_id']"
                               @input="projectChanged"
                               dependsOn="client_id"
                               :dependsOnValue="clientId"
            ></property-or-field>
            <div v-show="errors.has('project_id')" class="text-red-600">{{ errors.first('project_id') }}</div>

            <property-or-field  :action="action"
                               fieldId="contract_id"
                               v-model="formData['contract_id']"
                                dependsOn="project_id"
                                :dependsOnValue="projectId"
            ></property-or-field>
            <div v-show="errors.has('contract_id')" class="text-red-600">{{ errors.first('contract_id') }}</div>

            <property-or-field  :action="action"
                                fieldId="image"
                                v-model="formData['image']"
            ></property-or-field>
            <div v-show="errors.has('image')" class="text-red-600">{{ errors.first('image') }}</div>

            <property-or-field  :action="action"
                                fieldId="note"
                                v-model="formData['note']"
            ></property-or-field>
            <div v-show="errors.has('note')" class="text-red-600">{{ errors.first('note') }}</div>

            <property-or-field  :action="action"
                                fieldId="location"
                                v-model="formData['location']"
            ></property-or-field>

            <div v-show="errors.has('location')" class="text-red-600">{{ errors.first('location') }}</div>


            <template v-for="(field, index) in action.fields">

            </template>

            <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import PropertyOrField from "@/components/PropertyOrField";

export default {
    name: "CreateMaterialForm",
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            projectId: null,
            clientId: null
        }
    },
    components: {
        SlideOver,
        'a-button': Button,
        PropertyOrField
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        }

    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        projectChanged(value){
            this.projectId = value;
        },
        clientChanged(value){
            this.clientId = value;
        }
    },
    mounted() {
        this.clientId = this.formData['client_id'];
    }
}
</script>

<style scoped>

</style>
