<template>
    <div v-if="refundRequestsCount">
        <div class="bg-yellow-50 p-4">
            <div class="flex">
                <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd"
                              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                              clip-rule="evenodd"/>
                    </svg>
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm leading-5 text-yellow-700">
                        {{message}}
                    </p>
                    <p class="mt-3 text-sm leading-5 md:mt-0 md:ml-6">
                        <a @click="navigateToLink('return-requests')"
                           data-cy="refund-requests-link"
                           class="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600 transition ease-in-out duration-150 cursor-pointer">
                            Review return requests &rarr;
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from './Button';
    import Modal from './Modal';
    import LargeModel from "./LargeModel";
    import Siren from "super-siren";
    import {mapActions, mapGetters} from "vuex";
    import pluralize from 'pluralize';

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                returnRequests: [],
                openReturnRequests: []
            }
        },
        components: {
            LargeModel,
            'a-button': Button,
            Modal
        },
        props: {
            response: {
                type: Object
            },
        },
        created() {
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );

            this.returnRequestEntities();
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            openRefundRequestsCount() {
                return this.openReturnRequests.length;
            },
            refundRequestsCount() {
                return this.returnRequests.length;
            },
            message() {
                return `This order has ${this.refundRequestsCount} refund ` + pluralize('request', this.openRefundRequestsCount) + `, ${this.openRefundRequestsCount} of which require approval or rejection.`;
            }
        },
        methods: {
            pluralize(str) {
                return pluralize(str)
            },
            returnRequestEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.linkedEntities.filter(entity => entity.rels.contains('return_request')).forEach(function (entity) {

                    Siren.get(entity.href).then(res => {
                        this.returnRequests.push(res);
                        if (res.body.properties.get('requires_action')) {
                            this.openReturnRequests.push(res);
                        }
                    }).bind(this);
                }.bind(this));
            },
            navigateToLink(rel) {
                let link = this.response.body.links.filter(link => link.rels.contains(rel)).first().href;
                let parts = link.split('/api');
                this.$router.push(parts[1]);
            },
        },
    }
</script>

<style scoped>

</style>
