<template>
    <header data-v-1b26be5a="" class="bg-white shadow-sm mb-5">
      <div data-v-1b26be5a="" class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div data-v-1b26be5a="" class="container flex justify-between">
          <h2 data-v-1b26be5a="" class="text-lg leading-6 font-semibold text-gray-900">{{title}}</h2>
          <slot></slot>
          </div>
        </div>
    </header>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: 'Page Title'
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
