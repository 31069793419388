<template>
  <div >
  <notification :message="message"></notification>
    <top-header-template>
        <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Sign Up
                </h2>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md mb-1 mx-6">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <p class="text-red-600" v-if="error" v-text="error"></p>
                <form action="#" method="POST" @submit.prevent="onSubmit">
                    <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Email address</label>
                    <div class="mt-1 rounded-md shadow-sm">
                        <input
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          id="email"
                          name="email"
                          v-model="email"
                          v-validate="'required'"
                          placeholder="Please enter your email"
                          :class="{'is-invalid': errors.first('email')}"
                        >
                        <span class="mt-2 text-sm text-red-600">{{ errors.first('email') }}</span>
                    </div>
                    <div class="mt-4">
                        <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">First Name</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                id="first_name"
                                name="first_name"
                                v-model="firstName"
                                v-validate="'required'"
                                placeholder="Please enter your first name"
                                :class="{'is-invalid': errors.first('first_name')}"
                            >
                            <span class="mt-2 text-sm text-red-600">{{ errors.first('first_name') }}</span>
                        </div>
                    </div>
                    <div class="mt-4">
                        <label for="surname" class="block text-sm font-medium leading-5 text-gray-700">Last Name/ Family Name</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                id="surname"
                                name="surname"
                                v-model="surname"
                                v-validate="'required'"
                                placeholder="Please enter your surname"
                                :class="{'is-invalid': errors.first('surname')}"
                            >
                            <span class="mt-2 text-sm text-red-600">{{ errors.first('surname') }}</span>
                        </div>
                    </div>
                    <div class="mt-4">
                        <label for="contact_number" class="block text-sm font-medium leading-5 text-gray-700">Contact Number</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                id="contact_number"
                                name="contact_number"
                                v-model="contactNumber"
                                v-validate="'required'"
                                placeholder="Please enter your contact number"
                                :class="{'is-invalid': errors.first('contact_number')}"
                            >
                            <span class="mt-2 text-sm text-red-600">{{ errors.first('contact_number') }}</span>
                        </div>
                    </div>

                    <div class="mt-4">
                        <label for="date_of_birth" class="block text-sm font-medium leading-5 text-gray-700">Date Of Birth</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                id="date_of_birth"
                                name="date_of_birth"
                                v-model="dateOfBirth"
                                v-validate="'required'"
                                placeholder="Please enter your date of birth"
                                :class="{'is-invalid': errors.first('date_of_birth')}"
                                type="date"
                                autocomplete="off"
                            >
                            <span class="mt-2 text-sm text-red-600">{{ errors.first('date_of_birth') }}</span>
                        </div>
                    </div>
                  <div class="mt-4">
                    <label for="password" class="block text-sm font-medium leading-5 text-gray-700">Password</label>
                      <div class="relative mt-1 rounded-md shadow-sm">
                        <input
                          :type="!showPassword ? 'password' : 'text'"
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          id="password"
                          name="password"
                          v-model="password"
                          v-validate="'required'"
                          placeholder="Please enter your password"
                          :class="{'is-invalid': errors.first('password')}"
                        >
                          <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" @click="togglePassword">
                              <svg class="h-4 w-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                  <svg v-if="!showPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                  <svg v-if="showPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                              </svg>
                          </div>
                      </div>
                      <span class="mt-2 text-sm text-red-600">{{ errors.first('password') }}</span>
                  </div>
                    <div class="mt-4">
                        <a class="cursor-pointer underline" @click="showCertificates = true">Add Documents</a>
                        <large-model v-show="showCertificates" @removeFromDisplay="showCertificates = false" @perform="showCertificates = false">
                            <new-user-certificates v-model="certificates"></new-user-certificates>
                        </large-model>
                    </div>
                    <div class="mt-6">
                      <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="w-full flex-inline justify-center align-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:ring-primary active:bg-primary-700 transition duration-150 ease-in-out">
                            Sign Up
                            <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4 align-middle" colorClass="text-gray-200"></loading>
                        </button>
                      </span>
                    </div>
                </form>
              </div>
                <p class="mt-10 text-center text-sm text-gray-500">
                    Already have an account?
                    <a href="#" @click.prevent="goToLogin()" class="cursor-pointer font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Login</a>
                </p>
            </div>
        </div>
    </top-header-template>
  </div>
</template>

<script>
import Notification from "../components/Notification";
import { mapActions, mapGetters } from "vuex";
import Siren from "super-siren";
import TopHeaderTemplate from "../templates/TopHeaderTemplate";
import axios from "axios";
import InductionCertificates from "@/components/Action/Fields/InductionCertificates.vue";
import NewUserCertificates from "@/components/Action/Fields/NewUserCertificates.vue";
import LargeModel from "@/v2/components/LargeModel.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: {Loading, NewUserCertificates, InductionCertificates, TopHeaderTemplate, Notification, LargeModel },

  data() {
    return {
        error: null,
        email: null,
        password: null,
        showPassword: false,
        contactNumber: null,
        firstName: null,
        surname: null,
        dateOfBirth: null,
        certificates: [],
        showCertificates: false,
        loading: false
    };
  },
  computed: {
    ...mapGetters({
         accessToken: "getAccessToken",
        message: "getMessage",
    })
  },
  mounted() {
    if (this.accessToken !== null) {
      Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
      return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
        this.$router.push({ path: "/" });
      });
    }
  },

  methods: {
    onSubmit() {
      this.$validator.validate().then(valid => {
        if (valid) {
            this.loading = true;
            this.errors.clear();
            this.error = null;
            axios.post(process.env.VUE_APP_API_URL + '/api/user/sign-up', {
                email: this.email,
                password: this.password,
                first_name: this.firstName,
                surname: this.surname,
                contact_number: this.contactNumber,
                date_of_birth: this.dateOfBirth,
                certificates: this.certificates
            })
                .then(response => {
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(response).properties['success']
                    );
                    if(this.$route.query.redirect_to){
                        this.$router.push("/login?redirect_to=" + this.$route.query.redirect_to);
                    }else {
                        this.$router.push("/login");
                    }
                    return response;
                })
                .catch(error => {
                    this.loading = false;
                    if (error.response.status !== 422) {
                        this.error = error;
                    }
                    this.$setLaravelValidationErrorsFromResponse(error.response.data);
                });
        }
      });
    },
      togglePassword(){
          this.showPassword = !this.showPassword
      },
      goToLogin(){
          if(this.$route.query.redirect_to){
              this.$router.push("/login?redirect_to=" + this.$route.query.redirect_to);
          }else {
              this.$router.push("/login");
          }
      },
      messages(response) {
          return response.data.entities
              .filter(function (entity) {
                  return entity.rel.includes("status-messages");
              })[0];
      }
  }
};
</script>
