<template>
    <div class="order-summary">
<!--        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Order Summary</h3>-->
        <div class="generic-summary p-4" :key="key">
            <div class="grid grid-cols-1 gap-4">
                <property-or-field v-if="getClientActionType() !== 'hidden'" @input="changeOrganisation" title="Client" :action="showAction" fieldId="organisation_id" propertyKey="organisationId" v-model="showActionData['organisation_id']"></property-or-field>
                <property-or-field @input="updateFields" title="Groups" :action="showAction" fieldId="user_group" propertyKey="user_group" v-model="showActionData['user_group']" dependsOn="client_id" :dependsOnValue="showActionData['organisation_id']"></property-or-field>
                <property-or-field @input="updateFields" title="Employee" :action="showAction" fieldId="user_id" propertyKey="user_id" v-model="showActionData['user_id']" dependsOn="client_id" :dependsOnValue="showActionData['organisation_id']" dependsOn2="user_group_id" :dependsOnValue2="showActionData['user_group']"></property-or-field>
                <property-or-field @input="updateFields" title="Week commencing on" :action="showAction" fieldId="date" propertyKey="date" v-model="showActionData['date']"></property-or-field>
                <property-or-field @input="updateFields" title="Show Deactivated Users" :action="showAction" fieldId="show_deactivated_users" propertyKey="show_deactivated_users" v-model="showActionData['show_deactivated_users']"></property-or-field>

            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import DownloadPdf from "../pages/DownloadPdf";
    import Siren from "super-siren";
    export default {
            inject: ["$validator"],
        components: {
            DownloadPdf,
            PropertyOrField,
        },
        data() {
            return {
                key : 0,
                showAction: null
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        created(){
            if(this.response.body) {
                return this.showAction = this.response.body.actions.filter(action => action.name === 'show-timesheet').first();
            }
            let follow = Siren.Helper.follow;
            let action = null;
            Siren.get(process.env.VUE_APP_API_URL + "/api").then(response => {
                console.log(response.body.findActionByName('show-timesheet'))
                this.showAction = action =  response.body.findActionByName('show-timesheet');
            });
            return action
        },
        computed: {
            showActionData(){
                console.log(this.showAction.fields);
                var that = this;
                if(!this.showAction){
                    return {};
                }
                if(!this.showAction.fields){
                    return {};
                }
                var payload = {};
                this.showAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
        },
        methods: {
            updateFields(){
                let organisationIdQuery = '';
                if(this.showActionData['organisation_id']){
                    organisationIdQuery = '&organisation_id=' + this.showActionData['organisation_id'];
                }
                let userGroupIdQuery = '';
                if(this.showActionData['user_group']){
                    userGroupIdQuery = '&user_group=' + this.showActionData['user_group'];
                }
                let showDeactivatedUsersQuery = '';
                if(this.showActionData['show_deactivated_users']){
                    showDeactivatedUsersQuery = '&show_deactivated_users=' + this.showActionData['show_deactivated_users'];
                }
                this.$router.push(this.$route.path+'?date='+this.showActionData['date']+'&user_id='+this.showActionData['user_id'] +organisationIdQuery + userGroupIdQuery + showDeactivatedUsersQuery);
            },
            changeOrganisation(){
                let organisationIdQuery = '';
                if(this.showActionData['organisation_id']){
                    organisationIdQuery = '&organisation_id=' + this.showActionData['organisation_id'];
                }
                this.showActionData['user_id'] = null;
                this.$router.push(this.$route.path+'?date='+this.showActionData['date']+'&user_id='+this.showActionData['user_id'] +organisationIdQuery);
            },
            change(){
                this.$emit('change');
            },
            getClientActionType(){
                return this.showAction.fields.filter(field => field.name === 'organisation_id').first().type;
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
