<template>
    <div id="clients" class="flex flex-col" >
        <div class="sm:flex sm:items-center sm:justify-between py-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                Clients
            </h3>
            <div class="mt-3 sm:mt-0 sm:ml-4">
                <action-with-form :action="createClientAction"
                                  actionKey="add-new-client"
                                  :redirectOnSuccess="false"
                                  @success="success('add-new-client')"
                ></action-with-form>
            </div>
        </div>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8" >
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Name
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Prefix
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody v-if="clients.size > 0" class="bg-white">
                <template v-for="client in clients">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ client.entity.properties.get('name') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ client.entity.properties.get('prefix') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <div class="flex">
                                <template v-for="actionWithFields in client.entity.actions.filter(action => !action.fields.isEmpty() && action.name !== 'add-new-user' && action.name !== 'add-new-project' && action.name !== 'add-new-site' && action.name !== 'filter')">
                                    <action-with-form :action="actionWithFields[1]"
                                                      :actionKey="actionWithFields[0]"
                                                      :onSuccessPath="$route.path"
                                    ></action-with-form>
                                </template>
                                <template v-for="actionWithFields in client.entity.actions.filter(action => action.fields.isEmpty() && action.name !== 'add-new-user' && action.name !== 'add-new-project' && action.name !== 'add-new-site' && action.name !== 'filter')">
                                    <action-with-confirmation :action="actionWithFields[1]"
                                                              :actionKey="actionWithFields[0]"
                                                              :onSuccessPath="$route.path"
                                    ></action-with-confirmation>
                                </template>
                            </div>
                        </td>
                    </tr>
                </template>
                </tbody>
                <tbody v-else>
                <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                        No clients found.
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionWithForm from "../ActionWithForm";
    import ActionWithConfirmation from "../ActionWithConfirmation";
    export default {
        components: {
            ActionWithForm,
            ActionWithConfirmation
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            clients() {
                let clients = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("client");
                    });
                if(!clients){
                    return null;
                }

                return clients;
            },
            createClientAction() {
                return this.response.body.actions.filter(action => action.name === 'add-new-client').first()
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            },
            success(key) {
                this.$router.go(0);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
