<template>
    <div class="order-summary">
        <div class="flex flex-col">
            <div class="generic-summary p-4 bg-white" :key="key">
                <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <property-or-field @input="change" :properties="properties" title="Clock In Delay" :action="updateAction" fieldId="clock_in_delay" v-model="updateActionData['clock_in_delay']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Week Starting" :action="updateAction" fieldId="week_starting" v-model="updateActionData['week_starting']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Site Times Apply" :action="updateAction" fieldId="site_times_apply" v-model="updateActionData['site_times_apply']"></property-or-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertyOrField from "@/components/PropertyOrField";
export default {
    name: "ClientOssForm",
    inject: ["$validator"],
    data(){
        return{
            key: 0
        }
    },
    components:{
        PropertyOrField
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        updateAction() {
            return this.response.body.actions.filter(action => action.name === 'update-on-site-selfie').first();
        },
        updateActionData(){
            var that = this;
            if(!this.updateAction){
                return {};
            }
            if(!this.updateAction.fields){
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function(field){
                if (field.value !== undefined) payload[field.name] = field.value;;
                if(field.name == 'delivery_method'){
                    that.deliveryMethod = field.value;
                };
            });
            return payload;
        },
        properties(){
            return this.response.body.properties;
        }
    },
    methods: {
        change(){
            this.$emit('change');
        },
        update(){
            return this.updateAction.perform(this.updateActionData);
        },
    }
}
</script>

<style scoped>

</style>
