<template>
    <paginated-list :response="response" :actionBaseUrl="$route.fullPath" :use-entities="true" :hasEntities="templates.size > 0">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Name</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Columns</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Actions</th>
        </template>
        <template v-slot:noItems>
            No templates found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in templates" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('name') }}
                    </div>
                </td>
                <td class="whitespace-normal py-4 pl-4 text-sm text-secondary-darkgrey font-bold">
                    <div>
                        <span v-if="entity.entity.properties.get('fields').filter(field => field['Slip Title'])[0]['Slip Title']">
                            Slip Title: {{entity.entity.properties.get('fields').filter(field => field['Slip Title'])[0]['Slip Title']}}<br>
                        </span>
                        <span v-if="entity.entity.properties.get('fields').filter(field => field['First Name'])[0]['First Name']">
                            First Name: {{entity.entity.properties.get('fields').filter(field => field['First Name'])[0]['First Name']}}<br>
                        </span>
                        <span v-if="entity.entity.properties.get('fields').filter(field => field['Surname'])[0]['Surname']">
                            Surname: {{entity.entity.properties.get('fields').filter(field => field['Surname'])[0]['Surname']}}<br>
                        </span>
                        <span v-if="entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']">
                            Email:
                            <span v-if="entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']['options']"><br>
                                <div class="ml-4" v-if="entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']['options'] !== null">Options: {{entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']['options']}}</div>
                                <div class="ml-4" v-if="entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']['manual_input'] !== null">Manual Input: {{entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']['manual_input']}}</div>
                                <div class="ml-4" v-if="entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']['retrieve'] !== null">Retrieve: {{entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']['retrieve']}}</div>
                                <div class="ml-4" v-if="entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']['column'] !== null">Column: {{entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']['column']}}</div>
                            </span>
                            <span v-else>
                                {{entity.entity.properties.get('fields').filter(field => field['Email'])[0]['Email']}}<br>
                            </span>

                        </span>
                        <span v-if="!field['Slip Title'] && !field['First Name'] && !field['Surname'] && !field['Email'] && key !== 'First Name' && key !== 'Surname' && key !== 'National Insurance Number' && key !== 'Email'"
                              v-for="(field, key) in entity.entity.properties.get('fields')">
                            <template v-if="field[Object.keys(field)[0]]['value']">
                                {{Object.keys(field)[0]}}: {{field[Object.keys(field)[0]]['value']}}<br>
                            </template>
                            <template v-else-if="Object.keys(field)[0] !== 'line_break' && Object.keys(field)[0] !== 'bar'">
                                <template v-if="field[Object.keys(field)[0]]['value']">
                                    {{Object.keys(field)[0]}}: {{field[Object.keys(field)[0]]['value']}}<br>
                                </template>
                                <template v-else-if="field[Object.keys(field)[0]]['column']">
                                    {{Object.keys(field)[0]}}: {{field[Object.keys(field)[0]]['column']}}<br>
                                </template>
                                <template v-else-if="field[Object.keys(field)[0]]['formula']">
                                    {{Object.keys(field)[0]}}: {{field[Object.keys(field)[0]]['formula']}}<br>
                                </template>
                                <template v-else-if="field[Object.keys(field)[0]]['value'] === ''">
                                    {{Object.keys(field)[0]}}<br>
                                </template>
                                <template v-else>
                                    {{Object.keys(field)[0]}}: {{field[Object.keys(field)[0]]}}<br>
                                </template>
                            </template>
                        </span>
                    </div>
                </td>
                <td class="whitespace-normal py-4 pl-4 text-sm text-secondary-darkgrey font-bold">
                    <create-pay-n-send-template-action class="mb-2" v-if="entity.entity.actions.get('update')" :action="entity.entity.actions.get('update')" :onSuccessPath="$route.path">
                    </create-pay-n-send-template-action>
                    <confirmation-action class="mb-2" v-if="entity.entity.actions.get('delete')" buttonType="negative" :action="entity.entity.actions.get('delete')" :onSuccessPath="$route.path">
                    </confirmation-action>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import CreatePayNSendTemplateAction from "@/v2/components/CreatePayNSendTemplateAction.vue";
export default
{
    name: "PayNSendTemplatePaginatedList",
    components:{
        ConfirmationAction,
        PaginatedList,
        StatusBadge,
        OrderTabs,
        CreatePayNSendTemplateAction
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        templates() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('template'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
