<template>
    <div class="flex flex-col" v-if="deliveryConfirmations.size > 0">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">
            POD & Delivery Confirmations
        </h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Name
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        File
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <template v-for="deliveryConfirmation in deliveryConfirmations">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ deliveryConfirmation.entity.properties.get('name') }}
                        </td>
                        <td>
                            <download-pdf-icon-with-link v-if="deliveryConfirmation.entity.properties.get('file')" :url="deliveryConfirmation.entity.properties.get('file')">
                            </download-pdf-icon-with-link>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ReconcileOrderForm from './ReconcileOrderForm';
    import DownloadPdf from "../pages/DownloadPdf";
    import DeleteInvoiceInConfirmation from "./DeleteInvoiceInConfirmation";
    import ActionWithForm from "./ActionWithForm";
    import ActionWithConfirmation from "./ActionWithConfirmation";
    import DownloadPdfIconWithLink from "./DownloadPdfIconWithLink";
    import SelectQuoteForm from "@/v2/components/forms/SelectQuoteForm.vue";
    import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                submittingByCard: false,
                invoiceFile: null,
                showPDF:false,
                quoteValue: null,
                includesVat:null
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        components: {
            ConfirmationAction,
            SelectQuoteForm,
            DownloadPdfIconWithLink,
            ReconcileOrderForm,
            DownloadPdf,
            DeleteInvoiceInConfirmation,
            ActionWithConfirmation,
        },
        computed: {
            deliveryConfirmations() {
                return this.response.body.entities.filter(entity => entity.rels.contains('delivery-confirmation'));
            },
        },
        mounted(){
            let quote =  this.response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("quote");
                })
                .first();
            if(quote){
                this.includesVat = quote.entity.properties.get('includes_vat')
                this.quoteValue = parseFloat(quote.entity.properties.get('total').replace('£', ''))
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
