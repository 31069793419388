<template>
    <paginated-list :response="response" actionBaseUrl="/sages_description">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Description</th>
        </template>
        <template v-slot:noItems>
            No sage descriptions found.
        </template>
        <template v-slot:tableRows>
            <tr @click="navigateToItem(entity)" v-for="entity in sageDescriptions" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('description') }}
                    </div>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";
export default {
    name: "SageDescriptionPaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        OrderTabs,
        DownloadPdfIconWithLink
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        sageDescriptions() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('sage_description'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        getParentLink(properties) {
            return '/orders/' + properties.get("order_uuid");
        }
    }
}
</script>

<style scoped>

</style>
