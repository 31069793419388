<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Quality Assessment</h2>

                <div class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty()&& action.name !== 'update-quality-assessment')"
                         class="mr-1">
                        <action-with-form :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="false"
                                          @success="success(actionWithFields[0])"
                        ></action-with-form>
                    </div>
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty() && action.name === 'update-quality-assessment')"
                         class="mr-1">
                        <update-quality-assessment-form :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="false"
                                          @success="success(actionWithFields[0])"
                        ></update-quality-assessment-form>
                    </div>
                    <div v-if="remaningActions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf')" class="mr-1">
                        <a-button @click.native="downloadPdf()" class="mr-1" :loading="pdfLoading">
                            <svg class="w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                            Download PDF
                        </a-button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import Button from '../Button';
    import ActionWithConfirmation from '../ActionWithConfirmation';
    import ActionWithForm from '../ActionWithForm';
    import fileDownload from "js-file-download";
    import UpdateQualityAssessmentForm from "@/components/CustomForms/UpdateQualityAssessmentForm";

    export default {
        inject: ["$validator"],
        components: {
            'a-button': Button,
            ActionWithConfirmation,
            ActionWithForm,
            UpdateQualityAssessmentForm
        },
        data() {
            return {
                pdfLoading: false
            }
        },
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'add-comment')
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        methods: {
            success(key) {
                this.$router.go(0);
            },
            downloadPdf(){
                this.pdfLoading = true;
                this.remaningActions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf').first().perform().responseType('blob').then(res => {
                    var fileURL = window.URL.createObjectURL(res.xhr.response);
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'quality-assessment.pdf');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.pdfLoading = false;
                }).catch(error => {
                    this.pdfLoading = false;
                    alert('There was an error processing your request.' + error);
                    this.$emit('failure');
                });
            }
        },
        created() {
            document.title = 'Quality Assessment - CMS';
        },
    }
</script>

<style lang="scss" scoped>

</style>
