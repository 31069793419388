<template>
    <div class="rounded-md flex-grow">
        <div class="focus-within:z-10">
            <label for="email" class="block text-sm font-medium leading-5 text-gray-700  mt-3">{{field.title}}<span class="text-red-500 ml-1" v-if="required">*</span></label>
            <date-picker :initialView="'year'"
                         class="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md p-0"
                         input-class="w-full h-full p-2"
                         v-model="value"
                         format="dd/MM/yyyy"
                         :placeholder="field.title"
            ></date-picker>
        </div>
    </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import moment from "moment";
    export default {
        components:{DatePicker},
        props: {
            value:{
                type:String
            },
            field: {
                type: Object
            }
        },
        computed:{
            required: function(){
                if(this.field.validation !== null) {
                    return this.field.validation.includes('required');
                }
                return false;
            }
        },
        watch: {
            value: {
                handler(value) {
                this.$emit("input",moment(value).format('YYYY-MM-DD'));
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
