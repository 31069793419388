<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo" @unauthorised="unauthorised">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <a href="/slip-send">Slip Send</a>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">Pending</span>
                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2" v-if="failedPayNSends(response).size > 0">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="sm:flex sm:items-center sm:justify-between pt-4">
                                        <h3 class="px-4 sm:px-6 md:px-8 text-lg leading-6 font-medium text-gray-900">
                                            Failed Slip Sends
                                        </h3>
                                    </div>
                                    <pay-n-send-pending-paginated-list :response="response" :pay-n-sends="failedPayNSends(response)"></pay-n-send-pending-paginated-list>
                                </div>
                            </section>

                        </div>
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2" v-if="successfulPayNSends(response)">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="sm:flex sm:items-center sm:justify-between pt-4">
                                        <h3 class="px-4 sm:px-6 md:px-8 text-lg leading-6 sm:flex sm:justify-between font-medium text-gray-900 w-full items-center">
                                            <span>
                                                Successful Slip Sends
                                            </span>
                                            <confirmation-action v-if="successfulPayNSends(response).size > 0 && response.body.actions.filter(action =>action.name === 'send-pay-n-sends').first()" additionalButtonClasses="my-2 w-full"
                                                                 :action="response.body.actions.filter(action =>action.name === 'send-pay-n-sends').first()" :actionKey="'send-pay-n-sends'"  :properties="response.body.properties"
                                                                 onSuccessPath="/slip-send"
                                            ></confirmation-action>

                                        </h3>
                                    </div>
                                    <pay-n-send-pending-paginated-list :response="response" :pay-n-sends="successfulPayNSends(response)"></pay-n-send-pending-paginated-list>
                                </div>
                            </section>
                        </div>
                        <section v-if="response.body.actions.filter(action =>action.name !== 'send-pay-n-sends').count() !== 0" class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <pending-pay-n-send-actions :response="response" :remainingActions="response.body.actions.filter(action =>action.name !== 'send-pay-n-sends')"></pending-pay-n-send-actions>
                            </div>
                        </section>
                    </div>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import UpdateMaterialForm from "@/v2/components/forms/UpdateMaterialForm";
import StockAdjustmentPaginatedList from "@/v2/paginatedLists/StockAdjustmentPaginatedList";
import Actions from "@/v2/components/Actions";
import SupplierPaginatedList from "@/v2/paginatedLists/SuppliersPaginatedList.vue";
import PayNSendTemplatePaginatedList from "@/v2/paginatedLists/PayNSendTemplatePaginatedList.vue";
import PayNSendPendingPaginatedList from "@/v2/paginatedLists/PayNSendPendingPaginatedList.vue";
import PendingPayNSendActions from "@/v2/components/PendingPayNSendActions.vue";
import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";
export default {
    name: "Material",
    data() {
        return {
            displayMaterialDetails: false
        }
    },
    components: {
        ConfirmationAction,
        PendingPayNSendActions,
        PayNSendPendingPaginatedList,
        PayNSendTemplatePaginatedList,
        SupplierPaginatedList,
        StockAdjustmentPaginatedList,
        UpdateMaterialForm,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        Actions
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        unauthorised(){
            this.$router.push({path: '/slip-send'});
        },
        successfulPayNSends(response){
            return response.body.entities.filter(entity =>
                entity.rels.contains('pay-n-sends') &&
                ((entity.entity.properties.get('email_option') !== 'retrieve' &&
                        (entity.entity.properties.get('email') !== null &&
                entity.entity.properties.get('email').includes('@'))) ||
                entity.entity.properties.get('user') !== 'Not Found')
            )
        },
        failedPayNSends(response){
            return response.body.entities.filter(entity =>
                entity.rels.contains('pay-n-sends') &&
                ((entity.entity.properties.get('email_option') === 'retrieve' &&
                entity.entity.properties.get('user') === 'Not Found') ||
                (entity.entity.properties.get('email') !== null &&
                !entity.entity.properties.get('email')?.includes('@')))
            )
        }
    }
}
</script>

<style scoped>

</style>
