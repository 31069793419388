<template>
    <main-template>
        <template v-slot:default="slotProps">
            <page-header :logo="slotProps.logo">Office Tools</page-header>
            <office-submenu-nav :links="slotProps.links">

            </office-submenu-nav>
        </template>
    </main-template>
</template>

<script>
import MainTemplate from "@/v2/templates/MainTemplate";
import PageTitle from "@/common/PageTitle";
import PageHeader from "@/v2/components/Header";
import OfficeSubmenuNav from "@/v2/components/OfficeSubmenuNav";

export default {
    name: "OfficeTools",
    components: {
        OfficeSubmenuNav,
        PageTitle,
        MainTemplate,
        PageHeader
    },
    computed: {
    },
    methods: {
    }
}

</script>

<style scoped>

</style>
