<template>
    <div v-click-outside="hideOptions" class="rounded-md relative">
        <div>
            <label v-if="showLabel"
                for="email"
                class="block text-sm font-medium leading-5 text-gray-700  mt-3"
            >{{field.title}}<span class="text-red-500 ml-1" v-if="required">*</span></label>
            <div
                style="min-width:10rem;"
            >
                <div class="flex flex-wrap form-input checkbox-form-input rounded-md border-gray-300">
                    <div @click="hideOptions" v-if="explodedValue.length" class="selected-values py-1 px-2">
          <span
              v-for="value in explodedValue"
              class="inline-flex whitespace-nowrap items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-indigo-100 text-indigo-800"
          >
            {{value.title}}
            <button
                type="button"
                class="flex-shrink-0 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700"
            >
              <svg @click="removeOption(value)" class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
              </svg>
            </button>
          </span>
                    </div>
                    <input id="input" type="text" @focus="showOptions = true" :name="field.name"
                           @input="changeWidth(this)" v-model="search" class="inline-flex shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md" :placeholder="field.title" autocomplete="off"/>
                </div>
            </div>
        </div>
        <div data-cy="checkbox-dropdown" class="absolute z-20 left-0 right-0 rounded-md bg-white ring-1 ring-black ring-opacity-5 text-left"
             v-if="showOptions">
            <div class="py-1">
                <a
                    v-for="value in filteredOptions"
                    @click.exact="addItem(value, false)"
                    @click.ctrl="addItem(value, true)"
                    @click.meta="addItem(value, true)"
                    class="cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                >{{value.title}}</a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.form-input.checkbox-form-input{
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
    padding:0;
    input{
        padding-top: 0.5rem;
        padding-right: 0.75rem;
        padding-bottom: 0.5rem;
        padding-left: 0.75rem;
    }
}
</style>

<script>
var input = document.getElementById('input'); // get the input element
    if(input){
        input.addEventListener('input', resizeInput); // bind the "resizeInput" callback on "input" event
        resizeInput.call(input); // immediately call the function
    }

function resizeInput(input) {
    input.style.width = input.value.length + "ch";
}

import Multiselect from "vue-multiselect";
import vClickOutside from "v-click-outside";

export default {
    components: {
        Multiselect
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        value: {
            type: String
        },
        field: {
            type: Object
        },
        dependsOn: {
            type: String,
            default: null
        },
        dependsOnValue: {
            type: String,
            default: null
        },
        showLabel:{
            type: Boolean,
            default: true
        }
    },
    computed: {
        filteredOptions() {
            let options = [];
            this.field.options.forEach(function (value) {
                if(this.explodedValue.filter(function(object){
                    if(object.value === value.value) return object;
                }).length > 0 ){return;} else {
                    if (value.title.toLowerCase().includes(this.search.toLowerCase()) || this.search === '') {

                        if (this.dependsOn !== null && this.dependsOnValue !== null) {
                            if(!this.dependsOnValue.toString().includes(',')) {
                                console.log(value[this.dependsOn])
                                if (value[this.dependsOn].toString() === this.dependsOnValue.toString()) {
                                    options.push({
                                        'value': value.value,
                                        'title': value.title
                                    })
                                }
                            }else {
                                console.log(value[this.dependsOn]);
                                if(this.dependsOnValue.split(',').includes(value[this.dependsOn].toString())){
                                    console.log('includes');
                                    let dependsOn = value[this.dependsOn];
                                    this.dependsOnValue.split(',').forEach(function(dependsOnValue){
                                        console.log(dependsOn === parseInt(dependsOnValue));
                                        console.log(dependsOnValue);
                                        if (dependsOn === parseInt(dependsOnValue)) {
                                            options.push({
                                                'value': value.value,
                                                'title': value.title
                                            })
                                        }
                                    })
                                }
                            }
                        } else {
                            options.push({
                                'value': value.value,
                                'title': value.title
                            })
                        }
                    }
                }
            }, this);

            return options
        },
        required: function(){
            if(this.field.validation !== null) {
                return this.field.validation.includes('required');
            }
            return false;
        }
    },
    methods: {
        addItem(value, ctrl) {
            this.$set(this.explodedValue, this.explodedValue.length, value);
            this.clearSearch(ctrl);
        },
        clearSearch(ctrl) {
            this.search = '';
            if(ctrl === false) {
                this.showOptions = false;
            }
        },
        removeOption(value) {
            const index = this.explodedValue.indexOf(value);
            if (index > -1) {
                this.explodedValue.splice(index, 1);
            }
        },
        hideOptions() {
            setTimeout(() => this.showOptions = false, 200);
        },
        startingOptions() {
            let options = [];
            this.field.options.forEach(function (value) {
                if (this.value.split(',').includes(value.value.toString())) {
                    options.push({
                        'value': value.value,
                        'title': value.title
                    })
                }
            }, this);
            return options
        },
        changeWidth(e){
            console.log(e);
        }
    },
    data() {
        return {
            explodedValue: this.value ? this.startingOptions() : [],
            search: '',
            showOptions: false
        };
    },
    watch: {
        explodedValue: {
            handler(value) {
                let ids = [];
                value.forEach(function(val){
                    ids.push(val.value);
                })
                this.$emit("input", ids.toString());
            },
            deep: true
        }
    }
};
</script>

<style scoped>

</style>
