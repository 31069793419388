<template>
    <div class="flex flex-col" v-if="bankDetails">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Bank Details</h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Bank Name
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Name On Account
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Sort Code
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Account Number
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <template v-if="bankDetails">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{bankDetails.entity.properties.get('bank_name')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{bankDetails.entity.properties.get('name_on_account')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{bankDetails.entity.properties.get('sort_code')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{bankDetails.entity.properties.get('account_number')}}
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionWithForm from "./ActionWithForm";
    import ActionWithConfirmation from "./ActionWithConfirmation";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        components: {
            ActionWithConfirmation,
            ActionWithForm,
        },
        computed: {
            bankDetails() {
                let bankDetails = this.response.body.entities.filter(entity => entity.rels.contains('bank-details')).first();
                return bankDetails;
            },
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
