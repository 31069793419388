<template>
    <paginated-list :response="response" actionBaseUrl="/evac">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Name</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Clock In Time</th>
        </template>
        <template v-slot:noItems>
            No workers found.
        </template>
        <template v-slot:tableRows>
            <tr @click="navigateToItem(entity)" v-for="entity in evac" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('user') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{ formatToTime(entity.entity.properties.get('clock_in_time')) }}</div>
                </td>
            </tr>
            <tr v-if="evacNotClocked && evacNotClocked.count() !== 0" class="bg-secondary-lightbacka"><th colspan="2" class="py-2 pl-4 uppercase font-bold pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6 col-span-2">These employees are no longer clocked in at this location</th></tr>
            <tr @click="navigateToItem(entity)" v-for="entity in evacNotClocked" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('user') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{ formatToTime(entity.entity.properties.get('clock_in_time')) }}</div>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import dayjs from "dayjs";
export default {
    name: "EvacPaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        OrderTabs
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        evac() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('evac') && entity.entity.properties.get('still_here') === 'still-here');
        },
        evacNotClocked(){
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('evac') && entity.entity.properties.get('still_here') === 'no-longer-here');
        }
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        formatToTime(datetime){
            if (datetime == null){
                return '';
            }
            if(!dayjs(datetime).isValid()){
                return '';
            }
            return dayjs(datetime).format('HH:mm');
        },
    }
}
</script>

<style scoped>

</style>
