<template>
    <div>
        <div class="bg-gray-100 border border-gray-200 border-b-0 border-r-0 p-6 hidden lg:block">
            <div class="bg-gray-200 overflow-hidden rounded-lg mb-5">
                <div class="px-4 py-5 sm:p-6">
                    <h4 class="uppercase text-xs text-gray-400">Requested By</h4>
                    <p class="text-gray-500 text-xs mb-2">{{orderUser.entity.properties.get('name')}}</p>

                    <h4 data-v-557595cc="" class="uppercase text-xs text-gray-400">Contact Info</h4>
                    <h4 class="text-sm">{{orderUser.entity.properties.get('name')}}</h4>
                    <span class="text-gray-500 text-xs flex">{{orderUser.entity.properties.get('contact_number') ? orderUser.entity.properties.get('contact_number') + ' / ' : ''}}  {{orderUser.entity.properties.get('email')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            response: {
                type: Object
            },
        },
        computed: {
            orderUser() {
                let orderUser = this.response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("user");
                    })
                    .first();

                if (!orderUser) {
                    return null;
                }

                return orderUser;
            },
        },
    }
</script>

<style scoped>

</style>
