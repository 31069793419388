<template>
    <div>
        <h4 class="text-sm leading-5 font-medium text-gray-900" >Activity Feed</h4>

        <div v-for="orderActivity in orderActivities" class="flex justify-start mt-4">
            <template v-if="orderActivity.entity.properties.get('causer')">
                <span class="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-400 mt-1 mr-3">
                    <span class="text-xs font-medium leading-none text-white">{{initials(orderActivity.entity.properties.get('causer'))}}</span>
                </span>
                <div class="w-full">
                    <div class="flex justify-between">
                        <h5 class="font-light text-sm">{{orderActivity.entity.properties.get('causer')}}</h5>
                        <p class="text-gray-400 text-sm">{{orderActivity.entity.properties.get('date')}}</p>
                    </div>
                    <p class="text-gray-400">{{ orderActivity.entity.properties.get('activity').replace('Order status changed from approved ', 'Order status changed from awaiting delivery ') }}</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            orderActivities(orderEntity) {
                let orderActivities = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("activities");
                    });

                if(!orderActivities){
                    return null;
                }

                return orderActivities;
            },
        },
        methods: {
            initials(name) {
                return name
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>