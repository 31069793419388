<template>
    <div v-if="actionField">
        <label class="block text-sm font-medium leading-5 text-gray-400 mb-0 label-a"
        >{{title}}</label
        >
        <div class="form-group" v-if="actionField">
            <component ref="fieldComponent" :is="getInputComponent(actionField)" :field="actionField"
                       v-model="propsVal" :dependsOn="dependsOn" :dependsOnValue="dependsOnValue" :isOptional="isOptional"></component>
            <div v-show="errors.has(validationFieldName)" class="text-red-600">{{ errors.first(validationFieldName) }}
            </div>
        </div>
        <div v-else>
            {{properties.get(propertyKey)}}
        </div>
    </div>
</template>

<script>
    import CheckboxInput from './Action/Fields/CheckboxInput';
    import RadioInput from './Action/Fields/RadioInput';
    import TextInput from './Action/Fields/TextInput';
    import DateInput from './Action/Fields/DateInput';
    import NumberInput from './Action/Fields/NumberInput';
    import TextAreaInput from './Action/Fields/TextAreaInput';

    export default {
        inject: ["$validator"],
        components: {
            CheckboxInput,
            RadioInput,
            TextInput,
            DateInput,
            NumberInput,
            TextAreaInput
        },
        computed: {
            actionField() {
                if (!this.action) {
                    return null;
                }
                return this.action.fields.filter(field => field.name === this.fieldId).first();
            },
            validationFieldName() {
                if (!this.scope) {
                    return this.fieldId;
                }

                return this.scope + '.' + this.fieldId;
            }
        },
        methods: {
            getInputComponent(actionField) {
                if (actionField.name === 'additional_notes') {
                    return 'text-area-input';
                }

                return actionField.type + '-input';
            }
        },
        props: {
            value: {
                type: String
            },
            title: {
                type: String
            },
            action: {
                type: Object
            },
            fieldId: {
                type: String
            },
            propertyKey: {
                type: String
            },
            properties: {
                type: Object
            },
            scope: {
                type: String,
                default: null
            },
            dependsOn: {
                type: String,
                default: null
            },
            dependsOnValue: {
            },
            isOptional: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                propsVal: this.value
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            }
        }
    };
</script>

<style lang="scss" scoped></style>
