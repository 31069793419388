<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <projects-header :response="response"></projects-header>
                        <div class="container mx-auto p-4 mb-8">
                            <filterable-paginated-projects-list :response="response">
                            </filterable-paginated-projects-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import DashboardStats from '../../components/Dashboard/DashboardStats';
    import SavedFilters from '../../components/SavedFilters';
    import ProjectsHeader from '../../components/Headers/ProjectsHeader';
    import FilterablePaginatedProjectsList from "../../components/FilterablePaginatedProjectsList";
    import MainTemplate from "../../templates/MainTemplate";
    export default {
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            ProjectsHeader,
            FilterablePaginatedProjectsList
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
