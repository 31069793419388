<template>
    <paginated-list :response="response" actionBaseUrl="/invoices">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Invoice PDF DAte</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Client</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Reference</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Total Value</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Status</th>
        </template>
        <template v-slot:noItems>
            No sales invoices found.
        </template>
        <template v-slot:tableRows>
            <tr @click="navigateToItem(entity)" v-for="entity in invoices" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('date') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{ entity.entity.properties.get('client_name') }}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-ism text-secondary-darkgrey font-bold">
                    <div>{{entity.entity.properties.get('reference')}}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('grand_total') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('status') }}
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
export default {
    name: "SalesInvoicePaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        OrderTabs
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        invoices() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('invoice'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
