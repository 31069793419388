<template>
    <page-header :logo="logo">
        <div class="md:flex justify-between w-full items-center">
            Widget
            <div class="flex items-center">
                <div class="mr-1">
                    <a-button  @click.native="displayModal = true">Add Widget</a-button>
                    <slide-over v-if="displayModal" title="Add widget"
                                confirmationString=''
                                @removeFromDisplay="displayModal = false" @perform="addWidget">
                        <radio-input :field="widgetTypeValues" v-model="inputValue"></radio-input>
                    </slide-over>
                </div>
            </div>

        </div>
    </page-header>
</template>

<script>
import PageHeader from "@/v2/components/Header";
import Button from "@/v2/buttons/Button";
import SlideOver from "@/v2/components/SlideOver";
import {mapGetters} from "vuex";
export default {
    name: "WidgetHeader",
    components: {
        PageHeader,
        SlideOver,
        'a-button' : Button
    },
    props:{
        response:{
            type: Object
        },
        editable:{
            type: Boolean,
        },
        logo:{
            type: String,
            default: null
        }
    },
    data() {
        return {
            displayModal: false,
            widgetTypeValues: null,
            inputValue:null
        }
    },
    methods: {
        success(key) {
            this.$router.go(0);
        },
        addWidget(){
            EventBus.$emit('addWidget', this.inputValue);
            this.inputValue = null;
            this.displayModal = false;
        }
    },
    created() {
        document.title = 'Widget - CMS';

        this.widgetTypeValues = { name: 'widget_type', title: 'Widget Type', type: 'radio', options: [
                {value: 'stats', title: 'Stats'},
                {value: 'project-locations', title: 'Project Locations'},
                {value: 'orders-in-progress', title: 'Orders In Progress'},
                {value: 'awaiting-approval', title: 'Awaiting Approval'},
                {value: 'awaiting-credit', title: 'Awaiting Credit'},
                {value: 'schedules', title: 'Schedules'},
                {value: 'current-date-time', title: 'Current Date And Time'},
                {value: 'dashboard-clocked-in',title: 'Users Checked In'},
                {value: 'notifications', title: 'Notifications'},
                {value: 'quotes-mismatch', title: 'Quotes Mismatch'}
            ]};
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        }),
    }
}
</script>

<style scoped>

</style>
