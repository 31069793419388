<template>
    <div>
        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div
                        class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                    >
                        <table class="min-w-full" data-cy="orders-table">
                            <thead>
                            <tr v-if="filterAction">
                                <th colspan=1000 class="p-8  bg-gray-50">
                                    <generic-table-filter :action="filterAction"
                                                          :response="response"
                                                          action-base-url="/return-requests"
                                    ></generic-table-filter>
                                </th>
                            </tr>
                            <tr>
                                <th
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                    v-for="headingValue in headingValues"
                                    :key="headingValue"
                                >{{headingValue}}
                                </th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                            </tr>
                            </thead>
                            <tbody v-if="this.response.body.properties.get('total') === 0">
                            <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                                <td colspan="100"
                                    class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                    No return requests found.
                                </td>
                            </tr>
                            </tbody>
                            <tbody>
                            <template v-for="returnRequestEntity, key in returnRequestEntities">
                                <tr class="bg-white hover:bg-gray-100 cursor-pointer"
                                    :class="{'bg-gray-50': !(key % 2 == 0)}">
                                    <template v-for="field, fieldKey in getPropertiesToDisplay(returnRequestEntity)">
                                        <div v-if="fieldKey === 'status'"
                                             class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                        <span
                                            :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 badge-${getStatus(field,key)}`"
                                        >
                                            {{getStatus(field,key)}}
                                        </span>
                                        </div>
                                        <td v-else class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                            <span v-if="field === 'click-to-view'">
                                                <a @click="navigateToItem(returnRequestEntity)"
                                                   data-cy="link-to-item"
                                                   class="underline hover:no-underline text-gray-600 text-xs hover:text-gray-900 mr-2">Click to view details</a>
                                            </span>
                                            <span v-else>
                                                {{field}}
                                            </span>
                                        </td>
                                    </template>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5">
                                        <a @click="navigateToItem(returnRequestEntity)"
                                           data-cy="link-to-item"
                                           class="underline hover:no-underline text-gray-600 text-xs hover:text-gray-900 mr-2">View
                                            Return
                                            Request</a>
                                        <action-with-form class="inline-flex"
                                                          v-if="hasRejectAction(returnRequestEntity) && !successfullyAcceptedOrRejected.includes(key)"
                                                          title="Reject"
                                                          :action="getRejectAction(returnRequestEntity)"
                                                          :redirect-on-success="false"
                                                          @success="rejectedSuccessfully(returnRequestEntity, key)"
                                                          actionKey="reject-return-request"
                                                          :isNegativeAction="hasRejectAction(returnRequestEntity)"
                                        ></action-with-form>
                                        <green-button
                                            data-cy="action-button-accept-return-request"
                                            v-if="hasAcceptAction(returnRequestEntity) && !successfullyAcceptedOrRejected.includes(key)"
                                            @click.native="performAcceptAction(returnRequestEntity, key)">
                                            Accept
                                            <loading v-if="processingAcceptActions.includes(key)"
                                                     class="inline-flex -m-6 relative h-auto -ml-4"></loading>
                                        </green-button>
                                        <div v-show="errors.first('return0.items')"
                                             class="text-red-600">{{
                                            errors.first('return'+key+'.items')}}
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                        <pagination
                            :response="response"
                            @navigate="paginationNavigation($event)"
                            @navigateAdditionalNext="paginationNavigation($event)"
                            @navigateAdditionalPrev="paginationNavigation($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatusBadge from "./StatusBadge";
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    import PrimaryButton from "./PrimaryButton";
    import Button from "./Button";
    import SecondaryButton from "./SecondaryButton";
    import Loading from "./Loading";
    import ActionWithForm from './ActionWithForm';
    import GreenButton from "./GreenButton";

    export default {
        inject: ["$validator"],
        components: {
            SecondaryButton,
            PrimaryButton,
            'a-button': Button,
            StatusBadge,
            Pagination,
            GenericTableFilter,
            Loading,
            ActionWithForm,
            GreenButton
        },
        props: {
            response: {
                type: Object,
            },
        },
        data() {
            return {
                processingAcceptActions: [],
                processingRejectActions: [],
                successfullyAcceptedOrRejected: [],
                successfullyAccepted: [],
                successfullyRejected: [],
            }
        },
        computed: {
            filterAction() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            returnRequestEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('return-request'));
            },
            headingValues() {
                return ['Order reference', 'Qty ordered/delivered', 'Qty in this return', 'Status'];
            }
        },
        methods: {
            getStatus(field, key) {
                if (this.successfullyAccepted.includes(key)) {
                    return 'accepted';
                }

                if (this.successfullyRejected.includes(key)) {
                    return 'rejected';
                }

                return field;
            },
            getPropertiesToDisplay(returnRequestEntity) {
                return {
                    'description': returnRequestEntity.entity.properties.get('order_reference'),
                    'qty_ordered': 'click-to-view',
                    'qty_in_return': returnRequestEntity.entity.properties.get('qty_in_return'),
                    'status': returnRequestEntity.entity.properties.get('status')
                };
            },
            hasAcceptAction(returnRequestEntity) {
                return returnRequestEntity.entity.actions.filter(action => action.name ===
                    'accept-return-request').first();
            },
            hasRejectAction(returnRequestEntity) {
                return returnRequestEntity.entity.actions.filter(action => action.name ===
                    'reject-return-request').first();
            },
            getRejectAction(returnRequestEntity) {
                return returnRequestEntity.entity.actions.filter(action => action.name ===
                    'reject-return-request').first();
            },
            performAcceptAction(returnRequestEntity, key) {
                this.processingAcceptActions.push(key);
                return returnRequestEntity.entity.actions.filter(action => action.name ===
                    'accept-return-request').first().perform().then(response => {
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(response).entity.properties.get("success")
                    )
                    ;
                    this.successfullyAcceptedOrRejected.push(key);
                    this.successfullyAccepted.push(key);
                    this.processingAcceptActions.splice(this.processingAcceptActions.indexOf(key), 1);
                    this.errors.clear();
                }).catch(error => {
                    this.processingAcceptActions.splice(this.processingAcceptActions.indexOf(key), 1);
                    if (error.response.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    alert('Error.' + error.response.text);
                });
            },
            rejectedSuccessfully(returnRequestEntity, key) {
                this.successfullyRejected.push(key);
                this.successfullyAcceptedOrRejected.push(key);
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            navigateToItem(returnRequestEntity) {
                let selfLink = returnRequestEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
