<template>
    <paginated-list :response="response" actionBaseUrl="/return-requests">
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Order Reference</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Qty Ordered/Delivered</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Qty in this return</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Status</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey"></th>
        </template>
        <template v-slot:tableRows>
            <tr v-for="returnRequestEntity in orders" class="hover:bg-secondary-lightbacka">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ returnRequestEntity.entity.properties.get('order_reference') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div><a class="underline" href="#" @click="navigateToItem(returnRequestEntity)">Click to view details</a></div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{returnRequestEntity.entity.properties.get('qty_in_return')}}</div>
                </td>

                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey">
                    <span
                        :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 badge-${getStatus(returnRequestEntity.entity.properties.get('status'),'status')}`"
                    >
                        {{getStatus(returnRequestEntity.entity.properties.get('status'),'status')}}
                    </span>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">

                </td>
            </tr>


        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";
export default {
    name: "ScheduleManagerPaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        DownloadPdfIconWithLink
    },
    props:{
        response:{
            type: Object
        }
    },
    data(){
        return {
            processingAcceptActions: [],
            processingRejectActions: [],
            successfullyAcceptedOrRejected: [],
            successfullyAccepted: [],
            successfullyRejected: [],
        }
    },
    computed:{
        orders() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('return-request'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
            window.location = parts[1];
        },
        getStatus(field, key) {
            if (this.successfullyAccepted.includes(key)) {
                return 'accepted';
            }

            if (this.successfullyRejected.includes(key)) {
                return 'rejected';
            }

            return field;
        },
    }
}
</script>

<style scoped>

</style>
