<template>
<tr class="hover:bg-secondary-lightbacka">

    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
        <div>{{ device['name'] }}
        </div>
    </td>
    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold">
        <div>{{ device['uniqueId'] }}
        </div>
    </td>
    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold">
        <div>{{ device['status'] === 'unknown'? 'offline' : device['status'] }}
        </div>
    </td>
    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold">
        <div>{{ getFormattedDate(device['lastUpdate']) }}
        </div>
    </td>
    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold">
        <div>{{ getMiles(positionDetails['data'][0]['attributes']['totalDistance']) ? getMiles(positionDetails['data'][0]['attributes']['totalDistance']).toFixed(2) : getMiles(positionDetails['data'][0]['attributes']['totalDistance']) }}
        </div>
    </td>
    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold">
        <div>{{ getMiles(positionDetails['data'][0]['attributes']['odometer']) ? getMiles(positionDetails['data'][0]['attributes']['odometer']).toFixed(2) : getMiles(positionDetails['data'][0]['attributes']['odometer']) }}
        </div>
    </td>
    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold">
        <div>lat: {{ positionDetails['data'][0]['latitude'] }}<br>
            long: {{ positionDetails['data'][0]['longitude'] }}
        </div>
    </td>
    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold cursor-pointer underline">
        <div>
            <a href="#" @click="viewDetails = true">View All Details</a>
        </div>
    </td>

    <large-model v-if="viewDetails" @removeFromDisplay="viewDetails = false" :displayConfirmButton="false" cancelButtonText="Close">
        <p class="text-lg bold">Viewing {{ device['name'] }}</p>
        <p class="mt-4">
            <table>
                <template v-for="(details, title) in device" >
                    <tr v-if="details !== null && title !== 'id' && title !== 'positionId'">
                    {{ title }}: {{ details }}
                    </tr>
                </template>
                <template v-for="(details, title) in positionDetails['data'][0]" v-if="positionDetails['data'][0]">
                    <tr v-if="details !== null && title !== 'attributes' && title !== 'deviceId' && title !== 'id'">
                        {{ title }}: {{ details }}
                    </tr>
                    <template v-else-if="title === 'attributes'" >
                        <tr v-for="(attributes, attributeTitle) in details">
                            {{attributeTitle}}: {{attributes}}
                        </tr>
                    </template>
                </template>
            </table>
        </p>
    </large-model>
</tr>
</template>
<script>
import axios from "axios";
import LargeModel from "@/v2/components/LargeModel.vue";
import moment from "moment";

export default {
  name: 'traccar-device-row',
    components: {LargeModel},
    data() {
        return {
            positionDetails: null,
            viewDetails: false
        }
    },
  props: {
    device: {
        type: Array
    },
  },
    mounted(){
            let that = this;
            axios.get('https://traccar.companiesms.co.uk/api/positions?id=' + this.device['positionId'],{
                headers: {
                    'Authorization': 'Basic cm9iZXJ0QGJldHRyLnNvZnR3YXJlOlVWI3ltcWpYdnUyaWkydEc=',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(function (response) {
                    that.positionDetails = response;
                })
                .catch(function (error) {
                    console.log(error);
                });

    },
    methods:{
      getMiles(meters){
          if(meters !== null && meters !== undefined) {
              return meters * 0.000621371192;
          }
          return null;
        },
        getFormattedDate(date){
            var dateObj = new Date(date)
            return moment(dateObj).format('D/MM/Y HH:m:s')
        }
    }
}
</script>
