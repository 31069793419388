<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Daily Summary</h2>

                <div class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => action.name ==='show-oss-daily-report')"
                         class="mr-1">
                        <div v-if="actionWithFields[0] === 'show-oss-daily-report'">
                            <template v-for="(field, index) in actionWithFields[1]['fields']">
                                <component is="radio-input" :field="field[1]" v-model="organisationId" @input="clientChanged">
                                </component>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import Button from '../Button';
    import ActionWithConfirmation from '../ActionWithConfirmation';
    import ActionWithForm from '../ActionWithForm';
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import RadioInput from "@/components/Action/Fields/RadioInput";
    import fileDownload from "js-file-download";

    export default {
        inject: ["$validator"],
        components: {
            'a-button': Button,
            ActionWithConfirmation,
            ActionWithForm,
            RadioInput
        },
        props: {
            response: {
                type: Object,
            },
        },
        data(){
            return {
                organisationId: null
            }
        },
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'filter')
            },

        },
        methods: {
            success(key) {
                this.$router.go(0);
            },
            clientChanged() {
                this.$router.push(this.$route.path + '?date=' + this.$route.query['date'] + '&organisationId=' + this.organisationId);
            },
            organisationIdFromAction(){
                let fields = this.remaningActions.filter(action => action.name ==='show-oss-daily-report').first().fields;
                if (!fields) {
                    return null;
                }
                console.log(fields.first()['value']);
                this.organisationId = fields.first()['value'];
                return fields.first()['value'];
            },
        },
        created() {
            document.title = 'Daily Summary - CMS';
            this.organisationIdFromAction();
        },
    }
</script>

<style lang="scss" scoped>

</style>
