<template>
    <div>
        <div class="py-6">
            <div class="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                <order-tabs :response="response"></order-tabs>
                <div>
                    <div>
                        <div class="flex flex-col">
                            <div class="-mt-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mb-8">
                                        <div class="bg-white">
                                            <order-filters :response="response" :action="filterAction" :actionBaseUrl="actionBaseUrl()"></order-filters>
                                        </div>

                                        <table class="min-w-full">
                                            <thead class="bg-secondary-lightbacka">
                                            <tr>
                                                <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Flag</th>
                                                <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Order Details</th>
                                                <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Project Details</th>
                                                <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Created</th>
                                                <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Last Updated</th>
                                                <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Status</th>
                                            </tr>
                                            </thead>
                                            <tbody v-if="this.response.body.properties.get('total') === 0">
                                            <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                                                <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                                    No orders found.
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tbody v-else class="divide-y divide-trueGray-200 bg-white">
                                            <tr v-for="orderEntity in orders" class="hover:bg-secondary-lightbacka cursor-pointer">
                                                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                                                    <flag-order-action class="ml-1" v-if="orderEntity.entity.actions.get('flag-order')" :hasFlag="orderEntity.entity.properties.get('has_flag')" :action="orderEntity.entity.actions.get('flag-order')" :onSuccessPath="$route.path" :redirect-on-success="false">
                                                    </flag-order-action>
                                                </td>
                                                <td @click="navigateToItem(orderEntity)" class="whitespace-normal px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                                                    <div>{{ orderEntity.entity.properties.get('order_reference') }}
                                                        <template v-if="orderEntity.entity.properties.get('purchase_order_number')">
                                                            /
                                                            {{ orderEntity.entity.properties.get('purchase_order_number') }}
                                                        </template>
                                                    </div>
                                                    <div>"{{ orderEntity.entity.properties.get('quick_reference') }}"</div>
                                                </td>
                                                <td @click="navigateToItem(orderEntity)" class="whitespace-normal px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                                                    <div>{{ orderEntity.entity.properties.get('project') }} - {{orderEntity.entity.properties.get('site')}}</div>
                                                    <div></div>
                                                </td>
                                                <td @click="navigateToItem(orderEntity)" class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                                                    <div>By {{ orderEntity.entity.properties.get('created_by') }}</div>
                                                    <div>{{ showCreatedDaysAgo(orderEntity) }} <span class="text-xs font-normal">({{ orderEntity.entity.properties.get('date') }})</span></div>
                                                </td>
                                                <td @click="navigateToItem(orderEntity)" class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                                                    <template v-if="orderEntity.entity.properties.get('updated_by') !== null">
                                                        <div>By {{ orderEntity.entity.properties.get('updated_by') }}</div>
                                                    </template>
                                                    <template v-else>
                                                        <div>By Unknown User</div>
                                                    </template>
                                                    <template v-if="orderEntity.entity.properties.get('updated_at') !== 'Unknown Date'">
                                                        <div>{{ showUpdatedDaysAgo(orderEntity) }} <span class="text-xs font-normal">({{ orderEntity.entity.properties.get('updated_at') }})</span></div>
                                                    </template>
                                                    <template v-else>
                                                        <div><span class="text-xs font-normal">({{ orderEntity.entity.properties.get('updated_at') }})</span></div>
                                                    </template>
                                                </td>
                                                <td @click="navigateToItem(orderEntity)" class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey">
                                                    <status-badge :status="orderEntity.entity.properties.get('status')"></status-badge>
                                                </td>
                                            </tr>
                                            </tbody>

                                        </table>
                                        <pagination :response="response"></pagination>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from "@/components/StatusBadge";
import OrderFilters from "@/v2/filters/OrderFilters";
import OrderTabs from "@/v2/tabs/OrderTabs";
import Pagination from "@/v2/paginatedLists/Pagination";
import dayjs from "dayjs"
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
let config = {
    rounding: Math.floor
}
dayjs.extend(duration);
dayjs.extend(relativeTime, config);
export default {
    name: "OrderPaginatedList",
    components: {FlagOrderAction, ConfirmationAction, OrderTabs, OrderFilters, StatusBadge, Pagination},
    props: {
        response: {
            type: Object,
        },
    },
    computed: {
        orders() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('order'));
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        }
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        actionBaseUrl(){
            if(this.$route.name === 'TakeOffs'){
                return '/take-off-orders';
            }
            if(this.$route.name === 'MismatchQuotes'){
                return '/quotes-mismatch'
            }
            if(this.$route.name === 'ScheduleManager'){
                return '/schedule-manager'
            }
            if(this.$route.name === 'TenderOrders'){
                return '/tender-orders'
            }


            return '/orders';
        },
        showCreatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('created_at'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        },
        showUpdatedDaysAgo(orderEntity){
            let createdDate = dayjs(orderEntity.entity.properties.get('updated_at_unformatted'));
            return dayjs.duration(createdDate.diff(dayjs(), 'seconds'),'seconds').humanize(true)
        }
    }
}
</script>

<style scoped>

</style>
