<template>
    <div class="flex flex-col" v-if="response && invoices.size > 0">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Responses</h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Quote
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Name
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Email
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Proof
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <template v-for="invoice in invoices">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ invoice.entity.properties.get('quote') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                        {{ invoice.entity.properties.get('name') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ invoice.entity.properties.get('email') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <template v-if="showPDF">
                                <download-pdf :url="invoice.entity.properties.get('proof_file')" @removeFromDisplay="showPDF = false"></download-pdf>
                            </template>
                            <a href="#" @click.prevent="showPDF = true" target="_blank">View Proof</a>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import DownloadPdf from "../../pages/DownloadPdf";
    export default {
        components:{DownloadPdf},
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                submittingByCard: false,
                invoiceFile: null,
                showPDF:false
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            invoices() {
                let invoices = this.response.body.entities.filter(entity => entity.rels.contains('responses'));
                return invoices;
            },
        },
        methods: {
            getAbsolutePdfLink(url) {
                return '/files/'+url;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
