<template>
    <div>
        <a-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">

            <component v-if="getField('first_name')" :is="getFieldComponent(getField('first_name'))" :field="getField('first_name')" v-model="formData[getField('first_name').name]">
            </component>
            <div v-show="errors.has(getField('first_name').name)" class="text-red-600 text-sm">{{ errors.first(getField('first_name').name) }}</div>

            <component v-if="getField('surname')" :is="getFieldComponent(getField('surname'))" :field="getField('surname')" v-model="formData[getField('surname').name]">
            </component>
            <div v-show="errors.has(getField('surname').name)" class="text-red-600 text-sm">{{ errors.first(getField('surname').name) }}</div>

            <component v-if="getField('email')" :is="getFieldComponent(getField('email'))" :field="getField('email')" v-model="formData[getField('email').name]">
            </component>
            <div v-show="errors.has(getField('email').name)" class="text-red-600 text-sm">{{ errors.first(getField('email').name) }}</div>

            <component v-if="getField('password')" :is="getFieldComponent(getField('password'))" :field="getField('password')" v-model="formData[getField('password').name]">
            </component>
            <div v-show="errors.has(getField('password').name)" class="text-red-600 text-sm">{{ errors.first(getField('password').name) }}</div>

            <component v-if="getField('contact_number')" :is="getFieldComponent(getField('contact_number'))" :field="getField('contact_number')" v-model="formData[getField('contact_number').name]">
            </component>
            <div v-show="errors.has(getField('contact_number').name)" class="text-red-600 text-sm">{{ errors.first(getField('contact_number').name) }}</div>

            <component v-if="getField('role_ids')" :is="getFieldComponent(getField('role_ids'))" :field="getField('role_ids')" v-model="formData[getField('role_ids').name]">
            </component>
            <div v-show="errors.has(getField('role_ids').name)" class="text-red-600 text-sm">{{ errors.first(getField('role_ids').name) }}</div>

            <component v-if="getField('payment_type')" :is="getFieldComponent(getField('payment_type'))" :field="getField('payment_type')" v-model="formData[getField('payment_type').name]">
            </component>
            <div v-show="errors.has(getField('payment_type').name)" class="text-red-600 text-sm">{{ errors.first(getField('payment_type').name) }}</div>

            <component v-if="getField('hourly_rate')" :is="getFieldComponent(getField('hourly_rate'))" :field="getField('hourly_rate')" v-model="formData[getField('hourly_rate').name]">
            </component>
            <div v-show="errors.has(getField('hourly_rate').name)" class="text-red-600 text-sm">{{ errors.first(getField('hourly_rate').name) }}</div>

            <component v-if="getField('unique_taxpayer_reference')" :is="getFieldComponent(getField('unique_taxpayer_reference'))" :field="getField('unique_taxpayer_reference')" v-model="formData[getField('unique_taxpayer_reference').name]">
            </component>
            <div v-show="errors.has(getField('unique_taxpayer_reference').name)" class="text-red-600 text-sm">{{ errors.first(getField('unique_taxpayer_reference').name) }}</div>

            <component v-if="getField('contract_start_date')" :is="getFieldComponent(getField('contract_start_date'))" :field="getField('contract_start_date')" v-model="formData[getField('contract_start_date').name]">
            </component>
            <div v-show="errors.has(getField('contract_start_date').name)" class="text-red-600 text-sm">{{ errors.first(getField('contract_start_date').name) }}</div>

            <component v-if="getField('holidays_per_year')" :is="getFieldComponent(getField('holidays_per_year'))" :field="getField('holidays_per_year')" v-model="formData[getField('holidays_per_year').name]">
            </component>
            <div v-show="errors.has(getField('holidays_per_year').name)" class="text-red-600 text-sm">{{ errors.first(getField('holidays_per_year').name) }}</div>

            <component v-if="getField('accounting_code')" :is="getFieldComponent(getField('accounting_code'))" :field="getField('accounting_code')" v-model="formData[getField('accounting_code').name]">
            </component>
            <div v-show="errors.has(getField('accounting_code').name)" class="text-red-600 text-sm">{{ errors.first(getField('accounting_code').name) }}</div>

        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";

export default {
    name: "AddClientUserForm",
    data() {
        return {
            submittingModal: false
        }
    },
    components: {
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        }
    },
}
</script>

<style scoped>

</style>
