<template>
    <div class="order-summary">
<!--        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Order Summary</h3>-->
        <div class="generic-summary p-4" :key="key">
            <div class="grid grid-cols-1 gap-4">
                <property-or-field v-if="updateAction.fields.filter(field => field.name === 'organisation_id').first().type !== 'hidden'" @input="updateFields" title="Client" :action="updateAction" fieldId="organisation_id" propertyKey="organisationId" v-model="updateActionData['organisation_id']"></property-or-field>
                <property-or-field @input="updateFields" title="Groups" :action="updateAction" fieldId="user_group" propertyKey="user_group" v-model="updateActionData['user_group']" dependsOn="client_id" :dependsOnValue="updateActionData['organisation_id']"></property-or-field>
                <property-or-field @input="updateFields" title="Employee" :action="updateAction" fieldId="user_id" propertyKey="user_id" v-model="updateActionData['user_id']" dependsOn="client_id" :dependsOnValue="updateActionData['organisation_id']" dependsOn2="user_group_id" :dependsOnValue2="updateActionData['user_group']"></property-or-field>
                <property-or-field @input="updateFields" title="Project" :action="updateAction" fieldId="project_id" propertyKey="project_id" v-model="updateActionData['project_id']" dependsOn="client_id" :dependsOnValue="updateActionData['organisation_id']"></property-or-field>
                <property-or-field @input="updateFields" title="Show Deactivated Users" :action="updateAction" fieldId="show_deactivated_users" propertyKey="show_deactivated_users" v-model="updateActionData['show_deactivated_users']"></property-or-field>
            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import DownloadPdf from "../pages/DownloadPdf";
    export default {
            inject: ["$validator"],
        components: {
            DownloadPdf,
            PropertyOrField,
        },
        data() {
            return {
                key : 0
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'show-oss-daily-report').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
        },
        methods: {
            updateFields(){
                let organisationIdQuery = '';
                if(this.updateActionData['organisation_id'] !== null){
                    organisationIdQuery = '&organisation_id='+ this.updateActionData['organisation_id'];
                }
                let date = this.$route.query['date'];
                if(this.$route.query['date'] === undefined || this.$route.query.date === null){
                    date = null
                }
                let userGroupIdQuery = '';
                if(this.updateActionData['user_group'] !== null){
                  userGroupIdQuery = '&user_group='+ this.updateActionData['user_group'];
                }
                let showDeactivatedUsersQuery = '';
                if(this.updateActionData['show_deactivated_users'] !== null && this.updateActionData['show_deactivated_users'] !== undefined){
                    showDeactivatedUsersQuery = '&show_deactivated_users='+ this.updateActionData['show_deactivated_users'];
                }
                this.$router.push(this.$route.path+'?date='+date+'&project_id='+this.updateActionData['project_id']+ '&user_id='+ this.updateActionData['user_id']+organisationIdQuery+userGroupIdQuery+showDeactivatedUsersQuery);
            },
            change(){
                this.$emit('change');
            },
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
