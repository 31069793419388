<template>
    <main-template>
        <template  slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        Clients
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span @click="navigateToClient(response)" class="cursor-pointer">
                            {{response.body.properties.get('name')}}
                        </span>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            Account
                        </span>

                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-4">
                                        <div>
                                            Packages
                                            <ul role="list" class="divide-y divide-gray-200">
                                                <li class="flex py-4 cursor-pointer" @click="currentPackage = 'procurement'">
                                                    <div class="ml-3">
                                                        <p class="text-sm font-medium text-gray-900" :class="{'font-bold': currentPackage==='procurement'}">Procurement (Buying Group)</p>
                                                    </div>
                                                </li>
                                                <li class="flex py-4 cursor-pointer" @click="currentPackage = 'oss'">
                                                    <div class="ml-3">
                                                        <p class="text-sm font-medium text-gray-900" :class="{'font-bold': currentPackage==='oss'}">On-Site Selfie</p>
                                                    </div>
                                                </li>
                                                <li class="flex py-4 cursor-pointer" @click="currentPackage = 'apps'">
                                                    <div class="ml-3">
                                                        <p class="text-sm font-medium text-gray-900" :class="{'font-bold': currentPackage==='apps'}">Apps</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-span-3 border-gray-200 border-2 mx-5">
                                            <div v-if="currentPackage === 'procurement'">
                                                <paginated-list class="-mb-8" :response="response" :useEntities="true" :hasEntities="true">
                                                    <template v-slot:headers>
                                                        <th scope="col" class="flex-inline py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">
                                                            <div class="flex items-center">
                                                                <span class="mr-1">CMS Pro</span>
                                                                <template v-if="hasProcurementPackage(response, 'cms_pro')">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                                                    </svg>
                                                                </template>
                                                                <template v-else>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-error">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </template>
                                                            </div>

                                                        </th>
                                                        <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">
                                                            <div class="flex items-center">
                                                                <span class="mr-1">CMS Pro+</span>
                                                                <template v-if="hasProcurementPackage(response, 'cms_pro+')">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                                                    </svg>
                                                                </template>
                                                                <template v-else>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-error">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </template>
                                                            </div>
                                                        </th>
                                                        <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">
                                                            <div class="flex items-center">
                                                                <span class="mr-1">CMS Enterprise</span>
                                                                <template v-if="hasProcurementPackage(response, 'cms_enterprise')">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                                                    </svg>
                                                                </template>
                                                                <template v-else>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-error">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </template>
                                                            </div>
                                                        </th>
                                                    </template>
                                                    <template v-slot:tableRows>
                                                        <tr class="hover:bg-secondary-lightbacka cursor-pointer">
                                                            <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                                                                <div>
                                                                    Price per user: £250<br>
                                                                    Max orders: £100,000.00<br>
                                                                    Buying Group Member: Yes
                                                                </div>
                                                            </td>
                                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                                                                <div>
                                                                    Price per user: £400<br>
                                                                    Max orders: £500,000.00<br>
                                                                    Buying Group Member: Yes
                                                                </div>
                                                            </td>
                                                            <td class="whitespace-nowrap px-3 py-4 text-ism text-secondary-darkgrey font-bold">
                                                                <div>
                                                                    Price per user: £600<br>
                                                                    Max orders: unlimited<br>
                                                                    Buying Group Member: Yes
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </paginated-list>
                                            </div>
                                            <div v-if="currentPackage === 'oss'">
                                                <paginated-list class="-mb-8" :response="response" :useEntities="true" :hasEntities="true">
                                                    <template v-slot:headers>
                                                        <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">
                                                            <div class="flex items-center">
                                                                <span class="mr-1">User Package</span>
                                                                <template v-if="hasOssPackage(response, 'user')">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                                                    </svg>
                                                                </template>
                                                                <template v-else>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-error">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </template>
                                                            </div>
                                                        </th>
                                                        <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">
                                                            <div class="flex items-center">
                                                                <span class="mr-1">Project Package</span>
                                                                <template v-if="hasOssPackage(response, 'project')">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                                                    </svg>
                                                                </template>
                                                                <template v-else>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-error">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </template>
                                                            </div>
                                                        </th>
                                                    </template>
                                                    <template v-slot:tableRows>
                                                        <tr class="hover:bg-secondary-lightbacka cursor-pointer">
                                                            <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                                                                <div>
                                                                    Price per user: £3<br>
<!--                                                                    Current User count:<br>-->
                                                                </div>
                                                            </td>
                                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                                                                <div>
                                                                    Price per project: £450<br>
<!--                                                                    Current project count: <br>-->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </paginated-list>
                                            </div>
                                            <div v-if="currentPackage === 'apps'">
                                                <paginated-list class="-mb-8" :response="response" :useEntities="true" :hasEntities="true">
                                                    <template v-slot:headers>
                                                        <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">
                                                            <div class="flex items-center">
                                                                <span class="mr-1">User Package</span>
                                                                <template v-if="hasAppsPackage(response, 'user')">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                                                    </svg>
                                                                </template>
                                                                <template v-else>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-error">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </template>
                                                            </div>
                                                        </th>
                                                        <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">
                                                            <div class="flex items-center">
                                                                <span class="mr-1">Project Package</span>
                                                                <template v-if="hasAppsPackage(response, 'project')">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                                                    </svg>
                                                                </template>
                                                                <template v-else>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-error">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </template>
                                                            </div>
                                                        </th>
                                                    </template>
                                                    <template v-slot:tableRows>
                                                        <tr class="hover:bg-secondary-lightbacka cursor-pointer">
                                                            <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                                                                <div>
                                                                    Price per user: £2<br>
                                                                    <!--                                                                    Current User count:<br>-->
                                                                </div>
                                                            </td>
                                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                                                                <div>
                                                                    Price per project: £300<br>
                                                                    <!--                                                                    Current project count: <br>-->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </paginated-list>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>


                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1">
                                        <client-account-invoice-form ref="accountInvoiceForm" :response="response" @change="change"></client-account-invoice-form>
                                        <client-invoice-paginated-list :response="response"></client-invoice-paginated-list>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </template>
                <div v-if="hasChangesWaitingOnSave"
                     style="right:0px;left:6rem;"
                     class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                    <a-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                        Account
                    </a-button>
                </div>

            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import ClientAccountInvoiceForm from "@/v2/components/forms/ClientAccountInvoiceForm";
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import Siren from "super-siren";
import ClientInvoicePaginatedList from "@/v2/paginatedLists/ClientInvoicePaginatedList";
export default {
    name: "ClientAccount",
    data() {
        return {
            hasChangesWaitingOnSave: false,
            loading: false,
            currentPackage: 'procurement'
        }
    },
    components: {
        ClientInvoicePaginatedList,
        PaginatedList,
        ClientAccountInvoiceForm,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToClient(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        change() {
            this.hasChangesWaitingOnSave = true;
        },
        async saveData() {
            this.loading = true;
            this.$setLaravelValidationErrorsFromResponse('');
            this.$refs.accountInvoiceForm.update().then(res => {
                this.$store.dispatch(
                    "setMessage",
                    'The account was saved successfully'
                );
                this.hasChangesWaitingOnSave = false;
                this.loading = false;
            }).catch(error => {
                this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                this.loading = false;
            })
        },
        hasProcurementPackage(response, packageName){
            return response.body.properties.get('procurement_package') === packageName;
        },
        hasOssPackage(response, packageName){
            return response.body.properties.get('oss_package') === packageName;
        },
        hasAppsPackage(response, packageName){
            return response.body.properties.get('apps_package') === packageName;
        }
    }
}
</script>

<style scoped>

</style>
