<template>
    <div :key="$route.fullPath" class="create-new-order">
        <main-template class="font-fonta">
            <template slot-scope="{logo}">
            <page-header :logo="logo">
                <div class="md:flex justify-between w-full items-center">
                    Create New Order
                    <div >
                        <span data-v-1b26be5a=""
                              class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 badge-pending">
                                <status-badge status="pending"></status-badge>
                        </span>
                    </div>
                </div>
            </page-header>

        <loading v-if="loading"></loading>
        <div class="px-16 py-16" v-if="!loading" :key="key">
            <template>
                <div>
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <div class="px-4 sm:px-0">
                                <h3 class="text-lg font-medium leading-6 text-gray-900">Contract / User Information</h3>
                                <p class="mt-1 text-sm text-gray-600">
                                    Basic Contract / User Information goes here
                                </p>
                            </div>
                        </div>
                        <div class="mt-5 md:mt-0 md:col-span-2">
                            <form action="#" method="POST">
                                <div class="shadow sm:rounded-md sm:overflow-hidden">
                                    <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                        <div>
                                            <div>
                                                <property-or-field title="Client" :action="createNewOrderAction"
                                                                   fieldId="client_id" propertyKey="client"
                                                                   v-model="createNewOrderActionData['client_id']"
                                                                   @input="setClientId"></property-or-field>
                                            </div>
                                        </div>

                                        <div>
                                                <dependant-field title="Project" :action="createNewOrderAction"
                                                                 fieldId="project_id"
                                                                 dependsOn="client_id"
                                                                 :dependsOnValue="clientId"
                                                                 v-model="createNewOrderActionData['project_id']"
                                                                 @input="setProjectId"></dependant-field>
                                        </div>

                                        <div>
                                            <dependant-field @input="change" title="Site" :action="createNewOrderAction"
                                                             fieldId="site_id"
                                                             dependsOn="project_id"
                                                             :dependsOnValue="projectId"
                                                             v-model="createNewOrderActionData['site_id']"
                                            ></dependant-field>
                                        </div>
                                        <div>
                                            <dependant-field title="Site Manager" :action="createNewOrderAction"
                                                             fieldId="site_manager_id" propertyKey="user_id"
                                                             dependsOn="client_id"
                                                             :dependsOnValue="clientId"
                                                             v-model="createNewOrderActionData['site_manager_id']"></dependant-field>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="hidden sm:block" aria-hidden="true">
                    <div class="py-12">
                        <div class="border-t border-gray-200" />
                    </div>
                </div>

                <div class="mt-10 sm:mt-0">
                    <div class="md:grid md:grid-cols-3 md:gap-6 space-y-6">
                        <div class="mt-5 md:col-span-1">
                            <div class="px-4 sm:px-0">
                                <h3 class="text-lg font-medium leading-6 text-gray-900">Schedule Information</h3>
                                <p class="mt-1 text-sm text-gray-600">
                                    Basic schedule information goes here
                                </p>
                            </div>
                        </div>
                        <div class="mt-5 md:mt-0 md:col-span-2">
                            <form action="#" method="POST">
                                <div class="shadow overflow-hidden sm:rounded-md">
                                    <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                        <div>
                                            <div>
                                                <property-or-field @input="changeScheduledFile" title="Scheduled File"
                                                                   :action="createNewOrderAction" fieldId="scheduled_file"
                                                                   propertyKey="scheduled_file"
                                                                   v-model="createNewOrderActionData['scheduled_file']"></property-or-field>
                                            </div>

                                            <div>
                                                <property-or-field @input="change" title="Expected By Date" v-if="createNewOrderActionData['scheduled_file'] === null"
                                                                   :action="createNewOrderAction" fieldId="expected_by_date"
                                                                   propertyKey="expected_by_date"
                                                                   v-model="createNewOrderActionData['expected_by_date']"></property-or-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="hidden sm:block" aria-hidden="true">
                    <div class="py-12">
                        <div class="border-t border-gray-200" />
                    </div>
                </div>

                <div class="mt-10 sm:mt-0">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <div class="px-4 sm:px-0">
                                <h3 class="text-lg font-medium leading-6 text-gray-900">Specifications</h3>
                                <p class="mt-1 text-sm text-gray-600 font-normal">
                                    Specifications go here
                                </p>
                            </div>
                        </div>
                        <div class="mt-5 md:mt-0 md:col-span-2">
                            <form action="#" method="POST">
                                <div class="shadow overflow-hidden sm:rounded-md">
                                    <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                        <div>
                                            <property-or-field @input="change" title="Quick Reference"
                                                                :action="createNewOrderAction" fieldId="quick_reference"
                                                                propertyKey="quick_reference"
                                                                v-model="createNewOrderActionData['quick_reference']"></property-or-field>
                                        </div>
                                        <div>
                                            <property-or-field @input="change" title="Specifications"
                                                               :action="createNewOrderAction" fieldId="specifications" propertyKey="specifications"
                                                               v-model="createNewOrderActionData['specifications']"></property-or-field>
                                        </div>
                                        <div>
                                            <property-or-field @input="changeDeliveryMethod" title="Delivery Method"
                                                               :action="createNewOrderAction" fieldId="delivery_method"
                                                               propertyKey="delivery_method"
                                                               v-model="createNewOrderActionData['delivery_method']"></property-or-field>
                                        </div>
                                        <div v-if="createNewOrderActionData['delivery_method'] === 'pick_up'">
                                            <property-or-field v-if="createNewOrderActionData['delivery_method'] === 'pick_up'" @input="change" title="Supplier"
                                                               :action="createNewOrderAction" fieldId="supplier"
                                                               propertyKey="supplier"
                                                               v-model="createNewOrderActionData['supplier']"></property-or-field>
                                        </div>
                                        <div v-if="createNewOrderActionData['delivery_method'] === 'pick_up'">
                                            <property-or-field v-if="createNewOrderActionData['delivery_method'] === 'pick_up'" @input="change" title="Pick Up Location"
                                                               :action="createNewOrderAction" fieldId="pick_up_location"
                                                               propertyKey="pick_up_location"
                                                               v-model="createNewOrderActionData['pick_up_location']"></property-or-field>
                                        </div>
                                        <div>
                                            <property-or-field @input="change" title="Delivery must be booked"
                                                               :action="createNewOrderAction" fieldId="booking_delivery"
                                                               propertyKey="booking_delivery"
                                                               v-model="createNewOrderActionData['booking_delivery']"></property-or-field>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="hidden sm:block" aria-hidden="true">
                    <div class="py-12">
                        <div class="border-t border-gray-200" />
                    </div>
                </div>

                <div class="mt-10 sm:mt-0">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <div class="px-4 sm:px-0">
                                <h3 class="text-lg font-medium leading-6 text-gray-900">Notes / Attachments</h3>
                                <p class="mt-1 text-sm text-gray-600">
                                    Notes and attachments go here
                                </p>
                            </div>
                        </div>
                        <div class="mt-5 md:mt-0 md:col-span-2">
                            <form action="#" method="POST">
                                <div class="shadow overflow-hidden sm:rounded-md">
                                    <div class="px-4 py-5 bg-white space-y-6 sm:p-6 grid-cols-1">
                                        <div>
                                            <property-or-field @input="change" title="Supplier Notes"
                                                               :action="createNewOrderAction" fieldId="additional_notes"
                                                               propertyKey="additional_notes"
                                                               v-model="createNewOrderActionData['additional_notes']"></property-or-field>
                                        </div>
                                        <div>
                                            <property-or-field @input="change" title="Procurement Notes"
                                                               :action="createNewOrderAction" fieldId="procurement_notes"
                                                               propertyKey="procurement_notes"
                                                               v-model="createNewOrderActionData['procurement_notes']"></property-or-field>
                                        </div>
                                        <div>
                                            <property-or-field @input="change" title="Attachment"
                                                               :action="createNewOrderAction" fieldId="attachment"
                                                               propertyKey="attachment"
                                                               v-model="createNewOrderActionData['attachment']"></property-or-field>
                                        </div>
                                        <div>
                                            <property-or-field @input="change" title="Question"
                                                               :action="createNewOrderAction" fieldId="question"
                                                               propertyKey="question"
                                                               v-model="createNewOrderActionData['question']"></property-or-field>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </template>
            <div
                v-if="!loading"
                class="p-3 py-10 bottom-0 w-full flex justify-end z-40">
                <a-button @click.native="createOrder()" class="cursor-pointer" data-cy="submit" :loading="submitting">
                    Create
                    Order
                </a-button>
            </div>
        </div>


            </template>
        </main-template>
    </div>
</template>

<script>
    import Loading from '@/components/Loading';
    import Button from '@/v2/buttons/Button';
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import PropertyOrField from "@/components/PropertyOrField";
    import PageTitle from "@/common/PageTitle";
    import DependantField from "@/components/DependantField";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import PageHeader from "@/v2/components/Header";
    import StatusBadge from "@/components/StatusBadge";
    let follow = Siren.Helper.follow;
    export default {
        components: {
            MainTemplate,
            Loading,
            'a-button': Button,
            PropertyOrField,
            PageTitle,
            DependantField,
            PageHeader,
            StatusBadge
        },
        data() {
            return {
                createNewOrderAction: null,
                projectId: null,
                loading: true,
                submitting: false,
                key:0,
                clientId: null,
                scheduledFile: null,
                random:1
            }
        },

        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            createNewOrderActionData() {
                var vm = this;
                if (!this.createNewOrderAction) {
                    return {};
                }
                if (!this.createNewOrderAction.fields) {
                    return {};
                }
                var payload = {};
                this.createNewOrderAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                if(payload['client_id'] === undefined)
                {
                    Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                    Siren.get(process.env.VUE_APP_API_URL + "/api/")
                        .then(
                            follow("current-user")
                        )
                        .then(res => {
                            this.clientId = res.body.properties.get("client_id");
                        }, this);
                }
                return payload;
            },
        },
        methods: {
            change() {
            },
            changeDeliveryMethod() {
                this.change();
                this.key++;
            },
            changeIsScheduled() {
                this.change();
                this.key++;
            },
            createOrder() {
                this.submitting = true;
                this.createNewOrderAction.perform(this.createNewOrderActionData).then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.submitting = false;
                    let next = res.body.links
                        .filter(link => link.rels.contains("self"))
                        .first();
                    let parts = next.href.split("/api");
                    this.$router.push(parts[1]);
                }).catch(error => {
                    this.submitting = false;
                    if (error.response.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    console.log(error.response.text);
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.random++;
                    this.key++;
                });
            },
            setProjectId(value) {
                this.projectId = value;
            },
            setClientId(value) {
                this.clientId = value;
            },
            changeScheduledFile(value) {
                this.scheduledFile = value;
                console.log(value);
                this.change();
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },
        mounted() {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow("orders"))
                .then(res => {
                    let siren = res.body;
                    this.createNewOrderAction = siren.actions.filter(action => action.name === 'create-new-order').get('create-new-order');
                    this.loading = false;
                }, this);

        },
    }
</script>

<style lang="scss">
    .create-new-order .label-a {
        display: none;
    }
</style>
