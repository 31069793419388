<template>
    <div class="order-summary">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">QA Category Summary</h3>
        <div class="generic-summary p-4 bg-white" :key="key">
            <div class="grid grid-cols-3 gap-4">
                <property-or-field @input="change" :properties="properties" title="Name" :action="updateAction" fieldId="name" propertyKey="name" v-model="updateActionData['name']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Client" :action="updateAction" fieldId="client_id" propertyKey="client_id" v-model="updateActionData['client_id']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Is Drawing Required For QA" :action="updateAction" fieldId="is_drawing_required" propertyKey="is_drawing_required" v-model="updateActionData['is_drawing_required']"></property-or-field>

            </div>
            <div class="grid grid-cols-1 mt-4">
                <questions-input title="Pre-Check Questions" @change="change" :action="updateAction" fieldId="pre_check_questions" :properties="properties" propertyKey="pre_check_questions" v-model="updateActionData['pre_check_questions']"></questions-input>
            </div>
            <div class="grid grid-cols-1 mt-4">
                <questions-input title="Questions" @change="change" :action="updateAction" fieldId="questions" :properties="properties" propertyKey="questions" v-model="updateActionData['questions']"></questions-input>
            </div>
          <div class="grid grid-cols-1 mt-4">
              <payment-types-input title="Payment Types" @change="change" fieldId="payment_types" :properties="properties" propertyKey="payment_types" :action="updateAction" v-model="updateActionData['payment_types']">
              </payment-types-input>
          </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from "@/components/PropertyOrField";
    import QuestionsInput from "@/components/Action/Fields/QuestionsInput";
    import PaymentTypesInput from "@/components/Action/Fields/PaymentTypesInput";
    export default {
        inject: ["$validator"],
        components: {
            PropertyOrField,
            QuestionsInput,
            PaymentTypesInput
        },
        data() {
            return {
                key : 0
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update-template').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            properties(){
                return this.response.body.properties;
            }
        },
        methods: {
            update(){
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
