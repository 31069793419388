<template>
    <div>
        <a-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>
        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
        :confirmationString='`Are you sure you want to \"${action.title}\"`'
        @removeFromDisplay="displayModal = false" @perform="perform()">
            <component :is="getFieldComponent(action.fields.get('operative_id'))" :field="action.fields.get('operative_id')" v-model="formData['operative_id']"
                        dependsOn='client_id' :dependsOnValue="clientId">
            </component>
            <div v-show="errors.has('operative_id')" class="text-red-600">{{ errors.first('operative_id') }}</div>

            <component :is="getFieldComponent(action.fields.get('work_done'))" :field="action.fields.get('work_done')" v-model="formData['work_done']">
            </component>
            <div v-show="errors.has('work_done')" class="text-red-600">{{ errors.first('work_done') }}</div>

            <component :is="getFieldComponent(action.fields.get('work_done_comment'))" :field="action.fields.get('work_done_comment')" v-model="formData['work_done_comment']">
            </component>
            <div v-show="errors.has('work_done_comment')" class="text-red-600">{{ errors.first('work_done_comment') }}</div>

            <component :is="getFieldComponent(action.fields.get('additional_notes'))" :field="action.fields.get('additional_notes')" v-model="formData['additional_notes']">
            </component>
            <div v-show="errors.has('additional_notes')" class="text-red-600">{{ errors.first('additional_notes') }}</div>

            <component :is="getFieldComponent(action.fields.get('on_site_confirmation_image'))" :field="action.fields.get('on_site_confirmation_image')" v-model="formData['on_site_confirmation_image']">
            </component>
            <div v-show="errors.has('on_site_confirmation_image')" class="text-red-600">{{ errors.first('on_site_confirmation_image') }}</div>

            <component :is="getFieldComponent(action.fields.get('construction_drawing'))" :field="action.fields.get('construction_drawing')" v-model="formData['construction_drawing']">
            </component>
            <div v-show="errors.has('construction_drawing')" class="text-red-600">{{ errors.first('construction_drawing') }}</div>

            <template v-if="action.fields.get('signed_qa')">
                <component :is="getFieldComponent(action.fields.get('signed_qa'))" :field="action.fields.get('signed_qa')" v-model="formData['signed_qa']">
                </component>
                <div v-show="errors.has('signed_qa')" class="text-red-600">{{ errors.first('signed_qa') }}</div>
            </template>
            <div class="rounded-md flex-grow" v-if="action.fields.get('answers')">
                <div class="focus-within:z-10">
                    <label for="email" class="block text-sm font-medium leading-5 text-gray-700 mt-3">Questions & Answers</label>
                    <a class="underline cursor-pointer" href="#" @click="displayAnswers = true">Edit Questions & Answers</a>
                    <div v-show="errors.has('answers')" class="text-red-600">{{ errors.first('answers') }}</div>
                </div>
            </div>

            <div class="rounded-md flex-grow" v-if="action.fields.get('payment_types')">
                <div class="focus-within:z-10">
                    <label for="email" class="block text-sm font-medium leading-5 text-gray-700 mt-3">Payment Types</label>
                    <a class="underline cursor-pointer" href="#" @click="displayPaymentTypes = true">Edit Payment Types</a>
                </div>
                <div v-show="errors.has('payment_types')" class="text-red-600">{{ errors.first('payment_types') }}</div>
            </div>

        </slide-over>

        <large-model v-show="displayAnswers" @removeFromDisplay="displayAnswers = false" @perform="displayAnswers = false" additionalClasses="z-50">
            <questions-box fieldId="answers" :action="action" v-model="formData['answers']">

            </questions-box>
        </large-model>

        <large-model v-show="displayPaymentTypes" @removeFromDisplay="displayPaymentTypes = false" @perform="displayPaymentTypes = false" additionalClasses="z-50">
            <payment-types-box fieldId="payment_types" :action="action" v-model="formData['payment_types']">

            </payment-types-box>
        </large-model>

    </div>
</template>

<script>
    import Button from '@/v2/buttons/Button';
    import Modal from '@/v2/components/Modal';
    import SlideOver from "@/v2/components/SlideOver";
    import fileDownload from "js-file-download";
    import QuestionsBox from "@/components/Action/Fields/QuestionsBox.vue";
    import PaymentTypesBox from "@/components/Action/Fields/PaymentTypesBox.vue";
    import LargeModel from "@/v2/components/LargeModel.vue";

    export default {
        data() {
            return {
                submittingModal: false,
                clientId: null,
                projectId: null,
                displayPaymentTypes: false,
                displayAnswers: false
            }
        },
        components: {
            LargeModel,
            PaymentTypesBox,
            QuestionsBox,
            SlideOver,
            'a-button': Button,
            Modal,
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            buttonType:{
                type: String,
                default: null
            },
            displayModal:{
                type:Boolean,
                default: false
            },
            additionalButtonClasses: {
                type : String,
                default: null
            },
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name === 'client_id' && field.value !== undefined) this.clientId = field.value;
                    if(field.name === 'project_id' && field.value !== undefined) this.projectId = field.value;
                }.bind(this));
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            setProjectId(value){
                this.projectId = value;
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>
