import { render, staticRenderFns } from "./EvacPaginatedList.vue?vue&type=template&id=01a3facd&scoped=true&"
import script from "./EvacPaginatedList.vue?vue&type=script&lang=js&"
export * from "./EvacPaginatedList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01a3facd",
  null
  
)

export default component.exports