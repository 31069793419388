import { render, staticRenderFns } from "./InvoicesInHeader.vue?vue&type=template&id=925dd04e&scoped=true&"
import script from "./InvoicesInHeader.vue?vue&type=script&lang=js&"
export * from "./InvoicesInHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "925dd04e",
  null
  
)

export default component.exports