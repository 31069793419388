<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">CMS Dashboard</h2>
                <div class="flex items-center">
                    <div class="mr-1 hidden md:block">
                        <template v-if="this.response.body.actions.filter(action => action.name === 'save-widgets').first()">
                            <template v-if="editable">
                                <a-button @click.native="displayModal = true">Add Widget</a-button>
                                <slide-over v-if="displayModal" title="Add widget"
                                confirmationString=''
                                @removeFromDisplay="displayModal = false" @perform="addWidget">
                                    <radio-input :field="widgetTypeValues" v-model="inputValue"></radio-input>
                                </slide-over>
                            </template>
                            <template v-else>
                                <a-button @click.native="enableEditWidgets">Edit Widgets</a-button>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import Siren from "super-siren";
    import {mapGetters} from "vuex";
    import Button from '../Button';
    import SlideOver from "../SlideOver";
    import RadioInput from "../Action/Fields/RadioInput"

    export default {
        inject: ["$validator"],
        components: {
            'a-button': Button,
            SlideOver,
            RadioInput
        },
        props: {
            response: {
                type: Object,
            },
            editable: {
                type: Boolean
            },
            hasOssModule: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                displayModal: false,
                widgetTypeValues: null,
                inputValue:null
            }
        },
        methods: {
            success(key) {
                this.$router.go(0);
            },
            addWidget(){
                EventBus.$emit('addWidget', this.inputValue);
                this.inputValue = null;
                this.displayModal = false;
            },
            enableEditWidgets(){
                this.editable = true;
                EventBus.$emit('enableEditWidgets');
            }
        },
        created() {
            document.title = 'Dashboard - CMS';

            this.widgetTypeValues = { name: 'widget_type', title: 'Widget Type', type: 'radio', options: [
                {value: 'project-locations', title: 'Project Locations'},
                {value: 'orders-in-progress', title: 'Orders In Progress'},
                {value: 'awaiting-approval', title: 'Awaiting Approval'},
                {value: 'awaiting-credit', title: 'Awaiting Credit'},
                {value: 'schedules', title: 'Schedules'},
                {value: 'current-date-time', title: 'Current Date And Time'},
                {value: 'dashboard-clocked-in',title: 'Users Checked In'},
                {value: 'notifications', title: 'Notifications'},
                {value: 'quotes-mismatch', title: 'Quotes Mismatch'}
            ]};
            if(!this.hasOssModule){
                this.widgetTypeValues.options.forEach((widget, index) => {
                    if(widget.value === 'dashboard-clocked-in' && !this.hasOssModule){
                        this.widgetTypeValues.options.splice(index, 1);
                    }
                });
            }
        },
        mounted(){
            EventBus.$on('disableEditWidgets', function() {
                this.editable = false;
            }.bind(this))
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
        }
    }
</script>

<style lang="scss" scoped>

</style>
