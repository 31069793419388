<template>
    <page-header :logo="logo">
        <div class="md:flex justify-between w-full items-center">
            Daily Summary
            <div class="flex items-center text-secondary-darkgrey">
                <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'show-oss-daily-report' && action.name ==='clock-in')">
                    <clock-in-form class="action-with-fields" :action="actionWithFields[1]"
                                        :actionKey="actionWithFields[0]"
                                        :redirectOnSuccess="true"
                                        :onSuccessPath="$router.currentRoute.fullPath"
                    ></clock-in-form>
                </div>
            </div>
        </div>
    </page-header>
</template>

<script>
import PageHeader from "@/v2/components/Header"
import ClockInForm from "@/v2/components/forms/ClockInForm";
export default {
    name: "DailyReportHeader",
    components: {
        ClockInForm,
        PageHeader
    },
    data(){
      return {
          organisationId: null
      }
    },
    props: {
        response: {
            type: Object
        },
        logo:{
            type: String,
            default: null
        }
    },
    computed: {
        remainingActions() {
            return this.response.body.actions.filter(action => action.name !== 'filter')
        },
    },
    methods:{
        clientChanged() {
            this.$router.push(this.$route.path + '?date=' + this.$route.query['date'] + '&organisationId=' + this.organisationId);
        },
        organisationIdFromAction(){
            let action = this.remainingActions.filter(action => action.name ==='show-oss-daily-report').first();
            if(!action){
                return null;
            }
            let fields = action.fields;
            if (!fields) {
                return null;
            }
            this.organisationId = fields.first()['value'];
            return fields.first()['value'];
        },
    },
    created(){
        this.organisationIdFromAction();
    }
}
</script>

<style scoped>

</style>
