<template>
    <div class="product-form">
        <a-button v-if="showButton" :class="additionalButtonClasses" @click.native="displayModal = true;$emit('displayForm');" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false;$emit('removeFromDisplay')" @perform="perform()">

            <component v-if="getField('name')" :is="getFieldComponent(getField('name'))" :field="getField('name')" v-model="formData[getField('name').name]">
            </component>
            <div v-show="errors.has(getField('name').name)" class="text-red-600 text-sm">{{ errors.first(getField('name').name) }}</div>

            <component v-if="getField('code')" :is="getFieldComponent(getField('code'))" :field="getField('code')" v-model="formData[getField('code').name]">
            </component>
            <div v-show="errors.has(getField('code').name)" class="text-red-600 text-sm">{{ errors.first(getField('code').name) }}</div>

            <component v-if="getField('data_sheet')" :is="getFieldComponent(getField('data_sheet'))" :field="getField('data_sheet')" v-model="formData[getField('data_sheet').name]">
            </component>
            <div v-show="errors.has(getField('data_sheet').name)" class="text-red-600 text-sm">{{ errors.first(getField('data_sheet').name) }}</div>

            <component v-if="getField('category_id')" :is="getFieldComponent(getField('category_id'))" :field="getField('category_id')" v-model="formData[getField('category_id').name]"
            @input="setCategoryId">
            </component>
            <div v-show="errors.has(getField('category_id').name)" class="text-red-600 text-sm">{{ errors.first(getField('category_id').name) }}</div>

            <dependant-field title="Sub Category" :action="action"
                             fieldId="sub_category_id"
                             dependsOn="parent_category_id"
                             :dependsOnValue="categoryId"
                             v-model="formData['sub_category_id']"
                             ref="subCategory" @input="setSubCategoryId"
                             ></dependant-field>

            <div v-show="errors.has(getField('sub_category_id').name)" class="text-red-600 text-sm">{{ errors.first(getField('sub_category_id').name) }}</div>

            <component v-if="getField('manufacturer_id')" :is="getFieldComponent(getField('manufacturer_id'))" :field="getField('manufacturer_id')" v-model="formData[getField('manufacturer_id').name]">
            </component>
            <div v-show="errors.has(getField('manufacturer_id').name)" class="text-red-600 text-sm">{{ errors.first(getField('manufacturer_id').name) }}</div>

            <template v-if="getField('type_id')">
                <dependant-field title="Type" :action="action"
                                 fieldId="type_id"
                                 dependsOn="category_id"
                                 :dependsOnValue="subCategoryId"
                                 v-model="formData['type_id']"
                                 ref="type"
                ></dependant-field>


                <div v-show="errors.has(getField('type_id').name)" class="text-red-600 text-sm">{{ errors.first(getField('type_id').name) }}</div>
            </template>

        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import DependantField from "@/components/DependantField.vue";

export default {
    name: "AddProductForm",
    data() {
        return {
            submittingModal: false,
            categoryId: null,
            subCategoryId: null
        }
    },
    components: {
        DependantField,
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
        showButton:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
                if (field.value !== undefined && field.name === 'category_id') this.categoryId = field.value;
                if (field.value !== undefined && field.name === 'sub_category_id') this.subCategoryId = field.value;
            }, this);
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        },
        setCategoryId(value){
            this.categoryId = value;
            this.$refs.subCategory.$refs.fieldComponent.setValue(null);
        },
        setSubCategoryId(value){
            this.subCategoryId = value;
            this.$refs.type.$refs.fieldComponent.setValue(null);
        }
    },
}
</script>

<style>
    .product-form label.label-a{
        display: none;
    }
</style>
