<template>
    <div :key="$route.fullPath">
        <main-template>
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                Product Types
                                <div class="flex items-center -mt-3 text-secondary-darkgrey">
                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter' && !action.fields.isEmpty())">
                                        <form-action class="action-with-fields" :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                        ></form-action>
                                    </div>
                                </div>
                            </div>
                        </page-header>
                        <div class="container mx-auto p-4 mb-8">
                            <filterable-paginated-product-types-list :response="response">
                            </filterable-paginated-product-types-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import DashboardStats from '../../components/Dashboard/DashboardStats';
    import SavedFilters from '../../components/SavedFilters';
    import FilterablePaginatedProductTypesList from "../../components/FilterablePaginatedProductTypesList";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    export default {
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            FilterablePaginatedProductTypesList,
            PageHeader,
            FormAction
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
