<template>
    <div class="order-summary">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Order Summary</h3>
        <div class="generic-summary p-4 bg-white" :key="key">
            <div class="grid grid-cols-3 gap-4">
                <template v-if="properties.get('invoice')">
                    <template v-if="showInvoice">
                        <download-pdf :url="properties.get('invoice')" @removeFromDisplay="showInvoice = false"></download-pdf>
                    </template>
                    <a href="#" @click.prevent="toggleInvoice" target="_blank" class="flex">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                        View Invoice
                    </a>
                </template>
                <property-or-field @input="change" :properties="properties" title="Specified Notes" :action="updateAction" fieldId="specified_notes" propertyKey="specified_notes" v-model="updateActionData['specified_notes']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Discount" :action="updateAction" fieldId="discount" propertyKey="discount" v-model="updateActionData['discount']"></property-or-field>

                <property-or-field @input="change" :properties="properties" title="Date" :action="updateAction" fieldId="date" propertyKey="date" v-model="updateActionData['date']"></property-or-field>
                <property-or-field @input="changeIsScheduled" :properties="properties" title="Is Scheduled" :action="updateAction" fieldId="is_scheduled" propertyKey="is_scheduled" v-model="updateActionData['is_scheduled']"></property-or-field>
                <property-or-field v-if="!updateAction || properties.get('is_scheduled') === 'No'" @input="change" :properties="properties" title="Expected By Date" :action="updateAction" fieldId="expected_by_date" propertyKey="expected_by_date" v-model="updateActionData['expected_by_date']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Project" :action="updateAction" fieldId="project_id" propertyKey="project" v-model="updateActionData['project']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Quick Reference" :action="updateAction" fieldId="quick_reference" propertyKey="quick_reference" v-model="updateActionData['quick_reference']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Specifications" :action="updateAction" fieldId="specifications" propertyKey="specifications" v-model="updateActionData['specifications']"></property-or-field>
                <property-or-field @input="changeDeliveryMethod" :properties="properties" title="Delivery Method" :action="updateAction" fieldId="delivery_method" propertyKey="delivery_method" v-model="updateActionData['delivery_method']"></property-or-field>
                <property-or-field v-if="!updateAction || updateActionData['delivery_method'] === 'pick_up'" @input="change" :properties="properties" title="Supplier" :action="updateAction" fieldId="supplier" propertyKey="supplier" v-model="updateActionData['supplier']"></property-or-field>
                <property-or-field v-if="!updateAction || updateActionData['delivery_method'] === 'pick_up'" @input="change" :properties="properties" title="Pick Up Location" :action="updateAction" fieldId="pick_up_location" propertyKey="pick_up_location" v-model="updateActionData['pick_up_location']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Delivery must be booked" :action="updateAction" fieldId="booking_delivery" propertyKey="booking_delivery" v-model="updateActionData['booking_delivery']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Supplier Notes" :action="updateAction" fieldId="additional_notes" propertyKey="additional_notes" v-model="updateActionData['additional_notes']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Procurement Notes" :action="updateAction" fieldId="procurement_notes" propertyKey="procurement_notes" v-model="updateActionData['procurement_notes']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Attachment" :action="updateAction" fieldId="attachment" propertyKey="attachment" v-model="updateActionData['attachment']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Scheduled File" :action="updateAction" fieldId="scheduled_file" propertyKey="scheduled_file" v-model="updateActionData['scheduled_file']"></property-or-field>

            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import DownloadPdf from "../pages/DownloadPdf";
    export default {
            inject: ["$validator"],
        components: {
            DownloadPdf,
            PropertyOrField,
        },
        data() {
            return {
                key : 0,
                showInvoice: false
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            properties(){
                console.log(this.response.body.properties);
                return this.response.body.properties;
            }
        },
        methods: {
            toggleInvoice()
            {
                this.showInvoice = true
            },
            updateOrder(){
                console.log(this.updateActionData);
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            },
            changeDeliveryMethod(){
                this.key++;
                this.$emit('change');
            },
            changeIsScheduled(){
                this.key++;
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
