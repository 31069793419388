<template>
    <div class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-3 pt-6">
        <div class="border md:rounded-lg mb-8 bg-white grid grid-cols-3 col-span-2 section-links">
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('materials')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('materials')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M448 92.99V207.5L539.9 242.4C561.6 250.6 576 271.5 576 294.7V413.9C576 436.1 562.9 456.2 542.5 465.1L446.2 507.5C432.2 513.7 416.3 513.8 402.2 507.1L288 460.7L173.8 507.1C159.7 513.8 143.8 513.7 129.8 507.5L33.46 465.1C13.13 456.2 0 436.1 0 413.9V294.7C0 271.5 14.39 250.6 36.15 242.4L128 207.5V92.99C128 69.32 142.9 48.2 165.2 40.24L269.2 3.134C281.4-1.209 294.6-1.209 306.8 3.134L410.8 40.24C433.1 48.2 448 69.32 448 92.99V92.99zM290.7 48.34C288.1 47.72 287.1 47.72 285.3 48.34L204.1 77.32L288 109.5L371.9 77.32L290.7 48.34zM264 238.6V151.7L176 117.9V207.2L264 238.6zM312 238.6L400 207.2V117.9L312 151.7V238.6zM176 353.9V455.1L264 418.7V320.2L176 353.9zM52.78 421.2L128 454.3V353.9L48 323.2V413.9C48 417.1 49.88 419.9 52.78 421.2zM400 353.9L312 320.2V418.7L400 455.1V353.9zM448 454.3L523.2 421.2C526.1 419.9 528 417.1 528 413.9V323.2L448 353.9V454.3zM155 250.7C153.2 250 151.3 250.1 149.5 250.7L70.81 280.6L152 311.7L235.9 279.5L155 250.7zM340.1 279.5L424 311.7L505.2 280.6L426.5 250.7C424.7 250.1 422.8 250 420.1 250.7L340.1 279.5z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue ">Materials</p>
                        <p>Manage materials & material stock levels</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('office-items')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('office-items')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M368 336h-32c-8.875 0-16 7.125-16 16v32c0 8.875 7.125 16 16 16h32c8.875 0 16-7.125 16-16v-32C384 343.1 376.9 336 368 336zM320 256v32c0 8.875 7.125 16 16 16h32C376.9 304 384 296.9 384 288V256c0-8.875-7.125-16-16-16h-32C327.1 240 320 247.1 320 256zM432 400h32c8.875 0 16-7.125 16-16v-32c0-8.875-7.125-16-16-16h-32c-8.875 0-16 7.125-16 16v32C416 392.9 423.1 400 432 400zM432 304h32C472.9 304 480 296.9 480 288V256c0-8.875-7.125-16-16-16h-32C423.1 240 416 247.1 416 256v32C416 296.9 423.1 304 432 304zM512 32H269C262.5 13.38 244.9 0 224 0H144C123.1 0 105.5 13.38 99 32H64C28.62 32 0 60.62 0 96v352c0 35.38 28.62 64 64 64h448c35.38 0 64-28.62 64-64V96C576 60.62 547.4 32 512 32zM144 48H224v320H144V48zM528 448c0 8.875-7.125 16-16 16H64c-8.875 0-16-7.125-16-16V96c0-8.875 7.125-16 16-16h32v288C96 394.5 117.5 416 144 416H224c26.5 0 48-21.5 48-48v-288H320v72C320 174 337.1 192 360 192h168V448zM528 144h-160v-64H512c8.875 0 16 7.125 16 16V144z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Office Items</p>
                        <p>Manage Office Items</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('vehicles')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('vehicles')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M627.4 162.9C639.1 169.2 643.4 183.8 637.1 195.4C630.8 207.1 616.2 211.4 604.6 205.1L319.1 51.28L35.41 205.1C23.75 211.4 9.19 207.1 2.887 195.4C-3.416 183.8 .9271 169.2 12.59 162.9L308.6 2.887C315.7-.9623 324.3-.9623 331.4 2.887L627.4 162.9zM200 360C200 346.7 210.7 336 224 336C237.3 336 248 346.7 248 360C248 373.3 237.3 384 224 384C210.7 384 200 373.3 200 360zM440 360C440 373.3 429.3 384 416 384C402.7 384 392 373.3 392 360C392 346.7 402.7 336 416 336C429.3 336 440 346.7 440 360zM182 207.8C192.3 179.1 219.4 160 249.8 160H390.2C420.6 160 447.7 179.1 457.1 207.8L486.6 287.9C486.8 288.4 486.9 288.9 487.1 289.3C511.8 306.7 528 335.5 528 368V488C528 501.3 517.3 512 504 512C490.7 512 480 501.3 480 488V448H160V488C160 501.3 149.3 512 136 512C122.7 512 112 501.3 112 488V368C112 335.5 128.2 306.7 152.9 289.3C153.1 288.9 153.2 288.4 153.4 287.9L182 207.8zM249.8 208C239.7 208 230.6 214.4 227.2 223.9L210.1 272H429.9L412.8 223.9C409.4 214.4 400.3 208 390.2 208H249.8zM432 320H208C181.5 320 160 341.5 160 368V400H480V368C480 341.5 458.5 320 432 320z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Vehicles</p>
                        <p>Manage Vehicles</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasSagesLink() {
            return this.hasLink('sages') || this.hasLink('sage-descriptions') || this.hasLink('sage-nominal-codes');
        },
    },
}
</script>

<style scoped>

</style>
