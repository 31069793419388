<template>
    <div class="order-summary">
        <div class="flex flex-col">
            <div class="generic-summary p-4 bg-white" :key="key">
                <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <property-or-field @input="change" :properties="properties" title="Custom Approval Threshold" :action="updateAction" fieldId="approval_threshold" v-model="updateActionData['approval_threshold']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Default Currency" :action="updateAction" fieldId="default_currency" v-model="updateActionData['default_currency']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Warehouse Address" :action="updateAction" fieldId="warehouse_address" v-model="updateActionData['warehouse_address']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Quotes" :action="updateAction" fieldId="quotes" v-model="updateActionData['quotes']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="CMS Quote Required" :action="updateAction" fieldId="cms_quote_required" v-model="updateActionData['cms_quote_required']"></property-or-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertyOrField from "@/components/PropertyOrField";
export default {
    name: "ClientProcurementSettingsForm",
    inject: ["$validator"],
    data(){
        return{
            key: 0
        }
    },
    components:{
        PropertyOrField
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        updateAction() {
            return this.response.body.actions.filter(action => action.name === 'update-procurement').first();
        },
        updateActionData(){
            var that = this;
            if(!this.updateAction){
                return {};
            }
            if(!this.updateAction.fields){
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function(field){
                if (field.value !== undefined ) payload[field.name] = field.value;
                if(field.name == 'delivery_method'){
                    that.deliveryMethod = field.value;
                };
            });
            return payload;
        },
        properties(){
            return this.response.body.properties;
        }
    },
    methods: {
        change(){
            this.$emit('change');
        },
        update(){
            return this.updateAction.perform(this.updateActionData);
        }
    }
}
</script>

<style scoped>

</style>
