import { render, staticRenderFns } from "./ClientProcurementSettingsForm.vue?vue&type=template&id=69acf51e&scoped=true&"
import script from "./ClientProcurementSettingsForm.vue?vue&type=script&lang=js&"
export * from "./ClientProcurementSettingsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69acf51e",
  null
  
)

export default component.exports