var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.$route.fullPath},[_c('main-template',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var logo = ref.logo;
return [_c('fetch-root-siren',{attrs:{"linkName":_vm.linkName,"link":_vm.link,"logo":logo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var response = ref.response;
var logo = ref.logo;
return _c('div',{},[(!response)?_c('loading'):[_c('daily-report-header',{attrs:{"response":response,"logo":logo}}),_c('div',{staticClass:"p-4"},[_c('div',[_c('oss-tabs'),_c('daily-report-action',{attrs:{"response":response}}),_c('header',{staticClass:"mt-8 relative flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6"},[_c('div',[_c('h1',{staticClass:"text-lg font-semibold leading-6 text-gray-900"},[_c('time',{staticClass:"sm:inline"},[_vm._v(_vm._s(_vm.currentDate()))])]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(_vm._s(_vm.currentDay()))])]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center rounded-md shadow-sm md:items-stretch"},[_c('button',{staticClass:"flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50",attrs:{"type":"button"},on:{"click":function($event){return _vm.getPrevDate()}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Previous month")]),_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z","clip-rule":"evenodd"}})])]),_c('DatePicker',{staticClass:"hidden border-t border-b border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block cursor-pointer flex items-center",class:{'rounded-r-md border-r' : _vm.isCurrentPageToday()},attrs:{"max-date":new Date()},on:{"input":_vm.changeDay},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var togglePopover = ref.togglePopover;
return [_c('button',{staticClass:"h-full w-full px-3.5",attrs:{"type":"button"},on:{"click":togglePopover}},[_vm._v(" "+_vm._s(_vm.getCurrentPageName())+" ")])]}}],null,true),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('span',{staticClass:"relative -mx-px h-5 w-px bg-gray-300 md:hidden"}),(!_vm.isCurrentPageToday())?_c('button',{staticClass:"flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50",attrs:{"type":"button"},on:{"click":function($event){return _vm.getNextDate()}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Next month")]),_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z","clip-rule":"evenodd"}})])]):_vm._e()],1)])]),_c('div',{staticClass:"mb-8"},[_c('daily-reports-paginated-list',{attrs:{"response":response}})],1)],1)])]],2)}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }