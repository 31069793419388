<template>
    <paginated-list :response="response" :flippedScrollbar="true" :stickyHeaders="true">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <template v-for="(qa_tracker_column_name,key) in headers">
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase text-gray-500 tracking-wider" :class="{'sm:pl-6 left-0 sticky bg-secondary-lightbacka': key===0}"  v-if="qa_tracker_column_name !== 'priority' && qa_tracker_column_name !== 'update-link'">
                  {{qa_tracker_column_name}}
                    <span class="flex" v-if="savePriorityAction && qa_tracker_column_name !== 'name'">
                        <span class="inline-flex" v-if="key > 1" >
                          <svg @click="moveHeaderLeft(key)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>

                        </span>
                        <span @click="moveHeaderRight(key)" v-if="
                            (headers[headers.filter(header => header !== 'priority' && header !== 'update-link').length] !== 'priority' && key !== headers.filter(header => header !== 'priority' && header !== 'update-link').length) ||
                            (headers[headers.filter(header => header !== 'priority' && header !== 'update-link').length] === 'priority' && key !== headers.filter(header => header !== 'priority' && header !== 'update-link').length -1)
                        ">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>
                      </span>
                      <span v-if="getCategoryDetails(qa_tracker_column_name) && getCategoryDetails(qa_tracker_column_name)['update-link']" class="cursor-pointer font-normal underline text-gray-400" @click.prevent="removeCategory(getCategoryDetails(qa_tracker_column_name), key)">remove</span>
                </th>
            </template>
        </template>
        <template v-slot:noItems>
            No levels found.
        </template>
        <template v-slot:tableRows>
            <template v-for="(qa_trackers,key) in qaTrackers">
                <tr v-if="qa_trackers.name.replaceAll('/',' / ') !== 'priority'" >
                    <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold sm:pl-6  items-center sticky left-0 bg-white">
                        <span class="inline-flex">
                            {{qa_trackers.name.replaceAll('/',' / ')}}

                          <span v-if="savePriorityAction">
                            <span v-if="key !== 0">
                              <svg @click="moveTrackerDown(qa_trackers)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                              </svg>
                            </span>
                            <span>
                              <svg v-if="key !== qaTrackers.length - 1" @click="moveTrackerUp(qa_trackers)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                              </svg>
                            </span>
                          </span>
                        </span>
                        <span v-if="qa_trackers['update-link']" class="flex cursor-pointer font-normal underline text-gray-400 center" @click.prevent="removeIdentifier(qa_trackers['update-link'], key)">remove</span>

                    </td>
                    <template v-for="(qa_tracker_column_name,index) in headers.slice(1)">
                        <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey font-bold" v-if="qa_tracker_column_name !== 'priority' && qa_tracker_column_name !== 'update-link'">
                            <template v-if="qa_trackers[qa_tracker_column_name]">
                                <template v-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'pre-check-started'">
                                    <a @click="goToPreCheck(getParsedData(qa_trackers[qa_tracker_column_name])['pre_check_id'])" class="cursor-pointer">
                                        {{ getParsedData(qa_trackers[qa_tracker_column_name])['user_first_name']}}<br>
                                        {{ getParsedData(qa_trackers[qa_tracker_column_name])['user_surname']}}<br>
                                        {{getParsedData(qa_trackers[qa_tracker_column_name])['date_of_works']}}
                                    </a>
                                </template>
                                <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'pre-check-questions-not-confirmed'">
                                    <a @click="goToPreCheck(getParsedData(qa_trackers[qa_tracker_column_name])['pre_check_id'])" class="cursor-pointer">
                                        {{ getParsedData(qa_trackers[qa_tracker_column_name])['user_first_name']}}<br>
                                        {{ getParsedData(qa_trackers[qa_tracker_column_name])['user_surname']}}<br>
                                        {{getParsedData(qa_trackers[qa_tracker_column_name])['date_of_works']}}
                                    </a>
                                </template>
                                <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'pre-check-completed'">
                                    <a @click="goToPreCheck(getParsedData(qa_trackers[qa_tracker_column_name])['pre_check_id'])" class="cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="currentColor" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 256a160 160 0 1 1 320 0A160 160 0 1 1 0 256z"/></svg>
                                    </a>
                                </template>
                              <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'created'">
                                <a @click="goToQualityAssessment(getParsedData(qa_trackers[qa_tracker_column_name])['qa_id'])" class="cursor-pointer">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                </a>
                              </template>
                              <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'signed'">
                                <a @click="goToQualityAssessment(getParsedData(qa_trackers[qa_tracker_column_name])['qa_id'])" class="cursor-pointer">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                  </svg>
                                </a>
                              </template>
                              <template v-else-if="getParsedData(qa_trackers[qa_tracker_column_name])['status'] === 'approved'">
                                <a @click="goToQualityAssessment(getParsedData(qa_trackers[qa_tracker_column_name])['qa_id'])" class="cursor-pointer">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                  </svg>
                                </a>
                              </template>
                              <template v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              </template>
                            </template>
                            <template v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </template>
                        </td>
                    </template>
                </tr>
            </template>




        </template>
    </paginated-list>
</template>

<script>
import axios from "axios";

Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};


import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import {mapGetters} from "vuex";
export default {
    name: "QaTrackerLevelPaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        OrderTabs
    },
    data(){
        return{
            'headers' : null,
            'removingCategory': false,
            'removingIdentifier': false
        }
    },
    props:{
        response:{
            type: Object
        }
    },
    mounted(){
        if(this.response){
           this.headers = Object.keys(this.qaTrackers[0]).splice(1);
        }

    },
    computed:{
        qaTrackers(){
          if(!this.response){
            return null;
          }
          return this.response.body.properties.get('qa_tracker');
        },
        savePriorityAction() {
          return this.response.body.actions.filter(action => action.name === 'update-priority').first();
        },
        ...mapGetters({
            accessToken: "getAccessToken"
        }),
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
      getParsedData(data){
          return JSON.parse(data);
      },
      goToQualityAssessment(qaId){
          this.$router.push({ name: 'quality-assessment', params: { id: qaId } })
      },
        goToPreCheck(preCheckId){
            this.$router.push({ name: 'pre-check', params: { id: preCheckId } })
        },
      moveTrackerUp(tracker){
          let index = this.qaTrackers.indexOf(tracker);
          console.log('index: ' + index);
          if(index < this.qaTrackers.length) {
            this.qaTrackers.move(index, index + 1);
            this.$emit('change');
          }
      },
      moveTrackerDown(tracker){
        let index = this.qaTrackers.indexOf(tracker);
        if(index > 0) {
          this.qaTrackers.move(index, index - 1);
          this.$emit('change');
        }
      },
        moveHeaderLeft(key){
            if(key > 1) {
                this.headers.move(key, key - 1);
                this.$emit('change');
            }
        },
        moveHeaderRight(key){
            if(key > 0 && key < this.headers.length - 1) {
                this.headers.move(key, key + 1);
                this.$emit('change');
            }
        },
      savePriorities(){
          console.log(JSON.stringify(this.qaTrackers));
        return this.savePriorityAction.perform({
          'trackers': JSON.stringify(this.qaTrackers),
          'categories': JSON.stringify(this.headers.slice(1, this.headers.length -1))
        });
      },
        getCategoryDetails(categoryName){
            if(this.response.body.properties.get('qa_category_ids').filter(category => category.name == categoryName)[0])
            {
                return this.response.body.properties.get('qa_category_ids').filter(category => category.name == categoryName)[0];
            }
            return null;
        },
        removeCategory(removalLink, key)
        {
            if(!this.removingCategory) {
                this.removingCategory = true;
                let that = this;
                axios.patch(removalLink['update-link'], {}, {
                    headers: {"Authorization": `Bearer ${this.accessToken}`}
                }).then(function () {
                    that.headers.splice(key, 1);
                    that.$store.dispatch(
                        "setMessage",
                        'The tracker was updated successfully.'
                    );
                    that.removingCategory = false;
                }).catch(function(){
                    that.removingCategory = false;
                })
            }
        },
        removeIdentifier(removalLink, key)
        {
            if(!this.removingIdentifier) {
                this.removingIdentifier = true;
                let that = this;
                axios.patch(removalLink, {}, {
                    headers: {"Authorization": `Bearer ${this.accessToken}`}
                }).then(function () {
                    that.qaTrackers.splice(key, 1);
                    that.$store.dispatch(
                        "setMessage",
                        'The tracker was updated successfully.'
                    );
                    that.removingIdentifier = false;
                }).catch(function(){
                    that.removingIdentifier = false;
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
