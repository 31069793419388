<template>
    <div :key="$route.fullPath">
        <main-template>
            <template slot-scope="{logo}">
                <fetch-root-siren :linkName='linkName' :link="link">
                    <div slot-scope="{ response }">
                        <loading v-if="!response"></loading>
                        <template v-else>
                            <page-header :logo="logo">
                                <div class="md:flex justify-between w-full items-center">
                                    Widgets
                                    <div class="flex items-center -mt-3 text-secondary-darkgrey">
                                        <div v-for="actionWithFields in response.body.actions.filter(action => !action.fields.isEmpty() && action.name !== 'add-default-widget')"
                                             class="mr-1">
                                            <form-action :action="actionWithFields[1]"
                                                              :actionKey="actionWithFields[0]"
                                                              :redirectOnSuccess="false"
                                                              @success="success(actionWithFields[0])"
                                            ></form-action>
                                        </div>
                                        <div class="mr-1">
                                            <a-button @click.native="goToCreateWidgetPage()" v-if="response.body.actions.filter(action => action.name === 'add-dashboard-widget').first()">Create Default Widget</a-button>
                                        </div>
                                    </div>
                                </div>

                            </page-header>
                            <div class="container mx-auto p-4 mb-8">
                                <filterable-paginated-dashboard-widgets-list :response="response">

                                </filterable-paginated-dashboard-widgets-list>
                            </div>
                        </template>
                    </div>
                </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import DashboardWidgetsHeader from "../../components/Headers/DashboardWidgetsHeader";
    import SavedFilters from "../../components/SavedFilters";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import FilterablePaginatedDashboardWidgetsList from "../../components/FilterablePaginatedDashboardWidgetsList";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import Button from "@/v2/buttons/Button";

    export default {
        components: {
            FormAction,
            MainTemplate,
            FetchRootSiren,
            Loading,
            DashboardWidgetsHeader,
            SavedFilters,
            FilterablePaginatedDashboardWidgetsList,
            PageHeader,
            'a-button': Button
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods:{
            success(key) {
                this.$router.go(0);
            },
            goToCreateWidgetPage(){
                this.$router.push('/dashboard-widgets-create');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
