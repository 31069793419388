<template>
    <main-template>
        <template v-slot:default="slotProps">
            <page-header :logo="slotProps.logo">Admin</page-header>
            <admin-submenu-nav :links="slotProps.links" :actions="slotProps.actions">

            </admin-submenu-nav>
        </template>
    </main-template>
</template>

<script>
import MainTemplate from "@/v2/templates/MainTemplate";
import PageTitle from "@/common/PageTitle";
import PageHeader from "@/v2/components/Header";
import OfficeSubmenuNav from "@/v2/components/OfficeSubmenuNav";
import ManagementSubmenuNav from "@/v2/components/ManagementSubmenuNav";
import QASubmenuNav from "@/v2/components/QASubmenuNav";
import InventorySubmenuNav from "@/v2/components/InventorySubmenuNav";
import AdminSubmenuNav from "@/v2/components/AdminSubmenuNav";

export default {
    name: "RentalItems",
    components: {
        AdminSubmenuNav,
        InventorySubmenuNav,
        QASubmenuNav,
        ManagementSubmenuNav,
        OfficeSubmenuNav,
        PageTitle,
        MainTemplate,
        PageHeader
    },
    computed: {
    },
    methods: {
    }
}

</script>

<style scoped>

</style>
