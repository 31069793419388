<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <a href="/rental-items">Rental Items</a>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('description')}}
                        </span>

                        <status-badge class="ml-2" :status="response.body.properties.get('status')"></status-badge>
                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-2">
                                        <div class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                    Rental Item Details
<!--                                                    <svg @click="displayRfiDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">-->
<!--                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />-->
<!--                                                    </svg>-->
                                                </dt>
                                                <dd class="text-sm text-black font-bold">{{ response.body.properties.get('description') }}</dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Requested By</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{response.body.properties.get('requested_by_user')}}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Contract</dt>
                                                <dd class="text-sm font-bold text-black">{{response.body.properties.get('contract')}}</dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Quantity</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('quantity') }}
                                                </dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Due Date</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('due_date') }}
                                                </dd>
                                            </div>
                                            <div v-if="response.body.properties.get('show_account_used') && response.body.properties.get('account_used')">
                                                <dt class="text-sm font-nomal text-gray-500">Account Used</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('account_used') }}
                                                </dd>
                                            </div>
                                        </div>
                                        <div class="py-6 md:py-0">
                                            <div class="bg-secondary-lightbacka shadow sm:rounded-lg">
                                                <div class="px-4 py-5 sm:px-6 grid grid-cols-3 gap-2">
                                                    <div class="bg-white sm:rounded-lg p-2 border cursor-pointer" @click="displayInvoices = true" v-if="response.body.properties.get('show_invoices')">
                                                        <p class="text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                            Invoices <span class="cursor-pointer font-normal underline text-gray-400">View</span>
                                                        </p>
                                                        <dd class="text-sm text-black font-bold">{{ response.body.properties.get('invoices_total') }}</dd>
                                                    </div>
                                                    <div v-if="response.body.properties.get('rental_fee')" class="bg-white sm:rounded-lg p-2 border cursor-pointer" @click="displayApproval = true">
                                                        <p class="text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                            Approval <span class="cursor-pointer font-normal underline text-gray-400">View</span>
                                                        </p>
                                                        <dd class="text-sm text-black font-bold">{{ response.body.properties.get('rental_fee') }} / {{ response.body.properties.get('rental_fee_per') }}</dd>
                                                    </div>
                                                </div>
                                             </div>
                                            <div class="border mt-4 sm:rounded-lg p-4" v-if="response.body.properties.get('rental_fee')">
                                                <h3 class="">Rental Item Fees</h3>
                                                <p class="text-sm text-gray-500"><span>Rental fee </span>{{response.body.properties.get('rental_fee')}} <span>per</span> {{response.body.properties.get('rental_fee_per')}}<br/>

                                                    <span>Pickup fee </span> {{response.body.properties.get('pickup_fee')}}<br/>
                                                    <span>Extra fee </span> {{response.body.properties.get('extra_fee')}}</p>

                                                <p class="text-sm text-gray-500" v-if="response.body.properties.get('supplier')"><span>Supplier </span> {{response.body.properties.get('supplier_name')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <section class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <rental-item-actions :response="response"  :remainingActions="
                                response.body.actions.filter(action =>
                                    action.name !== 'update-rental-item'
                                )"></rental-item-actions>
                            </div>
                        </section>
                    </div>

                    <large-model v-show="displayInvoices" @removeFromDisplay="displayInvoices=false" :displayConfirmButton="false">
                        <p class="text-xl mx-auto px-4 sm:px-6 md:px-8">Invoices</p>
                        <order-invoice-paginated-list :response="response"></order-invoice-paginated-list>
                    </large-model>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import StatusBadge from "@/components/StatusBadge";
import PageHeader from "@/v2/components/Header";
import Actions from "@/v2/components/Actions";
import LargeModel from "@/v2/components/LargeModel";
import OrderInvoicePaginatedList from "@/v2/paginatedLists/OrderInvoicePaginatedList";
import RentalItemActions from "@/v2/components/RentalItemActions";
export default {
    name: "Rfi",
    data() {
        return {
            displayInvoices: false,
            displayApproval: false
        }
    },
    components: {
        PageHeader,
        StatusBadge,
        Button,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        Actions,
        LargeModel,
        OrderInvoicePaginatedList,
        RentalItemActions
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        showOrderItems(){
            this.displayOrderItems = true;
        },
        async saveItems(){
            if (!this.orderItemsSubmitting) {
                this.orderItemsSubmitting = true;
                this.$refs.orderItems.updateItems().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'The order was saved successfully'
                    );
                    this.hasChangesWaitingOnSave = false;
                    this.orderItemsSubmitting = false;
                    this.$router.go(0);
                }).catch(error => {
                    alert('There was an error processing your request.' + error.error);
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.orderItemsSubmitting = false;
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
