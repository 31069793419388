<template>
    <div>
        <main-template>
            <page-title title="Export PI/PC"></page-title>
            <div class="container mx-auto px-4">
                <invoice-export-form @success="showReimportForm = true" v-if="!showReimportForm && action" :action="action"></invoice-export-form>
                <reimport-form v-if="showReimportForm && reimportaction" :action="reimportaction"></reimport-form>
                <div v-if="!showReimportForm" class="text-center pt-4 text-xs hover:underline text-gray-400 cursor-pointer" @click="showReimportForm = true">Already done this? - Skip to reimport</div>
            </div>
        </main-template>
    </div>
</template>

<script>
    import PageTitle from '../common/PageTitle';
    import { mapGetters } from "vuex";
    import Siren from 'super-siren';
    import ActionHelper from '../mixins/Siren/ActionHelper';
    import MainTemplate from "../templates/MainTemplate";
    import InvoiceExportForm from "../components/CustomForms/InvoiceExportForm";
    import ReimportForm from "../components/CustomForms/ReimportForm";
    let follow = Siren.Helper.follow;
    export default {
        mixins: [ActionHelper],
        name: "Reports",
        components: {
            PageTitle,
            MainTemplate,
            InvoiceExportForm,
            ReimportForm
        },
        data() {
            return {
                action: null,
                reimportaction: null,
                showReimportForm: false
            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
        },
        mounted () {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow("orders"))
                .then(res => {
                    let siren = res.body;
                    this.action = siren.actions.filter(action => action.name === 'export-invoices').get('export-invoices');
                    this.reimportaction = siren.actions.filter(action => action.name === 'reimport-with-sage-numbers').get('reimport-with-sage-numbers');
                }, this);

        }
    };
</script>
