<template>
  <div >
      <top-header-template>
        <div class="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Sign up application submitted successfully.
                </h2>
            </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <p>
                        Your sign up application was sent successfully. Once your application is approved, you will recieve an e-mail with a login link.
                    </p>
                </div>
            </div>
        </div>
  </top-header-template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Siren from "super-siren";
import TopHeaderTemplate from "../templates/TopHeaderTemplate";

export default {
    components: {TopHeaderTemplate},
    data() {
        return {
            error: null,
            email: null,
            password: null,
            contactNumber: null,
            name: null,
            clientName: null,
            vatNumber: null,
            companyRegistrationNumber : null,
            storeClientAction: null,
            storeClientAdminAction: null
        };
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        })
    },
    created() {
        let follow = Siren.Helper.follow;
        Siren.Client.removeHeader("Authorization");
        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
            let siren = response.body;
            this.storeClientAction = siren.findActionByName('store-new-client');
            this.storeClientAdminAction = siren.findActionByName('store-new-client-admin');
        });
    },
    methods: {
        onSubmit() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.storeClientAction.perform({
                        name: this.clientName,
                        vat_number: this.vatNumber,
                        company_registration_number: this.companyRegistrationNumber
                    })
                        .then(res => {
                            this.storeClientAdminAction.perform({
                                name: this.name,
                                email: this.email,
                                password: this.password,
                                contact_number: this.contactNumber,
                                client_id: res.body.properties.get('id')
                            })
                                .then(res => {
                                    this.$store.dispatch(
                                        "setMessage",
                                        "Your sign up application was sent successfully. Once your application is approved, you will recieve an e-mail with a login link."
                                    );
                                    debugger;
                                    this.$router.push("/login");
                                })
                                .catch(error => {
                                    this.submitting = false;
                                    if (error.response.status !== 422) {
                                        alert('There was an error processing your request.' + error);
                                    }
                                    this.errors.clear("client");
                                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text), "client_admin");

                                })
                        })
                        .catch(error => {
                            this.submitting = false;
                            if (error.response.status !== 422) {
                                alert('There was an error processing your request.' + error);
                            }
                            this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text), "client");
                        })
                }
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        }
    }
}

</script>
