<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Sales Invoices</h2>
                <span class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty())"
                         class="mr-1">
                        <create-sales-invoice-action v-if="actionWithFields[0] === 'create-new-invoice'" :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="false"
                                          @success="redirectToItem"
                        ></create-sales-invoice-action>
                        <action-with-form v-else :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="false"
                                          @success="redirectToItem"
                        ></action-with-form>
                    </div>
                </span>
            </div>
        </div>
    </header>
</template>

<script>
    import ActionWithForm from "./ActionWithForm";
    import ExportInvoices from "./ExportInvoices";
    import CreateSalesInvoiceAction from "@/components/CustomForms/CreateSalesInvoiceAction";
    export default {
        components: {
            ActionWithForm,
            ExportInvoices,
            CreateSalesInvoiceAction
        },
        inject: ["$validator"],
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'filter');
            },
        },
        methods: {
            redirectToItem(response) {
                let selfLink = response.body.links.filter(link => link.rels.contains("self")).first().href;
                let parts = selfLink.split("/api");
                this.$router.push(parts[1])
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
