6<template>
    <div>
        <div v-if="remainingActions.filter(action => action.name === 'approve-quotes').first()">
            <quote-approval-action class="my-2" :approveAction="getAction('approve-quotes')" :rejectAction="getAction('reject-quotes')" :onSuccessPath="$router.currentRoute.fullPath"></quote-approval-action>
        </div>
        <a-button v-if="getNumberOfItems() === 0" @click.native="displayOrderItems" class="my-2 w-full">Add Items</a-button>
        <div v-for="(action, index) in remainingActions.filter(action => action.name !== 'select-quote-and-ask-cms-to-beat' && action.name !== 'approve-competing-quote')">
            <template v-if="!action[1].fields.isEmpty() && action[0] !== 'reject-quotes'">
                <email-preview-form additionalButtonClasses="my-2 w-full" v-if="action[0] === 'send-quotation-request'"
                                    :action="action[1]"
                                    :actionKey="action[0]"
                                    :onSuccessPath="$router.currentRoute.fullPath"
                                    :buttonType="getColourType(action[0], index)"></email-preview-form>
                <select-quote-form v-else-if="action[0] === 'select-quote-for-approval' || action[0] === 'select-competing-quote' "
                                   :action="action[1]"
                                   :actionKey="action[0]"
                                   :onSuccessPath="$router.currentRoute.fullPath"
                                   :secondAction="remainingActions.filter(action2 => !action2.fields.isEmpty() && action2.name ==='select-quote-and-ask-cms-to-beat').first()"
                                   additionalButtonClasses="my-2 w-full"
                                   :buttonType="getColourType(action[0], index)"
                ></select-quote-form>
                <update-scheduled-file-form v-else-if="action[0] === 'update-scheduled-file'"
                                   :action="action[1]"
                                   :actionKey="action[0]"
                                   :onSuccessPath="$router.currentRoute.fullPath"
                                   :secondAction="action[1]"
                                   additionalButtonClasses="my-2 w-full"
                                   :buttonType="getColourType(action[0], index)"
                ></update-scheduled-file-form>
                <goods-in-note-form v-else-if="action[0] === 'add-goods-in-note' || action[0] === 'mark-as-fully-delivered' "
                                    :response="response" :action="action[1]"
                                    :actionKey="action[0]"
                                    :onSuccessPath="$router.currentRoute.fullPath"
                                    additionalButtonClasses="my-2 w-full"
                                    :buttonType="getColourType(action[0], index)"
                ></goods-in-note-form>

                <split-order-form v-else-if="action[0] === 'split-order'"
                                  :response="response" :action="action[1]"
                                  :actionKey="action[0]"
                                  :onSuccessPath="$router.currentRoute.fullPath"
                                  additionalButtonClasses="my-2 w-full"
                                  :buttonType="getColourType(action[0], index)"></split-order-form>

                <returns-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'create-return-request'"
                              :response="response" :action="action[1]"
                              :actionKey="action[0]"
                              :onSuccessPath="$router.currentRoute.fullPath"
                              :buttonType="getColourType(action[0], index)">
                </returns-form>
                <reconcile-order-form v-else-if="action[0] === 'reconcile-order' || action[0] === 'create-invoice-in'"
                                      :action="action[1]"
                                      :actionKey="action[0]"
                                      :onSuccessPath="$router.currentRoute.fullPath"
                                      additionalButtonClasses="my-2 w-full"
                                      :buttonType="getColourType(action[0], index)"
                >
                </reconcile-order-form>
                <new-credit-note-form v-else-if="action[0] === 'add-credit-note'"
                                      :action="action[1]"
                                      :actionKey="action[0]"
                                      :onSuccessPath="$router.currentRoute.fullPath"
                                      additionalButtonClasses="my-2 w-full"
                                      :buttonType="getColourType(action[0], index)"
                ></new-credit-note-form>
                <confirmation-action additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'mark-as-paid'" :action="action[1]" :actionKey="action[0]" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)"></confirmation-action>
                <ready-for-quote-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'mark-as-ready-for-quote'" :action="action[1]" :actionKey="action[0]" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)"></ready-for-quote-form>
                <review-quotes-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'reject-competing-quote'" :response="response"
                               :firstAction="action[1]" :secondAction="remainingActions.filter(action => action.fields.isEmpty() && action.name ==='approve-competing-quote').first()"
                               :onSuccessPath="$router.currentRoute.fullPath"
                ></review-quotes-form>
                <confirmation-action additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'mark-as-take-off'" :action="action[1]" :actionKey="action[0]" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)"></confirmation-action>



                <form-action v-else :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></form-action>
            </template>
            <template v-else-if="action[1].fields.isEmpty() && action[0] !== 'approve-quotes'">
                <confirmation-action v-if="action[0] === 'delete-order'" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties" onSuccessPath="/orders"></confirmation-action>
                <confirmation-action v-else additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath"></confirmation-action>
            </template>
        </div>
    </div>
</template>

<script>
import Button from "@/v2/buttons/Button";
import ApproveButtons from "@/v2/buttons/ApproveButtons";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
import QuoteApprovalAction from "@/v2/components/QuoteApprovalAction";
import ReturnsForm from "@/v2/components/forms/ReturnsForm";
import GoodsInNoteForm from "@/v2/components/forms/GoodsInNoteForm";
import ReconcileOrderForm from "@/v2/components/forms/ReconcileOrderForm";
import NewCreditNoteForm from "@/v2/components/forms/NewCreditNoteForm";
import EmailPreviewForm from "@/v2/components/forms/EmailPreviewForm";
import SelectQuoteForm from "@/v2/components/forms/SelectQuoteForm";
import SplitOrderForm from "@/v2/components/forms/SplitOrderForm";
import ReadyForQuoteForm from "@/v2/components/forms/ReadyForQuoteForm";
import ReviewQuotesForm from "@/v2/components/forms/ReviewQuotesForm";
import UpdateScheduledFileForm from "@/v2/components/forms/UpdateScheduledFileForm.vue";
export default {
    name: "OrderActions",
    data() {
        return {
            displayApproveModal : false,
            displayRejectModal : false
        }
    },
    components:{
        QuoteApprovalAction,
        FormAction,
        ConfirmationAction,
        'a-button' : Button,
        ApproveButtons,
        ReturnsForm,
        GoodsInNoteForm,
        ReconcileOrderForm,
        NewCreditNoteForm,
        EmailPreviewForm,
        SelectQuoteForm,
        SplitOrderForm,
        ReadyForQuoteForm,
        ReviewQuotesForm,
        UpdateScheduledFileForm
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update' &&
                    action.name !== 'update-invoice' &&
                    action.name !== 'add-new-item' &&
                    action.name !== 'search-products' &&
                    action.name !== 'update-quote' &&
                    action.name !== 'add-comment' &&
                    action.name !== 'add-product-to-order' &&
                    action.name !== 'get-pre-priced-values' &&
                    action.name !== 'flag-order' &&
                    action.name !== 'select-cms-quote' &&
                    action.name !== 'filter-products'
                )
        },
    },
    methods:{
        getColourType(action, index){
            if(action === 'delete-order'){
                return 'negative';
            }
            if(action === 'mark-as-ready-for-quote'){
                return 'positive';
            }
            return  index === 0 && this.getNumberOfItems() !== 0 ? 'primary': 'secondary';
        },
        getAction(actionName){
            return this.remainingActions.filter(action => action.name === actionName).first();
        },
        getNumberOfItems(){
            return this.response.body.entities.filter(entity => entity.rels.contains('order_item')).count();
        },
        displayOrderItems(){
            this.$emit('displayOrderItems', true);
        }
    }
}
</script>

<style scoped>

</style>
