<template>
    <div>
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select id="tabs"  name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option>
                    Daily Reports
                </option>
                <option v-if="links.filter(link => link.rels.contains('oss-weekly-report')).count() > 0">
                    Weekly Reports
                </option>
                <option v-if="links.filter(link => link.rels.contains('oss-timesheet-builder')).count() > 0">
                    Timesheet Builder
                </option>
            </select>
        </div>
        <div class="hidden sm:block">
            <div class="border-b border-gray-200">
                <nav class="flex space-x-8 ml-4" aria-label="Tabs">
                    <a @click="navigateTo('oss-daily-report')" href="#"
                       class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                       :class="{'text-secondary-lightblue border-secondary-lightblue focus:secondary-lightblue focus:secondary-lightblue': isActive('daily-reports')}"
                    >
                        Daily Report
                    </a>
                    <a v-if="links.filter(link => link.rels.contains('oss-weekly-report')).count() > 0" @click="navigateTo('oss-weekly-report')" href="#"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                       :class="{'text-secondary-lightblue border-secondary-lightblue focus:secondary-lightblue focus:secondary-lightblue': isActive('weekly-reports')}"
                    >
                        Weekly Report
                    </a>
                    <a v-if="links.filter(link => link.rels.contains('oss-timesheet-builder')).count() > 0" @click="navigateTo('oss-timesheet-builder')" href="#"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                       :class="{'text-secondary-lightblue border-secondary-lightblue focus:secondary-lightblue focus:secondary-lightblue': isActive('timesheet-builder')}"
                    >
                        Timesheet Builder
                    </a>
                </nav>
            </div>
        </div>

    </div>
</template>

<script>
import Siren from "super-siren";
import {mapGetters} from "vuex";

export default {
    name: "OrderTabs",
    props: {
        response: {
            type: Object,
        },
    },
    data(){
        return {
            links:null
        }
    },
    mounted() {
        let follow = Siren.Helper.follow;
        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
            let siren = res.body;
            this.links = siren.links;
            return res;
        }, this)
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
            message: "getMessage",
            messageWithoutTimeout: "getMessageWithoutTimeout"
        }),
    },
    methods: {
        navigateTo(linkName){
            console.log(this.links);
            window.location = this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(linkName)).first().href);
        },
        isActive(pageName){
            if(this.$route.path.includes(pageName)){
                return true;
            };
            return false;
        }
    }
}
</script>

<style scoped>

</style>
