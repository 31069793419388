<template>
    <div>
        <a-button  v-if="isButton && !isNegativeAction && !isPositiveAction" class="mr-3" :class="additionalButtonClasses" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">{{action.title}}</a-button>
        <red-button  v-else-if="isButton && isNegativeAction" class="mr-3" :class="additionalButtonClasses" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">{{action.title}}</red-button>
        <green-button  v-else-if="isButton && isPositiveAction" class="mr-3" :class="additionalButtonClasses" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`"><slot></slot>{{action.title}}</green-button>
        <a v-else class="cursor-pointer font-normal underline text-gray-400"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">{{action.title}}</a>
        <portal to="destination">
            <modal v-if="displayModal" :submitting="submittingModal" :title="action.title" :confirmationString='finalConfirmationString' @removeFromDisplay="displayModal = false" @perform="perform()" :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction"></modal>
        </portal>
    </div>
</template>

<script>
    import Button from './Button';
    import Modal from './Modal';
    import RedButton from "./RedButton";
    import GreenButton from "./GreenButton";
    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false
            }
        },
        components: {
            'a-button' : Button,
            Modal,
            RedButton,
            GreenButton
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            properties:{
                type: Object
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            isButton:{
                type: Boolean,
                default:true
            },
            additionalButtonClasses:{
                type:String
            },
            isNegativeAction: {
                type:Boolean,
                default:false
            },
            isPositiveAction:{
                type:Boolean,
                default:false
            }
        },
        computed: {
            finalConfirmationString() {
                var preText = '';
                if (this.action.name === 'request-update'){
                    if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                    preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
                }

                return preText + `Are you sure you want to "${this.action.title}"`
            }
        },
        methods: {
            perform() {
                this.submittingModal = true;
                this.action.perform().then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if(newPath  !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        }else{
                            this.$router.go(0);
                        }
                    }
                }).catch(error => {
                    alert('There was an error processing your request.' + error);
                    this.displayModal = false;
                    this.submittingModal = false;
                });
            },
            messages(response) {
            return response.body.entities
                .filter(function(entity) {
                return entity.rels.contains("status-messages");
                })
                .first();
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
