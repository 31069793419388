<template>
    <div>
        <a-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>
      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">
            <template v-if="action.fields.get('client_id').type !== 'hidden'">
                <component :is="getFieldComponent(action.fields.get('client_id'))" :field="action.fields.get('client_id')" v-model="formData['client_id']" @input="setClientId">
                </component>
                <div v-show="errors.has('client_id')" class="text-red-600">{{ errors.first('client_id') }}</div>
            </template>

            <component :is="getFieldComponent(action.fields.get('user_id'))" :field="action.fields.get('user_id')" v-model="formData['user_id']" dependsOn="client_id" :dependsOnValue="clientId">
            </component>
            <div v-show="errors.has('user_id')" class="text-red-600">{{ errors.first('user_id') }}</div>

            <component :is="getFieldComponent(action.fields.get('date'))" :field="action.fields.get('date')" v-model="formData['date']">
            </component>
            <div v-show="errors.has('date')" class="text-red-600">{{ errors.first('date') }}</div>

            <component :is="getFieldComponent(action.fields.get('time'))" :field="action.fields.get('time')" v-model="formData['time']">
            </component>
            <div v-show="errors.has('time')" class="text-red-600">{{ errors.first('time') }}</div>

            <template v-if="action.fields.get('latitude')">
                <div class="flex items-center">
                    <component :is="getFieldComponent(action.fields.get('latitude'))" :field="action.fields.get('latitude')" v-model="latitude">
                    </component>
                    <location-picker-with-icon class="mt-4" :x="latitude" :y="longitude" @sendLocation="saveLocation"></location-picker-with-icon>
                </div>
                <div v-show="errors.has('latitude')" class="text-red-600">{{ errors.first('latitude') }}</div>
            </template>

            <template v-if="action.fields.get('longitude')">
                <div class="flex items-center">
                    <component :is="getFieldComponent(action.fields.get('longitude'))" :field="action.fields.get('longitude')" v-model="longitude">
                    </component>
                    <location-picker-with-icon class="mt-4" :x="latitude" :y="longitude" @sendLocation="saveLocation"></location-picker-with-icon>
                </div>
                <div v-show="errors.has('longitude')" class="text-red-600">{{ errors.first('longitude') }}</div>
            </template>

            <component :is="getFieldComponent(action.fields.get('project_id'))" :field="action.fields.get('project_id')" v-model="formData['project_id']" dependsOn="client_id" :dependsOnValue="clientId">
            </component>
            <div v-show="errors.has('project_id')" class="text-red-600">{{ errors.first('project_id') }}</div>

            <component :is="getFieldComponent(action.fields.get('photo'))" :field="action.fields.get('photo')" v-model="formData['photo']">
            </component>
            <div v-show="errors.has('photo')" class="text-red-600">{{ errors.first('photo') }}</div>

            <component :is="getFieldComponent(action.fields.get('comment'))" :field="action.fields.get('comment')" v-model="formData['comment']">
            </component>
            <div v-show="errors.has('comment')" class="text-red-600">{{ errors.first('comment') }}</div>
            <template v-if="action.fields.get('is_night_shift')">

                <component :is="getFieldComponent(action.fields.get('is_night_shift'))" :field="action.fields.get('is_night_shift')" v-model="formData['is_night_shift']">
                </component>
                <div v-show="errors.has('is_night_shift')" class="text-red-600">{{ errors.first('is_night_shift') }}</div>
            </template>

            <div v-show="errors.has('items')" class="text-red-600 text-sm">{{ errors.first('items') }}</div>

        </slide-over>
        <modal class="z-50" v-if="displayClockInError && errors.has('clock-in')" title="Already Clocked In" :confirmation-string="errors.first('clock-in')"
               @removeFromDisplay="displayClockInError=false" @perform="displayClockInError=false" :hasConfirmationAction="false">
        </modal>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import LocationPickerWithIcon from "@/components/LocationPickerWithIcon";
import Modal from "@/v2/components/Modal.vue";

export default {
    name: "CreateClientForm",
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            clientId: null,
            latitude: null,
            longitude: null,
            displayClockInError: false
        }
    },
    components: {
        Modal,
        SlideOver,
        'a-button': Button,
        LocationPickerWithIcon
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            this.clientId = payload['client_id'];
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                    this.displayClockInError = true;
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        setClientId(value){
            this.clientId = value;
        },
        saveLocation(location){
            this.latitude = location.x;
            this.longitude = location.y;
            this.formData['latitude'] = location.x;
            this.formData['longitude'] = location.y;
        }
    },
}
</script>

<style scoped>

</style>
