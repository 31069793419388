<template>
    <div class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-3 pt-6">
        <div class="border md:rounded-lg mb-8 bg-white grid grid-cols-5 col-span-3 section-links-2">
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('vehicle-requirements')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('vehicle-requirements')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M257.1 128h-52C167 128 136 159 136 198c0 13 11 24 24 24s24-11 24-24C184 186 193.1 176 205.1 176h52C269.1 176 280 186 280 198c0 8-4 15-11 19L212 251C204 256 200 264 200 272V288c0 13 11 24 24 24S248 301 248 288V286l45.1-28C315 245 328 222 328 198C328 159 297 128 257.1 128zM224 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S241.1 336 224 336zM384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM400 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h320c8.822 0 16 7.178 16 16V416z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue ">Site Vehicle Entry Log</p>
<!--                        <p>View RFIs</p>-->
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('on-site-selfie')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('on-site-selfie')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 256c35.35 0 64-28.65 64-64s-28.65-64-64-64S128 156.7 128 192S156.7 256 192 256zM320 0H64C28.65 0 0 28.65 0 64v384c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V64C384 28.65 355.3 0 320 0zM336 448c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V64c0-8.822 7.178-16 16-16h256c8.822 0 16 7.178 16 16V448zM224 288H160c-44.18 0-80 35.82-80 80C80 376.8 87.16 384 96 384h192c8.836 0 16-7.164 16-16C304 323.8 268.2 288 224 288z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">On-Site Selfie</p>
<!--                        <p>View address book</p>-->
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('procurement')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('procurement')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM475.6 240L520.6 80H131.1L161.6 240H475.6zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z"/></svg>

                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Procurement</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('quality-assessments')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('quality-assessments')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M88 48C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72C16 58.75 26.75 48 40 48H88zM488 72C501.3 72 512 82.75 512 96C512 109.3 501.3 120 488 120H184C170.7 120 160 109.3 160 96C160 82.75 170.7 72 184 72H488zM488 232C501.3 232 512 242.7 512 256C512 269.3 501.3 280 488 280H184C170.7 280 160 269.3 160 256C160 242.7 170.7 232 184 232H488zM488 392C501.3 392 512 402.7 512 416C512 429.3 501.3 440 488 440H184C170.7 440 160 429.3 160 416C160 402.7 170.7 392 184 392H488zM16 232C16 218.7 26.75 208 40 208H88C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232zM88 368C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392C16 378.7 26.75 368 40 368H88z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Quality Assessments</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('day-pay-requests')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('day-pay-requests')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M88 48C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72C16 58.75 26.75 48 40 48H88zM488 72C501.3 72 512 82.75 512 96C512 109.3 501.3 120 488 120H184C170.7 120 160 109.3 160 96C160 82.75 170.7 72 184 72H488zM488 232C501.3 232 512 242.7 512 256C512 269.3 501.3 280 488 280H184C170.7 280 160 269.3 160 256C160 242.7 170.7 232 184 232H488zM488 392C501.3 392 512 402.7 512 416C512 429.3 501.3 440 488 440H184C170.7 440 160 429.3 160 416C160 402.7 170.7 392 184 392H488zM16 232C16 218.7 26.75 208 40 208H88C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232zM88 368C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392C16 378.7 26.75 368 40 368H88z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Day Pay Requests</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasSagesLink() {
            return this.hasLink('sages') || this.hasLink('sage-descriptions') || this.hasLink('sage-nominal-codes');
        },
    },
}
</script>

<style scoped>

</style>
