<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <dashboards-header :response="response"></dashboards-header>
                        <div class="container mx-auto p-4 mb-8">
                            <filterable-paginated-dashboards-list :response="response">
                            </filterable-paginated-dashboards-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import MainTemplate from "../../templates/MainTemplate";
    import FilterablePaginatedDashboardsList from "../../components/FilterablePaginatedDashboardsList";
    import DashboardsHeader from "../../components/DashboardsHeader";

    export default {
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            DashboardsHeader,
            FilterablePaginatedDashboardsList
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
