<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" @unauthorised="unauthorised">


            <div slot-scope="{ response }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <span @click="navigateToSubscriptions(response)" class="cursor-pointer">
                            Subscriptions
                        </span>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('name')}}
                        </span>

                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-2">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                   Subscription Details
                                                    <svg @click="displaySubscriptionDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </dt>
                                                <dd class="text-sm text-black font-bold">{{ response.body.properties.get('name') }}</dd>
                                            </div>

                                            <div v-if="response.body.properties.get('login_user')">
                                                <dt class="text-sm font-nromal text-gray-500">Login User</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('login_user') }}
                                                </dd>

                                            </div>
                                            <div v-if="response.body.properties.get('login_password')">
                                                <dt class="text-sm font-nromal text-gray-500">Login Password</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('login_password') }}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Tax Code</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('tax_code') }}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Short Description</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('short_description') }}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Nominal Code</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('nominal_code') }}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Notes</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('notes') }}
                                                </dd>

                                            </div>
                                        </dl>
                                        <div class="py-6 md:py-0">
                                            <div class="bg-secondary-lightbacka shadow sm:rounded-lg">
                                                <div class="px-4 py-5 sm:px-6 grid grid-cols-2 xl:grid-cols-3 gap-2">
                                                    <div class="bg-white sm:rounded-lg p-2 border cursor-pointer" @click="displayInvoices = true">
                                                        <p class="text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                            Invoices <span class="cursor-pointer font-normal underline text-gray-400">View</span>
                                                        </p>
                                                        <dd class="text-sm text-black font-bold">{{ response.body.properties.get('invoices_total') }}</dd>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <section v-if="response.body.actions.filter(action =>
                                    action.name !== 'update-subscription'
                                ).count() > 0" class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <subscription-actions :response="response" :remainingActions="
                                response.body.actions.filter(action =>
                                    action.name !== 'update-subscription'
                                )"></subscription-actions>
                            </div>
                        </section>
                    </div>

                    <update-subscription-form :displayModal="displaySubscriptionDetails" :action="response.body.actions.filter(action => action.name==='update-subscription').first()" @removeFromDisplay="displaySubscriptionDetails=false" :onSuccessPath="$route.fullPath" :showButton="false"></update-subscription-form>

                    <large-model v-show="displayInvoices" @removeFromDisplay="displayInvoices=false" :displayConfirmButton="false">
                        <p class="text-xl mx-auto px-4 sm:px-6 md:px-8">Invoices</p>
                        <order-invoice-paginated-list :response="response"></order-invoice-paginated-list>
                    </large-model>



                </template>
                <modal title="No Access" confirmation-string="" v-if="showUnauthorised" @perform="goToSubscriptions" @removeFromDisplay="goToSubscriptions">
                    You do not have access to view this subscription.
                </modal>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import Actions from "@/v2/components/Actions";
import SubscriptionActions from "@/v2/components/SubscriptionActions.vue";
import OrderInvoicePaginatedList from "@/v2/paginatedLists/OrderInvoicePaginatedList.vue";
import LargeModel from "@/v2/components/LargeModel.vue";
import FetchRootSiren from "@/components/FetchSirenRoot.vue";
import Modal from "@/v2/components/Modal.vue";
import UpdateSubscriptionForm from "@/v2/components/forms/UpdateSubscriperForm.vue";
export default {
    name: "Subscription",
    data() {
        return {
            displaySubscriptionDetails: false,
            displayInvoices: false,
            showUnauthorised: false
        }
    },
    components: {
        UpdateSubscriptionForm,
        Modal,
        FetchRootSiren,
        LargeModel, OrderInvoicePaginatedList,
        SubscriptionActions,
        Actions,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToSubscriptions(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('subscriptions')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        unauthorised(){
            this.showUnauthorised = true;
        },
        goToSubscriptions(){
            this.$router.push('/subscriptions');
        }
    }
}
</script>

<style scoped>

</style>
