<template>
    <div>
        <a-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">

            <component v-if="getField('prefix')" :is="getFieldComponent(getField('prefix'))" :field="getField('prefix')" v-model="formData[getField('prefix').name]">
            </component>
            <div v-show="errors.has(getField('prefix').name)" class="text-red-600 text-sm">{{ errors.first(getField('prefix').name) }}</div>

            <component v-if="getField('name')" :is="getFieldComponent(getField('name'))" :field="getField('name')" v-model="formData[getField('name').name]">
            </component>
            <div v-show="errors.has(getField('name').name)" class="text-red-600 text-sm">{{ errors.first(getField('name').name) }}</div>

            <component v-if="getField('address')" :is="getFieldComponent(getField('address'))" :field="getField('address')" v-model="formData[getField('address').name]">
            </component>
            <div v-show="errors.has(getField('address').name)" class="text-red-600 text-sm">{{ errors.first(getField('address').name) }}</div>

            <component v-if="getField('user_id')" :is="getFieldComponent(getField('user_id'))" :field="getField('user_id')" v-model="formData[getField('user_id').name]">
            </component>
            <div v-show="errors.has(getField('user_id').name)" class="text-red-600 text-sm">{{ errors.first(getField('user_id').name) }}</div>

            <component v-if="getField('project_id')" :is="getFieldComponent(getField('project_id'))" :field="getField('project_id')" v-model="formData[getField('project_id').name]">
            </component>
            <div v-show="errors.has(getField('project_id').name)" class="text-red-600 text-sm">{{ errors.first(getField('project_id').name) }}</div>

            <component v-if="getField('is_approval_required')" :is="getFieldComponent(getField('is_approval_required'))" :field="getField('is_approval_required')" v-model="formData[getField('is_approval_required').name]">
            </component>
            <div v-show="errors.has(getField('is_approval_required').name)" class="text-red-600 text-sm">{{ errors.first(getField('is_approval_required').name) }}</div>

            <component v-if="getField('approval_users')" :is="getFieldComponent(getField('approval_users'))" :field="getField('approval_users')" v-model="formData[getField('approval_users').name]">
            </component>
            <div v-show="errors.has(getField('approval_users').name)" class="text-red-600 text-sm">{{ errors.first(getField('approval_users').name) }}</div>

            <template v-if="getField('delivery_contact_id')">
                <component v-if="getField('delivery_contact_id')" :is="getFieldComponent(getField('delivery_contact_id'))" :field="getField('delivery_contact_id')" v-model="formData[getField('delivery_contact_id').name]">
                </component>
                <div v-show="errors.has(getField('delivery_contact_id').name)" class="text-red-600 text-sm">{{ errors.first(getField('delivery_contact_id').name) }}</div>
            </template>

            <template v-if="getField('sage_reference')">
                <component v-if="getField('sage_reference')" :is="getFieldComponent(getField('sage_reference'))" :field="getField('sage_reference')" v-model="formData[getField('sage_reference').name]">
                </component>
                <div v-show="errors.has(getField('sage_reference').name)" class="text-red-600 text-sm">{{ errors.first(getField('sage_reference').name) }}</div>
            </template>

            <template v-if="getField('budget')">
                <component v-if="getField('budget')" :is="getFieldComponent(getField('budget'))" :field="getField('budget')" v-model="formData[getField('budget').name]">
                </component>
                <div v-show="errors.has(getField('budget').name)" class="text-red-600 text-sm">{{ errors.first(getField('budget').name) }}</div>
            </template>

        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";

export default {
    name: "AddContractForm",
    data() {
        return {
            submittingModal: false
        }
    },
    components: {
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        }
    },
}
</script>

<style scoped>

</style>
