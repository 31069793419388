<template>
    <tr class="bg-white order-item-row">
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
          <property-or-field ref="name" @input="changePresetName" class="mb-2"
                             :properties="orderItemEntity.entity.properties" :scope="scope"
                             :action="updateAction" fieldId="preset-name"
                             propertyKey="preset-name"
                             v-model="updateActionData['preset-name']"></property-or-field>
            <property-or-field v-if="presetName === 'custom' || !updateActionData['name']" ref="name" @input="changeName"
                                            :properties="orderItemEntity.entity.properties" :scope="scope"
                                            :action="updateAction" fieldId="name"
                                            propertyKey="name"
                                            v-model="updateActionData['name']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            <property-or-field ref="image" @input="change"
                                            :properties="orderItemEntity.entity.properties" :scope="scope"
                                            :action="updateAction" fieldId="image"
                                            propertyKey="image"
                                            v-model="updateActionData['image']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            <property-or-field ref="expiry_date" @input="change"
                               :properties="orderItemEntity.entity.properties" :scope="scope"
                               :action="updateAction" fieldId="expiry_date"
                               propertyKey="expiry_date"
                               v-model="updateActionData['expiry_date']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            <property-or-field ref="reminder_limit" @input="change"
                               :properties="orderItemEntity.entity.properties" :scope="scope"
                               :action="updateAction" fieldId="reminder_limit"
                               propertyKey="reminder_limit"
                               v-model="updateActionData['reminder_limit']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            <property-or-field ref="front_or_back" @input="change"
                               :properties="orderItemEntity.entity.properties" :scope="scope"
                               :action="updateAction" fieldId="front_or_back"
                               propertyKey="front_or_back"
                               v-model="updateActionData['front_or_back']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <span v-if="removeAction" class="cursor-pointer font-normal underline text-gray-400" @click="removeItem()">remove</span>
        </td>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from "../mixins/orderItemsUpdate";

    export default {
        inject: ["$validator"],
        mixins: [orderItemsUpdate],
        data(){
          return {
            name: null,
            presetName: null
          }
        },
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            orderItemEntity: {
                type: Object,
            },
            scope: {
                type: String
            },
            response: {
                type: Object
            }
        },
      mounted() {
            this.presetName = this.updateActionData['preset-name'];
          },
      computed: {
            updateAction() {
                return this.orderItemEntity.entity.actions.filter(action => action.name === 'update').first();
            },
            removeAction() {
                return this.orderItemEntity.entity.actions.filter(action => action.name === 'delete').first();
            },
            updateActionData() {
                if (!this.updateAction) {
                    return {};
                }
                if (!this.updateAction.fields) {
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            }
        },
        methods: {
            update() {
              if(this.updateActionData['preset-name'] !== 'custom'){
                this.updateActionData['name'] = this.updateActionData['preset-name'];
              }
              return this.updateAction.perform(this.updateActionData);
            },
            removeItem() {
                return this.removeAction.perform().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'Certificate removed successfully.'
                    );
                    this.$router.go(0);
                }).catch(error => {
                    alert('There was an error processing your request.' + error);
                });
            },
            change() {
                this.$emit('change');
            },
            changeName(value) {
              this.name = value;
              this.$emit('change');
            },
            changePresetName(value) {
              this.presetName = value;
              this.$emit('change');
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
