<template>
    <div class="order-summary">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Supplier Summary</h3>
        <div class="generic-summary p-4 bg-white" :key="key">
            <div class="grid grid-cols-3 pb-4 gap-4">
                <property-or-field @input="change" :properties="properties" title="Name" :action="updateAction" fieldId="name" propertyKey="name" v-model="updateActionData['name']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Sage Reference" :action="updateAction" fieldId="sage_reference" propertyKey="sage_reference" v-model="updateActionData['sage_reference']"></property-or-field>
            </div>
            <div class="grid grid-cols-1 pb-8 gap-4">
                <contacts :response="response"></contacts>
            </div>
            <div class="grid grid-cols-3 pb-4 gap-4">
                <property-or-field @input="change" :properties="properties" title="Credit Limit" :action="updateAction" fieldId="credit_limit" propertyKey="credit_limit" v-model="updateActionData['credit_limit']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Start Date" :action="updateAction" fieldId="credit_limit_start_date" propertyKey="credit_limit_start_date" v-model="updateActionData['credit_limit_start_date']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Payment Terms In Days" :action="updateAction" fieldId="payment_terms_in_days" propertyKey="payment_terms_in_days" v-model="updateActionData['payment_terms_in_days']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Payment Terms From" :action="updateAction" fieldId="payment_terms_from" propertyKey="payment_terms_from" v-model="updateActionData['payment_terms_from']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Type" :action="updateAction" fieldId="type" propertyKey="type" v-model="updateActionData['type']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Self Managed Clients" :action="updateAction" fieldId="self_managed_client_ids" propertyKey="self_managed_client_ids" v-model="updateActionData['self_managed_client_ids']"></property-or-field>
            </div>
            <div class="grid grid-cols-1 pb-4 gap-4">
                <property-or-field @input="change" class="w-full" :properties="properties" title="Notes" :action="updateAction" fieldId="notes" propertyKey="notes" v-model="updateActionData['notes']"></property-or-field>
            </div>
            <div class="grid grid-cols-1 pb-8 gap-4">
                <ratings :response="response"></ratings>
            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from '../PropertyOrField';
    import Contacts from "../Contacts";
    import Ratings from "@/components/Ratings";
    export default {
        inject: ["$validator"],
        components: {
            PropertyOrField,
            Contacts,
            Ratings
        },
        data() {
            return {
                key : 0
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update-company').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            properties(){
                return this.response.body.properties;
            }
        },
        methods: {
            update(){
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
