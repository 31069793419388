<template>
    <paginated-list :response="response" :useEntities="true" :hasEntities="contacts.count() > 0">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey sm:pl-6">Name</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Email</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Contact Number</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Contact Type</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Supplier Position</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Actions</th>
        </template>
        <template v-slot:noItems>
            No Contacts found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in contacts" class="hover:bg-secondary-lightbacka">
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('name') }}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{entity.entity.properties.get('email')}}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('contact_number') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('contact_type') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('supplier_position') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <form-action v-if="entity.entity.actions.filter(action => action.name==='update-supplier').first()"
                                 :action="entity.entity.actions.filter(action => action.name==='update-supplier').first()"
                                 title="Update"
                                 :onSuccessPath="$route.fullPath"
                    ></form-action>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import FormAction from "@/v2/components/FormAction";
export default {
    name: "SupplierContactPaginatedList",
    components:{
        FormAction,
        PaginatedList,
        StatusBadge,
        OrderTabs
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        contacts() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('contact'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
