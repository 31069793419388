<template>
    <tr class="bg-white invoice-line-item-row">
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            <property-or-autocomplete-field ref="description" @input="change" @suggestionSelected="updateCode"
                                            :properties="invoiceItemEntity.entity.properties" :scope="scope"
                                            :searchAction="searchAction" :action="updateAction" fieldId="description"
                                            propertyKey="description"
                                            v-model="updateActionData['description']"></property-or-autocomplete-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top text-right">
            <property-or-autocomplete-field ref="code" @input="change" @suggestionSelected="updateDescription"
                                            :properties="invoiceItemEntity.entity.properties" :scope="scope"
                                            :searchAction="searchAction" :action="updateAction" fieldId="unit_price"
                                            propertyKey="unit_price"
                                            v-model="updateActionData['unit_price']"></property-or-autocomplete-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top text-right">
            20%
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <span v-if="removeAction" class="cursor-pointer font-normal underline text-gray-400" data-cy="remove-item" @click="removeItem()">remove</span>
        </td>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from "../mixins/orderItemsUpdate";

    export default {
        inject: ["$validator"],
        mixins: [orderItemsUpdate],
        data(){
            return {
                loading: false
            }
        },
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            invoiceItemEntity: {
                type: Object,
            },
            scope: {
                type: String
            },
            response: {
                type: Object
            }
        },
        computed: {
            updateAction() {
                return this.invoiceItemEntity.entity.actions.filter(action => action.name === 'update').first();
            },
            removeAction() {
                return this.invoiceItemEntity.entity.actions.filter(action => action.name === 'delete').first();
            },
            updateActionData() {
                if (!this.updateAction) {
                    return {};
                }
                if (!this.updateAction.fields) {
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            update() {
                return this.updateAction.perform(this.updateActionData);
            },
            removeItem() {
                if(this.loading === false) {
                    this.loading = true;
                    return this.removeAction.perform().then(res => {
                        this.$store.dispatch(
                            "setMessage",
                            'Item removed successfully.'
                        );
                        this.loading = false;
                        this.$router.go(0);
                    }).catch(error => {
                        alert('There was an error processing your request.' + error);
                    });
                }
            },
            change() {
                this.$emit('change');
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
