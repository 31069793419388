<template>
    <div v-if="action || properties && properties.get(propertyKey)">
        <div class="flex flex-col questions-input">
            <h3 v-if="title" class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">{{title}}</h3>
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Payment Type
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Value
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Multiple
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Default Quantity
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="action" class="bg-white" v-for="(paymentType,key) in paymentTypes"><!-- bg-gray-50 -->
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <input type="text" v-model="paymentType.payment_type" class="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" @input="change">
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <gbp-input :value="paymentType.value" :field="valueField" v-model="paymentType.value" @input="change"></gbp-input>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <radio-input :value="paymentType.multiple" :field="multipleField" v-model="paymentType.multiple" @input="change"></radio-input>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <number-input v-if="paymentType.multiple" :value="paymentType.default_quantity" :field="defaultQuantityField" v-model="paymentType.default_quantity" @input="change"></number-input>
                                    <template v-else>{{paymentType.default_quantity}}</template>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">
                                    <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem(key);">remove</span>
                                </td>
                            </tr>

                            <tr v-if="!action" class="bg-white" v-for="paymentType in paymentTypes"><!-- bg-gray-50 -->
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{paymentType.payment_type}}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{paymentType.value}}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{paymentType.multiple}}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{paymentType.default_quantity}}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">

                                </td>
                            </tr>
                            <tr v-if="action" class="bg-white text-center">
                                <td colspan="100">
                                    <div class="grid grid-cols-3 p-3">
                                        <a-button @click.native="addNewItem()" data-cy="add-item"
                                        class="col-start-2 inline-flex justify-center">
                                        + New Payment Type</a-button>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-show="errors.has('payment_types')" class="text-red-600">{{ errors.first('payment_types') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertyOrField from "@/components/PropertyOrField";
import Button from "@/v2/buttons/Button";
import GbpInput from "@/components/Action/Fields/GbpInput";

export default {
    inject: ["$validator"],
    components: {
        PropertyOrField,
        'a-button': Button
    },
    data() {
        return {
            paymentTypes: []
        }
    },
    props: {
        action: {
            type: Object,
        },
        propertyKey: {
            type: String
        },
        properties: {
            type: Object
        },
        title: {
            type: String,
            default: null
        }
    },
    methods: {
        addNewItem() {
            this.paymentTypes.push({payment_type:null, value:null, multiple:0, default_quantity:1});
            this.$emit('change');
        },
        removeItem(key) {
            this.$set(this.paymentTypes, this.paymentTypes.splice(key, 1));
            this.$emit('change');
        },
        change() {
            this.$emit("change");
        },
    },
    created() {
        if(this.properties) {
            this.paymentTypes = this.properties.get('payment_types');
        }
    },
    computed:{
        valueField() {
            return {'title':'value', 'validation':null}
        },
        multipleField() {
            return {'title':'Multiple', 'validation':null, 'options': [{'title':'Yes','value':1},{'title':'No','value':0, 'selected':1}], 'value': 0}
        },
        defaultQuantityField() {
            return {'title':'Default Quantity', 'validation':null}
        },
    },
    watch: {
        paymentTypes: {
            handler(newValue, oldValue) {
                this.$emit("input", JSON.stringify(newValue));
            },
            deep: true
        }
    },
}
</script>

<style>
.questions-input label{
    display:none
}
</style>
