<template>
    <div class="bg-white overflow-hidden shadow rounded-lg h-full" >
        <div class="px-4 py-5 sm:p-6 h-full">
            <div class="flex items-center h-full">
                <div class="flex-shrink-0 bg-green-400 rounded-md p-3">
                    <svg class="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                    </svg>
                </div>
                <div class="ml-5 w-0 flex-1 h-full">
                    <div class="flex justify-between mb-8 items-center">
                        <div class="w-full md:mb-0">
                            <div class="relative">
                                <select name="projects" @change="changeSelectedProject($event)" v-model="key" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                    <option :value="null">--Select Project--</option>
                                    <option v-for="project in projects" :value="project.value">{{ project.title }}</option>
                                </select>
                                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-between items-center">
                            <div>
                                <a v-if="selectedProject !== defaultFilter" class="text-gray-400 text-sm cursor-pointer" @click="saveDefaultFilter()">Save as default filter</a>
                            </div>
                        </div>
                    </div>
                    <dl class="h-full pb-20" v-if="userCheckins">
                        <dt class="text-sm leading-5 font-medium text-gray-500 truncate">Currently Clocked In ({{logins.length}})</dt>
                        <dd class="flex items-baseline h-full overflow-auto">
                            <ul class="text-sm text-gray-800">
                                <template v-for="userCheckin in logins">

                                    <li @click="showUserDetails(userCheckin)" class="cursor-pointer hover:underline">{{userCheckin.user_name}} <span  class="text-xs text-gray-400">(Clocked in at {{getTimeClockedIn(userCheckin.clock_in['time'])}}
                                        <span v-if="userCheckin.clock_out && userCheckin.clock_out['time']">and out at {{ getTimeClockedIn(userCheckin.clock_out['time']) }}</span>
                                        )</span></li>
                                </template>
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>

        <large-model class="sm:flex sm:items-center sm:justify-center" v-show="showDetails" v-if="detailsToShow" @removeFromDisplay="closeDetails"  :displayConfirmButton="false">
            <p class="text-xl mx-auto px-4 sm:px-6 md:px-8">{{ detailsToShow['user_name'] }}</p>
            <p class="mx-auto px-4 sm:px-6 md:px-8 mt-2" v-if="detailsToShow.clock_in"> Clocked in at {{getTimeClockedIn(detailsToShow.clock_in['time'])}}
            <template v-if="detailsToShow.clock_in.location">
                <span class="px-4" v-if="detailsToShow.clock_in.location['x'] !== 'N/A'">/</span>
                <location-viewer-with-icon v-if="detailsToShow.clock_in.location['x'] !== 'N/A'" :x="detailsToShow.clock_in.location['x']" :y="detailsToShow.clock_in.location['y']" :usePortal="true"></location-viewer-with-icon>
            </template>
            <template v-if="detailsToShow.clock_in.photo_url">
                <span class="px-4" v-if="detailsToShow.clock_in['photo_url'] !== 'N/A'">/</span>
                <photo-viewer-with-icon class="inline-flex" v-if="detailsToShow.clock_in['photo_url'] !== 'N/A'" :file-name="detailsToShow.clock_in['photo_url']" :usePortal="true"></photo-viewer-with-icon>
            </template>
            </p>

            <p class="mx-auto px-4 sm:px-6 md:px-8 mt-2" v-if="detailsToShow.clock_out"> Clocked out at {{getTimeClockedIn(detailsToShow.clock_out['time'])}}
                <template v-if="detailsToShow.clock_out.location">
                    <span class="px-4" v-if="detailsToShow.clock_out.location['x'] !== 'N/A'">/</span>
                    <location-viewer-with-icon v-if="detailsToShow.clock_out.location['x'] !== 'N/A'" :x="detailsToShow.clock_out.location['x']" :y="detailsToShow.clock_out.location['y']" :usePortal="true"></location-viewer-with-icon>
                </template>
                <template v-if="detailsToShow.clock_out.photo_url">
                    <span class="px-4" v-if="detailsToShow.clock_out['photo_url']">/</span>
                    <photo-viewer-with-icon class="inline-flex" v-if="detailsToShow.clock_out['photo_url'] !== 'N/A'" :file-name="detailsToShow.clock_out['photo_url']" :usePortal="true"></photo-viewer-with-icon>
                </template>
            </p>




        </large-model>
    </div>
</template>

<script>
    import Siren from "super-siren";
    import {mapGetters} from "vuex";
    import moment from "moment";
    import ActionWithForm from "./../ActionWithForm";
    import PrimaryButton from "../PrimaryButton";
    import SecondaryButton from "../SecondaryButton";
    import dayjs from "dayjs";
    import LargeModel from "@/components/LargeModel";
    import LocationViewerWithIcon from "@/components/LocationViewerWithIcon";
    import PhotoViewerWithIcon from "@/components/PhotoViewerWithIcon";

    export default {
        name: "DashboardClockedIn",
        components: {
            PhotoViewerWithIcon,
            LocationViewerWithIcon, LargeModel, SecondaryButton, PrimaryButton, ActionWithForm},
        data() {
            return {
                key: null,
                userCheckins: null,
                projects: [],
                selectedProject: null,
                ossDefaultAction: null,
                defaultFilter:null,
                showDetails:false,
                detailsToShow:null
            }
        },
        created() {
            let follow = Siren.Helper.follow;

            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

            Siren.get(process.env.VUE_APP_API_URL + "/api").then(
                follow('current-user')
            ).then(res => {
                this.ossDefaultAction = res.body.actions.filter(action => action.name === 'save-as-default-project-oss-filter').first();
                this.projects = res.body.actions.filter(action => action.name === 'save-as-default-project-oss-filter').first().fields.first(field => field.name === 'identifier').options
                if (res.body.properties.get('identifier') != null) {
                    this.defaultFilter = res.body.properties.get('identifier');
                    this.key = this.selectedProject = res.body.properties.get('identifier');
                    this.filterClockedInUsersByProject(this.selectedProject)
                }

                var client = res.body.embeddedEntities.filter(entity => entity.entity.classes.contains('client')).first();

                if (client !== undefined) {
                    let clientId = client.entity.properties.get('id');
                    if (clientId) {
                        let currentDate = dayjs(new Date()).format('YYYY-MM-DD');
                        Siren.get(process.env.VUE_APP_API_URL + "/api/on-site-selfie/daily-reports/" + clientId + '?date=' + currentDate).then(response => {
                            this.userCheckins = response.body

                        }, this);
                    }
                }

            }, this);


        },
        methods: {
            getTimeClockedIn(date) {
                var dateObj = new Date(date)
                return moment(dateObj).format('h:mma')
            },
            changeSelectedProject(event) {
                this.filterClockedInUsersByProject(event.target.value)
            },
            filterClockedInUsersByProject(project) {
                if(project === ''){
                    project = null;
                }
                this.key = this.selectedProject = project
            },
            saveDefaultFilter() {
                this.ossDefaultAction.perform({
                    identifier: this.selectedProject
                }).then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'Default filter saved.'
                    );
                    this.defaultFilter = this.selectedProject;
                })
            },
            clearSavedFilter() {
                this.key = null
                this.selectedProject = null

                this.saveDefaultFilter()
            },
            showUserDetails(details){
                this.showDetails = true;
                this.detailsToShow = details;
            },
            closeDetails(){
                this.showDetails = false;
                this.detailsToShow = null;
            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            logins() {
                let users = this.userCheckins;
                if(this.userCheckins !== null && this.selectedProject !== null) {
                    return this.userCheckins.filter(clockin => clockin.project_ids.filter(project => project == this.selectedProject).length > 0);
                }

                return users
            },
        }
    }
</script>

<style scoped>

</style>
