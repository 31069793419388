<template>
    <div class="py-6 px-3 filters">
        <div class="flex">
            <form @submit.prevent="performAction(action,actionData)" class="w-full flex">
                <div class="gap-2" :class="{'grid grid-cols-6': this.filters.count() >= 6, 'grid grid-cols-5' : this.filters.count() === 5, 'grid grid-cols-4' : this.filters.count() === 4 , 'grid grid-cols-3' : this.filters.count() === 3, 'grid grid-cols-2' : this.filters.count() === 2, 'grid grid-cols-1' : this.filters.count() === 1}">
                <div v-if="getField('filter[client_id]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Client" :action="action"
                                           fieldId="filter[client_id]" propertyKey="client_id"
                                           v-model="actionData['filter[client_id]']"></property-or-field>
                    </div>
                </div>
                <div v-if="getField('filter[order_reference]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Order Reference" :action="action"
                                           fieldId="filter[order_reference]" propertyKey="order_reference"
                                           v-model="actionData['filter[order_reference]']"></property-or-field>
                    </div>
                </div>
                <div v-if="getField('filter[query]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Query" :action="action"
                                           fieldId="filter[query]" propertyKey="query"
                                           v-model="actionData['filter[query]']"></property-or-field>

                    </div>
                </div>
                <div v-if="getField('filter[status]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Status" :action="action"
                                           fieldId="filter[status]" propertyKey="status"
                                           v-model="actionData['filter[status]']"></property-or-field>
                    </div>
                </div>
                <div v-if="getField('filter[project_id]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Project" :action="action"
                                           fieldId="filter[project_id]" propertyKey="project_id"
                                           v-model="actionData['filter[project_id]']"></property-or-field>
                    </div>
                </div>
                <div v-if="getField('filter[site_id]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Contract" :action="action"
                                           fieldId="filter[site_id]" propertyKey="site_id"
                                           v-model="actionData['filter[site_id]']"></property-or-field>
                    </div>
                </div>
                <div v-if="getField('filter[site_manager_ids]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Site Manager" :action="action"
                                           fieldId="filter[site_manager_ids]" propertyKey="site_manager_ids"
                                           v-model="actionData['filter[site_manager_ids]']"></property-or-field>

                    </div>
                </div>
                  <div v-if="getField('filter[company_ids]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Company" :action="action"
                                           fieldId="filter[company_ids]" propertyKey="company_ids"
                                           v-model="actionData['filter[company_ids]']"></property-or-field>

                    </div>
                  </div>
                    <div v-if="getField('filter[role_ids]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="Roles" :action="action"
                                               fieldId="filter[role_ids]" propertyKey="role_ids"
                                               v-model="actionData['filter[role_ids]']"></property-or-field>

                        </div>
                    </div>
                  <div v-if="getField('filter[contact_type]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Contact Type" :action="action"
                                           fieldId="filter[contact_type]" propertyKey="contact_type"
                                           v-model="actionData['filter[contact_type]']"></property-or-field>
                    </div>
                  </div>
                  <div v-if="getField('filter[is_approved]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Is Approved" :action="action"
                                           fieldId="filter[is_approved]" propertyKey="is_approved"
                                           v-model="actionData['filter[is_approved]']"></property-or-field>
                    </div>
                  </div>
                  <div v-if="getField('filter[uploaded_to_sage]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Uploaded To Sage" :action="action"
                                           fieldId="filter[uploaded_to_sage]" propertyKey="uploaded_to_sage"
                                           v-model="actionData['filter[uploaded_to_sage]']"></property-or-field>
                    </div>
                  </div>
                  <div v-if="getField('filter[item]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Item" :action="action"
                                           fieldId="filter[item]" propertyKey="item"
                                           v-model="actionData['filter[item]']"></property-or-field>
                    </div>
                  </div>
                  <div v-if="getField('filter[manufacturer_id]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Manufacturer" :action="action"
                                           fieldId="filter[manufacturer_id]" propertyKey="manufacturer_id"
                                           v-model="actionData['filter[manufacturer_id]']"></property-or-field>
                    </div>
                  </div>
                  <div v-if="getField('filter[date_from]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Date From" :action="action"
                                           fieldId="filter[date_from]" propertyKey="date_from"
                                           v-model="actionData['filter[date_from]']"></property-or-field>
                    </div>
                  </div>
                  <div v-if="getField('filter[date_to]')" class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <property-or-field title="Date To" :action="action"
                                           fieldId="filter[date_to]" propertyKey="date_to"
                                           v-model="actionData['filter[date_to]']"></property-or-field>
                    </div>
                  </div>
                    <div v-if="getField('filter[added_by]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="Added By" :action="action"
                                               fieldId="filter[added_by]" propertyKey="added_by"
                                               v-model="actionData['filter[added_by]']"></property-or-field>
                        </div>
                    </div>
                    <div v-if="getField('filter[operative]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="User" :action="action"
                                               fieldId="filter[operative]" propertyKey="operative"
                                               v-model="actionData['filter[operative]']"></property-or-field>
                        </div>
                    </div>
                    <div v-if="getField('filter[subcontractor_id]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="User" :action="action"
                                               fieldId="filter[subcontractor_id]" propertyKey="subcontractor_id"
                                               v-model="actionData['filter[subcontractor_id]']"></property-or-field>
                        </div>
                    </div>
                    <div v-if="getField('filter[contract_id]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="Contract" :action="action"
                                               fieldId="filter[contract_id]" propertyKey="contract_id"
                                               v-model="actionData['filter[contract_id]']"></property-or-field>
                        </div>
                    </div>
                    <div v-if="getField('filter[category]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="Categories" :action="action"
                                               fieldId="filter[category]" propertyKey="category"
                                               v-model="actionData['filter[category]']"></property-or-field>
                        </div>
                    </div>
                    <div v-if="getField('filter[from_date]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="From Date" :action="action"
                                               fieldId="filter[from_date]" propertyKey="from_date"
                                               v-model="actionData['filter[from_date]']"></property-or-field>
                        </div>
                    </div>
                    <div v-if="getField('filter[to_date]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="To Date" :action="action"
                                               fieldId="filter[to_date]" propertyKey="to_date"
                                               v-model="actionData['filter[to_date]']"></property-or-field>
                        </div>
                    </div>
                    <div v-if="getField('filter[order_value]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="Value" :action="action"
                                               fieldId="filter[order_value]" propertyKey="order_value"
                                               v-model="actionData['filter[order_value]']"></property-or-field>
                        </div>
                    </div>
                    <div v-if="getField('filter[flag]')" class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <property-or-field title="flag first" :action="action"
                                               fieldId="filter[flag]" propertyKey="flag"
                                               v-model="actionData['filter[flag]']"></property-or-field>
                        </div>
                    </div>
                </div>
            <div class="flex-grow px-2">
                <button type="submit" class="place-content-center align-center inline-flex items-center border px-4 py-2 border-secondary-lightblue shadow-sm text-sm font-medium rounded-md text-secondary-lightblue bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-lightblue w-full">
                    Filter
                    <loading v-if="loading"></loading>
                </button>
            </div>

            </form>

        </div>
        <div class="text-left flex">
            <span v-for="(removeFilterLink,index) in removeFilterLinks" :key="index"
                  class="inline-flex px-2.5 py-0.5 text-xs font-medium leading-4 bg-indigo-100 text-indigo-800">
                {{getRemovalLinkTitle(removeFilterLink.title)}}
                <button @click="navigateTo(removeFilterLink.href)" type="button"
                        class="flex-shrink-0 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700">
                    <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
                    </svg>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import SelectInput from "@/v2/components/SelectInput";
import PropertyOrField from "@/components/PropertyOrField";
export default {
    name: "OrderFilters",
    components: {PropertyOrField, SelectInput},
    data() {
        return {
            actionData: this.defaultValues(),
            loading: false
        }
    },
    props: {
        response: {
            type: Object,
        },
        action: {
            type: Object
        },
        actionBaseUrl:{
            type: String,
            default: '/orders'
        }
    },
    computed: {
        filterAction(){
            return this.action;
        },
        filters(){
            return this.filterAction.fields;
        },
        removeFilterLinks() {
            if (!this.response) {
                return null;
            }

            return this.response.body.links.filter(link => link.rels.toString().includes('remove_filter'));
        }
    },
    methods:{
        getField(fieldName){
            return this.filters.filter(field => field.contains(fieldName)).first();
        },
        performAction(action, actionData) {
            let parts = action.href.split('/api');
            this.$router.push({path: this.actionBaseUrl, query: actionData}).then(failure => {
                if(failure.fullPath !== window.location.href){
                     window.location.href = failure.fullPath;
                };
            }).catch();
        },
        defaultValues() {
            let defaults = {};
            if (!this.action) {
                return {};
            }
            this.action.fields.forEach(function (field) {
                if (Array.isArray(field.value)) {
                    let filter = field.value.filter(o => o.selected === true);
                    if (filter[0]) {
                        defaults[field.name] = filter.map(o => o['value']).join(',');
                    } else {
                        defaults[field.name] = null;
                    }
                } else {
                    defaults[field.name] = field.value;
                }
            });
            return defaults;
        },
        getRemovalLinkTitle(originalTitle) {
            return String(originalTitle).replace('- Remove Filter', '');
        },
        navigateTo(link) {
            window.location = this.getFrontendRouteFromApiRoute(link);
        },
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
    }
}
</script>

<style>
.filters label{
    display:none
}
</style>
