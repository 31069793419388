import Siren from "super-siren";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            hasOutstandingOnboardingSteps: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
    },
    mounted() {
        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);


        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(response => {
                    this.hasOutstandingOnboardingSteps = response.body.links.filter(link => link.rels.contains('onboarding-steps')).size > 0;
                    console.log(this.hasOutstandingOnboardingSteps);
                });
        });
    }
}
