<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                Dashboard
                                <div class="flex items-center -mt-3 text-secondary-darkgrey">
                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter' && !action.fields.isEmpty())">
                                        <form-action class="action-with-fields" :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                        ></form-action>
                                    </div>
                                </div>
                            </div>
                        </page-header>
                        <div class="grid grid-cols-4 min-h-screen">
                            <div
                                class="col-span-4 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">

                                <dashboard-summary ref="summary"  :response="response" @change="change"></dashboard-summary>
                            </div>
                        </div>
                    </template>
                    <div v-if="hasChangesWaitingOnSave"
                         style="right:0px;left:16rem;"
                         class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                        <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                            Dashboard
                        </primary-button>
                    </div>
                </div>

            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import DashboardSummary from '../../components/Summaries/DashboardSummary';
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import PrimaryButton from "../../components/PrimaryButton";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import EditDashboardHeader from "../../components/Headers/EditDashboardHeader";
    import PageHeader from "@/v2/components/Header";

    export default {
        inject: ["$validator"],
        components: {
            MainTemplate,
            FetchRootSiren,
            Loading,
            PrimaryButton,
            EditDashboardHeader,
            DashboardSummary,
            PageHeader
        },
        data() {
            return {
                hasChangesWaitingOnSave: false,
                loading:false
            }
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },
        },
        methods: {
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            async saveData() {
                this.loading = true;
                this.$refs.summary.update().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'The dashboard was saved successfully'
                    );
                    this.hasChangesWaitingOnSave = false;
                    this.loading = false;
                    this.$router.go(0);
                }).catch(error => {
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
        },
    }
</script>

<style scoped>

</style>
