<template>
    <paginated-list :response="response" :useEntities="true" :hasEntities="invoices.count() > 0">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Reference</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Invoice PDF</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Short Description</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">TAX Code</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Gross Value</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Net Value</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">VAT Value</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Uploaded To Sage</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Is Proforma Paid</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Linked Sales Invoice Status</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Action</th>
        </template>
        <template v-slot:noItems>
            No invoices found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in invoices" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('reference') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <download-pdf-icon-with-link :url="entity.entity.properties.get('file')"></download-pdf-icon-with-link>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('short_description') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('tax_code') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('gross_value') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('net_value') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ entity.entity.properties.get('vat_value') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <template v-if="entity.entity.properties.get('isUploaded')">
                        <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    </template>
                    <template v-else>
                        <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <template v-if="entity.entity.properties.get('isProformaPaid')">
                        <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    </template>
                    <template v-else-if="entity.entity.properties.get('isProformaPaid') === false">
                        <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </template>
                    <template v-else>
                        N/A
                    </template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <a v-if="entity.entity.properties.get('linked_sales_invoice') && entity.entity.properties.get('linked_sales_invoice')['uuid']" class="underline hover:no-underline" :href="`/invoices/${entity.entity.properties.get('linked_sales_invoice')['uuid']}`">{{entity.entity.properties.get('linked_sales_invoice')['reference']}} - {{entity.entity.properties.get('linked_sales_invoice')['status']}}</a>
                    <span v-else>No linked sales invoice</span>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <reconcile-order-form class="mb-2" v-if="entity.entity.actions.get('update-invoice')"
                                          :action="entity.entity.actions.get('update-invoice')"
                                          actionKey="update-invoice"
                                          :onSuccessPath="$router.currentRoute.fullPath">
                    </reconcile-order-form>
                    <create-subscription-invoice-form class="mb-2"  v-if="entity.entity.actions.get('update-subscription-invoice')"
                                                      :action="entity.entity.actions.get('update-subscription-invoice')"
                                                      actionKey="update-subscription-invoice"
                                                      :onSuccessPath="$router.currentRoute.fullPath"
                    >
                    </create-subscription-invoice-form>
                    <confirmation-action class="mb-2" v-if="entity.entity.actions.get('mark-invoice-as-uploaded-to-sage')"
                                              :action="entity.entity.actions.get('mark-invoice-as-uploaded-to-sage')"
                                              actionKey="mark-invoice-as-uploaded-to-sage"
                                              :onSuccessPath="$router.currentRoute.fullPath">
                    </confirmation-action>
                    <reassign-invoice-form class="mb-2" v-if="entity.entity.actions.get('reassign-invoice')"
                                           :action="entity.entity.actions.get('reassign-invoice')"
                                           actionKey="reassign-invoice"
                                           :onSuccessPath="$router.currentRoute.fullPath"
                                           :isUploaded="entity.entity.properties.get('isUploaded')">
                    </reassign-invoice-form>
                    <delete-invoice-in-confirmation v-if="entity.entity.actions.get('delete-invoice')"
                                                    :action="entity.entity.actions.get('delete-invoice')"
                                                    actionKey="delete-invoice"
                                                    :onSuccessPath="$router.currentRoute.fullPath"
                                                    :isButton="false">
                    </delete-invoice-in-confirmation>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import ReconcileOrderForm from "@/v2/components/forms/ReconcileOrderForm";
import DeleteInvoiceInConfirmation from "@/v2/components/forms/DeleteInvoiceInConfirmation";
import ReassignInvoiceForm from "@/v2/components/forms/ReassignInvoiceForm";
import CreateSubscriptionInvoiceForm from "@/v2/components/forms/CreateSubscriptionInvoiceForm.vue";
export default {
    name: "OrderInvoicePaginatedList",
    components:{
      CreateSubscriptionInvoiceForm,
        ConfirmationAction,
        PaginatedList,
        StatusBadge,
        OrderTabs,
        DownloadPdfIconWithLink,
        ReconcileOrderForm,
        DeleteInvoiceInConfirmation,
        ReassignInvoiceForm
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        invoices() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('invoice'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        getParentLink(properties) {
            if(properties.get("entity_type") === "rental_item_invoice_in"){
                return '/rental-items/' + properties.get("parent_uuid");
            }
            if(properties.get("entity_type") === "subscription"){
                return '/subscriptions/' + properties.get("parent_uuid");
            }
            return '/orders/' + properties.get("parent_uuid");
        }
    }
}
</script>

<style scoped>

</style>
