<template>
    <div>
        <a-button class="mr-3" :class="additionalButtonClasses" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{action.title}}
        </a-button>
        <portal to="destination">
            <large-modal v-if="displayModal" :submitting="submittingModal" :title="action.title"
                         :confirmationString='`Are you sure you want to \"${action.title}\"`'
                         @removeFromDisplay="displayModal = false" @perform="perform()">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Set delivered quantity
                </h3>
                <div class="mb-4">
                    <p class="text-sm leading-5 text-gray-500">
                        Please update the delivered quantities.
                    </p>
                </div>
                <div class="flex flex-col">
                    <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border border-gray-200">
                            <table class="min-w-full goods-in-order-items">
                                <thead>
                                <tr>
                                    <th
                                        class="px-2 py-1 sm:px-6 sm:py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        Description
                                    </th>
                                    <th
                                        class="hidden sm:table-cell px-2 py-1sm:px-6 sm:py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        Code
                                    </th>
                                    <th
                                        class="px-2 py-1sm:px-6 sm:py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        Qty Ordered
                                    </th>
                                    <th
                                        class="px-2 py-1sm:px-6 sm:py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        Qty previously delivered
                                    </th>
                                    <th
                                        class="px-2 py-1sm:px-6 sm:py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        Qty to record
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(orderItemEntity, key) in orderItemEntities">
                                    <td class="px-2 py-1sm:px-6 sm:py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                        {{orderItemEntity.entity.properties.get('description')}}
                                    </td>
                                    <td class="hidden sm:table-cell px-2 py-1 sm:px-6 sm:py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                        {{orderItemEntity.entity.properties.get('code')}}
                                    </td>
                                    <td class="px-2 py-1 sm:px-6 sm:py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                        {{orderItemEntity.entity.properties.get('quantity')}}
                                    </td>
                                    <td class="px-2 py-1 sm:px-6 sm:py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                        {{orderItemEntity.entity.properties.get('delivered_quantity')}}
                                    </td>
                                    <td class="px-2 py-1 sm:px-6 sm:py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                        <component
                                            :is="getFieldComponent(getItemFieldByOrderItemEntity(orderItemEntity).type)"
                                            :field="getItemFieldByOrderItemEntity(orderItemEntity)"
                                            v-model="formData[getItemFieldByOrderItemEntity(orderItemEntity).name]">
                                        </component>
                                        <div v-show="errors.has(getItemFieldByOrderItemEntity(orderItemEntity).name)"
                                             class="text-red-600">{{
                                            errors.first(getItemFieldByOrderItemEntity(orderItemEntity).name) }}
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="mt-3" v-if="getField('delivery_notes')">
                        <component :is="getFieldComponent(getField('delivery_notes').type)" :field="getField('delivery_notes')" v-model="formData[getField('delivery_notes').name]">
                        </component>
                        <div v-show="errors.has(getField('delivery_notes').name)" class="text-red-600">{{
                            errors.first(getField('delivery_notes').name) }}
                        </div>
                    </div>
                    <div class="mt-3" v-if="getField('docket')">
                        <component :is="getFieldComponent(getField('docket').type)" :field="getField('docket')" v-model="formData[getField('docket').name]">
                        </component>
                        <div v-show="errors.has(getField('docket').name)" class="text-red-600">{{
                                errors.first(getField('docket').name) }}
                        </div>
                    </div>
                    <div v-show="errors.has('order')" class="text-red-600">{{
                            errors.first('order') }}
                    </div>
                </div>

            </large-modal>
        </portal>
    </div>
</template>

<script>
    import Button from './Button';
    import Modal from './Modal';
    import LargeModal from './LargeModel';

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false
            }
        },
        components: {
            'a-button': Button,
            LargeModal
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            response: {
                type: Object
            },
            additionalButtonClasses:{
                type:String
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            orderItemEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('order_item'));
            },
        },
        methods: {
            getItemFieldByOrderItemEntity(orderItemEntity) {
                return this.action.fields.get('items[' + orderItemEntity.entity.properties.get('id') + ']');
            },
            getField(key) {
                return this.action.fields.get(key);
            },
            getFieldComponent(type) {
                return type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.$router.go(0);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.response.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                    // this.displayModal = false;
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },
    }
</script>

<style lang="scss">
    .goods-in-order-items label {
        display: none;
    }
</style>
