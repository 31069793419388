<template>
    <div class="flex flex-col items-center">
        <div class="flex flex-col items-center px-2" @click="displayModal = true">
            <slot></slot>
        </div>
        <generic-modal v-show="displayModal" @removeFromDisplay="displayModal = false" >
            <template v-slot:header>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Record delivery of order
                </h3>
                <div class="mt-2">
                    <div class="text-sm leading-5 text-gray-500">
                        <!-- Order -->
                        <component @input="changeOrder" :is="getFieldComponent(action.fields.get('filter[order_id]'))" :field="action.fields.get('filter[order_id]')" v-model="formData[action.fields.get('filter[order_id]').name]">
                        </component>
                        <div v-show="errors.has(action.fields.get('filter[order_id]').name)" class="text-red-600">{{ errors.first(action.fields.get('filter[order_id]').name) }}</div>

                        <template v-if="markAsFullyDeliveredAction || addGoodsInNoteAction">
                            <goods-in-note-action-with-form additionalButtonClasses="w-full justify-center mt-2 mr-0" v-if="markAsFullyDeliveredAction"  :action="markAsFullyDeliveredAction" :response="orderResponse"></goods-in-note-action-with-form>
                            <goods-in-note-action-with-form additionalButtonClasses="w-full justify-center mt-2 mr-0" v-if="addGoodsInNoteAction"  :action="addGoodsInNoteAction" :response="orderResponse"></goods-in-note-action-with-form>
                        </template>
                        <template v-else-if="orderActions !== null">
                            No actions avaliable for this order.
                        </template>
                    </div>
                </div>
            </template>

            <template v-slot:footer="slotProps">
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button @click="slotProps.removeFromDisplay()" data-cy="cancel-modal" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Cancel
                    </button>
                </span>
            </template>
        </generic-modal>
    </div>
</template>

<script>
    import Modal from "./Modal";
    import GenericModal from "./GenericModal";
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import ActionWithConfirmation from "./ActionWithConfirmation";
    import GoodsInNoteActionWithForm from "./GoodsInNoteActionWithForm";
    import ActionWithForm from "@/components/ActionWithForm";

    export default {
        components: {Modal, GenericModal,ActionWithConfirmation,GoodsInNoteActionWithForm, ActionWithForm},
        data() {
            return {
                orderActions: null,
                orderResponse: null
            }
        },
        props: {
            action: {
                type: Object,
            },
            displayModal: {
                type: Boolean,
                default:false
            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            markAsFullyDeliveredAction(){
                if(!this.orderActions){
                    return null;
                }
                return this.orderActions.filter(action => action.name === 'mark-as-fully-delivered').first();
            },
            addGoodsInNoteAction(){
                if(!this.orderActions){
                    return null;
                }
                return this.orderActions.filter(action => action.name === 'add-goods-in-note').first();
            }
        },
        methods: {
            changeOrder(){
                this.action.perform(this.formData)
                    .then(res => {
                        let firstOrderSelfLink = res.body.entities.first().entity.links.filter(link => link.rels.contains('self')).first();
                        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                        Siren.get(firstOrderSelfLink.href).then(res => {
                            this.orderActions = res.body.actions;
                            this.orderResponse = res;
                        });
                    });
            },
            getFieldComponent(field) {
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
        },
    }
</script>

<style scoped>

</style>
