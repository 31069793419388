<template>
    <div class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-3 pt-6">
        <div class="border md:rounded-lg mb-8 bg-white grid grid-cols-5 col-span-3 section-links-2">
            <div class="p-6" style="min-height: 200px;"  v-if="hasLink('admin.clients')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('admin.clients')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320 32C346.5 32 368 53.49 368 80V144C368 170.5 346.5 192 320 192H312V232H464C494.9 232 520 257.1 520 288V320H528C554.5 320 576 341.5 576 368V432C576 458.5 554.5 480 528 480H464C437.5 480 416 458.5 416 432V368C416 341.5 437.5 320 464 320H472V288C472 283.6 468.4 280 464 280H312V320H320C346.5 320 368 341.5 368 368V432C368 458.5 346.5 480 320 480H256C229.5 480 208 458.5 208 432V368C208 341.5 229.5 320 256 320H264V280H112C107.6 280 104 283.6 104 288V320H112C138.5 320 160 341.5 160 368V432C160 458.5 138.5 480 112 480H48C21.49 480 0 458.5 0 432V368C0 341.5 21.49 320 48 320H56V288C56 257.1 81.07 232 112 232H264V192H256C229.5 192 208 170.5 208 144V80C208 53.49 229.5 32 256 32H320zM320 80H256V144H320V80zM48 432H112V368H48V432zM256 432H320V368H256V432zM464 432H528V368H464V432z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue ">Manage Clients</p>
                        <p>View & Edit Clients & Client User, Projects, Contracts etc.</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;"  v-if="hasLink('admin.client')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('admin.client')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM384 80H64C55.16 80 48 87.16 48 96V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V96C400 87.16 392.8 80 384 80z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manage Organisation</p>
                        <p>Manage your organisation & it's users, projects, contracts etc.</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('admin.users')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('admin.users')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM319.9 160c30.85 0 55.96 25.12 55.96 56S350.7 272 319.9 272S263.9 246.9 263.9 216S289 160 319.9 160zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM178.1 464c10.47-36.76 47.36-64 91.14-64H369.9c43.77 0 80.66 27.24 91.14 64H178.1zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manage Users</p>
                        <p>View & manage all users</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('admin.default-client-users')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('admin.default-client-users')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 48c44.11 0 80 35.89 80 80c0 44.11-35.89 80-80 80C179.9 208 144 172.1 144 128C144 83.89 179.9 48 224 48zM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM48.71 464C55.38 401.1 108.7 352 173.3 352H274.7c64.61 0 117.1 49.13 124.6 112H48.71zM616 200h-48v-48C568 138.8 557.3 128 544 128s-24 10.75-24 24v48h-48C458.8 200 448 210.8 448 224s10.75 24 24 24h48v48C520 309.3 530.8 320 544 320s24-10.75 24-24v-48h48C629.3 248 640 237.3 640 224S629.3 200 616 200z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manage New Users</p>
                        <p>View all users who have signed up via the app signup form.</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('admin.direct-suppliers')" >
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('admin.direct-suppliers')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg  class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320.1 175C330.3 184.4 330.3 199.6 320.1 208.1L240.1 288.1C231.6 298.3 216.4 298.3 207 288.1C197.7 279.6 197.7 264.4 207 255L246.1 216H112C98.75 216 88 205.3 88 192C88 178.7 98.75 168 112 168H246.1L207 128.1C197.7 119.6 197.7 104.4 207 95.03C216.4 85.66 231.6 85.66 240.1 95.03L320.1 175zM480 512C426.1 512 384 469 384 416H256C256 469 213 512 160 512C106.1 512 64 469 64 416C28.65 416 0 387.3 0 352V64C0 28.65 28.65 0 64 0H352C387.3 0 416 28.65 416 64V96H458.7C473.6 96 487.8 101.9 498.3 112.4L591.6 205.7C602.1 216.2 608 230.4 608 245.3V368H616C629.3 368 640 378.7 640 392C640 405.3 629.3 416 616 416H576C576 469 533 512 480 512H480zM48 64V352C48 360.8 55.16 368 64 368H76.84C93.44 339.3 124.5 320 160 320C195.5 320 226.6 339.3 243.2 368H352C360.8 368 368 360.8 368 352V64C368 55.16 360.8 48 352 48H64C55.16 48 48 55.16 48 64zM558 240C557.9 239.9 557.8 239.7 557.7 239.6L464.4 146.3C462.9 144.8 460.9 144 458.7 144H416V240L558 240zM160 464C186.5 464 208 442.5 208 416C208 389.5 186.5 368 160 368C133.5 368 112 389.5 112 416C112 442.5 133.5 464 160 464zM480 368C453.5 368 432 389.5 432 416C432 442.5 453.5 464 480 464C506.5 464 528 442.5 528 416C528 389.5 506.5 368 480 368z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manage Direct Suppliers</p>
                        <p>Manage direct suppliers.</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('admin.products')" >
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('admin.products')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M360 0C390.9 0 416 25.07 416 56V184C416 214.9 390.9 240 360 240H200C169.1 240 144 214.9 144 184V56C144 25.07 169.1 0 200 0H360zM360 48H320V112C320 120.8 312.8 128 304 128H256C247.2 128 240 120.8 240 112V48H200C195.6 48 192 51.58 192 56V184C192 188.4 195.6 192 200 192H360C364.4 192 368 188.4 368 184V56C368 51.58 364.4 48 360 48V48zM0 328C0 297.1 25.07 272 56 272H216C246.9 272 272 297.1 272 328V456C272 486.9 246.9 512 216 512H56C25.07 512 0 486.9 0 456V328zM176 384C176 392.8 168.8 400 160 400H112C103.2 400 96 392.8 96 384V320H56C51.58 320 48 323.6 48 328V456C48 460.4 51.58 464 56 464H216C220.4 464 224 460.4 224 456V328C224 323.6 220.4 320 216 320H176V384zM304 328C304 297.1 329.1 272 360 272H520C550.9 272 576 297.1 576 328V456C576 486.9 550.9 512 520 512H360C329.1 512 304 486.9 304 456V328zM480 384C480 392.8 472.8 400 464 400H416C407.2 400 400 392.8 400 384V320H360C355.6 320 352 323.6 352 328V456C352 460.4 355.6 464 360 464H520C524.4 464 528 460.4 528 456V328C528 323.6 524.4 320 520 320H480V384z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manage Products</p>
                        <p>Manage products</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('admin.product-types')" >
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('admin.product-types')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg  class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M442.5 163.6C446.1 171.8 448 180.6 448 189.6V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V189.6C0 180.6 1.879 171.8 5.516 163.6L47.11 70.01C57.38 46.89 80.3 32 105.6 32H342.4C367.7 32 390.6 46.9 400.9 70.01L442.5 163.6zM288 160H388.4L357 89.5C354.5 83.72 348.7 80 342.4 80H261.3L288 160zM186.7 80H105.6C99.27 80 93.54 83.72 90.97 89.5L59.64 160H159.1L186.7 80zM160 208H48V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V208H288V256C288 273.7 273.7 288 256 288H192C174.3 288 160 273.7 160 256V208z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manage Product Types</p>
                        <p>Manage product types</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;"  v-if="hasLink('admin.dashboards')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('admin.dashboards')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg  class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 168C0 145.9 17.91 128 40 128H88C110.1 128 128 145.9 128 168V216C128 238.1 110.1 256 88 256H40C17.91 256 0 238.1 0 216V168zM40 216H88V168H40V216zM0 328C0 305.9 17.91 288 40 288H88C110.1 288 128 305.9 128 328V376C128 398.1 110.1 416 88 416H40C17.91 416 0 398.1 0 376V328zM40 376H88V328H40V376zM248 128C270.1 128 288 145.9 288 168V216C288 238.1 270.1 256 248 256H200C177.9 256 160 238.1 160 216V168C160 145.9 177.9 128 200 128H248zM248 168H200V216H248V168zM160 328C160 305.9 177.9 288 200 288H248C270.1 288 288 305.9 288 328V376C288 398.1 270.1 416 248 416H200C177.9 416 160 398.1 160 376V328zM200 376H248V328H200V376zM408 128C430.1 128 448 145.9 448 168V216C448 238.1 430.1 256 408 256H360C337.9 256 320 238.1 320 216V168C320 145.9 337.9 128 360 128H408zM408 168H360V216H408V168zM320 328C320 305.9 337.9 288 360 288H408C430.1 288 448 305.9 448 328V376C448 398.1 430.1 416 408 416H360C337.9 416 320 398.1 320 376V328zM360 376H408V328H360V376z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manage Dashboards</p>
                        <p>Manage dashboards</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('admin.dashboard-widgets')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full"  :href="getLinkHref('admin.dashboard-widgets')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg   class="w-6 h-6 text-secondary-lavenderblue fill-current"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M576 64C611.3 64 640 92.65 640 128V384C640 419.3 611.3 448 576 448H64C28.65 448 0 419.3 0 384V128C0 92.65 28.65 64 64 64H576zM576 112H64C55.16 112 48 119.2 48 128V384C48 392.8 55.16 400 64 400H576C584.8 400 592 392.8 592 384V128C592 119.2 584.8 112 576 112z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manage Dashboard widgets</p>
                        <p>Manage dashboard widgets</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('admin.cms-urls')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full"  :href="getLinkHref('admin.cms-urls')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M563.3 267.2c56.2-56.2 56.2-147.3 0-203.5C509.8 10.2 423.9 7.3 366.9 57.2l-6.1 5.4c-10 8.7-11 23.9-2.3 33.9s23.9 11 33.9 2.3l6.1-5.4c38-33.2 95.2-31.3 130.9 4.4c37.4 37.4 37.4 98.1 0 135.6L416.1 346.6c-37.4 37.4-98.2 37.4-135.6 0c-35.7-35.7-37.6-92.9-4.4-130.9l4.7-5.4c8.7-10 7.7-25.1-2.3-33.9s-25.1-7.7-33.9 2.3l-4.7 5.4c-49.8 57-46.9 142.9 6.6 196.4c56.2 56.2 147.3 56.2 203.5 0L563.3 267.2zM42.7 244.8c-56.2 56.2-56.2 147.3 0 203.5c53.6 53.6 139.5 56.4 196.5 6.5l6.1-5.4c10-8.7 11-23.9 2.3-33.9s-23.9-11-33.9-2.3l-6.1 5.4c-38 33.2-95.2 31.3-130.9-4.4c-37.4-37.4-37.4-98.1 0-135.6L190 165.4c37.4-37.4 98.1-37.4 135.6 0c35.7 35.7 37.6 92.9 4.4 130.9l-5.4 6.1c-8.7 10-7.7 25.1 2.3 33.9s25.1 7.7 33.9-2.3l5.4-6.1c49.9-57 47-142.9-6.5-196.5c-56.2-56.2-147.3-56.2-203.5 0L42.7 244.8z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Manage URLs</p>
                        <p>Manage URLs</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="border md:rounded-lg mb-4 bg-white p-6"  v-if="hasAction('invite-client')">
            <span class="text-sm font-nomal text-gray-500 uppercase">Onboarding Tools</span>
            <ul>
                <li v-if="hasAction('invite-client')">
                    <a class="text-sm font-bold text-black" href="/invite-client">Invite Client</a>
                    <a class="block text-sm font-nomal text-gray-500" href="/invite-client">Request personal & client details for a new client.</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
        actions: {
            type: Object,
            default: {}
        },
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasSagesLink() {
            return this.hasLink('sages') || this.hasLink('sage-descriptions') || this.hasLink('sage-nominal-codes');
        },
        hasAction(key){
            if(!this.actions){
                return false;
            }
            return this.actions.filter(action => action.name === key).size;
        }
    },
}
</script>

<style scoped>

</style>
