<template>
    <paginated-list :response="response" actionBaseUrl="/rental-items">
        <template v-slot:savedFilter>
            <order-tabs :response="response"></order-tabs>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">PO Number</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Requested By</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Contract</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Description</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Quantity</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Unit</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Due Date</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Status</th>
        </template>
        <template v-slot:noItems>
            No rental items found.
        </template>
        <template v-slot:tableRows>
            <tr @click="navigateToItem(orderEntity)" v-for="orderEntity in orders" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ orderEntity.entity.properties.get('purchase_order_number') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>By {{ orderEntity.entity.properties.get('requested_by_user') }}</div>
                    <div>10 days ago <span class="text-xs font-normal">(01/01/2021)</span></div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{orderEntity.entity.properties.get('contract')}}</div>
                    <div></div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ orderEntity.entity.properties.get('description') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ orderEntity.entity.properties.get('quantity') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ orderEntity.entity.properties.get('unit') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    {{ orderEntity.entity.properties.get('due_date') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey">
                    <status-badge :status="orderEntity.entity.properties.get('status')"></status-badge>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";
import OrderTabs from "@/v2/tabs/OrderTabs";
export default {
    name: "RentalItemsPaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        DownloadPdfIconWithLink,
        OrderTabs
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        orders() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('rental-item'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
