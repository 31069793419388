<template>
    <div>
        <div>
            <dl class="grid grid-cols-3 gap-x-4 gap-y-4 mb-4">
                <div v-if="selectedQuote">
                    <dt class="text-xs font-normal text-secondary-darkgrey-100">Supplier</dt>
                    <dd class="text-sm text-black font-bold">{{ selectedQuote.entity.properties.get('company_name') }}</dd>
                </div>
                <div v-if="selectedQuote">
                    <dt class="text-xs font-normal text-secondary-darkgrey-100">Manufacturer</dt>
                    <dd class="text-sm text-black font-bold"><template v-if="selectedQuote.entity.properties.get('manufacturer')">
                        {{ selectedQuote.entity.properties.get('manufacturer') }}
                    </template>
                    <template>N/A</template>
                    </dd>
                </div>
                <div v-if="selectedQuote">
                    <dt class="text-xs font-normal text-secondary-darkgrey-100">Mismatch Check</dt>
                    <dd class="text-sm text-black font-bold flex justify-center">
                        <template v-if="selectedQuote.entity.properties.get('invoice_total') === '£0.00' &&
                        selectedQuote.entity.properties.get('credit_notes_total') === '£0.00' &&
                        selectedQuote.entity.properties.get('eur_invoice_total') === '€0.00' &&
                        selectedQuote.entity.properties.get('eur_credit_notes_total') === '€0.00'">
                            N/A
                        </template>
                        <template v-else-if="selectedQuote.entity.properties.get('invoices_over_quote') !== true">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                        </template>
                        <template v-else>
                            <span class="text-error">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                </svg>
                            </span>
                        </template>
                    </dd>
                </div>

                <div class="border-2 rounded-lg p-2" v-if="response.body.entities.filter(entity => entity.rels.contains('pre-priced-item') && entity.entity.properties.get('product') !== null).count() !== 0">
                    <dt class="text-xs font-normal text-secondary-darkgrey-100">Product Details</dt>
                    <dd class="text-black font-bold my-2" >{{ formatTotal(lowestPrice) }}</dd>
                    <dd class="text-xs text-secondary-darkgrey-100 cursor-pointer" @click="showPrePricedItems">Click to view</dd>
                </div>
                <div v-if="selectedQuote" class="border-2 rounded-lg p-2">
                    <dt v-if="!selectedQuote.entity.properties.get('is_proforma')" class="text-xs font-normal text-secondary-darkgrey-100">Quote Details</dt>
                    <dt v-else class="text-xs font-normal text-secondary-darkgrey-100">Proforma Quote Details</dt>
                    <dd class="text-black font-bold my-2" :class="{'text-error': selectedQuote.entity.properties.get('invoices_over_quote') === true}">{{ selectedQuote.entity.properties.get('total') }}</dd>
                    <dd class="text-xs text-secondary-darkgrey-100 cursor-pointer" @click="showQuotePdf = true">Click to view</dd>
                </div>
                <div v-if="cmsQuote && !response.body.properties.get('is_fully_approved')" class="border-2 rounded-lg p-2">
                    <dt class="text-xs font-normal text-secondary-darkgrey-100">CMS Quote Details</dt>
                    <dd class="text-black font-bold my-2">{{ cmsQuote.entity.properties.get('total') }}</dd>
                    <dd class="text-xs text-secondary-darkgrey-100 cursor-pointer" @click="showCmsQuotePdf = true">Click to view</dd>
                </div>
                <div v-if="selectedQuote && response.body.properties.get('show_invoices_credits')" class="border-2 rounded-lg p-2">
                    <dt class="text-xs font-normal text-secondary-darkgrey-100">Invoice Details</dt>
                    <dd class=" text-black font-bold my-2" :class="{'text-error': selectedQuote.entity.properties.get('invoices_over_quote') === true}">
                        <template v-if="selectedQuote.entity.properties.get('invoice_total') !== '£0.00' || selectedQuote.entity.properties.get('eur_invoice_total') === '€0.00'">
                            {{ selectedQuote.entity.properties.get('invoice_total') }}
                        </template>
                        <template v-if="selectedQuote.entity.properties.get('eur_invoice_total') !== '€0.00'">
                            {{ selectedQuote.entity.properties.get('eur_invoice_total')}}
                        </template>
                    </dd>
                    <dd class="text-xs text-secondary-darkgrey-100 cursor-pointer" @click="displayInvoices">Click to view</dd>
                </div>
                <div v-if="selectedQuote && response.body.properties.get('show_invoices_credits')" class="border-2 rounded-lg p-2">
                    <dt class="text-xs font-normal text-secondary-darkgrey-100">Credit Details</dt>
                    <dd class="text-black font-bold my-2" :class="{'text-error': selectedQuote.entity.properties.get('invoices_over_quote') === true}">
                        <template v-if="selectedQuote.entity.properties.get('credit_notes_total') !== '£0.00' || selectedQuote.entity.properties.get('eur_credit_notes_total') === '€0.00'">
                        {{ selectedQuote.entity.properties.get('credit_notes_total') }}
                        </template>
                        <template v-if="selectedQuote.entity.properties.get('eur_credit_notes_total') !== '€0.00'">
                        {{ selectedQuote.entity.properties.get('eur_credit_notes_total')}}
                        </template>
                    </dd>
                    <dd class="text-xs text-secondary-darkgrey-100 cursor-pointer" @click="displayRefunded">Click to view</dd>
                </div>
            </dl>
        </div>
        <download-pdf v-if="showQuotePdf" :url="selectedQuote.entity.properties.get('pdf_links')" @removeFromDisplay="showQuotePdf=false" :bannerText="getBannerText">
            <template v-slot="header">View Quote <a-button @click.native="updateQuote" v-if="response.body.actions.filter(action => action.name==='update-quote').first()" class="ml-4">Update</a-button></template>
        </download-pdf>

        <update-quote-form v-if="response.body.actions.filter(action => action.name==='update-quote')" :displayModal="displayQuoteDetails" :action="response.body.actions.filter(action => action.name==='update-quote').first()" @removeFromDisplay="closeUpdateQuote" :onSuccessPath="$router.currentRoute.fullPath"></update-quote-form>


        <download-pdf v-if="showCmsQuotePdf" :url="cmsQuote.entity.properties.get('pdf_links')" @removeFromDisplay="showCmsQuotePdf=false">
            <template v-slot="header">View Quote <a-button @click.native="viewCmsDetails = true" v-if="cmsQuote" class="ml-4">View Details</a-button>
                <confirmation-action zIndex="z-50" v-if="response.body.actions.filter(action => action.name==='select-cms-quote').first()" class="inline-flex ml-4" additionalButtonClasses="my-2 w-full" :action="response.body.actions.filter(action => action.name==='select-cms-quote').first()" actionKey="select-cms-quote" :redirectOnSuccess="true" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath"></confirmation-action>
                <select-quote-form class="inline-flex ml-4" v-if="response.body.actions.filter(action => action.name==='select-quote-for-approval').first()"
                                   :action="response.body.actions.filter(action => action.name==='select-quote-for-approval').first()"
                                   actionKey="select-quote-for-approval"
                                   :onSuccessPath="$router.currentRoute.fullPath"
                                   :secondAction="response.body.actions.filter(action2 => !action2.fields.isEmpty() && action2.name ==='select-quote-and-ask-cms-to-beat').first()"
                                   additionalButtonClasses="my-2 w-full"
                                   title="Use Another Quote"
                ></select-quote-form>
            </template>
        </download-pdf>
<!--        <update-quote-form v-if="cmsQuote" :displayModal="displayQuoteDetails" :action="response.body.actions.filter(action => action.name==='update-quote').first()" @removeFromDisplay="viewCmsDetails=false" :onSuccessPath="$router.currentRoute.fullPath"></update-quote-form>-->
        <view-cms-quote-details v-if="cmsQuote" :displayModal="viewCmsDetails" :quote="cmsQuote" @removeFromDisplay="viewCmsDetails = false"></view-cms-quote-details>
    </div>
</template>

<script>
import DownloadPdf from "@/pages/DownloadPdf";
import Button from "@/v2/buttons/Button";
import UpdateQuoteForm from "@/v2/components/forms/UpdateQuoteForm";
import {integer} from "vee-validate/dist/rules.esm";
import ViewCmsQuoteDetails from "@/v2/components/forms/ViewCmsQuoteDetails.vue";
import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";
import SelectQuoteForm from "@/v2/components/forms/SelectQuoteForm.vue";
export default {
    name: "SelectedQuote",
    components:{
        SelectQuoteForm,
        ConfirmationAction,
        ViewCmsQuoteDetails,
        DownloadPdf,
        'a-button': Button,
        UpdateQuoteForm
    },
    data(){
        return {
            showQuotePdf: false,
            displayQuoteDetails: false,
            showOriginalQuote: false,
            showCmsQuotePdf: false,
            viewCmsDetails: false
        }
    },
    props:{
        response :{
            type: Object
        },
        lowestPrice:{
            default: 0
        }
    },
    computed:{
        selectedQuote(orderEntity) {
            let selectedQuote = this.response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("quote");
                })
                .first();

            if (!selectedQuote) {
                return null;
            }

            return selectedQuote;
        },
        cmsQuote(orderEntity) {
            let selectedQuote = this.response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("cms_quote");
                })
                .first();

            if (!selectedQuote) {
                return null;
            }

            return selectedQuote;
        },
        getBannerText()
        {
            if(this.selectedQuote.entity.properties.get('total_with_service_fee') !== null){
                return 'Total inc service fee: ' + this.selectedQuote.entity.properties.get('total_with_service_fee')
            }
            return null;
        }
    },
    methods: {
        updateQuote() {
            this.displayQuoteDetails = true;
            this.showQuotePdf = false;
        },
        displayInvoices(){
            this.$emit('displayInvoices', true);
        },
        displayRefunded(){
            this.$emit('displayRefunded', true);
        },
        closeUpdateQuote(){
            this.displayQuoteDetails = false;
        },
        showPrePricedItems(){
            this.$emit('showPrePricedItems');
        },
        formatTotal(total){
            return '£'+ (total / 100).toFixed(2);
        }
    }
}
</script>

<style scoped>

</style>
