<template>
    <main-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <div class="md:flex justify-between w-full">
                            <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                Clients
                                <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span @click="navigateToOss(response)" class="cursor-pointer">
                                    On-site Selfie
                                </span>
                                <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span class="text-secondary-darkblue">
                                    Departments
                                </span>
                            </div>
                            <div class="flex items-center text-secondary-darkgrey">
                                <div v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'update-client'  && !action.fields.isEmpty())"
                                     class="mr-1">
                                    <form-action :action="actionWithFields[1]"
                                                 :actionKey="actionWithFields[0]"
                                                 :redirectOnSuccess="true"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                                    ></form-action>
                                </div>
                            </div>
                        </div>

                    </page-header>


                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-full lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1">
                                        <client-departments-table :response="response"></client-departments-table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import FormAction from "@/v2/components/FormAction";
import ClientContractsTable from "@/components/Tables/ClientContractsTable";
import ClientDepartmentsTable from "@/components/Tables/ClientDepartmentsTable.vue";
export default {
    name: "ClientDepartments",
    data() {
        return {
            loading: false
        }
    },
    components: {
        ClientDepartmentsTable,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        ClientContractsTable,
        FormAction
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToClient(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        navigateToOss(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('on-site-selfie')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
