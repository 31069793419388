<template>
    <div class="fixed dsadas bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10">
        <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left max-h-screen-3/4 overflow-auto">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{title}}
                </h3>
                <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500">
                    {{confirmationString}}
                </p>
                </div>
                <slot></slot>
            </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <secondary-button class="mr-3" @click.native="perform()" data-cy="submit-awaiting-payment" :loading="submitting">
                    Awaiting Payment
                </secondary-button>
                <primary-button @click.native="displayPaymentModal = true" data-cy="submit-with-card-payment">
                    Paid By Card
                </primary-button>
            </span>
            <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button @click="removeFromDisplay()" data-cy="cancel-modal" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Cancel
                </button>
            </span>
            </div>
            <modal v-if="displayPaymentModal" title="Payment details" @removeFromDisplay="displayPaymentModal = false" @perform="performWithCardPayment()" :submitting="submittingByCard">
                <slot name="additional-fields"></slot>
            </modal>
        </div>
        </div>
</template>

<script>
    import PrimaryButton from './PrimaryButton';
    import SecondaryButton from "./SecondaryButton";
    import Modal from './Modal';
    export default {
        components: {
            SecondaryButton,
            PrimaryButton,
            Modal
        },
        data() {
            return {
                displayPaymentModal: false
            }
        },
        props: {
            submitting:{
                type: Boolean,
                default: false
            },
            submittingByCard:{
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Perform action.'
            },
            confirmationString: {
                type: String,
                default: 'Are you sure?'
            },
        },
        methods: {
            removeFromDisplay() {
                this.$emit('removeFromDisplay');
            },
            perform(){
                this.$emit('perform');
            },
            performWithCardPayment(){
                this.$emit('performWithCardPayment');
            }
        },
    }
</script>
