<template>
    <div class="cursor-default">
        <a-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>
      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">
            <template v-for="(field, index) in action.fields">
                <template v-if="field[0] ==='email_options'">
                    <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="emailOptions">
                    </component>
                    <div v-show="errors.has(field[1].name)" class="text-red-600 text-sm">{{ errors.first(field[1].name) }}</div>
                </template>
                <template v-else-if="field[0] === 'email_manual_input'">
                    <template v-if="emailOptions === 'manual_input'">
                        <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[0]]">
                        </component>
                        <div v-show="errors.has(field[1].name)" class="text-red-600 text-sm">{{ errors.first(field[1].name) }}</div>
                    </template>
                </template>
                <template v-else-if="field[0] === 'email_column'">
                    <template v-if="emailOptions === 'column' || (emailOptions === 'retrieve' && emailRetrieve !== null)">
                        <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[0]]">
                        </component>
                        <div v-show="errors.has(field[1].name)" class="text-red-600 text-sm">{{ errors.first(field[1].name) }}</div>
                    </template>
                </template>
                <template v-else-if="field[0] === 'email_retrieve'">
                    <template v-if="emailOptions === 'retrieve'">
                        <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="emailRetrieve">
                        </component>
                        <div v-show="errors.has(field[1].name)" class="text-red-600 text-sm">{{ errors.first(field[1].name) }}</div>
                    </template>
                </template>
                <template v-else-if="field[0] !== 'fields'">
                    <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[0]]">
                    </component>
                    <div v-show="errors.has(field[1].name)" class="text-red-600 text-sm">{{ errors.first(field[1].name) }}</div>
                </template>
                <template v-else-if="field[0] === 'fields'">
                    <div class="rounded-md flex-grow">
                        <div class="focus-within:z-10">
                            <label for="email" class="block text-sm font-medium leading-5 text-gray-700 mt-3">{{ field[1].title }}</label>
                            <a class="underline cursor-pointer" href="#" @click="showFields = true">Add Fields</a>
                        </div>
                        <div v-show="errors.has(field[1].name)" class="text-red-600 text-sm">{{ errors.first(field[1].name) }}</div>
                    </div>
                </template>
            </template>

            <div v-show="errors.has('items')" class="text-red-600 text-sm">{{ errors.first('items') }}</div>

        </slide-over>

        <large-model v-show="showFields" @removeFromDisplay="showFields = false" @perform="showFields = false" additionalClasses="z-50" :displayCancelButton="false">
            <component :is="getFieldComponent(action.fields.get('fields'))" :field="action.fields.get('fields')" v-model="formData['fields']">
            </component>
            <div v-show="errors.has('fields')" class="text-red-600 text-sm">{{ errors.first('fields') }}</div>
        </large-model>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import LargeModel from "@/v2/components/LargeModel.vue";
import QuestionsBox from "@/components/Action/Fields/QuestionsBox.vue";
import {ifError} from "assert";

export default {
    name: "CreatePayNSendTemplateAction",
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            showFields: false,
            emailOptions: null,
            emailRetrieve: null
        }
    },
    components: {
        QuestionsBox, LargeModel,
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            let that = this;
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) {
                    if(field.name === 'email_options') {
                        that.emailOptions = field.value;
                    }else if(field.name === 'email_retrieve'){
                        that.emailRetrieve = field.value;
                    }
                    payload[field.name] = field.value
                };
            });
            return payload;
        },
    },
    methods: {
        ifError,
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            if(!this.submittingModal) {
                this.submittingModal = true;
                this.formData['email_options'] = this.emailOptions;
                this.formData['email_retrieve'] = this.emailRetrieve;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.path;
                        if (newPath !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        } else {
                            this.$router.go(0);
                        }
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else if (error.status === 403 || error.status === 401) {
                        let errorMessage = "You do not have permission to " + this.action.title;
                        if (error.response.body.message) {
                            errorMessage = error.response.body.message;
                        }
                        alert(errorMessage);
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        }
    },
}
</script>

<style scoped>

</style>
