<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

        <div slot-scope="{ response, logo }">
            <loading v-if="!response"></loading>
            <template v-else>
                <page-header :logo="logo">
                    <div class="md:flex justify-between w-full items-center">
                        Pre-Checks
                        <div class="flex items-center text-secondary-darkgrey">

                            <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter')">
                                <a-button v-if="actionWithFields[0] === 'get-pre-check-template' && !response.body.actions.filter(action => action.name === 'can-not-create-pre-check-on-hub').first()" @click.native="goToCreatePreCheckPage()">Create New Pre-Check</a-button>
                                <template v-else-if="actionWithFields[0] === 'can-not-create-pre-check-on-hub'">
                                    {{actionWithFields[1].title}}
                                </template>
                                <form-action v-else-if="actionWithFields[0] !== 'get-pre-check-template'" class="action-with-fields" :action="actionWithFields[1]"
                                                  :actionKey="actionWithFields[0]"
                                             :redirectOnSuccess="true"
                                             :onSuccessPath="$router.currentRoute.fullPath"
                                ></form-action>
                            </div>
                        </div>
                    </div>
                </page-header>

                <pre-check-paginated-list :response="response"></pre-check-paginated-list>
            </template>
        </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import QualityAssementPaginatedList from "@/v2/paginatedLists/QualityAssessmentPaginatedList";
    import Button from "@/v2/buttons/Button";
    import PreCheckPaginatedList from "@/v2/paginatedLists/PreCheckPaginatedList.vue";
    export default {
    name: "PreChecks",
        components: {
            PreCheckPaginatedList,
            QualityAssementPaginatedList,
            FormAction,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader,
            'a-button': Button
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods: {
            goToCreatePreCheckPage() {
                return this.$router.push('pre-checks/create');
            },
            goToCreateTemplatePage() {
                return this.$router.push('templates/create');
            },
        }
    }

</script>

<style scoped>

</style>
