<template>
    <div class="order-items">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Return request items</h3>
        <div class="flex flex-col" v-if="orderItems">
            <div class="-my-2 py-2 overflow-x-auto md:overflow-visible sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
                    <table class="min-w-full">
                        <thead>
                        <tr>
                            <th style="width:100%;"
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Description
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Code
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Ordered qty
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Delivered qty
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Qty to return
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="refundRequestOrderItemEntity, key in orderItems">
                            <refund-request-item-row :ref="'orderItemRow'"
                                                     :orderItemEntity="refundRequestOrderItemEntity"
                                                     :refundItemEntity="getRelated(refundRequestOrderItemEntity)"
                                                     :scope="'order-item-row-'+key"
                                                     :response="response"></refund-request-item-row>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OrderItemRow from './OrderItemRow';
    import RefundRequestItemRow from './RefundRequestItemRow';
    import Siren from "super-siren";
    import {mapGetters} from "vuex";

    export default {
        components: {
            OrderItemRow,
            RefundRequestItemRow
        },
        data() {
            return {
                orderItems: null
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            refundRequestOrderItemEntities() {
                if (!this.response) {
                    return null;
                }

                let res = this.response;

                let firstOrderSelfLink = this.response.body.entities.filter(entity =>
                    entity.rels.contains('order')).first().entity.links.filter(link => link.rels.contains('self')).first();
                Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                Siren.get(firstOrderSelfLink.href).then(res => {
                    this.orderItems = res.body.entities.filter(entity => entity.rels.contains('order_item'))
                });
            },
            refundRequestItemEntities() {
                if (!this.response) {
                    return null;
                }

                return this.response.body.entities.filter(entity => entity.rels.contains('refund_request_item'));
            },
            order() {
                if (!this.response) {
                    return null;
                }
            },
        },
        methods: {
            getRelated(refundRequestOrderItemEntity) {
                let orderItemId = refundRequestOrderItemEntity.entity.properties.get('id');
                return this.refundRequestItemEntities.filter(entity => parseInt(entity.entity.properties.get('order_item_id')) === parseInt(orderItemId)).first();
            }
        },
        created(){
            this.refundRequestOrderItemEntities();
        }
    }
</script>

<style lang="scss">
    .order-items label {
        display: none;
    }
</style>
