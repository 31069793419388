<template>
    <div>
        <a-button class="mr-3" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>
        <reconcile-slide-over v-if="displayModal" :submitting="submittingModal" :title="action.title"
               :confirmationString='`Are you sure you want to \"${action.title}\"`'
               @removeFromDisplay="displayModal = false" @perform="perform()">
            <template v-if="mainFields.get('order_id')">
                <!-- Credit Note Order -->
                <component :is="getFieldComponent(mainFields.get('order_id'))" :field="mainFields.get('order_id')"
                           v-model="formData[mainFields.get('order_id').name]"
                           dependsOn="site_id"
                           :dependsOnValue="siteId">
                </component>
                <div v-show="errors.has(mainFields.get('order_id').name)" class="text-red-600">{{
                        errors.first(mainFields.get('order_id').name) }}</div>
            </template>

            <!-- Credit Note Reference -->
            <component :is="getFieldComponent(mainFields.get('reference'))" :field="mainFields.get('reference')"
                       v-model="formData[mainFields.get('reference').name]">
            </component>
            <div v-show="errors.has(mainFields.get('reference').name)" class="text-red-600">{{
                    errors.first(mainFields.get('reference').name) }}</div>

            <!-- Credit Note File -->
            <component :is="getFieldComponent(mainFields.get('file'))" :field="mainFields.get('file')"
                       v-model="formData[mainFields.get('file').name]">
            </component>
            <div v-show="errors.has(mainFields.get('file').name)" class="text-red-600">{{
                    errors.first(mainFields.get('file').name) }}</div>

            <!-- Credit Note Tax Code -->
            <component @input="saveTaxPercent" :is="getFieldComponent(mainFields.get('tax_code'))" :field="mainFields.get('tax_code')"
                       v-model="formData[mainFields.get('tax_code').name]">
            </component>
            <div v-show="errors.has(mainFields.get('tax_code').name)" class="text-red-600">{{
                    errors.first(mainFields.get('tax_code').name) }}</div>

            <!-- Short Description -->
            <component :is="getFieldComponent(mainFields.get('short_description'))"
                       :field="mainFields.get('short_description')"
                       v-model="formData[mainFields.get('short_description').name]">
            </component>
            <div v-show="errors.has(mainFields.get('short_description').name)" class="text-red-600">{{
                    errors.first(mainFields.get('short_description').name) }}</div>

            <!-- Date -->
            <component :is="getFieldComponent(mainFields.get('date'))"
                       :field="mainFields.get('date')"
                       v-model="formData[mainFields.get('date').name]">
            </component>
            <div v-show="errors.has(mainFields.get('date').name)" class="text-red-600">{{
                    errors.first(mainFields.get('date').name) }}</div>


            <!-- Nominal Code -->
            <component :is="getFieldComponent(mainFields.get('nominal_code'))"
                       :field="mainFields.get('nominal_code')"
                       v-model="formData[mainFields.get('nominal_code').name]">
            </component>
            <div v-show="errors.has(mainFields.get('nominal_code').name)" class="text-red-600">{{
                    errors.first(mainFields.get('nominal_code').name) }}</div>

            <div class="flex items-center justify-end">
                <span class="ml-3"  @click="toggleTax()">
                    <span class="text-sm font-medium text-gray-900 mr-2">Auto Calculate Values </span>
                </span>
                <button type="button" @click="toggleTax()" :class="{'bg-indigo-600' : calculateTaxOn}" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" aria-pressed="false" aria-labelledby="annual-billing-label">
                    <span class="sr-only">Use setting</span>
                    <span aria-hidden="true" :class="{'translate-x-5' : calculateTaxOn}" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                </button>
            </div>

            <!-- Net Value -->
            <component class="flashable" :class="{'flash' : wasNetRecentlyAutomaticallyUpdated}" @blur="calculateValues('net')" :is="getFieldComponent(mainFields.get('net_value'))" :field="mainFields.get('net_value')"
                       v-model="formData[mainFields.get('net_value').name]">
            </component>
            <div v-show="errors.has(mainFields.get('net_value').name)" class="text-red-600">{{
                    errors.first(mainFields.get('net_value').name) }}</div>

            <!-- Vat Value -->
            <component class="flashable" :class="{'flash' : wasVatRecentlyAutomaticallyUpdated}" @blur="calculateValues('vat')" :is="getFieldComponent(mainFields.get('vat_value'))" :field="mainFields.get('vat_value')"
                       v-model="formData[mainFields.get('vat_value').name]">
            </component>
            <div v-show="errors.has(mainFields.get('vat_value').name)" class="text-red-600">{{
                    errors.first(mainFields.get('vat_value').name) }}</div>

            <!-- Gross Value -->
            <component class="flashable" :class="{'flash' : wasGrossRecentlyAutomaticallyUpdated}" @blur="calculateValues('gross')" :is="getFieldComponent(mainFields.get('gross_value'))" :field="mainFields.get('gross_value')"
                       v-model="formData[mainFields.get('gross_value').name]">
            </component>
            <div v-show="errors.has(mainFields.get('gross_value').name)" class="text-red-600">{{
                    errors.first(mainFields.get('gross_value').name) }}</div>

          <!-- Is More Credit Needed -->
          <template v-if="mainFields.get('is_more_credit_needed')">
            <component :is="getFieldComponent(mainFields.get('is_more_credit_needed'))" :field="mainFields.get('is_more_credit_needed')"
                       v-model="formData[mainFields.get('is_more_credit_needed').name]">
            </component>
            <div v-show="errors.has(mainFields.get('is_more_credit_needed').name)" class="text-red-600">{{
                errors.first(mainFields.get('is_more_credit_needed').name) }}</div>
          </template>
        </reconcile-slide-over>
    </div>
</template>

<script>
    import Button from './Button';
    import ReconcileModal from './ReconcileModal';
    import SlideOver from "./SlideOver";
    import ReconcileSlideOver from './ReconcileSlideOver';

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                formData: {},
                taxPercent: null,
                calculateTaxOn: true,
                wasGrossRecentlyAutomaticallyUpdated: false,
                wasNetRecentlyAutomaticallyUpdated: false,
                wasVatRecentlyAutomaticallyUpdated: false,
                projectId: null,
                clientId: null,
                siteId: null
            }
        },
        components: {
            'a-button': Button,
            ReconcileModal,
            ReconcileSlideOver,
            SlideOver
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            }
        },
        computed: {
            mainFields(){
                return this.action.fields;
            },
        },
        methods: {
            toggleTax(){
                this.calculateTaxOn = !this.calculateTaxOn;
            },
            saveTaxPercent(value){
                let taxFieldOptions = this.mainFields.get('tax_code').options
                if(taxFieldOptions.filter(option => option.value === value)[0]){
                    let taxFieldOptionsValues = taxFieldOptions.filter(option => option.value === value)[0].title.split('-');
                    this.taxPercent = parseFloat(taxFieldOptionsValues.pop().trim());
                }
            },
            calculateValues(type){
                if(this.taxPercent !== null && this.calculateTaxOn) {
                    console.log(type);
                    if (type === 'net') {
                        this.$set(this.formData, 'vat_value', Math.round(this.formData.net_value * this.taxPercent, 2));
                        this.$set(this.formData, 'gross_value', Math.round(this.formData.net_value + this.formData.vat_value));
                        this.wasGrossRecentlyAutomaticallyUpdated = true;
                        this.wasVatRecentlyAutomaticallyUpdated = true;
                        setTimeout(() => this.wasVatRecentlyAutomaticallyUpdated = false, 8000);
                        setTimeout(() => this.wasGrossRecentlyAutomaticallyUpdated = false, 8000);
                    }

                    if (type === 'gross') {
                        this.$set(this.formData, 'net_value', Math.round(this.formData.gross_value / (1 + this.taxPercent)));
                        this.$set(this.formData, 'vat_value', Math.round(this.formData.gross_value - this.formData.net_value));
                        this.wasNetRecentlyAutomaticallyUpdated = true;
                        this.wasVatRecentlyAutomaticallyUpdated = true;
                        setTimeout(() => this.wasVatRecentlyAutomaticallyUpdated = false, 8000);
                        setTimeout(() => this.wasNetRecentlyAutomaticallyUpdated = false, 8000);
                    }
                }
            },
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = true;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/credit-notes');
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    alert('There was an error processing your request.' + error);
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                    this.$emit('failure');
                });

            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            setProjectId(value) {
                this.projectId = value;
            },
            setClientId(value) {
                this.clientId = value;
            },
            setSiteId(value) {
                this.siteId = value;
            },
        },
        mounted() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                this.formData = payload;
                this.saveTaxPercent(parseInt(this.formData[this.mainFields.get('tax_code').name]));
        }

    }
</script>

<style lang="scss">
    .flashable input{
        box-shadow: 0 0 0 #ddd;
    }
    .flash input{
        transition: box-shadow 1s;
        box-shadow: 0 0 8px orange;
    }
</style>
