<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">QA Trackers</h2>

                <div class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty())"
                         class="mr-1">
                        <create-qa-tracker-form v-if="actionWithFields[0] === 'create-qa-tracker'"
                                                :action="actionWithFields[1]"
                                                :actionKey="actionWithFields[0]"
                                                :redirectOnSuccess="true"
                                                onSuccessPath="/qa-trackers"
                                                @success="success(actionWithFields[0])"
                        ></create-qa-tracker-form>
                        <action-with-form v-else :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="true"
                                          :onSuccessPath="qa-trackers"
                                          @success="success(actionWithFields[0])"
                        ></action-with-form>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import ActionWithForm from '../ActionWithForm';
    import Button from "@/components/Button";
    import CreateQaTrackerForm from "@/components/CustomForms/CreateQaTrackerForm";
    import fileDownload from "js-file-download";

    export default {
        inject: ["$validator"],
        components:{
            ActionWithForm,
            'a-button': Button,
            CreateQaTrackerForm
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'filter')
            }
        },
        created() {
            document.title = 'QA Trackers - CMS';
        }
    }
</script>

<style lang="scss" scoped>

</style>
