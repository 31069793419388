import fileDownload from 'js-file-download';
export default {
  methods: {
    performAction(action, dataOverride = null, redirectToOrdersPageIfOrderRecievedOnSuccess = null, redirect = true, scope = null) {
      return new Promise((resolve, reject) => {
        this.$validator.validate().then(valid => {
          if (valid) {
            let convertedJSON = {};
            if (dataOverride === null) {
              convertedJSON = this.fieldValues;
            } else {
              convertedJSON = dataOverride;
            }

            this.currentAction = null;

            action.perform(convertedJSON).then(res => {
                console.log('performed');
              if (res.type === 'text/csv') {
                fileDownload(res.text, 'export.csv');
              }else if (!redirect) {
                // resolve('success');
                // return;
              } else if (res.body.classes.has('deleted-site')) {
                this.$store.dispatch(
                  "setMessage",
                  this.messages(res).entity.properties.get("success")
                );

                this.$router.push('/sites');
                this.$router.go(0);//Force reload
              } else if (res.body.classes.has('deleted-project')) {
                this.$store.dispatch(
                  "setMessage",
                  this.messages(res).entity.properties.get("success")
                );

                this.$router.push('/projects');
                this.$router.go(0);//Force reload
              } else if (res.body.classes.has('rental-request')) {
                this.$store.dispatch(
                  "setMessage",
                  this.messages(res).entity.properties.get("success")
                );

                this.$router.push('/pending-rentals');
                this.$router.go(0);//Force reload
              } else if (res.body.classes.has('saved-filter')) {
                this.$store.dispatch(
                  "setMessage",
                  this.messages(res).entity.properties.get("success")
                );
                this.$router.go(0);//Force reload
              } else if (res.body.classes.has('deleted-order') || res.body.classes.has("scheduled-delivery") || (redirectToOrdersPageIfOrderRecievedOnSuccess && (res.body.classes.has('order')))) {
                this.$store.dispatch(
                  "setMessage",
                  this.messages(res).entity.properties.get("success")
                );

                this.$router.push({ 'name': 'orders' });
                this.$router.go(0);//Force reload

              } else if (res.body.classes.has('draft-rental-item')) {
                // Do nothing...
                // draft-rental-item
                let draftRentalLink = res.body.links.filter(link => link.rels.contains("draft-rental")).first().href;

                let parser = document.createElement('a');
                parser.href = this.getFrontendRouteFromApiRoute(draftRentalLink);

                this.$store.dispatch(
                  "setMessage",
                  this.messages(res).entity.properties.get("success")
                );

                this.$router.go(parser.href);
              } else if (res.body.classes.has('off-hire')) {
                // Do nothing...
                // draft-rental-item
                let rentalLink = res.body.links.filter(link => link.rels.contains("rental")).first().href;

                let parser = document.createElement('a');
                parser.href = this.getFrontendRouteFromApiRoute(rentalLink);

                this.$store.dispatch(
                  "setMessage",
                  this.messages(res).entity.properties.get("success")
                );

                this.$router.go(parser.href);
              } else if (res.body.classes.has('mail-sent')) {
                let parentLink = res.body.links.filter(link => link.rels.contains("parent")).first().href;

                let parser = document.createElement('a');
                parser.href = this.getFrontendRouteFromApiRoute(parentLink);

                this.$store.dispatch(
                  "setMessage",
                  this.messages(res).entity.properties.get("success")
                );

                this.$router.go(parser.href);
              } else if (res.body.classes.has('response')) {
                let parentLink = res.body.links.filter(link => link.rels.contains("parent")).first().href;

                let parser = document.createElement('a');
                parser.href = this.getFrontendRouteFromApiRoute(parentLink);

                this.$store.dispatch(
                  "setMessage",
                  this.messages(res).entity.properties.get("success")
                );

                this.$router.go(parser.href);
              } else {
                if (res.body === null) {
                  this.$emit('reload');
                } else {
                  this.$emit('reload-res', { res: res })
                  this.$emit('close', { programatic: true });
                }
              }
              resolve('success234');
            }).catch(err => {
              console.log(err);
              console.log('error');
              this.$setLaravelValidationErrorsFromResponse(JSON.parse(err.response.text), scope);
              reject(err);
            });
          } else {
            reject('did not pass validation');
          }
        });
      });
    },
    messages(response) {
      return response.body.entities
        .filter(function(entity) {
          return entity.rels.contains("status-messages");
        })
        .first();
    }
  }
};
