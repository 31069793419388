<template>
    <div class="flex flex-col" v-if="savedRetentions">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Saved Retentions</h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Value
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Saved At
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <template v-if="savedRetentions && savedRetentions.count() > 0">
                    <tr class="border-b border-gray-200 last:border-b-0" v-for="retention in savedRetentions">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{retention.entity.properties.get('value')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{retention.entity.properties.get('added_at')}}
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                        <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                            <slot name="noItems">no saved retentions found.</slot>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ActionWithForm from "./ActionWithForm";
    import ActionWithConfirmation from "./ActionWithConfirmation";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        components: {
            ActionWithConfirmation,
            ActionWithForm,
        },
        computed: {
            savedRetentions() {
                let savedRetentions = this.response.body.entities.filter(entity => entity.rels.contains('saved_retention') && entity.entity.properties.get('added_back_to_voucher_at') === null);
                return savedRetentions;
            },
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
