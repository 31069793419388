<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full" data-cy="orders-table">
                    <thead>
                        <tr v-if="filterAction">
                            <th colspan=1000 class="p-8  bg-gray-50">
                                <generic-table-filter :action="filterAction"
                                :response="response"
                                  :actionBaseUrl="$route.path"
                                ></generic-table-filter>
                            </th>
                        </tr>
                        <tr>
                        <th
                            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            v-for="headingValue in headingValues"
                            :key="headingValue"
                        >{{headingValue}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.response.body.properties.get('total') === 0">
                        <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                No clients found.
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="productEntity,key in productEntities">
                            <tr @click="navigateToItem(productEntity)" class="bg-white hover:bg-gray-100 cursor-pointer" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                <template v-for="field, key in getPropertiesToDisplay(productEntity)">
                                    <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                        {{field}}
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                    </table>
                    <pagination
                        :response="response"
                        @navigate="paginationNavigation($event)"
                        @navigateAdditionalNext="paginationNavigation($event)"
                        @navigateAdditionalPrev="paginationNavigation($event)"
                    />
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatusBadge from "./StatusBadge";
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    export default {
        components: {
            StatusBadge,
            Pagination,
            GenericTableFilter
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            productEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('client'));
            },
            headingValues() {
                return ['Prefix', 'Name'];
            }
        },
        methods: {
            getPropertiesToDisplay(productEntity) {
                return {
                    'prefix' : productEntity.entity.properties.get('prefix'),
                    'name' : productEntity.entity.properties.get('name')
                };
            },
            navigateToItem(productEntity) {
                let selfLink = productEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
