<template>
    <paginated-list :response="response" :actionBaseUrl="$route.fullPath" :use-entities="true" :hasEntities="payNSends.size > 0">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">User</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Email</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">PDF</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Actions</th>
        </template>
        <template v-slot:noItems>
            No Slip Send found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="temp in payNSends" :key="temp" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                    {{ temp.entity.properties.get('user') }}
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                    {{ temp.entity.properties.get('email') }}
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                    <download-pdf-icon-with-link :url="temp.entity.properties.get('pdf_url')"></download-pdf-icon-with-link>
                </td>
                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                    <confirmation-action v-if="temp.entity.actions.filter(action => action.name === 'delete').first()"
                                         :action="temp.entity.actions.filter(action => action.name === 'delete').first()"
                                         action-key="delete"
                                         :on-success-path="$route.path"
                    ></confirmation-action>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
export default {
    name: "PayNSendPendingPaginatedList",
    components:{
        DownloadPdfIconWithLink,
        ConfirmationAction,
        PaginatedList,
        StatusBadge,
        OrderTabs
    },
    props:{
        response:{
            type: Object
        },
        payNSends:{
            type: Object
        }
    },
    computed:{
        templates() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('template'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
