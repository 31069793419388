<template>
    <div class="order-items">
        <div class="md:flex justify-between w-full items-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Order Items</h3>
            <div class="flex items-center text-secondary-darkgrey">
                <button v-if="response.body.actions
                    .filter(action =>
                        action.name === 'add-product-to-order'
                    ).count() === 1" @click="addPreDefinedItems" data-cy="add-item"
                        class="col-start-2 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition duration-150 ease-in-out">
                    Product List
                </button>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="-my-2 py-2 overflow-x-auto md:overflow-visible sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
                    <table class="min-w-full">
                        <thead>
                        <tr>
                            <th style="width:100%;"
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Description
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Code
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Due Date
                            </th>
                            <th class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Qty
                            </th>
                            <th v-if="isDelivered" class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Delivered Qty
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Unit
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="orderItemEntity, key in orderItemEntities">
                            <order-item-row @change="change" :ref="'orderItemRow'" :orderItemEntity="orderItemEntity"
                                            :scope="'order-item-row-'+key" :response="response"></order-item-row>
                        </template>
                        </tbody>
                        <new-order-items @change="change" :ref="'newOrderItems'" :response="response"></new-order-items>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OrderItemRow from './OrderItemRow';
    import NewOrderItems from './NewOrderItems';
    import ClockInForm from "@/v2/components/forms/ClockInForm.vue";

    export default {
        inject: ["$validator"],
        components: {
            ClockInForm,
            OrderItemRow,
            NewOrderItems
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            orderItemEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('order_item'));
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update').first();
            },
        },
        methods: {
            async updateItems() {
                let updateItemPromises = [];

                if (this.$refs.orderItemRow) {
                    for (const { index,row } of this.$refs.orderItemRow.map((row, index) => ({ index,row }))){
                        updateItemPromises.push(await row.update().catch(error => {
                            throw {
                                error: error,
                                scope: 'order-item-row-' + index
                            };
                        }));
                    };
                }
                const newItems = await this.$refs.newOrderItems.addNewItems();
                await newItems.forEach(function (promise) {
                    updateItemPromises.push(promise);
                })
                return await Promise.all(updateItemPromises);
            },
            change() {
                this.$emit('change');
            },
            updateRow(row){
                return row.update().catch(error => {
                    throw {
                        error: error,
                        scope: 'order-item-row-' + index
                    };
                });
            },
            addPreDefinedItems() {
                this.$emit('addPreDefinedItems');
            },



        },
    }
</script>

<style lang="scss">
    .order-items label {
        display: none;
    }

    .table-container-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        background-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -moz-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -ms-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -o-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
    }
</style>
