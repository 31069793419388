import axios from 'axios';

export const login = ({ commit }, { email, password }) => {
  return axios.post(process.env.VUE_APP_API_URL + '/api/user/login', { email: email, password: password })
    .then(response => {
      commit('setUser', response.data.user);
      commit('setAccessToken', response.data.access_token);
      return response;
    });
};

export const loginAsDifferentUser = ({ commit }, {user, accessToken, previousUser }) => {
    commit('setUser', user);
    commit('setAccessToken', accessToken);
    if(previousUser != null) {
        window.localStorage.setItem('previous_user', previousUser);
    }else{
        window.localStorage.removeItem('previous_user');
    }
}

export const getUser = ({ commit, state }) => {
  return axios.get(process.env.VUE_APP_API_URL + '/api/user', {
    headers: { Authorization: `Bearer ${state.accessToken}` },
  })
    .then(response => commit('setUser', response.data.user));
};

export const logout = ({ commit }) => {
  commit('logout');
  window.localStorage.removeItem('previous_user');
};

export const setMessage = ({ commit }, message) => {
  commit('setMessage', message);

  setTimeout(() => {
    commit('clearMessages');
  }, 4000);
};

export const clearMessages = ({ commit }) => {
  setTimeout(() => {
    commit('clearMessages');
  }, 4000);
}

export const clearMessagesNow = ({ commit }) => {
    commit('clearMessages');
}

export const setMessageWithoutTimeout = ({ commit }, message) => {
    commit('setMessageWithoutTimeout', message);
};

export const clearMessagesWithoutTimeoutNow = ({ commit }) => {
    commit('clearMessagesWithoutTimeout');
}

