<template>
    <div :key="$route.fullPath" class="create-new-order">
        <main-template>

        <page-title title="Create Qa Category">
        </page-title>
        <loading v-if="loading"></loading>
        <div class="container mx-auto px-4" v-if="!loading" :key="key">
            <div class="bg-white shadow p-4 sm:rounded-lg mb-4">
                <property-or-field @input="change" title="Name" :action="createTemplateAction"
                                   fieldId="name" propertyKey="name"
                                   v-model="createTemplateActionData['name']"></property-or-field>

                <property-or-field @input="change" title="Client" :action="createTemplateAction"
                                   fieldId="client_id" propertyKey="client_id"
                                   v-model="createTemplateActionData['client_id']"></property-or-field>

                <questions-input class="mt-4" fieldId="questions" :action="createTemplateAction" v-model="createTemplateActionData['questions']">

                </questions-input>
            </div>
            <div
                v-if="!loading "
                class="p-3 bottom-0 w-full mt-6 -mt-4 flex justify-end z-40">
                <primary-button @click.native="createTemplate()" class="cursor-pointer" data-cy="submit" :loading="submitting">
                    Create
                    Qa Category
                </primary-button>
            </div>

        </div>
        </main-template>
    </div>
</template>

<script>
    import Loading from '../components/Loading';
    import PrimaryButton from '../components/PrimaryButton';
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import PropertyOrField from "../components/PropertyOrField";
    import PageTitle from "../common/PageTitle";
    import DependantField from "../components/DependantField";
    import MainTemplate from "../templates/MainTemplate";
    import QuestionsInput from "../components/Action/Fields/QuestionsInput";

    let follow = Siren.Helper.follow;
    export default {
        components: {
            MainTemplate,
            Loading,
            PrimaryButton,
            PropertyOrField,
            PageTitle,
            DependantField,
            QuestionsInput
        },
        data() {
            return {
                createTemplateAction: null,
                loading: true,
                submitting: false,
                key:0
            }
        },

        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            createTemplateActionData() {
                var vm = this;
                if (!this.createTemplateAction) {
                    return {};
                }
                if (!this.createTemplateAction.fields) {
                    return {};
                }
                var payload = {};
                this.createTemplateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            change() {
            },
            createTemplate() {
                this.submitting = true;
                this.createTemplateAction.perform(this.createTemplateActionData).then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.submitting = false;
                    res.body.links.filter(link => link.rels.contains('self'))
                        .map(link => link.href)
                        .forEach(href => {
                            let parts = href.split('/api');
                            this.$router.push(parts[1]);
                        });
                    this.$emit('success', res);
                }).catch(error => {
                    this.submitting = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },
        mounted() {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow("templates"))
                .then(res => {
                    let siren = res.body;
                    this.createTemplateAction = siren.actions.filter(action => action.name === 'create-template').get('create-template');
                    this.loading = false;
                }, this);

        },
    }
</script>

<style lang="scss">
    .create-new-order .label-a {
        display: none;
    }
</style>
