<template>
  <div v-if="message && !hideNotification" class="fixed z-40 inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
    <transition
    enter-active-class="transform ease-out duration-300 transition"
    leave-active-class="transition ease-in duration-100"
    enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
>
      <div v-show="message && !hideNotification" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
        <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                  <svg class="h-6 w-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  Failed!
                </p>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  {{message}}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button @click="hideNotifications()" class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</div>
</template>

<script>
export default {
  name: "Notification",
  props: ["message", "response"],
  data() {
    return {
      hideNotification: false,
    };
  },
  methods: {
    hideNotifications(){
      this.hideNotification = true;
      this.$store.dispatch(
          "clearMessagesWithoutTimeoutNow"
        );
    }
  },
  watch: {
    message: function(val) {
        this.hideNotification = false;
    },
    response: function(val) {
        this.hideNotification = false;
    }
  }
};
</script>
