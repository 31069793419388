<template>
  <div >
      <top-header-template>
          <div class="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
              <div class="sm:mx-auto sm:w-full sm:max-w-4xl">
                <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Induction
                </h2>
              </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-4xl">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <p class="alert alert-danger" v-if="error" v-text="error"></p>
                <form action="#" method="POST" @submit.prevent="onSubmit">
                    <div v-if="inductionAction.title === 'Personal Details'">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Personal Details
                        </h3>
                        <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                            Please enter your personal details in this section.
                        </p>
                    </div>
                    <div v-show="referralCodeError" class="text-red-600">{{ referralCodeError }}</div>
                    <div v-show="!referralCodeError && errors.has('referral_code')" class="text-red-600">{{errors.first('referral_code')}}</div>
                    <div v-if="inductionAction.fields.get('email')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                        <component :is="getFieldComponent(inductionAction.fields.get('email'))" :field="inductionAction.fields.get('email')" v-model="formData['email']">
                        </component>
                        <div v-show="errors.has('email')" class="text-red-600">{{ errors.first('email') }}</div>
                    </div>

                    <div v-if="inductionAction.fields.get('first_name')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                        <component :is="getFieldComponent(inductionAction.fields.get('first_name'))" :field="inductionAction.fields.get('first_name')" v-model="formData['first_name']">
                        </component>
                        <div v-show="errors.has('first_name')" class="text-red-600">{{ errors.first('first_name') }}</div>
                    </div>

                    <div v-if="inductionAction.fields.get('surname')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                        <component :is="getFieldComponent(inductionAction.fields.get('surname'))" :field="inductionAction.fields.get('surname')" v-model="formData['surname']">
                        </component>
                        <div v-show="errors.has('surname')" class="text-red-600">{{ errors.first('surname') }}</div>
                    </div>

                    <div v-if="inductionAction.fields.get('date_of_birth')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                        <component :is="getFieldComponent(inductionAction.fields.get('date_of_birth'))" :field="inductionAction.fields.get('date_of_birth')" v-model="formData['date_of_birth']">
                        </component>
                        <div v-show="errors.has('date_of_birth')" class="text-red-600">{{ errors.first('date_of_birth') }}</div>
                    </div>

                    <div v-if="inductionAction.fields.get('address')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                        <component :is="getFieldComponent(inductionAction.fields.get('address'))" :field="inductionAction.fields.get('address')" v-model="formData['address']">
                        </component>
                        <div v-show="errors.has('address')" class="text-red-600">{{ errors.first('address') }}</div>
                    </div>

                    <div v-if="inductionAction.fields.get('contact_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                        <component :is="getFieldComponent(inductionAction.fields.get('contact_number'))" :field="inductionAction.fields.get('contact_number')" v-model="formData['contact_number']">
                        </component>
                        <div v-show="errors.has('contact_number')" class="text-red-600">{{ errors.first('contact_number') }}</div>
                    </div>

                    <div v-if="inductionAction.fields.get('national_insurance_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                        <component :is="getFieldComponent(inductionAction.fields.get('national_insurance_number'))" :field="inductionAction.fields.get('national_insurance_number')" v-model="formData['national_insurance_number']">
                        </component>
                        <div v-show="errors.has('national_insurance_number')" class="text-red-600">{{ errors.first('national_insurance_number') }}</div>

                    </div>

                    <div v-if="inductionAction.fields.get('password')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                        <component :is="getFieldComponent(inductionAction.fields.get('password'))" :field="inductionAction.fields.get('password')" v-model="formData['password']">
                        </component>
                        <div v-show="errors.has('password')" class="text-red-600">{{ errors.first('password') }}</div>

                    </div>

                    <div v-if="inductionAction.fields.get('confirm_password')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                        <component :is="getFieldComponent(inductionAction.fields.get('confirm_password'))" :field="inductionAction.fields.get('confirm_password')" v-model="formData['confirm_password']">
                        </component>
                        <div v-show="errors.has('confirm_password')" class="text-red-600">{{ errors.first('confirm_password') }}</div>

                    </div>
                    <div v-if="inductionAction.title === 'Employment Details'" class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                        <div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Employment Details
                            </h3>
                            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                Please enter your organisation details in this section.
                            </p>
                        </div>
                        <div v-if="inductionAction.fields.get('company_type')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('company_type'))" :field="inductionAction.fields.get('company_type')" v-model="formData['company_type']" @input="setCompanyType">
                            </component>
                            <div v-show="errors.has('company_type')" class="text-red-600">{{ errors.first('company_type') }}</div>

                        </div>


                        <div v-if="inductionAction.fields.get('vat_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('vat_number'))" :field="inductionAction.fields.get('vat_number')" v-model="formData['vat_number']">
                            </component>
                            <div v-show="errors.has('vat_number')" class="text-red-600">{{ errors.first('vat_number') }}</div>

                        </div>

                        <div v-if="CompanyType ==='sole-trader'">
                            <div v-if="inductionAction.fields.get('company_name')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('company_name'))" :field="inductionAction.fields.get('company_name')" v-model="formData['company_name']">
                                </component>
                                <div v-show="errors.has('company_name')" class="text-red-600">{{ errors.first('company_name') }}</div>

                            </div>

                            <div v-if="inductionAction.fields.get('utr_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('utr_number'))" :field="inductionAction.fields.get('utr_number')" v-model="formData['utr_number']">
                                </component>
                                <div v-show="errors.has('utr_number')" class="text-red-600">{{ errors.first('utr_number') }}</div>

                            </div>

                            <div v-if="inductionAction.fields.get('company_address_use_personal')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('company_address_use_personal'))" :field="inductionAction.fields.get('company_address_use_personal')" v-model="formData['company_address_use_personal']" @input="setUsePersonalAddress">
                                </component>
                                <div v-show="errors.has('company_address_use_personal')" class="text-red-600">{{ errors.first('company_address_use_personal') }}</div>

                            </div>
                            <div v-if="usePersonalAddress !== '1' && inductionAction.fields.get('company_address')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('company_address'))" :field="inductionAction.fields.get('company_address')" v-model="formData['company_address']">
                                </component>
                                <div v-show="errors.has('company_address')" class="text-red-600">{{ errors.first('company_address') }}</div>

                            </div>
                        </div>
                        <div v-if="CompanyType === 'partnership'">
                            <div v-if="inductionAction.fields.get('company_name')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('company_name'))" :field="inductionAction.fields.get('company_name')" v-model="formData['company_name']">
                                </component>
                                <div v-show="errors.has('company_name')" class="text-red-600">{{ errors.first('company_name') }}</div>

                            </div>

                            <div v-if="inductionAction.fields.get('utr_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('utr_number'))" :field="inductionAction.fields.get('utr_number')" v-model="formData['utr_number']">
                                </component>
                                <div v-show="errors.has('utr_number')" class="text-red-600">{{ errors.first('utr_number') }}</div>

                            </div>

                            <div v-if="inductionAction.fields.get('partner_name')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('partner_name'))" :field="inductionAction.fields.get('partner_name')" v-model="formData['partner_name']">
                                </component>
                                <div v-show="errors.has('partner_name')" class="text-red-600">{{ errors.first('partner_name') }}</div>

                            </div>

                            <div v-if="inductionAction.fields.get('partner_utr_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('partner_utr_number'))" :field="inductionAction.fields.get('partner_utr_number')" v-model="formData['partner_utr_number']">
                                </component>
                                <div v-show="errors.has('partner_utr_number')" class="text-red-600">{{ errors.first('partner_utr_number') }}</div>

                            </div>

                            <div v-if="inductionAction.fields.get('company_address_use_personal')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('company_address_use_personal'))" :field="inductionAction.fields.get('company_address_use_personal')" v-model="formData['company_address_use_personal']" @input="setUsePersonalAddress">
                                </component>
                                <div v-show="errors.has('company_address_use_personal')" class="text-red-600">{{ errors.first('company_address_use_personal') }}</div>

                            </div>
                            <div v-if="usePersonalAddress !== '1' && inductionAction.fields.get('company_address')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('company_address'))" :field="inductionAction.fields.get('company_address')" v-model="formData['company_address']">
                                </component>
                                <div v-show="errors.has('company_address')" class="text-red-600">{{ errors.first('company_address') }}</div>

                            </div>
                        </div>
                        <div v-if="CompanyType === 'limited-company'">
                            <div v-if="inductionAction.fields.get('company_name')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('company_name'))" :field="inductionAction.fields.get('company_name')" v-model="formData['company_name']">
                                </component>
                                <div v-show="errors.has('company_name')" class="text-red-600">{{ errors.first('company_name') }}</div>

                            </div>

                            <div v-if="inductionAction.fields.get('utr_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('utr_number'))" :field="inductionAction.fields.get('utr_number')" v-model="formData['utr_number']">
                                </component>
                                <div v-show="errors.has('utr_number')" class="text-red-600">{{ errors.first('utr_number') }}</div>

                            </div>

                            <div v-if="inductionAction.fields.get('company_registration_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('company_registration_number'))" :field="inductionAction.fields.get('company_registration_number')" v-model="formData['company_registration_number']">
                                </component>
                                <div v-show="errors.has('company_registration_number')" class="text-red-600">{{ errors.first('company_registration_number') }}</div>

                            </div>

                            <div v-if="inductionAction.fields.get('certificate_of_incorporation')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                                <component :is="getFieldComponent(inductionAction.fields.get('certificate_of_incorporation'))" :field="inductionAction.fields.get('certificate_of_incorporation')" v-model="formData['certificate_of_incorporation']">
                                </component>
                                <div v-show="errors.has('certificate_of_incorporation')" class="text-red-600">{{ errors.first('certificate_of_incorporation') }}</div>

                            </div>

                        </div>

                    </div>
                    <div v-if="inductionAction.title === 'Insurance Details'" class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                        <div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Insurance Details
                            </h3>
                            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                Please enter your insurance details in this section.
                            </p>
                        </div>
                        <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Public Liability
                            </h3>
                        </div>
                        <div v-if="inductionAction.fields.get('public_insurance_held_with')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('public_insurance_held_with'))" :field="inductionAction.fields.get('public_insurance_held_with')" v-model="formData['public_insurance_held_with']">
                            </component>
                            <div v-show="errors.has('public_insurance_held_with')" class="text-red-600">{{ errors.first('public_insurance_held_with') }}</div>

                        </div>
                        <div v-if="inductionAction.fields.get('public_insurance_address')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('public_insurance_address'))" :field="inductionAction.fields.get('public_insurance_address')" v-model="formData['public_insurance_address']">
                            </component>
                            <div v-show="errors.has('public_insurance_address')" class="text-red-600">{{ errors.first('public_insurance_address') }}</div>

                        </div>
                        <div v-if="inductionAction.fields.get('public_policy_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('public_policy_number'))" :field="inductionAction.fields.get('public_policy_number')" v-model="formData['public_policy_number']">
                            </component>
                            <div v-show="errors.has('public_policy_number')" class="text-red-600">{{ errors.first('public_policy_number') }}</div>

                        </div>
                        <div v-if="inductionAction.fields.get('public_policy_expiry_date')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('public_policy_expiry_date'))" :field="inductionAction.fields.get('public_policy_expiry_date')" v-model="formData['public_policy_expiry_date']">
                            </component>
                            <div v-show="errors.has('public_policy_expiry_date')" class="text-red-600">{{ errors.first('public_policy_expiry_date') }}</div>

                        </div>
                        <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Employers Liability
                            </h3>
                        </div>

                        <div v-if="inductionAction.fields.get('employers_insurance_held_with')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('employers_insurance_held_with'))" :field="inductionAction.fields.get('employers_insurance_held_with')" v-model="formData['employers_insurance_held_with']">
                            </component>
                            <div v-show="errors.has('employers_insurance_held_with')" class="text-red-600">{{ errors.first('employers_insurance_held_with') }}</div>

                        </div>
                        <div v-if="inductionAction.fields.get('employers_insurance_address')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('employers_insurance_address'))" :field="inductionAction.fields.get('employers_insurance_address')" v-model="formData['employers_insurance_address']">
                            </component>
                            <div v-show="errors.has('employers_insurance_address')" class="text-red-600">{{ errors.first('employers_insurance_address') }}</div>

                        </div>
                        <div v-if="inductionAction.fields.get('employers_policy_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('employers_policy_number'))" :field="inductionAction.fields.get('employers_policy_number')" v-model="formData['employers_policy_number']">
                            </component>
                            <div v-show="errors.has('employers_policy_number')" class="text-red-600">{{ errors.first('employers_policy_number') }}</div>

                        </div>
                        <div v-if="inductionAction.fields.get('employers_policy_expiry_date')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('employers_policy_expiry_date'))" :field="inductionAction.fields.get('employers_policy_expiry_date')" v-model="formData['employers_policy_expiry_date']">
                            </component>
                            <div v-show="errors.has('employers_policy_expiry_date')" class="text-red-600">{{ errors.first('employers_policy_expiry_date') }}</div>

                        </div>


                    </div>
                    <div v-if="inductionAction.title === 'Certificates'" class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                        <div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Certs & Tickets
                            </h3>
                            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                Please upload certificates, tickets and qualifications in this section.
                            </p>
                        </div>
                        <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <div class="grid grid-cols-2 mt-3">
                                <div v-if="inductionAction.fields.get('cscs_front_image')" class="mx-2">
                                    <component class="-mt-3" :is="getFieldComponent(inductionAction.fields.get('cscs_front_image'))" :field="inductionAction.fields.get('cscs_front_image')" v-model="formData['cscs_front_image']">
                                    </component>
                                    <div v-show="errors.has('cscs_front_image')" class="text-red-600">{{ errors.first('cscs_front_image') }}</div>
                                </div>
                                <div v-if="inductionAction.fields.get('cscs_back_image')" class="mx-2">
                                    <component class="-mt-3" :is="getFieldComponent(inductionAction.fields.get('cscs_back_image'))" :field="inductionAction.fields.get('cscs_back_image')" v-model="formData['cscs_back_image']">
                                    </component>
                                    <div v-show="errors.has('cscs_back_image')" class="text-red-600">{{ errors.first('cscs_back_image') }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="inductionAction.fields.get('cscs_expiry_date')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('cscs_expiry_date'))" :field="inductionAction.fields.get('cscs_expiry_date')" v-model="formData['cscs_expiry_date']">
                            </component>
                            <div v-show="errors.has('cscs_expiry_date')" class="text-red-600">{{ errors.first('cscs_expiry_date') }}</div>
                        </div>
                        <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <div class="grid grid-cols-2">
                                <div v-if="inductionAction.fields.get('id_name')" class="mx-2">
                                    <component :is="getFieldComponent(inductionAction.fields.get('id_name'))" :field="inductionAction.fields.get('id_name')" v-model="formData['id_name']">
                                    </component>
                                    <div v-show="errors.has('id_name')" class="text-red-600">{{ errors.first('id_name') }}</div>
                                </div>
                                <div v-if="inductionAction.fields.get('id_front_image')" class="mx-2">
                                    <component class="-mt-3" :is="getFieldComponent(inductionAction.fields.get('id_front_image'))" :field="inductionAction.fields.get('id_front_image')" v-model="formData['id_front_image']">
                                    </component>
                                    <div v-show="errors.has('id_front_image')" class="text-red-600">{{ errors.first('id_front_image') }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="inductionAction.fields.get('id_expiry_date')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('id_expiry_date'))" :field="inductionAction.fields.get('id_expiry_date')" v-model="formData['id_expiry_date']">
                            </component>
                            <div v-show="errors.has('id_expiry_date')" class="text-red-600">{{ errors.first('id_expiry_date') }}</div>
                        </div>
                        <div v-if="inductionAction.fields.get('certificates')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <induction-certificates class="mt-6" :action="inductionAction" v-model="formData['certificates']"></induction-certificates>
                        </div>
                    </div>
                    <div v-if="inductionAction.title === 'Bank Details'" class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                        <div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Bank & Payment
                            </h3>
                            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                Please list the bank details you wish to receive payment to.
                            </p>
                        </div>
                        <div v-if="inductionAction.fields.get('bank_name')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('bank_name'))" :field="inductionAction.fields.get('bank_name')" v-model="formData['bank_name']">
                            </component>
                            <div v-show="errors.has('bank_name')" class="text-red-600">{{ errors.first('bank_name') }}</div>
                        </div>
                        <div v-if="inductionAction.fields.get('name_on_account')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('name_on_account'))" :field="inductionAction.fields.get('name_on_account')" v-model="formData['name_on_account']">
                            </component>
                            <div v-show="errors.has('name_on_account')" class="text-red-600">{{ errors.first('name_on_account') }}</div>
                        </div>
                        <div v-if="inductionAction.fields.get('account_number')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('account_number'))" :field="inductionAction.fields.get('account_number')" v-model="formData['account_number']">
                            </component>
                            <div v-show="errors.has('account_number')" class="text-red-600">{{ errors.first('account_number') }}</div>
                        </div>
                        <div v-if="inductionAction.fields.get('sort_code')" class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                            <component :is="getFieldComponent(inductionAction.fields.get('sort_code'))" :field="inductionAction.fields.get('sort_code')" v-model="formData['sort_code']">
                            </component>
                            <div v-show="errors.has('sort_code')" class="text-red-600">{{ errors.first('sort_code') }}</div>
                        </div>
                    </div>
                    <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10" v-if="inductionAction.title === 'Declaration' && complete === false">
                        <div v-if="inductionAction.fields.get('insurance_declaration')">
                            <input type="checkbox" id="chkInsuranceDeclaration" :true-value="1" :false-value="0" v-model="formData['insurance_declaration']"/><label class="ml-3" for="chkInsuranceDeclaration">I agree to <a href="#" class="underline text-blue-500" @click.prevent="showInsuranceDeclaration = true">Insurance Declaration</a>.</label>
                            <div v-show="errors.has('insurance_declaration')" class="text-red-600">{{errors.first('insurance_declaration')}}</div>
                        </div>
                        <div class="mt-2" v-if="inductionAction.fields.get('terms_and_conditions')">
                            <input type="checkbox" id="chkTermsAndConditions" :true-value="1" :false-value="0" v-model="formData['terms_and_conditions']"/><label class="ml-3" for="chkTermsAndConditions">I agree to <a href="#" class="underline text-blue-500" @click.prevent="showTerms = true">Terms & Conditions</a>.</label>
                            <div v-show="errors.has('terms_and_conditions')" class="text-red-600">{{errors.first('terms_and_conditions')}}</div>
                        </div>
                    </div>
                    <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10" v-if="complete === true">
                        <p>Thank you, your details are being verified and confirmation will be sent to your email address.</p>
                    </div>
                    <div v-if="complete === false" class="mt-6 border-t border-gray-200 pt-6">
                      <span class="block w-full rounded-md shadow-sm">
                        <button data-cy="submit" v-if="inductionAction.title === 'Declaration'" type="submit" :disabled="loading" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out ml-2">
                            Sign up
                            <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4"></loading>
                        </button>
                        <button data-cy="submit" v-if="inductionAction.title !== 'Declaration'" type="submit" :disabled="loading" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out ml-2">
                            Next
                            <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4"></loading>
                        </button>
                      </span>
                    </div>
                </form>
                    <terms-and-conditions-popup v-show="showTerms" @removeFromDisplay="showTerms=false"></terms-and-conditions-popup>
                    <insurance-declaration-popup v-show="showInsuranceDeclaration" @removeFromDisplay="showInsuranceDeclaration=false"></insurance-declaration-popup>
              </div>
          </div>
      </div>
  </top-header-template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Siren from "super-siren";
import TopHeaderTemplate from "../templates/TopHeaderTemplate";
import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField";
import PropertyOrField from "@/components/PropertyOrField";
import TextAreaInput from "@/components/Action/Fields/TextAreaInput";
import InductionCertificates from "@/components/Action/Fields/InductionCertificates";
import TermsAndConditionsPopup from "@/components/TermsAndConditionsPopup";
import InsuranceDeclarationPopup from "@/components/InsuranceDeclarationPopup";
import Button from "@/components/Button";
import Loading from "@/components/Loading";

export default {
    components: {
        Button,
        InsuranceDeclarationPopup,
        TermsAndConditionsPopup,
        PropertyOrField,
        PropertyOrAutocompleteField,
        TopHeaderTemplate,
        TextAreaInput,
        InductionCertificates,
        Loading},
    data() {
        return {
            error: null,
            inductionAction: null,
            CompanyType:null,
            usePersonalAddress: '0',
            insuranceType: null,
            referralCodeError: null,
            showTerms: false,
            showInsuranceDeclaration: false,
            complete: false,
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        }),
        formData() {
            if (!this.inductionAction.fields) {
                return {};
            }
            var payload = {};
            this.inductionAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    created() {
        let follow = Siren.Helper.follow;
        Siren.Client.removeHeader("Authorization");

        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
            let siren = response.body;
            this.inductionAction = siren.findActionByName('induction');
        });
        if(this.$route.query.referral_code === undefined){
            alert('No Referral Code found.');
            this.referralCodeError = 'No Referral Code found.';
        }
    },
    methods: {
        onSubmit() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.formData['referral_code'] = this.$route.query.referral_code;
                    this.$nextTick(function(){
                        this.inductionAction.perform(this.formData).then(res => {
                            if(this.inductionAction.title === 'Declaration'){
                                this.complete = true;
                            }else{
                                this.inductionAction = res.body.actions.filter(action => action.name === 'induction').first();
                            }
                            this.loading = false;
                        })
                            .catch(error => {
                                this.submitting = false;
                                this.loading = false;
                                if (error.response.status !== 422) {
                                    alert('There was an error processing your request.' + error);
                                }
                                this.errors.clear();
                                this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                            });
                    })
                }
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        togglePassword(){
            this.showPassword = !this.showPassword
        },
        getFieldComponent(field) {
            if(field === undefined){
                debugger;
            }
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        setUsePersonalAddress(value){
            this.usePersonalAddress = value;
        },
        setCompanyType(value){
            this.CompanyType = value;
        },
        setInsuranceType(value){
            this.insuranceType = value;
        },
    }
}

</script>
