<template>
    <div class="rounded-md flex-grow">
        <div class="focus-within:z-10">
            <label for="email" class="block text-sm font-medium leading-5 text-gray-700  mt-3">{{field.title}}<span class="text-red-500 ml-1" v-if="required">*</span></label>
                <input
                type="time"
                :name="field.name"
                class="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                :placeholder="field.title"
                v-model="value"
                ref="input"
                />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value:{
                type:String
            },
            field: {
                type: Object
            }
        },
        computed:{
            required: function(){
                if(this.field.validation !== null) {
                    return this.field.validation.includes('required');
                }
                return false;
            }
        },
        watch: {
            value: {
                handler(value) {
                this.$emit("input", value);
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
