<template>
    <api-paginated-list :response="response" :actionBaseUrl="$route.fullPath" :use-entities="true" :has-entities="true">
        test
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Name</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Unique Id</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Status</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Last Updated</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Total Distance</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Odometer</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Position</th>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey">Details</th>
        </template>
        <template v-slot:noItems>
            No devices found.
        </template>
        <template v-slot:tableRows>
            <template v-for="device in response">
                <traccar-device-row :device="device"/>
            </template>
        </template>
    </api-paginated-list>
</template>

<script>
import ApiPaginatedList from "@/v2/paginatedLists/ApiPaginatedList.vue";
import axios from "axios";
import TraccarDeviceRow from "@/v2/paginatedLists/TraccarDeviceRow.vue";

export default {
    name: "TraccarApiPaginatedList",
    components:{
        TraccarDeviceRow,
        ApiPaginatedList
    },
    props:{
        response:{
            type: Array
        }
    },
    computed:{
        suppliers() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('company'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        }
    }
}
</script>

