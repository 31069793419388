<template>
    <div :key="$route.fullPath">
        <main-template>
            <iframe :src="baseUrl" width="100%" style="height:100vh" class="-mb-12"></iframe>
        </main-template>
    </div>
</template>

<script>
    import Loading from '../components/Loading';
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Siren from "super-siren";
    import {mapGetters} from "vuex";


    export default {
        data(){
            return {
                baseUrl: null,
                urlName: null
            }
        },
        components: {
            MainTemplate,
            Loading
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
            ...mapGetters({
                accessToken: "getAccessToken",
                message: "getMessage",
            })
        },
        mounted(){
            let follow = Siren.Helper.follow;
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            console.log(this.$route.name);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow(this.$route.name))
                .then(res => {
                    let siren = res.body;
                    this.baseUrl = siren.properties.get('url');
                }, this);
        }
    }
</script>

<style lang="scss" scoped>

</style>
