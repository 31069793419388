<template>
    <div class="container table-filter" data-cy="table-filter" v-if="action">
        <form @submit.prevent="performAction(action,actionData)" class="w-full">
            <div class="flex justify-between items-start">
                <component v-if="action.fields.filter(field => field.contains('filter[client_id]')).first()" :is="getFieldComponent(action.fields.filter(field => field.contains('filter[client_id]')).first().type)" :field="action.fields.filter(field => field.contains('filter[client_id]')).first()"
                           v-model="actionData[action.fields.filter(field => field.contains('filter[client_id]')).first().name]"
                           @input="setClientId" @change="setClientId">
                </component>
                <component v-if="action.fields.filter(field => field.contains('filter[project_id]')).first()"
                    :is="getFieldComponent(action.fields.filter(field => field.contains('filter[project_id]')).first().type)" :field="action.fields.filter(field => field.contains('filter[project_id]')).first()"
                           v-model="actionData[action.fields.filter(field => field.contains('filter[project_id]')).first().name]"
                           @input="setProjectId" @change="setProjectId" dependsOn="client_id" :dependsOnValue="clientId">
                </component>
                <component v-if="action.fields.filter(field => field.contains('filter[site_id]')).first()"
                           :is="getFieldComponent(action.fields.filter(field => field.contains('filter[site_id]')).first().type)" :field="action.fields.filter(field => field.contains('filter[site_id]')).first()"
                           v-model="actionData[action.fields.filter(field => field.contains('filter[site_id]')).first().name]"
                           dependsOn="project_id" :dependsOnValue="projectId">
                </component>
                <component v-if="action.fields.filter(field => field.contains('filter[manufacturer_id]')).first()"
                           :is="getFieldComponent(action.fields.filter(field => field.contains('filter[manufacturer_id]')).first().type)" :field="action.fields.filter(field => field.contains('filter[manufacturer_id]')).first()"
                           v-model="actionData[action.fields.filter(field => field.contains('filter[manufacturer_id]')).first().name]"
                           >
                </component>
                <component v-if="action.fields.filter(field => field.contains('filter[date_from]')).first()"
                            :is="getFieldComponent(action.fields.filter(field => field.contains('filter[date_from]')).first().type)" :field="action.fields.filter(field => field.contains('filter[date_from]')).first()"
                           v-model="actionData[action.fields.filter(field => field.contains('filter[date_from]')).first().name]"
                ></component>
                <component v-if="action.fields.filter(field => field.contains('filter[date_to]')).first()"
                           :is="getFieldComponent(action.fields.filter(field => field.contains('filter[date_to]')).first().type)" :field="action.fields.filter(field => field.contains('filter[date_to]')).first()"
                           v-model="actionData[action.fields.filter(field => field.contains('filter[date_to]')).first().name]"
                ></component>
                <button data-cy="search" @click="performAction(action,actionData)"
                        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    <span>Search</span>
                </button>
            </div>

        </form>

        <div class="text-left">
        <span v-for="(removeFilterLink,index) in removeFilterLinks" :key="index"
              class="inline-flex px-2.5 py-0.5 text-xs font-medium leading-4 bg-indigo-100 text-indigo-800">
        {{getRemovalLinkTitle(removeFilterLink.title)}} {{removeFilterLinks.href}}
        <button @click="navigateTo(removeFilterLink.href)" type="button"
                class="flex-shrink-0 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700">
            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
            </svg>
        </button>
    </span>
        </div>
    </div>
</template>

<script>
    import RadioInput from './Action/Fields/RadioInput';
    import CheckboxInput from './Action/Fields/CheckboxInput';
    import TextInput from './Action/Fields/TextInput';

    export default {
        data() {
            return {
                actionData: this.defaultValues(),
                projectId: this.defaultValues()['filter[project_id]'],
                clientId: this.defaultValues()['filter[client_id]']
            }
        },
        components: {
            RadioInput,
            CheckboxInput,
            TextInput
        },
        props: {
            action: {
                type: Object
            },
            response: {
                type: Object
            },
            actionBaseUrl: {
                default: '/orders'
            }
        },
        computed: {
            activeFiltersArray() {
                return this.activeFilters.split(', ');
            },
            removeFilterLinks() {
                if (!this.response) {
                    return null;
                }

                return this.response.body.links.filter(link => link.rels.toString().includes('remove_filter'));
            }
        },
        methods: {
            performAction(action, actionData) {
                let parts = action.href.split('/api');
                this.$router.push({path: this.actionBaseUrl, query: actionData});
            },
            navigateTo(link) {
                window.location = this.getFrontendRouteFromApiRoute(link);
            },
            getRemovalLinkTitle(originalTitle) {
                return String(originalTitle).replace('- Remove Filter', '');
            },
            getFieldComponent(type) {
                return type + '-input';
            },
            defaultValues() {
                let defaults = {};
                if (!this.action) {
                    return {};
                }
                this.action.fields.forEach(function (field) {
                    if (Array.isArray(field.value)) {
                        let filter = field.value.filter(o => o.selected === true);
                        if (filter[0]) {
                            defaults[field.name] = filter.map(o => o['value']).join(',');
                        } else {
                            defaults[field.name] = null;
                        }
                    } else {
                        defaults[field.name] = field.value;
                    }
                });
                return defaults;
            },
            setProjectId(value){
                if (value === ''){
                    this.projectId= null;
                    return;
                }
                this.projectId = value;
            },
            setClientId(value){
                if(value ===''){
                    this.clientId = null;
                    return;
                }
                this.clientId = value;
            }

        },
    };
</script>

<style lang="scss">
    .table-filter label {
        display: none;
    }
</style>
