<template>
    <div :key="$route.fullPath">
        <main-template>
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link" :logo="logo">
                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                Employee Timesheet
                                <div class="flex items-center text-secondary-darkgrey">
                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'show-timesheet')">
                                        <clock-in-user-form class="action-with-fields" :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                        ></clock-in-user-form>
                                    </div>
                                </div>
                            </div>
                        </page-header>
                        <div class="p-4">
                            <!--                        <saved-filters :response="response">-->
                            <!--                        </saved-filters>-->
                            <oss-tabs></oss-tabs>
                            <timesheet-builder-action :response="response" ref="timesheetReport" @change="change"></timesheet-builder-action>

                        </div>
                        <div class="mb-8">
                            <timesheet-builder-paginated-list :response="response">

                            </timesheet-builder-paginated-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
import FetchRootSiren from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import CompaniesHeader from "@/components/CompaniesHeader";
import MainTemplate from "@/v2/templates/MainTemplate";
import moment from "moment";
import WeeklyReportHeader from "@/components/Headers/WeeklyReportHeader";
import TimesheetBuilderAction from "@/components/TimesheetBuilderAction";
import FilterablePaginatedTimesheetBuilderList from "@/components/FilterablePaginatedTimesheetBuilderList";
import TimesheetBuilderHeader from "@/components/Headers/TimesheetBuilderHeader";
import PageHeader from "@/v2/components/Header";
import OssTabs from "@/v2/tabs/OssTabs"
import TimesheetBuilderPaginatedList from "@/v2/paginatedLists/TimesheetBuilderPaginatedList";
import FormAction from "@/v2/components/FormAction";
import ClockInUserForm from "@/v2/components/forms/ClockInUserForm";
export default {
    components: {
        TimesheetBuilderHeader,
        FilterablePaginatedTimesheetBuilderList,
        TimesheetBuilderAction,
        WeeklyReportHeader,
        MainTemplate,
        FetchRootSiren,
        Loading,
        PageHeader,
        OssTabs,
        TimesheetBuilderPaginatedList,
        ClockInUserForm
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            return this.$route.fullPath.replace('/v3','')
        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>

</style>
