<template>
    <div class="bg-primary-100 relative">
        <nav class="bg-primary-800 mb-12">
            <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div class="relative flex items-center justify-between h-16">
                    <div class="flex-1 flex items-center justify-center">
                        <a href="/">
                            <img v-if="!isUrlStaging()" class="h-8 w-auto" src="../../images/logo.png" alt="Companies Management System">
                            <span v-else class="text-red-500 text-3xl bold">TEST</span>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
            <div>
                <slot></slot>
            </div>
        </main>
    </div>

</template>

<script>
    import MenuNav from "../common/MenuNav";
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import formatting from "../mixins/formatting";
    export default {
        components:{MenuNav},
        mixins: [formatting],
        data() {
            return {
                currentAction: null,
                canCreateOrders: [1, 2, 6, 7], //Site Manage, Procurement Manager, Admin, Approval Team,
                canInviteClients: [6],
                onboardingSteps: [],
                links : null,
                clientName: null
            };
        },
        computed: {
            initials() {
                if (this.user) {
                    return this.user.name
                        .replace(/[^a-zA-Z- ]/g, "")
                        .match(/\b\w/g)
                        .join("");
                }
            },
            ...mapGetters({
                accessToken: "getAccessToken",
                user: "getUser",
                message: "getMessage",
            }),
        },
        methods: {
            logout() {
                this.$store.dispatch("logout").then(() => this.$router.push("/login"));
            },
            isUrlStaging(){
                return process.env.VUE_APP_BASE_URL === 'https://test.companiesms.co.uk'
            }
        },
        mounted() {
            let follow = Siren.Helper.follow;
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
                let siren = res.body;
                this.links = siren.links;
                return res;
            }, this).then(
                follow("current-user")
            )
                .then(res => {
                    let siren = res.body;
                    let client = siren.embeddedEntities.filter(entity => entity.entity.classes.contains('client')).first();
                    if(client !== undefined){
                        let name = client.entity.properties.get('name');
                        this.clientName = name;
                    }
                }, this);

            this.$forceUpdate();
        },
    }
</script>

<style scoped>

</style>
