<template>
    <div class="flex flex-col" v-if="suppliers.size > 0">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">
            Suppliers
        </h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Name
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Total
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        PDF
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        actions
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <template v-for="supplier in suppliers">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ supplier.entity.properties.get('supplier_name') }}
                        </td>
                        <td>
                            {{supplier.entity.properties.get('total')}}
                        </td>
                        <td>
                            <download-pdf-icon-with-link v-if="supplier.entity.properties.get('pdf')" :url="supplier.entity.properties.get('pdf')">
                                <template v-slot="header">
                                    <span class="flex">View Quote
                                        <select-quote-form v-if="supplier.entity.actions.filter(action => action.name === 'select-quote').first()"
                                                           :action="supplier.entity.actions.filter(action => action.name === 'select-quote').first()"
                                                           :actionKey="'select-quote'"
                                                           :onSuccessPath="$router.currentRoute.fullPath"
                                                           additionalButtonClasses="ml-4"
                                                           buttonType="primary"
                                                           :firstButtonTitle="supplier.entity.actions.filter(action => action.name === 'select-quote').first().title"
                                        ></select-quote-form>
                                    </span>
                                </template>

                            </download-pdf-icon-with-link>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 flex inline-flex">
                            <select-quote-form v-if="supplier.entity.actions.filter(action => action.name === 'select-quote').first()"
                                               :action="supplier.entity.actions.filter(action => action.name === 'select-quote').first()"
                                               :actionKey="'select-quote'"
                                               :onSuccessPath="$router.currentRoute.fullPath"
                                               additionalButtonClasses="mx-2"
                                               buttonType="primary"
                                               :firstButtonTitle="supplier.entity.actions.filter(action => action.name === 'select-quote').first().title"
                            ></select-quote-form>
                            <confirmation-action v-if="supplier.entity.actions.filter(action => action.name === 'use-quote').first()"
                                :action="supplier.entity.actions.filter(action => action.name === 'use-quote').first()"
                                action-key="use-quote"
                                :onSuccessPath="$router.currentRoute.fullPath"
                                additionalButtonClasses="mx-2"
                                buttonType="primary">
                            </confirmation-action>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import ReconcileOrderForm from './ReconcileOrderForm';
    import DownloadPdf from "../pages/DownloadPdf";
    import DeleteInvoiceInConfirmation from "./DeleteInvoiceInConfirmation";
    import ActionWithForm from "./ActionWithForm";
    import ActionWithConfirmation from "./ActionWithConfirmation";
    import DownloadPdfIconWithLink from "./DownloadPdfIconWithLink";
    import SelectQuoteForm from "@/v2/components/forms/SelectQuoteForm.vue";
    import ConfirmationAction from "@/v2/components/ConfirmationAction.vue";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                submittingByCard: false,
                invoiceFile: null,
                showPDF:false,
                quoteValue: null,
                includesVat:null
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        components: {
            ConfirmationAction,
            SelectQuoteForm,
            DownloadPdfIconWithLink,
            ReconcileOrderForm,
            DownloadPdf,
            DeleteInvoiceInConfirmation,
            ActionWithConfirmation,
        },
        computed: {
            suppliers() {
                let suppliers = this.response.body.entities.filter(entity => entity.rels.contains('quote-sent-to'));
                return suppliers;
            },
        },
        mounted(){
            let quote =  this.response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("quote");
                })
                .first();
            if(quote){
                this.includesVat = quote.entity.properties.get('includes_vat')
                this.quoteValue = parseFloat(quote.entity.properties.get('total').replace('£', ''))
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
