<template>
    <div :key="$route.fullPath" class="h-full order-container">
<!--        <top-header></top-header>-->
        <main-template>
            <fetch-root-siren :link="link" @unauthorised="displayError" >
            <div slot-scope="{ response }" v-if="response">
                <order-header :response="response"></order-header>

                <div class="grid grid-cols-4 min-h-screen">


                    <div
                        class="col-span-4 lg:col-span-3 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">

                        <refund-requests-review :response="response"></refund-requests-review>

                        <div class="container mx-auto px-4 mb-4" v-if="response.body.properties.get('rejection_note')">
                            <div class="rounded-md bg-yellow-50 p-4 shadow">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <svg class="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd"
                                                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                    </div>
                                    <div class="ml-3">
                                        <h3 class="text-sm leading-5 font-medium text-yellow-800">
                                            Rejected
                                        </h3>
                                        <div class="mt-2 text-sm leading-5 text-yellow-700">
                                            <p>
                                                {{response.body.properties.get('rejection_note')}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <order-summary :response="response" ref="orderSummary" @change="change"></order-summary>
                        <selected-quote :response="response"></selected-quote>
                        <provisional-quote :response="response"></provisional-quote>
                        <invoices :response="response"></invoices>
                        <invoices-waiting-approval-table-list :response="response"></invoices-waiting-approval-table-list>
                        <invoices-rejected-table-list :response="response"></invoices-rejected-table-list>
                        <order-credit-notes :response="response"></order-credit-notes>
                        <order-items :response="response" ref="orderItems" @change="change"></order-items>
                        <order-items :response="response" ref="orderItems" @change="change"></order-items>
                        <goods-in-note-table :response="response"></goods-in-note-table>
                    </div>
                    <div class="bg-gray-100 border border-gray-200 border-b-0 border-r-0 p-6 hidden lg:block">
                        <order-user-information :response="response"></order-user-information>
                        <order-activity-feed :response="response"></order-activity-feed>
                    </div>
                </div>
            </div>
        </fetch-root-siren>
        </main-template>
        <div v-if="hasChangesWaitingOnSave"
             class="p-3 fixed bottom-0 w-full bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
            <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                Order
            </primary-button>
        </div>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import FilterablePaginatedList from '../components/FilterablePaginatedOrdersList';
    import OrderHeader from '../components/OrderHeader';
    import OrderSummary from '../components/OrderSummary';
    import OrderItems from '../components/OrderItems';
    import SelectedQuote from '../components/SelectedQuote';
    import PrimaryButton from '../components/PrimaryButton';
    import OrderUserInformation from '../components/OrderUserInformation';
    import OrderActivityFeed from '../components/OrderActivityFeed';
    import RefundRequestsReview from '../components/RefundRequestsReview';
    import MainTemplate from "../templates/MainTemplate";
    import Invoices from '../components/InvoicesTableList';
    import OrderCreditNotes from "@/components/CreditNotesTableList";
    import GoodsInNoteTable from "@/components/GoodsInNoteTable";
    import InvoicesWaitingApprovalTableList from "@/components/InvoicesWaitingApprovalTableList";
    import InvoicesRejectedTableList from "@/components/InvoicesRejectedTableList";
    import ProvisionalQuote from "@/components/ProvisionalQuote";

    export default {
        inject: ["$validator"],
        data() {
            return {
                hasChangesWaitingOnSave: false,
                loading: false
            }
        },
        components: {
            PrimaryButton,
            FetchRootSiren,
            FilterablePaginatedList,
            OrderHeader,
            OrderSummary,
            OrderItems,
            SelectedQuote,
            OrderUserInformation,
            OrderActivityFeed,
            RefundRequestsReview,
            MainTemplate,
            Invoices,
            OrderCreditNotes,
            GoodsInNoteTable,
            InvoicesWaitingApprovalTableList,
            InvoicesRejectedTableList,
            ProvisionalQuote
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            }
        },
        methods: {
            async saveData() {
                this.loading = true;
                this.$refs.orderSummary.updateOrder().then(res => {
                    this.$refs.orderItems.updateItems().then(res => {
                        this.$store.dispatch(
                            "setMessage",
                            'The order was saved successfully'
                        );
                        this.hasChangesWaitingOnSave = false;
                        this.loading = false;
                        this.$router.go(0);
                    }).catch(error => {
                        alert('There was an error processing your request.' + error.error);
                        this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                        this.loading = false;
                    })
                }).catch(error => {
                    // alert('There was an error processing your request.' + error);
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            displayError(){
                alert('You do not have access to view this order.');
                this.$router.push('/orders');
            }
        },
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1024px) {
        .order-container {
            background: linear-gradient(to right, white, white 50%, #f4f5f7 50%);
        }
    }
</style>
