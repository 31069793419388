<template>
    <div class="grid grid-cols-4 min-h-screen gap-4 m-4">
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                            QA Information
                        </h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">
                            Quality Assessment Details
                        </p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Work By
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{response.body.properties.get('subcontract_name')}}
                                </dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Project &amp; Location
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{response.body.properties.get('project_name')}} {{response.body.properties.get('contract_name')}} <span class="text-xs text-gray-400">( {{response.body.properties.get('location')}} )</span>
                                </dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Works done according to drawings
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{response.body.properties.get('work_done_according_to_drawings_comment') ? response.body.properties.get('work_done_according_to_drawings_comment') : 'N/A'}}
                                </dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">
                                    Outstanding works and notes
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    {{response.body.properties.get('additional_notes') ? response.body.properties.get('additional_notes') : 'N/A'}}
                                </dd>
                            </div>
                            <div class="sm:col-span-2">
                                <dt class="text-sm font-medium text-gray-500">
                                    Questions & Answers
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
                                        <li v-for="(question, index) in response.body.properties.get('questions')" :key="question.question" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div class="w-0 flex-1 flex items-center">
                                                <span class="ml-2 flex-1 w-0 truncate">
                                                  {{ question.question }}<span v-if="question.is_required" class="text-red-500 ml-1">*</span>
                                                </span>
                                            </div>
                                            <div class="ml-4 flex-shrink-0">
                                                <svg v-if="response.body.properties.get('answers')[index]" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                                                <svg v-else xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
                                            </div>
                                        </li>
                                    </ul>
                                </dd>
                            </div>
                            <div class="sm:col-span-2">
                                <dt class="text-sm font-medium text-gray-500">
                                    On site confirmation image
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <div class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                                        <a :href="response.body.properties.get('on_site_confirmation_image')" class="pointer-events-none">
                                            <img :src="response.body.properties.get('on_site_confirmation_image')" alt="" class="group-hover:opacity-75 object-cover pointer-events-none  rounded-lg">
                                        </a>
                                        <span class="sr-only">View details for IMG_5214.HEIC</span>
                                    </div>
                                </dd>
                            </div>
                            <div class="sm:col-span-2">
                                <dt class="text-sm font-medium text-gray-500">
                                    Construction Drawing
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <div class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                                        <a :href="response.body.properties.get('construction_drawing')" class="pointer-events-none">
                                            <img :src="response.body.properties.get('construction_drawing')" alt="" class="group-hover:opacity-75 object-cover pointer-events-none  rounded-lg">
                                        </a>
                                        <span class="sr-only">View details for IMG_5214.HEIC</span>
                                    </div>
                                </dd>
                            </div>
                            <div class="sm:col-span-2" v-for="(additionalImage, index) in response.body.properties.get('additional_images')">
                                <dt class="text-sm font-medium text-gray-500">
                                    Additional Image {{index+1}}
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <div class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                                        <a :href="additionalImage" class="pointer-events-none">
                                            <img :src="additionalImage" alt="" class="group-hover:opacity-75 object-cover pointer-events-none  rounded-lg">
                                        </a>
                                        <span class="sr-only">View details for IMG_5214.HEIC</span>
                                    </div>
                                </dd>
                            </div>
                            <div v-if="response.body.properties.get('signed_qa')" class="sm:col-span-2">
                                <dt class="text-sm font-medium text-gray-500">
                                    Signed QA
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <div class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                                        <a :href="response.body.properties.get('signed_qa')" class="pointer-events-none">
                                            <img :src="response.body.properties.get('signed_qa')" alt="" class="group-hover:opacity-75 object-cover pointer-events-none  rounded-lg">
                                        </a>
                                        <span class="sr-only">View details for IMG_5214.HEIC</span>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>

                </div>
            </section>


            <!-- Comments-->
            <section aria-labelledby="notes-title">
                <div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                    <div class="divide-y divide-gray-200">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="notes-title" class="text-lg font-medium text-gray-900">Comments</h2>
                        </div>
                        <div class="px-4 py-6 sm:px-6">
                            <span v-if="comments.length < 1" class="text-gray-500 text-sm">No comments yet.</span>

                            <ul class="space-y-8">
                                <li v-for="comment in comments">
                                    <div class="flex space-x-3">
                                        <div class="flex-shrink-0">
                                            <img class="h-10 w-10 rounded-full" v-if="comment.added_by_profile_image" :src="getprofileImageUrl(comment.added_by_profile_image)" alt="">
                                            <div v-else>
                                                <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
                                                    <span class="text-md font-medium leading-none text-white">{{initials(comment.added_by)}}</span>
                                                  </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="text-sm">
                                                <a href="#" class="font-medium text-gray-900">{{comment.added_by}}</a>
                                            </div>
                                            <div class="mt-1 text-sm text-gray-700">
                                                <p>{{comment.text}}</p>
                                            </div>
                                            <div class="mt-2 text-sm space-x-2">
                                                <span class="text-gray-500 font-medium">{{ comment.created_at }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                        <div class="flex space-x-3">
                            <div class="flex-shrink-0">
                                <img
                                    class="h-10 w-10 rounded-full" v-if="user.profile_image" :src="getprofileImageUrl(user.profile_image)">
                                <div v-else>
                                        <span class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
                                            <span class="text-md font-medium leading-none text-white">{{initials(user.name)}}</span>
                                          </span>
                                </div>
                            </div>
                            <div class="min-w-0 flex-1">
                                <div>
                                    <label for="comment" class="sr-only">About</label>
                                    <textarea id="comment" name="comment" rows="3" v-model="comment" class="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md" placeholder="Add a comment"></textarea>
                                </div>
                                <div class="mt-3 flex items-center justify-between">
                                    <span></span>
                                    <button @click="addComment(response)" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                        Comment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <section aria-labelledby="timeline-title" class="lg:col-start-4 lg:col-span-1">
            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Timeline</h2>

                <!-- Activity Feed -->
                <div class="mt-6 flow-root">
                    <ul class="-mb-8">
                        <li v-for="(activity, index) in response.body.properties.get('human_readable_activity_stream')">
                            <div class="relative pb-8">
                                <span v-if="index+1 !== response.body.properties.get('human_readable_activity_stream').length" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                <div class="relative flex space-x-3">
                                    <div>
                                                          <span class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                                            <!-- Heroicon name: solid/user -->
                                                            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                              <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                                                            </svg>
                                                          </span>
                                    </div>
                                    <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                        <div>
                                            <p class="text-sm text-gray-500">{{activity.description}} <a href="#" class="font-medium text-gray-900">{{activity.actioned_by}}</a></p>
                                        </div>
                                        <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                            <time datetime="2020-09-20">{{activity.date}}</time>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mt-6 flex flex-col justify-stretch" v-if="approveAction">
                    <action-with-confirmation :action="approveAction" :isPositiveAction="true" additionalButtonClasses="w-full justify-center py-4" class="w-full" :onSuccessPath="$route.path">
<!--                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" class="fill-current mr-1" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/></svg>-->
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" class="fill-current mr-1" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                    </action-with-confirmation>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ActionWithConfirmation from "../ActionWithConfirmation";
import Siren from "super-siren";
import {mapGetters} from "vuex";
export default {
    components:{ActionWithConfirmation},
    props: {
        response: {
            type: Object
        },
        commentsOverride: {
            type: Array
        },
        comment: ''
    },
    data() {
        return {
            currentUser: null
        }
    },
    computed: {
        comments() {
            if(this.commentsOverride){
                return this.commentsOverride;
            }
            return this.response.body.properties.get('comments_stream');
        },
        approveAction(){
            if(!this.response){
                return null;
            }

            return this.response.body.actions.filter(action => action.name === 'approve-qa').first();
        },

        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
    },
    methods: {
        addComment(response) {
            console.log(this.comment);
            return response.body.actions.filter(action => action.name === 'add-comment').first().perform({
                'comment' : this.comment
            }).then(res => {
                this.commentsOverride = res.body.properties.get('comments_stream');
                this.comment = '';
            });
        },
        initials(name) {
            if (name) {
                return name
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        getprofileImageUrl(url){
            return process.env.VUE_APP_API_URL + '/storage/profile_images/' + url;
        }
    },

}
</script>

<style scoped>

</style>
