<template>
    <div class="order-summary" v-if="response">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div class="generic-summary p-4 bg-white shadow sm:rounded-lg" :key="key">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">User Summary...</h3>

            <div class="grid grid-cols-1 gap-4">
                <property-or-field @input="change" :properties="properties" title="First Name" :action="updateAction" fieldId="first_name" propertyKey="first_name" v-model="updateActionData['first_name']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Last Name/ Family Name" :action="updateAction" fieldId="surname" propertyKey="surname" v-model="updateActionData['surname']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Email" :action="updateAction" fieldId="email" propertyKey="email" v-model="updateActionData['email']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Contact Number" :action="updateAction" fieldId="contact_number" propertyKey="contact_number" v-model="updateActionData['contact_number']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Projects" :action="updateAction" fieldId="project_ids" propertyKey="projects" v-model="updateActionData['project_ids']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Address" :action="updateAction" fieldId="address" propertyKey="address" v-model="updateActionData['address']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Costing Projects" :action="updateAction" fieldId="costing_project_ids" propertyKey="projects" v-model="updateActionData['costing_project_ids']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Contract Start Date" :action="updateAction" fieldId="contract_start_date" propertyKey="contract_start_date" v-model="updateActionData['contract_start_date']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Holidays Per Year" :action="updateAction" fieldId="holidays_per_year" propertyKey="holidays_per_year" v-model="updateActionData['holidays_per_year']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Exclude From Address Book" :action="updateAction" fieldId="exclude_from_address_book" propertyKey="exclude_from_address_book" v-model="updateActionData['exclude_from_address_book']"></property-or-field>

                </div>
        </div>
        <div>
          <div class="grid gap-4 grid-cols-3">
            <div class="border md:rounded-lg bg-white grid grid-cols-3 col-span-3 section-links">
<!--              <div class="p-6" style="min-height: 200px;">-->
<!--                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full">-->

<!--                  <div>-->
<!--                    <p class="text-xl font-bold w-full text-secondary-darkblue ">Roles & Permissions</p>-->

<!--                  </div>-->
<!--                </a>-->
<!--              </div>-->
            <div class="p-6" style="min-height: 200px;">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full cursor-pointer" @click.prevent="changeCurrentView('permissions')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 256c35.35 0 64-28.65 64-64s-28.65-64-64-64S128 156.7 128 192S156.7 256 192 256zM320 0H64C28.65 0 0 28.65 0 64v384c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V64C384 28.65 355.3 0 320 0zM336 448c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V64c0-8.822 7.178-16 16-16h256c8.822 0 16 7.178 16 16V448zM224 288H160c-44.18 0-80 35.82-80 80C80 376.8 87.16 384 96 384h192c8.836 0 16-7.164 16-16C304 323.8 268.2 288 224 288z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Roles & Permissions</p>
                        <!--                        <p>View address book</p>-->
                    </div>
                </a>
            </div>
              <div class="p-6" style="min-height: 200px;">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full cursor-pointer" @click.prevent="changeCurrentView('leave')">
                  <div>
                    <p class="text-xl font-bold w-full text-secondary-darkblue ">Leave</p>
                    <p>
                      Holidays Used: {{holidaysUsed}}<br>
                      Holidays Remaining: {{holidaysRemaining}}<br>
                      Unpaid Leave: {{unpaidLeave}}
                    </p>
                  </div>
                </a>
              </div>
              <div class="p-6" style="min-height: 200px;">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full">

                  <div>

                  </div>
                </a>
              </div>
              <div class="p-6" style="min-height: 200px;">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full cursor-pointer" @click.prevent="changeCurrentView('accounts')">
                  <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                    <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M257.1 128h-52C167 128 136 159 136 198c0 13 11 24 24 24s24-11 24-24C184 186 193.1 176 205.1 176h52C269.1 176 280 186 280 198c0 8-4 15-11 19L212 251C204 256 200 264 200 272V288c0 13 11 24 24 24S248 301 248 288V286l45.1-28C315 245 328 222 328 198C328 159 297 128 257.1 128zM224 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S241.1 336 224 336zM384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM400 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h320c8.822 0 16 7.178 16 16V416z"/></svg>
                  </div>

                  <div>
                    <p class="text-xl font-bold w-full text-secondary-darkblue ">Accounts</p>
                    <!--                        <p>View RFIs</p>-->
                  </div>
                </a>
              </div>
              <div class="p-6" style="min-height: 200px;" v-if="addCertificateAction || certificates.count() > 0">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full cursor-pointer" @click.prevent="changeCurrentView('documents')">
                  <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                    <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 256c35.35 0 64-28.65 64-64s-28.65-64-64-64S128 156.7 128 192S156.7 256 192 256zM320 0H64C28.65 0 0 28.65 0 64v384c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V64C384 28.65 355.3 0 320 0zM336 448c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V64c0-8.822 7.178-16 16-16h256c8.822 0 16 7.178 16 16V448zM224 288H160c-44.18 0-80 35.82-80 80C80 376.8 87.16 384 96 384h192c8.836 0 16-7.164 16-16C304 323.8 268.2 288 224 288z"/></svg>
                  </div>

                  <div>
                    <p class="text-xl font-bold w-full text-secondary-darkblue">Documents</p>
                    <!--                        <p>View address book</p>-->
                  </div>
                </a>
              </div>
                <div class="p-6" style="min-height: 200px;" v-if="payNSends.count() > 0">
                    <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full cursor-pointer" @click.prevent="changeCurrentView('payslips')">
                        <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                            <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M64 464H320c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm80 48c0-8.8 7.2-16 16-16h80c8.8 0 16 7.2 16 16s-7.2 16-16 16H96c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h80c8.8 0 16 7.2 16 16s-7.2 16-16 16H96c-8.8 0-16-7.2-16-16zM192 400c0-8.8 7.2-16 16-16h80c8.8 0 16 7.2 16 16s-7.2 16-16 16H208c-8.8 0-16-7.2-16-16zM112 224H272c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H112c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32z"/></svg>
                        </div>

                        <div>
                            <p class="text-xl font-bold w-full text-secondary-darkblue">Slip Sends</p>
                        </div>
                    </a>
                </div>
            </div>
          </div>
        </div>
    </div>

      <div class="generic-summary p-4 bg-white shadow sm:rounded-lg mt-8" :key="key" v-if="currentView=== 'accounts'">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Accounts</h3>

        <div class="grid grid-cols-3 gap-4">
          <property-or-field @input="change" :properties="properties" title="Payment Type" :action="updateAction" fieldId="payment_type" propertKey="payment_type" v-model="updateActionData['payment_type']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Payment Type Rate" :action="updateAction" fieldId="hourly_rate" propertKey="hourly_rate" v-model="updateActionData['hourly_rate']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Overtime/Day Pay Rate" :action="updateAction" fieldId="overtime_rate" propertKey="overtime_rate" v-model="updateActionData['overtime_rate']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Unique Taxpayer Reference" :action="updateAction" fieldId="unique_taxpayer_reference" propertKey="unique_tax_payer_reference" v-model="updateActionData['unique_taxpayer_reference']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Date Of Birth" :action="updateAction" fieldId="date_of_birth" propertyKey="date_of_birth" v-model="updateActionData['date_of_birth']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="National Insurance Number" :action="updateAction" fieldId="national_insurance_number" propertyKey="national_insurance_number" v-model="updateActionData['national_insurance_number']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Company Type" :action="updateAction" fieldId="company_type" propertyKey="company_type" v-model="updateActionData['company_type']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Vat Number" :action="updateAction" fieldId="vat_number" propertyKey="vat_number" v-model="updateActionData['vat_number']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Company name" :action="updateAction" fieldId="company_name" propertyKey="company_name" v-model="updateActionData['company_name']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Company Address" :action="updateAction" fieldId="company_address" propertyKey="company_address" v-model="updateActionData['company_address']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Company Registration Number" :action="updateAction" fieldId="company_registration_number" propertyKey="company_registration_number" v-model="updateActionData['company_registration_number']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Tax Rate" :action="updateAction" fieldId="tax_rate" propertyKey="tax_rate" v-model="updateActionData['tax_rate']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Retention" :action="updateAction" fieldId="retention" propertyKey="retention" v-model="updateActionData['retention']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Retention Period" :action="updateAction" fieldId="retention_period" propertyKey="retention_period" v-model="updateActionData['retention_period']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Saved Retentions" :action="updateAction" fieldId="saved_retentions" propertyKey="saved_retentions" v-model="updateActionData['saved_retentions']"></property-or-field>
            <property-or-field @input="change" :properties="properties" title="Self Employed" :action="updateAction" fieldId="self_employed" propertyKey="self_employed" v-model="updateActionData['self_employed']"></property-or-field>
            <property-or-field @input="change" :properties="properties" title="Right To Work" :action="updateAction" fieldId="right_to_work" propertyKey="right_to_work" v-model="updateActionData['right_to_work']"></property-or-field>

          <template v-if="properties.get('certificate_of_incorporation')">
            <div>
              <label class="block text-sm font-medium leading-5 text-gray-500 mb-0 label-a"
              >Copy Of Certificate Of Incorporation
              </label>
              <div class="form-group">
                <a class="text-sm text-gray-700 underline" v-if="properties.get('certificate_of_incorporation')" href="#" @click.prevent="showPDF = true">Download certificate of incorporation ({{properties.get('certificate_of_incorporation')}})</a>
                <template v-if="showPDF">
                  <download-pdf :url="certificateOfIncorporationUrl" @removeFromDisplay="showPDF = false"></download-pdf>
                </template>
              </div>
            </div>
          </template>

          <property-or-field @input="change" :properties="properties" title="Registered Partner Name" :action="updateAction" fieldId="partner_name" propertyKey="partner_name" v-model="updateActionData['partner_name']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Partner Unique Taxpayer reference" :action="updateAction" fieldId="partner_unique_taxpayer_reference" propertyKey="partner_unique_taxpayer_reference" v-model="updateActionData['partner_unique_taxpayer_reference']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Accounting Code" :action="updateAction" fieldId="accounting_code" propertyKey="accounting_code" v-model="updateActionData['accounting_code']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Group" :action="updateAction" fieldId="group_id" propertyKey="group_id" v-model="updateActionData['group_id']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Site Times Apply" :action="updateAction" fieldId="site_times_apply" propertyKey="site_times_apply" v-model="updateActionData['site_times_apply']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Department" :action="updateAction" fieldId="department" propertyKey="department" v-model="updateActionData['department']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Approval Departments" :action="updateAction" fieldId="payment_voucher_approval_department_ids" propertyKey="payment_voucher_approval_department_ids" v-model="updateActionData['payment_voucher_approval_department_ids']"></property-or-field>
          <property-or-field @input="change" :properties="properties" title="Payment Voucher Paid Email" :action="updateAction" fieldId="payment_voucher_email" propertyKey="payment_voucher_email" v-model="updateActionData['payment_voucher_email']"></property-or-field>


        </div>
         <saved-retention-table :response="response" ref="savedretention"></saved-retention-table>


      </div>
      <div class="generic-summary p-4 bg-white shadow sm:rounded-lg mt-8" :key="key" :class="{'hidden': currentView!=='permissions'}">
          <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Roles</h3>

          <div class="grid grid-cols-3 gap-4">
            <property-or-field @input="change" :properties="properties" title="" :action="updateAction" fieldId="role_ids" propertyKey="roles" v-model="updateActionData['role_ids']"></property-or-field>
          </div>
        <div class="grid grid-cols-1 gap-4">
            <user-permissions v-show="currentView==='permissions'" @input="change" @valueNotChanged="valueNotChanged" :properties="properties" :field="permissionField" v-model="updateActionData['permission_ids']"></user-permissions>
        </div>
      </div>
    </div>
</template>

<script>
    import PropertyOrField from '../PropertyOrField';
    import Certificates from "@/components/Certificates";
    import DownloadPdf from "@/pages/DownloadPdf";
    import UserPermissions from "@/components/Tables/UserPermissions";
    import SavedRetentionTable from "@/components/SavedRetentionTable";
    export default {
        inject: ["$validator"],
        components: {
            UserPermissions,
            PropertyOrField,
            Certificates,
            DownloadPdf,
            SavedRetentionTable
        },
        data() {
            return {
                key : 0,
                showPDF: false,
                currentView: 'accounts'
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
          holidaysUsed: {
              default: 0
          }
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update-user').first();
            },
            permissionField() {
                return this.updateAction.fields.filter(field => field.name === 'permission_ids').first();
            },
            addCertificateAction() {
                return this.response.body.actions.filter(action => action.name === 'add-certificate').first();
            },
            certificates() {
              if (!this.response) {
                return null;
              }
              return this.response.body.entities.filter(entity => entity.rels.contains('certificate'));
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            properties(){
                console.log(this.response.body.properties);
                return this.response.body.properties;
            },
            addCertificateActionData() {
                var vm = this;
                if (!this.addCertificateAction) {
                    return {};
                }
                if (!this.addCertificateAction.fields) {
                    return {};
                }
                var payload = {};
                this.addCertificateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            certificateOfIncorporationUrl(){
                return process.env.VUE_APP_API_URL + '/api/files/' + this.properties.get('certificate_of_incorporation');
            },
            unpaidLeave(){
                return this.response.body.properties.get('unpaid_leave');
            },
            holidaysRemaining(){
                return this.response.body.properties.get('holidays_remaining')
            },
            payNSends() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('pay_n_send'));
            },
        },
        methods: {
            update(){
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            },
            valueNotChanged(){
                this.$emit('valueNotChanged');
            },
            changeCurrentView(name) {
              this.currentView = name;
              this.$emit('currentViewChanged', name);

            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
