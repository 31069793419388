<template>
    <div class="order-summary">
        <div class="flex flex-col">
            <div class="generic-summary p-4 bg-white" :key="key">
                <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <property-or-field @input="change" :properties="properties" title="Send Operative Jobs List" :action="updateAction" fieldId="send_jobs_list" v-model="updateActionData['send_jobs_list']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Allow Images" :action="updateAction" fieldId="allow_images" v-model="updateActionData['allow_images']"></property-or-field>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertyOrField from "@/components/PropertyOrField";
export default {
    name: "ClientPreChecksForm",
    inject: ["$validator"],
    data(){
        return{
            key: 0
        }
    },
    components:{
        PropertyOrField
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        updateAction() {
            return this.response.body.actions.filter(action => action.name === 'update-worxs').first();
        },
        updateActionData(){
            var that = this;
            if(!this.updateAction){
                return {};
            }
            if(!this.updateAction.fields){
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function(field){
                if (field.value !== undefined ) console.log(field.value); payload[field.name] = field.value;
            });
            return payload;
        },
        properties(){
            return this.response.body.properties;
        }
    },
    methods: {
        change(){
            this.$emit('change');
        },
        update(){
            return this.updateAction.perform(this.updateActionData);
        },
    }
}
</script>

<style scoped>

</style>
