import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';
import VuexPersistence from 'vuex-persist';
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const STORE = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state,
  actions,
  getters,
  mutations,
});

export default STORE;
