<template>
    <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
        <template v-if="cellKey === 'status'">
            <status-badge :status="field" ></status-badge>
        </template>
        <template v-else-if="cellKey ==='invoice_pdf'">
            <template v-if="showPDF">
                <download-pdf :url="field" @removeFromDisplay="showPDF = false"></download-pdf>
            </template>
            <a href="#" @click.prevent="showPDF = true" target="_blank">
                <svg class="w-6 h-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
            </a>
        </template>
        <template v-else-if="cellKey === 'uploaded'">
            <template v-if="field">
                <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
            </template>
            <template v-else>
                <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
            </template>
        </template>
        <template v-else-if="cellKey === 'linked_invoices'">
            <a class="underline hover:no-underline" :href="`/invoices/${field['uuid']}`">{{field['reference']}} - {{field['status']}}</a>
        </template>
        <template v-else>
            <span v-html="field"></span>
        </template>
    </td>
</template>

<script>
    import DownloadPdf from "../../pages/DownloadPdf";
    import StatusBadge from "../StatusBadge";
export default {
    data() {
        return {
            showPDF: false
        }
    },
    components:{
        DownloadPdf,
        StatusBadge
    },
    props: {
        field: {
            type: String,
        },
        cellKey:{
            type: String
        }
    },
}
</script>

