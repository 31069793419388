<template>
    <div>
        <div v-if="logo"><img :class="classForImg" :src="logo" />
            <span class="text-xs text-gray-400 text-right w-full block px-1 pt-1">Powered by CMS Hub.</span>
        </div>
        <img v-else :class="classForImg" src="../../images/logo.png" alt="CMS Hub">
    </div>
</template>
<script>
    import Siren from "super-siren";
    import {mapGetters} from "vuex";
    export default {
        data() {
            return {
                logo:null
            }
        },
        props: {
            classForImg: {
                type: String,
            },
        },
        async created() {
            let follow = Siren.Helper.follow;

            await Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(
                    follow("current-user")
                )
                .then(res => {
                    let siren = res.body;
                    let client = siren.embeddedEntities.filter(entity => entity.entity.classes.contains('client')).first();
                    if (client !== undefined){
                        let logo = client.entity.properties.get('logo');
                        this.logo = logo;
                        if (logo && logo.startsWith('public/')){
                            this.logo = this.logo.replace('public/', '');
                        }
                        this.logo = process.env.VUE_APP_API_URL + '/storage/logo/' + this.logo
                    }

                    let directSupplier = siren.embeddedEntities.filter(entity => entity.entity.classes.contains('direct-supplier')).first();
                    if(directSupplier !== undefined){
                        let logo = directSupplier.entity.properties.get('logo');
                        this.logo = logo;
                        if (logo.startsWith('public/')){
                            this.logo = this.logo.replace('public/', '');
                        }
                        this.logo = process.env.VUE_APP_API_URL + '/storage/logo/' + this.logo;
                    }

                }, this);
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),

        }
    };
</script>
