<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link">

            <div slot-scope="{ response }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <span @click="navigateToCallOuts(response)" class="cursor-pointer">
                            Call Outs
                        </span>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('name')}}
                        </span>

                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-2">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                   Call Out Details
<!--                                                    <svg @click="displayProductDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">-->
<!--                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />-->
<!--                                                    </svg>-->
                                                </dt>
                                                <dd class="text-sm text-black font-bold">{{ response.body.properties.get('name') }}</dd>
                                            </div>

                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Operative</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('user') }}
                                                </dd>

                                            </div>

                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Requested By</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('requested_by') }}
                                                </dd>

                                            </div>

                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Description</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('description') }}
                                                </dd>

                                            </div>

                                            <div v-if="response.body.properties.get('contact_name')">
                                                <dt class="text-sm font-nromal text-gray-500">Contact Name</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('contact_name') }}
                                                </dd>

                                            </div>

                                            <div v-if="response.body.properties.get('contact_number')">
                                                <dt class="text-sm font-nromal text-gray-500">Contact Number</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('contact_number') }}
                                                </dd>

                                            </div>

                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Address</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('address') }}
                                                </dd>

                                            </div>
                                            <div v-if="response.body.properties.get('appointment_date_and_time')">
                                                <dt class="text-sm font-nromal text-gray-500">Appointment Date And Time</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ (response.body.properties.get('appointment_date_and_time')) }}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Status</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('status') }}
                                                </dd>

                                            </div>
                                            <div v-if="response.body.properties.get('start_time')">
                                                <dt class="text-sm font-nromal text-gray-500">Start Time</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ formatTime(response.body.properties.get('start_time')) }}
                                                </dd>

                                            </div>
                                            <div v-if="response.body.properties.get('end_time')">
                                                <dt class="text-sm font-nromal text-gray-500">End Time</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ formatTime(response.body.properties.get('end_time')) }}
                                                </dd>

                                            </div>

                                            <div v-if="response.body.properties.get('finished_comment')">
                                                <dt class="text-sm font-nromal text-gray-500">Comment</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('finished_comment') }}
                                                </dd>
                                            </div>
                                            <div v-for="(image, index) in response.body.properties.get('images')">
                                                <dt class="text-sm font-medium text-gray-500">
                                                    Image {{index+1}}
                                                </dt>
                                                <dd class="mt-1 text-sm text-gray-900">
                                                    <div
                                                        class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                                                        <a :href="image" class="pointer-events-none">
                                                            <img :src="image" alt=""
                                                                 class="group-hover:opacity-75 object-cover pointer-events-none  rounded-lg">
                                                        </a>
                                                    </div>
                                                </dd>
                                            </div>

<!--                                            <div>-->
<!--                                                <dt class="text-sm font-nromal text-gray-500">Image</dt>-->
<!--                                                <dd class="text-sm font-bold text-black">-->
<!--                                                    <img v-if="response.body.properties.get('image')" :src="response.body.properties.get('image')"/>-->
<!--                                                    <img v-else src="../../../images/no-image-available.png" width="200" height="200"/>-->
<!--                                                </dd>-->

<!--                                            </div>-->

                                        </dl>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <section v-if="response.body.actions.filter(action =>
                                    action.name !== 'update-call-out' && action.name !== 'start-call-out' && action.name !== 'finish-call-out' && action.name !=='download-pdf' && action.name !== 'download-invoice'
                                ).count() > 0 || response.body.properties.get('pdf_file') || response.body.properties.get('invoice_file')" class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <actions :response="response" :remainingActions="
                                response.body.actions.filter(action =>
                                    action.name !== 'update-call-out' && action.name !== 'start-call-out' && action.name !== 'finish-call-out' && action.name !=='download-pdf' && action.name !== 'download-invoice'
                                )" deletion-path="/call-outs"></actions>
                                <a-button class="w-full my-2" :colourType="response.body.actions.filter(action =>
                                    action.name !== 'update-call-out' && action.name !== 'start-call-out' && action.name !== 'finish-call-out'
                                ).size > 0 ? 'secondary' : 'primary'" v-if="response.body.properties.get('pdf_file')" @click.native="showPdf = true">View Report</a-button>
                                <a-button class="w-full my-2" :colourType="response.body.actions.filter(action =>
                                    action.name !== 'update-call-out' && action.name !== 'start-call-out' && action.name !== 'finish-call-out'
                                ).size > 0 || response.body.properties.get('pdf_file') ? 'secondary' : 'primary'" v-if="response.body.properties.get('invoice_file')" @click.native="showInvoice = true">View Invoice</a-button>
                            </div>
                        </section>
                    </div>
<!--                    <add-product-form :displayModal="displayProductDetails" :action="response.body.actions.filter(action => action.name==='update-product').first()" @removeFromDisplay="displayProductDetails=false" :onSuccessPath="$route.fullPath" :showButton="false"></add-product-form>-->

                    <download-pdf :url="response.body.properties.get('invoice_file')" v-if="response.body.properties.get('invoice_file') && showInvoice" @removeFromDisplay="showInvoice=false"></download-pdf>
                    <download-pdf :url="response.body.properties.get('pdf_file')" v-if="response.body.properties.get('pdf_file') && showPdf" @removeFromDisplay="showPdf= false"></download-pdf>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import Actions from "@/v2/components/Actions";
import ProductPricePaginatedList from "@/v2/paginatedLists/ProductPricePaginatedList.vue";
import DownloadPdf from "@/pages/DownloadPdf.vue";
import UpdateProductForm from "@/v2/components/forms/UpdateProductForm.vue";
import AddProductForm from "@/v2/components/forms/AddProductForm.vue";
import FormAction from "@/v2/components/FormAction.vue";
import moment from "moment";
export default {
    name: "CallOut",
    data() {
        return {
            displayProductDetails: false,
            showDataSheet: false,
            showPdf: false,
            showInvoice: false
        }
    },
    components: {
        FormAction,
        AddProductForm,
        UpdateProductForm,
        ProductPricePaginatedList,
        Actions,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        DownloadPdf
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToCallOuts(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('call-outs')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        formatTime(time){
            return moment(time).format('DD/MM/YYYY HH:mm:ss')
        }
    }
}
</script>

<style scoped>

</style>
