<template>
    <button title="Upload items from csv" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-50 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-blue-200 transition ease-in-out duration-150">
        <slot></slot>
        <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4"></loading>
    </button>
</template>

<script>
    import Loading from './Loading';
    export default {
        components: {
            Loading,
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
