<template>
    <nav class="bg-white shadow">
        <div class="max-w-full mx-auto px-2 sm:px-6 lg:px-8 h-20">
            <div class="relative flex justify-between h-full">
                <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div class="flex-shrink-0 flex items-center text-secondary-lightblue text-xl font-bold w-full">
                      <div class="flex justify-between w-full items-center" >
                        <div class="flex items-center w-full">
                          <slot></slot>
                        </div>
                        <div v-if="logo" style="height: 80px; max-height: 80px" class="flex items-center ml-6">
                            <a href="/" ><img height="80" style="max-height:80px" :src="logo"/></a>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Header",
    props: {
      logo: {
        type: String,
        default:null
      }
  }
}
</script>

<style scoped>

</style>
