<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full" data-cy="orders-table">
                    <thead>
                        <tr v-if="filterAction">
                            <th colspan=1000 class="p-8  bg-gray-50">
                                <costing-table-filter :action="filterAction"
                                                      :response="response" :actionBaseUrl="$route.path"
                                ></costing-table-filter>
                            </th>
                        </tr>
                        <tr>
                        <th
                            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            v-for="headingValue in headingValues"
                            :key="headingValue"
                        >{{headingValue}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.response.body.properties.get('total') === 0">
                        <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                No costings found.
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="(companyEntity, key) in companyEntities">
                            <tr @click="navigateToItem(companyEntity)" class="bg-white hover:bg-gray-100 cursor-pointer" :class="{'bg-gray-50': !(key % 2 == 0), 'bg-red-100': getPropertiesToDisplay(companyEntity).project}">
                                <template v-for="(field, key) in getPropertiesToDisplay(companyEntity)">
                                    <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                        <template v-if="key === 'status'">
                                            <status-badge :status="field" ></status-badge>
                                        </template>
                                        <template v-else>{{field}}</template>
                                    </td>
                                </template>
                            </tr>
                            <tr @click="navigateToSite(site)" v-for="(site, index) in companyEntity.entity.properties.get('sites')" class="bg-white hover:bg-gray-100 cursor-pointer" :class="{'bg-gray-50': !(index % 2 == 0)}">
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    {{site['site_name']}}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    {{site['quotes_total']}}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    {{site['unpaid_total']}}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    {{site['invoices_total']}}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    {{site['rentals_total']}}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    £0.00
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    {{site['payment_vouchers_total']}}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    £0.00
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                    {{site['overall_total']}}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    </table>
                    <pagination
                        :response="response"
                        @navigate="paginationNavigation($event)"
                        @navigateAdditionalNext="paginationNavigation($event)"
                        @navigateAdditionalPrev="paginationNavigation($event)"
                    />
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatusBadge from "./StatusBadge";
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    import CostingTableFilter from "@/components/CostingTableFilter";
    export default {
        components: {
            StatusBadge,
            Pagination,
            GenericTableFilter,
            CostingTableFilter
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            companyEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('costing'));
            },
            headingValues() {
                return ['Name', 'Ordered', 'Unpaid Orders', 'Invoiced', 'Rentals', 'Subcontracted', 'Vouchers', 'Day Work', 'Total'];
            }
        },
        methods: {
            getPropertiesToDisplay(companyEntity) {
                if (companyEntity.entity.properties.get('site_name') !== undefined) {
                    return {
                        'site': companyEntity.entity.properties.get('site_name'),
                        'quotes_total': companyEntity.entity.properties.get('quotes_total'),
                        'unpaid_total': companyEntity.entity.properties.get('unpaid_total'),
                        'invoices_total': companyEntity.entity.properties.get('invoices_total'),
                        'rentals_total' : companyEntity.entity.properties.get('rentals_total'),
                        'subcontracted_total': '£0.00',
                        'priced_work_total': companyEntity.entity.properties.get('payment_vouchers_total'),
                        'day_work_total': '£0.00',
                        'overall_total': companyEntity.entity.properties.get('overall_total')
                    };
                }else if (companyEntity.entity.properties.get('project_name') !== undefined){
                    return {
                        'project': companyEntity.entity.properties.get('project_name'),
                        'quotes_total': companyEntity.entity.properties.get('quotes_total'),
                        'unpaid_total': companyEntity.entity.properties.get('unpaid_total'),
                        'invoices_total': companyEntity.entity.properties.get('invoices_total'),
                        'rentals_total' : companyEntity.entity.properties.get('rentals_total'),
                        'subcontracted_total': '£0.00',
                        'priced_work_total': companyEntity.entity.properties.get('payment_vouchers_total'),
                        'day_work_total': '£0.00',
                        'overall_total': companyEntity.entity.properties.get('overall_total')
                    }
                }else{
                   return {
                       'final_total': companyEntity.entity.properties.get('final_total'),
                       'quotes_total': companyEntity.entity.properties.get('quotes_total'),
                       'unpaid_total': companyEntity.entity.properties.get('unpaid_total'),
                       'invoices_total': companyEntity.entity.properties.get('invoices_total'),
                       'rentals_total' : companyEntity.entity.properties.get('rentals_total'),
                       'subcontracted_total': '£0.00',
                       'priced_work_total': companyEntity.entity.properties.get('payment_vouchers_total'),
                       'day_work_total': '£0.00',
                       'overall_total': companyEntity.entity.properties.get('overall_total')
                   }
                }
            },
            navigateToItem(companyEntity) {
                let selfLink = companyEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            },
            navigateToSite(site) {
                let selfLink = site['site_link']
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            },
            getProjectName(companyEntity) {
                this.projectName = companyEntity.entity.properties.get('project_name')
                return this.projectName;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
