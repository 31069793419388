<template>
    <div>
        <a-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colourType="buttonType">
            {{action.title}}
        </a-button>
        <a v-else class="cursor-pointer font-normal underline text-gray-400"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">{{action.title}}</a>
        <portal to="destination">
            <modal v-if="displayModal" :submitting="submittingModal" :title="action.title" :confirmationString='finalConfirmationString' @removeFromDisplay="displayModal = false" @perform="perform()"></modal>
        </portal>
    </div>
</template>

<script>
    import Button from '@/v2/buttons/Button';
    import Modal from '@/v2/components/Modal';
    import fileDownload from "js-file-download";
    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false
            }
        },
        components: {
            'a-button' : Button,
            Modal,
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            properties:{
                type: Object
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            isButton:{
                type: Boolean,
                default:true
            },
            additionalButtonClasses:{
                type:String
            },
            buttonType:{
                type: String
            }
        },
        computed: {
            finalConfirmationString() {
                var preText = '';
                if (this.action.name === 'request-update'){
                    if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                    preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
                }

                return preText + `Are you sure you want to "${this.action.title}"`
            }
        },
        methods: {
            perform() {
                this.submittingModal = true;
                var performaction = this.action.perform();
                if(this.action.name === 'export-as-excel'){
                    performaction = this.action.perform().responseType('blob');
                }
                performaction.then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    if(res.status === 204){
                        alert('No matching invoices were found.');
                        this.loading = false;
                        return;
                    }
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                    }
                    if(res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                        var fileURL = window.URL.createObjectURL(res.xhr.response);
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'export.xlsx');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    }
                    this.$store.dispatch(
                        "setMessage",
                        'The export was processed successfully'
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                }).catch(error => {
                    alert('There was an error processing your request.' + error);
                    this.displayModal = false;
                    this.submittingModal = false;
                });
            },
            messages(response) {
            return response.body.entities
                .filter(function(entity) {
                return entity.rels.contains("status-messages");
                })
                .first();
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
