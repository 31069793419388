<template>
    <div>
        <a-button :class="additionalButtonClasses" v-if="isButton" :colourType="buttonType" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>
        <a v-else class="cursor-pointer font-normal  text-gray-400"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
        :confirmationString='`Are you sure you want to \"${action.title}\"`'
        @removeFromDisplay="displayModal = false" @perform="perform()">
            <template v-if="action.fields.get('name')">
                <component :is="getFieldComponent(action.fields.get('name'))" :field="action.fields.get('name')" v-model="formData['name']">
                </component>
                <div v-show="errors.has('name')" class="text-red-600 text-sm">{{ errors.first('name') }}</div>
            </template>

            <template v-if="action.fields.get('is_tender')">
                <component :is="getFieldComponent(action.fields.get('is_tender'))" :field="action.fields.get('is_tender')" v-model="formData['is_tender']">
                </component>
                <div v-show="errors.has('is_tender')" class="text-red-600 text-sm">{{ errors.first('is_tender') }}</div>
            </template>
            <template v-if="action.fields.get('latitude')">
                <div class="flex items-center">
                    <component :is="getFieldComponent(action.fields.get('latitude'))" :field="action.fields.get('latitude')" v-model="latitude">
                    </component>
                    <location-picker-with-icon class="mt-4" :x="latitude" :y="longitude" @sendLocation="saveLocation"></location-picker-with-icon>
                </div>
                <div v-show="errors.has('latitude')" class="text-red-600 text-sm">{{ errors.first('latitude') }}</div>
            </template>

            <template v-if="action.fields.get('longitude')">
                <div class="flex items-center">
                    <component :is="getFieldComponent(action.fields.get('longitude'))" :field="action.fields.get('longitude')" v-model="longitude">
                    </component>
                    <location-picker-with-icon class="mt-4" :x="latitude" :y="longitude" @sendLocation="saveLocation"></location-picker-with-icon>
                </div>
                <div v-show="errors.has('longitude')" class="text-red-600 text-sm">{{ errors.first('longitude') }}</div>
            </template>

            <template v-if="action.fields.get('note')">
                <component :is="getFieldComponent(action.fields.get('note'))" :field="action.fields.get('note')" v-model="formData['note']">
                </component>
                <div v-show="errors.has('note')" class="text-red-600 text-sm">{{ errors.first('note') }}</div>
            </template>

            <template v-if="action.fields.get('address')">
                <component :is="getFieldComponent(action.fields.get('address'))" :field="action.fields.get('address')" v-model="formData['address']">
                </component>
                <div v-show="errors.has('address')" class="text-red-600 text-sm">{{ errors.first('address') }}</div>
            </template>

            <template v-if="action.fields.get('delivery_notifications')">
                <component :is="getFieldComponent(action.fields.get('delivery_notifications'))" :field="action.fields.get('delivery_notifications')" v-model="formData['delivery_notifications']">
                </component>
                <div v-show="errors.has('delivery_notifications')" class="text-red-600 text-sm">{{ errors.first('delivery_notifications') }}</div>
            </template>

            <template v-if="action.fields.get('sage_reference')">
                <component :is="getFieldComponent(action.fields.get('sage_reference'))" :field="action.fields.get('sage_reference')" v-model="formData['sage_reference']">
                </component>
                <div v-show="errors.has('sage_reference')" class="text-red-600 text-sm">{{ errors.first('sage_reference') }}</div>
            </template>

            <template v-if="action.fields.get('weekday_start')">
                <component :is="getFieldComponent(action.fields.get('weekday_start'))" :field="action.fields.get('weekday_start')" v-model="formData['weekday_start']">
                </component>
                <div v-show="errors.has('weekday_start')" class="text-red-600 text-sm">{{ errors.first('weekday_start') }}</div>
            </template>

            <template v-if="action.fields.get('weekday_end')">
                <component :is="getFieldComponent(action.fields.get('weekday_end'))" :field="action.fields.get('weekday_end')" v-model="formData['weekday_end']">
                </component>
                <div v-show="errors.has('weekday_end')" class="text-red-600 text-sm">{{ errors.first('weekday_end') }}</div>
            </template>

            <template v-if="action.fields.get('weekend_start')">
                <component :is="getFieldComponent(action.fields.get('weekend_start'))" :field="action.fields.get('weekend_start')" v-model="formData['weekend_start']">
                </component>
                <div v-show="errors.has('weekend_start')" class="text-red-600 text-sm">{{ errors.first('weekend_start') }}</div>
            </template>

            <template v-if="action.fields.get('weekend_end')">
                <component :is="getFieldComponent(action.fields.get('weekend_end'))" :field="action.fields.get('weekend_end')" v-model="formData['weekend_end']">
                </component>
                <div v-show="errors.has('weekend_end')" class="text-red-600 text-sm">{{ errors.first('weekend_end') }}</div>
            </template>

            <template v-if="action.fields.get('deduct_lunch')">
                <component :is="getFieldComponent(action.fields.get('deduct_lunch'))" :field="action.fields.get('deduct_lunch')" v-model="formData['deduct_lunch']">
                </component>
                <div v-show="errors.has('deduct_lunch')" class="text-red-600 text-sm">{{ errors.first('deduct_lunch') }}</div>
            </template>

            <template v-if="action.fields.get('site_rules')">
                <component :is="getFieldComponent(action.fields.get('site_rules'))" :field="action.fields.get('site_rules')" v-model="formData['site_rules']">
                </component>
                <div v-show="errors.has('site_rules')" class="text-red-600 text-sm">{{ errors.first('site_rules') }}</div>
            </template>

            <template v-if="action.fields.get('assigned_to_client_id')">
                <component :is="getFieldComponent(action.fields.get('assigned_to_client_id'))" :field="action.fields.get('assigned_to_client_id')" v-model="formData['assigned_to_client_id']">
                </component>
                <div v-show="errors.has('assigned_to_client_id')" class="text-red-600 text-sm">{{ errors.first('assigned_to_client_id') }}</div>
            </template>

            <template v-if="action.fields.get('operative_leave_approval')">
                <component :is="getFieldComponent(action.fields.get('operative_leave_approval'))" :field="action.fields.get('operative_leave_approval')" v-model="formData['operative_leave_approval']">
                </component>
                <div v-show="errors.has('operative_leave_approval')" class="text-red-600 text-sm">{{ errors.first('operative_leave_approval') }}</div>
            </template>

            <template v-if="action.fields.get('department_id')">
                <div class="rounded-md flex-grow">
                    <div class="focus-within:z-10">
                        <label for="email" class=" block text-sm font-medium text-gray-700">Departments</label>
                        <department-code-input :action="action" v-model="formData['department_codes']"></department-code-input>
                    </div>
                </div>

                <div v-show="errors.has('department_id')" class="text-red-600 text-sm">{{ errors.first('department_id') }}</div>
            </template>

            <template v-if="action.fields.get('type_id') || action.fields.get('manufacturer_id')">
                <div class="border border-black p-4 rounded-md text-black font-bold">
                    Specifications
                    <template v-if="action.fields.get('type_id')">
                        <component :is="getFieldComponent(action.fields.get('type_id'))" :field="action.fields.get('type_id')" v-model="formData['type_id']">
                        </component>
                        <div v-show="errors.has('type_id')" class="text-red-600 text-sm">{{ errors.first('type_id') }}</div>
                    </template>

                    <template v-if="action.fields.get('manufacturer_id')">
                        <component :is="getFieldComponent(action.fields.get('manufacturer_id'))" :field="action.fields.get('manufacturer_id')" v-model="formData['manufacturer_id']">
                        </component>
                        <div v-show="errors.has('manufacturer_id')" class="text-red-600 text-sm">{{ errors.first('manufacturer_id') }}</div>
                    </template>
                </div>
            </template>
        <div v-show="errors.has('items')" class="text-red-600 text-sm">{{ errors.first('items') }}</div>

        </slide-over>
    </div>
</template>

<script>
    import Button from '@/v2/buttons/Button';
    import Modal from '@/v2/components/Modal';
    import SlideOver from "@/v2/components/SlideOver";
    import fileDownload from "js-file-download";
    import LocationPickerWithIcon from "@/components/LocationPickerWithIcon";
    import DepartmentCodeInput from "@/components/Action/Fields/DepartmentCodeInput.vue";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                latitude: null,
                longitude: null
            }
        },
        components: {
            DepartmentCodeInput,
            LocationPickerWithIcon,
            SlideOver,
            'a-button': Button,
            Modal,
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            isButton:{
                type: Boolean,
                default: true
            },
            buttonType:{
                type: String,
                default: 'primary'
            },
            additionalButtonClasses:{
                type: String,
                default: null
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                let that = this;
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.value !== undefined && field.name === 'latitude') this.latitude = field.value;
                    if(field.value !== undefined && field.name === 'longitude') this.longitude = field.value;
                }.bind(this));
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.formData['latitude'] = this.latitude;
                this.formData['longitude'] = this.longitude;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if(newPath  !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        }else{
                            this.$router.go(0);
                        }
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            saveLocation(location){
                this.latitude = location.x;
                this.longitude = location.y;
                this.formData['latitude'] = location.x;
                this.formData['longitude'] = location.y;
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>
