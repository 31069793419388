<template>
    <div :key="$route.fullPath">
        <main-template>
            <template slot-scope="{logo}">
                <fetch-root-siren :linkName='linkName' :link="link">
                    <div slot-scope="{ response }">
                        <loading v-if="!response"></loading>
                        <template v-else>
                            <widget-header :response="response" :logo="logo"></widget-header>
                            <drag-drop-widgets :default="true" :editable="editable" :response="response"></drag-drop-widgets>
                        </template>
                    </div>
                </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import SavedFilters from "../components/SavedFilters";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import FilterablePaginatedDashboardWidgetsList from "../components/FilterablePaginatedDashboardWidgetsList";
    import DragDropWidgets from "../components/DragDropWidgets";
    import PageHeader from "@/v2/components/Header";
    import WidgetHeader from "@/v2/components/headers/WidgetHeader";

    export default {
        components: {
            WidgetHeader,
            MainTemplate,
            FetchRootSiren,
            Loading,
            SavedFilters,
            FilterablePaginatedDashboardWidgetsList,
            DragDropWidgets,
            PageHeader
        },
        data(){
            return{
                editable: true
            }
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
