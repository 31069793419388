import pluralize from 'pluralize';

export default {
    computed: {
        searchAction() {
            return this.response.body.actions.filter(action => action.name === 'search-products').first();
        },
    },
    methods: {
        ucfirst(str) {
            var firstLetter = str.substr(0, 1);
            return firstLetter.toUpperCase() + str.substr(1);
        },
        pluralize(str) {
            return pluralize(str);
        },
    }
}
