<template>
    <button :href=href class="cursor-pointer mt-1 group w-full flex items-center pl-2 pr-1 py-2 text-sm leading-5 font-medium rounded-md text-white hover:bg-primary-700 focus:outline-none focus:bg-primary-700 transition ease-in-out duration-150">
        <slot></slot>
        <svg v-if="hasSubMenu" :class="{'text-gray-400 rotate-90' : isOpen}" class="ml-auto h-5 w-5 transform group-hover:text-gray-400 group-focus:text-gray-400 transition-colors ease-in-out duration-150" viewBox="0 0 20 20">
            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
        </svg>
    </button>
</template>

<script>
    export default {
        props: {
            isOpen: {
                type: Boolean
            },
            hasSubMenu: {
                type: Boolean
            },
            href:{
                type:String
            }
        },
    }
</script>

<style scoped>

</style>
