<template>
    <tr class="bg-white order-item-row">
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            {{orderItemEntity.name}} - hours: {{orderItemEntity.hours}}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <span v-if="removeAction" class="cursor-pointer font-normal underline text-gray-400" @click="removeItem()">remove</span>
        </td>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from "../mixins/orderItemsUpdate";

    export default {
        inject: ["$validator"],
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            orderItemEntity: {
                type: Object,
            },
            scope: {
                type: String
            },
            response: {
                type: Object
            }
        },
        computed: {
            updateAction() {
                return this.orderItemEntity.entity.actions.filter(action => action.name === 'update').first();
            },
            removeAction() {
                //return this.orderItemEntity.entity.actions.filter(action => action.name === 'delete').first();
            },
            updateActionData() {
                if (!this.updateAction) {
                    return {};
                }
                if (!this.updateAction.fields) {
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
        },
        methods: {
            update() {
                return this.updateAction.perform(this.updateActionData);
            },
            removeItem() {
                return this.removeAction.perform().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'Item removed successfully.'
                    );
                    this.$router.go(0);
                }).catch(error => {
                    alert('There was an error processing your request.' + error);
                });
            },
            change() {
                this.$emit('change');
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
