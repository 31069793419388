<template>
    <paginated-list v-if="showInvoices" :response="res ? res : response" :actionBaseUrl="$route.path" :useEntities="true" :hasEntities="invoices && invoices.count() > 0">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Reference</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Total</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Status</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Download</th>
        </template>
        <template v-slot:noItems>
            No invoices found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in invoices" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('reference') }}
                    </div>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('grand_total') }}
                    </div>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('status') }}
                    </div>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>
                        <download-pdf-icon-with-link v-if="entity.entity.properties.get('file_path')" :url="entity.entity.properties.get('file_path')"></download-pdf-icon-with-link>
                    </div>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import Siren from "super-siren";
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import {mapGetters} from "vuex";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";

export default {
    name: "ClientInvoicePaginatedList",
    components: {
        DownloadPdfIconWithLink,
        PaginatedList
    },
    data(){
        return {
            invoices: null,
            res: null,
            showInvoices: false
        }
    },
    props:{
        response:{
            type: Object
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
    },
    mounted() {
        let follow = Siren.Helper.follow;
        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
        let invoicesLink = this.response.body.links.filter(link => link.rels.contains('invoices')).first().href;

        let link = invoicesLink.split('/api');
        Siren.get(invoicesLink).then(res => {
            this.res = res;
            this.showInvoices = true;
            this.invoices = res.body.entities;
        }, this).catch(error => {
            if(error.status === 403 || error.status === 401){
                this.showInvoices = false;
            }
        });
    },
}
</script>

<style scoped>

</style>
