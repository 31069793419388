<template>
    <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
    <div class="fixed z-10 inset-0 overflow-y-auto z-50">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" style="height:100%">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div class=" inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle min-w-90 sm:p-6" style="height:90%" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" @click="removeFromDisplay" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                        <!-- Heroicon name: x -->
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="sm:flex sm:items-start flex-col h-full w-full">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-3">
                        Search <GmapAutocomplete
                            placeholder="Search"
                            @place_changed="setPlace"
                            class="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md max-w-full"
                            style="width:500px"
                        >
                        </GmapAutocomplete>
                    </h3>
                    <div class="pt-3 text-center sm:mt-0 sm:text-left relative h-full w-full">
                        <div class="bg-white overflow-hidden h-full">
                            <div class="sm:pt-6 h-full"><div class="flex items-center h-full">
                                <div class="w-0 flex-1 h-full pb-5 sm:pb-6">
                                    <GmapMap
                                        :center="{lat:getCenterX(), lng:getCenterY()}"
                                        :zoom="zoom"
                                        map-type-id="terrain"
                                        class="h-full"
                                        style="min-height: 200px"
                                        @click="handleClick"
                                        :options="{clickableIcons :false}"
                                    >

                                        <GmapMarker v-if="positionX && positionY"
                                            :position="{lat: positionX, lng: positionY}"
                                            :clickable="true"
                                            :draggable="false"
                                        />
<!--                                        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">-->
<!--                                            {{infoContent}}-->
<!--                                        </gmap-info-window>-->
                                    </GmapMap>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline" v-if="positionX && positionY">
                        Selected Coordinates: {{positionX}}, {{positionY}}
                        <primary-button class="ml-4" @click.native="usePosition(); removeFromDisplay()">
                            Use these coordinates
                        </primary-button>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import PrimaryButton from "@/components/PrimaryButton";
    export default {
        components: {
            PrimaryButton,
        },
        props: {
            x:{
              type: Number,
                default: null
            },
            y:{
                type: Number,
                default: null
            }
        },
        data() {
            return {
                centerX: 51.509865,
                centerY: -0.118092,
                file: null,
                mimeType: null,
                positionX: this.x === null ? null : parseFloat(this.x),
                positionY: this.y === null ? null : parseFloat(this.y),
                zoom: 12
            }
        },
        created() {
            // this.downloadWithVueResource();
        },
        methods: {
            removeFromDisplay() {
                this.$emit('removeFromDisplay');
            },
            perform() {
                this.$emit('perform');
            },
            handleClick(event) {
                if (event.latLng && event.latLng.lat) {
                    this.positionX = event.latLng.lat();
                    this.positionY = event.latLng.lng();
                }
            },
            usePosition(){
                this.$emit('sendLocation', {x: this.positionX, y: this.positionY})
            },
            getCenterX(){
                if(this.positionX && this.positionY){
                    return this.positionX;
                }
                return this.centerX;
            },
            getCenterY(){
                if(this.positionX && this.positionY){
                    return this.positionY;
                }
                return this.centerY;
            },
            setPlace(place){
                this.centerX = place.geometry.location.lat();
                this.centerY = place.geometry.location.lng();
                this.zoom = 16;
                this.positionX = place.geometry.location.lat();
                this.positionY = place.geometry.location.lng();

            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        }
    }
</script>

<style scoped>
</style>
