<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900"><slot></slot></h2>
                <div class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty())"
                         class="mr-1">
                        <create-rental-form v-if="actionWithFields[0] === 'create-rental'"
                                            onSuccessPath="/rental-items"
                                              :action="actionWithFields[1]"
                                              :actionKey="actionWithFields[0]"></create-rental-form>
                        <action-with-form v-else :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="false"
                        >
                        </action-with-form>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import ActionWithForm from "../ActionWithForm";
    import CreateRentalForm from "../CustomForms/CreateRentalForm";
    import ApproveRentalForm from "../CustomForms/ApproveRentalForm";

    export default {
        inject: ["$validator"],
        components: {
            ActionWithForm,
            CreateRentalForm,
            ApproveRentalForm
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'filter')
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
