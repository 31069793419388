<template>
    <div>
        <approve-buttons :approveText="approveAction.title" :rejectText="rejectAction.title" @rejectClicked="displayRejectModal = true" @approveClicked="displayApproveModal = true"></approve-buttons>
        <portal to="destination">
            <modal v-if="displayApproveModal" :submitting="submittingApproveModal" :title="approveAction.title" :confirmationString='finalConfirmationString' @removeFromDisplay="displayApproveModal = false" @perform="performApprove()"></modal>
        </portal>
        <slide-over v-show="displayRejectModal" :submitting="submittingRejectModal" :title="rejectAction.title"
                    :confirmationString='`Are you sure you want to \"${rejectAction.title}\"`'
                    @removeFromDisplay="displayRejectModal = false" @perform="performReject()">
            <template v-for="(field, index) in rejectAction.fields">
                <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="rejectFormData[field[1].name]">
                </component>
                <div v-show="errors.has(field[1].name)" class="text-red-600">{{ errors.first(field[1].name) }}</div>
            </template>

            <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </slide-over>
    </div>
</template>

<script>
import ApproveButtons from "@/v2/buttons/ApproveButtons";
import fileDownload from "js-file-download";
import SlideOver from "@/v2/components/SlideOver";
import Modal from "@/v2/components/Modal";
export default {
    name: "QuoteApprovalAction",
    components: {
        ApproveButtons,
        SlideOver,
        Modal
    },
    data(){
        return {
            displayRejectModal: false,
            submittingRejectModal: false,
            displayApproveModal: false,
            submittingApproveModal: false
        }
    },
    props: {
        approveAction: {
            type: Object
        },
        rejectAction: {
            type: Object
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        rejectFormData() {
            if (!this.rejectAction.fields) {
                return {};
            }
            var payload = {};
            this.rejectAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        finalConfirmationString() {
            var preText = '';
            if (this.approveAction.name === 'request-update'){
                if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                    preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
            }

            return preText + `Are you sure you want to "${this.approveAction.title}"`
        }
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        performApprove(){
            this.submittingApproveModal = true;
            this.approveAction.perform().then(res => {
                    this.displayApproveModal = false;
                    this.submittingApproveModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if(newPath  !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        }else{
                            this.$router.go(0);
                        }
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingApproveModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
        },
        performReject(){
                this.submittingRejectModal = true;
                this.rejectAction.perform(this.rejectFormData).then(res => {
                    this.displayRejectModal = false;
                    this.submittingRejectModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if(newPath  !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        }else{
                            this.$router.go(0);
                        }
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingRejectModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
    }
}
</script>

<style scoped>

</style>
