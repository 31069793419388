<template>
    <div class="order-summary">
        <div class="flex flex-col">
            <div class="generic-summary p-4 bg-white" :key="key">
                <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <property-or-field @input="change" :properties="properties" title="Drivers Name" :action="updateSVELAction" fieldId="drivers_name" v-model="updateSVELActionData['drivers_name']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Company Name" :action="updateSVELAction" fieldId="company_name" v-model="updateSVELActionData['company_name']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Registration" :action="updateSVELAction" fieldId="registration" v-model="updateSVELActionData['registration']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Miles" :action="updateSVELAction" fieldId="miles" v-model="updateSVELActionData['miles']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Photo on check in (forced)" :action="updateSVELAction" fieldId="photo_on_check_in" v-model="updateSVELActionData['photo_on_check_in']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Photo on check out (forced)" :action="updateSVELAction" fieldId="photo_on_check_out" v-model="updateSVELActionData['photo_on_check_out']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="FORS Grade" :action="updateSVELAction" fieldId="fors_grade" v-model="updateSVELActionData['fors_grade']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="FORS/CLOCKS Checklist" :action="updateSVELAction" fieldId="fors_clocks_checklist" v-model="updateSVELActionData['fors_clocks_checklist']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Licence Check" :action="updateSVELAction" fieldId="licence_check" v-model="updateSVELActionData['licence_check']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Licence check photo (forced)" :action="updateSVELAction" fieldId="licence_check_photo" v-model="updateSVELActionData['licence_check_photo']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Document & Signature" :action="updateSVELAction" fieldId="document_signature" v-model="updateSVELActionData['document_signature']"></property-or-field>
                    <property-or-field @input="change" :properties="properties" title="Site Rules" :action="updateSVELAction" fieldId="site_rules" v-model="updateSVELActionData['site_rules']"></property-or-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertyOrField from "@/components/PropertyOrField";
export default {
    name: "SiteVehicleEntryLogRequirementForm",
    inject: ["$validator"],
    data(){
        return{
            key: 0
        }
    },
    components:{
        PropertyOrField
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        updateSVELAction() {
            return this.response.body.actions.filter(action => action.name === 'update-log-requirements').first();
        },
        updateSVELActionData(){
            var that = this;
            if(!this.updateSVELAction){
                return {};
            }
            if(!this.updateSVELAction.fields){
                return {};
            }
            var payload = {};
            this.updateSVELAction.fields.forEach(function(field){
                if (field.value !== undefined) payload[field.name] = field.value;
                if(field.name == 'delivery_method'){
                    that.deliveryMethod = field.value;
                };
            });
            return payload;
        },
        properties(){
            return this.response.body.properties;
        }
    },
    methods: {
        change(){
            this.$emit('change');
        },
        update(){
            return this.updateSVELAction.perform(this.updateSVELActionData);
        },
    }
}
</script>

<style scoped>

</style>
