<template>
    <div>
        <a-button :class="additionalButtonClasses" :colourType="buttonType" @click.native="displayModal = true">{{action.title}}</a-button>

        <portal to="destination">
            <modal v-if="displayModal" :submitting="submittingModal" :title="action.title" :confirmationString='finalConfirmationString' @removeFromDisplay="displayModal = false" @perform="perform(false)" :buttonType="buttonType"></modal>
            <modal v-if="displayConfirm" title="You already have a sticker assigned" confirmation-string="Would you like to replace your current sticker with this one?"
                   @removeFromDisplay="displayConfirm=false" @perform="perform(true)" :submitting="submittingModal"></modal>
        </portal>


    </div>
</template>

<script>
import Modal from "@/v2/components/Modal";
import Button from "@/v2/buttons/Button"
export default {
    name: "AssignOnSiteIdAction",
    components: {
        'a-button': Button,
        Modal
    },
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            displayConfirm: false
        }
    },
    props:{
        buttonType:{
            type: String,
            default: 'primary'
        },
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        properties:{
            type: Object
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        additionalButtonClasses: {
            type: String,
            default: null
        }
    },
    computed:{
        finalConfirmationString() {
            var preText = '';
            if (this.action.name === 'request-update'){
                if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                    preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
            }

            return preText + `Are you sure you want to "${this.action.title}"`
        }
    },
    methods: {
        perform(continueOnFail) {
            if(this.submittingModal === false) {
                this.submittingModal = true;
                this.action.perform({
                    'continueOnFail': continueOnFail
                }).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if (newPath !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        } else {
                            this.$router.go(0);
                        }
                    }
                }).catch(error => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    if (error.status === 403 || error.status === 401) {
                        alert("You do not have permission to " + this.action.title);
                    } else if(error.status === 422){
                        //alert("display new confirmation action");
                        this.displayConfirm = true;
                    }else {
                        alert('There was an error processing your request.' + error.status);
                    }
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function(entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        }
    }
}
</script>

<style scoped>

</style>
