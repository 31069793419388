<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div
                        class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                    >
                        <table class="min-w-full" data-cy="orders-table">
                            <thead>
                            <tr v-if="filterAction">
                                <th colspan=1000 class="p-8  bg-gray-50">
                                    <generic-table-filter :action="filterAction"
                                                          :response="response"
                                                          actionBaseUrl="/rfis"
                                    ></generic-table-filter>
                                </th>
                            </tr>
                            <tr>
                                <th
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                    v-for="headingValue in headingValues"
                                    :key="headingValue"
                                >{{headingValue}}</th>
                            </tr>
                            </thead>
                            <tbody v-if="this.response.body.properties.get('total') === 0">
                            <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                                <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                    No RFIs found.
                                </td>
                            </tr>
                            </tbody>
                            <tbody>
                            <template v-for="productEntity,key in productEntities">
                                <tr @click="navigateToItem(productEntity)" class="bg-white hover:bg-gray-100 cursor-pointer" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                    <template v-for="field, key in getPropertiesToDisplay(productEntity)">
                                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                            {{field}}
                                        </td>
                                    </template>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                        <pagination
                            :response="response"
                            @navigate="paginationNavigation($event)"
                            @navigateAdditionalNext="paginationNavigation($event)"
                            @navigateAdditionalPrev="paginationNavigation($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    import StatusBadge from "./StatusBadge";
    export default {
        components: {
            Pagination,
            GenericTableFilter
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            productEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('information-request'));
            },
            headingValues() {
                return ['RFI Reference', 'Description', 'Project', 'Required By Date', 'Status'];
            }
        },
        methods: {
            getPropertiesToDisplay(entity) {
                return {
                    'rfi_reference': entity.entity.properties.get('rfi_reference'),
                    'description': entity.entity.properties.get('description'),
                    'project': entity.entity.properties.get('project'),
                    'required_by_date': entity.entity.properties.get('required_by_date'),
                    'status': entity.entity.properties.get('status'),
                };
            },
            navigateToItem(entity) {
                let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            }
        }
    }
</script>

<style scoped>

</style>
