import { render, staticRenderFns } from "./SupplierHeader.vue?vue&type=template&id=568bc1eb&scoped=true&"
import script from "./SupplierHeader.vue?vue&type=script&lang=js&"
export * from "./SupplierHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "568bc1eb",
  null
  
)

export default component.exports