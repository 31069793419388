<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <client-header
                            :response="response"></client-header>
                        <div class="grid grid-cols-4 min-h-screen">
                            <div
                                class="col-span-4 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">

                                <client-summary ref="summary"  :response="response" @change="change"></client-summary>
                                <div>
                                    <div class="sm:hidden">
                                        <label for="tabs" class="sr-only">Select a tab</label>
                                        <select id="tabs" name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" v-model="currentTab">
                                            <option value="users" :selected="currentTab === 'users'">Users</option>
                                            <option value="projects" :selected="currentTab === 'projects'">Projects</option>
                                            <option value="contracts" :selected="currentTab === 'contracts'">Contracts</option>
                                            <option value="reoccurring-invoice-items" :selected="currentTab === 'reoccurring-invoice-items'">Reoccurring Invoice Items</option>
                                        </select>
                                    </div>
                                    <div class="hidden sm:block">
                                        <div class="border-b border-gray-200">
                                            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                                                <a href="#" :class="[currentTab === 'users' ? activeClass : inActiveClass]" @click="currentTab='users'" class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                                                    Users
                                                </a>

                                                <a href="#" :class="[currentTab === 'projects' ? activeClass : inActiveClass]" @click="currentTab='projects'" class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                                                    Projects
                                                </a>

                                                <a href="#" :class="[currentTab === 'contracts' ? activeClass : inActiveClass]" @click="currentTab='contracts'" class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                                                    Contracts
                                                </a>

                                                <!-- Current: "border-indigo-500 text-indigo-600",
                                                Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200" -->

                                                <a href="#" :class="[currentTab === 'reoccurring-invoice-items' ? activeClass : inActiveClass]" @click="currentTab='reoccurring-invoice-items'" class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                                                    Reoccurring Invoice Items
                                                    <!-- Current: "bg-indigo-100 text-indigo-600", Default: "bg-gray-100 text-gray-900"
                                                    <span class="bg-gray-100 text-gray-900 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">52</span>-->
                                                </a>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <client-users-table v-if="currentTab === 'users'" :response="response"></client-users-table>
                                <client-projects-table v-if="currentTab === 'projects'" :response="response"></client-projects-table>
                                <client-contracts-table v-if="currentTab === 'contracts'" :response="response"></client-contracts-table>
                                <reoccurring-invoice-items v-if="currentTab === 'reoccurring-invoice-items'" :response="response"></reoccurring-invoice-items>
                            </div>
                        </div>
                    </template>
                    <div v-if="hasChangesWaitingOnSave"
                         style="right:0px;left:16rem;"
                         class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                        <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                            Client
                        </primary-button>
                    </div>
                </div>

            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import ClientHeader from '../../components/Headers/ClientHeader';
    import ClientSummary from '../../components/Summaries/ClientSummary';
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import PrimaryButton from "../../components/PrimaryButton";
    import MainTemplate from "../../templates/MainTemplate";
    import ReoccurringInvoiceItems from "../../components/ReoccurringInvoiceItems";
    import ClientUsersTable from "../../components/Tables/ClientUsersTable";
    import ClientContractsTable from "../../components/Tables/ClientContractsTable";
    import ClientProjectsTable from "../../components/Tables/ClientProjectsTable";

    export default {
        inject: ["$validator"],
        components: {
            ReoccurringInvoiceItems,
            MainTemplate,
            FetchRootSiren,
            Loading,
            PrimaryButton,
            ClientHeader,
            ClientSummary,
            ClientUsersTable,
            ClientContractsTable,
            ClientProjectsTable
        },
        data() {
            return {
                hasChangesWaitingOnSave: false,
                loading:false,
                currentTab:'users',
                activeClass: 'border-blue-700 text-blue-700',
                inActiveClass: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200'
            }
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },
        },
        methods: {
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            async saveData() {
                this.loading = true;
                this.$refs.summary.update().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'The client was saved successfully'
                    );
                    this.hasChangesWaitingOnSave = false;
                    this.loading = false;
                }).catch(error => {
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
        },
    }
</script>

<style scoped>

</style>
