<template>
    <div class="grid grid-cols-4 min-h-screen gap-4 m-4 payment-voucher-summary">
        <div class="space-y-6 col-start-1 lg:col-span-3 col-span-4">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                            Payment Voucher Information
                        </h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">
                            Payment Voucher Details
                        </p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <property-or-field @input="change" :properties="properties" title="Operative" :action="updateAction" fieldId="operative_id" propertyKey="subcontractor_name" v-model="updateActionData['operative_id']" dependsOn="client_id" :dependsOnValue="clientId"></property-or-field>
                            </div>
                            <div class="sm:col-span-1">
                                <property-or-field @input="change" :properties="properties" title="Contract" :action="updateAction" fieldId="contract_id" propertyKey="contract_details" v-model="updateActionData['contract_id']" dependsOn="project_id" :dependsOnValue="projectId"></property-or-field>
                            </div>
                            <div class="sm:col-span-1">
                                <property-or-field @input="change" :properties="properties" title="Additional Day Work Hours" :action="updateAction" fieldId="hours" propertyKey="hours" v-model="updateActionData['hours']"></property-or-field>
                            </div>
                        </dl>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <payment-voucher-qas :response="response" @change="change" ref="paymentVoucherQas"></payment-voucher-qas>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <payment-voucher-day-pays :response="response" @change="change" ref="paymentVoucherDayPays"></payment-voucher-day-pays>
                    </div>
                </div>
            </section>

            <!-- Description list-->
            <section aria-labelledby="voucher-preview">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 class="text-lg leading-6 font-medium text-gray-900">
                            Voucher Preview
                        </h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">
                            Payment Voucher Details
                        </p>

                        <h3 class="text-md leading-6 font-medium text-gray-900 mt-3">Current Earnings</h3>
                        <table width="400px">
                            <thead class="text-left">
                                <tr>
                                    <th>Item</th>
                                    <th class="text-right">Qty</th>
                                    <th class="text-right">Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="response.body.properties.get('hours') > 0 && response.body.properties.get('payment_type') === 'hourly_rate'">
                                <tr>
                                    <td>Day Work:</td>
                                    <td class="text-right">{{response.body.properties.get('hours')}}</td>
                                    <td class="text-right">{{response.body.properties.get('total_hourly_payment')}}</td>
                                </tr>
                                </template>
                                <template v-if="response.body.properties.get('hours') > 0 && response.body.properties.get('payment_type') !== 'hourly_rate'">
                                    <tr>
                                        <td>Extra/Overtime:</td>
                                        <td class="text-right">{{response.body.properties.get('hours')}}</td>
                                        <td class="text-right">{{response.body.properties.get('total_overtime_payment')}}</td>
                                    </tr>
                                </template>
                                <template v-for="qa in response.body.properties.get('qas')">
                                    <tr>
                                        <td colspan="3" class="font-bold">{{qa['name']}}</td>
                                    </tr>
                                        <tr v-if="question.quantity > 0" v-for="question in qa.questions">
                                            <td>{{question.name}}:</td>
                                            <td class="text-right">{{question.quantity}}</td>
                                            <td class="text-right">{{question.total_value}}</td>
                                        </tr>
                                </template>
                                <template v-for="dayPay in response.body.properties.get('day_pay_requests')">
                                    <tr>
                                        <td>{{dayPay.name}}:</td>
                                        <td class="text-right">{{dayPay.hours}}</td>
                                        <td class="text-right">{{dayPay.total}}</td>
                                    </tr>
                                </template>
                            </tbody>
                            <tfoot class="mt-3 text-right pt-3">
                                <tr>
                                    <th colspan="2">Gross :</th>
                                    <td >{{response.body.properties.get('grand_total')}}</td>
                                </tr>
                                <tr>
                                    <th colspan="2">Retention :</th>
                                    <td>{{response.body.properties.get('total_retention_held_back')}}</td>
                                </tr>
                                <tr>
                                    <th colspan="2">Retention Return :</th>
                                    <td>{{response.body.properties.get('total_retention')}}</td>
                                </tr>
                                <tr>
                                    <th colspan="2">Tax(20%) :</th>
                                    <td>{{response.body.properties.get('total_tax')}}</td>
                                </tr>
                                <tr>
                                    <th colspan="2">To Pay :</th>
                                    <td >{{response.body.properties.get('total_to_pay')}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </section>
        </div>


        <section aria-labelledby="timeline-title" class="lg:col-start-4 lg:col-span-1 col-span-2">
            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Timeline</h2>

                <!-- Activity Feed -->
                <div class="mt-6 flow-root">
                    <ul class="-mb-8">
                        <li v-for="(activity, index) in response.body.properties.get('activity_stream')">
                            <div class="relative pb-8">
                                <span v-if="index+1 !== response.body.properties.get('activity_stream').length" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                <div class="relative flex space-x-3">
                                    <div>
                                                          <span class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                                            <!-- Heroicon name: solid/user -->
                                                            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                              <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                                                            </svg>
                                                          </span>
                                    </div>
                                    <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                        <div>
                                            <p class="text-sm text-gray-500">{{activity.description}} <a href="#" class="font-medium text-gray-900">{{activity.actioned_by}}</a></p>
                                        </div>
                                        <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                            <time datetime="2020-09-20">{{activity.date}}</time>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mt-6 flex flex-col justify-stretch">
                    <action-with-confirmation v-if="approveAction" :action="approveAction" :isPositiveAction="true" additionalButtonClasses="w-full justify-center py-4" class="w-full" :onSuccessPath="$route.path">
<!--                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" class="fill-current mr-1" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/></svg>-->
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" class="fill-current mr-1" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                    </action-with-confirmation>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ActionWithConfirmation from "../ActionWithConfirmation";
import PaymentVoucherQas from "@/components/PaymentVoucherQas";
import PropertyOrField from "@/components/PropertyOrField";
import PaymentVoucherDayPays from "@/components/PaymentVoucherDayPays";
export default {
    inject: ["$validator"],
    components:{PaymentVoucherDayPays, PaymentVoucherQas, ActionWithConfirmation, PropertyOrField},
    data() {
        return {
            clientId : null,
            projectId: null
        }
    },
    props: {
        response: {
            type: Object
        },
    },
    computed: {
        approveAction(){
            if(!this.response){
                return null;
            }

            return this.response.body.actions.filter(action => action.name === 'approve-payment-voucher').first();
        },
        updateAction() {
            return this.response.body.actions.filter(action => action.name === 'update-payment-voucher').first();
        },
        updateActionData(){
            var that = this;
            if(!this.updateAction){
                return {};
            }
            if(!this.updateAction.fields){
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function(field){
                if (field.value !== undefined) payload[field.name] = field.value;
                if(field.name === 'client_id'){
                    that.clientId = field.value;
                }
                if(field.name === 'project_id'){
                    that.projectId = field.value;
                }
            });
            return payload;
        },
        properties(){
            return this.response.body.properties;
        }
    },
    methods: {
        update(){
            return this.updateAction.perform(this.updateActionData);
        },
        change(){
            this.$emit('change');
        }
    },
}
</script>

<style>
.payment-voucher-summary .form-group label{
    display:none
}
</style>
