<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <quality-assessment-header :response="response"></quality-assessment-header>
                        <quality-assessment-summary :response="response"></quality-assessment-summary>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import ReturnHeader from "../components/ReturnHeader";
    import RefundRequestItems from "../components/RefundRequestItems";
    import RefundRequestedByDetails from "../components/RefundRequestedByDetails";
    import MainTemplate from "../templates/MainTemplate";
    import QualityAssessmentHeader from "../components/Headers/QualityAssessmentHeader";
    import QualityAssessmentSummary from "../components/Summaries/QualityAssessmentSummary";

    export default {
        data() {
            return {
                attachments: [
                    { name: 'All Unistrut installed correctly?', answer: true },
                    { name: 'Spacing between unistruts correct?', answer: false },
                    { name: 'Correct amount of fixings installed including square washer', answer: true },
                    { name: 'Cut end of Unistrut cleand and free of debris.', answer: true },
                    { name: 'Cut end of Unistrut zinc painted', answer: false },
                    { name: 'End cap installed', answer: true },
                    { name: 'Workplace left clean and tidy.', answer: true },
                ],
            }
        },
        components: {
            MainTemplate,
            ReturnHeader,
            FetchRootSiren,
            Loading,
            RefundRequestItems,
            RefundRequestedByDetails,
            QualityAssessmentHeader,
            QualityAssessmentSummary
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },
            questions()
            {
                if(!this.response){
                    return;
                }
                return this.response.body.properties.get('questions')
            },
            answers()
            {
                if(!this.response){
                    return;
                }
                return this.response.body.properties.get('answers')
            }
        },
        setup() {
            return {
                attachments,
            }
        },
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1024px) {
        .order-container {
            background: linear-gradient(to right, white, white 50%, #f4f5f7 50%);
        }
    }
</style>
