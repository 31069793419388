<template>
    <div class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-3 grid-rows-4 pt-6">
        <div>
            <div class="border md:rounded-lg bg-white p-6" v-if="hasLink('export-client-orders')">
                <span class="text-sm font-nomal text-gray-500 uppercase">Exports</span>
                <ul>
                    <li v-if="hasLink('export-client-orders')">
                        <a class="text-sm font-bold text-black" :href="getLinkHref('export-client-orders')">Orders</a>
                        <p class="text-sm font-nomal text-gray-500">Export orders</p>
                    </li>
                    <li class="mt-2" v-if="hasLink('export-client-invoices')">
                        <a class="text-sm font-bold text-black" :href="getLinkHref('export-client-invoices')">Purchase Invoices / Purchase Credit</a>
                        <p class="text-sm font-nomal text-gray-500">Export PI & PC</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
        actions: {
            type: Object,
            default: {}
        }
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasAction(key){
            return this.actions.filter(action => action.name === key).size;
        }
    },
}
</script>

<style scoped>

</style>
