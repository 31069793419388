<template>
    <div v-if="action">
        <p class="block text-sm font-medium leading-5 text-gray-700 mt-3">Payment Types</p>

        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="flex flex-col questions-box">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Payment Type
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Confirmed
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Quantity
                                    </th>
                                </tr>
                                </thead>
                                <tbody  v-if="paymentTypes.length">
                                <tr v-for="(paymentType, key) in paymentTypes" class="bg-white"><!-- bg-gray-50 -->
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {{paymentType.title}}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="true" v-model="confirmedPaymentTypes[paymentType.value].checked">
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 remove-label">
                                        <number-input v-if="paymentType.multiple" :value="paymentType.default_quantity" :field="quantityField" v-model="confirmedPaymentTypes[paymentType.value].quantity" @input="change" :disabled="!confirmedPaymentTypes[paymentType.value]"></number-input>
                                        <template v-else>{{paymentType.default_quantity}}</template>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                        No Payment Types found
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div v-show="errors.has(validationFieldName)" class="text-red-600">{{ errors.first(validationFieldName) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["$validator"],
    data() {
        return {
            confirmedPaymentTypes: [],
            qtys: []
        }
    },
    props: {
        action: {
            type: Object,
        },
        fieldId: {
            type: String
        },
        scope: {
            type: String,
            default: null
        },
    },
    computed: {
        paymentTypes() {
            let answers =  this.action.fields.get('payment_types').options;
            let that = this;
            answers.forEach(function(answer){
                let checked = false;
                if(answer.checked){
                    checked = true;
                }
                let quantity = answer.default_quantity;
                if(answer.quantity){
                    quantity = answer.quantity;
                }
                that.confirmedPaymentTypes.push({'checked': checked, 'quantity':quantity});
            })
            return answers;

        },
        validationFieldName() {
            if (!this.scope) {
                return this.fieldId;
            }

            return this.scope + '.' + this.fieldId;
        },
        quantityField() {
            return {'title':'Quantity', 'validation':null}
        },
    },
    methods: {

    },
    watch: {
        confirmedPaymentTypes: {
            handler(newValue, oldValue) {
                this.$emit("input", JSON.stringify(newValue));
            },
            deep: true
        }
    },
}
</script>

<style>
.questions-box label{
    display:none
}
</style>
