<template>
    <div class="order-items">
        <div class="md:flex justify-between w-full items-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Pre-Priced List</h3>
                <div class="flex items-center text-secondary-darkgrey">
                    <split-order-form v-if="getSplitOrderAction"
                                      :response="response" :action="getSplitOrderAction"
                                      :actionKey="getSplitOrderAction['title']"
                                      :onSuccessPath="$router.currentRoute.fullPath"
                                      additionalButtonClasses="my-2 w-full"
                                      :onlyIncludeProducts="true"
                    ></split-order-form>
                </div>
        </div>
        <div class="flex flex-col">
            <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
                    <table class="min-w-full">
                        <thead>
                        <tr>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Item
                            </th>
                            <th style="width:100%;"
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Description
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Code
                            </th>
                            <th class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Qty
                            </th>
                            <th v-if="isDelivered" class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Delivered Qty
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Unit
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            v-for="supplier in prices">
                                {{supplier['supplier_name']}}
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="orderItemEntity, key in orderItemEntities">
                            <pre-priced-item-row @change="change" :orderItemEntity="orderItemEntity"
                                             :response="response" @setPrice="setPrice" :prices="prices"></pre-priced-item-row>
                        </template>
                        <tr>
                            <td v-if="isDelivered" class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500" colspan="6">
                                Total:
                            </td>
                            <td v-else class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500" colspan="5">
                                Total:
                            </td>
                            <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500" v-for="supplier in prices">
                                {{ formatTotal(getTotalForSupplier(supplier['supplier_id'])) }}
                            </td>
                            <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OrderItemRow from './OrderItemRow';
    import NewOrderItems from './NewOrderItems';
    import ClockInForm from "@/v2/components/forms/ClockInForm.vue";
    import PrePricedItemRow from "@/components/PrePricedItemRow.vue";
    import FilterableSelect from "@/templates/FilterableSelect.vue";
    import PropertyOrField from "@/components/PropertyOrField.vue";
    import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
    import SplitOrderForm from "@/v2/components/forms/SplitOrderForm.vue";

    export default {
        inject: ["$validator"],
        components: {
            SplitOrderForm,
            PropertyOrAutocompleteField,
            PropertyOrField,
            FilterableSelect,
            PrePricedItemRow,
            ClockInForm,
            OrderItemRow,
            NewOrderItems
        },
        data(){
            return {
                'supplierId': null,
                'responseWithPrices' : null,
                'prices' : [],
                'lowestPrice': 0
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            orderItemEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('pre-priced-item') && entity.entity.properties.get('product') !== null);
                if(!this.responseWithPrices) {
                    return this.response.body.entities.filter(entity => entity.rels.contains('order_item'));
                }
                return this.responseWithPrices.body.entities.filter(entity => entity.rels.contains('order_item'))
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
            updateAction() {
                return null; // this.response.body.actions.filter(action => action.name === 'update').first();
            },
            priceAction(){
                return this.response.body.actions
                    .filter(action =>
                        action.name === 'get-pre-priced-values'
                    ).first()
            },
            getSplitOrderAction(){
                return this.response.body.actions
                    .filter(action =>
                        action.name === 'split-order'
                    ).first()
            }
        },
        methods: {
            async updateItems() {
                let updateItemPromises = [];

                if (this.$refs.orderItemRow) {
                    for (const row of this.$refs.orderItemRow){
                        updateItemPromises.push(await row.update().catch(error => {
                            throw {
                                error: error,
                                scope: 'order-item-row-' + 1
                            };
                        }));
                    };
                }

                this.$refs.newOrderItems.addNewItems().forEach(function (promise) {
                    updateItemPromises.push(promise);
                })
                return await Promise.all(updateItemPromises);
            },
            change() {
                this.$emit('change');
            },
            updateRow(row){
                return row.update().catch(error => {
                    throw {
                        error: error,
                        scope: 'order-item-row-' + index
                    };
                });
            },
            addPreDefinedItems() {
                this.$emit('addPreDefinedItems');
            },
            getPrices(){
                console.log('getPrices');
                this.submittingModal = true;
                this.priceAction.perform({
                    'supplier_id': this.supplierId
                }).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.responseWithPrices = res
                    // this.$store.dispatch(
                    //     "setMessage",
                    //     this.messages(res).entity.properties.get("success")
                    // );
                    // if (this.redirectOnSuccess) {
                    //     this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    //     this.$router.go(0);
                    // }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            setPrice(value){
                let priceExists = false;
                this.prices.forEach(function(price) {
                    if(price.supplier_id === value.supplier_id){
                        priceExists = true;
                    }
                })
                if(!priceExists && value.supplier_name !== null) {
                    this.prices.push({'supplier_id': value.supplier_id, 'supplier_name': value.supplier_name});
                }
            },
            getTotalForSupplier(supplierId){
                if(this.orderItemEntities){
                    let total = 0;
                    this.orderItemEntities.forEach(function(entity){
                        entity.entity.properties.get('prices').forEach(function(price){
                            if(price['supplier_id'] === supplierId){
                                total += price['price_as_cents'];
                            }
                        })

                    })
                    if(this.lowestPrice === 0 || (total < this.lowestPrice && total > 0)){
                        this.lowestPrice = total;
                        this.$emit('lowestPrice', this.lowestPrice);
                    }
                    return total;
                }
            },
            formatTotal(total){
                return '£'+ (total / 100).toFixed(2);
            }


        },
    }
</script>

<style lang="scss">
    .order-items label {
        display: none;
    }

    .table-container-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        background-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -moz-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -ms-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -o-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
    }
</style>
