<template>
    <div class="z-40 rental-form">
        <a-button class="mr-3" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>

        <slide-over v-if="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">
            <template v-if="action.fields.get('client_id')">
                <property-or-field title="Client" :action="action"
                                   fieldId="client_id" propertyKey="client"
                                   v-model="formData['client_id']"
                                   @input="setClientId"
                                    @returnPaymentType="returnAccountUsed"></property-or-field>
                <div v-show="errors.has('client_id')" class="text-red-600 text-sm">{{ errors.first('client_id') }}</div>

                <dependant-field title="Project" :action="action"
                                 fieldId="project_id"
                                 dependsOn="client_id"
                                 :dependsOnValue="clientId"
                                 v-model="formData['project_id']"
                                 @input="setProjectId"></dependant-field>
                <div v-show="errors.has('project_id')" class="text-red-600 text-sm">{{ errors.first('project_id') }}</div>
            </template>
            <template v-else>
                <property-or-field title="Project" :action="action"
                                   fieldId="project_id" propertyKey="project"
                                   v-model="formData['project_id']"
                                   @input="setProjectId"></property-or-field>
                <div v-show="errors.has('project_id')" class="text-red-600 text-sm">{{ errors.first('project_id') }}</div>
            </template>

            <dependant-field @input="change" title="Site" :action="action"
                             fieldId="site_id"
                             dependsOn="project_id"
                             :dependsOnValue="projectId"
                             v-model="formData['site_id']"
            ></dependant-field>
            <div v-show="errors.has('site_id')" class="text-red-600 text-sm">{{ errors.first('site_id') }}</div>

            <!-- Quick Reference -->
            <component :is="getFieldComponent(action.fields.get('quick_reference'))" :field="action.fields.get('quick_reference')" v-model="formData['quick_reference']">
            </component>
            <div v-show="errors.has('quick_reference')" class="text-red-600 text-sm">{{ errors.first('quick_reference') }}</div>

            <!-- Description -->
            <component :is="getFieldComponent(action.fields.get('description'))" :field="action.fields.get('description')" v-model="formData['description']">
            </component>
            <div v-show="errors.has('description')" class="text-red-600 text-sm">{{ errors.first('description') }}</div>

            <!-- Code -->
            <component :is="getFieldComponent(action.fields.get('code'))" :field="action.fields.get('code')" v-model="formData['code']">
            </component>
            <div v-show="errors.has('code')" class="text-red-600 text-sm">{{ errors.first('code') }}</div>

            <!-- Due Date -->
            <component :is="getFieldComponent(action.fields.get('due_date'))" :field="action.fields.get('due_date')" v-model="formData['due_date']">
            </component>
            <div v-show="errors.has('due_date')" class="text-red-600 text-sm">{{ errors.first('due_date') }}</div>

            <!-- Quantity -->
            <component :is="getFieldComponent(action.fields.get('quantity'))" :field="action.fields.get('quantity')" v-model="formData['quantity']">
            </component>
            <div v-show="errors.has('quantity')" class="text-red-600 text-sm">{{ errors.first('quantity') }}</div>

            <!-- Quantity -->
            <component :is="getFieldComponent(action.fields.get('unit'))" :field="action.fields.get('unit')" v-model="formData['unit']">
            </component>
            <div v-show="errors.has('unit')" class="text-red-600 text-sm">{{ errors.first('unit') }}</div>

            <!-- Quantity -->
            <span v-if="action.fields.get('account_used')">
                <component  :is="getFieldComponent(action.fields.get('account_used'))" :field="action.fields.get('account_used')" v-model="accountUsed">
                </component>
                <div v-show="errors.has('account_used')" class="text-red-600">{{ errors.first('account_used') }}</div>
            </span>
        </slide-over>
    </div>
</template>

<script>
    import Button from "@/v2/buttons/Button";
    import SlideOver from "@/v2/components/SlideOver";
    import PropertyOrField from "@/components/PropertyOrField";
    import DependantField from "@/components/DependantField";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                projectId: null,
                clientId:null,
                accountUsed:null
            }
        },
        components: {
            SlideOver,
            'a-button': Button,
            DependantField,
            PropertyOrField
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if (field.value !== undefined && field.name === 'account_used') this.accountUsed = field.value;
                }, this);
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.formData['account_used'] = this.accountUsed;
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            setProjectId(value) {
                this.projectId = value;
            },
            setClientId(value) {
                this.clientId = value;
            },
            returnAccountUsed(value){
                console.log(value);
                this.accountUsed = value;
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
    .rental-form label.label-a{
        display: none;
    }
</style>
