<template>
    <paginated-list :response="response" :actionBaseUrl="$route.fullPath" :use-entities="true" :hasEntities="suppliers && suppliers.count() !== 0">
        <template v-slot:savedFilter>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Name</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Actions</th>
        </template>
        <template v-slot:noItems>
            No supplier groups found.
        </template>
        <template v-slot:tableRows>
            <tr v-for="entity in suppliers" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ entity.entity.properties.get('name') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-2 text-sm text-secondary-darkgrey font-bold">
                    <form-action v-if="entity.entity.actions.get('update-supplier-group')" :action="entity.entity.actions.get('update-supplier-group')" :onSuccessPath="$route.path">
                    </form-action>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import OrderTabs from "@/v2/tabs/OrderTabs";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
export default {
    name: "SupplierGroupsPaginatedList",
    components:{
        ConfirmationAction,
        PaginatedList,
        StatusBadge,
        OrderTabs,
        FormAction
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        suppliers() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('supplier-group'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
