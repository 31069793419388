<template>
    <div>
        <div class="">
            <div class="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                <div>
                    <div>
                        <div class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-4 lg:px-4">
                                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <div class="bg-white">
<!--                                            <order-filters :response="response" :action="filterAction"></order-filters>-->
                                        </div>

                                        <table class="min-w-full">
                                            <thead class="bg-secondary-lightbacka">
                                                <tr>
                                                    <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Employee</th>
                                                    <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Details</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="this.response.body.properties.get('total') === 0 ||entities.count() === 0">
                                                <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                                                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                                        No daily reports found.
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else class="divide-y divide-trueGray-200 bg-white">
                                            <template v-for="(entity, key) in entities">
                                            <tr class="hover:bg-secondary-lightbacka cursor-pointer">
                                                <td v-for="(field, key) in getPropertiesToDisplay(entity)" class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                                                    <template v-if="key === 'details'">
                                                        <div class="h-1/2 border-gray-100 border-l flex align-items-center p-3 ">
                                                            <template v-if="field['times']['in'] !== 'N/A'">
                                                                <div class="inline-block w-16 font-bold">IN:</div>{{formatToTime(field['times']['in'])}} <span class="px-4">/</span>
                                                                <location-viewer-with-icon v-if="field['locations']['in'] !== 'N/A'" :x="field['locations']['in']['x']" :y="field['locations']['in']['y']">{{field['locations']['in']['project']}}</location-viewer-with-icon> <span class="px-4" v-if="field['photos']['in'] !== 'N/A'">/</span>
                                                                <photo-viewer-with-icon class="inline-flex" v-if="field['photos']['in'] !== 'N/A'" :file-name="field['photos']['in']"></photo-viewer-with-icon>
                                                                <span class="flex" v-if="field['comments']['in']">
                                                                    <span class="px-4">/</span>
                                                                    {{field['comments']['in']}}
                                                                    <oss-comment-form v-if="entity.entity.actions.filter(action => action.name === 'change-clock-in-comment').first()"
                                                                                      :action="entity.entity.actions.filter(action => action.name === 'change-clock-in-comment').first()"
                                                                                      :actionKey="entity.entity.actions.filter(action => action.name === 'change-clock-in-comment').first().name"
                                                                                      :redirectOnsuccess="true"
                                                                                      :onSuccessPath="$router.currentRoute.fullPath"
                                                                                      :edit="true"
                                                                    ></oss-comment-form>
                                                                </span>
                                                                <span class="flex" v-else-if="entity.entity.actions.filter(action => action.name === 'change-clock-in-comment').first()">
                                                                    <span class="px-4">/</span>
                                                                    <oss-comment-form v-if="entity.entity.actions.filter(action => action.name === 'change-clock-in-comment').first()"
                                                                                      :action="entity.entity.actions.filter(action => action.name === 'change-clock-in-comment').first()"
                                                                                      :actionKey="entity.entity.actions.filter(action => action.name === 'change-clock-in-comment').first().name"
                                                                                      :redirectOnsuccess="true"
                                                                                      :onSuccessPath="$router.currentRoute.fullPath"
                                                                                      :edit="false"
                                                                    ></oss-comment-form>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                N/A
                                                            </template>
                                                        </div>
                                                        <div v-if="field['relocations']" class="h-1/2 border-t border-gray-100 border-l flex align-items-center p-3 ">
                                                            <div class="outloute-block font-bold">Markers:</div>
                                                        </div>
                                                        <template v-for="relocation in field['relocations']">
                                                            <div class="h-1/2 border-gray-100 border-l flex align-items-center p-3 ">
                                                                <div class="outloute-block font-bold w-16"></div>
                                                                {{formatToTime(relocation['time'])}}<span v-if="relocation['location']" class="px-4">/</span>
                                                                <location-viewer-with-icon v-if="relocation['location']" :x="relocation['location']['x']" :y="relocation['location']['y']">{{relocation['location']['project']}}</location-viewer-with-icon> <span v-if="relocation['photo']" class="px-4">/</span>
                                                                <photo-viewer-with-icon relocationclass="outloute-flex" v-if="relocation['photo']" :file-name="relocation['photo']"></photo-viewer-with-icon>
                                                                <span v-if="relocation['comment']"><span class="px-4">/</span>{{relocation['comment']}}</span>

                                                            </div>
                                                        </template>
                                                        <div class="h-1/2 border-t border-gray-100 border-l flex align-items-center p-3 ">
                                                            <div class="outloute-block w-16 font-bold">OUT:</div>
                                                            <template v-if="field['times']['out'] !== 'N/A'">
                                                                {{formatToTime(field['times']['out'])}} <span class="px-4">/</span>
                                                                <location-viewer-with-icon v-if="field['locations']['out'] !== 'N/A'" :x="field['locations']['out']['x']" :y="field['locations']['out']['y']">{{field['locations']['out']['project']}}</location-viewer-with-icon> <span class="px-4">/</span>
                                                                <photo-viewer-with-icon class="outloute-flex" v-if="field['photos']['out'] !== 'N/A'" :file-name="field['photos']['out']"></photo-viewer-with-icon>
                                                                <span class="flex" v-if="field['comments']['out']">
                                                                    <span class="px-4">/</span>
                                                                    {{field['comments']['out']}}
                                                                    <oss-comment-form v-if="entity.entity.actions.filter(action => action.name === 'change-clock-out-comment').first()" :action="entity.entity.actions.filter(action => action.name === 'change-clock-out-comment').first()"
                                                                                  :actionKey="entity.entity.actions.filter(action => action.name === 'change-clock-out-comment').first().name"
                                                                                  :redirectOnsuccess="true"
                                                                                  :onSuccessPath="$router.currentRoute.fullPath"
                                                                                  :edit="true"
                                                                    ></oss-comment-form>
                                                                </span>
                                                                <span class="flex" v-else-if="entity.entity.actions.filter(action => action.name === 'change-clock-out-comment').first()">
                                                                    <span class="px-4">/</span>
                                                                    <oss-comment-form v-if="entity.entity.actions.filter(action => action.name === 'change-clock-out-comment').first()" :action="entity.entity.actions.filter(action => action.name === 'change-clock-out-comment').first()"
                                                                                                   :actionKey="entity.entity.actions.filter(action => action.name === 'change-clock-out-comment').first().name"
                                                                                                   :redirectOnsuccess="true"
                                                                                                   :onSuccessPath="$router.currentRoute.fullPath"
                                                                                                   :edit="false"
                                                                    ></oss-comment-form>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                N/A
                                                            </template>
                                                        </div>
                                                    </template>
                                                    <div class="px-3" style="max-width: 60px;" v-else>{{field}}</div>
                                                </td>
                                            </tr>
                                            </template>
                                            </tbody>

                                        </table>
                                        <pagination :response="response"></pagination>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import Pagination from "@/v2/paginatedLists/Pagination";
import PhotoViewerWithIcon from "@/components/PhotoViewerWithIcon";
import LocationViewerWithIcon from "@/components/LocationViewerWithIcon";
import OssCommentForm from "@/v2/components/forms/OssCommentForm";

export default {
    name: "DailyReportsPaginatedList",
    components:{
        OssCommentForm,
        Pagination,
        PhotoViewerWithIcon,
        LocationViewerWithIcon
    },
    props: {
        response:{
            type: Object
        }
    },
    computed:{
        filterAction() {
            if(!this.response){
                return null;
            }
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
        entities(){
            if(!this.response){
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('daily-report-item'));
        },
        headingValues() {
            return ['Employee', 'Details'];
        }
    },
    methods:{
        formatToTime(datetime){
            return dayjs(datetime).format('HH:mm');
        },
        getPropertiesToDisplay(reportEntity) {

            return {
                'user': reportEntity.entity.properties.get('user'),
                'details':{
                    'times': {
                        'in' : reportEntity.entity.properties.get('time_in'),
                        'out' : reportEntity.entity.properties.get('time_out')
                    },
                    'locations': {
                        'in': reportEntity.entity.properties.get('location_in'),
                        'out': reportEntity.entity.properties.get('location_out')
                    },
                    'photos' : {
                        'in': reportEntity.entity.properties.get('photo_in'),
                        'out': reportEntity.entity.properties.get('photo_out'),
                    },
                    'comments': {
                        'in': reportEntity.entity.properties.get('comment_in'),
                        'out': reportEntity.entity.properties.get('comment_out'),
                    },
                    'relocations': reportEntity.entity.properties.get('relocations')
                }
            }
        },
        navigateToItem(companyEntity) {
            let selfLink = companyEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        getTimesheetLink(reportEntity){
            let timesheetLink = reportEntity.entity.links.filter(link => link.rels.contains('timesheet-builder')).first().href;
            let parts = timesheetLink.split('/api');
            return parts[1];
        }
    }
}


</script>

<style scoped>

</style>
