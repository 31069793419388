<template>
    <div class="px-4 sm:px-6 md:px-8 grid gap-4 grid-cols-3 grid-rows-4 pt-6">
        <div>
        <div class="border md:rounded-lg mb-4 bg-white p-6" v-if="hasSagesLink()">
            <span class="text-sm font-nomal text-gray-500 uppercase">Manage Sage</span>
            <ul>
            <li >
                <a class="text-sm font-bold text-black" v-if="hasLink('sages')" :href="getLinkHref('sages')">Tax Codes</a>
                <p class="text-sm font-nomal text-gray-500">View & Manage Tax Codes</p>
            </li>
            <li class="mt-2">
                <a class="text-sm font-bold text-black" v-if="hasLink('sage-descriptions')" :href="getLinkHref('sage-descriptions')">Short Descriptions</a>
                <p class="text-sm font-nomal text-gray-500">View & Manage Short Descriptions</p>
            </li>
            <li class="mt-2">
                <a class="text-sm font-bold text-black" v-if="hasLink('sage-nominal-codes')" :href="getLinkHref('sage-nominal-codes')">Nominal Codes</a>
                <p class="text-sm font-nomal text-gray-500">View & Manage Nominal Codes</p>
            </li>
            </ul>
        </div>
            <div class="border md:rounded-lg bg-white p-6" v-if="hasLink('reports')">
                <span class="text-sm font-nomal text-gray-500 uppercase">Exports</span>
                <ul>
                    <li >
                        <a class="text-sm font-bold text-black" href="/reports">Orders</a>
                        <p class="text-sm font-nomal text-gray-500">Export orders</p>
                    </li>
                    <li class="mt-2">
                        <a class="text-sm font-bold text-black" href="/invoice-reports">Purchase Invoices / Purchase Credit</a>
                        <p class="text-sm font-nomal text-gray-500">Export PI & PC</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="border md:rounded-lg mb-8 bg-white grid grid-cols-3 grid-rows-2 col-span-2 section-links">
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('invoices')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('invoices')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM96 128h80C184.8 128 192 120.8 192 112S184.8 96 176 96H96C87.16 96 80 103.2 80 112S87.16 128 96 128zM192 176C192 167.2 184.8 160 176 160H96C87.16 160 80 167.2 80 176S87.16 192 96 192h80C184.8 192 192 184.8 192 176zM197.5 308.8L191.1 307.2C168.3 300.2 168.8 296.1 169.6 292.5c1.375-7.812 16.59-9.719 30.27-7.625c5.594 .8438 11.73 2.812 17.59 4.844c10.39 3.594 21.83-1.938 25.45-12.34c3.625-10.44-1.891-21.84-12.33-25.47c-7.219-2.484-13.11-4.078-18.56-5.273V240c0-11.03-8.953-20-20-20s-20 8.969-20 20v5.992C149.6 250.8 133.8 264.8 130.2 285.7c-7.406 42.84 33.19 54.75 50.52 59.84l5.812 1.688c29.28 8.375 28.8 11.19 27.92 16.28c-1.375 7.812-16.59 9.75-30.31 7.625c-6.938-1.031-15.81-4.219-23.66-7.031l-4.469-1.625c-10.41-3.594-21.83 1.812-25.52 12.22c-3.672 10.41 1.781 21.84 12.2 25.53l4.266 1.5c7.758 2.789 16.38 5.59 25.06 7.512V416c0 11.03 8.953 20 20 20s20-8.969 20-20v-6.254c22.36-4.793 38.21-18.53 41.83-39.43C261.3 327 219.8 315.1 197.5 308.8z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue ">Sales Invoices</p>
                        <p>View, Manage & Generate sales invoices.</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('invoices-in')">
                <a class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('invoices-in')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M264 144C277.3 144 288 154.7 288 168C288 181.3 277.3 192 264 192H120C106.7 192 96 181.3 96 168C96 154.7 106.7 144 120 144H264zM264 320C277.3 320 288 330.7 288 344C288 357.3 277.3 368 264 368H120C106.7 368 96 357.3 96 344C96 330.7 106.7 320 120 320H264zM96 256C96 242.7 106.7 232 120 232H264C277.3 232 288 242.7 288 256C288 269.3 277.3 280 264 280H120C106.7 280 96 269.3 96 256zM80 40.39L120.4 5.778C129.4-1.926 142.6-1.926 151.6 5.778L192 40.39L232.4 5.778C241.4-1.926 254.6-1.926 263.6 5.778L304 40.39L344.4 5.778C351.5-.3214 361.5-1.72 370 2.196C378.5 6.113 384 14.63 384 24V488C384 497.4 378.5 505.9 370 509.8C361.5 513.7 351.5 512.3 344.4 506.2L304 471.6L263.6 506.2C254.6 513.9 241.4 513.9 232.4 506.2L192 471.6L151.6 506.2C142.6 513.9 129.4 513.9 120.4 506.2L80 471.6L39.62 506.2C32.5 512.3 22.49 513.7 13.97 509.8C5.456 505.9 0 497.4 0 488V24C0 14.63 5.456 6.112 13.97 2.196C22.49-1.72 32.5-.3214 39.62 5.778L80 40.39zM64.38 421.8C73.37 414.1 86.63 414.1 95.62 421.8L136 456.4L176.4 421.8C185.4 414.1 198.6 414.1 207.6 421.8L248 456.4L288.4 421.8C297.4 414.1 310.6 414.1 319.6 421.8L336 435.8V76.18L319.6 90.22C310.6 97.93 297.4 97.93 288.4 90.22L248 55.61L207.6 90.22C198.6 97.93 185.4 97.93 176.4 90.22L136 55.61L95.62 90.22C86.63 97.93 73.37 97.93 64.38 90.22L48 76.18V435.8L64.38 421.8z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Purchase Invoices</p>
                        <p>View & manage purchase invoices.</p>
                    </div>
                </a>
            </div>
            <div class="p-6" style="min-height: 200px;" v-if="hasLink('credit-notes')">
                <a style="" class="rounded-lg bg-white flex items-start justify-between flex-col text-gray-500 hover:text-gray-700 font-medium text-sm h-full" :href="getLinkHref('credit-notes')">
                    <div class="bg-secondary-lightbackb p-6 rounded-lg w-min">
                        <svg class="w-6 h-6 text-secondary-lavenderblue fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M80 256v64c0 17.67 14.33 32 32 32h160c17.67 0 32-14.33 32-32V256c0-17.67-14.33-32-32-32h-160C94.33 224 80 238.3 80 256zM365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM96 128h80C184.8 128 192 120.8 192 112S184.8 96 176 96H96C87.16 96 80 103.2 80 112S87.16 128 96 128zM96 192h80C184.8 192 192 184.8 192 176S184.8 160 176 160H96C87.16 160 80 167.2 80 176S87.16 192 96 192zM288 384h-80c-8.844 0-16 7.156-16 16s7.156 16 16 16H288c8.844 0 16-7.156 16-16S296.8 384 288 384z"/></svg>
                    </div>

                    <div>
                        <p class="text-xl font-bold w-full text-secondary-darkblue">Credit Notes</p>
                        <p>View, Manage & Generate credit notes.</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Object,
            default: {}
        },
    },
    methods: {
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasSagesLink() {
            return this.hasLink('sages') || this.hasLink('sage-descriptions') || this.hasLink('sage-nominal-codes');
        },
    },
}
</script>

<style scoped>

</style>
