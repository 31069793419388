<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <return-header :response="response"></return-header>
                        <div class="grid grid-cols-4 min-h-screen">
                            <div
                                class="col-span-4 lg:col-span-3 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">
                                <refund-request-items :response="response" ref="orderItems"></refund-request-items>
                            </div>
                            <refund-requested-by-details :response="response"></refund-requested-by-details>
                        </div>

                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import ReturnHeader from "../components/ReturnHeader";
    import RefundRequestItems from "../components/RefundRequestItems";
    import RefundRequestedByDetails from "../components/RefundRequestedByDetails";
    import MainTemplate from "../templates/MainTemplate";

    export default {
        components: {
            MainTemplate,
            ReturnHeader,
            FetchRootSiren,
            Loading,
            RefundRequestItems,
            RefundRequestedByDetails
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },

        },
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1024px) {
        .order-container {
            background: linear-gradient(to right, white, white 50%, #f4f5f7 50%);
        }
    }
</style>
