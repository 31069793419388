<template>
    <div :key="$route.fullPath">
        <main-template :backgroundImageUrl="backgroundImageUrl">
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <dashboard-header :editable="editable" :response="response" :hasOssModule="hasOssModule"></dashboard-header>
                        <onboarding-steps v-if="unfinishedOnboardingSteps.length > 0" :completeSteps="finishedOnboardingSteps" :incompleteSteps="unfinishedOnboardingSteps"></onboarding-steps>
                        <dashboard-modal></dashboard-modal>
                        <drag-drop-widgets :editable="editable"></drag-drop-widgets>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import FilterablePaginatedOrdersList from '../components/FilterablePaginatedOrdersList';
    import Loading from '../components/Loading';
    import DashboardStats from '../components/Dashboard/DashboardStats';
    import SavedFilters from '../components/SavedFilters';
    import DashboardHeader from '../components/Headers/DashboardHeader';
    import MainTemplate from '../templates/MainTemplate';
    import OnboardingSteps from "../components/OnboardingSteps";
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import DashboardModal from "../components/Dashboard/DashboardModal";
    import DragDropWidgets from "../components/DragDropWidgets";
    export default {
        components: {
            OnboardingSteps,
            FetchRootSiren,
            Loading,
            DashboardStats,
            DashboardHeader,
            SavedFilters,
            MainTemplate,
            DashboardModal,
            DragDropWidgets
        },
        data() {
            return {
                onboardingSteps: [],
                editable: false,
                backgroundImage: null,
                backgroundImageUrl: null,
                hasOssModule: false
            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken",
            }),
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
            unfinishedOnboardingSteps() {
                return this.onboardingSteps.filter(step => step.done !== true)
            },
            finishedOnboardingSteps() {
                return this.onboardingSteps.filter(step => step.done === true)
            }
        },
        mounted() {
            let follow = Siren.Helper.follow;

            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

            Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
                Siren.get(process.env.VUE_APP_API_URL + "/api/").then(
                    follow("current-user")
                )
                    .then(res => {
                        this.backgroundImage = res.body.properties.get('background_image');
                        if(this.backgroundImage !== null){
                            this.backgroundImageUrl = process.env.VUE_APP_API_URL + '/storage/background_images/' + this.backgroundImage
                        }
                        this.hasOssModule = res.body.properties.get('has_oss_module');
                    })
                Siren.get(process.env.VUE_APP_API_URL + "/api/")
                    .then(
                        follow("onboarding-steps")
                    )
                    .then(res => {
                        this.onboardingSteps = JSON.parse(res.text).links;
                    }, this)
                    .catch(error => {
                        console.log('error fetching dashboard');
                    });
            });
        }
    }
</script>

<style lang="scss" scoped>

</style>
