<template>
    <div>
        <menu-item @click.native="openSubMenu" :href=href :is-open="isOpen" :hasSubMenu="hasSubMenuSlot">
            <slot></slot>
        </menu-item>
        <div class="mt-1 space-y-1" v-show="isOpen">
            <slot name="sub-menu"></slot>
        </div>
    </div>

</template>

<script>
    import MenuItem from "./MenuItem";
    export default {
        components: {MenuItem},
        props: {
            isOpen: {
                type: Boolean
            },
            subMenu: {
                type: Array,
                default: function(){
                    return []
                }
            },
            href:{
                type: String
            }
        },
        computed: {
            hasSubMenuSlot () {
                return !!this.$slots['sub-menu']
            }
        },
        methods: {
            openSubMenu() {
                if(this.hasSubMenuSlot) {
                    this.$emit('openSubMenu');
                }else{
                    window.location = this.href;
                }
            },

        },
    }
</script>

<style scoped>

</style>
