<template>
    <div class="z-40 rental-form">
        <a-button class="mr-3" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a-button>

        <slide-over v-if="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">
            <template v-if="action.fields.get('client_id')">
                <property-or-field title="Client" :action="action"
                                   fieldId="client_id" propertyKey="client"
                                   v-model="formData['client_id']"
                                   @input="setClientId"></property-or-field>
                <div v-show="errors.has('client_id')" class="text-red-600">{{ errors.first('client_id') }}</div>

                <dependant-field title="Project" :action="action"
                                 fieldId="project_id"
                                 dependsOn="client_id"
                                 :dependsOnValue="clientId"
                                 v-model="formData['project_id']"
                                 @input="setProjectId"></dependant-field>
                <div v-show="errors.has('project_id')" class="text-red-600">{{ errors.first('project_id') }}</div>
            </template>
            <template v-else>
                <property-or-field title="Project" :action="action"
                                   fieldId="project_id" propertyKey="project"
                                   v-model="formData['project_id']"
                                   @input="setProjectId"></property-or-field>
                <div v-show="errors.has('project_id')" class="text-red-600">{{ errors.first('project_id') }}</div>
            </template>

            <dependant-field @input="change" title="Contract" :action="action"
                             fieldId="contract_id"
                             dependsOn="project_id"
                             :dependsOnValue="projectId"
                             v-model="formData['contract_id']"
            ></dependant-field>
            <div v-show="errors.has('contract_id')" class="text-red-600">{{ errors.first('contract_id') }}</div>

            <dependant-field @input="change" title="User" :action="action"
                             fieldId="user_id"
                             dependsOn="client_id"
                             :dependsOnValue="clientId"
                             v-model="formData['user_id']"
            ></dependant-field>
            <div v-show="errors.has('user_id')" class="text-red-600">{{ errors.first('user_id') }}</div>

            <component :is="getFieldComponent(action.fields.get('item'))" :field="action.fields.get('item')" v-model="formData['item']">
            </component>
            <div v-show="errors.has('item')" class="text-red-600">{{ errors.first('item') }}</div>

            <component :is="getFieldComponent(action.fields.get('serial_number'))" :field="action.fields.get('serial_number')" v-model="formData['serial_number']">
            </component>
            <div v-show="errors.has('serial_number')" class="text-red-600">{{ errors.first('serial_number') }}</div>

            <component :is="getFieldComponent(action.fields.get('image'))" :field="action.fields.get('image')" v-model="formData['image']">
            </component>
            <div v-show="errors.has('image')" class="text-red-600">{{ errors.first('image') }}</div>

            <component :is="getFieldComponent(action.fields.get('note'))" :field="action.fields.get('note')" v-model="formData['note']">
            </component>
            <div v-show="errors.has('note')" class="text-red-600">{{ errors.first('note') }}</div>


        </slide-over>
    </div>
</template>

<script>
    import Button from '../Button';
    import Modal from '../Modal';
    import SlideOver from "../SlideOver";
    import PropertyOrField from "../PropertyOrField";
    import DependantField from "../DependantField";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                projectId: null,
                clientId:null
            }
        },
        components: {
            SlideOver,
            'a-button': Button,
            Modal,
            DependantField,
            PropertyOrField
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                this.clientId = payload['client_id'];
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            setProjectId(value) {
                this.projectId = value;
            },
            setClientId(value) {
                this.clientId = value;
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
    .rental-form label.label-a{
        display: none;
    }
</style>
