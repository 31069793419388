<template>
    <div :key="$route.fullPath">
        <main-template>
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link" :logo="logo">
                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full items-center">
                                Weekly Summary
                                <div class="flex items-center text-secondary-darkgrey">
                                    <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'show-weekly-report')">
                                        <export-weekly-report-form v-if="actionWithFields[0] === 'export-report' || actionWithFields[0] === 'export-accounting-report'" class="action-with-fields" :action="actionWithFields[1]"
                                                                   :actionKey="actionWithFields[0]"
                                                                   :redirectOnSuccess="true"
                                                                   :onSuccessPath="$router.currentRoute.fullPath">
                                        </export-weekly-report-form>
                                        <form-action v-else class="action-with-fields" :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                        ></form-action>
                                    </div>
                                </div>
                            </div>
                        </page-header>
                        <div class="p-4">
                            <!--                        <saved-filters :response="response">-->
                            <!--                        </saved-filters>-->
                            <oss-tabs></oss-tabs>
                            <weekly-report-action :response="response" ref="weeklyReport" @change="change"></weekly-report-action>


                        </div>
                        <div class="mb-8">
                            <weekly-reports-paginated-list :response="response"></weekly-reports-paginated-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
            </template>
        </main-template>
    </div>
</template>

<script>
import FetchRootSiren from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from "@/v2/templates/MainTemplate";
import moment from "moment";
import WeeklyReportAction from "@/components/WeeklyReportAction";
import WeeklyReportHeader from "@/components/Headers/WeeklyReportHeader";
import PageHeader from "@/v2/components/Header";
import OssTabs from "@/v2/tabs/OssTabs";
import WeeklyReportsPaginatedList from "@/v2/paginatedLists/WeeklyReportsPaginatedList";
import FormAction from "@/v2/components/FormAction";
import ExportWeeklyReportForm from "@/v2/components/forms/ExportWeeklyReportForm";

export default {
    components: {
        ExportWeeklyReportForm,
        WeeklyReportHeader,
        WeeklyReportAction,
        MainTemplate,
        FetchRootSiren,
        Loading,
        PageHeader,
        OssTabs,
        WeeklyReportsPaginatedList,
        FormAction
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            return this.$route.fullPath.replace('/v3','')
        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>

</style>
