<template>
    <div class="order-summary":key="key">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Vehicle Summary</h3>
        <div class="generic-summary p-4 bg-white" >
            <div class="grid grid-cols-3 pb-4 gap-4">
                <property-or-field @input="change" :properties="properties" title="Make" :action="updateAction" fieldId="make" propertyKey="make" v-model="updateActionData['make']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Model" :action="updateAction" fieldId="model" propertyKey="model" v-model="updateActionData['model']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="License Number" :action="updateAction" fieldId="license_number" propertyKey="license_number" v-model="updateActionData['license_number']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Mileage" :action="updateAction" fieldId="mileage" propertyKey="mileage" v-model="updateActionData['mileage']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Current Keeper" :action="updateAction" fieldId="current_keeper_id" propertyKey="current_keeper_id" v-model="updateActionData['current_keeper_id']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Tax Expiry Date" :action="updateAction" fieldId="tax_expiry_date" propertyKey="tax_expiry_date" v-model="updateActionData['tax_expiry_date']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="MOT Expiry Date" :action="updateAction" fieldId="mot_expiry_date" propertyKey="mot_expiry_date" v-model="updateActionData['mot_expiry_date']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Notes" :action="updateAction" fieldId="notes" propertyKey="notes" v-model="updateActionData['notes']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Private Use" :action="updateAction" fieldId="private_use" propertyKey="private_use" v-model="updateActionData['private_use']"></property-or-field>
            </div>
            <div class="grid grid-cols1 pb-4 gap-4">
                <images-input :action="updateAction" v-model="updateActionData['images']"></images-input>
                <div v-show="errors.has('images')" class="text-red-600">{{ errors.first('images') }}</div>
            </div>
        </div>

        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1" v-if="properties.get('handover_date') || properties.get('handover_to_user')">Handover Details</h3>
        <div class="generic-summary p-4 bg-white">
            <div class="grid grid-cols-3 pb-4 gap-4">
                <property-or-field @input="change" :properties="properties" title="Handover To User" :action="updateAction" fieldId="handover_to_user" propertyKey="handover_to_user" v-model="updateActionData['handover_to_user']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Handover Date" :action="updateAction" fieldId="handover_date" propertyKey="handover_date" v-model="updateActionData['handover_date']"></property-or-field>
            </div>
            <div class="sm:col-span-2" v-if="response.body.properties.get('images')">
                <dt class="text-sm font-medium text-gray-500">
                    Images
                </dt>
                <div class="grid grid-cols-2 sm:grid-cols-4">
                    <dd class="mt-1 text-sm text-gray-900" v-for="(image, index) in response.body.properties.get('images')">
                        <div class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                                <a :href="image" class="pointer-events-none">
                                    <img :src="image" alt="" class="group-hover:opacity-75 object-cover pointer-events-none rounded-lg">
                                </a>
                                <button type="button" class="absolute">
                                    <span class="sr-only">View details for IMG_5214.HEIC</span>
                                </button>
                        </div>
                    </dd>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import PropertyOrField from '../PropertyOrField';
    import Contacts from "../Contacts";
    import ImagesInput from "@/components/Action/Fields/ImagesInput";
    export default {
        inject: ["$validator"],
        components: {
            PropertyOrField,
            Contacts,
            ImagesInput
        },
        data() {
            return {
                key : 0
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update-vehicle').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
            properties(){
                return this.response.body.properties;
            }
        },
        methods: {
            update(){
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
