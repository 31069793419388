<template>
    <div>
        <div v-for="(action, index) in remainingActions">
            <template v-if="!action[1].fields.isEmpty()">
                <start-handover-form v-if="action[1].name === 'start-handover' || action[1].name === 'complete-handover'" :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></start-handover-form>
                <form-action v-else :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></form-action>
            </template>
            <template v-else-if="action[1].fields.isEmpty()">
                <confirmation-action additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath"></confirmation-action>
            </template>
        </div>
    </div>
</template>

<script>
import Button from "@/v2/buttons/Button";
import ApproveButtons from "@/v2/buttons/ApproveButtons";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
import QuoteApprovalAction from "@/v2/components/QuoteApprovalAction";
import ReturnsForm from "@/v2/components/forms/ReturnsForm";
import GoodsInNoteForm from "@/v2/components/forms/GoodsInNoteForm";
import ReconcileOrderForm from "@/v2/components/forms/ReconcileOrderForm";
import NewCreditNoteForm from "@/v2/components/forms/NewCreditNoteForm";
import EmailPreviewForm from "@/v2/components/forms/EmailPreviewForm";
import SelectQuoteForm from "@/v2/components/forms/SelectQuoteForm";
import SplitOrderForm from "@/v2/components/forms/SplitOrderForm";
import StartHandoverForm from "@/v2/components/forms/StartHandoverForm";
export default {
    name: "Actions",
    data() {
        return {
            displayApproveModal : false,
            displayRejectModal : false
        }
    },
    components:{
        QuoteApprovalAction,
        FormAction,
        ConfirmationAction,
        'a-button' : Button,
        ApproveButtons,
        ReturnsForm,
        GoodsInNoteForm,
        ReconcileOrderForm,
        NewCreditNoteForm,
        EmailPreviewForm,
        SelectQuoteForm,
        SplitOrderForm,
        StartHandoverForm
    },
    props:{
        response:{
            type: Object
        },
        remainingActions:{
            type: Object
        }
    },
    methods:{
        getColourType(action, index){
            if(action === 'delete-order'){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        getAction(actionName){
            return this.remainingActions.filter(action => action.name === actionName).first();
        }
    }
}
</script>

<style scoped>

</style>
