<template>
    <div>
        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
        confirmationString='Please rate this order'
        @removeFromDisplay="displayModal = false" @perform="perform()"
        :isNegativeAction="isNegativeAction" :isPositiveAction="isPositiveAction">
        <template v-for="(field, index) in action.fields">
            <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[1].name]">
            </component>
            <div v-show="errors.has(field[1].name)" class="text-red-600">{{ errors.first(field[1].name) }}</div>
        </template>

        <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </slide-over>
    </div>
</template>

<script>
    import Button from '../Button';
    import Modal from '../Modal';
    import SlideOver from "../SlideOver";
    import fileDownload from "js-file-download";
    import RedButton from "../RedButton";
    import GreenButton from "../GreenButton";

    export default {
        data() {
            return {
                displayModal: true,
                submittingModal: false
            }
        },
        components: {
            SlideOver,
            'a-button': Button,
            Modal,
            RedButton,
            GreenButton
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            isNegativeAction: {
                type: Boolean,
                default: false
            },
            isPositiveAction: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if(newPath  !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        }else{
                            this.$router.go(0);
                        }
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>
