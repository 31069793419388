<template>
    <div>
        <a :class="additionalButtonClasses" class="text-center mx-auto w-full cursor-pointer" @click="displayModal = true">
            <svg v-if="flagProp" class="text-red-500 fill-current" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32V64 368 480c0 17.7 14.3 32 32 32s32-14.3 32-32V352l64.3-16.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L64 48V32z"/></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32V64 368 480c0 17.7 14.3 32 32 32s32-14.3 32-32V352l64.3-16.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L64 48V32z"/></svg>
        </a>

        <portal to="destination">
            <modal v-if="displayModal" :submitting="submittingModal" :title="title" :confirmationString='finalConfirmationString' @removeFromDisplay="displayModal = false" @perform="perform()" :buttonType="buttonType"></modal>
        </portal>
    </div>
</template>

<script>
import Modal from "@/v2/components/Modal";
import Button from "@/v2/buttons/Button"
export default {
    name: "FlagOrderAction",
    components: {
        'a-button': Button,
        Modal
    },
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            title: null,
            flagProp: false
        }
    },
    props:{
        buttonType:{
            type: String,
            default: 'primary'
        },
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        properties:{
            type: Object
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        additionalButtonClasses: {
            type: String,
            default: null
        },
        hasFlag:{
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.flagProp = this.hasFlag;
        if(this.flagProp){
            this.title = 'Remove Flag';
        }else{
            this.title = 'Flag Order';
        }
    },
    computed:{
        finalConfirmationString() {
            var preText = '';
            if (this.action.name === 'request-update'){
                if (this.properties.get('last_updated_by_name') !== null && this.properties.get('last_updated_by_date') !== null)
                    preText = this.properties.get('last_updated_by_name') + ' requested an update ' +  this.properties.get('last_updated_by_date') + ', ';
            }
            if(this.hasFlag){
                return preText + `Are you sure you want to "Remove Flag"`
            }

            return preText + `Are you sure you want to "${this.action.title}"`
        }
    },
    methods: {
        perform() {
            this.submittingModal = true;
            this.action.perform().then(res => {
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                this.flagProp = !this.flagProp;
                if(this.flagProp){
                    this.title = 'Remove Flag';
                }else{
                    this.title = 'Flag Order';
                }
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
            }).catch(error => {
              if(error.status === 403 || error.status === 401){
                alert("You do not have permission to " + this.action.title);
              }else {
                alert('There was an error processing your request.' + error);
              }
                this.displayModal = false;
                this.submittingModal = false;
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function(entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        }
    }
}
</script>

<style scoped>

</style>
