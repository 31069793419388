<template>
    <div class="h-screen">
        <div v-if="getPreviousUser()">
            <div class="text-center py-2">Signed in as {{user.name}} <a @click="signInPreviousUser" class="underline cursor-pointer">Sign Out</a></div>
        </div>
        <notification :message="message"></notification>
        <notification-without-timeout :message="messageWithoutTimeout"></notification-without-timeout>
        <div class="h-full">

            <div class="flex w-24 flex-col fixed inset-y-0 z-10">
                <div class="flex-1 flex flex-col min-h-0 bg-secondary-darkgrey">
                    <div class="flex-1 flex flex-col pb-4 overflow-y-auto">
                        <div class="bg-secondary-verylightblueb text-primary-blue w-full">
                            <div class="flex items-center flex-shrink-0 px-1 m-auto justify-center items-center h-20">
                                <a href="/">
                                    <img v-if="!isUrlStaging()" src="../../../images/logo-header.png">
                                    <span v-else class="text-red-500 text-3xl bold">TEST</span>
                                </a>
                            </div>
                        </div>

                        <nav class="flex-1 space-y-1 items-center uppercase text-xs mt-2">
                            <a v-if="hasAnAdminLink()" :href="getAdminLink()" :class="{'rounded-none border-l-4 border-current text-secondary-verylightpeach': isActive('admin'), 'text-secondary-lavenderblue hover:text-secondary-verylightpeach': !isActive('admin')}" class=" group flex items-center px-4 py-2 text-xs font-medium h-16">
                            <div class="m-auto">
                                <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M448 96h-172.1L226.7 50.75C214.7 38.74 198.5 32 181.5 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h384c35.35 0 64-28.66 64-64V160C512 124.7 483.3 96 448 96zM464 416c0 8.824-7.178 16-16 16H64c-8.822 0-16-7.176-16-16V96c0-8.824 7.178-16 16-16h117.5c4.273 0 8.293 1.664 11.31 4.688L256 144h192c8.822 0 16 7.176 16 16V416zM338.5 303.3C339.4 298.3 340 293.2 340 288s-.625-10.31-1.541-15.28L358.8 261c2.811-1.625 4.207-4.984 3.229-8.082C359.7 245.8 356.9 238.8 352.1 232s-8.562-12.73-13.62-18.25c-2.193-2.398-5.805-2.875-8.615-1.246l-20.52 11.85C302.5 217.8 293.7 212.6 284 209.1V185.5c0-3.246-2.215-6.137-5.387-6.836C271.3 177 263.8 176 256 176S240.7 177 233.4 178.7C230.2 179.4 228 182.3 228 185.5v23.64C218.3 212.6 209.5 217.8 201.8 224.4L181.2 212.5C178.4 210.9 174.8 211.4 172.6 213.8C167.6 219.3 162.9 225.2 159 232c-3.898 6.754-6.744 13.78-8.998 20.91C149 256 150.4 259.4 153.2 261l20.3 11.72C172.6 277.7 172 282.8 172 288s.623 10.31 1.539 15.28L153.2 315c-2.812 1.621-4.207 4.984-3.23 8.082C152.3 330.2 155.1 337.2 159 344c3.9 6.754 8.562 12.73 13.62 18.25c2.195 2.398 5.805 2.875 8.617 1.246l20.52-11.85C209.5 358.2 218.3 363.4 228 366.9v23.64c0 3.242 2.215 6.137 5.387 6.836C240.7 398.1 248.2 400 256 400s15.3-1.047 22.61-2.664c3.172-.6992 5.387-3.594 5.387-6.836v-23.64c9.734-3.465 18.54-8.637 26.24-15.21l20.52 11.85c2.811 1.629 6.422 1.152 8.615-1.246c5.055-5.52 9.716-11.5 13.62-18.25s6.745-13.78 8.999-20.92c.9766-3.098-.4199-6.461-3.23-8.082L338.5 303.3zM256 323c-19.33 0-35-15.67-35-35S236.7 253 256 253c19.33 0 35 15.67 35 35S275.3 323 256 323z"/></svg>
                                    Admin
                                </div>
                            </a>

                            <a href="/orders" v-if="hasLink('orders') || hasLink('tender-orders') || hasLink('return-requests') || hasLink('rental-items') || hasLink('take-offs') || hasLink('schedule-manager')" :class="{'rounded-none border-l-4 border-current text-secondary-verylightpeach': isActive('orders'), 'text-secondary-lavenderblue hover:text-secondary-verylightpeach': !isActive('orders')}" class=" group flex items-center py-2 text-xs font-medium h-16">
                                <div class="m-auto">
                                    <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM475.6 240L520.6 80H131.1L161.6 240H475.6zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z"/></svg>
                                    Procurement
                                </div>

                            </a>
                            <a v-if="hasOfficeLink()" href="/office" :class="{'rounded-none border-l-4 border-current text-secondary-verylightpeach': isActive('office'), 'text-secondary-lavenderblue hover:text-secondary-verylightpeach': !isActive('office')}" class=" group flex items-center px-4 py-2 text-xs font-medium h-16">
                                <div class="m-auto">
                                    <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M448 96h-64V64c0-35.35-28.65-64-64-64H192C156.7 0 128 28.65 128 64v32H64C28.65 96 0 124.7 0 160v256c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V160C512 124.7 483.3 96 448 96zM192 48h128c8.837 0 16 7.163 16 16v32h-160V64C176 55.16 183.2 48 192 48zM64 144h384c8.8 0 16 7.2 16 16v96h-416V160C48 151.2 55.2 144 64 144zM448 432H64c-8.8 0-16-7.2-16-16V304H192V320c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V304h144V416C464 424.8 456.8 432 448 432z"/></svg>
                                    Office
                                </div>
                            </a>
                            <a v-if="hasLink('RFIs') || hasLink('suppliers') || hasLink('holiday-requests') || hasLink('quality-assessments') || hasLink('templates') || hasLink('qa-trackers') || hasLink('vehicle-logs') || hasLink('payment-vouchers') || hasAnInventoryLink() ||hasLink('client-address-book') || hasLink('quotes-mismatch') || hasLink('costing') || hasLink('ezpz-pdf') || hasLink('product-list') || hasLink('on-site-id')" href="/manage" :class="{'rounded-none border-l-4 border-current text-secondary-verylightpeach': isActive('manage'), 'text-secondary-lavenderblue hover:text-secondary-verylightpeach': !isActive('manage')}" class=" group flex items-center px-4 py-2 text-xs font-medium h-16">
                                <div class="m-auto">
<!--                                    <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">&lt;!&ndash;! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. &ndash;&gt;<path d="M81.84 152.1C77.43 156.9 71.21 159.8 64.63 159.1C58.05 160.2 51.69 157.6 47.03 152.1L7.029 112.1C-2.343 103.6-2.343 88.4 7.029 79.03C16.4 69.66 31.6 69.66 40.97 79.03L63.08 101.1L118.2 39.94C127 30.09 142.2 29.29 152.1 38.16C161.9 47.03 162.7 62.2 153.8 72.06L81.84 152.1zM81.84 312.1C77.43 316.9 71.21 319.8 64.63 319.1C58.05 320.2 51.69 317.6 47.03 312.1L7.029 272.1C-2.343 263.6-2.343 248.4 7.029 239C16.4 229.7 31.6 229.7 40.97 239L63.08 261.1L118.2 199.9C127 190.1 142.2 189.3 152.1 198.2C161.9 207 162.7 222.2 153.8 232.1L81.84 312.1zM216 120C202.7 120 192 109.3 192 96C192 82.75 202.7 72 216 72H488C501.3 72 512 82.75 512 96C512 109.3 501.3 120 488 120H216zM192 256C192 242.7 202.7 232 216 232H488C501.3 232 512 242.7 512 256C512 269.3 501.3 280 488 280H216C202.7 280 192 269.3 192 256zM160 416C160 402.7 170.7 392 184 392H488C501.3 392 512 402.7 512 416C512 429.3 501.3 440 488 440H184C170.7 440 160 429.3 160 416zM64 448C46.33 448 32 433.7 32 416C32 398.3 46.33 384 64 384C81.67 384 96 398.3 96 416C96 433.7 81.67 448 64 448z"/></svg>-->
                                    <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M464 64C490.5 64 512 85.49 512 112V176C512 202.5 490.5 224 464 224H48C21.49 224 0 202.5 0 176V112C0 85.49 21.49 64 48 64H464zM464 112H320V176H464V112zM464 288C490.5 288 512 309.5 512 336V400C512 426.5 490.5 448 464 448H48C21.49 448 0 426.5 0 400V336C0 309.5 21.49 288 48 288H464zM464 336H192V400H464V336z"/></svg>
                                    Tools
                                </div>
                            </a>
                            <a v-if="hasLink('oss-daily-report') || hasLink('oss-weekly-report') || hasLink('oss-timesheet-builder')" :href="getLinkHref('oss-daily-report')" :class="{'rounded-none border-l-4 border-current text-secondary-verylightpeach': isActive('oss'), 'text-secondary-lavenderblue hover:text-secondary-verylightpeach': !isActive('oss')}" class=" group flex items-center py-2 text-xs font-medium h-16">
                                <div class="m-auto">
                                    <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1"   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 256c35.35 0 64-28.65 64-64s-28.65-64-64-64S128 156.7 128 192S156.7 256 192 256zM320 0H64C28.65 0 0 28.65 0 64v384c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V64C384 28.65 355.3 0 320 0zM336 448c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V64c0-8.822 7.178-16 16-16h256c8.822 0 16 7.178 16 16V448zM224 288H160c-44.18 0-80 35.82-80 80C80 376.8 87.16 384 96 384h192c8.836 0 16-7.164 16-16C304 323.8 268.2 288 224 288z"/></svg>

                                    On-Site Selfie
                                </div>

                            </a>
                            <a v-if="hasLink('cms-news')" :href="getLinkHref('cms-news')" :class="{'rounded-none border-l-4 border-current text-secondary-verylightpeach': isActive('urls/cms_news'), 'text-secondary-lavenderblue hover:text-secondary-verylightpeach': !isActive('cms-news')}" class=" group flex items-center py-2 text-xs font-medium h-16">
                                <div class="m-auto">
                                    <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M168 80c-13.3 0-24 10.7-24 24V408c0 8.4-1.4 16.5-4.1 24H440c13.3 0 24-10.7 24-24V104c0-13.3-10.7-24-24-24H168zM72 432c13.3 0 24-10.7 24-24V104c0-39.8 32.2-72 72-72H440c39.8 0 72 32.2 72 72V408c0 39.8-32.2 72-72 72H72c-39.8 0-72-32.2-72-72V112C0 98.7 10.7 88 24 88s24 10.7 24 24V408c0 13.3 10.7 24 24 24zM176 136c0-13.3 10.7-24 24-24H408c13.3 0 24 10.7 24 24v80c0 13.3-10.7 24-24 24H200c-13.3 0-24-10.7-24-24V136zm24 136h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H200c-13.3 0-24-10.7-24-24s10.7-24 24-24zm144 0h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H344c-13.3 0-24-10.7-24-24s10.7-24 24-24zM200 352h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H200c-13.3 0-24-10.7-24-24s10.7-24 24-24zm144 0h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H344c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/></svg>
                                    CMS News
                                </div>

                            </a>
                            <a v-if="hasLink('elmech-drive')" :href="getLinkHref('elmech-drive')" :class="{'rounded-none border-l-4 border-current text-secondary-verylightpeach': isActive('urls/elmech_drive'), 'text-secondary-lavenderblue hover:text-secondary-verylightpeach': !isActive('elmech-drive')}" class=" group flex items-center py-2 text-xs font-medium h-16">
                                <div class="m-auto">
                                    <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 80c-8.8 0-16 7.2-16 16V258c5.1-1.3 10.5-2 16-2H448c5.5 0 10.9 .7 16 2V96c0-8.8-7.2-16-16-16H64zM48 320v96c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V320c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zM0 320V96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V320v96c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320zm280 48a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm120-24a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                                    Elmech Drive
                                </div>

                            </a>
                            <a v-if="hasLink('cms-drive')" :href="getLinkHref('cms-drive')" :class="{'rounded-none border-l-4 border-current text-secondary-verylightpeach': isActive('urls/cms_drive'), 'text-secondary-lavenderblue hover:text-secondary-verylightpeach': !isActive('cms-drive')}" class=" group flex items-center py-2 text-xs font-medium h-16">
                                <div class="m-auto">
                                    <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 80c-8.8 0-16 7.2-16 16V258c5.1-1.3 10.5-2 16-2H448c5.5 0 10.9 .7 16 2V96c0-8.8-7.2-16-16-16H64zM48 320v96c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V320c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zM0 320V96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V320v96c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320zm280 48a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm120-24a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                                    CMScloud
                                </div>

                            </a>
                            <a v-if="hasLink('cctv')" :href="getLinkHref('cctv')" :class="{'rounded-none border-l-4 border-current text-secondary-verylightpeach': isActive('urls/cctv'), 'text-secondary-lavenderblue hover:text-secondary-verylightpeach': !isActive('cctv')}" class=" group flex items-center py-2 text-xs font-medium h-16">
                                <div class="m-auto">
                                    <svg class="w-5 h-5 text-secondary-lavenderblue fill-current m-auto mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M54.9 192.2l169.3 70.5c.1 0 .2 .1 .2 .1c.6 .2 1.1 .4 1.6 .7l92.2 38.4 160-106.7L134.9 52.2l-80 140zM185.2 298.5l-165.5-69c-8.5-3.5-15-10.5-18-19.2s-2-18.2 2.5-26.2l96-168c8-14 25.2-19.9 40.1-13.7l384 160c10.9 4.5 18.4 14.7 19.5 26.5s-4.3 23.2-14.1 29.7l-192 128c-8.9 5.9-20.2 7-30.1 2.9L229.5 317 182.4 440.5C178.9 449.8 170 456 160 456H48v32c0 13.3-10.7 24-24 24s-24-10.7-24-24V432 376c0-13.3 10.7-24 24-24s24 10.7 24 24v32h95.5l41.7-109.5zm211.8 47.1l132.9-88.6 36.7 16.3c7.9 3.5 11.6 12.8 8.3 20.8l-40 96c-1.7 4-4.9 7.2-8.9 8.7s-8.5 1.5-12.5-.3L396.9 345.6z"/></svg>

                                    CCTV
                                </div>

                            </a>
                        </nav>
                    </div>
                    <div class="flex-shrink-0 flex p-4 w-full items-center grid grid-cols-1">
                        <div v-click-outside="closeProfileMenu" class="relative">
                            <a @click="profileMenuIsOpen = true" class="cursor-pointer">
                                <img
                                    class="inline-flex w-14 items-center border-2 border-read-500 rounded-full mb-3" v-if="user.profile_image" :src="'/storage/profile_images/' + user.profile_image">
                                <img
                                    class="inline-flex w-14 items-center border-2 border-read-500 rounded-full mb-3" v-else-if="user.oss_profile_image && user.oss_profile_image.includes('on-site-selfie')" :src="user.oss_profile_image.replace('public', '/storage')">
                                <div v-else class="flex justify-center">
                                            <span
                                                class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
                                                    <span class="text-md font-medium leading-none text-white">{{ initials }}</span>
                                                </span>
                                </div>
                                <span v-if="userHasNotifications"
                                      class="absolute top-0 left-0 ml-11 block h-3 w-3 rounded-full ring-2 ring-white bg-red-400"></span>
                            </a>
                            <div v-show="profileMenuIsOpen"  class="absolute bottom-0 left-0 ml-16 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                <div class="py-1" role="none">
                                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                                    <a v-if="hasLink('current-user')" :href="getLinkHref('current-user')"  class="cursor-pointer text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">View Profile</a>
                                    <a @click.prevent="displaySettings = true; closeProfileMenu()"  class="cursor-pointer text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Account settings</a>
                                    <a v-if="canAccessHelpMenu()" :href="getLinkHref('cms-help-hub')" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">CMS Help Hub</a>
                                    <a href="/notifications" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-3">View Notifications</a>
                                    <a href="#" @click.prevent="logout" class="text-gray-700 block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1" id="menu-item-4">Sign out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pl-24 flex flex-col flex-1 mb-8 h-full" >
                <main class="flex-1 h-full" tabindex="0" :class="{'bg-cover': backgroundImageUrl !== null}" :style="[backgroundImageUrl !== null ?{backgroundImage:`url(${backgroundImageUrl})`, backgroundPosition: 'top 80px left 0'}: null]">
                   <div class="pb-12">
                        <slot v-bind:links="links" v-bind:actions="actions" v-bind:logo="logo"></slot>
                   </div>
                </main>
            </div>
        </div>

        <settings-slide-over v-if="displaySettings" :title="`Settings`" :data-cy="`button-settings`" :submitting="submittingModal"
                             @removeFromDisplay="resetSettings()" @perform="perform()" class="settings-slide-over">
            <property-or-field class="mt-4" title="First Name" :action="userEditAction" fieldId="first_name" v-model="formEditData['first_name']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Last Name/ Family Name" :action="userEditAction" fieldId="surname" v-model="formEditData['surname']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Email" :action="userEditAction" fieldId="email" v-model="formEditData['email']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Contact Number" :action="userEditAction" fieldId="contact_number" v-model="formEditData['contact_number']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Profile Image" :action="userEditAction" fieldId="profile_image" v-model="formEditData['profile_image']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Background Image" :action="userEditAction" fieldId="background_image" v-model="formEditData['background_image']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Contact Preference" :action="userEditAction" fieldId="contact_preference" v-model="formEditData['contact_preference']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Exclude From Address Book" :action="userEditAction" fieldId="exclude_from_address_book" v-model="formEditData['exclude_from_address_book']" scope="user-details"></property-or-field>
            <property-or-field class="mt-4" title="Sort Users By" :action="userEditAction" fieldId="sort_users_by" v-model="formEditData['sort_users_by']" scope="user-details"></property-or-field>

            <button v-if="showPasswordField === false" @click="showPasswordField = true" data-cy="edit-password" type="button" class="inline-flex mr-3 mt-4 justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Edit Password
            </button>

            <property-or-field class="mt-4" v-if="showPasswordField" title="Current Password" :action="userResetPasswordAction" fieldId="current_password" v-model="formPasswordData['current_password']" scope="password"></property-or-field>
            <property-or-field class="mt-4" v-if="showPasswordField" title="Password" :action="userResetPasswordAction" fieldId="password" v-model="formPasswordData['password']" scope="password"></property-or-field>

        </settings-slide-over>
    </div>
</template>

<script>
import Siren from "super-siren";
import {mapGetters} from "vuex";
import Notification from "@/components/Notification";
import NotificationWithoutTimeout from "@/components/NotificationWithoutTimeout";
import SettingsSlideOver from "@/components/SettingsSlideOver";
import PropertyOrField from "@/components/PropertyOrField";
import vClickOutside from 'v-click-outside';
import formatting from "@/mixins/formatting";
import axios from "axios";

export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
    inject: ["$validator"],
    mixins: [formatting],
    name: "mainTemplate",
    data(){
        return{
            links: null,
            actions:null,
            profileMenuIsOpen:false,
            displaySettings:false,
            userHasNotifications:false,
            userEditAction:null,
            userResetPasswordAction:null,
            showPasswordField: false,
            logo: null,
            submittingModal: false
        }
    },
    components:{
        PropertyOrField,
        SettingsSlideOver,
      NotificationWithoutTimeout,
      Notification
    },
    props:{
        backgroundImageUrl: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
            message: "getMessage",
            messageWithoutTimeout: "getMessageWithoutTimeout"
        }),
        formEditData() {
            if (!this.userEditAction.fields) {
                return {};
            }
            var payload = {};
            this.userEditAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        formPasswordData() {
            if (!this.userResetPasswordAction.fields) {
                return {};
            }
            var payload = {};
            this.userResetPasswordAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        initials() {
            if (this.user && this.user.name) {
                return this.user.name
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
    },
    mounted() {
        let follow = Siren.Helper.follow;
        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
            let siren = res.body;
            this.links = siren.links;
            this.actions = siren.actions;
            return res;
        }, this).then(
            follow("current-user")
        )
            .then(res => {
                console.log(2);
                let siren = res.body;
                this.user.name = siren.properties.get('name');
                this.user.has_notifications = siren.properties.get('has_notifications');
                this.userHasNotifications = this.user.has_notifications;
                this.user.profile_image = siren.properties.get('profile_image');
                this.user.oss_profile_image = siren.properties.get('oss_profile_image');
                this.userEditAction = siren.findActionByName('update-user');
                this.userResetPasswordAction= siren.findActionByName('reset-user-password');

                let client = siren.embeddedEntities.filter(entity => entity.entity.classes.contains('client')).first();
                if (client !== undefined){
                    let logo = client.entity.properties.get('logo');
                    this.logo = logo;
                    if (this.logo !== null) {
                        if (logo && logo.startsWith('public/')) {
                            this.logo = this.logo.replace('public/', '');
                        }
                        this.logo = process.env.VUE_APP_API_URL + '/storage/logo/' + this.logo
                    }
                }

                let directSupplier = siren.embeddedEntities.filter(entity => entity.entity.classes.contains('direct-supplier')).first();
                if(directSupplier !== undefined){
                    let logo = directSupplier.entity.properties.get('logo');
                    this.logo = logo;
                    if (logo.startsWith('public/')){
                        this.logo = this.logo.replace('public/', '');
                    }
                    this.logo = process.env.VUE_APP_API_URL + '/storage/logo/' + this.logo;
                }

                console.log(1);
                if(siren.properties.get('is_restricted')){
                    this.$store.dispatch("logout").then(() => this.$router.push("/login"));
                }
            }, this).catch(error => {
                console.log(error);
            console.log('error fetching user data');
        })
    },
    methods: {
        isActive(name){
            if(name === 'orders'){
                return this.$route.path.includes('/orders') || this.$route.path.includes('/tender-order') || this.$route.path.includes('/return-requests') || this.$route.path.includes('/rental-items') ||
                    this.$route.path.includes('take-off-orders') || this.$route.path.includes('/schedule-manager');
            }
            if(name === 'office'){
                return (this.$route.path.includes('/office') || this.$route.path.includes('/invoices') || this.$route.path.includes('/invoices-in') || this.$route.path.includes('/credit-notes') ||
                    this.$route.path.includes('/sages')  || this.$route.path.includes('/reports') ||
                    this.$route.path.includes('/invoice-reports')) && !this.$route.path.includes('/office-items');
            }
            if(name === 'manage'){
                return this.$route.path.includes('/manage') || this.$route.path.includes('/rfis') || this.$route.path.includes('/suppliers') || this.$route.path.includes('/holiday-requests') ||
                    this.$route.path.includes('/qa-tools') || this.$route.path.includes('/quality-assessments') || this.$route.path.includes('/qa-trackers') || this.$route.path.includes('/qa-categories') ||
                    this.$route.path.includes('/payment-vouchers')|| this.$route.path.includes('/site-vehicle-entry-logs')|| this.$route.path.includes('/evac') ||
                    this.$route.path.includes('/materials')|| this.$route.path.includes('/office-items')|| this.$route.path.includes('/vehicles') || this.$route.path.includes('/inventory') ||
                    this.$route.path.includes('/costing') || this.$route.path.includes('/quotes-mismatch') || this.$route.path.includes('/client-address-book') || this.$route.path.includes('/pre-checks')||
                    this.$route.path.includes('/subscriptions')|| this.$route.path.includes('/product-library')|| this.$route.path.includes('/product-categories')|| this.$route.path.includes('/pay-n-send');
            }
            if(name === 'oss'){
                return this.$route.path.includes('/onsiteselfie/daily-reports') || this.$route.path.includes('/on-site-selfie/weekly-reports') || this.$route.path.includes('/on-site-selfie/timesheet-builder');
            }
            if(name === 'hub'){
                return this.$route.path.includes('/help-hub/cms');
            }
            if(name === 'admin'){
                return this.$route.path.includes('/admin') || this.$route.path.includes('/clients') || this.$route.path.includes('/users') || this.$route.path.includes('/default-client-users') ||
                    this.$route.path.includes('/companies') || this.$route.path.includes('/direct-suppliers') || this.$route.path.includes('/manufacturers') || this.$route.path.includes('/products') ||
                    this.$route.path.includes('/product-types') || this.$route.path.includes('/dashboards') || this.$route.path.includes('/dashboard-widgets') || this.$route.path.includes('/invite-client') ||
                    this.$route.path.includes('/invite-user');
            }
            if(name === 'cms-news'){
                return this.$route.path.includes('/cms-news') && !this.$route.path.includes('/admin');
            }
            if(this.$route.path.includes('/' + name)){
                return true;
            };
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        logout() {
            this.$store.dispatch("logout").then(() => this.$router.push("/login"));
        },
        canAccessHelpMenu(){
            return this.hasLink('cms-help-hub')
        },
        hasOfficeLink(){
            return this.hasSagesLink() || this.hasLink('invoices') || this.hasLink('invoices-in') || this.hasLink('unapproved-invoices-in') || this.hasLink('credit-notes') || this.hasLink('reports');
        },
        hasSagesLink() {
            return this.hasLink('sages') || this.hasLink('sage-descriptions') || this.hasLink('sage-nominal-codes');
        },
        hasAnInventoryLink(){
            return this.hasLink('materials') || this.hasLink('office-items') || this.hasLink('vehicles')
        },
        hasAnAdminLink(){
            return this.hasLink('admin.clients') || this.hasLink('admin.client') || this.hasLink('admin.projects') || this.hasLink('admin.contracts') || this.hasLink('admin.users') || this.hasLink('admin.suppliers') || this.hasLink('admin.companies') || this.hasLink('admin.products')|| this.hasLink('admin.product-types') || this.hasLink('admin.direct-suppliers') || this.hasLink('admin.direct-supplier') || this.hasAction('invite-user') || this.hasAction('invite-client');
        },
        hasAction(key){
            if(this.actions === null){
                return 0;
            }
            return this.actions.filter(action => action.name === key).size;
        },
        resetSettings(){
            this.showPasswordField = false;
            this.displaySettings = false;
            this.formEditData.name = this.userEditAction.fields.get('name').value;
            this.formEditData.email = this.userEditAction.fields.get('email').value;
            this.formEditData.contact_number = this.userEditAction.fields.get('contact_number').value;
            this.formPasswordData.password = '';
            this.formEditData.profile_image = '';
            this.errors.clear("user-details");
            this.errors.clear("password");
        },
        perform() {
            this.displaySettings = true;
            this.submittingModal = true;
            this.errors.clear("user-details");
            this.errors.clear("password");
            this.userEditAction.perform(this.formEditData).then(res => {
                if (this.showPasswordField) {
                    this.userResetPasswordAction.perform(this.formPasswordData).then(res => {
                        this.successfullySubmitted(res);
                    }).catch(error => {
                        this.submittingModal = false;
                        if(error.status !== 422) {
                            alert('There was an error processing your request.' + error);
                        }
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text),
                            'password'
                        );
                        this.$emit('failure');
                    })
                }else{
                    this.successfullySubmitted(res);
                }
            }).catch(error => {
                this.submittingModal = false;
                if(error.status !== 422) {
                    alert('There was an error processing your request.' + error);
                }
                this.$setLaravelValidationErrorsFromResponse(
                    JSON.parse(error.response.text),
                    "user-details"
                );
                this.$emit('failure');
            });
        },
        successfullySubmitted(res){
            this.displaySettings = false;
            this.submittingModal = false;
            this.$router.go(0);
            this.$emit('success', res);
        },
        closeDisplaySettigns()
        {
            this.displaySettings = false;
        },
        closeProfileMenu()
        {
            this.profileMenuIsOpen = false
        },
        getAdminLink()
        {
            if(!this.hasLink('admin.clients') && this.hasLink('admin.client')){
                return this.getLinkHref('admin.client');
            }else{
                return process.env.VUE_APP_BASE_URL + '/admin';
            }
        },
        isUrlStaging(){
            return process.env.VUE_APP_BASE_URL === 'https://test.companiesms.co.uk'
        },
        getPreviousUser(){
            return window.localStorage.getItem('previous_user')
        },
        signInPreviousUser(){
            let previousUser = this.getPreviousUser();
            axios.post(process.env.VUE_APP_API_URL + "/api/users/" + previousUser + "/login-original-user", {}, {
                headers: { Authorization: `Bearer ${this.accessToken}` }
            }).then(res => {
                //console.log(res.data.properties);
                this.$store
                    .dispatch("loginAsDifferentUser", {
                        user: res.data.properties["user"],
                        accessToken: res.data.properties["access_token"],
                        previousUser: res.data.properties['previous_user']
                    }).then(( response ) =>  {
                        this.$router.go(0);
                    })
                }).catch(({ response }) => {alert(response.data.message)});
        }
    }
}
</script>

<style scoped>

</style>
