<template>
    <tbody v-if="newItemAction">
    <new-invoice-item @removeItem="removeItem(key)" ref="newInvoiceItem" :response="response" v-for="value,key in qty"
                    :key="key" :keyProp="key" :defaultValues="value" :scope="getScope(key)" v-if="value !== null"
                    :action="response"></new-invoice-item>

    <tr class="bg-white text-center">
        <td colspan="100">
            <div class="grid grid-cols-3 p-3">
                <button @click="addNewItem()" data-cy="add-item"
                        class="col-start-2 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition duration-150 ease-in-out">
                    + New Invoice Item
                </button>
                <upload-items-from-csv file-template="description,unit_price" file-template-name="invoice-items-upload-template.csv" @itemsImported="addImportedItems"></upload-items-from-csv>
            </div>
        </td>
    </tr>
    </tbody>
</template>

<script>
    import NewInvoiceItem from './NewInvoiceItem';
    import UploadItemsFromCsv from './UploadItemsFromCsv';

    export default {
        inject: ["$validator"],
        components: {
            NewInvoiceItem,
            UploadItemsFromCsv
        },
        props: {
            response: {
                type: Object
            },
        },
        data() {
            return {
                qty: []
            }
        },
        computed: {
            newItemAction() {
                return this.response.body.actions.filter(action => action.name === 'add-new-item').first();
            },
        },
        methods: {
            addImportedItems(obj) {
                obj.forEach(object => {
                    this.qty.push(object);
                });
                this.$emit('change');
                this.$store.dispatch(
                    "setMessage",
                    "CSV imported, please check the rows & save the order to persist the changes."
                );
            },
            removeItem(key) {
                this.$set(this.qty, key, null);
            },
            addNewItems() {
                let addNewItemPromises = [];
                if (this.$refs.newInvoiceItem) {
                    this.$refs.newInvoiceItem.forEach(function (row, index) {
                        addNewItemPromises.push(row.create().catch(error => {
                            throw {
                                error: error,
                                scope: this.getScope(index)
                            };
                        }));
                    }, this);
                }
                return addNewItemPromises;
            },
            getScope(index) {
                return 'new-invoice-item-row-' + index;
            },
            addNewItem() {
                this.qty.push({});
                this.$emit('change');
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
