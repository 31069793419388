<template>
    <div class="order-summary">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Dashboard Summary</h3>
        <div class="generic-summary p-4 bg-white" :key="key">
            <div class="grid grid-cols-3 gap-4">
                <property-or-field @input="change" :properties="properties" title="Notification Message" :action="updateAction" fieldId="message" propertyKey="message" v-model="updateActionData['message']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Notification Details" :action="updateAction" fieldId="details" propertyKey="details" v-model="updateActionData['details']"></property-or-field>
            </div>
            <p class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">CMS Hub Adverts</p>
            <div class="grid grid-cols-3 gap-4 pt-4">
                <property-or-field @input="change" :properties="properties" title="Advert File 1" comment="Ideal size 225px x 225px" :action="updateAction" fieldId="advert_file1" propertyKey="advert_file1" v-model="updateActionData['advert_file1']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert Link 1" :action="updateAction" fieldId="advert_link1" propertyKey="advert_link1" v-model="updateActionData['advert_link1']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert File 2" comment="Ideal size 225px x 225px" :action="updateAction" fieldId="advert_file2" propertyKey="advert_file2" v-model="updateActionData['advert_file2']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert Link 2" :action="updateAction" fieldId="advert_link2" propertyKey="advert_link2" v-model="updateActionData['advert_link2']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert File 3" comment="Ideal size 225px x 225px" :action="updateAction" fieldId="advert_file3" propertyKey="advert_file3" v-model="updateActionData['advert_file3']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert Link 3" :action="updateAction" fieldId="advert_link3" propertyKey="advert_link3" v-model="updateActionData['advert_link3']"></property-or-field>

            </div>

            <p class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">App Adverts</p>
            <div class="grid grid-cols-3 gap-4 pt-4">
                <property-or-field @input="change" :properties="properties" title="Advert File 1" comment="Ideal size 180px x 180px" :action="updateAction" fieldId="app_advert_file1" propertyKey="app_advert_file1" v-model="updateActionData['app_advert_file1']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert Link 1" :action="updateAction" fieldId="app_advert_link1" propertyKey="app_advert_link1" v-model="updateActionData['app_advert_link1']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert File 2" comment="Ideal size 180px x 180px" :action="updateAction" fieldId="app_advert_file2" propertyKey="app_advert_file2" v-model="updateActionData['app_advert_file2']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert Link 2" :action="updateAction" fieldId="app_advert_link2" propertyKey="app_advert_link2" v-model="updateActionData['app_advert_link2']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert File 3" comment="Ideal size 180px x 180px" :action="updateAction" fieldId="app_advert_file3" propertyKey="app_advert_file3" v-model="updateActionData['app_advert_file3']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Advert Link 3" :action="updateAction" fieldId="app_advert_link3" propertyKey="app_advert_link3" v-model="updateActionData['app_advert_link3']"></property-or-field>

            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from '../PropertyOrField';
    export default {
        inject: ["$validator"],
        components: {
            PropertyOrField,
        },
        data() {
            return {
                key : 0
            }
        },
        props: {
            response: {
                type: Object
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update-dashboard').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            properties(){
                return this.response.body.properties;
            }
        },
        methods: {
            update(){
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
