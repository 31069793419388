<template>
    <div v-if="advertFile" class="max-w-full pb-3 mb-3"><a :href="advertLink" target="_blank"><img class="mx-auto" :src="advertFile"></a></div>
</template>

<script>
    import Siren from "super-siren";
    import { mapGetters } from "vuex";
    export default {
        name: "MenuAdvert",
        data() {
            return {
                advertFile : null,
                advertLink : null
            };
        },
        created() {
            let follow = Siren.Helper.follow;

            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

            Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
                Siren.get(process.env.VUE_APP_API_URL + "/api/")
                    .then(
                        follow("dashboards")
                    )
                    .then(res => {
                        var properties = res.body.properties;
                        this.advertFile = process.env.VUE_APP_API_URL + '/storage/adverts/' + properties.get('advert_file');
                        this.advertLink = properties.get('advert_link');
                    }, this);
            })
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        }
    }

</script>

<style scoped>

</style>
