<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        CMS URLs
                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-1">
                                        <div class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                    CMS URL Details
                                                    <svg v-if="response.body.actions.filter(action => action.name==='update').first()" @click="displayCmsNewsDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </dt>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">CMS News URL</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{getUrlEntity(response, 'cms_news').entity.properties.get('url')}}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Elmech Drive URL</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{getUrlEntity(response, 'elmech_drive').entity.properties.get('url')}}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">CMScloud URL</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{getUrlEntity(response, 'cms_drive').entity.properties.get('url')}}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">CCTV URL</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{getUrlEntity(response, 'cctv').entity.properties.get('url')}}
                                                </dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">EzPz PDF URL</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{getUrlEntity(response, 'ezpz_pdf').entity.properties.get('url')}}
                                                </dd>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                    <update-cms-news-form v-if="response.body.actions.filter(action => action.name==='update')" :displayModal="displayCmsNewsDetails" :action="response.body.actions.filter(action => action.name==='update').first()" @removeFromDisplay="displayCmsNewsDetails=false" :onSuccessPath="$router.currentRoute.fullPath"></update-cms-news-form>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import PageHeader from "@/v2/components/Header";
import UpdateCmsNewsForm from "@/v2/components/forms/UpdateCmsNewsForm";
export default {
    name: "CmsNews",
    data() {
        return {
            displayCmsNewsDetails: false
        }
    },
    components: {
        UpdateCmsNewsForm,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        getUrlEntity(response, urlName){
            return response.body.entities.filter(entity => entity.rels.contains('cms-url') && entity.entity.properties.get('name') === urlName).first()
        }
    }
}
</script>

<style scoped>

</style>
