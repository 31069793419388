<template>
    <div>
        <a-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>
      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">
            <component :is="getFieldComponent(action.fields.get('name'))" :field="action.fields.get('name')" v-model="formData['name']">
            </component>
            <div v-show="errors.has('name')" class="text-red-600">{{ errors.first('name') }}</div>

            <component :is="getFieldComponent(action.fields.get('prefix'))" :field="action.fields.get('prefix')" v-model="formData['prefix']">
            </component>
            <div v-show="errors.has('prefix')" class="text-red-600">{{ errors.first('prefix') }}</div>

            <component :is="getFieldComponent(action.fields.get('approved'))" :field="action.fields.get('approved')" v-model="formData['approved']">
            </component>
            <div v-show="errors.has('approved')" class="text-red-600">{{ errors.first('approved') }}</div>

            <component :is="getFieldComponent(action.fields.get('logo'))" :field="action.fields.get('logo')" v-model="formData['logo']">
            </component>
            <div v-show="errors.has('logo')" class="text-red-600">{{ errors.first('logo') }}</div>

            <component :is="getFieldComponent(action.fields.get('approval_threshold'))" :field="action.fields.get('approval_threshold')" v-model="formData['approval_threshold']">
            </component>
            <div v-show="errors.has('approval_threshold')" class="text-red-600">{{ errors.first('approval_threshold') }}</div>

            <component :is="getFieldComponent(action.fields.get('vat_number'))" :field="action.fields.get('vat_number')" v-model="formData['vat_number']">
            </component>
            <div v-show="errors.has('vat_number')" class="text-red-600">{{ errors.first('vat_number') }}</div>

            <component :is="getFieldComponent(action.fields.get('company_registration_number'))" :field="action.fields.get('company_registration_number')" v-model="formData['company_registration_number']">
            </component>
            <div v-show="errors.has('company_registration_number')" class="text-red-600">{{ errors.first('company_registration_number') }}</div>

            <component :is="getFieldComponent(action.fields.get('address'))" :field="action.fields.get('address')" v-model="formData['address']">
            </component>
            <div v-show="errors.has('address')" class="text-red-600">{{ errors.first('address') }}</div>

            <component :is="getFieldComponent(action.fields.get('account_used'))" :field="action.fields.get('account_used')" v-model="formData['account_used']">
            </component>
            <div v-show="errors.has('account_used')" class="text-red-600">{{ errors.first('account_used') }}</div>

            <component :is="getFieldComponent(action.fields.get('direct_supplier_id'))" :field="action.fields.get('direct_supplier_id')" v-model="formData['direct_supplier_id']">
            </component>
            <div v-show="errors.has('direct_supplier_id')" class="text-red-600">{{ errors.first('direct_supplier_id') }}</div>

            <component :is="getFieldComponent(action.fields.get('department'))" :field="action.fields.get('department')" v-model="formData['department']">
            </component>
            <div v-show="errors.has('department')" class="text-red-600">{{ errors.first('department') }}</div>

            <component :is="getFieldComponent(action.fields.get('service_fee'))" :field="action.fields.get('service_fee')" v-model="formData['service_fee']">
            </component>
            <div v-show="errors.has('service_fee')" class="text-red-600">{{ errors.first('service_fee') }}</div>

            <component :is="getFieldComponent(action.fields.get('invoice_email'))" :field="action.fields.get('invoice_email')" v-model="formData['invoice_email']">
            </component>
            <div v-show="errors.has('invoice_email')" class="text-red-600">{{ errors.first('invoice_email') }}</div>

            <component :is="getFieldComponent(action.fields.get('modules'))" :field="action.fields.get('modules')" v-model="formData['modules']">
            </component>
            <div v-show="errors.has('modules')" class="text-red-600">{{ errors.first('modules') }}</div>

            <component :is="getFieldComponent(action.fields.get('procurement_package'))" :field="action.fields.get('procurement_package')" v-model="formData['procurement_package']">
            </component>
            <div v-show="errors.has('procurement_package')" class="text-red-600">{{ errors.first('procurement_package') }}</div>

            <component :is="getFieldComponent(action.fields.get('oss_package'))" :field="action.fields.get('oss_package')" v-model="formData['oss_package']">
            </component>
            <div v-show="errors.has('oss_package')" class="text-red-600">{{ errors.first('oss_package') }}</div>

            <component :is="getFieldComponent(action.fields.get('apps_package'))" :field="action.fields.get('apps_package')" v-model="formData['apps_package']">
            </component>
            <div v-show="errors.has('apps_package')" class="text-red-600">{{ errors.first('apps_package') }}</div>

            <div v-show="errors.has('items')" class="text-red-600 text-sm">{{ errors.first('items') }}</div>

        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";

export default {
    name: "CreateClientForm",
    data() {
        return {
            displayModal: false,
            submittingModal: false
        }
    },
    components: {
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        }
    },
}
</script>

<style scoped>

</style>
