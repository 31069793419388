<template>
    <div class="order-summary">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Client Summary</h3>
        <div class="generic-summary p-4 bg-white" :key="key">
            <div class="grid grid-cols-3 gap-4">
                <property-or-field @input="change" :properties="properties" title="Name" :action="updateAction" fieldId="name" propertyKey="name" v-model="updateActionData['name']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Prefix" :action="updateAction" fieldId="prefix" propertyKey="prefix" v-model="updateActionData['prefix']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Logo" :action="updateAction" fieldId="logo" propertyKey="logo" v-model="updateActionData['logo']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Employer Code" :action="updateAction" fieldId="employer_code" propertyKey="employer_code" v-model="updateActionData['employer_code']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Requirements" :action="updateAction" fieldId="requirements" propertyKey="requirements" v-model="updateActionData['requirements']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Company Registration Number" :action="updateAction" fieldId="company_registration_number" propertyKey="company_registration_number" v-model="updateActionData['company_registration_number']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Vat Number" :action="updateAction" fieldId="vat_number" propertyKey="vat_number" v-model="updateActionData['vat_number']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Enabled Features" :action="updateAction" fieldId="additional_enabled_features" propertyKey="additional_enabled_features" v-model="updateActionData['additional_enabled_features']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Modules" :action="updateAction" fieldId="modules" propertyKey="modules" v-model="updateActionData['modules']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="OSS Organisation ID" :action="updateAction" fieldId="oss_organisation_id" propertyKey="oss_organisation_id" v-model="updateActionData['oss_organisation_id']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Registered Address" :action="updateAction" fieldId="address" propertyKey="address" v-model="updateActionData['address']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Is Approved" :action="updateAction" fieldId="approved" propertyKey="approved" v-model="updateActionData['approved']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Account Used" :action="updateAction" fieldId="account_used" propertyKey="account_used" v-model="updateActionData['account_used']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Direct Supplier Id" :action="updateAction" fieldId="direct_supplier_id" propertyKey="direct_supplier_id" v-model="updateActionData['direct_supplier_id']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Department" :action="updateAction" fieldId="department" propertyKey="department" v-model="updateActionData['department']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Service Fee (%)" :action="updateAction" fieldId="service_fee" propertyKey="service_fee" v-model="updateActionData['service_fee']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Use Camera For On Site Confirmation" :action="updateAction" fieldId="use_camera_for_on_site_confirmation" propertyKey="use_camera_for_on_site_confirmation" v-model="updateActionData['use_camera_for_on_site_confirmation']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Procurement Package" :action="updateAction" fieldId="procurement_package" propertyKey="procurement_package" v-model="updateActionData['procurement_package']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="On-Site Selfie Package" :action="updateAction" fieldId="oss_package" propertyKey="oss_package" v-model="updateActionData['oss_package']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Apps Package" :action="updateAction" fieldId="apps_package" propertyKey="apps_package" v-model="updateActionData['apps_package']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Is Restricted" :action="updateAction" fieldId="is_restricted" propertyKey="is_restricted" v-model="isRestricted" :includeNullOption="false"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Admin That Can Login" :action="updateAction" fieldId="main_admin_when_restricted" propertyKey="main_admin_when_restricted" v-model="updateActionData['main_admin_when_restricted']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Restricted Message to Admin" :action="updateAction" fieldId="restricted_message" propertyKey="restricted_message" v-model="updateActionData['restricted_message']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Order Cut Off Time (All Clients)" :action="updateAction" fieldId="order_cut_off" propertyKey="order_cut_off" v-model="updateActionData['order_cut_off']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Credit Limit" :action="updateAction" fieldId="credit_limit" propertyKey="credit_limit" v-model="updateActionData['credit_limit']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Senior Approval Threshold" :action="updateAction" fieldId="senior_approval_threshold" propertyKey="senior_approval_threshold" v-model="updateActionData['senior_approval_threshold']"></property-or-field>
                <property-or-field @input="change" :properties="properties" title="Senior Approval Hide Activity" :action="updateAction" fieldId="senior_approval_hide_activity" propertyKey="senior_approval_hide_activity" v-model="updateActionData['senior_approval_hide_activity']"></property-or-field>
            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from '../PropertyOrField';
    export default {
        inject: ["$validator"],
        components: {
            PropertyOrField,
        },
        data() {
            return {
                key : 0,
                isRestricted: null
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update-client').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if (field.value !== undefined && field.name === 'is_restricted') this.isRestricted = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                }.bind(this));
                return payload;
            },
            properties(){
                return this.response.body.properties;
            }
        },
        methods: {
            update(){
                this.updateActionData['is_restricted'] = this.isRestricted;
                return this.updateAction.perform(this.updateActionData);
            },
            change(){
                this.$emit('change');
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
