<template>
    <div>
        <a-button :class="additionalButtonClasses" v-if="showButton" @click.native="$emit('displayModal')" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>
        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="$emit('removeFromDisplay')" @perform="perform()">
            <p class="text-sm text-gray-900 italic">By entering your details here you agree that you are happy to have this viewed when your On-Site ID sticker is scanned.</p>
            <component v-if="getField('date_of_birth')" :is="getFieldComponent(getField('date_of_birth'))" :field="getField('date_of_birth')" v-model="formData[getField('date_of_birth').name]">
            </component>
            <div v-show="errors.has(getField('date_of_birth').name)" class="text-red-600">{{ errors.first(getField('date_of_birth').name) }}</div>

            <component v-if="getField('medical_conditions')" :is="getFieldComponent(getField('medical_conditions'))" :field="getField('medical_conditions')" v-model="formData[getField('medical_conditions').name]">
            </component>
            <div v-show="errors.has(getField('medical_conditions').name)" class="text-red-600">{{ errors.first(getField('medical_conditions').name) }}</div>

            <component v-if="getField('blood_type')" :is="getFieldComponent(getField('blood_type'))" :field="getField('blood_type')" v-model="formData[getField('blood_type').name]">
            </component>
            <div v-show="errors.has(getField('blood_type').name)" class="text-red-600">{{ errors.first(getField('blood_type').name) }}</div>

            <component v-if="getField('allergies')" :is="getFieldComponent(getField('allergies'))" :field="getField('allergies')" v-model="formData[getField('allergies').name]">
            </component>
            <div v-show="errors.has(getField('allergies').name)" class="text-red-600">{{ errors.first(getField('allergies').name) }}</div>

            <component v-if="getField('need_to_know_information')" :is="getFieldComponent(getField('need_to_know_information'))" :field="getField('need_to_know_information')" v-model="formData[getField('need_to_know_information').name]">
            </component>
            <div v-show="errors.has(getField('need_to_know_information').name)" class="text-red-600">{{ errors.first(getField('need_to_know_information').name) }}</div>

            <template v-if="getField('cscs_card') && getField('expiry_date') && getField('reminder_limit')">
                <component v-if="getField('cscs_card')" :is="getFieldComponent(getField('cscs_card'))" :field="getField('cscs_card')" v-model="formData[getField('cscs_card').name]">
                </component>
                <div v-show="errors.has(getField('cscs_card').name)" class="text-red-600">{{ errors.first(getField('cscs_card').name) }}</div>
                <component v-if="getField('expiry_date')" :is="getFieldComponent(getField('expiry_date'))" :field="getField('expiry_date')" v-model="formData[getField('expiry_date').name]">
                </component>
                <div v-show="errors.has(getField('expiry_date').name)" class="text-red-600">{{ errors.first(getField('expiry_date').name) }}</div>
                <component v-if="getField('reminder_limit')" :is="getFieldComponent(getField('reminder_limit'))" :field="getField('reminder_limit')" v-model="formData[getField('reminder_limit').name]">
                </component>
                <div v-show="errors.has(getField('reminder_limit').name)" class="text-red-600">{{ errors.first(getField('reminder_limit').name) }}</div>
            </template>
        </slide-over>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";

export default {
    name: "UpdateOnSiteIdForm",
    data() {
        return {
            submittingModal: false
        }
    },
    components: {
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        displayModal:{
            type: Boolean,
            default: false
        },
        showButton:{
            type: Boolean,
            default: false
        },
        buttonType:{
            type: String,
            default: 'primary'
        }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            console.log(this.formData);
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        }
    },
}
</script>

<style scoped>

</style>
