<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <supplier-rating-header
                            :response="response"></supplier-rating-header>
                        <div class="grid grid-cols-4 min-h-screen">
                            <div
                                class="col-span-4 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">

                                <supplier-rating-summary ref="summary"  :response="response" @change="change"></supplier-rating-summary>
                            </div>
                        </div>
                    </template>
                    <div v-if="hasChangesWaitingOnSave"
                         style="right:0px;left:16rem;"
                         class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                        <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                            Company
                        </primary-button>
                    </div>
                </div>

            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../../components/FetchSirenRoot';
    import Loading from '../../components/Loading';
    import PrimaryButton from "../../components/PrimaryButton";
    import MainTemplate from "../../templates/MainTemplate";
    import SupplierRatingsHeader from "@/components/Headers/SupplierRatingsHeader";
    import SupplierRatingSummary from "@/components/Summaries/SupplierRatingSummary";
    import SupplierRatingHeader from "@/components/Headers/SupplierRatingHeader";

    export default {
        inject: ["$validator"],
        components: {
            SupplierRatingHeader,
            SupplierRatingSummary,
            SupplierRatingsHeader,
            MainTemplate,
            FetchRootSiren,
            Loading,
            PrimaryButton,
        },
        data() {
            return {
                hasChangesWaitingOnSave: false,
                loading:false
            }
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },
        },
        methods: {
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            async saveData() {
                this.loading = true;
                this.$refs.summary.update().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'The supplier was saved successfully'
                    );
                    this.hasChangesWaitingOnSave = false;
                    this.loading = false;
                }).catch(error => {
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
        },
    }
</script>

<style scoped>

</style>
