<template>
    <div class="container table-filter" data-cy="table-filter" v-if="action">
        <form @submit.prevent="performAction(action,actionData)" class="w-full">
            <div class="flex justify-between items-end item">

                <template v-for="(field, index) in action.fields">
                    <component class="input-item" :is="getFieldComponent(field[1].type)" :field="field[1]"
                               v-model="actionData[field[1].name]" :key="index">
                    </component>
                </template>
                <button data-cy="search" style="min-height:39px" @click="performAction(action,actionData)"
                        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    <span>Search</span>
                </button>
            </div>

        </form>

        <div class="text-left">
        <span v-for="(removeFilterLink,index) in removeFilterLinks" :key="index"
              class="inline-flex px-2.5 py-0.5 text-xs font-medium leading-4 bg-indigo-100 text-indigo-800">
        {{getRemovalLinkTitle(removeFilterLink.title)}} {{removeFilterLinks.href}}
        <button @click="navigateTo(removeFilterLink.href)" type="button"
                class="flex-shrink-0 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700">
            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
            </svg>
        </button>
    </span>
        </div>
    </div>
</template>

<script>
    import RadioInput from './Action/Fields/RadioInput';
    import CheckboxInput from './Action/Fields/CheckboxInput';
    import TextInput from './Action/Fields/TextInput';

    export default {
        data() {
            return {
                actionData: this.defaultValues()
            }
        },
        components: {
            RadioInput,
            CheckboxInput,
            TextInput
        },
        props: {
            action: {
                type: Object
            },
            response: {
                type: Object
            },
            actionBaseUrl: {
                default: '/orders'
            }
        },
        computed: {
            activeFiltersArray() {
                return this.activeFilters.split(', ');
            },
            removeFilterLinks() {
                if (!this.response) {
                    return null;
                }

                return this.response.body.links.filter(link => link.rels.toString().includes('remove_filter'));
            }
        },
        methods: {
            performAction(action, actionData) {
                let parts = action.href.split('/api');
                this.$router.push({path: this.actionBaseUrl, query: actionData});
            },
            navigateTo(link) {
                window.location = this.getFrontendRouteFromApiRoute(link);
            },
            getRemovalLinkTitle(originalTitle) {
                return String(originalTitle).replace('- Remove Filter', '');
            },
            getFieldComponent(type) {
                return type + '-input';
            },
            defaultValues() {
                let defaults = {};
                if (!this.action) {
                    return {};
                }
                this.action.fields.forEach(function (field) {
                    if (Array.isArray(field.value)) {
                        let filter = field.value.filter(o => o.selected === true);
                        if (filter[0]) {
                            defaults[field.name] = filter.map(o => o['value']).join(',');
                        } else {
                            defaults[field.name] = null;
                        }
                    } else {
                        defaults[field.name] = field.value;
                    }
                });
                return defaults;
            },

        },
    };
</script>

<style lang="scss">
.table-filter label{
    color:rgba(156, 163, 175, var(--tw-text-opacity));
    font-weight: normal;
}
.table-filter input, .table-filter select, .table-filter .form-input{
    border-radius: 0;
    margin:0;
    border-right-width: 0px;
}
.table-filter .item .input-item:first-of-type input,
.table-filter .item .input-item:first-of-type select,
.table-filter .item .input-item:first-of-type .form-input
{
    border-left-width:1px;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}
</style>
