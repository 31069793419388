<template>
    <div class="rounded-md">
        <div class="flex-grow">
          <label for="email" class="block text-sm font-medium leading-5 text-gray-700  mt-3">{{field.title}}<span class="text-red-500 ml-1" v-if="required">*</span></label>
          <select
            :aria-label="field.title"
            :name="field.name"
            :class="{'is-invalid' : errors.has(validationFieldName)}"
            class="form-select shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            v-model="propsVal"
            multiple
        >
            <!-- <option :value="null">{{field.title}}</option> -->
            <template v-for="option in field.options">
                <option :key="option.value" :value="option.value">{{option.title}}</option>
            </template>
        </select>
        </div>
      </div>
</template>

<script>
    export default {
        props: {
            value:{
                type: String,
                required: false,
                default: null
            },
            field: {
                type: Object
            },
            validationFieldName:{
                type: String,
                default: 'random'
            }
        },
        data() {
            return {
                propsVal: this.value
            }
        },
        computed:{
            required: function(){
                if(this.field.validation !== null) {
                    return this.field.validation.includes('required')
                }
                return false;
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                this.$emit("input", value);
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
