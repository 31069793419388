<template>
    <div>

        <div class="container-wide">
            <div class="flex flex-col">
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div
                    class="align-middle inline-block min-w-full shadow sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full" data-cy="rentals-table">
                    <thead>
                        <tr v-if="filterAction">
                            <th colspan=1000 class="p-8  bg-gray-50">
                                <generic-table-filter :action="filterAction"
                                :response="response"
                                :actionBaseUrl="$route.path"
                                ></generic-table-filter>
                            </th>
                        </tr>
                        <tr>
                        <th
                            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            v-for="headingValue in headingValues"
                            :key="headingValue"
                        >{{headingValue}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.response.body.properties.get('total') === 0">
                        <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                            <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                No rental items found.
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <template v-for="productEntity,key in productEntities">
                            <tr class="bg-white hover:bg-gray-100 cursor-pointer" :class="{'bg-gray-50': !(key % 2 == 0)}">
                                <template v-for="field, key in getPropertiesToDisplay(productEntity)">
                                    <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500" @click="navigateToItem(productEntity)">
                                        <template v-if="key === 'status'" @click="navigateToItem(productEntity)">
                                            <status-badge :status="field" ></status-badge>
                                        </template>
                                        <template v-else>
                                            {{field}}
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                    </table>
                    <pagination
                        :response="response"
                        @navigate="paginationNavigation($event)"
                        @navigateAdditionalNext="paginationNavigation($event)"
                        @navigateAdditionalPrev="paginationNavigation($event)"
                    />
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatusBadge from "./StatusBadge";
    import Pagination from "./Pagination";
    import GenericTableFilter from "./GenericTableFilter";
    import PrimaryButton from "./PrimaryButton";
    import SecondaryButton from "./SecondaryButton";
    import ActionWithForm from "./ActionWithForm";
    import ApproveRentalForm from "./CustomForms/ApproveRentalForm";
    import Modal from "./Modal";
    import RentalFeesModal from "./RentalFeesModal";
    import ReconcileOrderForm from "./ReconcileOrderForm";
    export default {
        components: {
            Modal,
            SecondaryButton,
            PrimaryButton,
            StatusBadge,
            Pagination,
            GenericTableFilter,
            ActionWithForm,
            ApproveRentalForm,
            RentalFeesModal,
            ReconcileOrderForm
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            filterAction() {
                if(!this.response){
                    return null;
                }
                return this.response.body.actions.filter(action => action.name === 'filter').first();
            },
            productEntities(){
                if(!this.response){
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('rental-item'));
            },
            headingValues() {
                return ['PO Number', 'Requested By', 'Contract','Description', 'Quantity', 'Unit','Due Date', 'Status'];
            }
        },
        methods: {
            getPropertiesToDisplay(productEntity) {
                return {
                    'purchase_order_number' : productEntity.entity.properties.get('purchase_order_number'),
                    'requested_by_user' : productEntity.entity.properties.get('requested_by_user'),
                    'contract' : productEntity.entity.properties.get('contract'),
                    'description' : productEntity.entity.properties.get('description'),
                    'quantity' : productEntity.entity.properties.get('quantity'),
                    'unit' : productEntity.entity.properties.get('unit'),
                    'due_date' : productEntity.entity.properties.get('due_date'),
                    'status' : productEntity.entity.properties.get('status')
                };
            },
            navigateToItem(productEntity) {
                let selfLink = productEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
                let parts = selfLink.split('/api');
                this.$router.push(parts[1]);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
