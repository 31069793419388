<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <sages-description-header :response="response"></sages-description-header>
                        <div class="container mx-auto p-4 mb-8">
                            <filterable-paginated-sage-descriptions-list :response="response">

                            </filterable-paginated-sage-descriptions-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import MainTemplate from '../templates/MainTemplate';
    import FilterablePaginatedSageDescriptionsList from "../components/FilterablePaginatedSageDescriptionsList";
    import SagesDescriptionHeader from "../components/SagesDescriptionHeader";
    export default {
        components: {
            SagesDescriptionHeader,
            FilterablePaginatedSageDescriptionsList,
            FetchRootSiren,
            Loading,
            MainTemplate
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
