<template>
    <div>
        <a-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>
        <portal to="destination">
        <slide-over-with-2-buttons v-show="displayModal" :submitting="submittingModal" :submittingSecond="submittingSecond" :title="action.title" :data-cy="`action-button-${action.name}`"
                                   :confirmationString='`Are you sure you want to \"${action.title}\"`'
                                   @removeFromDisplay="displayModal = false" @firstPerform="perform()" @secondPerform="secondPerform" :firstButtonTitle="firstButtonTitle" :secondButtonTitle="getSecondButtonTitle()" :showSecondButton="showSelectCompetingQuoteButton">
            <template v-if="getField('contact_id')">
                <component v-if="getField('contact_id')" :is="getFieldComponent(getField('contact_id'))" @input="supplierChanged" :field="getField('contact_id')" :options="getField('contact_id').options" :title="getField('contact_id').title" v-model="formData[getField('contact_id').name]" :data-cy="'filterable'">
                </component>
                <div v-if="getField('contact_id')" v-show="errors.has(getField('contact_id').name)" class="text-red-600">{{ errors.first(getField('contact_id').name) }}</div>
            </template>

            <template v-if="getField('total')">
                <component v-if="getField('total')" :is="getFieldComponent(getField('total'))" :field="getField('total')" v-model="formData[getField('total').name]">
                </component>
                <div v-if="getField('total')" v-show="errors.has(getField('total').name)" class="text-red-600">{{ errors.first(getField('total').name) }}</div>
            </template>

            <template v-if="getField('currency')">
                <component v-if="getField('currency')" :is="getFieldComponent(getField('currency'))" :field="getField('currency')" v-model="formData[getField('currency').name]">
                </component>
                <div v-if="getField('currency')" v-show="errors.has(getField('currency').name)" class="text-red-600">{{ errors.first(getField('currency').name) }}</div>
            </template>

            <template v-if="getField('includes_vat')">
                <component v-if="getField('includes_vat')" :is="getFieldComponent(getField('includes_vat'))" :field="getField('includes_vat')" v-model="formData[getField('includes_vat').name]">
                </component>
                <div v-if="getField('includes_vat')" v-show="errors.has(getField('includes_vat').name)" class="text-red-600">{{ errors.first(getField('includes_vat').name) }}</div>
            </template>

            <template v-if="getField('pdf_file')">
                <component v-if="getField('pdf_file')" :is="getFieldComponent(getField('pdf_file'))" :field="getField('pdf_file')" v-model="formData[getField('pdf_file').name]">
                </component>
                <div v-if="getField('pdf_file')" v-show="errors.has(getField('pdf_file').name)" class="text-red-600">{{ errors.first(getField('pdf_file').name) }}</div>
            </template>

            <template v-if="getField('supplier_pdf_file')">
                <component v-if="getField('supplier_pdf_file')" :is="getFieldComponent(getField('supplier_pdf_file'))" :field="getField('supplier_pdf_file')" v-model="formData[getField('supplier_pdf_file').name]">
                </component>
                <div v-if="getField('supplier_pdf_file')" v-show="errors.has(getField('supplier_pdf_file').name)" class="text-red-600">{{ errors.first(getField('supplier_pdf_file').name) }}</div>
            </template>

            <template v-if="getField('cms_pdf_file')">
                <div class="border-2 border-red-500 p-4 pt-2 rounded-md">
                    Use CMS Quote
                <component v-if="getField('cms_pdf_file')" :is="getFieldComponent(getField('cms_pdf_file'))" :field="getField('cms_pdf_file')" v-model="formData[getField('cms_pdf_file').name]">
                </component>
                <div v-if="getField('cms_pdf_file')" v-show="errors.has(getField('cms_pdf_file').name)" class="text-red-600">{{ errors.first(getField('cms_pdf_file').name) }}</div>
                <template v-if="getField('cms_total')">
                    <component class="mt-3" v-if="getField('cms_total')" :is="getFieldComponent(getField('cms_total'))" :field="getField('cms_total')" v-model="formData[getField('cms_total').name]">
                    </component>
                    <div v-if="getField('cms_total')" v-show="errors.has(getField('cms_total').name)" class="text-red-600">{{ errors.first(getField('cms_total').name) }}</div>
                </template>
                <template v-if="getField('cms_total_inc_tax')">
                    <component class="mt-3" v-if="getField('cms_total_inc_tax')" :is="getFieldComponent(getField('cms_total_inc_tax'))" :field="getField('cms_total_inc_tax')" v-model="formData[getField('cms_total_inc_tax').name]">
                    </component>
                    <div v-if="getField('cms_total_inc_tax')" v-show="errors.has(getField('cms_total_inc_tax').name)" class="text-red-600">{{ errors.first(getField('cms_total_inc_tax').name) }}</div>
                </template>
                <template v-if="getField('is_proforma')">
                    <component v-if="getField('is_proforma')" :is="getFieldComponent(getField('is_proforma'))" :field="getField('is_proforma')" v-model="formData[getField('is_proforma').name]">
                    </component>
                    <div v-if="getField('is_proforma')" v-show="errors.has(getField('is_proforma').name)" class="text-red-600">{{ errors.first(getField('is_proforma').name) }}</div>
                </template>
                </div>
            </template>



            <template v-if="getField('manufacturer_id')">
                <component v-if="getField('manufacturer_id')" :is="getFieldComponent(getField('manufacturer_id'))" :field="getField('manufacturer_id')" v-model="formData[getField('manufacturer_id').name]">
                </component>
                <div v-if="getField('manufacturer_id')" v-show="errors.has(getField('manufacturer_id').name)" class="text-red-600">{{ errors.first(getField('manufacturer_id').name) }}</div>
            </template>

            <template v-if="getField('account_used')">
                <component v-if="getField('account_used')" :is="getFieldComponent(getField('account_used'))" :field="getField('account_used')" v-model="formData[getField('account_used').name]">
                </component>
                <div v-if="getField('account_used')" v-show="errors.has(getField('account_used').name)" class="text-red-600">{{ errors.first(getField('account_used').name) }}</div>
            </template>

            <template v-if="getField('delivery_address')">
                <component v-if="getField('delivery_address')" :is="getFieldComponent(getField('delivery_address'))" :field="getField('delivery_address')" v-model="formData[getField('delivery_address').name]">
                </component>
                <div v-if="getField('delivery_address')" v-show="errors.has(getField('delivery_address').name)" class="text-red-600">{{ errors.first(getField('delivery_address').name) }}</div>
            </template>


            <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </slide-over-with-2-buttons>
        </portal>
    </div>
</template>

<script>
import Button from '@/v2/buttons/Button';
import Modal from '@/components/Modal';
import fileDownload from "js-file-download";
import SlideOverWith2Buttons from "@/v2/components/SlideOverWith2Buttons";

export default {
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            submittingSecond: false,
            showSelectCompetingQuoteButton: true
        }
    },
    components: {
        SlideOverWith2Buttons,
        'a-button': Button,
        Modal
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        secondAction: {
            type: Object,
            default: null
        },
        additionalButtonClasses:{
            type: String
        },
        buttonType: {
            type: String
        },
        firstButtonTitle:{
            type: String,
            default: 'Select Quote'
        }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    this.$router.go(0);
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getSecondButtonTitle(){
            if(this.secondAction === null){
                return "";
            }
            return "Select quote with CMS compete.";
        },
        secondPerform() {
            this.submittingSecond = true;
            this.secondAction.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingSecond = false;
                    return;
                }
                this.displayModal = false;
                this.submittingSecond = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                if (this.redirectOnSuccess) {
                    this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    this.$router.go(0);
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingSecond = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        supplierChanged(value){
            if(parseInt(this.formData['cms_supplier_id']) === parseInt(value) ||
                (this.formData['immediate_payment'] !== 'no' && this.formData['immediate_payment'] !== null)){
                this.showSelectCompetingQuoteButton = false;
            }else{
                this.showSelectCompetingQuoteButton = true;
            }
        },
        immediatePaymentChanged(value){
            console.log(value);
            if(parseInt(this.formData['cms_supplier_id']) === this.formData['supplier_id'] ||
                (value !== 'no' && value !== null)){
                this.showSelectCompetingQuoteButton = false;
            }else{
                this.showSelectCompetingQuoteButton = true;
            }
        },
        getField(fieldName){
            return this.action.fields.filter(field => field.name === fieldName).first();
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
