<template>
  <div >
      <top-header-template>
          <div class="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
              <div class="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Signup
                </h2>
              </div>
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <p class="alert alert-danger" v-if="error" v-text="error"></p>
                <form action="#" method="POST" @submit.prevent="onSubmit">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Your Personal Details
                        </h3>
                        <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                            Please enter your personal details in this section.
                        </p>
                    </div>
                    <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="client_admin_email" class="block text-sm font-medium leading-5 text-gray-700">
                            Email address
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    type="email"
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    id="client_admin_email"
                                    name="client_admin_email"
                                    v-model="email"
                                    v-validate="'required|email'"
                                    placeholder="Please enter your email address"
                                    :class="{'is-invalid': errors.first('client_admin_email') || errors.first('client_admin_email')}"
                                >
                            <p class="mt-2 text-sm text-red-600" id="email-error"
                            v-if="errors.first('client_admin_email') || errors.first('client_admin_email')"
                            >{{ errors.first('client_admin_email') || errors.first('client_admin_email') }}</p>
                        </div>
                    </div>

                    <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="client_admin_name" class="block text-sm font-medium leading-5 text-gray-700">
                            First Name
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                type="text"
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                id="client_admin_first_name"
                                name="client_admin_first_name"
                                v-model="firstName"
                                v-validate="'required'"
                                placeholder="Please enter your first name"
                                :class="{'is-invalid': errors.first('client_admin_first_name') || errors.first('client_admin_first_name')}"
                            >
                            <p class="mt-2 text-sm text-red-600" id="email-error"
                               v-if="errors.first('client_admin_first_name') || errors.first('client_admin_first_name')"
                            >{{ errors.first('client_admin_first_name') || errors.first('client_admin_first_name') }}</p>
                        </div>
                    </div>

                    <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="client_admin_name" class="block text-sm font-medium leading-5 text-gray-700">
                            Last Name/ Family Name
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                type="text"
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                id="client_admin_surname"
                                name="client_admin_surname"
                                v-model="surname"
                                v-validate="'required'"
                                placeholder="Please enter your surname"
                                :class="{'is-invalid': errors.first('client_admin_surname') || errors.first('client_admin_surname')}"
                            >
                            <p class="mt-2 text-sm text-red-600" id="email-error"
                               v-if="errors.first('client_admin_surname') || errors.first('client_admin_surname')"
                            >{{ errors.first('client_admin_surname') || errors.first('client_admin_surname') }}</p>
                        </div>
                    </div>

                    <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="client_admin_password" class="block text-sm font-medium leading-5 text-gray-700">
                            Password
                        </label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                :type="!showPassword ? 'password' : 'text'"
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                id="client_admin_password"
                                name="client_admin_password"
                                v-model="password"
                                v-validate="'required'"
                                placeholder="Please enter your password"
                                :class="{'is-invalid': errors.first('client_admin_password') || errors.first('client_admin_password')}"
                            >
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" @click="togglePassword">
                                <svg class="h-4 w-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                    <svg v-if="!showPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                    <svg v-if="showPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                                </svg>
                            </div>
                        </div>
                        <p class="mt-2 text-sm text-red-600" id="email-error"
                           v-if="errors.first('client_admin_password')  || errors.first('client_admin_password')"
                        >{{ errors.first('client_admin_password')  || errors.first('client_admin_password') }}</p>
                    </div>

                    <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="client_admin_contact_number" class="block text-sm font-medium leading-5 text-gray-700">
                            Contact Number
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                type="text"
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                id="client_admin_contact_number"
                                name="client_admin_contact_number"
                                v-model="contactNumber"
                                v-validate="'required'"
                                placeholder="Please enter your contact number"
                                :class="{'is-invalid': errors.first('client_admin_contact_number') || errors.first('client_admin_contact_number')}"
                            >
                            <p class="mt-2 text-sm text-red-600" id="email-error"
                               v-if="errors.first('client_admin_contact_number') || errors.first('client_admin_contact_number')"
                            >{{ errors.first('client_admin_contact_number') || errors.first('client_admin_contact_number') }}</p>
                        </div>
                    </div>

                    <div class="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                        <div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">
                                Your Organisation Details
                            </h3>
                            <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                Please enter your organisation details in this section.
                            </p>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="client_name" class="block text-sm font-medium leading-5 text-gray-700">
                                Organisation Name
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    type="text"
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    id="client_name"
                                    name="client_name"
                                    v-model="clientName"
                                    v-validate="'required'"
                                    placeholder="Please enter organisation name"
                                    :class="{'is-invalid': errors.first('client_name') || errors.first('client_name')}"
                                >
                                <p class="mt-2 text-sm text-red-600" id="email-error"
                                   v-if="errors.first('client_name') || errors.first('client_name') "
                                >{{ errors.first('client_name') || errors.first('client_name')  }}</p>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="client_company_registration_number" class="block text-sm font-medium leading-5 text-gray-700">
                                Company Registration Number
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    type="text"
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    id="client_company_registration_number"
                                    name="client_company_registration_number"
                                    v-validate="'required'"
                                    v-model="companyRegistrationNumber"
                                    placeholder="Please enter company registration number"
                                    :class="{'is-invalid': errors.first('companyRegistrationNumber') || errors.first('client_company_registration_number')}"
                                >
                                <p class="mt-2 text-sm text-red-600" id="email-error"
                                   v-if="errors.first('client_company_registration_number') || errors.first('client_company_registration_number')  "
                                >{{ errors.first('client_company_registration_number') || errors.first('client_company_registration_number')  }}</p>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="client_registered_address" class="block text-sm font-medium leading-5 text-gray-700">
                                Registered Address
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <textarea
                                    rows="5"
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    id="client_registered_address"
                                    name="client_registered_address"
                                    v-validate="'required'"
                                    v-model="address"
                                    placeholder="Please enter you registered address"
                                    :class="{'is-invalid': errors.first('client_registered_address') || errors.first('client_registered_address')}"
                                ></textarea>
                                <p class="mt-2 text-sm text-red-600" id="email-error"
                                   v-if="errors.first('client_registered_address') || errors.first('client_registered_address') "
                                >{{ errors.first('client_registered_address') || errors.first('client_registered_address')  }}</p>
                            </div>
                        </div>

                        <div class="mt-6 sm:mt-5 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="client_vat_number" class="block text-sm font-medium leading-5 text-gray-700">
                                VAT Number <span class="text-gray-400">(Please enter if VAT registered.) </span>
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    type="text"
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    id="client_vat_number"
                                    name="client_vat_number"
                                    v-model="vatNumber"
                                    placeholder="Please enter VAT number"
                                    :class="{'is-invalid': errors.first('client_vat_number') || errors.first('client_vat_number')}"
                                >
                                <p class="mt-2 text-sm text-red-600" id="email-error"
                                   v-if="errors.first('client_vat_number') || errors.first('client_vat_number') "
                                >{{ errors.first('client_vat_number') || errors.first('client_vat_number')  }}</p>
                            </div>
                        </div>


                    </div>

                    <div class="mt-6 border-t border-gray-200 pt-6">
                      <span class="block w-full rounded-md shadow-sm">
                        <button data-cy="submit" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                            Sign up
                            <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4"></loading>
                        </button>
                      </span>
                    </div>
                </form>
              </div>
          </div>
      </div>
  </top-header-template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Siren from "super-siren";
import TopHeaderTemplate from "../templates/TopHeaderTemplate";
import Loading from "@/components/Loading";

export default {
    components: {TopHeaderTemplate, Loading},
    data() {
        return {
            error: null,
            email: null,
            password: null,
            contactNumber: null,
            firstName: null,
            surname: null,
            clientName: null,
            vatNumber: null,
            companyRegistrationNumber : null,
            storeClientAction: null,
            storeClientAdminAction: null,
            showPassword: false,
            address: null,
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        })
    },
    created() {
        let follow = Siren.Helper.follow;
        Siren.Client.removeHeader("Authorization");

        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
            let siren = response.body;
            this.storeClientAction = siren.findActionByName('store-new-client');
        });
    },
    methods: {
        onSubmit() {
            if(!this.loading) {
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.loading = true;
                        this.storeClientAction.perform({
                            client_name: this.clientName,
                            client_vat_number: this.vatNumber,
                            client_company_registration_number: this.companyRegistrationNumber,
                            client_admin_email: this.email,
                            client_admin_first_name: this.firstName,
                            client_admin_surname: this.surname,
                            client_admin_contact_number: this.contactNumber,
                            client_admin_password: this.password,
                            client_registered_address: this.address
                        }).then(res => {
                            //this.$router.push("/signup/success");
                            this.loading = false;
                        })
                            .catch(error => {
                                this.loading = false;
                                if (error.response.status !== 422) {
                                    alert('There was an error processing your request.' + error);
                                }
                                this.errors.clear();
                                this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                            });
                    }
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        togglePassword(){
            this.showPassword = !this.showPassword
        }
    }
}

</script>
