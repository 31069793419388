<template>
    <a :href="href" class="group w-full flex items-center pl-11 pr-2 py-2 text-sm leading-5 font-medium text-white rounded-md hover:bg-primary-600 focus:outline-none focus:bg-primary-600 transition ease-in-out duration-150">
        <slot></slot>
    </a>
</template>

<script>
    export default {
        props: {
            href: {
                type: String
            },
        },
    }
</script>

<style scoped>

</style>
