import { render, staticRenderFns } from "./UpdateSageTaxCodeForm.vue?vue&type=template&id=26c74347&scoped=true&"
import script from "./UpdateSageTaxCodeForm.vue?vue&type=script&lang=js&"
export * from "./UpdateSageTaxCodeForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26c74347",
  null
  
)

export default component.exports