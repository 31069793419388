<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Vehicles</h2>

                <div class="flex items-center">
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty() && action.name === 'create-vehicle')"
                         class="mr-1">
                        <create-vehicle-form :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="true"
                                          onSuccessPath="/vehicles"
                                          @success="success(actionWithFields[0])"
                        ></create-vehicle-form>
                    </div>
                    <div v-for="actionWithFields in remaningActions.filter(action => !action.fields.isEmpty() && action.name !== 'create-vehicle')"
                         class="mr-1">
                        <action-with-form :action="actionWithFields[1]"
                                          :actionKey="actionWithFields[0]"
                                          :redirectOnSuccess="true"
                                          onSuccessPath="/vehicles"
                                          @success="success(actionWithFields[0])"
                        ></action-with-form>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import ActionWithForm from '../ActionWithForm';
    import Button from "@/components/Button";
    import CreatePaymentVoucherAction from "@/components/CreatePaymentVoucherAction";
    import CreateVehicleForm from "@/components/CustomForms/CreateVehicleForm";

    export default {
        inject: ["$validator"],
        components:{ActionWithForm, 'a-button': Button, CreatePaymentVoucherAction, CreateVehicleForm},
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            remaningActions() {
                return this.response.body.actions.filter(action => action.name !== 'filter')
            }
        },
        created() {
            document.title = 'Vehicles - CMS';
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>

</style>
