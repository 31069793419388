<template>
  <div>
    <main-template>
        <page-title title="Download Report"></page-title>
        <div class="container mx-auto px-4">
            <order-reports-form v-if="action" :action="action"></order-reports-form>
        </div>
    </main-template>
  </div>
</template>

<script>
import PageTitle from '../common/PageTitle';
import { mapGetters } from "vuex";
import Siren from 'super-siren';
import ActionHelper from '../mixins/Siren/ActionHelper';
import MainTemplate from "../templates/MainTemplate";
import OrderReportsForm from "../components/CustomForms/OrderReportsForm";
let follow = Siren.Helper.follow;
export default {
  mixins: [ActionHelper],
  name: "Reports",
  components: {
    PageTitle,
    MainTemplate,
      OrderReportsForm
  },
  data() {
    return {
      action: null
    }
  },
  computed: {
    ...mapGetters({
      accessToken: "getAccessToken"
    })
  },
  mounted () {
    Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
      Siren.get(process.env.VUE_APP_API_URL + "/api/")
        .then(follow("orders"))
        .then(res => {
          let siren = res.body;
          this.action = siren.actions.filter(action => action.name === 'get-report').get('get-report');
        }, this);

  },
};
</script>
