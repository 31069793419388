<template>
    <div id="holiday-requests" class="flex flex-col" >
        <div class="sm:flex sm:items-center sm:justify-between py-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                Slip Sends
            </h3>
        </div>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8" >
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Date
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        PDF
                    </th>
                </tr>
                </thead>
                <tbody v-if="payslips.size > 0" class="bg-white">
                <template v-for="payslip in payslips">
                    <tr class="border-b border-gray-200 last:border-b-0 hover:bg-gray-100">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ payslip.entity.properties.get('date') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <download-pdf-icon-with-link :url="payslip.entity.properties.get('pdf_url')"></download-pdf-icon-with-link>
                        </td>
                    </tr>
                </template>
                </tbody>
                <tbody v-else>
                <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                    <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                        No payslips found.
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "@/components/Button";
    import GenericTableFilter from "@/components/GenericTableFilter";
    import ConfirmationAction from "@/v2/components/ConfirmationAction";
    import FormAction from "@/v2/components/FormAction";
    import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
    export default {
        components: {
            DownloadPdfIconWithLink,
            FormAction,
            ConfirmationAction,
            GenericTableFilter,
            'a-button': Button
        },
        data(){
            return {
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            payslips() {
                let payslips = this.response.body.entities
                    .filter(function(entity) {
                        return entity.rels.contains("pay_n_send");
                    });
                if(!payslips){
                    return null;
                }

                return payslips;
            },
        },
        mounted(){
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            },
            success(key) {
                this.$router.go(0);
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
