<template>
    <tr class="bg-white order-item-row">
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            {{ orderItemEntity.entity.properties.get('product')['name'] }}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            {{ orderItemEntity.entity.properties.get('product')['description'] }}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            {{ orderItemEntity.entity.properties.get('product')['code'] }}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">

            <property-or-field @input="change" :properties="orderItemEntity.entity.properties" title="Qty"
                               :action="updateAction" fieldId="quantity" propertyKey="quantity"
                               v-model="updateActionData['quantity']"></property-or-field>
        </td>
        <td v-if="isDelivered" class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <property-or-field @input="change" :properties="orderItemEntity.entity.properties" title="Delivered Qty"
                               :action="updateAction" fieldId="delivered_quantity" propertyKey="delivered_quantity"
                               v-model="updateActionData['delivered_quantity']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <property-or-field @input="change" :properties="orderItemEntity.entity.properties" title="Unit"
                               :action="updateAction" fieldId="unit" propertyKey="unit"
                               v-model="updateActionData['unit']"></property-or-field>
        </td>
        <td class="px-3 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500"
            v-for="price in prices" >
            <span v-for="priceValue in orderItemEntity.entity.properties.get('prices')" v-if="priceValue['supplier_id'] === price['supplier_id']">
                {{priceValue['price']}}
            </span>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <span v-if="removeAction" class="cursor-pointer font-normal underline text-gray-400" @click="removeItem()">remove</span>
        </td>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from "../mixins/orderItemsUpdate";

    export default {
        inject: ["$validator"],
        mixins: [orderItemsUpdate],
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            orderItemEntity: {
                type: Object,
            },
            scope: {
                type: String
            },
            response: {
                type: Object
            },
            prices: {
                type: Object
            }
        },
        mounted() {

            this.orderItemEntity.entity.properties.get('prices').forEach(function(price){
                this.$emit('setPrice', price);
            }, this);
        },
        computed: {
            updateAction() {
                return null;//this.orderItemEntity.entity.actions.filter(action => action.name === 'update').first();
            },
            removeAction() {
                return null; //this.orderItemEntity.entity.actions.filter(action => action.name === 'delete').first();
            },
            updateActionData() {
                if (!this.updateAction) {
                    return {};
                }
                if (!this.updateAction.fields) {
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
        },
        methods: {
            async update() {
                return await this.updateAction.perform(this.updateActionData);
            },
            removeItem() {
                return this.removeAction.perform().then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'Item removed successfully.'
                    );
                    this.$router.go(0);
                }).catch(error => {
                    alert('There was an error processing your request.' + error);
                });
            },
            change() {
                this.$emit('change');
            },
            setPrice(){
                console.log('new price');
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
