<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link">

            <div slot-scope="{ response }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <template v-if="hasLink(response,'parent-category')">
                            <span @click="navigateToParentCategory(response)" class="cursor-pointer">
                            {{ response.body.properties.get('parent_category_name') }}
                            </span>
                        </template>
                        <template v-else>
                            <span @click="navigateToProductCategories(response)" class="cursor-pointer">
                                Product Library
                            </span>
                        </template>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('name')}}
                        </span>

                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-2">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                   Category Details
                                                    <svg v-if="response.body.actions.filter(action => action.name==='update-category').first()" @click="displayCategoryDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </dt>
                                                <dd class="text-sm text-black font-bold">{{ response.body.properties.get('name') }}</dd>
                                            </div>

                                            <div v-if="response.body.properties.get('data_sheet')">
                                                <dt class="text-sm font-nromal text-gray-500">Data Sheet</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <a class="text-sm text-gray-700 underline" href="#" @click.prevent="showDataSheet=true">Download DataSheet</a><br>
                                                    <download-pdf v-if="showDataSheet" :url="response.body.properties.get('data_sheet')" @removeFromDisplay="showDataSheet=false"></download-pdf>
                                                </dd>

                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <section v-if="response.body.actions.filter(action =>
                                    action.name !== 'update-category'
                                ).count() > 0" class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <actions :response="response" :remainingActions="
                                response.body.actions.filter(action =>
                                    action.name !== 'update-category'
                                )" :deletionPath="getDeletionPath(response)"></actions>
                            </div>
                        </section>
                        <section v-if="hasLink(response, 'no-type') || response.body.entities.filter(entity => entity.rels.contains('sub-category') || entity.rels.contains('type')).count() >0" class="lg:col-start-1 lg:col-span-3">
                            <product-category-list :withoutTypeLink="hasLink(response, 'no-type')" :response="response"  :categories="response.body.entities.filter(entity => entity.rels.contains('sub-category') || entity.rels.contains('type'))"></product-category-list>
                        </section>

                    </div>
                    <update-category-form v-if="response.body.actions.filter(action => action.name==='update-category').first()" :displayModal="displayCategoryDetails" :action="response.body.actions.filter(action => action.name==='update-category').first()" @removeFromDisplay="displayCategoryDetails=false" :onSuccessPath="$route.fullPath" :showButton="false"></update-category-form>

                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import Actions from "@/v2/components/Actions";
import DownloadPdf from "@/pages/DownloadPdf.vue";
import SubCategoryPaginatedList from "@/v2/paginatedLists/SubCategoryPaginatedList.vue";
import ProductCategoryList from "@/components/ProductCategoryList.vue";
import UpdateCategoryForm from "@/v2/components/forms/UpdateCategoryForm.vue";
export default {
    name: "Category",
    data() {
        return {
            displayCategoryDetails: false,
            showDataSheet: false
        }
    },
    components: {
        UpdateCategoryForm,
        ProductCategoryList,
        SubCategoryPaginatedList,
        Actions,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        DownloadPdf
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToProductCategories(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('product-categories')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        navigateToParentCategory(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('parent-category')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        hasLink(response, linkName){
            return response.body.links.filter(link => link.rels.contains(linkName)).first();
        },
        getDeletionPath(response){
            if(this.hasLink(response, 'parent-category')){
                let selfLink = response.body.links.filter(link => link.rels.contains('parent-category')).first().href;
                let parts = selfLink.split('/api');
                return parts[1];
            }
            let selfLink = response.body.links.filter(link => link.rels.contains('product-categories')).first().href
            let parts = selfLink.split('/api');
            return parts[1];
        }
    }
}
</script>

<style scoped>

</style>
