<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <a href="/vehicles">Vehicles</a>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('license_number')}}
                        </span>

                    </page-header>

                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1 md:grid-cols-2">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div>
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                    Vehicle Details
                                                    <svg v-if="response.body.actions.filter(action => action.name==='update-vehicle').first()" @click="displayVehicleDetails = true" xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-secondary-lavenderblue cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </dt>
                                                <dd class="text-sm text-black font-bold">{{ response.body.properties.get('license_number') }}</dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nromal text-gray-500">Make</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('make') }}
                                                </dd>

                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Model</dt>
                                                <dd class="text-sm font-bold text-black">{{response.body.properties.get('model')}}</dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Mileage</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{response.body.properties.get('mileage')}}
                                                </dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Current Keeper</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{response.body.properties.get('current_keeper_id')}}
                                                </dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Tax Expiry Date</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{response.body.properties.get('tax_expiry_date')}}
                                                </dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">MOT Expiry Date</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{response.body.properties.get('mot_expiry_date')}}
                                                </dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Private Use</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{response.body.properties.get('private_use')}}
                                                </dd>
                                            </div>
                                            <div>
                                                <dt class="text-sm font-nomal text-gray-500">Notes</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    <template v-if="response.body.properties.get('note')">{{response.body.properties.get('note')}}</template>
                                                    <template v-else>No Notes</template>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>
                            <section v-if="response.body.properties.get('handover_to_user') || response.body.properties.get('images')">
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1">
                                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <div v-if="response.body.properties.get('handover_to_user')">
                                                <dt class="inline-flex text-xs font-normal text-secondary-darkgrey-100 items-center">
                                                    Handover Details
                                                </dt>
                                            </div>
                                            <div v-if="response.body.properties.get('handover_to_user')">
                                                <dt class="text-sm font-nromal text-gray-500">Handover To</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('handover_to_user') }}
                                                </dd>

                                            </div>
                                            <div v-if="response.body.properties.get('handover_date')">
                                                <dt class="text-sm font-nromal text-gray-500">Handover Date</dt>
                                                <dd class="text-sm font-bold text-black">
                                                    {{ response.body.properties.get('handover_date') }}
                                                </dd>

                                            </div>
                                            <div v-if="response.body.properties.get('images')">
                                                <dt class="text-sm font-normal text-gray-500">Images</dt>
                                                <div class="grid grid-cols-2 sm:grid-cols-4">
                                                    <dd class="mt-1 text-sm text-gray-black flex" v-for="(image, index) in response.body.properties.get('images')">
                                                        <div class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg overflow-hidden">
                                                            <a :href="image" class="pointer-events-none">
                                                                <img :src="image" alt="" class="group-hover:opacity-75 object-cover pointer-events-none rounded-lg">
                                                            </a>
                                                            <button type="button" class="absolute">
                                                                <span class="sr-only">View details for IMG_5214.HEIC</span>
                                                            </button>
                                                        </div>
                                                    </dd>
                                                </div>

                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>

                        </div>
                        <section v-if="response.body.actions.filter(action =>action.name !== 'update-vehicle').count() !== 0" class="lg:col-start-3 lg:col-span-1">
                            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                <vehicle-actions :response="response" :remainingActions="response.body.actions.filter(action =>action.name !== 'update-vehicle')"></vehicle-actions>
                            </div>
                        </section>
                    </div>
                    <update-vehicle-form v-if="response.body.actions.filter(action => action.name==='update-vehicle').first()" :displayModal="displayVehicleDetails" :action="response.body.actions.filter(action => action.name==='update-vehicle').first()" @removeFromDisplay="displayVehicleDetails=false" :onSuccessPath="$route.path"></update-vehicle-form>


                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import UpdateVehicleForm from "@/v2/components/forms/UpdateVehicleForm";
import VehicleActions from "@/v2/components/VehicleActions";

export default {
    name: "Vehicle",
    data() {
        return {
            displayVehicleDetails: false
        }
    },
    components: {
        UpdateVehicleForm,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        VehicleActions
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },

    },
    methods: {
    }
}
</script>

<style scoped>

</style>
