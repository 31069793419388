<template>
    <div :key="$route.fullPath">
        <main-template class="pb-12">
            <template slot-scope="{logo}">
            <fetch-root-siren :linkName='linkName' :link="link" :logo="logo" @unauthorised="unauthorised">
                <div slot-scope="{ response, logo }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <payment-voucher-header :response="response" :logo="logo"></payment-voucher-header>
                        <payment-voucher-summary :response="response" @change="change" ref="paymentVoucherSummary"></payment-voucher-summary>
                    </template>
                </div>
            </fetch-root-siren>
            </template>

        </main-template>
        <div v-if="hasChangesWaitingOnSave"
             class="p-3 fixed bottom-0 w-full bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
            <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                Payment Voucher
            </primary-button>
        </div>
        <modal title="No Access" confirmation-string="" v-if="showUnauthorised" @perform="goToPaymentVouchers" @removeFromDisplay="goToPaymentVouchers">
            You do not have access to view this payment voucher.
        </modal>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import ReturnHeader from "../components/ReturnHeader";
    import RefundRequestItems from "../components/RefundRequestItems";
    import RefundRequestedByDetails from "../components/RefundRequestedByDetails";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import PaymentVoucherSummary from "@/components/Summaries/PaymentVoucherSummary";
    import PrimaryButton from "@/components/PrimaryButton";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import ConfirmationAction from "@/v2/components/ConfirmationAction";
    import Button from "@/v2/buttons/Button";
    import PaymentVoucherHeader from "@/v2/components/headers/PaymentVoucherHeader"
    import Modal from "@/v2/components/Modal.vue";

    export default {
        data() {
            return {
                hasChangesWaitingOnSave : false,
                loading: false,
                showUnauthorised: false
            }
        },
        components: {
            Modal,
            ConfirmationAction,
            FormAction,
            MainTemplate,
            ReturnHeader,
            FetchRootSiren,
            Loading,
            RefundRequestItems,
            RefundRequestedByDetails,
            PaymentVoucherHeader,
            PaymentVoucherSummary,
            PrimaryButton,
            PageHeader,
            'a-button': Button
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },
        },
        methods: {
            async saveData() {
                if(this.loading === false) {
                    this.loading = true;
                    this.$refs.paymentVoucherSummary.update().then(res => {
                        this.$refs.paymentVoucherSummary.$refs.paymentVoucherQas.updateItems().then(res => {
                            this.$refs.paymentVoucherSummary.$refs.paymentVoucherDayPays.updateItems().then(res => {
                            this.$store.dispatch(
                                "setMessage",
                                'The payment voucher was saved successfully.'
                            );
                            this.hasChangesWaitingOnSave = false;
                            this.loading = false;
                            this.$router.go(0);
                            }).catch(error => {
                                alert('There was an error processing your request.' + error.error);
                                this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                                this.loading = false;
                            });
                        }).catch(error => {
                            alert('There was an error processing your request.' + error.error);
                            this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                            this.loading = false;
                        });
                    }).catch(error => {
                        alert('There was an error processing your request.' + error);
                        this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text), error.scope);
                        this.loading = false;
                    });
                }
            },
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            unauthorised(){
                this.showUnauthorised = true;
            },
            goToPaymentVouchers(){
                this.$router.push('/payment-vouchers');
            }
        }
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1024px) {
        .order-container {
            background: linear-gradient(to right, white, white 50%, #f4f5f7 50%);
        }
    }
</style>
