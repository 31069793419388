<template>
    <main-template>
        <template v-slot:default="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link" :links="slotProps.links">
            <div slot-scope="{ response, links}">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="slotProps.logo">
                        <div class="md:flex justify-between w-full items-center">
                            <span class="flex items-center text-secondary-lightblue text-xl font-bold w-full">Orders
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">All Orders</span>
                       </span>
                            <div class="flex items-center text-secondary-darkgrey">
                                <div class="mr-1" v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter' && action.name !== 'get-report' && action.name !== 'reimport-with-sage-numbers' && action.name !== 'create-new-order' && action.name !== 'export-invoices' && action.name !== 'get-report')">
                                    <batch-reconcile-form v-if="actionWithFields[0] === 'batch-reconcile'" class="action-with-fields" :action="actionWithFields[1]"
                                                 :actionKey="actionWithFields[0]"
                                                 :redirectOnSuccess="false"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                                    ></batch-reconcile-form>
                                    <form-action v-else class="action-with-fields" :action="actionWithFields[1]"
                                                 :actionKey="actionWithFields[0]"
                                                 :redirectOnSuccess="true"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                                    ></form-action>
                                </div>
                            </div>
                        </div>

                    </page-header>
                <orders-submenu-nav :links="links">

                </orders-submenu-nav>
                <order-paginated-list :response="response"></order-paginated-list>
                </template>
            </div>
            </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
    import MainTemplate from "@/v2/templates/MainTemplate";
    import Loading from "@/components/Loading";
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import OrderPaginatedList from "@/v2/paginatedLists/OrderPaginatedList";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import Button from "@/v2/buttons/Button";
    import SubmenuNav from "@/v2/components/SubmenuNav";
    import OrdersSubmenuNav from "@/v2/components/OrdersSubmenuNav";
    import BatchReconcileForm from "@/v2/components/forms/BatchReconcileForm";
    export default {
    name: "Orders",
        components: {
            BatchReconcileForm,
            OrdersSubmenuNav,
            SubmenuNav,
            OrderPaginatedList,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader,
            FormAction,
            'a-button' : Button
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },

        },
        methods: {
            goToCreateOrdersPage() {
                return this.$router.push('orders/create');
            },
        }
    }

</script>

<style scoped>

</style>
