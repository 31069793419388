<template>
    <tr class="bg-white order-item-row">
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            <property-or-autocomplete-field ref="description" @input="descriptionUpdated"
                                            :properties="orderItemEntity.entity.properties" :scope="scope"
                                            :searchAction="searchAction" :action="updateAction" fieldId="description"
                                            propertyKey="description"
                                            v-model="updateActionData['description']"
                                            :disabled="orderItemEntity.entity.properties && orderItemEntity.entity.properties.get('product_id') !== null"></property-or-autocomplete-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
            <property-or-autocomplete-field ref="code" @input="codeUpdated"
                                            :properties="orderItemEntity.entity.properties" :scope="scope"
                                            :searchAction="searchAction" :action="updateAction" fieldId="code"
                                            propertyKey="code"
                                            v-model="updateActionData['code']"
                                            :disabled="orderItemEntity.entity.properties && orderItemEntity.entity.properties.get('product_id') !== null"></property-or-autocomplete-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <property-or-field ref="next" @input="dueDateUpdated" :properties="orderItemEntity.entity.properties"
                               title="Due Date"
                               :action="updateAction" fieldId="due_date" propertyKey="due_date" :scope="scope"
                               v-model="updateActionData['due_date']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <property-or-field @input="quantityUpdated" :properties="orderItemEntity.entity.properties" title="Qty"
                               :action="updateAction" fieldId="quantity" propertyKey="quantity" :scope="scope"
                               v-model="updateActionData['quantity']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <property-or-field @input="unitUpdated" :properties="orderItemEntity.entity.properties" title="Unit" :scope="scope"
                               :action="updateAction" fieldId="unit" propertyKey="unit"
                               v-model="updateActionData['unit']"></property-or-field>
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            <gbp-input :field="{name: 'price_in', title: 'Price In', type: 'number', validation: null}" v-model="items.price_in" @input="priceChanged"></gbp-input>
        </td>

        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            £{{(items.price_out/100).toFixed(2)}}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        </td>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from "../mixins/orderItemsUpdate";

    export default {
        inject: ["$validator"],
        data(){
          return {
              priceOut: 0,
              priceIn: 0,
              items: {},
              serviceFee: 0
          }
        },
        mounted(){
            this.items = {item_id: this.orderItemEntity.entity.properties.get('id'),
                description: this.updateActionData['description'],
                code: this.updateActionData['code'],
                due_date: this.updateActionData['due_date'],
                quantity: this.updateActionData['quantity'],
                unit: this.updateActionData['unit'],
                price_in: 0,
                price_out:0}
            this.serviceFee = (this.response.body.properties.get('service_fee') / 100) + 1;
        },
        mixins: [orderItemsUpdate],
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            orderItemEntity: {
                type: Object,
            },
            scope: {
                type: String
            },
            response: {
                type: Object
            }
        },
        computed: {
            updateAction() {
                return this.orderItemEntity.entity.actions.filter(action => action.name === 'update-for-quote-generation').first();
            },
            updateActionData() {
                if (!this.updateAction) {
                    return {};
                }
                if (!this.updateAction.fields) {
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
        },
        methods: {
            async update() {
                return await this.updateAction.perform(this.updateActionData);
            },
            change() {
                this.$emit('change');
            },
            priceChanged(value){
                let qty = this.items.quantity;
                this.priceOut = (value * qty * this.serviceFee);
                this.items.price_out = (value * qty * this.serviceFee);
                this.$emit('change');
            },
            descriptionUpdated(value){
                this.items.description = value;
                this.$emit('change');
            },
            codeUpdated(value){
                this.items.code = value;
                this.$emit('change');
            },
            dueDateUpdated(value){
                this.items.due_date = value;
                this.$emit('change');
            },
            quantityUpdated(value){
                this.items.quantity = value;
                this.items.price_out = (this.items.price_in * this.items.quantity * this.serviceFee);
                this.$emit('change');
            },
            unitUpdated(value){
                this.items.unit = value;
                this.$emit('change');
            },
            removeItem() {
                this.$emit('removeItem', this.keyProp);
                this.items= {};
            },
        },
        watch: {
            items: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
