<template>
    <div v-if="response" class="grid grid-cols-3 gap-x-4 gap-y-4">
        <div class="col-span-3">
            <dt class="text-xs font-normal text-secondary-darkgrey">Order Progress</dt>
            <dd class="text-xs font-normal text-secondary-darkgrey">
                <div class="mr-6 my-1">
                    <div class="w-full bg-gray-200 rounded-full h-2 opacity-100">
                        <div class="bg-secondary-darkblue h-2.5 rounded-full" :style="getStatusWidth()"></div>
                    </div>
                </div>
            </dd>
        </div>


        <div class="border-2 rounded-lg p-2 cursor-pointer" @click="showItems">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="h-10 w-10 m-auto"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320 64h-49.61C262.1 27.48 230.7 0 192 0S121 27.48 113.6 64H64C28.65 64 0 92.66 0 128v320c0 35.34 28.65 64 64 64h256c35.35 0 64-28.66 64-64V128C384 92.66 355.3 64 320 64zM192 48c13.23 0 24 10.77 24 24S205.2 96 192 96S168 85.23 168 72S178.8 48 192 48zM336 448c0 8.82-7.178 16-16 16H64c-8.822 0-16-7.18-16-16V128c0-8.82 7.178-16 16-16h18.26C80.93 117.1 80 122.4 80 128v16C80 152.8 87.16 160 96 160h192c8.836 0 16-7.164 16-16V128c0-5.559-.9316-10.86-2.264-16H320c8.822 0 16 7.18 16 16V448zM288 256H176C167.2 256 160 263.2 160 272S167.2 288 176 288H288c8.844 0 16-7.156 16-16S296.8 256 288 256zM288 352H176C167.2 352 160 359.2 160 368S167.2 384 176 384H288c8.844 0 16-7.156 16-16S296.8 352 288 352zM104 344c-13.25 0-24 10.75-24 24s10.75 24 24 24C117.3 392 128 381.3 128 368S117.3 344 104 344zM108 288c4.094 0 8.188-1.562 11.31-4.688l48-48c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L108 249.4L95.31 236.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62l24 24C99.81 286.4 103.9 288 108 288z"/></svg>
            <dt class="text-xs font-normal text-secondary-darkgrey-100 mt-4 mx-auto flex justify-center">Order list</dt>
        </div>
        <div class="border-2 rounded-lg p-2 cursor-pointer" v-if="response.body.properties.get('scheduled_file')" @click="showScheduledFile = true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="h-10 w-10 m-auto"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M127.1 0C141.3 0 151.1 10.75 151.1 24V64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V192H47.1V448C47.1 456.8 55.16 464 63.1 464H284.5C296.7 482.8 312.5 499.1 330.8 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0H127.1zM576 368C576 447.5 511.5 512 432 512C352.5 512 287.1 447.5 287.1 368C287.1 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368zM415.1 304V368C415.1 376.8 423.2 384 431.1 384H480C488.8 384 496 376.8 496 368C496 359.2 488.8 352 480 352H447.1V304C447.1 295.2 440.8 288 431.1 288C423.2 288 415.1 295.2 415.1 304V304z"/></svg>
            <dt class="text-xs font-normal text-secondary-darkgrey-100 mt-4 mx-auto flex justify-center">Schedule</dt>
        </div>
        <div class="border-2 rounded-lg p-2 cursor-pointer" v-if="response.body.properties.get('attachment')" @click="showAttachment = true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-10 w-10 m-auto"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M375 72.97C349.1 46.1 306.9 46.1 280.1 72.97L88.97 264.1C45.32 308.6 45.32 379.4 88.97 423C132.6 466.7 203.4 466.7 247 423L399 271C408.4 261.7 423.6 261.7 432.1 271C442.3 280.4 442.3 295.6 432.1 304.1L280.1 456.1C218.6 519.4 117.4 519.4 55.03 456.1C-7.364 394.6-7.364 293.4 55.03 231L247 39.03C291.7-5.689 364.2-5.689 408.1 39.03C453.7 83.75 453.7 156.3 408.1 200.1L225.2 384.7C193.6 416.3 141.6 413.4 113.7 378.6C89.88 348.8 92.26 305.8 119.2 278.8L271 127C280.4 117.7 295.6 117.7 304.1 127C314.3 136.4 314.3 151.6 304.1 160.1L153.2 312.7C143.5 322.4 142.6 337.9 151.2 348.6C161.2 361.1 179.9 362.2 191.2 350.8L375 167C401 141.1 401 98.94 375 72.97V72.97z"/></svg>
            <dt class="text-xs font-normal text-secondary-darkgrey-100 mt-4 mx-auto flex justify-center">Attachment</dt>
        </div>

        <div class="border-2 rounded-lg p-2 cursor-pointer" @click="showSuppliers = true" v-if="response.body.entities.filter(entity => entity.rels.includes('quote-sent-to')).count() > 0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="h-10 w-10 m-auto"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M352 48c8.8 0 16 7.2 16 16V352c0 8.8-7.2 16-16 16H320 243.2c-16.6-28.7-47.6-48-83.2-48s-66.6 19.3-83.2 48H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H352zm32 368c0 53 43 96 96 96s96-43 96-96h40c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V245.3c0-14.9-5.9-29.1-16.4-39.6l-93.3-93.3C487.8 101.9 473.6 96 458.7 96H416V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64c0 53 43 96 96 96s96-43 96-96h64 32 24 8zM557.7 239.6c.1 .1 .3 .3 .4 .4H416V144h42.7c2.1 0 4.2 .8 5.7 2.3l93.3 93.3zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
            <dt class="text-xs font-normal text-secondary-darkgrey-100 mt-4 mx-auto flex justify-center">Suppliers</dt>
        </div>
        <div class="border-2 rounded-lg p-2 cursor-pointer" v-if="selectedQuote && selectedQuote.entity.properties.get('cms_pdf_links')&& cmsQuote &&  selectedQuote.entity.properties.get('is_clone_quote') !== cmsQuote.entity.properties.get('is_clone_quote')" @click="showCmsQuote = true">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 m-auto" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 464H96v48H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V288H336V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"/></svg>
            <dt class="text-xs font-normal text-secondary-darkgrey-100 mt-4 mx-auto flex justify-center">CMS Quote</dt>
        </div>
        <div class="border-2 rounded-lg p-2 cursor-pointer" v-if="selectedQuote && selectedQuote.entity.properties.get('cms_pdf_links')" @click="showOriginalQuote = true">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 m-auto" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 464H96v48H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V288H336V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"/></svg>
            <dt class="text-xs font-normal text-secondary-darkgrey-100 mt-4 mx-auto flex justify-center">CMS Quote</dt>
        </div>
        <div class="border-2 rounded-lg p-2 cursor-pointer" v-if="response.body.entities.filter(entity => entity.rels.includes('delivery-confirmation')).count() > 0" @click="showDeliveryConfirmations = true">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 m-auto" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M320 64H280h-9.6C263 27.5 230.7 0 192 0s-71 27.5-78.4 64H104 64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64zM80 112v24c0 13.3 10.7 24 24 24h88 88c13.3 0 24-10.7 24-24V112h16c8.8 0 16 7.2 16 16V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V128c0-8.8 7.2-16 16-16H80zm88-32a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM289 267.6c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-89.7 89.7L129 287c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l53.3 53.3c9.4 9.4 24.6 9.4 33.9 0L289 267.6z"/></svg>
            <dt class="text-xs font-normal text-secondary-darkgrey-100 mt-4 mx-auto flex justify-center">POD & Delivery Confirmations</dt>
        </div>
        <download-pdf v-if="showAttachment" :url="attachmentUrl(response.body.properties.get('attachment'))" @removeFromDisplay="showAttachment=false"></download-pdf>
        <download-pdf v-if="showScheduledFile" :url="attachmentUrl(response.body.properties.get('scheduled_file'))" @removeFromDisplay="showScheduledFile=false"></download-pdf>
        <download-pdf v-if="showCmsQuote" :url="cmsQuote.entity.properties.get('pdf_links')" @removeFromDisplay="showCmsQuote=false"></download-pdf>
        <download-pdf v-if="showOriginalQuote" :url="selectedQuote.entity.properties.get('cms_pdf_links')" @removeFromDisplay="showOriginalQuote=false"></download-pdf>


        <large-model v-show="showSuppliers" @removeFromDisplay="showSuppliers = false" :displayConfirmButton="false">
            <sent-to-suppliers-table :response="response"></sent-to-suppliers-table>
        </large-model>

        <large-model v-show="showDeliveryConfirmations" @removeFromDisplay="showDeliveryConfirmations = false" :displayConfirmButton="false">
            <delivery-confirmation-table :response="response"></delivery-confirmation-table>
        </large-model>

    </div>
</template>

<script>
import DownloadPdf from "@/pages/DownloadPdf";
import LargeModel from "@/v2/components/LargeModel.vue";
import SentToSuppliersTable from "@/components/SentToSuppliersTable.vue";
import DeliveryConfirmationTable from "@/components/DeliveryConfirmationTable.vue";
export default {
    name: "OrderDetails",
    components:{
        LargeModel,
        DownloadPdf,
        SentToSuppliersTable,
        DeliveryConfirmationTable
    },
    data(){
        return {
            showAttachment: false,
            showScheduledFile: false,
            showQuotePdf: false,
            showCmsQuote: false,
            showOriginalQuote: false,
            showSuppliers: false,
            showDeliveryConfirmations: false
        }
    },
    props: {
        response: {
            type: Object
        }
    },
    computed:{
        selectedQuote(orderEntity) {
            let selectedQuote = this.response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("quote");
                })
                .first();

            if (!selectedQuote) {
                return null;
            }

            return selectedQuote;
        },
        cmsQuote(orderEntity) {
            let selectedQuote = this.response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("cms_quote");
                })
                .first();

            if (!selectedQuote) {
                return null;
            }

            return selectedQuote;
        }
    },
    methods:{
        attachmentUrl(attachment){
            if(!attachment.includes('/')){
                return process.env.VUE_APP_API_URL +'/api/files/attachments/' + attachment;
            }
            if(attachment.includes('public')){
                return process.env.VUE_APP_API_URL + '/' + attachment.replace('public', 'storage');
            }
            return process.env.VUE_APP_API_URL +'/api/files/' + attachment;
        },
        showItems() {
            this.$emit('showItems');
        },
        showPrePricedItems(){
            this.$emit('showPrePricedItems');
        },
        displayInvoices(){
            this.$emit('displayInvoices', true);
        },
        displayRefunded(){
            this.$emit('displayRefunded', true);
        },
        getStatusWidth(){
            if(this.getStatusValue() > 100){
                return "width: 100%";
            }
            return "width: " + this.getStatusValue() + "%";
        },
        getStatusValue(){
            return (this.response.body.properties.get('status_sort')/80) * 100
        }
    }
}
</script>

<style scoped>

</style>
