<template>
    <div>
        <div class="py-6">
            <div class="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                <slot name="savedFilter"></slot>
                <div>
                    <div>
                        <div class="flex flex-col">
                            <div class="-mt-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8" :class="{'flipped': flippedScrollbar}">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8" :class="{'flipped-scrollbar': flippedScrollbar}" >
                                    <div :class="{'relative parent-div': stickyHeaders}" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mb-8" >
                                        <div class="bg-white">
                                          <slot name="filters">
<!--                                            <order-filters :reloadPage="reloadPage" @reloadPage="reloadThePage" v-if="filterAction" :response="response" :action="filterAction" :actionBaseUrl="actionBaseUrl"></order-filters>-->
                                          </slot>

                                        </div>
                                        <table class="min-w-full">
                                            <thead class="bg-secondary-lightbacka" :class="{'sticky top-0  z-10':stickyHeaders}">
                                            <tr>
                                                <slot name="headers"></slot>
                                            </tr>
                                            </thead>
                                            <tbody v-if="!this.response">
                                            <tr class="bg-white hover:bg-gray-100 cursor-pointer">
                                                <td colspan="100" class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                                                    <slot name="noItems">No orders found.</slot>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tbody v-else class="divide-y divide-trueGray-200 bg-white">
                                                <slot name="tableRows"></slot>
                                            </tbody>

                                        </table>
<!--                                        <pagination :response="response"></pagination>-->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusBadge from "@/components/StatusBadge";
import OrderFilters from "@/v2/filters/OrderFilters";
import OrderTabs from "@/v2/tabs/OrderTabs";
import Pagination from "@/v2/paginatedLists/Pagination";
export default {
    name: "ApiPaginatedList",
    components: {OrderTabs, OrderFilters, Pagination},
    props: {
        response: {
            type: Array,
        },
        actionBaseUrl: {
            type: String,
        },
        useEntities:{
            type: Boolean,
            default: false
        },
        hasEntities:{
            type: Boolean,
            default: false
        },
        flippedScrollbar:{
            type: Boolean,
            default:false
        },
        reloadPage:{
            type: Boolean,
            default: true
        },
        stickyHeaders:{
            type: Boolean,
            default:false
        }
    },
    computed: {
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        }
    },
    methods:{
        reloadThePage(link){
            this.$emit('reloadPage', link);
        }
    }
}
</script>

<style scoped>
.flipped, .flipped .flipped-scrollbar
{
  transform:rotateX(180deg);
  -ms-transform:rotateX(180deg); /* IE 9 */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
}
.parent-div{
    max-height:90vh;
    overflow:auto;
    max-width:81vw;
}
</style>
