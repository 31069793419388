<template>
    <div>
        <div v-for="(action, index) in remainingActions">
            <template v-if="action[0] === 'download-pdf'">
                <a-button @click.native="downloadPdf()" :colourType="getColourType(action[0], index)" class="my-2 w-full" :loading="pdfLoading">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                    Download PDF
                </a-button>
            </template>
            <template v-else-if="!action[1].fields.isEmpty()">
                <form-action v-if="action[0] !== 'update-quality-assessment' && action[0] !== 'update-pre-check'" :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></form-action>
                <update-quality-assessment-form v-else-if="action[0] === 'update-quality-assessment'" :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></update-quality-assessment-form>
                <update-pre-check-form v-else-if="action[0] === 'update-pre-check'" :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></update-pre-check-form>
            </template>
          <template v-else-if="action[1].fields.isEmpty() && action[0] === 'delete-qa'">
            <confirmation-action additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties" onSuccessPath="/quality-assessments"></confirmation-action>
          </template>
            <template v-else-if="action[1].fields.isEmpty()">
                <confirmation-action additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath"></confirmation-action>
            </template>

        </div>
    </div>
</template>

<script>
import Button from "@/v2/buttons/Button";
import ApproveButtons from "@/v2/buttons/ApproveButtons";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
import UpdateQualityAssessmentForm from "@/v2/components/forms/UpdateQualityAssessmentForm.vue";
import UpdatePreCheckForm from "@/v2/components/forms/UpdatePreCheckForm.vue";
export default {
    name: "QualityAssessmentActions",
    data() {
        return {
            displayApproveModal : false,
            displayRejectModal : false,
            pdfLoading: false
        }
    },
    components:{
        FormAction,
        ConfirmationAction,
        'a-button' : Button,
        ApproveButtons,
        UpdateQualityAssessmentForm,
        UpdatePreCheckForm
    },
    props:{
        response:{
            type: Object
        },
        remainingActions:{
            type: Object
        }
    },
    methods:{
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('delete-qa')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        getAction(actionName){
            return this.remainingActions.filter(action => action.name === actionName).first();
        },
        downloadPdf(){
            this.pdfLoading = true;
            this.remainingActions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf').first().perform().responseType('blob').then(res => {
                var fileURL = window.URL.createObjectURL(res.xhr.response);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                let title = this.response.body.properties.get('download_pdf_title') + '.pdf'
                title = title.replaceAll('  ',' ').replaceAll('/','-').replaceAll('  ',' ');
                fileLink.setAttribute('download', title);
                document.body.appendChild(fileLink);
                fileLink.click();
                this.pdfLoading = false;
            }).catch(error => {
                this.pdfLoading = false;
                alert('There was an error processing your request.' + error);
                this.$emit('failure');
            });
        }
    }
}
</script>

<style scoped>

</style>
