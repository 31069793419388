<template>
    <paginated-list :response="response" actionBaseUrl="/schedule-manager">
        <template v-slot:savedFilter>
            <order-tabs :response="response"></order-tabs>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Order Details</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Project Details</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Scheduled File</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Created</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Last Updated</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Status</th>
        </template>
        <template v-slot:tableRows>
            <tr @click="navigateToItem(orderEntity)" v-for="orderEntity in orders" class="hover:bg-secondary-lightbacka cursor-pointer">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ orderEntity.entity.properties.get('order_reference') }}
                        <template v-if="orderEntity.entity.properties.get('purchase_order_number')">
                            /
                            {{ orderEntity.entity.properties.get('purchase_order_number') }}
                        </template>
                    </div>
                    <div>"{{ orderEntity.entity.properties.get('quick_reference') }}"</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{ orderEntity.entity.properties.get('project') }} - {{orderEntity.entity.properties.get('site')}}</div>
                    <div></div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <download-pdf-icon-with-link v-if="orderEntity.entity.properties.get('scheduled_file') !== null" :url="orderEntity.entity.properties.get('scheduled_file')"></download-pdf-icon-with-link>
                    <template v-else>No File Uploaded</template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>By {{ orderEntity.entity.properties.get('created_by') }}</div>
                    <div>10 days ago <span class="text-xs font-normal">(01/01/2021)</span></div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>By Danny McCready</div>
                    <div>10 days ago <span class="text-xs font-normal">(01/01/2021)</span></div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey">
                    <status-badge :status="orderEntity.entity.properties.get('status')"></status-badge>
                </td>
            </tr>
        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";
import OrderTabs from "@/v2/tabs/OrderTabs";
export default {
    name: "ScheduleManagerPaginatedList",
    components:{
        PaginatedList,
        StatusBadge,
        DownloadPdfIconWithLink,
        OrderTabs
    },
    props:{
        response:{
            type: Object
        }
    },
    computed:{
        orders() {
            if (!this.response) {
                return null;
            }
            return this.response.body.entities.filter(entity => entity.rels.contains('order'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
