<template>
    <div :key="$route.fullPath" class="h-full order-container">
        <main-template>
            <template slot-scope="{logo}">

            <fetch-root-siren :linkName='linkName' :link="link" :logo="logo">
                <div slot-scope="{ response, logo}">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <page-header :logo="logo">
                            <div class="md:flex justify-between w-full">
                                <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                    Clients
                                    <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    {{response.body.properties.get('name')}}
                                </div>
                                <div class="flex items-center text-secondary-darkgrey">
                                    <div v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'update-client' && action.name !== 'add-new-site' && action.name !== 'add-new-user' && action.name !== 'add-new-operative' && action.name !== 'add-new-project' && action.name !== 'filter' && action.name !== 'update-log-requirements' && !action.fields.isEmpty())"
                                         class="mr-1">
                                        <form-action :action="actionWithFields[1]"
                                                     :actionKey="actionWithFields[0]"
                                                     :redirectOnSuccess="true"
                                                     :onSuccessPath="$router.currentRoute.fullPath"
                                        ></form-action>
                                    </div>
                                </div>
                            </div>
                        </page-header>
                        <div class="grid grid-cols-4 min-h-screen">
                            <div
                                class="col-span-4 p-6 bg-white border border-gray-200 border-b-0 border-r-0 border-l-0">

                                <client-summary ref="summary"  :response="response" @change="change"></client-summary>
                                <client-entities-nav class="-mx-6" :links="response.body.links"></client-entities-nav>
                            </div>
                        </div>

                    </template>
                    <div v-if="hasChangesWaitingOnSave"
                         style="right:0px;left:6rem;"
                         class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                        <primary-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                            Client
                        </primary-button>
                    </div>
                </div>

            </fetch-root-siren>
            </template>

        </main-template>
    </div>
</template>

<script>
    import ClientHeader from '@/components/Headers/ClientHeader';
    import ClientSummary from '@/components/Summaries/ClientSummary';
    import FetchRootSiren from '@/components/FetchSirenRoot';
    import Loading from '@/components/Loading';
    import PrimaryButton from "@/components/PrimaryButton";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import PageHeader from "@/v2/components/Header";
    import FormAction from "@/v2/components/FormAction";
    import ClientEntitiesNav from "@/v2/components/ClientEntitiesNav";

    export default {
        inject: ["$validator"],
        components: {
            ClientEntitiesNav,
            MainTemplate,
            FetchRootSiren,
            Loading,
            PrimaryButton,
            ClientHeader,
            ClientSummary,
            PageHeader,
            FormAction
        },
        data() {
            return {
                hasChangesWaitingOnSave: false,
                loading:false,
            }
        },
        computed: {
            link() {
                return this.$route.fullPath.replace('/v3', '')
            },
            linkName() {
                return null;
            },
        },
        methods: {
            change() {
                this.hasChangesWaitingOnSave = true;
            },
            async saveData() {
                this.loading = true;
                this.$setLaravelValidationErrorsFromResponse('');
                this.$refs.summary.update().then(res => {
                    this.actionSuccess()
                }).catch(error => {
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                    this.loading = false;
                })
            },
            actionSuccess(){
                this.$store.dispatch(
                    "setMessage",
                    'The client was saved successfully'
                );
                this.hasChangesWaitingOnSave = false;
                this.loading = false;
            },
            navigateToVehicleLogRequirements(response){
                let requirementLink = response.body.links.filter(link => link.rels.contains('vehicle-requirements')).first().href;
                let parts = requirementLink.split('/api');
                this.$router.push(parts[1]);
            },
            navigateToOss(response){
                let ossLink = response.body.links.filter(link => link.rels.contains('on-site-selfie')).first().href;
                let parts = ossLink.split('/api');
                this.$router.push(parts[1]);
            },
            navigateToReoccurringInvoiceItems(response){
                let reoccurringLink = response.body.links.filter(link => link.rels.contains('reoccurring-invoice-items')).first().href;
                let parts = reoccurringLink.split('/api');
                this.$router.push(parts[1]);
            },
            navigateToContracts(response){
                let contractsLink = response.body.links.filter(link => link.rels.contains('contracts')).first().href;
                let parts = contractsLink.split('/api');
                this.$router.push(parts[1]);
            },
            navigateToProjects(response){
                let projectsLink = response.body.links.filter(link => link.rels.contains('projects')).first().href;
                let parts = projectsLink.split('/api');
                this.$router.push(parts[1]);
            },
            navigateToUsers(response){
                let usersLink = response.body.links.filter(link => link.rels.contains('users')).first().href;
                let parts = usersLink.split('/api');
                this.$router.push(parts[1]);
            },
        },
    }
</script>

<style scoped>

</style>
