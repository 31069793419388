<template>
    <div class="flex flex-col" v-if="creditNotes.size > 0">
        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Credit Notes</h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
            <table class="min-w-full">
                <thead>
                <tr>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Reference
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Invoice PDF
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Gross Value
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Net Value
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Vat Value
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Uploaded To Sage
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Linked Sales Invoice Status
                    </th>
                    <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Action
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white">
                <template v-for="creditNote in creditNotes">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{ creditNote.entity.properties.get('reference') }}
                        </td>
                        <download-pdf-icon-with-link :url="creditNote.entity.properties.get('file')"></download-pdf-icon-with-link>
                        <td
                            class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{creditNote.entity.properties.get('gross_value')}}
                        </td>
                        <td
                            class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{creditNote.entity.properties.get('net_value')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            {{creditNote.entity.properties.get('vat_value')}}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <template v-if="creditNote.entity.properties.get('isUploaded')">
                                <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            </template>
                            <template v-else>
                                <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </template>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <a class="underline hover:no-underline" v-if="creditNote.entity.properties.get('linked_sales_invoice')" :href="`/invoices/${creditNote.entity.properties.get('linked_sales_invoice').uuid}`">{{creditNote.entity.properties.get('linked_sales_invoice').reference}} - {{creditNote.entity.properties.get('linked_sales_invoice').status}}</a>
                            <span v-else>No linked sales invoice</span>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                            <new-credit-note-form class="mb-2" v-if="creditNote.entity.actions.get('update-credit-note')"
                                :action="creditNote.entity.actions.get('update-credit-note')"
                                                  actionKey="update-credit-note"
                                                  :onSuccessPath="$route.path"></new-credit-note-form>
                            <action-with-confirmation class="mb-2" v-if="creditNote.entity.actions.get('mark-as-uploaded-to-sage')"
                                                      :action="creditNote.entity.actions.get('mark-as-uploaded-to-sage')"
                                                      actionKey="mark-as-uploaded-to-sage"
                                                      :onSuccessPath="$route.path">
                            </action-with-confirmation>
                            <delete-credit-note-confirmation v-if="creditNote.entity.actions.get('delete-credit-note')"
                                                             :action="creditNote.entity.actions.get('delete-credit-note')"
                                                             actionKey="delete-credit-note"
                                                             :onSuccessPath="$route.path"
                                                             :isButton="false">
                            </delete-credit-note-confirmation>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
    import DownloadPdf from "../pages/DownloadPdf";
    import ActionWithForm from "./ActionWithForm";
    import ActionWithConfirmation from "./ActionWithConfirmation";
    import DownloadPdfIconWithLink from "./DownloadPdfIconWithLink";
    import NewCreditNoteForm from "@/components/NewCreditNoteForm";
    import DeleteCreditNoteConfirmation from "@/components/DeleteCreditNoteConfirmation";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                submittingByCard: false,
                showPDF:false,
                includesVat:null
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        components: {
            DownloadPdfIconWithLink,
            DownloadPdf,
            ActionWithConfirmation,
            NewCreditNoteForm,
            DeleteCreditNoteConfirmation
        },
        computed: {
            creditNotes() {
                let creditNotes = this.response.body.entities.filter(entity => entity.rels.contains('credit_note'));
                return creditNotes;
            },
        },
        methods: {
            getAbsolutePdfLink(url) {
            if (!/^https?:\/\//i.test(url)) {
                url = "http://" + url;
            }
            return url;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
