<template>
    <large-model class="email-preview" v-if="mailResponse" @removeFromDisplay="removeFromDisplay()" @perform="performSendAction()" :submitting="submitting">
      <div class="container mx-auto px-4 overflow-scroll">
        <div>
          <h1 class="text-gray-300 text-center mb-10 text-bold font-bold">Email preview</h1>
          <div class="send-for-quote-header">
            <div class="email-details">
              <div class="row">
                <div v-for="(field, index) in sendAction.fields" class="mb-2">
                    <component :is="getFieldComponent(field[1].type)" :field="field[1]" v-model="formData[field[1].name]">
                    </component>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white">
            <div class="email-body">
              <div class="row align-items-center" style="height:100%; text-align:center">
                <div class="col tailwind-only" v-if="mailResponse">
                  <div v-html="mailResponse.body.properties.get('html')"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </large-model>
</template>

<script>
import LargeModel from "./LargeModel";
import Loading from './Loading';

export default {
  name: "EmailPreview",

  data() {
    return {
      submitting: false,toValue:""

    }
  },

  components: {
    LargeModel,
    Loading
  },

  props: ["mailResponse", "displayLargeModel","sendActionName"],

  computed: {
    sendAction() {
      let action = this.mailResponse.body.actions.filter(
        action => action.name === 'send'
      ).first();
      return action;
    },
    formData(){
          if(!this.sendAction.fields){
              return {};
          }
          var payload = {};
          this.sendAction.fields.forEach(function(field){
              if (field.value !== undefined) payload[field.name] = field.value;
          });
          return payload;
      },
  },
  methods: {
    getFieldComponent(type) {
        return type + '-input';
    },
    removeFromDisplay(){
      return this.$emit('removeFromDisplay');
    },
    performSendAction(){
      this.submitting = true;
      this.sendAction.perform(this.formData).then(res => {
        this.$store.dispatch(
            "setMessage",
            this.messages(res).entity.properties.get("success")
        );
        this.submitting = false;
        this.$router.go(0);
      }).catch(error => {
        alert('There was an error processing your request.');
        this.submitting = false;
      })
    },
    messages(response) {
        return response.body.entities
            .filter(function(entity) {
            return entity.rels.contains("status-messages");
            })
            .first();
    }
  },
    created(){
      var companies = this.mailResponse.body.properties.get('companies')
      var email = this.mailResponse.body.properties.get('email')
        console.log(companies);
      console.log(email);
      if (companies){
          this.toValue = companies
          if (email){
              this.toValue += ", "
          }
      }
        if (email){
            this.toValue += email
        }

    }
};
</script>
