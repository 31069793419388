<template>
    <div>
        <div v-for="(action, index) in remainingActions">
            <template v-if="!action[1].fields.isEmpty()">
                <create-subscription-invoice-form v-if="action[0] === 'create-invoice-in'"
                                      :action="action[1]"
                                      :actionKey="action[0]"
                                      :onSuccessPath="$router.currentRoute.fullPath"
                                      additionalButtonClasses="my-2 w-full"
                                      :buttonType="getColourType(action[0], index)"
                >
                </create-subscription-invoice-form>
                <form-action v-else :response="response" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :onSuccessPath="$router.currentRoute.fullPath"></form-action>
            </template>
            <template v-else-if="action[1].fields.isEmpty()">
                <confirmation-action additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :buttonType="getColourType(action[0], index)" :redirectOnSuccess="true" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath"></confirmation-action>
            </template>
        </div>
    </div>
</template>

<script>
import Button from "@/v2/buttons/Button";
import ApproveButtons from "@/v2/buttons/ApproveButtons";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import FormAction from "@/v2/components/FormAction";
import QuoteApprovalAction from "@/v2/components/QuoteApprovalAction";
import CreateSubscriptionInvoiceForm from "@/v2/components/forms/CreateSubscriptionInvoiceForm.vue";
export default {
    name: "SubscriptionActions",
    data() {
        return {
            displayApproveModal : false,
            displayRejectModal : false
        }
    },
    components:{
        QuoteApprovalAction,
        FormAction,
        ConfirmationAction,
        'a-button' : Button,
        ApproveButtons,
        CreateSubscriptionInvoiceForm
    },
    props:{
        response:{
            type: Object
        },
        remainingActions:{
            type: Object
        }
    },
    methods:{
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        getAction(actionName){
            return this.remainingActions.filter(action => action.name === actionName).first();
        }
    }
}
</script>

<style scoped>

</style>
