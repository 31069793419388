<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">{{ title() }}</h2>
                <div class="flex items-center">
                    <div v-for="savedFilter in savedFilterAction.filter(action => !action.fields.isEmpty())"
                         class="mr-1">
                        <action-with-form :action="savedFilter[1]"
                                          :actionKey="savedFilter[0]"
                                          :redirectOnSuccess="false"
                                          @success="success(savedFilter[0])"
                        ></action-with-form>
                    </div>
                    <div v-for="savedFilter in savedDefaultFilterAction"
                         class="mr-1">
                        <action-with-confirmation :action="savedFilter[1]"
                                          :actionKey="savedFilter[0]"
                                          :redirectOnSuccess="true"
                                          :onSuccessPath="$router.currentRoute.fullPath"
                                          @success="success(savedFilter[0])"
                        ></action-with-confirmation>
                    </div>
                    <span v-if="this.$route.name !== 'MismatchQuotes' && this.response.body.actions.filter(action => action.name === 'create-new-order').first()" class="flex items-center">
                        <a-button @click.native="goToCreateOrdersPage()">Create New Order</a-button>
                    </span>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import Button from './Button';
    import ActionWithConfirmation from './ActionWithConfirmation';
    import ActionWithForm from './ActionWithForm';

    export default {
        inject: ["$validator"],
        components: {
            'a-button': Button,
            ActionWithConfirmation,
            ActionWithForm,
        },
        props: {
            response: {
                type: Object,
            },
        },
        created() {
            if(this.$route.name === 'MismatchQuotes'){
                document.title = 'Quotes Mismatch - Elmech';
            }

            document.title = 'Orders - Elmech';
        },
        computed: {
            savedFilterAction() {
                return this.response.body.actions.filter(action => action.name === 'save-filter')
            },
            savedDefaultFilterAction() {
                return this.response.body.actions.filter(action => action.name === 'set-as-default')
            }
        },
        methods: {
            goToCreateOrdersPage() {
                return this.$router.push('orders/create');
            },
            success(key) {
                this.$router.go(0);
            },
            title(){
                if(this.$route.name === 'MismatchQuotes'){
                    return 'Quotes Mismatch'
                }
                if(this.$route.name === 'ScheduleManager'){
                    return 'Schedule Manager';
                }
                if(this.$route.name === 'TenderOrders'){
                    return 'Tender Orders';
                }


                return 'Orders';
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
