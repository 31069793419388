<template>
    <div class="order-items">
        <div class="md:flex justify-between w-full items-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Order Items</h3>
        </div>
        <div class="flex flex-col">
            <div class="-my-2 py-2 overflow-x-auto md:overflow-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200">
                    <table class="min-w-full">
                        <thead>
                        <tr>
                            <th style="width:100%;"
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Description
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Code
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Due Date
                            </th>
                            <th class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Qty
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Unit
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Price In
                            </th>
                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Price Out
                            </th>
                            <td class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(orderItemEntity, key) in orderItemEntities">
                            <order-item-add-price-row @input="change" :ref="'orderItemRow'" :orderItemEntity="orderItemEntity"
                                            :scope="'order-item-row-'+key" :response="response"  v-model="itemPrices[key]"></order-item-add-price-row>
                        </template>
                        </tbody>
                        <new-order-add-price-items @input="change" :ref="'newOrderItems'" :response="response" v-model="newItemPrices" :serviceFee="serviceFee"></new-order-add-price-items>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OrderItemRow from './OrderItemRow';
    import NewOrderItems from './NewOrderItems';
    import ClockInForm from "@/v2/components/forms/ClockInForm.vue";
    import OrderItemAddPriceRow from "@/components/OrderItemAddPriceRow.vue";
    import NewOrderAddPriceItems from "@/components/NewOrderAddPriceItems.vue";

    export default {
        inject: ["$validator"],
        data(){
            return {
                itemPrices: [],
                newItemPrices: []
            }
        },
        components: {
            NewOrderAddPriceItems,
            OrderItemAddPriceRow,
            ClockInForm,
            OrderItemRow,
            NewOrderItems
        },
        props: {
            response: {
                type: Object,
            },
            serviceFee:{
                type: Number,
                default: 3
            }
        },
        mounted() {
            this.itemPrices = [];
            this.newItemPrices = [];
        },
        computed: {
            orderItemEntities() {
                if (!this.response) {
                    return null;
                }
                return this.response.body.entities.filter(entity => entity.rels.contains('order_item'));
            },
            isDelivered(){
                return this.response.body.properties.get('is_delivered');
            },
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'update').first();
            },
        },
        methods: {
            async updateItems() {
                let updateItemPromises = [];

                if (this.$refs.orderItemRow) {
                    for (const { index,row } of this.$refs.orderItemRow.map((row, index) => ({ index,row }))){
                        updateItemPromises.push(await row.update().catch(error => {
                            throw {
                                error: error,
                                scope: 'order-item-row-' + index
                            };
                        }));
                    };
                }
                const newItems = await this.$refs.newOrderItems.addNewItems();
                await newItems.forEach(function (promise) {
                    updateItemPromises.push(promise);
                })
                return await Promise.all(updateItemPromises);
            },
            change() {
                this.$emit('change');
                this.$emit('input', this.itemPrices.concat(this.newItemPrices));
            },
            updateRow(row){
                return row.update().catch(error => {
                    throw {
                        error: error,
                        scope: 'order-item-row-' + index
                    };
                });
            },
            addPreDefinedItems() {
                this.$emit('addPreDefinedItems');
            },
            removeItem(key) {
                this.itemPrices.splice(key, 1);
                this.items.splice(key, 1);
                this.change();
            },
        },
    }
</script>

<style lang="scss">
    .order-items label {
        display: none;
    }

    .table-container-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        background-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -moz-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -ms-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: -o-linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
        background-image: linear-gradient(0deg, rgba(255, 255, 255, .5), #fff);
    }
</style>
