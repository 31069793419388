<template>
    <button type="button" class="inline-flex items-center px-4 py-2 text-sm leading-5 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-50 focus:outline-none focus:border-red-300 focus:ring-red active:bg-red-200 transition ease-in-out duration-150">
        <slot></slot>
        <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4"></loading>
    </button>
</template>

<script>
import Loading from './Loading';
export default {
    components: {
        Loading,
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
