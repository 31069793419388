<template>
    <div :key="$route.fullPath" class="create-new-order">
        <main-template>
            <template slot-scope="{logo}">
                <page-header :logo="logo">
                    Create Qa Category
                </page-header>
                <loading v-if="loading"></loading>
                <div class="px-16 py-16" v-if="!loading" :key="key">
                    <div class="bg-white shadow rounded-md p-8 mb-8">
                        <property-or-field @input="change" title="Name" :action="createTemplateAction"
                                           fieldId="name" propertyKey="name"
                                           v-model="createTemplateActionData['name']"></property-or-field>

                        <property-or-field @input="change" title="Client" :action="createTemplateAction"
                                           fieldId="client_id" propertyKey="client_id"
                                           v-model="createTemplateActionData['client_id']" class="mt-4"></property-or-field>

                        <property-or-field @input="change" title="Is Drawing Required For QA" :action="createTemplateAction"
                                           fieldId="is_drawing_required" propertyKey="is_drawing_required"
                                           v-model="createTemplateActionData['is_drawing_required']"></property-or-field>

                        <questions-input title="Pre-Check Questions" class="mt-4" fieldId="pre_check_questions" :action="createTemplateAction" v-model="createTemplateActionData['pre_check_questions']">

                        </questions-input>

                        <questions-input title="Questions" class="mt-4" fieldId="questions" :action="createTemplateAction" v-model="createTemplateActionData['questions']">

                        </questions-input>

                        <payment-types-input title="Payment Types" class="mt-4" fieldId="payment_types" :action="createTemplateAction" v-model="createTemplateActionData['payment_types']">

                        </payment-types-input>
                    </div>
                    <div
                        v-if="!loading "
                        class="p-3 bottom-0 w-full mt-6 -mt-4 flex justify-end z-40">
                        <a-button @click.native="createTemplate()" class="cursor-pointer" data-cy="submit" :loading="submitting">
                            Create
                            Qa Category
                        </a-button>
                    </div>

                </div>
            </template>
        </main-template>
    </div>
</template>

<script>
    import Loading from '@/components/Loading';
    import {mapGetters} from "vuex";
    import Siren from "super-siren";
    import PropertyOrField from "@/components/PropertyOrField";
    import DependantField from "@/components/DependantField";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import QuestionsInput from "@/components/Action/Fields/QuestionsInput";
    import PageHeader from "@/v2/components/Header";
    import Button from "@/v2/buttons/Button";
    import PaymentTypesInput from "@/components/Action/Fields/PaymentTypesInput";

    let follow = Siren.Helper.follow;
    export default {
        components: {
            MainTemplate,
            Loading,
            PropertyOrField,
            DependantField,
            QuestionsInput,
            PageHeader,
            'a-button': Button,
            PaymentTypesInput
        },
        data() {
            return {
                createTemplateAction: null,
                loading: true,
                submitting: false,
                key:0
            }
        },

        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
            createTemplateActionData() {
                var vm = this;
                if (!this.createTemplateAction) {
                    return {};
                }
                if (!this.createTemplateAction.fields) {
                    return {};
                }
                var payload = {};
                this.createTemplateAction.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            change() {
            },
            createTemplate() {
                this.submitting = true;
                this.createTemplateAction.perform(this.createTemplateActionData).then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.submitting = false;
                    res.body.links.filter(link => link.rels.contains('self'))
                        .map(link => link.href)
                        .forEach(href => {
                            let parts = href.split('/api');
                            this.$router.push(parts[1]);
                        });
                    this.$emit('success', res);
                }).catch(error => {
                    this.submitting = false;
                    if(error.status === 422) {
                        console.log(JSON.parse(error.response.text));
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            }
        },
        mounted() {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow("templates"))
                .then(res => {
                    let siren = res.body;
                    this.createTemplateAction = siren.actions.filter(action => action.name === 'create-template').get('create-template');
                    this.loading = false;
                }, this);

        },
    }
</script>

<style lang="scss">
    .create-new-order .label-a {
        display: none;
    }
</style>
