<template>
    <div class="order-summary">
<!--        <h3 class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">Order Summary</h3>-->
        <div class="generic-summary p-4" :key="key">
            <div class="grid grid-cols-1 gap-4">
                <property-or-field v-if="getClientActionType() !== 'hidden'" @input="updateFields" title="Client" :action="updateAction" fieldId="organisation_id" propertyKey="organisationId" v-model="updateActionData['organisation_id']"></property-or-field>
                <property-or-field @input="updateFields" title="Week commencing on" :action="updateAction" fieldId="date" propertyKey="date" v-model="updateActionData['date']"></property-or-field>
                <property-or-field @input="updateFields" title="Groups" :action="updateAction" fieldId="user_group" propertyKey="user_group" v-model="updateActionData['user_group']" dependsOn="client_id" :dependsOnValue="updateActionData['organisation_id']"></property-or-field>
                <property-or-field @input="updateFields" title="Project" :action="updateAction" fieldId="projectId" propertyKey="projectId" v-model="updateActionData['projectId']"></property-or-field>
                <property-or-field @input="updateFields" title="Show Deactivated Users" :action="updateAction" fieldId="show_deactivated_users" propertyKey="show_deactivated_users" v-model="updateActionData['show_deactivated_users']"></property-or-field>
            </div>
        </div>
    </div>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import DownloadPdf from "../pages/DownloadPdf";
    export default {
            inject: ["$validator"],
        components: {
            DownloadPdf,
            PropertyOrField,
        },
        data() {
            return {
                key : 0
            }
        },
        props: {
            response: {
                type: Object,
                deliveryMethod: null
            },
        },
        computed: {
            updateAction() {
                return this.response.body.actions.filter(action => action.name === 'show-weekly-report').first();
            },
            updateActionData(){
                var that = this;
                if(!this.updateAction){
                    return {};
                }
                if(!this.updateAction.fields){
                    return {};
                }
                var payload = {};
                this.updateAction.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.name == 'delivery_method'){
                        that.deliveryMethod = field.value;
                    };
                });
                return payload;
            },
        },
        methods: {
            updateFields(){
                let organisationIdQuery = '';
                if(this.updateActionData['organisation_id'] !== null){
                    organisationIdQuery = '&organisation_id='+ this.updateActionData['organisation_id'];
                }
                let userGroupIdQuery = '';
                if(this.updateActionData['user_group'] !== null){
                    userGroupIdQuery = '&user_group='+ this.updateActionData['user_group'];
                }
                let showDeactivatedUsersQuery = '';
                if(this.updateActionData['show_deactivated_users'] !== null){
                    showDeactivatedUsersQuery = '&show_deactivated_users='+ this.updateActionData['show_deactivated_users'];
                }
                this.$router.push(this.$route.path+'?date='+this.updateActionData['date']+'&projectId='+this.updateActionData['projectId']+organisationIdQuery+userGroupIdQuery + showDeactivatedUsersQuery);
            },
            change(){
                this.$emit('change');
            },
            getClientActionType(){
                return this.updateAction.fields.filter(field => field.name === 'organisation_id').first().type;
            }
        },
    }
</script>

<style>
    .order-summary .form-group label{display:none;}
</style>
