
<template>
    <div :key="$route.fullPath">
        <main-template>
            <fetch-root-siren :linkName='linkName' :link="link">
                <div slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
                        <daily-report-header :response="response"></daily-report-header>
                        <div class="container mx-auto p-4 mb-8">
    <!--                        <saved-filters :response="response">-->
    <!--                        </saved-filters>-->
                            <header class="relative z-20 flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
                                <div>
                                    <h1 class="text-lg font-semibold leading-6 text-gray-900">
                                        <time class="sm:inline">{{currentDate()}}</time>
                                    </h1>
                                    <p class="mt-1 text-sm text-gray-500">{{currentDay()}}</p>
                                </div>
                                <div class="flex items-center">
                                    <div class="flex items-center rounded-md shadow-sm md:items-stretch">
                                        <button @click="getPrevDate()" type="button" class="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                                            <span class="sr-only">Previous month</span>
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                        <button type="button" :class="{'rounded-r-md border-r' : isCurrentPageToday()}" class="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block">
                                            {{getCurrentPageName()}}
                                        </button>
                                        <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden"></span>
                                        <button v-if="!isCurrentPageToday()" @click="getNextDate()" type="button" class="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                                            <span class="sr-only">Next month</span>
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </header>

                            <filterable-paginated-daily-reports-list :response="response">

                            </filterable-paginated-daily-reports-list>
                        </div>
                    </template>
                </div>
            </fetch-root-siren>
        </main-template>
    </div>
</template>

<script>
    import FetchRootSiren from '../components/FetchSirenRoot';
    import Loading from '../components/Loading';
    import MainTemplate from "../templates/MainTemplate";
    import FilterablePaginatedDailyReportsList from "@/components/FilterablePaginatedDailyReportsList";
    import moment from "moment";
    import DailyReportHeader from "@/components/Headers/DailyReportHeader";

    export default {
        components: {
            DailyReportHeader,
            FilterablePaginatedDailyReportsList,
            MainTemplate,
            FetchRootSiren,
            Loading,
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods:{
            getPrevDate(){
                let prevDate = new Date(this.$route.query.date);
                prevDate.setDate(prevDate.getDate() - 1);
                console.log('prev');
                this.$router.push(this.$route.path + '?date=' + moment(prevDate).format('YYYY-MM-DD') + '&organisationId=' + this.$route.query['organisationId']);
                return this.$route.path + '?date=' + moment(prevDate).format('YYYY-MM-DD') + '&organisationId=' + this.$route.query['organisationId']
            },
            getNextDate(){
                let nextDate = new Date(this.$route.query.date);
                nextDate.setDate(nextDate.getDate() + 1);
                console.log('next');
                if(nextDate > new Date()){
                    return;
                }
                this.$router.push(this.$route.path + '?date=' + moment(nextDate).format('YYYY-MM-DD') + '&organisationId=' + this.$route.query['organisationId']);
            },
            goToToday(){
                let today = new Date();
                this.$router.push(this.$route.path + '?date=' + moment(today).format('YYYY-MM-DD') + '&organisationId=' + this.$route.query['organisationId']);
            },
            isCurrentPageToday(){
                let nextDate = new Date(this.$route.query.date);
                nextDate.setDate(nextDate.getDate() + 1);
                if(nextDate > new Date()){
                    return true;
                }
                return false;
            },
            currentDate(){
                let currentDate = new Date(this.$route.query.date);
                return moment(currentDate).format('D MMMM YYYY');
            },
            currentDay(){
                let currentDate = new Date(this.$route.query.date);
                return moment(currentDate).format('dddd');
            },
            getCurrentPageName(){
                let currentDate = new Date(this.$route.query.date);
                if(this.isCurrentPageToday()){
                    return 'Today';
                }
                return moment(currentDate).format('Do MMM YYYY');
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
