<template>
    <div class="cursor-default">
        <a-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </a-button>
      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>

        <slide-over v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()">
            <template v-for="(field, index) in action.fields">
                <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[1].name]">
                </component>
                <div v-show="errors.has(field[1].name)" class="text-red-600 text-sm">{{ errors.first(field[1].name) }}</div>
            </template>

            <div v-show="errors.has('items')" class="text-red-600 text-sm">{{ errors.first('items') }}</div>

        </slide-over>

        <modal v-if="displayConfirmation" title="Batch Reconcile Finished" :hasConfirmationAction="false" confirmationString="" @removeFromDisplay="reloadPage" cancelButtonText="Close">
            <p class="text-sm leading-5 text-gray-500">
                {{ordersReconciled}} orders reconciled
            </p>
            <p class="text-sm leading-5 text-gray-500">
                {{ordersFailed}} orders couldn't be reconciled
            </p>
            <br>
            <p class="text-sm leading-5 text-gray-500" v-if="ordersFailedReasons.filter(reson => reson['reason'] === 'not_found').length !== 0">
                Order not found for:
                <template v-for="reasons in ordersFailedReasons.filter(reson => reson['reason'] === 'not_found')">
                    <span>{{reasons['reference']}}, </span>
                </template>
            </p>
            <p class="text-sm leading-5 text-gray-500" v-if="ordersFailedReasons.filter(reson => reson['reason'] === 'no_tax_details').length !== 0">
                Supplier tax details don't exists for: <br>
                <template v-for="reasons in ordersFailedReasons.filter(reson => reson['reason'] === 'no_tax_details')">
                    <span>{{reasons['reference']}}, </span>
                </template>
            </p>
            <p class="text-sm leading-5 text-gray-500" v-if="ordersFailedReasons.filter(reson => reson['reason'] === 'invoice_already_exists').length !== 0">
                Invoice already exists for: <br>
                <template v-for="reasons in ordersFailedReasons.filter(reson => reson['reason'] === 'invoice_already_exists')">
                    <span>{{reasons['reference']}}, </span>
                </template>
            </p>
            <p class="text-sm leading-5 text-gray-500" v-if="ordersFailedReasons.filter(reson => reson['reason'] !== 'not_found' && reson['reason'] !== 'no_tax_details' && reson['reason'] !== 'invoice_already_exists').length !== 0">
                You don't have permission for:
                <template v-for="reasons in ordersFailedReasons.filter(reson => reson['reason'] !== 'not_found' && reson['reason'] !== 'no_tax_details' && reson['reason'] !== 'invoice_already_exists')">
                    <span>{{reasons['reference']}}, </span>

                </template>
            </p>

        </modal>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import Modal from "@/components/Modal";

export default {
    name: "BatchReconcileForm",
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            ordersReconciled: 0,
            ordersFailed: 0,
            ordersFailedReasons: null,
            displayConfirmation: false
        }
    },
    components: {
        Modal,
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            this.submittingModal = true;
            this.action.perform(this.formData).then(res => {
                if (res.type === 'text/csv') {
                    fileDownload(res.text, 'export.csv');
                    this.submittingModal = false;
                    return;
                }
                this.displayModal = false;
                this.submittingModal = false;
                this.ordersReconciled = res.body.properties.get('reconciled');
                this.ordersFailed = res.body.properties.get('failed').length;
                this.ordersFailedReasons = res.body.properties.get('failed');
                this.displayConfirmation = true;

                if (this.redirectOnSuccess) {
                    let newPath = this.onSuccessPath;
                    let fullPath = this.$router.currentRoute.fullPath;

                    if(newPath  !== fullPath) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                    }else{
                        this.$router.go(0);
                    }
                }
                this.$emit('success', res);
            }).catch(error => {
                this.submittingModal = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        reloadPage(){
            let newPath = this.onSuccessPath;
            let fullPath = this.$router.currentRoute.fullPath;

            if(newPath  !== fullPath) {
                this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
            }else{
                this.$router.go(0);
            }
        }
    },
}
</script>

<style scoped>

</style>
