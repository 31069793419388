<template>
    <tr class="bg-white order-item-row">
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            {{orderItemEntity.entity.properties.get('description')}}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            {{orderItemEntity.entity.properties.get('code')}}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            {{orderItemEntity.entity.properties.get('quantity')}}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            {{orderItemEntity.entity.properties.get('delivered_quantity')}}
        </td>
        <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500">
            {{refundItemEntity.entity.properties.get('quantity')}}
        </td>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';
    import orderItemsUpdate from "../mixins/orderItemsUpdate";

    export default {
        inject: ["$validator"],
        mixins: [orderItemsUpdate],
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            orderItemEntity: {
                type: Object,
            },
            refundItemEntity: {
                type: Object,
            },
            scope: {
                type: String
            },
            response: {
                type: Object
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
