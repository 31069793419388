import { render, staticRenderFns } from "./SageTaxCodesPaginatedList.vue?vue&type=template&id=09526046&scoped=true&"
import script from "./SageTaxCodesPaginatedList.vue?vue&type=script&lang=js&"
export * from "./SageTaxCodesPaginatedList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09526046",
  null
  
)

export default component.exports