<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link" :logo="logo" @unauthorised="displayError">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <a href="/quality-assessments">Quality Assessments</a>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            {{response.body.properties.get('reference')}}
                        </span>

                    </page-header>

                    <QASummary :response="response"
                                  :show-construction-drawing-p-d-f="showConstructionDrawingPDF" :show-p-d-f="showPDF"
                                  @togglePdf="togglePDF"/>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import PageHeader from "@/v2/components/Header";
import QASummary from "@/v2/pages/QASummary.vue";

export default {
    name: "QualityAssessment",
    data() {
        return {
            displayQaDetails: false,
            showPDF: false,
            showConstructionDrawingPDF: false
        }
    },
    components: {
        QASummary,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        }
    },
    methods: {
        togglePDF(){
            this.showPDF = !this.showPDF;
        },
        displayError(){
            alert('You do not have access to view this quality assessment.');
            this.$router.push('/quality-assessments');
        }

    }
}
</script>

