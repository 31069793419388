<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div class="md:flex justify-between items-center mb-2 md:mb-0">
                <h2 class="text-lg leading-6 font-semibold text-gray-900">Return for order
                    #{{response.body.properties.get('order_reference')}}</h2>

                <div class="flex items-center">
                    <div v-for="actionWithFields in response.body.actions"
                         class="mr-1">
                        <action-with-form :action="actionWithFields[1]"
                                          onSuccessPath="/return-requests"
                                          :actionKey="actionWithFields[0]"
                                          :isNegativeAction="actionWithFields[0].includes('reject')"
                                          :isPositiveAction="actionWithFields[0].includes('accept')"
                        ></action-with-form>
                    </div>
                    <div>
                            <span
                                v-if="response.body.properties.get('status')"
                                :class="`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 badge-${response.body.properties.get('status')}`"
                            >{{response.body.properties.get('status')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import ActionWithConfirmation from "./ActionWithConfirmation";
    import ActionWithForm from "./ActionWithForm";

    export default {
        inject: ["$validator"],
        components: {
            ActionWithConfirmation,
            ActionWithForm
        },
        props: {
            response: {
                type: Object,
            },
        },
        created() {
            document.title = 'Products';
        },
    }
</script>

<style lang="scss" scoped>

</style>
