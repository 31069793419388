<script>
import Siren from "super-siren";
import { mapActions, mapGetters } from "vuex";
let follow = Siren.Helper.follow;

export default {
    inject: ["$validator"],
    data() {
        return {
            response: null
        }
    },
    props: {
        linkName: {
            type: String,
            default: null
        },
        link: {
            type: String,
            default: null
        },
        links:{
            type: Object,
            default: () => {},
        },
        logo:{
            type: String,
            default:null
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        })
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );

            if(this.linkName){
                let base = Siren.get(process.env.VUE_APP_API_URL + "/api/")
                    .then(follow(this.linkName))
                    .then(res => {
                    this.response = res;
                        this.$emit('responseLoaded', res);
                })
                .then(res => {
                    this.loading = false;
                });
            }

            if(this.link){
                Siren.get(process.env.VUE_APP_API_URL + "/api" + this.link).then(res => {
                    this.response = res;
                    this.$emit('responseLoaded', res);
                })
                .then(res => {
                    this.loading = false;
                }).catch((error) => {
                    if (error.status === 403){
                        alert("You don't have permission to view this page.");
                        this.$emit('unauthorised');
                    }
                    if(error.status === 401){
                        alert("You don't have permission to view this page.");
                    }
                });
            }
        }
    },

    render() {
        return this.$scopedSlots.default({
            response: this.response,
            links: this.links,
            logo:this.logo
        });
    }
};
</script>
