<template>
    <paginated-list :response="response" actionBaseUrl="/return-requests">
        <template v-slot:headers>
            <th scope="col" class="py-2 pl-4 uppercase font-normal pr-3 text-left text-sm text-secondary-darkgrey sm:pl-6">Description</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Code</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Ordered Qty</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Delivered Qty</th>
            <th scope="col" class="px-3 py-2 uppercase font-normal text-left text-sm text-secondary-darkgrey">Qty To Return</th>
        </template>
        <template v-slot:tableRows>
            <tr v-for="item in orderItems" class="hover:bg-secondary-lightbacka">
                <td class="whitespace-nowrap py-4 pl-4 text-sm text-secondary-darkgrey sm:pl-6 font-bold">
                    <div>{{ item.entity.properties.get('description') }}
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{item.entity.properties.get('code')}}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{item.entity.properties.get('quantity')}}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{item.entity.properties.get('delivered_quantity')}}</div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-secondary-darkgrey font-bold">
                    <div>{{getRelated(item).entity.properties.get('quantity')}}</div>
                </td>
            </tr>


        </template>
    </paginated-list>
</template>

<script>
import PaginatedList from "@/v2/paginatedLists/PaginatedList";
import StatusBadge from "@/components/StatusBadge";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink";
import Siren from "super-siren";
import {mapGetters} from "vuex";
export default {
    name: "ReturnRequestItemsList",
    components:{
        PaginatedList,
        StatusBadge,
        DownloadPdfIconWithLink
    },
    props:{
        response:{
            type: Object
        }
    },
    data(){
        return {
            orderItems : null
        }
    },
    computed:{
        ...mapGetters({
            accessToken: "getAccessToken"
        }),
        refundRequestOrderItemEntities() {
            if (!this.response) {
                return null;
            }

            let res = this.response;

            let firstOrderSelfLink = this.response.body.entities.filter(entity =>
                entity.rels.contains('order')).first().entity.links.filter(link => link.rels.contains('self')).first();
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(firstOrderSelfLink.href).then(res => {
                this.orderItems = res.body.entities.filter(entity => entity.rels.contains('order_item'))
            });
        },
        refundRequestItemEntities() {
            if (!this.response) {
                return null;
            }

            return this.response.body.entities.filter(entity => entity.rels.contains('refund_request_item'));
        },
    },
    methods: {
        navigateToItem(orderEntity){
            let selfLink = orderEntity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
            window.location = parts[1];
        },
        getRelated(refundRequestOrderItemEntity) {
            let orderItemId = refundRequestOrderItemEntity.entity.properties.get('id');
            return this.refundRequestItemEntities.filter(entity => parseInt(entity.entity.properties.get('order_item_id')) === parseInt(orderItemId)).first();
        }
    },
    created(){
        this.refundRequestOrderItemEntities();
    }
}
</script>

<style scoped>

</style>
