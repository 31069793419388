<template>
    <page-header :logo="logo">
        <div class="md:flex justify-between w-full items-center">
            <div class="md:flex items-center">
                <a href="/payment-vouchers">Payment Vouchers</a>
                <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="text-secondary-darkblue">
                    {{response.body.properties.get('subcontractor_name')}}
                </span>
            </div>
            <div class="flex items-center text-secondary-darkgrey">
                <div v-for="actionWithFields in remainingActions.filter(action => !action.fields.isEmpty() && action.name !== 'update-payment-voucher' && action.name !== 'add-qa'  && action.name !== 'add-day-pay' && action.name !== 'delete-payment-voucher')"
                     class="mr-1">
                    <form-action :action="actionWithFields[1]"
                                 :actionKey="actionWithFields[0]"
                                 :redirectOnSuccess="true"
                                 :onSuccessPath="$route.path"
                    ></form-action>
                </div>
                <div v-for="actionWithOutFields in remainingActions.filter(action => action.fields.isEmpty() && action.name !== 'download-pdf' && action.name !== 'delete-payment-voucher')"
                     class="mr-1">
                    <confirmation-action :action="actionWithOutFields[1]"
                                         :actionKey="actionWithOutFields[0]"
                                         :redirectOnSuccess="true"
                                         :onSuccessPath="$route.path"
                    ></confirmation-action>
                </div>
                <div v-if="remainingActions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf')" class="mr-1">
                    <a-button @click.native="downloadPdf()" class="mr-1" :loading="pdfLoading">
                        <svg class="w-6 h-6 text-current" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                        Download PDF
                    </a-button>
                </div>
                <div v-for="actionWithOutFields in remainingActions.filter(action => action.fields.isEmpty() && action.name === 'delete-payment-voucher')"
                     class="mr-1">
                    <confirmation-action :action="actionWithOutFields[1]"
                                         :actionKey="actionWithOutFields[0]"
                                         :redirectOnSuccess="true"
                                         :onSuccessPath="'/payment-vouchers'"
                                         buttonType="negative"
                    ></confirmation-action>
                </div>
            </div>
        </div>
    </page-header>
</template>

<script>
import PageHeader from "@/v2/components/Header"
import FormAction from "@/v2/components/FormAction";
import ConfirmationAction from "@/v2/components/ConfirmationAction";
import Button from "@/v2/buttons/Button";
export default {
    name: "PaymentVoucherHeader",
    components: {
        PageHeader,
        FormAction,
        ConfirmationAction,
        'a-button': Button
    },
    data(){
      return {
          pdfLoading: false
      }
    },
    props: {
        response: {
            type: Object
        },
        logo:{
            type: String,
            default: null
        }
    },
    computed: {
        remainingActions() {
            return this.response.body.actions.filter(action => action.name !== 'filter')
        },
    },
    methods:{
        downloadPdf(){
            this.pdfLoading = true;
            this.response.body.actions.filter(action => action.fields.isEmpty() && action.name === 'download-pdf').first().perform().responseType('blob').then(res => {
                var fileURL = window.URL.createObjectURL(res.xhr.response);
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                let pdfName = this.response.body.properties.get('pdf_name');
                fileLink.setAttribute('download', pdfName + '.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
                this.pdfLoading = false;
            }).catch(error => {
                this.pdfLoading = false;
                alert('There was an error processing your request.' + error);
                this.$emit('failure');
            });
        }
    }
}
</script>

<style scoped>

</style>
