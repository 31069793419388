<template>
    <tbody v-if="newItemAction">
    <new-certificate @removeItem="removeItem(key)" ref="newCertificateItem" :response="response" v-for="value,key in qty"
                    :key="key" :keyProp="key" :defaultValues="value" :scope="getScope(key)" v-if="value !== null"
                    :action="response"></new-certificate>

    <tr class="bg-white text-center">
        <td colspan="100">
            <div class="grid grid-cols-3 p-3">
                <button @click="addNewItem()" data-cy="add-item"
                        class="col-start-2 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition duration-150 ease-in-out">
                    + New Certificate
                </button>
            </div>
        </td>
    </tr>
    </tbody>
</template>

<script>
    import NewCertificate from "@/components/NewCertificate";
    import UploadItemsFromCsv from './UploadItemsFromCsv';

    export default {
        inject: ["$validator"],
        components: {
            NewCertificate,
            UploadItemsFromCsv
        },
        props: {
            response: {
                type: Object
            },
        },
        data() {
            return {
                qty: []
            }
        },
        computed: {
            newItemAction() {
                return this.response.body.actions.filter(action => action.name === 'add-certificate').first();
            },
        },
        methods: {
            removeItem(key) {
                this.$set(this.qty, key, null);
            },
            addCertificate() {
                let addNewItemPromises = [];
                if (this.$refs.newCertificateItem) {
                    this.$refs.newCertificateItem.forEach(function (row, index) {
                        addNewItemPromises.push(row.create().catch(error => {
                            throw {
                                error: error,
                                scope: row.scope
                            };
                        }));
                    }, this);
                }
                return addNewItemPromises;
            },
            getScope(index) {
                return 'new-certificate-row-' + index;
            },
            addNewItem() {
                this.qty.push({});
                this.$emit('change');
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
