<template>

    <div class="flex flex-col" v-if="response && attachments.size > 0">
        <div class="sm:col-span-2">
            <dt class="text-lg leading-6 font-medium text-gray-900 pt-4 pb-1">
                Attachments
            </dt>
            <dd class="mt-1 text-sm leading-5 text-gray-900">
                <ul class="border border-gray-200 rounded-md">
                    <template v-for="attachment in attachments">
                        <attachment-row :attachment="attachment">

                        </attachment-row>

                    </template>
                </ul>
            </dd>
        </div>
    </div>
</template>

<script>
    import ActionWithConfirmation from "../ActionWithConfirmation";
    import PrimaryButton from "../PrimaryButton";
    import SecondaryButton from "../SecondaryButton";
    import DownloadPdf from "../../pages/DownloadPdf";
    import AttachmentRow from "./AttachmentRow";
    export default {
        components:{SecondaryButton, PrimaryButton, ActionWithConfirmation, DownloadPdf,AttachmentRow},
        data() {
            return {
                showPDF: false
            }
        },
        props: {
            response: {
                type: Object,
            },
        },
        computed: {
            attachments() {
                return this.response.body.entities.filter(entity => entity.rels.contains('attachment'));
            },
        },
        methods: {
            getAbsolutePdfLink(url) {
                return url.replace('/storage/', '/files/');
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
