import { render, staticRenderFns } from "./MaterialQuantitiesTable.vue?vue&type=template&id=39835313&scoped=true&"
import script from "./MaterialQuantitiesTable.vue?vue&type=script&lang=js&"
export * from "./MaterialQuantitiesTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39835313",
  null
  
)

export default component.exports