<template>
    <main-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        Clients
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span @click="navigateToClient(response)" class="cursor-pointer">
                            {{response.body.properties.get('name')}}
                        </span>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="text-secondary-darkblue">
                            Vehicle Log Requirements
                        </span>

                    </page-header>



                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
                            <!-- Description list-->
                            <section>
                                <div class="bg-white shadow sm:rounded-lg">
                                    <div class="px-4 py-5 sm:px-6 grid grid-cols-1">
                                        <site-vehicle-entry-log-requirement-form ref="logRequirements" :response="response" @change="change"></site-vehicle-entry-log-requirement-form>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </template>
                <div v-if="hasChangesWaitingOnSave"
                     style="right:0px;left:6rem;"
                     class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-40">
                    <a-button @click.native="saveData()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                        Requirements
                    </a-button>
                </div>

            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import SiteVehicleEntryLogRequirementForm from "@/v2/components/forms/SiteVehicleEntryLogRequirementForm";
export default {
    name: "SiteVehicleEntryLogReuirements",
    data() {
        return {
            hasChangesWaitingOnSave: false,
            loading: false
        }
    },
    components: {
        SiteVehicleEntryLogRequirementForm,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToClient(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        navigateToSettings(response) {
            let settingLink = response.body.links.filter(link => link.rels.contains('settings')).first().href;
            let parts = settingLink.split('/api');
            this.$router.push(parts[1]);
        },
        change() {
            this.hasChangesWaitingOnSave = true;
        },
        async saveData() {
            this.loading = true;
            this.$setLaravelValidationErrorsFromResponse('');
            this.$refs.logRequirements.update().then(res => {
                this.$store.dispatch(
                    "setMessage",
                    'The requirements were saved successfully'
                );
                this.hasChangesWaitingOnSave = false;
                this.loading = false;
            }).catch(error => {
                this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped>

</style>
