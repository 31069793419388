<template>
    <tr>
        <template ref="newItem">
            <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
                <property-or-field ref="description" :scope="scope" :action="newItemAction"
                                                fieldId="description" propertyKey="description"
                                                v-model="newItemActionData['description']"></property-or-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
                <property-or-field ref="code" :scope="scope" :action="newItemAction" fieldId="unit_price"
                                                propertyKey="unit_price"
                                                v-model="newItemActionData['unit_price']"></property-or-field>
            </td>
            <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top text-right">
                20%
            </td>
            <td class="px-3 py-4 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 align-top">
                <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem();">remove</span>
            </td>
        </template>
    </tr>
</template>

<script>
    import PropertyOrField from './PropertyOrField';
    import PropertyOrAutocompleteField from './PropertyOrAutocompleteField';

    export default {
        inject: ["$validator"],
        data() {
            return {
                isSuccessfullyProcessed: false
            }
        },
        components: {
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            response: {
                type: Object,
            },
            scope: {
                type: String
            },
            keyProp: {
                type: Number
            },
            defaultValues: {
                type: Object,
                default: {}
            }
        },
        computed: {
            newItemAction() {
                return this.response.body.actions.filter(action => action.name === 'add-new-item').first();
            },
            searchAction() {
                return this.response.body.actions.filter(action => action.name === 'search-products').first();
            },
            newItemActionData() {
                if (!this.newItemAction.fields) {
                    return {};
                }
                var payload = {};
                this.newItemAction.fields.forEach(function (field) {
                    if (this.defaultValues[field.name]) {
                        payload[field.name] = this.defaultValues[field.name]
                    } else if (field.value !== undefined) {
                        payload[field.name] = field.value;
                    }
                }, this);
                return payload;
            },
        },
        methods: {
            create() {
                if (this.isSuccessfullyProcessed) {
                    return Promise.resolve();
                }

                return this.newItemAction.perform(this.newItemActionData).then(res => {
                    this.isSuccessfullyProcessed = true;
                });
            },
            removeItem() {
                this.$emit('removeItem', this.keyProp);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
